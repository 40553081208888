import type { LegalLanguages } from '../types';

export function getLegalLanguage(
  country: string,
  legalLanguages: LegalLanguages,
  browserLanguage: string
): string {
  const legalLanguage = legalLanguages[country.toLowerCase()] || legalLanguages['default'];

  if (typeof legalLanguage === 'object') {
    const locale = browserLanguage as keyof typeof legalLanguage;
    return legalLanguage[locale] || legalLanguage['default'] || '';
  }

  return legalLanguage || '';
}
