import i18next, {
  type CallbackError,
  type i18n,
  type ReadCallback,
  type ResourceKey,
} from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

export function setupI18next(
  loader: (language: string) => Promise<Record<string, string>>,
  fallbackLng: string
): i18n {
  const instance = i18next.createInstance({
    fallbackLng,
  });

  instance
    .use(
      resourcesToBackend((language: string, _namespace: string, callback: ReadCallback) => {
        loader(language).then(
          (res: ResourceKey) => callback(null, res),
          (err: CallbackError) => callback(err, null)
        );
      })
    )
    .init({
      interpolation: {
        escapeValue: false,
      },
    });

  return instance;
}
