import type { PiralPlugin } from 'piral-core';

import type { HandleErrorOptions } from '../../error-handling/types';

/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'piral-core/lib/types/custom' {
  interface PiletCustomApi extends PiletErrorHandlingApi {}
}

export interface PiletErrorHandlingApi {
  /**
   * An HDP App-Shell specific error handling API that displays and updates the Error Notification modal.
   * The following HTTP Status codes are handled:
   *  - Status codes: 400, 401, 403, 404, 500, 503
   *  - Fallback messages for: 4xx, 5xx
   * If no `options.code` provided only the `options.caption` is visible.
   * If no `options.caption` provided only a generic error message is visible.
   *
   * @param {HandleErrorOptions} options
   * @param {string} options.caption - The (already translated) error message that the modal should contain
   * @param {number} [options.code] - The HTTP Status Code if applicable
   */
  handleError(options: HandleErrorOptions): void;
}

export function createErrorHandlingApi(): PiralPlugin<PiletErrorHandlingApi> {
  return (context) => () => ({
    handleError(options: HandleErrorOptions) {
      return context.handleError(options);
    },
  });
}
