var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{display:inline-flex;align-items:center;justify-content:center;box-sizing:border-box;min-width:calc(var(--zui-gu) * 6);height:calc(var(--zui-gu) * 4);padding:0 calc(var(--zui-gu) * 2);color:var(--zui-color-pill-text);background-color:var(--zui-color-pill-background);border:1px solid var(--zui-color-pill-border);border-radius:16px;cursor:default}:host slot[name=iconClose]::slotted([tabindex]:focus){outline:none}:host(:focus){outline:none}:host([disabled]){opacity:.5;pointer-events:none}:host([disabled]) slot[name=iconClose]::slotted(*){pointer-events:none}:host([tabindex]:not([disabled]):focus-visible){border:1px dashed var(--zui-color-pill-border)}:host([tabindex]:not([disabled])) slot[name=iconClose]::slotted([tabindex]:focus-visible){outline:1px dashed var(--zui-color-pill-border)}slot[name=iconClose]::slotted(*){margin-left:calc(var(--zui-gu) * 1)}"
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-pill-background: var(--zui-color-gs-30);--zui-color-pill-border: var(--zui-color-gs-60);--zui-color-pill-text: var(--zui-color-gs-100)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-pill-background: var(--zui-color-gs-110);--zui-color-pill-border: var(--zui-color-gs-120);--zui-color-pill-text: var(--zui-color-gs-50)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const pillContentStyles = css `
  ${unsafeCSS(styles)}
`;
/**
 * A grouping UI component that displays the content of a pill
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-pill-content></zui-pill-content>
 * ```
 *
 * @slot - default slot for content
 * @slot icon - slot for a state dot or an icon
 * @slot iconClose - slot for a close icon within an interactive icon
 */
let PillContent = class PillContent extends BaseElement {
    render() {
        return html `
      <slot></slot>
      <slot name="icon"></slot>
      <slot name="iconClose"></slot>
    `;
    }
};
PillContent.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    pillContentStyles,
];
PillContent = __decorate([
    customElement('zui-pill-content')
], PillContent);
export { PillContent };
