var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
import { GetSharedEventClass } from '../../../contracts/event.classes.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import '../../../contracts/event.contracts.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const style = "@keyframes toggle-bottom{from{transform:translateY(calc(var(--zui-gu) * -4));opacity:0}to{transform:translateY(0);opacity:1}}@keyframes toggle-top{from{transform:translateY(calc(var(--zui-gu) * 4));opacity:0}to{transform:translateY(0);opacity:1}}:host{---zui-menu-animation-duration: 0.1s}#wrapper{animation-duration:var(---zui-menu-animation-duration);animation-fill-mode:forwards}#wrapper.open{animation-timing-function:ease-out;animation-direction:normal;pointer-events:all}#wrapper.close{animation-timing-function:ease-in;animation-direction:reverse;pointer-events:none}:host([data-popper-placement^=bottom]) #wrapper.running{animation-name:toggle-bottom}:host([data-popper-placement^=top]) #wrapper.running{animation-name:toggle-top}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const menuAnimationStyles = css `
  ${unsafeCSS(style)}
`;
class MenuAnimationTransitionEndEvent extends GetSharedEventClass('menu-animation-transition-end') {
}
/**
 * This element brings animations to an projected overlay menu which presentation can be toggled.
 * It manages the open and close animations and notifies about those processes.
 *
 * @example
 * ```HTML
 *   <zui-menu-animation open>
 *     <zui-menu>
 *       <zui-menu-item>Must adhere to interface</zui-menu-item>
 *     </zui-menu>
 *   </zui-menu-animation>
 * ```
 *
 * @fires menu-animation-transition-end - broadcasts if menu starts opening
 *
 * @slot - default slot for the wrapped zui-menu
 * @cssprop --zui-menu-animation-duration - duration of the menu toggle animation
 */
let MenuAnimation = class MenuAnimation extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.TransitionEvent = MenuAnimationTransitionEndEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * Either show or hide the menu, triggers animations.
         */
        this.open = false;
        this._running = false;
    }
    _handleAnimationEnd() {
        this._running = false;
        this._emitMenuAnimationTransitionEndEvent();
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('open')) {
            this._running = true;
        }
    }
    render() {
        return html `
      <div
        id="wrapper"
        class="${classMap({ open: this.open, close: !this.open, running: this._running })}"
        @animationcancel="${this._handleAnimationEnd}"
        @animationend="${this._handleAnimationEnd}"
      >
        <slot></slot>
      </div>
    `;
    }
};
MenuAnimation.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    menuAnimationStyles,
];
__decorate([
    property({ reflect: true, type: Boolean })
], MenuAnimation.prototype, "open", void 0);
__decorate([
    state()
], MenuAnimation.prototype, "_running", void 0);
MenuAnimation = __decorate([
    customElement('zui-menu-animation')
], MenuAnimation);
export { MenuAnimation };
