import { ZuiAvatar, ZuiHeaderbar, ZuiUserMenuButton, ZuiZeissLogo } from '@zeiss/zui-react';
import { type FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { getUserData } from '../../auth/authenticated-user';
import { useNavAction } from '../../hooks/use-nav-action';
import { BellNotificationButton } from '../bell-notification/BellNotificationButton';
import { UserMenuPopoverContent } from './UserMenuPopoverContent';

const HeaderContainer = styled.div`
  position: fixed;
  z-index: 2;
  right: 0;
  left: 0;
`;

const ClickableZuiZeissLogo = styled(ZuiZeissLogo)`
  cursor: pointer;
`;

function isSmallWidth() {
  return window.innerWidth < 1024;
}

const HeaderBar: FC = () => {
  const [isOpenUserMenuPopover, setIsOpenUserMenuPopover] = useState(false);
  const [avatarOnly, setAvatarOnly] = useState(isSmallWidth());

  const closeUserMenu = useCallback(() => {
    setTimeout(() => setIsOpenUserMenuPopover(false), 0);
  }, []);

  const navigateToHomepage = useNavAction('/');

  useEffect(() => {
    function updateWidth() {
      const exceededBreakpoint = (avatarOnly && !isSmallWidth()) || (!avatarOnly && isSmallWidth());
      if (exceededBreakpoint) {
        setAvatarOnly(!avatarOnly);
      }
    }

    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [avatarOnly]);

  const openUserMenu = () => !isOpenUserMenuPopover && setIsOpenUserMenuPopover(true);

  const { firstName = '', lastName = '' } = getUserData() ?? {};
  const fullName = `${firstName} ${lastName}`.trim();
  const initials = `${firstName[0]}${lastName[0]}`;

  return (
    <HeaderContainer>
      <ZuiHeaderbar>
        <ClickableZuiZeissLogo size="s32" slot="icon" onClick={navigateToHomepage} />
        <BellNotificationButton />
        <ZuiUserMenuButton
          size="m"
          slot="userMenuButton"
          emphasis={isOpenUserMenuPopover ? 'selected' : 'default'}
          userName={fullName}
          onClick={openUserMenu}
          avatarOnly={avatarOnly}
        >
          <ZuiAvatar slot="avatar" initials={initials} />
        </ZuiUserMenuButton>
      </ZuiHeaderbar>
      {isOpenUserMenuPopover && <UserMenuPopoverContent onClose={closeUserMenu} />}
    </HeaderContainer>
  );
};

export default HeaderBar;
