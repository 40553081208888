var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import { hostStyles } from '../../../host.styles.js';
import { BaseElement, RealBaseElement } from '../../base/base-element.class.js';
import '../list-item/list-item.component.js';
const style = ":host{overflow-x:hidden}"
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const listStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * The list container represents a list of items.
 *
 * We are currently working on skeleton components which can be used with ZUi components.
 * For now create yourself custom skeletons and put them as normal items into the zui-list.
 *
 * ## Figma
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=13761%3A0)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-list integrated hierarchy="second">
 *   <zui-list-item></zui-list-item>
 *   <zui-list-item></zui-list-item>
 * </zui-list>
 * ```
 *
 * @slot - This is the default slot. It's an innerHtml of the list-element
 */
let List = class List extends DisabledMixin(RealBaseElement) {
    constructor() {
        super(...arguments);
        /**
         * Defines the hierarchy for this list.
         */
        this.hierarchy = 'first';
        /**
         * Defines if the list is read-only or not.
         * A read-only list contains items without interaction states.
         */
        this.readonly = false;
        /**
         * Defines if the list is integrated or not.
         * A list that is integrated but not read-only contains integrated items.
         * A list that is neither integrated nor read-only contains standalone items.
         */
        this.integrated = false;
        /**
         *	Defines one of five possible sizes ('xs' / 's' / 'm' / 'l' / 'xl');
         *	This size will also be applied to each list item.
         */
        this.size = 'm';
    }
    _propagateProperties() {
        this._listItems.forEach((listItem) => {
            // only propagate disabled, if actually disabled
            if (this.disabled) {
                listItem.disabled = this.disabled;
            }
            listItem.size = this.size;
            listItem.hierarchy = this.hierarchy;
            listItem.readonly = this.readonly;
            listItem.integrated = this.integrated;
        });
    }
    _slotChangeHandler() {
        this._propagateProperties();
    }
    updated(_changedProperties) {
        super.updated(_changedProperties);
        this._propagateProperties();
    }
    render() {
        return html `<slot @slotchange=${this._slotChangeHandler}></slot>`;
    }
};
List.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    listStyles,
];
__decorate([
    property({ reflect: true, type: String })
], List.prototype, "hierarchy", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], List.prototype, "readonly", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], List.prototype, "integrated", void 0);
__decorate([
    property({ reflect: true, type: String })
], List.prototype, "size", void 0);
__decorate([
    queryAssignedElements({ flatten: true })
], List.prototype, "_listItems", void 0);
List = __decorate([
    customElement('zui-list')
], List);
export { List };
