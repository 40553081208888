var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import '../../../types.js';
import { isDefined, numberUndefinedConverter, stringUndefinedConverter } from '../../../utils/component.utils.js';
import { addLeadingZeros, constrainInputValue, isAllowedNumericKeyboardInput, } from '../utils/date-picker-input.utils.js';
import { BaseElement } from '../../base/base-element.class.js';
import { literalConverter } from '../utils/date-picker.utils.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { DelegateFocusMixin } from '../../../mixins/visual-focus/delegate-focus.mixin.js';
import { GetSharedEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { hostStyles } from '../../../host.styles.js';
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const styles = ":host{--zui-date-picker-input-part-placeholder-width: 0;---zui-date-picker-input-part-input-width: 0}input{width:var(---zui-date-picker-input-part-input-width);height:calc(var(--zui-gu) * 3.75);padding:0;color:var(--zui-color-text-default);font:var(--zui-typography-body);line-height:calc(var(--zui-gu) * 3.75);background-color:rgba(0,0,0,0);border:none;box-shadow:none}:host([disabled]) input{opacity:var(--zui-disabled-opacity)}input.show-placeholder{width:var(--zui-date-picker-input-part-placeholder-width)}input:focus{outline:none}input:active{outline:none}input::placeholder{color:var(--zui-color-placeholder-input)}:host([readonly]) input::placeholder{color:rgba(0,0,0,0);opacity:0}input::-moz-placeholder{opacity:1}input[type=number]{-moz-appearance:textfield}input[type=number]::-webkit-outer-spin-button,input[type=number]::-webkit-inner-spin-button{margin:0;-webkit-appearance:none}"
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-text-default: var(--zui-color-gs-100);--zui-color-text-on-dark-bg: var(--zui-color-gs-10);--zui-color-text-primary: var(--zui-color-pb-100);--zui-color-text-header: var(--zui-color-gs-120);--zui-color-text-secondary-line: var(--zui-color-gs-85);--zui-color-text-disabled: var(--zui-color-gs-100)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-text-default: var(--zui-color-gs-50);--zui-color-text-on-dark-bg: var(--zui-color-gs-10);--zui-color-text-primary: var(--zui-color-pb-70);--zui-color-text-header: var(--zui-color-gs-10);--zui-color-text-secondary-line: var(--zui-color-gs-70);--zui-color-text-disabled: var(--zui-color-gs-50);--zui-color-text-divider: var(--zui-color-gs-70)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const datePickerInputPartStyles = css `
  ${unsafeCSS(styles)}
`;
class DatePickerInputPartFocusPreviousEvent extends GetSharedEventClass('date-picker-input-part-focus-previous') {
}
class DatePickerInputPartFocusNextEvent extends GetSharedEventClass('date-picker-input-part-focus-next') {
}
class DatePickerInputPartChangedEvent extends GetSharedEventClass('date-picker-input-part-value-changed') {
}
/**
 * The date picker input part is used inside the zui-date-picker-input for displaying the day, month or year.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-date-picker-input-part
 *  input-part-type="day"
 *  literals="."
 *  max="31"
 *  min="1"
 *  value="5"
 * >
 * </zui-date-picker-input-part>
 * ```
 *
 * @fires {GetZuiEvent<DatePickerInputPart>, 'ChangedEvent'} zui-date-picker-input-part-value-changed - Fired when the value of the input part changes
 * @fires {GetZuiEvent<DatePickerInputPart>, 'FocusPreviousEvent'} zui-date-picker-input-part-focus-previous - Fired when the user presses the left arrow key
 * @fires {GetZuiEvent<DatePickerInputPart>, 'FocusNextEvent'} zui-date-picker-input-part-focus-next - Fired when the user presses the right arrow key
 * @cssprop --zui-date-picker-input-part-placeholder-width - override input placeholder width
 */
let DatePickerInputPart = class DatePickerInputPart extends DisabledMixin(DelegateFocusMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.ChangedEvent = DatePickerInputPartChangedEvent;
        this.FocusPreviousEvent = DatePickerInputPartFocusPreviousEvent;
        this.FocusNextEvent = DatePickerInputPartFocusNextEvent;
        /* eslint-disable @typescript-eslint/naming-convention */
    }
}))) {
    constructor() {
        super(...arguments);
        /**
         * literals
         */
        this.literals = [];
        /**
         * readonly
         */
        this.readonly = false;
        this._showPlaceholder = true;
        this._internalValue = undefined;
    }
    /**
     * value
     *
     * @returns number | undefined
     */
    get value() {
        return this._internalValue;
    }
    set value(value) {
        const oldVal = this._internalValue;
        this._internalValue = value;
        this._showPlaceholder = !isDefined(value);
        this.requestUpdate('value', oldVal);
    }
    get _maxCharacterLength() {
        return this.max.toString().length;
    }
    get _paddedValue() {
        return this.value !== undefined ? addLeadingZeros(this.value, this._maxCharacterLength) : '';
    }
    _handleInputEvent(event) {
        // "Pushes out" digits that exceed any possible max length
        // e.g. max-length=31, value=13 -> inputing "2" in front pushes out the last digit resulting in "21"
        if (event.target.value.length > this._maxCharacterLength) {
            event.target.value = event.target.value.slice(0, this._maxCharacterLength);
        }
        // if the current value has a length of 0, then show the placeholder
        this._showPlaceholder = event.target.value.length === 0;
        // If inserted value equals max allowed digits focus next part
        if (event.inputType === 'insertText' && event.target.value.length === this._maxCharacterLength) {
            this._emitDatePickerInputPartFocusNextEvent();
        }
    }
    _handleUserCommitValue(event) {
        // this function is called on blurEvents and on keydown-Enter, because we cannot use the change-Event as in other components
        // because we meddle with the input.target.value in _handleInputEvent, which does not trigger ChangeEvents in WebKit (rightfully!)
        var _a;
        // the function reflects the value from the input to the component's value and thus might trigger updates
        // because custom Event emit is only done on actual userInput
        const oldValue = this.value;
        const inputValue = event.target.value;
        if (inputValue === '') {
            // part has been reset
            this.value = undefined;
        }
        else {
            // otherwise constrain value
            this.value = constrainInputValue(Number(inputValue), this.min, this.max);
            // this is only necessary if the user enters the same single-digit input as before (no change for `value` -> no re-render -> manually render padding)
            this._inputElement.value = this._paddedValue;
        }
        if (oldValue !== this.value) {
            this._emitDatePickerInputPartValueChangedEvent({
                type: this.inputPartType,
                value: (_a = this.value) !== null && _a !== void 0 ? _a : null,
            });
        }
    }
    _handleBackspaceKeydownEvent(event) {
        if (event.target.value === '') {
            event.preventDefault();
            this._emitDatePickerInputPartFocusPreviousEvent();
        }
    }
    /**
     *  if input is localized literal, emit focus next event
     *
     * @param event KeyboardEvent
     */
    _handleLiteralInputEvent(event) {
        event.preventDefault();
        if (event.target.value !== '') {
            this._emitDatePickerInputPartFocusNextEvent();
        }
    }
    _handleInputFocusEvent() {
        this._inputElement.select();
    }
    /**
     * Firefox allows alphabetic characters in the number input.
     * We prevent the input on those characters and only allow numbers, valid/standard literals and navigation keys.
     *
     * @param event KeyboardEvent
     */
    _handleInputKeydownEvent(event) {
        if (this.literals.includes(event.key)) {
            this._handleLiteralInputEvent(event);
            return;
        }
        if (!isAllowedNumericKeyboardInput(event.key)) {
            event.preventDefault();
            return;
        }
        switch (event.key) {
            // mimics inputs change event by handling Blur and 'Enter' press
            case 'NumpadEnter':
            case 'Enter':
                this._handleUserCommitValue(event);
                break;
            case '0':
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
            case 'ArrowDown':
            case 'ArrowLeft':
            case 'ArrowRight':
            case 'ArrowUp':
            case 'Delete':
            case 'End':
            case 'Home':
            case 'Tab':
                break;
            case 'Backspace':
                this._handleBackspaceKeydownEvent(event);
                break;
            default:
                // exhaustiveness checking
                // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
                const t = event.key;
                break;
        }
    }
    render() {
        return html `
      <input
        ?disabled="${this.disabled}"
        ?readonly="${this.readonly}"
        class="${classMap({
            'show-placeholder': this._showPlaceholder,
        })}"
        .value="${this._paddedValue}"
        max="${this.max}"
        min="${this.min}"
        placeholder="${ifDefined(this.placeholder)}"
        type="number"
        @blur="${this._handleUserCommitValue}"
        @focus=${this._handleInputFocusEvent}
        @input="${this._handleInputEvent}"
        @keydown="${this._handleInputKeydownEvent}"
      />
    `;
    }
};
DatePickerInputPart.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    datePickerInputPartStyles,
];
__decorate([
    property({ reflect: true, type: String, attribute: 'input-part-type' })
], DatePickerInputPart.prototype, "inputPartType", void 0);
__decorate([
    property({ reflect: true, type: String, converter: literalConverter })
], DatePickerInputPart.prototype, "literals", void 0);
__decorate([
    property({ reflect: true, converter: numberUndefinedConverter })
], DatePickerInputPart.prototype, "max", void 0);
__decorate([
    property({ reflect: true, converter: numberUndefinedConverter })
], DatePickerInputPart.prototype, "min", void 0);
__decorate([
    property({ reflect: true, converter: stringUndefinedConverter })
], DatePickerInputPart.prototype, "placeholder", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], DatePickerInputPart.prototype, "readonly", void 0);
__decorate([
    property({ reflect: true, converter: numberUndefinedConverter })
], DatePickerInputPart.prototype, "value", null);
__decorate([
    query('input')
], DatePickerInputPart.prototype, "_inputElement", void 0);
__decorate([
    state()
], DatePickerInputPart.prototype, "_showPlaceholder", void 0);
DatePickerInputPart = __decorate([
    customElement('zui-date-picker-input-part')
], DatePickerInputPart);
export { DatePickerInputPart };
