var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, nothing, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { hostStyles } from '../../../host.styles.js';
import { DateTime } from 'luxon';
import { BaseElement } from '../../base/base-element.class.js';
import { getDefaultLocale, isoDateConverter } from '../utils/date-picker.utils.js';
import { GetSharedEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { traverseDOMSiblingsByStepAndDirection } from '../../../utils/dom.utils.js';
// deps
import '../date-picker-input-part-month/date-picker-input-part-month.component.js';
import '../date-picker-input-part/date-picker-input-part.component.js';
import '../../interactive-icon/interactive-icon.component.js';
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const styles = ":host{--zui-month-picker-input-month-placeholder-width: calc(var(--zui-gu) * 4.65);--zui-month-picker-input-year-placeholder-width: calc(var(--zui-gu) * 4.75);--zui-month-picker-input-width: calc(var(--zui-gu) * 15);--zui-month-picker-input-parts-gap-width: calc(var(--zui-gu) * 0.5);---zui-month-picker-input-year-input-width: calc(var(--zui-gu) * 3.75);display:flex;align-items:center;width:var(--zui-month-picker-input-width);padding-bottom:calc(var(--zui-gu) * 0.125);border-bottom:1px solid var(--zui-color-input-bottom-line-default-enabled)}:host(:hover){border-bottom-color:var(--zui-color-input-bottom-line-default-hover)}:host(:focus-within){border-bottom-color:var(--zui-color-input-bottom-line-default-focused)}:host([invalid]),:host([invalid]):host(:focus-within),:host([invalid]:hover){border-bottom-color:var(--zui-color-error)}:host(:hover),:host(:focus-within),:host([invalid]){padding-bottom:0;border-bottom-width:2px}:host([readonly]){padding-bottom:2px;border-bottom-width:0}:host([disabled]){opacity:var(--zui-disabled-opacity);pointer-events:none}.input-parts{display:flex;align-items:center;width:100%;min-width:0;line-height:calc(var(--zui-gu) * 3.75)}.input-parts *:not(:last-child):is(zui-date-picker-input-part,zui-date-picker-input-part-month){margin-right:var(--zui-month-picker-input-parts-gap-width)}.input-parts zui-date-picker-input-part-month{--zui-date-picker-input-part-month-placeholder-width: var(--zui-month-picker-input-month-placeholder-width)}.input-parts zui-date-picker-input-part[input-part-type=year]{---zui-date-picker-input-part-input-width: var(---zui-month-picker-input-year-input-width);--zui-date-picker-input-part-placeholder-width: var(--zui-month-picker-input-year-placeholder-width)}"
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-help: var(--zui-color-gs-80);--zui-color-info: var(--zui-color-pb-100);--zui-color-success: var(--zui-color-sc-g-110);--zui-color-warning: var(--zui-color-sc-y-100);--zui-color-error: var(--zui-color-sc-r-100);--zui-color-placeholder-input: var(--zui-color-gs-80);--zui-color-input-bottom-line-default-enabled: var(--zui-color-gs-60);--zui-color-input-bottom-line-default-hover: var(--zui-color-gs-120);--zui-color-input-bottom-line-default-focused: var(--zui-color-gs-100);--zui-color-input-bottom-line-default-pressed: var(--zui-color-gs-120)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-help: var(--zui-color-gs-80);--zui-color-info: var(--zui-color-pb-90);--zui-color-success: var(--zui-color-sc-g-110);--zui-color-warning: var(--zui-color-sc-y-100);--zui-color-error: var(--zui-color-sc-r-90);--zui-color-placeholder-input: var(--zui-color-gs-80);--zui-color-input-bottom-line-default-enabled: var(--zui-color-gs-120);--zui-color-input-bottom-line-default-hover: var(--zui-color-gs-120);--zui-color-input-bottom-line-default-focused: var(--zui-color-gs-120);--zui-color-input-bottom-line-default-pressed: var(--zui-color-gs-120)}"
const themeDark = ""
const themeLight = ""
// icons
import '@zeiss/zui-icons/dist/components/generated/common-time-date-calender.component.js';
const monthPickerInputStyles = css `
  ${unsafeCSS(styles)}
`;
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
class MonthPickerInputCalendarSelectedEvent extends GetSharedEventClass('month-picker-input-calendar-selected') {
}
class MonthPickerInputChangedEvent extends GetSharedEventClass('month-picker-input-changed') {
}
class MonthPickerInputFocusedEvent extends GetSharedEventClass('month-picker-input-focused') {
}
/**
 * The month picker input shows an input with an interactive calendar icon. The input allows to select a month and year.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-month-picker-input
 *   locale="en-US"
 *   placeholder-month="MMM"
 *   placeholder-year="YYYY"
 * >
 * </zui-month-picker-input>
 * ```
 *
 * @fires {GetZuiEvent<MonthPickerInput, 'CalendarSelectedEvent'>} zui-month-picker-input-calendar-selected - emits after the calendar icon has been pressed.
 * @fires {GetZuiEvent<MonthPickerInput, 'ChangedEvent'>} zui-month-picker-input-changed - emits the input date after is has been changed and eventual errors with it.
 * @fires {GetZuiEvent<MonthPickerInput, 'FocusedEvent'>} zui-month-picker-input-focused - emits if any part of the input has been focused.
 *
 * @cssprop --zui-month-picker-input-width - size of the input
 * @cssprop --zui-month-picker-input-month-placeholder-width - override default month input placeholder width that is optimized for MM
 * @cssprop --zui-month-picker-input-year-placeholder-width - override default year input placeholder width that is optimized for YYYY
 * @cssprop --zui-month-picker-input-parts-gap-width - sets some margin between in between the input parts
 */
let MonthPickerInput = class MonthPickerInput extends DisabledMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.CalendarSelectedEvent = MonthPickerInputCalendarSelectedEvent;
        this.ChangedEvent = MonthPickerInputChangedEvent;
        this.FocusedEvent = MonthPickerInputFocusedEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
})) {
    constructor() {
        super(...arguments);
        /**
         * whether the calendar is opened or not
         */
        this.calendarOpened = false;
        /**
         * input part month value that is used when no selected value is present
         * it is a luxon like month so 1-12 are valid values
         */
        this.inputPartMonthValue = null;
        /**
         * input part year value that is used when no selected value is present
         */
        this.inputPartYearValue = null;
        /**
         * whether the input is valid or not
         */
        this.invalid = false;
        /**
         * locale
         */
        this.locale = getDefaultLocale();
        /**
         * placeholder month
         */
        this.placeholderMonth = 'MMM';
        /**
         * placeholder year
         */
        this.placeholderYear = 'YYYY';
        /**
         * readonly
         */
        this.readonly = false;
        /**
         * The Date that is currently shown as seleted.
         */
        this.selectedDate = null;
        this._isValidNextElement = (element) => element.tagName.toLowerCase() === 'zui-date-picker-input-part' ||
            element.tagName.toLowerCase() === 'zui-date-picker-input-part-month';
    }
    get _selectedDateDT() {
        return this.selectedDate && DateTime.fromJSDate(this.selectedDate).isValid
            ? DateTime.fromJSDate(this.selectedDate)
            : undefined;
    }
    get _orderedInputParts() {
        // depending on the current locale, we want to have the order of year-month or month-year
        return DateTime.now().toLocaleParts({ locale: this.locale })[0].type === 'year'
            ? ['year', 'month']
            : ['month', 'year'];
    }
    // Luxon months are 1-12 but Date and in order to work with it in arrays we must substract 1
    get _currentMonthIndex() {
        var _a, _b;
        const dateTimeMonth = (_b = (_a = this._selectedDateDT) === null || _a === void 0 ? void 0 : _a.month) !== null && _b !== void 0 ? _b : this.inputPartMonthValue;
        return dateTimeMonth === null ? null : dateTimeMonth - 1;
    }
    _handleInputPartFocusNextEvent(event) {
        var _a;
        (_a = traverseDOMSiblingsByStepAndDirection(event.target, 'next', 1, this._isValidNextElement)) === null || _a === void 0 ? void 0 : _a.focus();
    }
    _handleInputPartFocusPreviousEvent(event) {
        var _a;
        (_a = traverseDOMSiblingsByStepAndDirection(event.target, 'previous', 1, this._isValidNextElement)) === null || _a === void 0 ? void 0 : _a.focus();
    }
    _handleDatePickerInputPartFocusEvent() {
        this._emitMonthPickerInputFocusedEvent();
    }
    _handleDatePickerInputPartValueChangedEvent({ detail, }) {
        var _a;
        // FIXME: The type of value should probably be undefined instead of null
        // The type of the inputPart values is undefined and the value of the input-part component is also null.
        const { value } = detail;
        this.inputPartYearValue = value;
        this._handleDateTimeValueChange({ year: (_a = this.inputPartYearValue) !== null && _a !== void 0 ? _a : undefined });
    }
    _handleDatePickerInputPartMonthValueChangedEvent({ detail, }) {
        var _a;
        const { value } = detail;
        // Luxon months are not 0 indexed but 1-12.
        this.inputPartMonthValue = value === null ? null : value + 1;
        this._handleDateTimeValueChange({ month: (_a = this.inputPartMonthValue) !== null && _a !== void 0 ? _a : undefined });
    }
    _handleDateTimeValueChange(dateTimeValueChange) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        // default DateObject adjusted for the changed part
        const dateTimeValue = Object.assign({ month: (_c = (_b = (_a = this._selectedDateDT) === null || _a === void 0 ? void 0 : _a.month) !== null && _b !== void 0 ? _b : this.inputPartMonthValue) !== null && _c !== void 0 ? _c : undefined, year: (_f = (_e = (_d = this._selectedDateDT) === null || _d === void 0 ? void 0 : _d.year) !== null && _e !== void 0 ? _e : this.inputPartYearValue) !== null && _f !== void 0 ? _f : undefined }, dateTimeValueChange);
        const isPartMissing = Object.values(dateTimeValue).includes(undefined);
        const isValid = !isPartMissing && DateTime.fromObject(Object.assign({}, dateTimeValue)).isValid;
        this.selectedDate = isValid ? DateTime.fromObject(Object.assign({}, dateTimeValue)).toJSDate() : null;
        this._emitMonthPickerInputChangedEvent({
            value: this.selectedDate,
            error: !isValid ? { month: (_g = dateTimeValue.month) !== null && _g !== void 0 ? _g : null, year: (_h = dateTimeValue.year) !== null && _h !== void 0 ? _h : null } : undefined,
        });
    }
    _handleShowCalendar() {
        if (!this.readonly) {
            this._emitMonthPickerInputCalendarSelectedEvent();
        }
    }
    /**
     *
     * @param locales locale that the months should be localized by
     * @param format specifies if the full or abreviated name of the months should be returned
     * @returns array of the years 12 months in a localized and specified format
     */
    _getAllMonths(locales, format = 'short') {
        const currentYear = new Date().getFullYear();
        // TODO: Only supports the Gregorian calendar system.
        // Might want to made adjustable for other calendar systems: https://moment.github.io/luxon/#/calendars
        const monthIndexes = [...Array(12).keys()];
        const formatter = new Intl.DateTimeFormat(locales, {
            month: format,
        });
        const getMonthName = (monthIndex) => formatter.format(new Date(currentYear, monthIndex));
        return monthIndexes.map(getMonthName);
    }
    // month-picker-input does not emit any InputEvents and thus it has to swallow deeper events
    _handleInput(event) {
        event.stopPropagation();
    }
    render() {
        return html `
      <div class="input-parts">
        ${this.readonly && !this.selectedDate
            ? html `<span>–</span>`
            : repeat(this._orderedInputParts, (type) => {
                var _a, _b;
                switch (type) {
                    case 'month':
                        return html `
                    <zui-date-picker-input-part-month
                      ?disabled=${this.disabled}
                      ?readonly=${this.readonly}
                      months=${this._getAllMonths(this.locale).join(',')}
                      placeholder=${this.placeholderMonth}
                      .value=${this._currentMonthIndex}
                      @input="${this._handleInput}"
                      @zui-date-picker-input-part-month-changed=${this._handleDatePickerInputPartMonthValueChangedEvent}
                      @zui-date-picker-input-part-month-focus-next=${this._handleInputPartFocusNextEvent}
                      @zui-date-picker-input-part-month-focus-previous=${this._handleInputPartFocusPreviousEvent}
                      @focus=${this._handleDatePickerInputPartFocusEvent}
                    ></zui-date-picker-input-part-month>
                  `;
                    case 'year':
                        return html `<zui-date-picker-input-part
                    ?disabled=${this.disabled}
                    ?readonly=${this.readonly}
                    input-part-type="year"
                    max="9999"
                    min="1"
                    placeholder=${this.placeholderYear}
                    .value=${(_b = (_a = this._selectedDateDT) === null || _a === void 0 ? void 0 : _a.year) !== null && _b !== void 0 ? _b : this.inputPartYearValue}
                    @zui-date-picker-input-part-value-changed=${this._handleDatePickerInputPartValueChangedEvent}
                    @zui-date-picker-input-part-focus-next=${this._handleInputPartFocusNextEvent}
                    @zui-date-picker-input-part-focus-previous=${this._handleInputPartFocusPreviousEvent}
                    @focus=${this._handleDatePickerInputPartFocusEvent}
                  ></zui-date-picker-input-part>`;
                    default:
                        return nothing;
                }
            })}
      </div>
      <zui-interactive-icon
        ?disabled=${this.disabled || this.readonly}
        emphasis="default"
        @click=${this._handleShowCalendar}
      >
        <zui-icon-common-time-date-calender size="s16"></zui-icon-common-time-date-calender>
      </zui-interactive-icon>
    `;
    }
};
MonthPickerInput.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    monthPickerInputStyles,
];
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'calendar-opened' })
], MonthPickerInput.prototype, "calendarOpened", void 0);
__decorate([
    property({ reflect: true, type: Number, attribute: 'input-part-month-value' })
], MonthPickerInput.prototype, "inputPartMonthValue", void 0);
__decorate([
    property({ reflect: true, type: Number, attribute: 'input-part-year-value' })
], MonthPickerInput.prototype, "inputPartYearValue", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], MonthPickerInput.prototype, "invalid", void 0);
__decorate([
    property({ reflect: true, type: String })
], MonthPickerInput.prototype, "locale", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'placeholder-month' })
], MonthPickerInput.prototype, "placeholderMonth", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'placeholder-year' })
], MonthPickerInput.prototype, "placeholderYear", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], MonthPickerInput.prototype, "readonly", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'selected-date', converter: isoDateConverter })
], MonthPickerInput.prototype, "selectedDate", void 0);
MonthPickerInput = __decorate([
    customElement('zui-month-picker-input')
], MonthPickerInput);
export { MonthPickerInput };
