import { ZuiList, ZuiListItem } from '@zeiss/zui-react';
import type { MenuSettings, MenuType } from 'piral-menu';
import type { FC, ReactNode } from 'react';

export interface ActionsMenuProps {
  type: MenuType;
  children?: ReactNode;
}

export const ActionsMenu: FC<ActionsMenuProps> = ({ children }) => <ZuiList>{children}</ZuiList>;

export interface ActionsMenuItemProps {
  meta?: MenuSettings;
  children?: ReactNode;
}

export const ActionsMenuItem: FC<ActionsMenuItemProps> = ({ children }) => (
  <ZuiListItem>{children}</ZuiListItem>
);
