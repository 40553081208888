var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement } from '../base/base-element.class.js';
import { hostStyles } from '../../host.styles.js';
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const styles = ":host{position:relative;width:fit-content;--zui-avatar-color-background: var(--zui-color-sc-b-110);---zui-avatar-font-padding-top: 0;---zui-avatar-font-padding-bottom: 0}:host(:focus){outline:none}:host([size=\"24\"]){--zui-avatar-diameter: calc(var(--zui-gu) * 3)}:host([size=\"32\"]){--zui-avatar-diameter: calc(var(--zui-gu) * 4)}:host([size=\"40\"]){--zui-avatar-diameter: calc(var(--zui-gu) * 5)}:host([size=\"64\"]){--zui-avatar-diameter: calc(var(--zui-gu) * 8)}:host([size=\"128\"]){--zui-avatar-diameter: calc(var(--zui-gu) * 16)}:host([size=\"24\"]) span{font:var(--zui-typography-label2);text-transform:var(--zui-typography-label2-text-transform);---zui-avatar-font-padding-bottom: 0.5px}:host([size=\"32\"]) span{font:var(--zui-typography-body)}:host([size=\"40\"]) span{font:var(--zui-typography-h4);---zui-avatar-font-padding-top: 1px}:host([size=\"64\"]) span{font:var(--zui-typography-h2)}:host([size=\"128\"]) span{font:var(--zui-typography-h0);---zui-avatar-font-padding-bottom: 2px}div,img{width:var(--zui-avatar-diameter);height:var(--zui-avatar-diameter);border-radius:50%}div{position:relative;background-color:var(--zui-avatar-color-background)}img{object-fit:cover}span{position:absolute;top:50%;left:50%;padding:var(---zui-avatar-font-padding-top) 0 var(---zui-avatar-font-padding-bottom) 0;color:var(--zui-color-text-avatar);text-transform:uppercase;transform:translate(-50%, -52%)}"
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-text-avatar: var(--zui-color-gs-10)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-text-avatar: var(--zui-color-gs-10)}"
const themeLight = ""
const themeDark = ""
const avatarStyles = css `
  ${unsafeCSS(styles)}
`;
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
/**
 * The avatar component displays a circle, with a customized background color
 * and customized centered initials - or with a centered background image in different sizes.
 * If the user specifies an image url, there will be no background colors nor initials.
 *
 * ## Figma
 * - [Web - Component Library](https://www.figma.com/file/z4fyXFOJCpuaNImx3K234n/❖-04-Web---Component-Library---1.4?node-id=284%3A0)
 * - [Styleguide – Web](https://www.figma.com/file/6dkjypErYWQPfuRBD58Aey/%F0%9F%93%96--Styleguide---Web?node-id=4426%3A0)
 *
 * @example
 * HTML:
 *
 * Avatar with background color and initials
 * ```html
 * <zui-avatar initials="zw" size="64" style="--zui-avatar-color-background: var(--zui-color-sc-b-110);"></zui-avatar>
 * ```
 *
 * Avatar with an image
 * ```html
 * <zui-avatar image-url="https://i.postimg.cc/3NG0WwMW/random-Face.jpg" size="32"></zui-avatar>
 * ```
 *
 * Avatar with Skeleton
 * ```html
 * <zui-avatar size="32">
 *    <zui-skeleton-circle slot="skeleton-image-url" style="--zui-skeleton-circle-diameter: 32px"></zui-skeleton-circle>
 * </zui-avatar>
 * ```
 *
 * @cssprop --zui-avatar-color-background - background color of the avatar
 * @cssprop --zui-avatar-diameter - circle diameter
 * @slot skeleton-image-url - This is the slot for adding a skeleton instead of an image url or initials. The Skeleton is only displayed when no image url or initials are set.
 */
let Avatar = class Avatar extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * Sets size of the AvatarComponent
         */
        this.size = '40';
        /**
         * Sets initials of the AvatarComponent
         */
        this.initials = '';
    }
    render() {
        return html `
      <div>
        ${this.imageUrl
            ? html `<img src="${this.imageUrl}" />`
            : this.initials
                ? html `<span>${this.initials}</span>`
                : html `<slot name="skeleton-image-url"></slot>`}
      </div>
    `;
    }
};
Avatar.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    avatarStyles,
];
__decorate([
    property({ reflect: true, type: String })
], Avatar.prototype, "size", void 0);
__decorate([
    property({ reflect: true, type: String })
], Avatar.prototype, "initials", void 0);
__decorate([
    property({ attribute: 'image-url', reflect: true, type: String })
], Avatar.prototype, "imageUrl", void 0);
Avatar = __decorate([
    customElement('zui-avatar')
], Avatar);
export { Avatar };
