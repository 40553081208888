var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
/* eslint-disable @typescript-eslint/ban-ts-comment */
import 'lit';
import '../../utils/util.types.js';
// Keys for localStorage
const ZUI_USAGE_METRICS = 'zui.usageMetrics';
const ZUI_ENABLE_METRICS = 'zui.enableMetrics';
// helpers
const logUsageForPage = (url) => {
    var _a, _b;
    const currentMetrics = (_b = JSON.parse((_a = localStorage.getItem(ZUI_USAGE_METRICS)) !== null && _a !== void 0 ? _a : 'null')) !== null && _b !== void 0 ? _b : {};
    currentMetrics[url] = usageMetricsUrlStack[url] || [];
    localStorage.setItem(ZUI_USAGE_METRICS, JSON.stringify(currentMetrics));
};
const logConnectionForComponentInUrl = (url, compName) => {
    const boundCompStack = usageMetricsUrlStack[url] || [];
    boundCompStack.push(compName);
    usageMetricsUrlStack[url] = boundCompStack;
};
const logDisconnectionForComponentInUrl = (url, compName) => {
    const boundCompStack = usageMetricsUrlStack[url] || [];
    const foundPosition = boundCompStack.indexOf(compName);
    if (foundPosition !== -1) {
        boundCompStack.splice(foundPosition, 1);
    }
    usageMetricsUrlStack[url] = boundCompStack;
};
const usageMetricsUrlStack = {};
let ifDebugEnabled = false;
// this determines, when a page is considered to be stable, i.e. after this any subsequent connections / disconnections are ignored
// eslint-disable-next-line @typescript-eslint/naming-convention
let PAGE_IS_STABLE_TIMEOUT = 5000;
/**
 * Whether metrics is enabled or not is stored in localStorage.
 * This way the flag is preserved over page reloads.
 *
 * @returns true if metrics is enabled
 */
const isMetricsEnabled = () => {
    // potentially, there are browsers that don't support localStorage,
    // so we do this check here.
    // We don't check again in other methods because all other methods are only called | only make sense when
    // devs explicitly enable metrics and therefore have localStorage anyway.
    if (localStorage) {
        const item = localStorage.getItem(ZUI_ENABLE_METRICS);
        if (item) {
            try {
                return JSON.parse(item) === true;
            }
            catch (e) {
                return false;
            }
        }
    }
    return false;
};
// expose some helpers
window['zuiEnableMetrics'] = () => {
    localStorage.setItem(ZUI_ENABLE_METRICS, 'true');
};
window['zuiDisableMetrics'] = () => {
    localStorage.removeItem(ZUI_ENABLE_METRICS);
};
window['zuiCurrentUsage'] = usageMetricsUrlStack;
window['zuiExportUsage'] = () => {
    var _a;
    console.log(JSON.stringify(JSON.parse((_a = localStorage.getItem(ZUI_USAGE_METRICS)) !== null && _a !== void 0 ? _a : 'null'), null, 4));
};
window['zuiEnableDebug'] = () => (ifDebugEnabled = true);
window['zuiSetUsageStableTimeout'] = (timeout) => {
    PAGE_IS_STABLE_TIMEOUT = timeout;
};
let lastPage = '';
const waitForCurrentLoad = (currentPage) => {
    if (lastPage !== currentPage) {
        // new page load, trigger stabilizer...
        lastPage = currentPage;
        ifDebugEnabled && console.log('Watching for page: %s', currentPage);
        setTimeout(() => {
            var _a;
            // are we still on the cu
            const pageAfterTimeout = window.location.href;
            if (pageAfterTimeout === lastPage) {
                ifDebugEnabled &&
                    console.log('Yes! Stable usage is: %o with %d elements', usageMetricsUrlStack[pageAfterTimeout], (_a = usageMetricsUrlStack[pageAfterTimeout]) === null || _a === void 0 ? void 0 : _a.length);
                // we are still on the same page log usages!
                logUsageForPage(pageAfterTimeout);
            }
            // regardless ditch cache
            usageMetricsUrlStack[pageAfterTimeout] = [];
        }, PAGE_IS_STABLE_TIMEOUT);
    }
};
// eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const UsageMixin = (superClass
// { defaultSize }: { defaultSize: string } = { defaultSize: 's16' }
) => {
    var _InstrumentedClass_isMetricsEnabled;
    class InstrumentedClass extends superClass {
        constructor(...args) {
            super(...args);
            _InstrumentedClass_isMetricsEnabled.set(this, isMetricsEnabled());
        }
        connectedCallback() {
            super.connectedCallback();
            if (__classPrivateFieldGet(this, _InstrumentedClass_isMetricsEnabled, "f")) {
                const currentPage = window.location.href;
                // console.log('Me %s is connected for URL %s!', this.tagName.toLowerCase(), currentPage);
                logConnectionForComponentInUrl(window.location.href, this.tagName.toLowerCase());
                waitForCurrentLoad(currentPage);
            }
        }
        disconnectedCallback() {
            super.disconnectedCallback();
            if (__classPrivateFieldGet(this, _InstrumentedClass_isMetricsEnabled, "f")) {
                // console.log('Me %s is disconnected for URL %s!', this.tagName.toLowerCase(), window.location.href);
                logDisconnectionForComponentInUrl(window.location.href, this.tagName.toLowerCase());
            }
        }
    }
    _InstrumentedClass_isMetricsEnabled = new WeakMap();
    return InstrumentedClass;
};
