import type { Dict, PageComponentProps } from 'piral-core';
import type { ComponentType } from 'react';

import { DefaultLayout } from './DefaultLayout';
import { FullScreenLayout } from './FullScreenLayout';
import { LegalDocumentLayout } from './LegalDocumentLayout';

export function createLayouts(): Dict<ComponentType<PageComponentProps>> {
  return {
    default: DefaultLayout,
    fullscreen: FullScreenLayout,
    legal: LegalDocumentLayout,
  };
}
