import { type Subscription, switchMap, timer } from 'rxjs';

import { fetchAndStoreSessionData } from '../auth';
import type { RuntimeConfig } from '../types';

let subscription: Subscription | null = null;

export function startSessionRefreshStream(
  config: RuntimeConfig,
  sessionExpirationInSeconds: number
): void {
  if (subscription) {
    return;
  }

  const sessionLifeTimeInMillis = sessionExpirationInSeconds * 1000;
  const refreshInterval = sessionLifeTimeInMillis / 2;
  subscription = timer(refreshInterval, refreshInterval)
    .pipe(switchMap(() => fetchAndStoreSessionData(config)))
    .subscribe();
}

export function cancelSessionRefreshStream(): void {
  subscription?.unsubscribe();
  subscription = null;
}
