import type { i18n } from 'i18next';
import type { PiralPlugin } from 'piral-core';
import { distinctUntilChanged, type Observable, ReplaySubject } from 'rxjs';

import { setupI18next } from '../../intl/i18n';
import { getLegalLanguage } from '../../intl/legal-language';
import type { HdpApp } from '../../types';
import { StorageKey } from '../../utils/storage';

/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'piral-core/lib/types/custom' {
  interface PiletCustomApi extends PiletIntlApi {}
}

export interface LanguageLoader {
  /**
   * I18n callback to load the translation on locale change.
   * @param language locale to load the translation file
   */
  (language: string): Promise<Record<string, string>>;
}

export interface PiletIntlApi {
  /**
   * Provides an event stream which emits everytime the browser locale changes.
   * @deprecated Use `userLocale$` instead.
   */
  browserLocale$: Observable<string>;
  /**
   * Provides an event stream which emits everytime the user changes his language selection.
   */
  userLocale$: Observable<string>;
  /**
   * Provides an array of all supported locales of the HDP App Shell.
   */
  getAvailableLocales(): ReadonlyArray<string>;
  /**
   * Returns the locale of the browser.
   * @deprecated Use `getUserLocale()` instead.
   * @return locale of the browser
   */
  getBrowserLocale(): string;
  /**
   * Returns the locale of the user if configured, else the browser language is returned.
   * @return locale according to the user's preferences
   */
  getUserLocale(): string;
  /**
   * Returns the default language which can be used as a fallback.
   */
  getDefaultLocale(): string;
  /**
   * API to integrate the `i18next` hook.
   * @param loader Callback which is invoked on language switch
   */
  setLanguageProvider(loader: LanguageLoader): void;
  /**
   * Returns the i18n as an Angular module to provide the module in the Angular injection context.
   * @return i18n instance
   */
  getLanguageProvider(): i18n;
  /**
   * Returns the locale of the user which is configured in ZEISS ID.
   * If the country parameter is provided the legal language for this country is returned.
   * @param {string} country - Provide this parameter to receive legal language for a specific country.
   * @return the legal locale for the provided context
   */
  getLegalLanguage(country?: string): string;
}

export function createIntlApi(app: HdpApp): PiralPlugin<PiletIntlApi> {
  const browserLocale$ = new ReplaySubject<string>(1);

  browserLocale$.subscribe((lang) => {
    document.documentElement.setAttribute('lang', lang);
  });

  browserLocale$.next(app.getUserLocale());

  window.addEventListener('languagechange', () => {
    browserLocale$.next(app.getUserLocale());
  });

  window.addEventListener('languageChangeFromSettings', (e) => {
    const language = (e as CustomEvent).detail;
    browserLocale$.next(language);
  });

  const handleLanguageUpdate = (event: StorageEvent) => {
    if (event.key === StorageKey.BROWSER_LANGUAGE && event.newValue !== event.oldValue) {
      browserLocale$.next(app.getUserLocale());
    }
  };

  window.addEventListener('storage', handleLanguageUpdate);

  const bl = browserLocale$.pipe(distinctUntilChanged());
  const providers = [app.languageProvider];

  bl.subscribe(async (locale) => {
    await Promise.all(providers.map((provider) => provider.loadLanguages(locale)));
    await Promise.all(providers.map((provider) => provider.changeLanguage(locale)));
  });

  return () => () => {
    let { languageProvider } = app;

    return {
      browserLocale$: bl,
      userLocale$: bl,
      getBrowserLocale: app.getBrowserLocale,
      getUserLocale: app.getUserLocale,
      getAvailableLocales() {
        return [...app.config.availableLocales];
      },
      getDefaultLocale() {
        return app.locale;
      },
      setLanguageProvider(loader) {
        languageProvider = setupI18next(loader, app.languageProvider.language);
        providers.unshift(languageProvider);
      },
      getLanguageProvider() {
        return languageProvider;
      },
      getLegalLanguage(country?: string) {
        return getLegalLanguage(
          country ?? app.country,
          app.config.legalLanguages,
          app.getUserLocale()
        );
      },
    };
  };
}
