var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var TextfieldTimePicker_1;
import { css, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin.js';
import '../../../mixins/form-participation/form-participation.types.js';
import { getDefaultLocale, isoDateConverter } from '../../date-picker/utils/date-picker.utils.js';
import { diffTime, getDayTime, getUpdatedDate, hasDayTime, timePickerInputValueConverter, } from '../utils/time-picker.utils.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { generateUid, getContentsFromPortal } from '../../../utils/portal.utils.js';
import '@popperjs/core/lib/enums.js';
import { DateTime } from 'luxon';
import { isDefined } from '../../../utils/component.utils.js';
import '../../../types.js';
import { FormValidationMixin } from '../../../mixins/form-participation/form-validation.mixin.js';
import '../../../directives/overlay/overlay.directive.js';
import '../time-picker/time-picker.component.js';
import '../time-picker-day-time-toggle/time-picker-day-time-toggle.component.js';
import { TimePickerInput } from '../time-picker-input/time-picker-input.component.js';
import '../../popover/popover.component.js';
import '../../tooltip/tooltip.component.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{--zui-textfield-time-picker-input-hour-placeholder-width: calc(var(--zui-gu) * 2.5);--zui-textfield-time-picker-input-minute-placeholder-width: calc(var(--zui-gu) * 3);--zui-textfield-time-picker-input-width: 100%;width:var(--zui-textfield-time-picker-input-width)}zui-time-picker-input{--zui-time-picker-input-hour-placeholder-width: var(--zui-textfield-time-picker-input-hour-placeholder-width);--zui-time-picker-input-minute-placeholder-width: var(--zui-textfield-time-picker-input-minute-placeholder-width);--zui-time-picker-input-width: var(--zui-textfield-time-picker-input-width)}"
import { GetNotificationEventClass, MimickedChangeEvent, MimickedInputEvent, } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { ValidationStyleMixin } from '../../../mixins/validation-style/validation-style.mixin.js';
import '../../../contracts/validation-style.contract.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
// deps
import '../../../directives/validation-message/validation-message.directive.js';
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const textfieldTimePickerComponentStyles = css `
  ${unsafeCSS(styles)}
`;
class TextfieldTimePickerInputChangedEvent extends GetNotificationEventClass('textfield-time-picker-input-changed') {
}
/* eslint-disable jsdoc/require-property-description */
/**
 * The `zui-textfield-time-picker` is a form enabled component. It uses the `zui-time-picker-input` for entering hour and minute values and when in `h12` mode the day time.
 * The `zui-time-picker` is shown on the `zui-time-picker-input` icon click and can also be used for changing the time.
 * When no `locale` is given it uses the browser locale. When no `hour-cycle` is given the locale based `hour-cycle` is used.
 * It is possible to set a min/max date time to show error messages accordingly when the entered time is lower or greater.
 *
 * @example
 * html```
 * <zui-textfield-time-picker></zui-textfield-time-picker>
 * ```
 *
 * @fires {GetZuiEvent<TextfieldTimePicker, 'InputChangedEvent'>} zui-textfield-time-picker-input-changed - event is emitted when the input value has changed
 * @fires change - the `change` event is emitted on change
 * @fires input - the `input` event is emitted on input
 *
 * @cssprop --zui-textfield-time-picker-input-hour-placeholder-width - width of the hour input when no value is present - default: 20px
 * @cssprop --zui-textfield-time-picker-input-minute-placeholder-width - width of the minute input when no value is present - default: 24px
 * @cssprop --zui-textfield-time-picker-input-width - width of the input - default: 100%
 *
 * @property { "line-only" | "static-text" | "static-tooltip" | "cursor-anchored-tooltip" | "component-anchored-tooltip"} [validationMessageStyle=static-tooltip]
 */
let TextfieldTimePicker = TextfieldTimePicker_1 = 
/* eslint-enable jsdoc/require-property-description */
class TextfieldTimePicker extends ValidationStyleMixin(FormValidationMixin(FormDataHandlingMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.InputChangedEvent = TextfieldTimePickerInputChangedEvent;
        this.InputEvent = MimickedInputEvent;
        this.ChangeEvent = MimickedChangeEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}))), {
    supportedValidationMessageStyles: [
        'static-tooltip',
        'static-text',
        'line-only',
        'cursor-anchored-tooltip',
        'component-anchored-tooltip',
    ],
    defaultValidationMessageStyle: 'static-tooltip',
}) {
    constructor() {
        super();
        /* eslint-enable @typescript-eslint/naming-convention */
        /**
         * the default value is used for the time picker popover when there is no value or just parts of the `zui-time-picker-input`.
         */
        this.defaultValue = DateTime.now().set({ second: 0, millisecond: 0 }).toJSDate();
        /**
         * Full locale code as defined in RFC 5646/BCP 47,
         * i.e. "en-US".
         *
         * By default, the first full locale in [navigator.languages](https://developer.mozilla.org/en-US/docs/Web/API/Navigator/languages) is used.
         */
        this.locale = getDefaultLocale();
        /**
         * placeholder day time
         */
        this.placeholderDayTime = 'AM';
        /**
         * placeholder hour
         */
        this.placeholderHour = 'HH';
        /**
         * placeholder minute
         */
        this.placeholderMinute = 'MM';
        /**
         * required
         */
        this.required = false;
        /**
         * selected value
         */
        this.value = null;
        this._showTimePicker = false;
        this._userChange = false;
        this._timePickerPortal = `time-picker-${generateUid()}`;
        this._textfieldTimePickerResizeObserver = new ResizeObserver(() => {
            requestAnimationFrame(() => {
                var _a;
                (_a = this._textfieldTimePickerOverlayRef) === null || _a === void 0 ? void 0 : _a.forcePositioning();
            });
        });
        // todo: this should be removed when a reusable solution has been implemented
        // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
        this._handleOutsideClick = (event) => {
            if (!this._showTimePicker) {
                return;
            }
            const isInsideClick = this.isSameNode(event.target) && event.composedPath().some((path) => path instanceof TimePickerInput);
            const isTimePickerPortal = event.target.getAttribute('name') === this._timePickerPortal;
            if (!isInsideClick && !isTimePickerPortal) {
                this._showTimePicker = false;
            }
        };
        this._maxValidator = () => isDefined(this._maxValueDT) && isDefined(this._valueDT) ? diffTime(this._valueDT, this._maxValueDT) >= 0 : true;
        this._minValidator = () => isDefined(this._minValueDT) && isDefined(this._valueDT) ? diffTime(this._valueDT, this._minValueDT) <= 0 : true;
        this._offset = () => [0, 8];
        this._requiredValidator = () => !this.required || isDefined(this.value);
        this._timePickerPositionReference = () => this._timePickerInputRef;
        this._messageAnchorReferenceCallback = () => this._timePickerInputRef;
        // setup form integration
        this.addValidator({ type: 'rangeOverflow', validator: this._maxValidator, validatesOnProperties: ['max'] });
        this.addValidator({ type: 'rangeUnderflow', validator: this._minValidator, validatesOnProperties: ['min'] });
        this.addValidator({
            type: 'valueMissing',
            validator: this._requiredValidator,
            validatesOnProperties: ['required'],
        });
        this.setDefaultValidityMessages({
            rangeOverflow: TextfieldTimePicker_1.MAX_ERROR_MESSAGE,
            rangeUnderflow: TextfieldTimePicker_1.MIN_ERROR_MESSAGE,
            valueMissing: TextfieldTimePicker_1.REQUIRED_ERROR_MESSAGE,
        });
    }
    get _defaultValueDT() {
        return DateTime.fromJSDate(this.defaultValue);
    }
    /**
     * hour cycle
     *
     * @returns {'h12' | 'h23'} locale dependent or overridden hour cycle
     */
    get hourCycle() {
        if (!this._internalHourCycle) {
            return hasDayTime(this.locale) ? 'h12' : 'h23';
        }
        return this._internalHourCycle;
    }
    set hourCycle(value) {
        const oldValue = this._internalHourCycle;
        this._internalHourCycle = value;
        this.requestUpdate('hourCycle', oldValue);
    }
    get _maxValueDT() {
        return this.max ? DateTime.fromJSDate(this.max) : undefined;
    }
    get _minValueDT() {
        return this.min ? DateTime.fromJSDate(this.min) : undefined;
    }
    get _valueDT() {
        return this.value ? DateTime.fromJSDate(this.value) : undefined;
    }
    get _currentValueDT() {
        var _a;
        return (_a = this._valueDT) !== null && _a !== void 0 ? _a : this._defaultValueDT;
    }
    get _dayTime() {
        return this._is12HourFormat ? getDayTime(this._currentValueDT, this.hourCycle) : undefined;
    }
    get _formattedValue() {
        if (this._valueDT) {
            return this._valueDT.toFormat(this._timeFormat);
        }
        return timePickerInputValueConverter.toAttribute(this._timePickerInputValue);
    }
    get _is12HourFormat() {
        return this.hourCycle === 'h12';
    }
    get _timeFormat() {
        return this.hourCycle === 'h12' ? TextfieldTimePicker_1.FORMAT_TIME_H12 : TextfieldTimePicker_1.FORMAT_TIME_H23;
    }
    connectedCallback() {
        super.connectedCallback();
        // todo: this should be removed when a reusable solution has been implemented
        // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
        window.addEventListener('click', this._handleOutsideClick);
    }
    disconnectedCallback() {
        this._textfieldTimePickerResizeObserver.disconnect();
        // todo: this should be removed when a reusable solution has been implemented
        // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
        window.removeEventListener('click', this._handleOutsideClick);
        super.disconnectedCallback();
    }
    formResetCallback() {
        super.formResetCallback();
        this._timePickerInputValue = DateTime.fromISO(this.resetValue).isValid
            ? timePickerInputValueConverter.fromAttribute(this.resetValue)
            : {
                dayTime: undefined,
                hour: undefined,
                minute: undefined,
            };
    }
    _handleTimePickerChangedEvent({ detail }) {
        this._userChange = true;
        this.value = new Date(detail.value);
        this.emitInputEvent();
    }
    _handleTimePickerInputEvent() {
        this.emitInputEvent();
    }
    _handleTimePickerInputInputEvent() {
        this.emitInputEvent();
    }
    _handleTimePickerInputChangedEvent({ detail }) {
        this._timePickerInputValue = Object.assign({}, this._timePickerInputRef.value);
        this._userChange = true;
        // map from undefined to null, otherwise create date
        this.value = isDefined(detail.value) ? new Date(detail.value) : null;
        this.emitInputEvent();
    }
    _handleTimePickerInputFocusInEvent() {
        if (this._showTimePicker) {
            this._showTimePicker = false;
        }
    }
    _handleTimePickerInputToggle() {
        var _a, _b, _c;
        this._showTimePicker = !this._showTimePicker;
        if (!this._showTimePicker) {
            return;
        }
        requestAnimationFrame(() => {
            var _a;
            (_a = this._textfieldTimePickerOverlayRef) === null || _a === void 0 ? void 0 : _a.forcePositioning();
        });
        // only update value on open when there is no current value
        if (isDefined(this.value)) {
            return;
        }
        // set value on open to a mix of input parts and default value
        const { dayTime, hour, minute } = (_a = this._timePickerInputRef.value) !== null && _a !== void 0 ? _a : {
            dayTime: undefined,
            hour: undefined,
            minute: undefined,
        };
        const updateValue = this._is12HourFormat
            ? isDefined(dayTime) || isDefined(hour) || isDefined(minute)
            : isDefined(hour) || isDefined(minute);
        if (updateValue) {
            // treat open as a user change as the value will be updated
            this._userChange = true;
            this.value = getUpdatedDate({
                hour: hour !== null && hour !== void 0 ? hour : (_b = this._defaultValueDT) === null || _b === void 0 ? void 0 : _b.hour,
                minute: minute !== null && minute !== void 0 ? minute : (_c = this._defaultValueDT) === null || _c === void 0 ? void 0 : _c.minute,
            }, this._defaultValueDT, this.hourCycle, 
            // FIXME: this looks like a bug
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (dayTime !== null && dayTime !== void 0 ? dayTime : this._dayTime)).toJSDate();
            this.emitInputEvent();
        }
    }
    _handleTimePickerKeyDown({ code }) {
        if (code === 'Escape') {
            this._showTimePicker = false;
        }
    }
    _delegateFocusToTimePicker() {
        const [timePickerRef] = getContentsFromPortal(this._timePickerPortal, 'zui-time-picker');
        timePickerRef.focus();
    }
    firstRendered() {
        super.firstRendered();
        this._textfieldTimePickerResizeObserver.observe(this._timePickerInputRef);
    }
    updated(changedProperties) {
        var _a, _b;
        super.updated(changedProperties);
        if (changedProperties.has('value') && this._userChange) {
            this.emitChangeEvent();
            this.emitTextfieldTimePickerInputChangedEvent({ value: (_b = (_a = this._valueDT) === null || _a === void 0 ? void 0 : _a.toISO()) !== null && _b !== void 0 ? _b : null });
            this._userChange = false;
        }
    }
    render() {
        return html `
      <zui-time-picker-input
        zuiFormControl
        ?disabled="${this.disabled}"
        ?readonly="${this.readonly}"
        ?invalid="${this.showValidation}"
        hour-cycle="${this.hourCycle}"
        placeholder-day-time="${this.placeholderDayTime}"
        placeholder-hour="${this.placeholderHour}"
        placeholder-minute="${this.placeholderMinute}"
        default-value="${this._defaultValueDT.toFormat('HH:mm')}"
        value="${ifDefined(this._formattedValue)}"
        @focusin="${this._handleTimePickerInputFocusInEvent}"
        @zui-time-picker-input-changed="${this._handleTimePickerInputChangedEvent}"
        @zui-time-picker-input-input="${this._handleTimePickerInputInputEvent}"
        @zui-time-picker-input-toggle="${this._handleTimePickerInputToggle}"
      >
      </zui-time-picker-input>
      ${!this._showTimePicker
            ? html `<zui-validation-message-directive
            .messageAnchorReferenceCallback=${this._messageAnchorReferenceCallback}
            .triggerHostReference=${this}
            validation-message=${this.validationMessage}
          ></zui-validation-message-directive>`
            : nothing}
      ${this._showTimePicker
            ? html `
            <zui-overlay-directive
              .offsetHandler="${this._offset}"
              .placements="${TextfieldTimePicker_1.TIME_PICKER_PLACEMENTS}"
              .positionReferenceCallback="${this._timePickerPositionReference}"
              flip
              id="textfield-time-picker"
              portal="${this._timePickerPortal}"
              @zui-overlay-ready="${this._delegateFocusToTimePicker}"
            >
              <zui-popover style="width: auto; min-width: auto; padding: 32px">
                <zui-time-picker
                  ?disabled="${this.disabled}"
                  default-value="${this._defaultValueDT}"
                  hour-cycle="${this.hourCycle}"
                  locale="${this.locale}"
                  value="${ifDefined(this._valueDT)}"
                  @zui-time-picker-changed="${this._handleTimePickerChangedEvent}"
                  @zui-time-picker-input="${this._handleTimePickerInputEvent}"
                  @keydown="${this._handleTimePickerKeyDown}"
                ></zui-time-picker>
              </zui-popover>
            </zui-overlay-directive>
          `
            : nothing}
    `;
    }
};
TextfieldTimePicker.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    textfieldTimePickerComponentStyles,
];
/* eslint-disable @typescript-eslint/naming-convention */
TextfieldTimePicker.FORMAT_TIME_H12 = 'hh:mm a';
TextfieldTimePicker.FORMAT_TIME_H23 = 'HH:mm';
TextfieldTimePicker.MAX_ERROR_MESSAGE = 'This time is not allowed.';
TextfieldTimePicker.MIN_ERROR_MESSAGE = 'This time is not allowed.';
TextfieldTimePicker.REQUIRED_ERROR_MESSAGE = 'The value is required.';
TextfieldTimePicker.TIME_PICKER_PLACEMENTS = ['bottom-start', 'bottom-end', 'top-start', 'top-end'];
__decorate([
    property({ reflect: true, type: String, attribute: 'default-value', converter: isoDateConverter })
], TextfieldTimePicker.prototype, "defaultValue", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'hour-cycle' })
], TextfieldTimePicker.prototype, "hourCycle", null);
__decorate([
    property({ reflect: true, type: String })
], TextfieldTimePicker.prototype, "locale", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], TextfieldTimePicker.prototype, "max", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], TextfieldTimePicker.prototype, "min", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'placeholder-day-time' })
], TextfieldTimePicker.prototype, "placeholderDayTime", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'placeholder-hour' })
], TextfieldTimePicker.prototype, "placeholderHour", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'placeholder-minute' })
], TextfieldTimePicker.prototype, "placeholderMinute", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], TextfieldTimePicker.prototype, "required", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], TextfieldTimePicker.prototype, "value", void 0);
__decorate([
    query('zui-overlay-directive#textfield-time-picker')
], TextfieldTimePicker.prototype, "_textfieldTimePickerOverlayRef", void 0);
__decorate([
    query('zui-time-picker-input')
], TextfieldTimePicker.prototype, "_timePickerInputRef", void 0);
__decorate([
    state()
], TextfieldTimePicker.prototype, "_showTimePicker", void 0);
__decorate([
    state()
], TextfieldTimePicker.prototype, "_internalHourCycle", void 0);
__decorate([
    state()
], TextfieldTimePicker.prototype, "_timePickerInputValue", void 0);
TextfieldTimePicker = TextfieldTimePicker_1 = __decorate([
    customElement('zui-textfield-time-picker')
    /* eslint-enable jsdoc/require-property-description */
], TextfieldTimePicker);
export { TextfieldTimePicker };
