var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{font:var(--zui-typography-label2) !important;text-transform:var(--zui-typography-label2-text-transform);display:flex;align-items:center;justify-content:center;width:var(--zui-picker-header-cell-width, calc(var(--zui-gu) * 4));height:var(--zui-picker-header-cell-height, calc(var(--zui-gu) * 8));overflow:hidden;color:var(--zui-color-text-default);white-space:nowrap}.text-wrapper{margin-top:1px}"
import { BaseElement } from '../../base/base-element.class.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-text-default: var(--zui-color-gs-100);--zui-color-text-on-dark-bg: var(--zui-color-gs-10);--zui-color-text-primary: var(--zui-color-pb-100);--zui-color-text-header: var(--zui-color-gs-120);--zui-color-text-secondary-line: var(--zui-color-gs-85);--zui-color-text-disabled: var(--zui-color-gs-100)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-text-default: var(--zui-color-gs-50);--zui-color-text-on-dark-bg: var(--zui-color-gs-10);--zui-color-text-primary: var(--zui-color-pb-70);--zui-color-text-header: var(--zui-color-gs-10);--zui-color-text-secondary-line: var(--zui-color-gs-70);--zui-color-text-disabled: var(--zui-color-gs-50);--zui-color-text-divider: var(--zui-color-gs-70)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
export const headerCellStyles = css `
  ${unsafeCSS(styles)}
`;
/**
 * The picker header cell is sub component of the date picker which can be reused in all picker widgets. A text can get set with the attribute text.
 * It has two CSS variables which can overwrite the width and height of the header-cell: --zui-picker-header-cell-width and --zui-picker-header-cell-height
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-picker-header-cell text="MO"></zui-picker-header-cell>
 * ```
 * @cssprop --zui-picker-header-cell-width - Overwrites the width of the picker-header-cell
 * @cssprop --zui-picker-header-cell-height - Overwrites the height of the picker-header-cell
 */
let PickerHeaderCell = class PickerHeaderCell extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * Text of the picker header cell
         */
        this.text = '';
        /**
         * Value of the picker header cell
         */
        this.value = '';
    }
    render() {
        return html `<div class="text-wrapper">${this.text}</div>`;
    }
};
PickerHeaderCell.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    headerCellStyles,
];
__decorate([
    property({ reflect: true, type: String })
], PickerHeaderCell.prototype, "text", void 0);
__decorate([
    property({ reflect: true, type: String })
], PickerHeaderCell.prototype, "value", void 0);
PickerHeaderCell = __decorate([
    customElement('zui-picker-header-cell')
], PickerHeaderCell);
export { PickerHeaderCell };
