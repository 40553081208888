var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement } from 'lit/decorators.js';
import { hostStyles } from '../../../host.styles.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-thin-line-default: var(--zui-color-gs-40);--zui-color-thin-line-highlight: var(--zui-color-gs-60);--zui-color-thick-line-default: var(--zui-color-gs-40);--zui-color-thick-line-highlight: var(--zui-color-gs-60);--zui-color-thick-line-primary: var(--zui-color-pb-100);--zui-menubar-divider-color: var(--zui-color-gs-120)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-thin-line-default: var(--zui-color-gs-110);--zui-color-thin-line-highlight: var(--zui-color-gs-120);--zui-color-thick-line-default: var(--zui-color-gs-110);--zui-color-thick-line-highlight: var(--zui-color-gs-120);--zui-color-thick-line-primary: var(--zui-color-pb-90);--zui-menubar-divider-color: var(--zui-color-gs-120)}"
const style = ":host{--zui-horizontal-rule-margin: none;position:relative}hr{height:var(--zui-line-strength-thin);margin:var(--zui-horizontal-rule-margin, revert);background-color:var(--zui-menubar-divider-color);border:none}"
import { BaseElement } from '../../base/base-element.class.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const themeDark = ""
const themeLight = ""
// make component self contained
import '../../divider/divider.component.js';
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const menuBarDividerStyles = css `
  ${unsafeCSS(style)}
`;
// eslint-disable-next-line jsdoc/require-example
/**
 * ** deprecated **
 * This component is deprecated in V2 and will be removed in next major release,
 * please use the generated dividers of the menubar instead.
 *
 * @deprecated
 * @private
 */
let MenubarDivider = class MenubarDivider extends BaseElement {
    connectedCallback() {
        super.connectedCallback();
        console.warn('The usage of <zui-menubar-divider> has been deprecated and it will be removed in the next major release! Please switch to the generated dividers, using the group attribute of the menubar component.');
    }
    render() {
        return html `<hr />`;
    }
};
MenubarDivider.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    menuBarDividerStyles,
];
MenubarDivider = __decorate([
    customElement('zui-menubar-divider')
], MenubarDivider);
export { MenubarDivider };
