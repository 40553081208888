import { getPublicPath } from '../environment';
import { getUserData } from './authenticated-user';

export function redirectUnauthenticatedUserToLandingPage(force = false): void {
  if (getUserData() === undefined && (force || isRestrictedPage())) {
    location.href = createLandingPageUrl();
  }
}

function isRestrictedPage(): boolean {
  return !location.pathname.startsWith(`${getPublicPath()}public`);
}

function createLandingPageUrl(): string {
  const landingPageUrl = new URL(`${location.origin}${getPublicPath()}public/authentication`);
  // Strip trailing slash from public path, if any
  const publicPath = getPublicPath().replace(/[/]?$/, '');
  const { search, pathname, hash } = location;
  // If path includes public path, replace public path. If not, just replace starting slash.
  const pathnameNoPublicPath = (pathname || '').replace(new RegExp(`^(${publicPath})?/?`), '');
  const redirectUrlAfterLogin = `${pathnameNoPublicPath}${search || ''}${hash || ''}`;

  if (redirectUrlAfterLogin !== '') {
    landingPageUrl.searchParams.append('redirect_to', redirectUrlAfterLogin);
  }

  return landingPageUrl.toString();
}
