var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { hostStyles } from '../../../host.styles.js';
import { BaseElement } from '../../base/base-element.class.js';
const styles = ":host{display:block;height:calc(var(--zui-gu) * 8)}#flex-wrapper{display:flex;align-items:center;width:100%;max-width:100%;height:100%}#ellipsis-wrapper{display:inline-block;width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}#ellipsis-wrapper.has-product-sub-name{color:var(--zui-color-headerbar-product-sub-name-color)}#product-name{color:var(--zui-color-headerbar-product-name-color);font:var(--zui-typography-subtitle1)}#product-sub-name{margin-left:calc(var(--zui-gu) * 0.5);color:var(--zui-color-headerbar-product-sub-name-color);font:var(--zui-typography-body)}"
import { classMap } from 'lit/directives/class-map.js';
// dependencies
import '../../truncate-with-tooltip/truncate-with-tooltip.component.js';
import '../../tooltip/tooltip.component.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-headerbar-product-name-color: var(--zui-color-gs-100);--zui-color-headerbar-product-sub-name-color: var(--zui-color-gs-85)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-headerbar-product-name-color: var(--zui-color-gs-100);--zui-color-headerbar-product-sub-name-color: var(--zui-color-gs-85)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const headerbarProductNameStyles = css `
  ${unsafeCSS(styles)}
`;
/**
 * The zui-headerbar-product-name is a sub component of the headerbar which can show a product name and a product sub name.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-headerbar-product-name product-name="ZUi" product-sub-name="web component library"></zui-headerbar-product-name>
 * ```
 */
let HeaderbarProductName = class HeaderbarProductName extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * Defines the product name
         */
        this.productName = '';
        /**
         * Defines the product sub name
         */
        this.productSubName = '';
    }
    render() {
        var _a;
        return html `<div id="flex-wrapper"
      ><div id="ellipsis-wrapper" class="${classMap({ 'has-product-sub-name': ((_a = this.productSubName) === null || _a === void 0 ? void 0 : _a.length) > 0 })}">
        <zui-truncate-with-tooltip delayed tooltip-trigger="hover,focus">
          <zui-tooltip slot="tooltip">${this.productName} – ${this.productSubName}</zui-tooltip>
          <span id="product-name">${this.productName}</span
          ><span id="product-sub-name">${this.productSubName}</span></zui-truncate-with-tooltip
        >
      </div></div
    >`;
    }
};
HeaderbarProductName.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    headerbarProductNameStyles,
];
__decorate([
    property({ reflect: true, type: String, attribute: 'product-name' })
], HeaderbarProductName.prototype, "productName", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'product-sub-name' })
], HeaderbarProductName.prototype, "productSubName", void 0);
HeaderbarProductName = __decorate([
    customElement('zui-headerbar-product-name')
], HeaderbarProductName);
export { HeaderbarProductName };
