import { type MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { useKey, useOutsideClick } from 'rooks';

// Manages the stack of open dialogs
const openDialogs: Array<string> = [];

export function useClose(id: string, cb: (ev: Event) => void): MutableRefObject<any> {
  const ref = useRef<any>(null);

  const onClose = useCallback(
    (ev: Event) => {
      if (ev.target instanceof HTMLElement && ev.target.closest('zui-portal')) {
        // Do nothing, apparently was invoked from something like a date picker
      } else {
        const activeDialog = openDialogs[openDialogs.length - 1];

        if (activeDialog === id) {
          cb(ev);
        }
      }
    },
    [id, cb]
  );

  useEffect(() => {
    if (document.querySelector('zui-portal')) {
      // A (ZUI) portal is open - we should close it by "clicking" outside
      document.body.dispatchEvent(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      );
    }

    // Adds the current dialog on top of the stack
    openDialogs.push(id);

    return () => {
      // Removes the dialog from the stack
      openDialogs.splice(openDialogs.indexOf(id), 1);
    };
  }, []);

  useOutsideClick(ref, onClose);

  useKey(['Escape'], onClose);

  return ref;
}
