import { ZuiPopover, ZuiScrollableDirective } from '@zeiss/zui-react';
import styled from 'styled-components';

import { NotificationItemAction } from './BellNotificationElement.styles';

export const PopoverContainer = styled.div`
  position: fixed;
  z-index: 1;
  right: 0;
  top: var(--arn-space-16);
`;

export const Popover = styled(ZuiPopover)`
  position: relative;
  top: 1px;
  right: 0;
  width: var(--arn-space-135);
  max-width: var(--arn-space-135);
  padding: 0;
  border-color: var(--zui-color-gs-20);
  border-radius: 0;
`;

export const PopoverHeader = styled.div`
  padding: var(--arn-space-4) var(--arn-space-8);
  background-color: var(--zui-color-gs-20);
  font: var(--zui-typography-subtitle1);
  display: flex;
`;

export const Scrollable = styled(ZuiScrollableDirective)`
  width: 100%;

  /* subtract height of header bar and popover header */
  height: calc(100vh - 119px);
`;

export const StyledNotificationItemAction = styled(NotificationItemAction)`
  display: 'inline-block';
`;

export const StyledPopoverHeaderHeadline = styled.div`
  margin-right: auto;
`;
