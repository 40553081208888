import {
  ZuiIconAlertsEwiErrorRound,
  ZuiIconAlertsEwiInfo,
  ZuiIconAlertsEwiQuestion,
} from '@zeiss/zui-react-icons';
import type { FC } from 'react';

import type { BellNotificationSeverity } from '../../plugins/bell-notification/types';

interface NotificationSeverityIconProps {
  severity: BellNotificationSeverity;
}

const ewiqSeverity = {
  INFO: <ZuiIconAlertsEwiInfo size="s24" slot="icon" />,
  QUESTION: <ZuiIconAlertsEwiQuestion size="s24" slot="icon" />,
  ERROR: <ZuiIconAlertsEwiErrorRound size="s24" slot="icon" />,
};

export const NotificationSeverityIcon: FC<NotificationSeverityIconProps> = ({ severity }) =>
  ewiqSeverity[severity];
