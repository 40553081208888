import type { Dict, ExtensionRegistration } from 'piral-core';
import { lazy } from 'react';

import { DEFAULT_LOCALE } from '../locale';
import type { HdpApp } from '../types';
import { makeExtension } from '../utils/make-extension';
import { withSuspense } from '../utils/with-suspense';
import { AnyMenu } from './AnyMenu';
import { ErrorSwitch } from './ErrorSwitch';

const FossDisclosureStatement = lazy(() => import('./FossDisclosureStatement'));

export function createExtensions(app: HdpApp): Dict<Array<ExtensionRegistration>> {
  const { t } = app.languageProvider;
  const language = app.ifuLanguage || DEFAULT_LOCALE;

  return {
    'app-shell-any-menu': makeExtension(AnyMenu),
    'app-shell-error-switch': makeExtension(ErrorSwitch),
    foss: makeExtension(withSuspense(FossDisclosureStatement), {
      name: app.title,
      title: () => `${t('fossPage.title')}`,
    }),
    'instructions-for-use': makeExtension(() => null, {
      title: () => `${t('instructionsPage.ifuLink')}`,
      url: `${app.basePath}assets/legal/ifu/ifu-${language}.pdf`,
    }),
  };
}
