var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import '../../../types.js';
import '../../interactive-text/interactive-text.component.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{---zui-time-picker-day-time-toggle-font: var(--zui-typography-label2);---zui-time-picker-day-time-toggle-text-transform: var(--zui-typography-label2-text-transform);display:inline-flex;flex-direction:column}:host *+*{margin:calc(var(--zui-gu) * 0.75) 0 0 0}:host([integrated]){flex-direction:row}:host([integrated]) *+*{margin:0 0 0 calc(var(--zui-gu) * 2)}zui-interactive-text{--zui-interactive-text-font: var(---zui-time-picker-day-time-toggle-font);--zui-interactive-text-text-transform: var(---zui-time-picker-day-time-toggle-text-transform);--zui-interactive-text-hitarea-size: 4px}"
const theme = ""
import { GetSharedEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const timePickerDayTimeToggleComponentStyles = css `
  ${unsafeCSS(styles)}
`;
class TimePickerDayTimeChangedEvent extends GetSharedEventClass('time-picker-day-time-changed') {
}
/**
 * Time picker day time toggle.
 *
 * @example
 * html```
 * <zui-time-picker-day-time-toggle value="AM"></zui-time-picker-day-time-toggle>
 * ```
 */
let TimePickerDayTimeToggle = class TimePickerDayTimeToggle extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.ChangedEvent = TimePickerDayTimeChangedEvent;
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * standalone / integrated
         */
        this.integrated = false;
    }
    _handleAmPmToggle(event) {
        const value = event.target.getAttribute('id');
        if (value === this.value) {
            return;
        }
        this.value = value;
        this._emitTimePickerDayTimeChangedEvent({ value: this.value });
    }
    render() {
        return html `
      <zui-interactive-text
        id="AM"
        emphasis="${this.value === 'AM' ? 'highlight' : 'subtle'}"
        @click="${this._handleAmPmToggle}"
      >
        <span>AM</span>
      </zui-interactive-text>
      <zui-interactive-text
        id="PM"
        emphasis="${this.value === 'PM' ? 'highlight' : 'subtle'}"
        @click="${this._handleAmPmToggle}"
      >
        <span>PM</span>
      </zui-interactive-text>
    `;
    }
};
TimePickerDayTimeToggle.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    timePickerDayTimeToggleComponentStyles,
];
__decorate([
    property({ reflect: true, type: Boolean })
], TimePickerDayTimeToggle.prototype, "integrated", void 0);
__decorate([
    property({ reflect: true, type: String })
], TimePickerDayTimeToggle.prototype, "value", void 0);
TimePickerDayTimeToggle = __decorate([
    customElement('zui-time-picker-day-time-toggle')
], TimePickerDayTimeToggle);
export { TimePickerDayTimeToggle };
