import { useGlobalState, useGlobalStateContext } from 'piral-core';
import { type FC, useEffect } from 'react';

import { clearErrorMessages } from './error-actions';

export const GlobalErrorMessages: FC = () => {
  const messages = useGlobalState((state) => state.errorMessages);
  const context = useGlobalStateContext();

  // Clear error messages on component clean-up
  useEffect(() => () => clearErrorMessages(context), []);

  return (
    <>
      {messages.map((message: string, index: number) => (
        <div key={index} data-test="error-message">
          {message}
        </div>
      ))}
    </>
  );
};
