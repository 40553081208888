var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
const style = ":host{--zui-pagination-dot-transition-timing: 200ms;display:inline-flex;width:calc(var(--zui-gu) * 1);height:calc(var(--zui-gu) * 1)}button{display:flex;align-items:center;justify-content:center;width:100%;height:100%;padding:0;background-color:rgba(0,0,0,0);border:none}:host(:first-child) button{justify-content:flex-end}:host(:last-child) button{justify-content:flex-start}button:hover{cursor:pointer}.pagination-dot{width:calc(var(--zui-gu) * 1);height:calc(var(--zui-gu) * 1);background-color:var(--zui-color-pagination-dot-background-default);border-radius:50%;transition:background-color var(--zui-pagination-dot-transition-timing) ease-in-out,width var(--zui-pagination-dot-transition-timing) ease-in-out,height var(--zui-pagination-dot-transition-timing) ease-in-out}:host([emphasis=default]) .pagination-dot,:host([emphasis=selected][zui-internal-animate-selected]) .pagination-dot{background-color:var(--zui-color-pagination-dot-background-default)}:host([emphasis=selected]) .pagination-dot{background-color:var(--zui-color-pagination-dot-background-selected)}:host([size=s]) .pagination-dot{width:calc(var(--zui-gu) * 0.5);height:calc(var(--zui-gu) * 0.5)}:host([size=m]) .pagination-dot{width:calc(var(--zui-gu) * 1);height:calc(var(--zui-gu) * 1)}"
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-pagination-dot-background-default: var(--zui-color-gs-50);--zui-color-pagination-dot-background-selected: var(--zui-color-pb-100)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-pagination-dot-background-default: var(--zui-color-gs-90);--zui-color-pagination-dot-background-selected: var(--zui-color-pb-90)}"
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const themeDark = ""
const themeLight = ""
const paginationDotStyles = css `
  ${unsafeCSS(style)}
`;
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const INTERNAL_ATTRIBUTE_SELECTED_NAME = 'zui-internal-animate-selected';
/**
 * Renders a pagination dot based on the `emphasis` and `size` attribute/property.
 *
 * This component is internally used in the `PaginationDotBar` component.
 *
 * @example
 * ```HTML
 * <zui-pagination-dot emphasis="default" size="m"></zui-pagination-dot>
 * ```
 * @cssprop --zui-pagination-dot-transition-timing - animation duration of the dot
 */
let PaginationDot = class PaginationDot extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * the dot emphasis
         */
        this.emphasis = 'default';
        /**
         * the dot size
         */
        this.size = 'm';
        this._timeout = 0;
        this._animateSelected = false;
    }
    /**
     * internal prop for toggling anim
     *
     * @private
     * @returns result whether anim is to be triggered or not
     */
    get animateSelected() {
        return this._animateSelected;
    }
    set animateSelected(value) {
        this._animateSelected = value;
        // from false to true, schedule timeout
        if (this._animateSelected) {
            setTimeout(() => {
                this.removeAttribute(INTERNAL_ATTRIBUTE_SELECTED_NAME);
            }, this._timeout);
        }
    }
    firstUpdated() {
        this._timeout = parseInt(getComputedStyle(this).getPropertyValue('--zui-pagination-dot-transition-timing'), 10);
    }
    render() {
        return html `
      <button>
        <span class="pagination-dot"></span>
      </button>
    `;
    }
};
PaginationDot.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    paginationDotStyles,
];
PaginationDot.internalAnimateSelectedAttribute = INTERNAL_ATTRIBUTE_SELECTED_NAME;
__decorate([
    property({ reflect: true, type: Boolean, attribute: INTERNAL_ATTRIBUTE_SELECTED_NAME })
], PaginationDot.prototype, "animateSelected", null);
__decorate([
    property({ reflect: true, type: String })
], PaginationDot.prototype, "emphasis", void 0);
__decorate([
    property({ reflect: true, type: String })
], PaginationDot.prototype, "size", void 0);
__decorate([
    state()
], PaginationDot.prototype, "_timeout", void 0);
PaginationDot = __decorate([
    customElement('zui-pagination-dot')
], PaginationDot);
export { PaginationDot };
