import type { ZeissIdDiscoveryData } from '@arnold/common/types/zeissid';

import type { RuntimeConfig } from '../types';
import { fetchJSON } from '../utils/fetch-json';

export function getAuthorizationEndpoint(config: RuntimeConfig): Promise<string> {
  return getDiscoveryData(config).then((data) => data.authorization_endpoint);
}

export function getLogoutEndpoint(config: RuntimeConfig): Promise<string> {
  return getDiscoveryData(config).then((data) => data.end_session_endpoint);
}

function getDiscoveryData(config: RuntimeConfig): Promise<ZeissIdDiscoveryData> {
  return fetchJSON<ZeissIdDiscoveryData>(config.auth.zeissIdDiscoveryUrl);
}
