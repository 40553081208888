var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var TimePickerCell_1;
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import '../../../types.js';
import { addLeadingZeros } from '../../date-picker/utils/date-picker-input.utils.js';
import { clamp, cycle, isDefined } from '../../../utils/component.utils.js';
const styles = ":host{display:inline-flex;flex-direction:column;align-items:center}input{box-sizing:border-box;width:calc(var(--zui-gu) * 6);height:calc(var(--zui-gu) * 6);margin:calc(var(--zui-gu) * 1.5) 0;padding:0;color:var(--zui-time-picker-cell-text-color);font:var(--zui-typography-h1);line-height:calc(var(--zui-gu) * 6);text-align:center;background-color:var(--zui-time-picker-cell-background-color);border:1px solid var(--zui-time-picker-cell-border-color);border-radius:calc(var(--zui-gu) * 0.375)}:host([disabled]) input{opacity:var(--zui-disabled-opacity);pointer-events:none}input:hover{color:var(--zui-time-picker-cell-text-color-hovered);background-color:var(--zui-time-picker-cell-background-color-hovered);border-color:var(--zui-time-picker-cell-border-color-hovered)}input:focus{outline:none}input:active{color:var(--zui-time-picker-cell-text-color-pressed);background-color:var(--zui-time-picker-cell-background-color-pressed);border-color:var(--zui-time-picker-cell-border-color-pressed)}input::selection{background:var(--zui-color-selection-background)}input[type=number]::-webkit-outer-spin-button,input[type=number]::-webkit-inner-spin-button{margin:0;-webkit-appearance:none}input[type=number]{-moz-appearance:textfield}"
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-time-picker-cell-background-color: var(--zui-color-gs-30);--zui-time-picker-cell-background-color-hovered: var(--zui-color-gs-40);--zui-time-picker-cell-background-color-focused: var(--zui-color-gs-30);--zui-time-picker-cell-background-color-pressed: var(--zui-color-gs-50);--zui-time-picker-cell-border-color: var(--zui-color-gs-60);--zui-time-picker-cell-border-color-hovered: var(--zui-color-gs-60);--zui-time-picker-cell-border-color-focused: var(--zui-color-gs-60);--zui-time-picker-cell-border-color-pressed: var(--zui-color-gs-60);--zui-time-picker-cell-text-color: var(--zui-color-gs-120);--zui-time-picker-cell-text-color-hovered: var(--zui-color-gs-120);--zui-time-picker-cell-text-color-focused: var(--zui-color-gs-120);--zui-time-picker-cell-text-color-pressed: var(--zui-color-gs-120)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-time-picker-cell-background-color: var(--zui-color-gs-110);--zui-time-picker-cell-background-color-hovered: var(--zui-color-gs-115);--zui-time-picker-cell-background-color-focused: var(--zui-color-gs-110);--zui-time-picker-cell-background-color-pressed: var(--zui-color-gs-120);--zui-time-picker-cell-border-color: var(--zui-color-gs-120);--zui-time-picker-cell-border-color-hovered: var(--zui-color-gs-120);--zui-time-picker-cell-border-color-focused: var(--zui-color-gs-120);--zui-time-picker-cell-border-color-pressed: var(--zui-color-gs-120);--zui-time-picker-cell-text-color: var(--zui-color-gs-10);--zui-time-picker-cell-text-color-hovered: var(--zui-color-gs-10);--zui-time-picker-cell-text-color-focused: var(--zui-color-gs-10);--zui-time-picker-cell-text-color-pressed: var(--zui-color-gs-10)}"
import { hostStyles } from '../../../host.styles.js';
import { GetSharedEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const themeDark = ""
const themeLight = ""
// Dependencies
import '../../interactive-icon/interactive-icon.component.js';
// icons
import '@zeiss/zui-icons/dist/components/generated/arrows-arrow-outline-actually-centred-down.component.js';
import '@zeiss/zui-icons/dist/components/generated/arrows-arrow-outline-actually-centred-up.component.js';
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const timePickerCellComponentStyles = css `
  ${unsafeCSS(styles)}
`;
class TimePickerCellChangedEvent extends GetSharedEventClass('time-picker-cell-changed', { bubbles: true }) {
}
class TimePickerCellInputEvent extends GetSharedEventClass('time-picker-cell-input', { bubbles: true }) {
}
/**
 * The `zui-time-picker-cell` is part of the `zui-time-picker` and is meant for displaying and editing hours and minutes.
 *
 * @example
 * html```
 * <zui-time-picker-cell default-value="12" max="23" min="0"></zui-time-picker-cell>
 * ```
 */
let TimePickerCell = TimePickerCell_1 = class TimePickerCell extends DisabledMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.ChangedEvent = TimePickerCellChangedEvent;
        this.InputEvent = TimePickerCellInputEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
})) {
    constructor() {
        super(...arguments);
        /**
         * default value
         */
        this.defaultValue = 0;
        /**
         * max
         */
        this.max = 0;
        /**
         * min
         */
        this.min = 0;
        /**
         * step size
         *
         * @private
         */
        this.step = 1;
    }
    get _paddedValue() {
        if (isDefined(this.value)) {
            return addLeadingZeros(this.value, TimePickerCell_1.MAX_CHARACTER_LENGTH);
        }
        return null;
    }
    get _currentValue() {
        var _a;
        return (_a = this.value) !== null && _a !== void 0 ? _a : this.defaultValue;
    }
    get _currentInputValue() {
        return this._input.value !== '' ? Number(this._input.value) : this.defaultValue;
    }
    _getDecreasedValue(value) {
        return cycle(value, this.min, this.max, this.step, 'decrease');
    }
    _getIncreasedValue(value) {
        return cycle(value, this.min, this.max, this.step, 'increase');
    }
    _decreaseValue() {
        if (isDefined(this.value)) {
            this.value = this._getDecreasedValue(this._currentValue);
        }
        else {
            this.value = this._currentValue;
        }
        this._emitTimePickerCellChangedEvent({ value: this.value });
    }
    _increaseValue() {
        if (isDefined(this.value)) {
            this.value = this._getIncreasedValue(this._currentValue);
        }
        else {
            this.value = this._currentValue;
        }
        this._emitTimePickerCellChangedEvent({ value: this.value });
    }
    _handleInputEvent() {
        if (this._input.value.length > TimePickerCell_1.MAX_CHARACTER_LENGTH) {
            this._input.value = this._input.value.slice(0, TimePickerCell_1.MAX_CHARACTER_LENGTH);
        }
        if (this._input.value !== '') {
            this._input.value = String(clamp(this.min, Number(this._input.value), this.max));
        }
        this._emitTimePickerCellInputEvent({ value: parseInt(this._input.value) });
    }
    _handleInputBlurEvent() {
        const oldValue = this.value;
        this.value = this._currentInputValue;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore because TS does not no nothing about the DOM
        this._input.value = this._paddedValue;
        if (oldValue !== this.value) {
            this._emitTimePickerCellChangedEvent({ value: this.value });
        }
    }
    _handleInputClickEvent() {
        if (!isDefined(this.value)) {
            this._input.value = addLeadingZeros(this.defaultValue, TimePickerCell_1.MAX_CHARACTER_LENGTH);
        }
    }
    _handleInputKeydownEvent(event) {
        switch (event.key) {
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
            case '0':
            case 'ArrowLeft':
            case 'ArrowRight':
            case 'Backspace':
            case 'Delete':
            case 'Tab':
                break;
            case 'ArrowUp':
                {
                    event.preventDefault();
                    event.target.value = String(this._getIncreasedValue(this._currentInputValue));
                    this._emitTimePickerCellInputEvent({ value: parseInt(this._input.value) });
                }
                break;
            case 'ArrowDown':
                {
                    event.preventDefault();
                    event.target.value = String(this._getDecreasedValue(this._currentInputValue));
                    this._emitTimePickerCellInputEvent({ value: parseInt(this._input.value) });
                }
                break;
            default:
                event.preventDefault();
                break;
        }
    }
    render() {
        return html `
      <zui-interactive-icon
        emphasis="subtle"
        id="increase"
        ?disabled="${this.disabled}"
        @click="${this._increaseValue}"
      >
        <zui-icon-arrows-arrow-outline-actually-centred-up></zui-icon-arrows-arrow-outline-actually-centred-up>
      </zui-interactive-icon>
      <input
        .value="${this._paddedValue}"
        ?disabled="${this.disabled}"
        max="${this.max}"
        min="${this.min}"
        step="${this.step}"
        type="number"
        @blur="${this._handleInputBlurEvent}"
        @click="${this._handleInputClickEvent}"
        @input="${this._handleInputEvent}"
        @keydown="${this._handleInputKeydownEvent}"
      />
      <zui-interactive-icon
        emphasis="subtle"
        id="decrease"
        ?disabled="${this.disabled}"
        @click="${this._decreaseValue}"
      >
        <zui-icon-arrows-arrow-outline-actually-centred-down></zui-icon-arrows-arrow-outline-actually-centred-down>
      </zui-interactive-icon>
    `;
    }
};
TimePickerCell.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    timePickerCellComponentStyles,
];
// eslint-disable-next-line @typescript-eslint/naming-convention
TimePickerCell.MAX_CHARACTER_LENGTH = 2;
__decorate([
    property({ reflect: true, type: Number, attribute: 'default-value' })
], TimePickerCell.prototype, "defaultValue", void 0);
__decorate([
    property({ reflect: true, type: Number })
], TimePickerCell.prototype, "max", void 0);
__decorate([
    property({ reflect: true, type: Number })
], TimePickerCell.prototype, "min", void 0);
__decorate([
    property({ reflect: true, type: Number })
], TimePickerCell.prototype, "step", void 0);
__decorate([
    property({ reflect: true, type: Number })
], TimePickerCell.prototype, "value", void 0);
__decorate([
    query('input')
], TimePickerCell.prototype, "_input", void 0);
TimePickerCell = TimePickerCell_1 = __decorate([
    customElement('zui-time-picker-cell')
], TimePickerCell);
export { TimePickerCell };
