var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
const style = ":host{display:flex;flex:1 1 0;align-items:center;justify-content:center;min-width:0;height:calc(var(--zui-gu) * 6);padding:0 calc(var(--zui-gu) * 0.5)}:host(:not(:first-child)){margin-left:calc(var(--zui-gu) * 0.25)}:host([emphasis=next]){color:var(--zui-step-indicator-item-text-color-next);box-shadow:inset 0 4px var(--zui-step-indicator-item-border-color-next)}:host([emphasis=next][size=s]){font:var(--zui-typography-caption) !important}:host([emphasis=next][size=m]){font:var(--zui-typography-body) !important}:host([emphasis=selected]){color:var(--zui-step-indicator-item-text-color-selected);box-shadow:inset 0 4px var(--zui-step-indicator-item-border-color-selected)}:host([emphasis=selected][size=s]){font:var(--zui-typography-label1) !important}:host([emphasis=selected][size=m]){font:var(--zui-typography-subtitle1) !important}:host([emphasis=visited]){color:var(--zui-step-indicator-item-text-color-visited);box-shadow:inset 0 4px var(--zui-step-indicator-item-border-color-visited)}:host([emphasis=visited][size=s]){font:var(--zui-typography-caption) !important}:host([emphasis=visited][size=m]){font:var(--zui-typography-body) !important}.step-indicator-item-text{margin-top:4px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}"
// dependencies
import '../../truncate-with-tooltip/truncate-with-tooltip.component.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-step-indicator-item-text-color-next: var(--zui-color-gs-85);--zui-step-indicator-item-text-color-selected: var(--zui-color-gs-120);--zui-step-indicator-item-text-color-visited: var(--zui-color-gs-85);--zui-step-indicator-item-border-color-next: var(--zui-color-gs-80);--zui-step-indicator-item-border-color-selected: var(--zui-color-pb-100);--zui-step-indicator-item-border-color-visited: var(--zui-color-pb-70)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-step-indicator-item-text-color-next: var(--zui-color-gs-80);--zui-step-indicator-item-text-color-selected: var(--zui-color-gs-10);--zui-step-indicator-item-text-color-visited: var(--zui-color-gs-80);--zui-step-indicator-item-border-color-next: var(--zui-color-gs-80);--zui-step-indicator-item-border-color-selected: var(--zui-color-pb-90);--zui-step-indicator-item-border-color-visited: var(--zui-color-ac-db-110)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const stepIndicatorItemStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * The `zui-step-indicator-item` is part of the `zui-step-indicator-bar` and visualizes it's state (visited/selected/next) by the given emphasis.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.2?node-id=76965%3A368673)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=45219%3A117)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-step-indicator-item text="One" value="one"></zui-step-indicator-item>
 * ```
 */
let StepIndicatorItem = class StepIndicatorItem extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * the emphasis is set dependent on the parent `zui-step-indicator-bar` value and should not be set manually
         */
        this.emphasis = 'next';
        /**
         * each `zui-step-indicator-item` gets the size set for the `zui-step-indicator-bar` and should not be set manually
         */
        this.size = 'm';
    }
    render() {
        return html `<div class="step-indicator-item-text">
      <zui-truncate-with-tooltip
        delayed
        tooltip-trigger="hover,focus"
        interactive-element-selector="zui-step-indicator-item"
        >${this.text}</zui-truncate-with-tooltip
      >
    </div>`;
    }
};
StepIndicatorItem.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    stepIndicatorItemStyles,
];
__decorate([
    property({ reflect: true, type: String })
], StepIndicatorItem.prototype, "emphasis", void 0);
__decorate([
    property({ reflect: true, type: String })
], StepIndicatorItem.prototype, "size", void 0);
__decorate([
    property({ reflect: true, type: String })
], StepIndicatorItem.prototype, "text", void 0);
__decorate([
    property({ reflect: true, type: String })
], StepIndicatorItem.prototype, "value", void 0);
StepIndicatorItem = __decorate([
    customElement('zui-step-indicator-item')
], StepIndicatorItem);
export { StepIndicatorItem };
