import type { FC, ReactNode } from 'react';

import { useContent } from '../hooks/use-content';
import type { ContentString } from '../types';

interface ContentProps {
  content: ContentString;
}

const Content: FC<ContentProps> = ({ content }) => {
  const str = useContent(content);
  return <>{str}</>;
};

export function getContent(contentString: ContentString): ReactNode {
  return <Content content={contentString} />;
}
