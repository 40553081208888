import type { MenuContainerProps } from 'piral-menu';
import type { FC } from 'react';

import { ActionsMenu } from '../ActionsMenu';
import { NavigationBar } from '../NavigationBar';
import { UserMenuPopover } from '../UserMenuPopover';

export const MenuContainer: FC<MenuContainerProps> = ({ type, children }) => {
  if (type === 'general') {
    return <NavigationBar>{children}</NavigationBar>;
  }

  if (type === 'user') {
    return <UserMenuPopover>{children}</UserMenuPopover>;
  }

  return <ActionsMenu type={type}>{children}</ActionsMenu>;
};
