var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { property } from 'lit/decorators.js';
import { BaseElement } from '../base/base-element.class.js';
import { WithEventsMixin } from '../../mixins/events/events.mixin.js';
import '../../contracts/event.contracts.js';
import { GetSharedEventClass } from '../../contracts/event.classes.js';
import { DisabledMixin } from '../../mixins/disabled/disabled.mixin.js';
import 'lit';
class MenubarItemSelectedEvent extends GetSharedEventClass('menubar-item-selected', { bubbles: true }) {
}
/**
 * Abstract base class for all menubar-items that are used in the menubar.
 */
export class MenubarItemBase extends DisabledMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.SelectedEvent = MenubarItemSelectedEvent;
    }
})) {
    constructor() {
        super(...arguments);
        /**
         * Defines one of three possible emphasis states (default/selected/selected-primary).
         */
        this.emphasis = 'default';
        /**
         * Sets the Color variant of the Menu Item
         */
        this.variant = 'primary';
    }
}
__decorate([
    property({ reflect: true, type: String })
], MenubarItemBase.prototype, "emphasis", void 0);
__decorate([
    property({ reflect: true, type: String })
], MenubarItemBase.prototype, "value", void 0);
__decorate([
    property({ reflect: true, type: String })
], MenubarItemBase.prototype, "variant", void 0);
__decorate([
    property({ reflect: true, type: Number })
], MenubarItemBase.prototype, "group", void 0);
export class RealMenubarItemBase extends MenubarItemBase {
}
