import { getCsrfToken, targetsSameOrigin } from './util';

export function setCsrfForFetch(csrfCookieName: string, csrfHeaderName: string): void {
  const { fetch: originalFetch } = window;

  window.fetch = async (input, init) => {
    if (targetsSameOrigin(input)) {
      const csrfToken = getCsrfToken(csrfCookieName);

      if (!init) {
        if (input instanceof Request) {
          input.headers.append(csrfHeaderName, csrfToken);
        } else {
          init = {
            headers: {
              [csrfHeaderName]: csrfToken,
            },
          };
        }
      } else if (!init.headers) {
        init.headers = { [csrfHeaderName]: csrfToken };
      } else if (Array.isArray(init.headers)) {
        init.headers.push([csrfHeaderName, csrfToken]);
      } else if (init.headers instanceof Headers) {
        init.headers.append(csrfHeaderName, csrfToken);
      } else {
        init.headers[csrfHeaderName] = csrfToken;
      }
    }

    return originalFetch.call(window, input, init);
  };
}

export function setCsrfForXHR(csrfCookieName: string, csrfHeaderName: string): void {
  const originalOpen = XMLHttpRequest.prototype.open;

  // eslint-disable-next-line max-params
  XMLHttpRequest.prototype.open = function (
    method: string,
    url: any,
    async = true,
    username?: string,
    password?: string
  ) {
    const opened = originalOpen.call(this, method, url, Boolean(async), username, password);

    if (targetsSameOrigin(url)) {
      const csrfToken = getCsrfToken(csrfCookieName);
      this.setRequestHeader(csrfHeaderName, csrfToken);
    }

    return opened;
  };
}
