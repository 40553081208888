var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import '../../../types.js';
import { DelegateFocusMixin } from '../../../mixins/visual-focus/delegate-focus.mixin.js';
import '../time-picker-day-time-toggle/time-picker-day-time-toggle.component.js';
import { hostStyles } from '../../../host.styles.js';
const sharedStyles = ":host([disabled]){pointer-events:none}input{height:calc(var(--zui-gu) * 3.75);padding:0;color:var(--zui-color-text-default);font:var(--zui-typography-body);line-height:calc(var(--zui-gu) * 3.75);background-color:rgba(0,0,0,0);border:none;box-shadow:none}input:focus{outline:none}input:active{outline:none}input::placeholder{color:var(--zui-color-placeholder-input)}input::selection{background:var(--zui-color-selection-background)}input[type=number]{-moz-appearance:textfield}input[type=number]::-webkit-outer-spin-button,input[type=number]::-webkit-inner-spin-button{margin:0;-webkit-appearance:none}"
const styles = ":host{display:inline-flex}:host::before{width:.5ch;content:\"\"}input{width:calc(var(--zui-gu) * 2.75)}"
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-placeholder-input: var(--zui-color-gs-80);--zui-color-input-bottom-line-default-enabled: var(--zui-color-gs-60);--zui-color-input-bottom-line-default-hover: var(--zui-color-gs-120);--zui-color-input-bottom-line-default-focused: var(--zui-color-gs-100);--zui-color-input-bottom-line-default-pressed: var(--zui-color-gs-120)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-placeholder-input: var(--zui-color-gs-80);--zui-color-input-bottom-line-default-enabled: var(--zui-color-gs-120);--zui-color-input-bottom-line-default-hover: var(--zui-color-gs-120);--zui-color-input-bottom-line-default-focused: var(--zui-color-gs-120);--zui-color-input-bottom-line-default-pressed: var(--zui-color-gs-120)}"
import { GetSharedEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const timePickerInputPartStyles = css `
  ${unsafeCSS(sharedStyles)}
`;
const timePickerInputPartDayTimeComponentStyles = css `
  ${unsafeCSS(styles)}
`;
class TimePickerInputPartFocusPreviousEvent extends GetSharedEventClass('time-picker-input-part-focus-previous') {
}
class TimePickerInputPartDayTimeChangedEvent extends GetSharedEventClass('time-picker-input-part-day-time-changed') {
}
class TimePickerInputPartDayTimeInputEvent extends GetSharedEventClass('time-picker-input-part-day-time-input') {
}
/**
 * The `zui-time-picker-input-part-day-time` is part of the `zui-time-picker-input` and is not designed for single usage.
 *
 * @example
 * html```
 * <zui-time-picker-input-part-day-time></zui-time-picker-input-part-day-time>
 * ```
 */
let TimePickerInputPartDayTime = class TimePickerInputPartDayTime extends DisabledMixin(DelegateFocusMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.FocusPreviousEvent = TimePickerInputPartFocusPreviousEvent;
        this.ChangedEvent = TimePickerInputPartDayTimeChangedEvent;
        this.InputEvent = TimePickerInputPartDayTimeInputEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}))) {
    constructor() {
        super(...arguments);
        /**
         * readonly
         */
        this.readonly = false;
    }
    _handleTimePickerInputPartDayTimeFocusEvent() {
        this._inputRef.select();
    }
    _handleTimePickerInputPartDayTimeBluredEvent() {
        this._commitInputValue();
    }
    _commitInputValue() {
        const oldValue = this.value;
        this.value = this._inputRef.value.length > 0 ? this._inputRef.value : null;
        if (oldValue !== this.value) {
            this._emitTimePickerInputPartDayTimeChangedEvent({ value: this.value });
        }
    }
    _handleTimePickerInputPartDayTimeInputEvent(event) {
        event.stopPropagation();
        this._emitTimePickerInputPartDayTimeInputEvent({ value: this._inputRef.value });
    }
    _handleTimePickerInputPartDayTimeKeydownEvent(event) {
        if (this.readonly) {
            return;
        }
        switch (event.key) {
            case 'ArrowLeft':
            case 'ArrowRight':
            case 'Delete':
            case 'Tab':
                break;
            case 'a':
            case 'A':
                {
                    event.preventDefault();
                    this._inputRef.value = 'AM';
                    this._emitTimePickerInputPartDayTimeInputEvent({ value: this._inputRef.value });
                }
                break;
            case 'Backspace':
                if (this._inputRef.value === '') {
                    event.preventDefault();
                    this._emitTimePickerInputPartFocusPreviousEvent();
                }
                break;
            case 'p':
            case 'P':
                {
                    event.preventDefault();
                    this._inputRef.value = 'PM';
                    this._emitTimePickerInputPartDayTimeInputEvent({ value: this._inputRef.value });
                }
                break;
            case 'ArrowUp':
                {
                    event.preventDefault();
                    this._inputRef.value = this._inputRef.value === 'AM' ? 'PM' : 'AM';
                    this._emitTimePickerInputPartDayTimeInputEvent({ value: this._inputRef.value });
                }
                break;
            case 'ArrowDown':
                {
                    event.preventDefault();
                    this._inputRef.value = this._inputRef.value === 'AM' ? 'PM' : 'AM';
                    this._emitTimePickerInputPartDayTimeInputEvent({ value: this._inputRef.value });
                }
                break;
            case 'Enter':
                {
                    event.preventDefault();
                    this._commitInputValue();
                }
                break;
            default:
                event.preventDefault();
                break;
        }
    }
    render() {
        var _a;
        return html `
      <input
        .value="${(_a = this.value) !== null && _a !== void 0 ? _a : ''}"
        ?disabled="${this.disabled}"
        ?readonly="${this.readonly}"
        placeholder="${ifDefined(this.placeholder)}"
        type="text"
        @blur=${this._handleTimePickerInputPartDayTimeBluredEvent}
        @focus=${this._handleTimePickerInputPartDayTimeFocusEvent}
        @input=${this._handleTimePickerInputPartDayTimeInputEvent}
        @keydown=${this._handleTimePickerInputPartDayTimeKeydownEvent}
      />
    `;
    }
};
TimePickerInputPartDayTime.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    timePickerInputPartStyles,
    timePickerInputPartDayTimeComponentStyles,
];
__decorate([
    property({ reflect: true, type: String })
], TimePickerInputPartDayTime.prototype, "placeholder", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], TimePickerInputPartDayTime.prototype, "readonly", void 0);
__decorate([
    property({ reflect: true, type: String })
], TimePickerInputPartDayTime.prototype, "value", void 0);
__decorate([
    query('input')
], TimePickerInputPartDayTime.prototype, "_inputRef", void 0);
TimePickerInputPartDayTime = __decorate([
    customElement('zui-time-picker-input-part-day-time')
], TimePickerInputPartDayTime);
export { TimePickerInputPartDayTime };
