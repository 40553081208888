var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{--zui-state-label-background-color: var(--zui-color-state-label-background-color-default);--zui-state-label-text-color: var(--zui-color-state-label-color-default);--zui-state-label-width: calc(var(--zui-gu) * 10);display:flex;align-items:center;justify-content:center;width:var(--zui-state-label-width);min-width:0;max-width:100%;height:calc(var(--zui-gu) * 3);padding:0 calc(var(--zui-gu) * 1);color:var(--zui-state-label-text-color);background-color:var(--zui-state-label-background-color)}.state-label-text{overflow:hidden;white-space:nowrap;text-transform:uppercase;text-overflow:ellipsis}zui-truncate-with-tooltip{font:var(--zui-typography-label2) !important;text-transform:var(--zui-typography-label2-text-transform)}:host([state=default]){--zui-state-label-background-color: var(--zui-color-state-label-background-color-default);--zui-state-label-text-color: var(--zui-color-state-label-text-color-default)}:host([state=error]){--zui-state-label-background-color: var(--zui-color-state-label-background-color-error);--zui-state-label-text-color: var(--zui-color-state-label-text-color-error)}:host([state=warning]){--zui-state-label-background-color: var(--zui-color-state-label-background-color-warning);--zui-state-label-text-color: var(--zui-color-state-label-text-color-warning)}:host([state=progress]){--zui-state-label-background-color: var(--zui-color-state-label-background-color-progress);--zui-state-label-text-color: var(--zui-color-state-label-text-color-progress)}:host([state=ready]){--zui-state-label-background-color: var(--zui-color-state-label-background-color-ready);--zui-state-label-text-color: var(--zui-color-state-label-text-color-ready)}"
// dependencies
import '../../truncate-with-tooltip/truncate-with-tooltip.component.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-state-label-background-color-default: var(--zui-color-gs-35);--zui-color-state-label-background-color-error: rgba(var(--zui-color-sc-r-100-rgb), 0.2);--zui-color-state-label-background-color-progress: rgba(var(--zui-color-pb-100-rgb), 0.2);--zui-color-state-label-background-color-ready: rgba(var(--zui-color-sc-g-110-rgb), 0.2);--zui-color-state-label-background-color-warning: rgba(var(--zui-color-sc-y-100-rgb), 0.2);--zui-color-state-label-text-color-default: var(--zui-color-gs-100);--zui-color-state-label-text-color-error: var(--zui-color-sc-r-100);--zui-color-state-label-text-color-progress: var(--zui-color-pb-100);--zui-color-state-label-text-color-ready: var(--zui-color-sc-g-110);--zui-color-state-label-text-color-warning: var(--zui-color-sc-y-110)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-state-label-background-color-default: var(--zui-color-gs-110);--zui-color-state-label-background-color-error: rgba(var(--zui-color-sc-r-100-rgb), 0.2);--zui-color-state-label-background-color-progress: rgba(var(--zui-color-pb-90-rgb), 0.2);--zui-color-state-label-background-color-ready: rgba(var(--zui-color-sc-g-110-rgb), 0.2);--zui-color-state-label-background-color-warning: rgba(var(--zui-color-sc-y-100-rgb), 0.2);--zui-color-state-label-text-color-default: var(--zui-color-gs-50);--zui-color-state-label-text-color-error: var(--zui-color-sc-r-100);--zui-color-state-label-text-color-progress: var(--zui-color-pb-90);--zui-color-state-label-text-color-ready: var(--zui-color-sc-g-110);--zui-color-state-label-text-color-warning: var(--zui-color-sc-y-110)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const stateLabelStyles = css `
  ${unsafeCSS(styles)}
`;
/**
 * The state label is a colored rectangle which has a background and text color based on its state.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=23531%3A787239)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=12425%3A0)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-state-label state="ready">
 *   <span>Ready</span>
 * </zui-state-label>
 * ```
 *
 * @slot - default slot for state label text
 *
 * @cssprop --zui-state-label-background-color - override background color
 * @cssprop --zui-state-label-text-color - override text color
 * @cssprop --zui-state-label-width - override default width of 80px
 */
let StateLabel = class StateLabel extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * Sets the state of the StateLabel
         */
        this.state = 'default';
        /**
         * show / hide zui tooltip
         */
        this.hideTooltip = false;
    }
    render() {
        return html `<div class="state-label-text">
      ${!this.hideTooltip
            ? html `<zui-truncate-with-tooltip
            delayed
            tooltip-trigger="hover,focus"
            interactive-element-selector="zui-state-label"
            preserve-markup
            ><slot></slot
          ></zui-truncate-with-tooltip>`
            : html `<slot></slot>`}
    </div>`;
    }
};
StateLabel.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    stateLabelStyles,
];
__decorate([
    property({ reflect: true, type: String })
], StateLabel.prototype, "state", void 0);
__decorate([
    property({ reflect: true, attribute: 'hide-tooltip', type: Boolean })
], StateLabel.prototype, "hideTooltip", void 0);
StateLabel = __decorate([
    customElement('zui-state-label')
], StateLabel);
export { StateLabel };
