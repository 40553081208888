var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement, RealBaseElement } from '../../base/base-element.class.js';
import { FocusDifferentiationMixin } from '../../../mixins/visual-focus/focus-differentiation.mixin.js';
import { hostStyles } from '../../../host.styles.js';
const style = ":host([zui-keyboard-focused]) h1{border:1px dashed;border-radius:var(--zui-border-radius-on-focus);outline:none}h1{margin:0;color:var(--zui-color-ewiq-dialog-header-text-color);font:var(--zui-typography-h1);border:1px solid rgba(0,0,0,0)}"
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-ewiq-dialog-header-text-color: var(--zui-color-gs-120)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-ewiq-dialog-header-text-color: var(--zui-color-gs-10)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const ewiqDialogHeadlineStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * This represents a very simple headline component, which is used in other components, e.g. EwiqDialog. It provides a default slot,
 * which is wrapped in a zui-styled h1 tags
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-ewiq-dialog-headline>Test</zui-ewiq-dialog-headline>
 * ```
 *
 * @slot - This is the default slot to be used for the headline text
 */
let EwiqDialogHeadline = class EwiqDialogHeadline extends FocusDifferentiationMixin(RealBaseElement) {
    constructor() {
        super(...arguments);
        /**
         * ARIA role for this element; defaults to 'heading' if not explicitly set by author
         */
        this.role = 'heading';
    }
    render() {
        return html `<h1 tabindex="0"><slot></slot></h1>`;
    }
};
EwiqDialogHeadline.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    ewiqDialogHeadlineStyles,
];
__decorate([
    property({ reflect: true })
], EwiqDialogHeadline.prototype, "role", void 0);
EwiqDialogHeadline = __decorate([
    customElement('zui-ewiq-dialog-headline')
], EwiqDialogHeadline);
export { EwiqDialogHeadline };
