import { Menu } from 'piral-menu';
import type { FC } from 'react';

export interface AnyMenuProps {
  type: string;
}

/**
 * Exposes the `Menu` component as an extension component.
 * This is registered as the `app-shell-any-menu` extension.
 * Can be used in any pilet to render an arbitrary (second-level)
 * menu.
 */
export const AnyMenu: FC<AnyMenuProps> = ({ type }) => <Menu type={type} />;
