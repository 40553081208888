var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { hostStyles } from '../../host.styles.js';
import { BaseElement, RealBaseElement } from '../base/base-element.class.js';
const style = ":host{--zui-expander-width: 100%;--zui-expander-height: calc(var(--zui-gu) * 2)}:host{width:var(--zui-expander-width);height:var(--zui-expander-height);min-height:calc(var(--zui-gu) * 2)}:host button{display:grid;width:100%;height:100%;color:var(--zui-color-expander-icon-color);border:none;outline:none;cursor:pointer;place-items:center}:host button:disabled{cursor:default;opacity:var(--zui-disabled-opacity)}:host([hierarchy=first]) button{background-color:var(--zui-color-expander-bg-first-hierarchy-enabled)}:host([hierarchy=first]) button:focus{background-color:var(--zui-color-expander-bg-first-hierarchy-focus)}:host([hierarchy=first]) button:hover{background-color:var(--zui-color-expander-bg-first-hierarchy-hover)}:host([hierarchy=first]) button:active{background-color:var(--zui-color-expander-bg-first-hierarchy-active)}:host([hierarchy=first]) button:disabled{background-color:var(--zui-color-expander-bg-first-hierarchy-disabled)}:host([hierarchy=second]) button{background-color:var(--zui-color-expander-bg-second-hierarchy-enabled)}:host([hierarchy=second]) button:focus{background-color:var(--zui-color-expander-bg-second-hierarchy-focus)}:host([hierarchy=second]) button:hover{background-color:var(--zui-color-expander-bg-second-hierarchy-hover)}:host([hierarchy=second]) button:active{background-color:var(--zui-color-expander-bg-second-hierarchy-active)}:host([hierarchy=second]) button:disabled{background-color:var(--zui-color-expander-bg-second-hierarchy-disabled)}"
import { DisabledMixin } from '../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-expander-bg-first-hierarchy-enabled: var(--zui-color-gs-10);--zui-color-expander-bg-first-hierarchy-disabled: var(--zui-color-gs-10);--zui-color-expander-bg-first-hierarchy-hover: var(--zui-color-gs-20);--zui-color-expander-bg-first-hierarchy-focus: var(--zui-color-gs-10);--zui-color-expander-bg-first-hierarchy-active: var(--zui-color-gs-30);--zui-color-expander-bg-second-hierarchy-enabled: var(--zui-color-gs-20);--zui-color-expander-bg-second-hierarchy-disabled: var(--zui-color-gs-20);--zui-color-expander-bg-second-hierarchy-hover: var(--zui-color-gs-30);--zui-color-expander-bg-second-hierarchy-focus: var(--zui-color-gs-20);--zui-color-expander-bg-second-hierarchy-active: var(--zui-color-gs-40);--zui-color-expander-icon-color: var(--zui-color-gs-80)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-expander-bg-first-hierarchy-enabled: var(--zui-color-gs-100);--zui-color-expander-bg-first-hierarchy-disabled: var(--zui-color-gs-100);--zui-color-expander-bg-first-hierarchy-hover: var(--zui-color-gs-105);--zui-color-expander-bg-first-hierarchy-focus: var(--zui-color-gs-100);--zui-color-expander-bg-first-hierarchy-active: var(--zui-color-gs-110);--zui-color-expander-bg-second-hierarchy-enabled: var(--zui-color-gs-105);--zui-color-expander-bg-second-hierarchy-disabled: var(--zui-color-gs-105);--zui-color-expander-bg-second-hierarchy-hover: var(--zui-color-gs-110);--zui-color-expander-bg-second-hierarchy-focus: var(--zui-color-gs-105);--zui-color-expander-bg-second-hierarchy-active: var(--zui-color-gs-115);--zui-color-expander-icon-color: var(--zui-color-gs-80)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
// icons
import '@zeiss/zui-icons/dist/components/generated/arrows-arrow-outline-actually-centred-down.component.js';
import '@zeiss/zui-icons/dist/components/generated/arrows-arrow-outline-actually-centred-up.component.js';
const expanderStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * The zui-expander is a semantic button that indicates the ability to expand or collapse a surrounding element (e.g. \<zui-box\>).
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---6.5)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=3095%3A208504)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-expander closed hierarchy="second" disabled>
 * </zui-expander>
 *  ```
 *
 * @cssprop --zui-expander-width - sets the expander width manually
 * @cssprop --zui-expander-height - sets the expander height manually, minimum is 16px
 */
let Expander = class Expander extends DisabledMixin(RealBaseElement) {
    constructor() {
        super(...arguments);
        /**
         * Sets the state of the component, in particular if the icon is shown closed/open.
         */
        this.closed = false;
        /**
         * Defines one of two possible hierarchy level (first/second).
         * Affects the background color of the component.
         */
        this.hierarchy = 'first';
    }
    /**
     * **deprecated** instead of 'tabindex' please use 'tabIndex', notice upper case 'I'.
     * We added this property with incorrect spelling (i in lower case), all components inherit the native tabIndex
     * property and tabindex attribute.
     * The tabindex property with wrong spelling will be removed, this only concerns the property and not the attribute.
     *
     * @deprecated
     */
    set tabindex(index) {
        const oldValue = this.tabIndex;
        this.tabIndex = index;
        // if we write our own setter we must call this. Lit documentation: https://lit.dev/docs/v1/components/properties/#accessors-custom
        this.requestUpdate('tabindex', oldValue);
    }
    get tabindex() {
        return this.tabIndex;
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.hasAttribute('tabindex')) {
            this.tabIndex = 0;
        }
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('tabindex')) {
            console.warn(`Deprecated tabindex property with the value: ${this.tabindex} was used on zui-expander. Please use the native tabIndex property (notice upper case 'I').`);
        }
    }
    render() {
        return html `
      <button ?disabled=${this.disabled}>
        ${this.closed
            ? html `<zui-icon-arrows-arrow-outline-actually-centred-down
              size="s12"
            ></zui-icon-arrows-arrow-outline-actually-centred-down>`
            : html `<zui-icon-arrows-arrow-outline-actually-centred-up
              size="s12"
            ></zui-icon-arrows-arrow-outline-actually-centred-up>`}
      </button>
    `;
    }
};
Expander.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    expanderStyles,
];
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'closed' })
], Expander.prototype, "closed", void 0);
__decorate([
    property({ noAccessor: true, attribute: false })
], Expander.prototype, "tabindex", null);
__decorate([
    property({ reflect: true, type: String })
], Expander.prototype, "hierarchy", void 0);
Expander = __decorate([
    customElement('zui-expander')
], Expander);
export { Expander };
