var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
import { commaListConverter } from '../../../utils/component.utils.js';
import '../content-accordion-details/content-accordion-details.component.js';
import { GetNotificationEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const style = ":host{display:flex;flex-direction:column;align-items:center;justify-content:center}"
const theme = ""
const themeDark = ""
const themeLight = ""
const contentAccordionStyles = css `
  ${unsafeCSS(style)}
`;
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
class ContentAccordionOpenChangedEvent extends GetNotificationEventClass('content-accordion-open-changed') {
}
/**
 * This component allow "stacking" multiple accordion details.
 * It can work in single and multi mode. Opening / closing is done by clicking the mouse on any item but also by setting
 * the value attribute for content accordion. In the case of the multi select option, items in the value attribute
 * are separated with a comma (see example below).
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=15382%3A3275)
 * - [Styleguide – Desktop](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=33682%3A167)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-content-accordion integrated multi value="one,two">
 *  <zui-content-accordion-details value="one" headline-text="Enabled"></zui-content-accordion-details>
 *  <zui-content-accordion-details value="two" headline-text="Enabled">></zui-content-accordion-details>
 *  <zui-content-accordion-details value="three" headline-text="Enabled">></zui-content-accordion-details>
 * </zui-content-accordion>
 * ```
 *
 * Content Accordion with Skeleton
 * ```html
 * <zui-content-accordion integrated multi value="one,two">
 *  <zui-content-accordion-details value="one">
 *    <zui-skeleton-rectangle
 *      slot="skeleton-headline-text"
 *      style="--zui-skeleton-rectangle-width: 400px; --zui-skeleton-rectangle-height: 20px;"
 *    ></zui-skeleton-rectangle>
 *  </zui-content-accordion-details>
 *  <zui-content-accordion-details value="two">
 *    <zui-skeleton-rectangle
 *      slot="skeleton-headline-text"
 *      style="--zui-skeleton-rectangle-width: 400px; --zui-skeleton-rectangle-height: 20px;"
 *    ></zui-skeleton-rectangle>
 *  </zui-content-accordion-details>
 * </zui-content-accordion>
 * ```
 *
 *
 * @slot default - Here you can insert your zui-accordion-details items
 * @fires {GetZuiEvent<ContentAccordion, 'OpenChangedEvent'>} zui-content-accordion-open-changed - emits an array with opened items, when content accordion details is clicked.
 */
let ContentAccordion = class ContentAccordion extends DisabledMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.OpenChangedEvent = ContentAccordionOpenChangedEvent;
    }
})) {
    constructor() {
        super(...arguments);
        /**
         * Defines possible sizes (s/m/l). Default: m.
         */
        this.size = 'm';
        /**
         * Defines integrated attribute
         */
        this.integrated = false;
        /**
         * It is possible to set multi select, which allows you to open several items at the same time
         */
        this.multi = false;
        /**
         * Specifies which item is open or which items are open when multi is selected.
         * Values are comma-separated on the attribute and an array on the property.
         */
        this.value = [];
        this._propagateDisabled = false;
    }
    _propagateState() {
        this._assignedAccordion.forEach((accordion) => {
            if (this._propagateDisabled) {
                accordion.disabled = this.disabled;
            }
            accordion.integrated = this.integrated;
            accordion.size = this.size;
            accordion.open = this.value.includes(accordion.value);
        });
    }
    _handleOpenStateChanged(event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        if (!event.detail.opened) {
            this.value = this.value.filter((element) => element !== event.detail.value);
        }
        else if (this.multi) {
            this.value = [...this.value, event.detail.value];
        }
        else if (!this.multi) {
            this.value = [event.detail.value];
        }
        this.emitContentAccordionOpenChangedEvent();
    }
    _handleSlotChange() {
        this._propagateState();
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (this.disabled || changedProperties.get('disabled')) {
            this._propagateDisabled = true;
        }
        this._propagateState();
    }
    render() {
        return html `<slot
      @zui-content-accordion-details-toggled="${this._handleOpenStateChanged}"
      @slotchange="${this._handleSlotChange}"
    ></slot>`;
    }
};
ContentAccordion.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    contentAccordionStyles,
];
__decorate([
    property({ reflect: true, type: String })
], ContentAccordion.prototype, "size", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], ContentAccordion.prototype, "integrated", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], ContentAccordion.prototype, "multi", void 0);
__decorate([
    property({ reflect: true, type: String, converter: commaListConverter() })
], ContentAccordion.prototype, "value", void 0);
__decorate([
    queryAssignedElements({ selector: 'zui-content-accordion-details', flatten: true })
], ContentAccordion.prototype, "_assignedAccordion", void 0);
ContentAccordion = __decorate([
    customElement('zui-content-accordion')
], ContentAccordion);
export { ContentAccordion };
