import type { PiralPlugin } from 'piral-core';
import { v4 as uuidv4 } from 'uuid';

import { addNotificationToQueue } from './state';
import { createNotificationsSync } from './sync';
import type { BellNotification, BellNotificationData, BellNotificationItemApi } from './types';

interface BellNotificationsPiralState {
  bellNotifications: Array<BellNotification>;
}

/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'piral-core/lib/types/custom' {
  interface PiletCustomApi extends PiletBellNotificationsApi {}
  interface PiralCustomState extends BellNotificationsPiralState {}
}

export interface PiletBellNotificationsApi {
  /**
   * Gives the option to add a new notification to the bell notification queue
   *
   * @param {BellNotificationData} notificationData
   */
  queueNotification(notificationData: BellNotificationData): BellNotificationItemApi;
}

export function createBellNotificationApi(): PiralPlugin<PiletBellNotificationsApi> {
  const defaultNotificationData = {
    origin: '',
    severity: 'INFO',
    title: '',
    description: '',
  };

  return (context) => {
    const tabId = uuidv4();
    createNotificationsSync(context, tabId);

    return () => ({
      queueNotification(notificationData) {
        const id = uuidv4();
        const notification: BellNotification = {
          ...defaultNotificationData,
          ...notificationData,
          id,
          fromCurrentTab: true,
          tabId,
          state: 'unread',
          timestamp: notificationData.timestamp || new Date(),
        };

        context.dispatch((state) => ({
          ...state,
          bellNotifications: addNotificationToQueue(state.bellNotifications, notification),
        }));

        return {
          id,
        };
      },
    });
  };
}
