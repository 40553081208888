var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, query, queryAssignedElements } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { BaseElement } from '../base/base-element.class.js';
import { hostStyles } from '../../host.styles.js';
const styles = ":host{display:block}#container{display:flex;flex-direction:row;align-items:center;justify-content:space-between;width:100%;height:calc(var(--zui-gu) * 8);background-color:var(--zui-focus-view-bar-background-color)}#left-icon-button{display:flex;flex-direction:row;align-items:center;justify-content:start}#left-aligned-content{display:flex;flex-direction:row;align-items:center;justify-content:start;height:100%;overflow:hidden}#center-aligned-content{display:flex;flex-direction:row;flex-shrink:0;align-items:center;justify-content:center;height:100%;margin-right:calc(var(--zui-gu) * 2);margin-left:calc(var(--zui-gu) * 2);overflow:hidden}#center-aligned-content.used{margin-right:calc(var(--zui-gu) * 4);margin-left:calc(var(--zui-gu) * 4)}#right-aligned-content{display:flex;flex-direction:row;align-items:center;justify-content:end;height:100%;overflow:hidden}#right-icon-button{display:flex;flex-direction:row;align-items:center;justify-content:end}#left{display:flex;flex-direction:row;flex-shrink:1;align-items:center;justify-content:start;height:100%;overflow:hidden}#left.separator zui-divider,#right.separator zui-divider{display:unset;flex-shrink:0}zui-divider{display:none;height:calc(var(--zui-gu) * 8)}#right{display:flex;flex-direction:row;flex-shrink:1;align-items:center;justify-content:end;height:100%;overflow:hidden}"
// dependencies
import '../divider/divider.component.js';
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-focus-view-bar-background-color: var(--zui-color-gs-10)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-focus-view-bar-background-color: var(--zui-color-gs-100)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const focusViewBarStyles = css `
  ${unsafeCSS(styles)}
`;
/**
 * The Focus View Bar is a different type of headerbar for the modal focus view dialog. It has slots for left, right and center content and additional allows to show up to two buttons at the sides.
 * If you are using the left or right content together with the center content it is important to implement custom breakpoints, so that you can control what happens with the content when there is not enough space.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/z4fyXFOJCpuaNImx3K234n/%E2%9D%96-04-Web---Component-Library---1.5?node-id=763%3A46553)
 * - [Styleguide – Desktop](https://www.figma.com/file/6dkjypErYWQPfuRBD58Aey/%F0%9F%93%96--Styleguide---Web?node-id=7683%3A79745)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-focus-view-bar>
 *   <zui-headerbar-icon-button slot="left-icon-button" emphasis="subtle" size="m">
 *     <zui-icon-arrows-arrow-outline-actually-centred-left slot="icon"></zui-icon-arrows-arrow-outline-actually-centred-left>
 *   </zui-headerbar-icon-button>
 *   <div slot="left-content" style="font: var(--zui-typography-h3); margin-left: 32px;">Left</div>
 *   <div slot="center-content" style="font: var(--zui-typography-h3);">Center</div>
 *   <div slot="right-content" style="font: var(--zui-typography-h3); margin-right: 32px;">Right</div>
 *   <zui-headerbar-icon-button slot="right-icon-button" emphasis="subtle" size="m">
 *     <zui-icon-symbols-close slot="icon"></zui-icon-symbols-close>
 *   </zui-headerbar-icon-button>
 * </zui-focus-view-bar>
 * ```
 * @slot left-icon-button - This is the slot for zui-headerbar-icon-button that gets displayed on the left side.
 * @slot right-icon-button - This is the slot for zui-headerbar-icon-button that gets displayed on the right side.
 * @slot left-content - This is the slot for the content which gets displayed on the left side.
 * @slot right-content - This is the slot for the content which gets displayed on the right side.
 * @slot center-content - This is the slot for the content which gets displayed in the center of the component.
 */
let FocusViewBar = class FocusViewBar extends BaseElement {
    constructor() {
        super(...arguments);
        this._widthCenter = 0;
        this._widthfocusViewBar = 0;
        this._centerAlignedContentResizeObserver = new ResizeObserver(([{ contentRect }]) => {
            requestAnimationFrame(() => {
                this._widthCenter = contentRect.width;
                this.requestUpdate();
            });
        });
        this._focusViewBarResizeObserver = new ResizeObserver(([{ contentRect }]) => {
            requestAnimationFrame(() => {
                this._widthfocusViewBar = contentRect.width;
                this.requestUpdate();
            });
        });
    }
    /**
     * The left separator should only be shown if both the left icon button and the left content are visible.
     * This function checks if the corresponding slots are used.
     */
    get _isLeftseparatorNeeded() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return this._leftContent.length > 0 && this._leftIconButton.length > 0;
    }
    /**
     * The right separator should only be shown if both the right icon button and the right content are visible.
     * This function checks if the corresponding slots are used.
     */
    get _isRightseparatorNeeded() {
        return this._rightContent.length > 0 && this._rightIconButton.length > 0;
    }
    get _isLeftContentUsed() {
        return this._leftContent.length > 0;
    }
    get _isRightContentUsed() {
        return this._rightContent.length > 0;
    }
    get _isCenterContentUsed() {
        return this._centerContent.length > 0;
    }
    disconnectedCallback() {
        this._centerAlignedContentResizeObserver.disconnect();
        this._focusViewBarResizeObserver.disconnect();
        super.disconnectedCallback();
    }
    /**
     * When slot content changes we need to update css classes. For this we need to trigger a rerendering on every slotchange.
     */
    _handleSlotchange() {
        this.requestUpdate();
    }
    get _minWidth() {
        const margin = this._isCenterContentUsed ? 64 : 32;
        return `${(this._widthfocusViewBar - this._widthCenter - margin) / 2}px`;
    }
    get _maxWidth() {
        const margin = this._isCenterContentUsed ? 64 : 32;
        const maxCenterContentWidth = this._widthfocusViewBar - margin - 130;
        return maxCenterContentWidth < 0 ? '0px' : `${maxCenterContentWidth}px`;
    }
    firstRendered() {
        super.firstRendered();
        this._centerAlignedContentResizeObserver.observe(this._centerContainer);
        this._focusViewBarResizeObserver.observe(this);
    }
    render() {
        return html `
      <div id="container">
        <div
          id="left"
          class="${classMap({ separator: this._isLeftseparatorNeeded })}"
          style="min-width: ${this._minWidth}"
        >
          <div id="left-icon-button">
            <slot @slotchange="${this._handleSlotchange}" name="left-icon-button"></slot>
          </div>
          <zui-divider emphasis="default" size="s" orientation="vertical"></zui-divider>
          <div id="left-aligned-content" class="${classMap({ used: this._isLeftContentUsed })}"
            ><slot name="left-content" @slotchange="${this._handleSlotchange}"></slot
          ></div>
        </div>
        <div
          id="center-aligned-content"
          class="${classMap({ used: this._isCenterContentUsed })}"
          style="max-width: ${this._maxWidth};"
          ><slot name="center-content" @slotchange="${this._handleSlotchange}"></slot
        ></div>
        <div
          id="right"
          class="${classMap({ separator: this._isRightseparatorNeeded })}"
          style="min-width: ${this._minWidth}"
        >
          <div id="right-aligned-content" class="${classMap({ used: this._isRightContentUsed })}"
            ><slot name="right-content" @slotchange="${this._handleSlotchange}"></slot
          ></div>
          <zui-divider emphasis="default" size="s" orientation="vertical"></zui-divider>
          <div id="right-icon-button"
            ><slot @slotchange="${this._handleSlotchange}" name="right-icon-button"></slot
          ></div>
        </div>
      </div>
    `;
    }
};
FocusViewBar.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    focusViewBarStyles,
];
__decorate([
    queryAssignedElements({ slot: 'left-icon-button', flatten: true })
], FocusViewBar.prototype, "_leftIconButton", void 0);
__decorate([
    queryAssignedElements({ slot: 'left-content', flatten: true })
], FocusViewBar.prototype, "_leftContent", void 0);
__decorate([
    queryAssignedElements({ slot: 'right-icon-button', flatten: true })
], FocusViewBar.prototype, "_rightIconButton", void 0);
__decorate([
    queryAssignedElements({ slot: 'right-content', flatten: true })
], FocusViewBar.prototype, "_rightContent", void 0);
__decorate([
    queryAssignedElements({ slot: 'center-content', flatten: true })
], FocusViewBar.prototype, "_centerContent", void 0);
__decorate([
    query('#center-aligned-content', true)
], FocusViewBar.prototype, "_centerContainer", void 0);
FocusViewBar = __decorate([
    customElement('zui-focus-view-bar')
], FocusViewBar);
export { FocusViewBar };
