import type { ComponentsState, ErrorComponentsState } from 'piral-core';

import { DefaultDialogLayout } from './DefaultDialogLayout';
import { ErrorInfo } from './ErrorInfo';
import { Layout } from './Layout';
import { LoadingIndicator } from './LoadingIndicator';
import { MenuContainer } from './MenuContainer';
import { MenuItem } from './MenuItem';
import { ModalContainer } from './ModalContainer';
import { NotFoundError } from './NotFoundError';
import { NotificationsHost } from './NotificationsHost';
import { NotificationsToast } from './NotificationsToast';

export function createShellLayoutComponents(): Partial<ComponentsState> {
  return {
    ErrorInfo,
    Layout,
    ModalsDialog: DefaultDialogLayout,
    ModalsHost: ModalContainer,
    NotificationsHost,
    NotificationsToast,
    LoadingIndicator,
    MenuContainer,
    MenuItem,
  };
}

export function createShellErrorComponents(): Partial<ErrorComponentsState> {
  return {
    not_found: NotFoundError,
  };
}
