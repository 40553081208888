import type { BellNotification } from './types';

const MAX_NOTIFICATIONS = 100;

function sortBellNotificationsByTimestamp(a: BellNotification, b: BellNotification) {
  return b.timestamp.valueOf() - a.timestamp.valueOf();
}

export function normalizeNotificationsQueue(
  newNotifications: Array<BellNotification>
): Array<BellNotification> {
  newNotifications.sort(sortBellNotificationsByTimestamp);

  if (newNotifications.length > MAX_NOTIFICATIONS) {
    return newNotifications.slice(0, MAX_NOTIFICATIONS);
  }

  return newNotifications;
}

export function addNotificationToQueue(
  bellNotifications: Array<BellNotification>,
  newNotification: BellNotification
): Array<BellNotification> {
  return normalizeNotificationsQueue([...bellNotifications, newNotification]);
}
