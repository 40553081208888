var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAsync } from 'lit/decorators.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{--zui-picker-grid-cell-height: calc(var(--zui-gu) * 4);--zui-picker-grid-cell-min-width: calc(var(--zui-gu) * 4);--zui-picker-grid-cell-border: 1px solid;width:var(--zui-picker-grid-cell-width, auto);min-width:var(--zui-picker-grid-cell-min-width);height:var(--zui-picker-grid-cell-height)}:host(:focus),button:focus{outline:none}:host([disabled]) button{pointer-events:none}:host([disabled]) span{text-decoration:line-through}:host([emphasis=selected]) button{background:none}:host([emphasis=selected]) button{background:var(--zui-color-grid-cell-background-selected)}:host([emphasis=selected]) button:hover{background:var(--zui-color-grid-cell-background-selected)}:host([emphasis=selected]) button:focus{background:var(--zui-color-grid-cell-background-selected)}:host([emphasis=default]) button:hover,:host([emphasis=subtle]) button:hover{border-color:var(--zui-color-grid-cell-border-hover)}:host([emphasis=default]) button:focus,:host([emphasis=subtle]) button:focus{border-color:var(--zui-color-grid-cell-border-pressed)}:host([disabled][emphasis=default]) span{color:var(--zui-color-grid-cell-text-default-disabled);opacity:.5}:host([emphasis=default]) span{color:var(--zui-color-grid-cell-text-default-enabled)}:host(:not([disabled])[emphasis=default]:hover) button span{color:var(--zui-color-grid-cell-text-default-hover)}:host([disabled][emphasis=selected]) button{opacity:.5}:host([emphasis=selected]) span{color:var(--zui-color-grid-cell-text-selected)}:host([emphasis=subtle]) span{color:var(--zui-color-grid-cell-text-subtle);opacity:.5}button{width:100%;height:100%;margin:0;padding:0;background:rgba(0,0,0,0);border:var(--zui-picker-grid-cell-border) rgba(0,0,0,0)}span{display:block;max-height:calc(var(--zui-picker-grid-cell-height) - calc(var(--zui-gu) * 1));margin-bottom:2px;overflow:hidden;font:var(--zui-typography-caption)}"
import { ifDefined } from 'lit/directives/if-defined.js';
import { BaseElement } from '../../base/base-element.class.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-grid-cell-background-selected: var(--zui-color-gs-80);--zui-color-grid-cell-text-default-enabled: var(--zui-color-gs-120);--zui-color-grid-cell-text-default-disabled: var(--zui-color-gs-80);--zui-color-grid-cell-text-default-hover: var(--zui-color-gs-100);--zui-color-grid-cell-text-selected: var(--zui-color-gs-10);--zui-color-grid-cell-text-subtle: var(--zui-color-gs-80);--zui-color-grid-cell-border-hover: var(--zui-color-gs-40);--zui-color-grid-cell-border-pressed: var(--zui-color-gs-60)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-grid-cell-background-selected: var(--zui-color-gs-80);--zui-color-grid-cell-text-default-enabled: var(--zui-color-gs-10);--zui-color-grid-cell-text-default-disabled: var(--zui-color-gs-80);--zui-color-grid-cell-text-default-hover: var(--zui-color-gs-10);--zui-color-grid-cell-text-selected: var(--zui-color-gs-10);--zui-color-grid-cell-text-subtle: var(--zui-color-gs-80);--zui-color-grid-cell-border-hover: var(--zui-color-gs-80);--zui-color-grid-cell-border-pressed: var(--zui-color-gs-60)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const gridCellStyles = css `
  ${unsafeCSS(styles)}
`;
/**
 * A rectangular styled cell UI component, which is used in the general picker widget
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-picker-grid-cell
 *  value="february"
 *  emphasis="selected"
 *  style="--zui-picker-grid-cell-width: 40px; --zui-picker-grid-cell-height: 40px"
 * >Feb</zui-picker-grid-cell>
 * ```
 *
 * @slot - default slot for text content
 * @cssprop --zui-picker-grid-cell-border - border style of the cell
 * @cssprop --zui-picker-grid-cell-height - sets the height of the cell
 * @cssprop --zui-picker-grid-cell-min-width - sets the minimum width of the cell
 */
let PickerGridCell = class PickerGridCell extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * disabled state of the grid cell
         */
        // TODO: implement DisabledMixin.
        // There is a spec for picker-grid that expects a click event even for disabled cells.
        // This looks strange and we should check if this is correct.
        this.disabled = false;
        /**
         * Defines one of three possible emphasis states (default/selected/subtle);
         */
        this.emphasis = 'default';
    }
    // TODO: this should be an mixin
    focus(options = { preventScroll: true }) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.disabled) {
                const button = yield this._pickerGridCellButton;
                button.focus(options);
            }
        });
    }
    blur() {
        return __awaiter(this, void 0, void 0, function* () {
            const button = yield this._pickerGridCellButton;
            button.blur();
        });
    }
    // map a click to a .focus() for Firefox + Webkit
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('click', this._handleClick);
    }
    disconnectedCallback() {
        this.removeEventListener('click', this._handleClick);
        super.disconnectedCallback();
    }
    _handleClick() {
        this.focus();
    }
    render() {
        return html `<button ?disabled="${this.disabled}" value="${ifDefined(this.value)}"
      ><span><slot></slot></span
    ></button>`;
    }
};
PickerGridCell.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    gridCellStyles,
];
__decorate([
    property({ reflect: true, type: Boolean })
], PickerGridCell.prototype, "disabled", void 0);
__decorate([
    property({ reflect: true, type: String })
], PickerGridCell.prototype, "value", void 0);
__decorate([
    property({ reflect: true })
], PickerGridCell.prototype, "emphasis", void 0);
__decorate([
    queryAsync('button')
], PickerGridCell.prototype, "_pickerGridCellButton", void 0);
PickerGridCell = __decorate([
    customElement('zui-picker-grid-cell')
], PickerGridCell);
export { PickerGridCell };
