var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, query, queryAssignedElements, state } from 'lit/decorators.js';
import '../../components/scrollbar/scrollbar.component.js';
import { BaseElement } from '../../components/base/base-element.class.js';
const style = ":host{--zui-scrollable-corner-size: calc(var(--zui-gu) * 1.5);--zui-scrollable-width: auto;--zui-scrollable-height: auto;--zui-scrollable-max-width: none;--zui-scrollable-max-height: none;position:relative;display:flex;flex-flow:column;width:var(--zui-scrollable-width);max-width:var(--zui-scrollable-max-width);height:var(--zui-scrollable-height);max-height:var(--zui-scrollable-max-height);isolation:isolate}.wrapper{flex-grow:1;align-self:stretch;overflow:scroll;-ms-overflow-style:none;scrollbar-width:none}.wrapper::-webkit-scrollbar{display:none}.corner{position:absolute;top:auto;right:0;bottom:0;left:auto;display:block;width:var(--zui-scrollable-corner-size);height:var(--zui-scrollable-corner-size);opacity:0}"
import { WithEventsMixin } from '../../mixins/events/events.mixin.js';
import { GetNotificationEventClass, MimickedScrollEvent } from '../../contracts/event.classes.js';
import '../../contracts/event.contracts.js';
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const SCROLLABLE_DIRECTIVE_STYLES = css `
  ${unsafeCSS(style)}
`;
const SCROLLING_TIMEOUT = 100;
class ScrollableScrollUpEvent extends GetNotificationEventClass('scrollable-scroll-up') {
}
class ScrollableScrollDownEvent extends GetNotificationEventClass('scrollable-scroll-down') {
}
/**
 * The scrollable directive is a feature component with limited UI implementation. It uses the low-level UI component `zui-scrollbar`
 * internally.
 *
 * ## Functionality
 * This component is meant to be _wrapped around_ the scroll target to apply the custom ZUi scrollbars and behavior. It will
 * not break the browsers default scroll behavior but replaces the native scrollbars with custom implemented `zui-scrollbar`
 * UI components. Those components are managed to visualize the available scroll space and the scroll progress.
 *
 * ## Features
 * - it automatically detects if scrollbars are necessary at all and toggles them for each axis accordingly
 * - adds a corner square between two scrollbars to conduct scrollbar appearance and capture wheel events there as well
 * - listens to wheel events on scrollbars to simulate mouse wheel scrolling on target by keeping the custom scrollbar drag\
 *   and click behavior
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2745)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=1%3A102414)
 *
 *
 * @example
 *
 * ### Basic usage
 * ```HTML
 * <zui-scrollable-directive style="--zui-scrollable-max-height: 400px; width: 100%;">
 *   <p>Lorem ipsum dolor</p>
 *   <p>sit amet,</p>
 *   <p>consetetur sadipscing</p>
 *   <p>elitr, sed...</p>
 * </zui-scrollable-directive>
 * ```
 *
 * <br />
 *
 * ### Size constraints on `<zui-scrollable-directive>`
 *
 * You can constrain both the scrollable's width and its height with to a fixed size or a maximum size (responsive).
 * Both absolute and relative units are possible, and you can choose between custom and standard css properties.
 *
 * If you want to use reponsive width, don't forget to override the default `width: auto` with `width: -moz-fit-content; width: fit-content`.
 *
 * |           |        |                                        | custom css props                      | standard css props   |
 * | --------- | ------ | -------------------------------------- | ------------------------------------- | -------------------- |
 * | reponsive | height | absolute                               | `--zui-scrollable-max-height: 400px;` | `max-height: 400px;` |
 * |           |        | relative (to parent with fixed height) | `--zui-scrollable-max-height: 100%;`  | `max-height: 100%;`  |
 * |           |        |                                        |                                       |                      |
 * |           | width  | absolute                               | `--zui-scrollable-max-width: 400px;`  | `max-width: 400px;`  |
 * |           |        | relative (to parent with fixed width)  | `--zui-scrollable-max-width: 100%`    | `max-width: 100%;`   |
 * |           |        |                                        |                                       |                      |
 * | fixed     | height | absolute                               | `--zui-scrollable-height: 400px;`     | `height: 400px;`     |
 * |           |        | relative (to parent with fixed height) | `--zui-scrollable-height: 100%;`      | `height: 100%;`      |
 * |           |        |                                        |                                       |                      |
 * |           | width  | absolute                               | `--zui-scrollable-width: 400px;`      | `width: 400px;`      |
 * |           |        | relative (to parent with fixed width)  | `--zui-scrollable-width: 100%;`       | `width: 100%;`       |
 *
 *
 *
 * Besides these basic constraints, you can of course use `<zui-scrollable-directive>` as a **CSS Flex Item** (don't forget `min-width:0;` or `min-height:0;` respectively !!) ...
 *
 * ```HTML
 * <div style="width: 300px; height: 300px; display: flex; flex-flow: column;">
 *   <h2>Heading</h2>
 *   <zui-scrollable-directive style="flex-basis: 0; flex-grow: 1; min-height: 0;" >
 *     <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
 *   </zui-scrollable-directive>
 * </div>
 * ```
 *
 * ... or as a **CSS Grid Item** (don't forget `min-width:0;min-height:0;`) ...
 *
 * ```HTML
 * <div style="width: 300px; height: 300px; display: grid;">
 *   <h2>Heading</h2>
 *   <zui-scrollable-directive style="min-width: 0; min-height: 0;">
 *     <p style="width: 500px;">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
 *   </zui-scrollable-directive>
 * </div>
 * ```
 *
 * <br />
 *
 * ### Relative slot content height
 * If the `<zui-scrollable-directive>` has a fixed height in some way, you can set relative heights on your slot content, as well.
 *
 * ```HTML
 * <zui-scrollable-directive
 *   style="--zui-scrollable-height: 400px;"
 * >
 *   <div style="height: 100%; background-color: teal"></div>
 *   <div style="height: 100%; background-color: navy"></div>
 * </zui-scrollable-directive>
 * ```
 * @fires scroll - The scroll event is fired when content is scrolled
 * @fires {GetZuiEvent<ScrollableDirective, 'ScrollUpEvent'>} zui-scroll-up event is fired when the scrollbar scrolls up.
 * @fires {GetZuiEvent<ScrollableDirective, 'ScrollDownEvent'>} zui-scroll-down event is fired when the scrollbar scrolls down.
 *
 * @cssprop --zui-scrollable-corner-size - sets the dimensions of the quadratic corner between the scrollbars
 * @cssprop --zui-scrollable-height - sets the height of the scrollable directive content explicitly
 * @cssprop --zui-scrollable-width - sets the width of the scrollable directive content explicitly
 * @cssprop --zui-scrollable-max-height - limits the height of the scroll wrapper until scrollbars appear
 * @cssprop --zui-scrollable-max-width - limits the width of the scroll wrapper until scrollbars appear
 */
let ScrollableDirective = class ScrollableDirective extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.ScrolledEvent = MimickedScrollEvent;
        this.ScrollUpEvent = ScrollableScrollUpEvent;
        this.ScrollDownEvent = ScrollableScrollDownEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * **deprecated** use a solid background on interactions
         * The property will be removed, as there won't be any background options
         *
         * @deprecated
         */
        this.background = 'hidden';
        /**
         * **deprecated** enlarge the hitarea (at least if vertical) _but not the background_
         * The property will be removed, as the hitarea now has a fixed size
         *
         * @deprecated
         */
        this.hitarea = 'enlarged';
        /**
         * show the track of the scrollbar with a solid background-color on interactions
         */
        this.showTrack = false;
        this._cornerColliding = false;
        this._showHorizontal = false;
        this._showVertical = false;
        // watches slotted contents to be resized
        this._resizeObserver = new ResizeObserver(this._updateScrollbarSizes.bind(this));
        // stores the latest wrapper scroll
        this._wrapperScrollOffsets = { x: 0, y: 0 };
        this._scrollHorizontalTimeout = undefined;
        this._scrollVerticalTimeout = undefined;
        this._previousScrollTop = 0;
    }
    // clean up
    disconnectedCallback() {
        this._resizeObserver.disconnect();
        window.clearTimeout(this._scrollHorizontalTimeout);
        window.clearTimeout(this._scrollVerticalTimeout);
        super.disconnectedCallback();
    }
    // set the scroll offset of the wrapper from scrollbars
    _updateScroll({ detail: { offset, orientation } }) {
        if (orientation === 'horizontal') {
            this._wrapperRef.scrollLeft = (this._wrapperRef.scrollWidth * offset) / 100;
        }
        else {
            this._wrapperRef.scrollTop = (this._wrapperRef.scrollHeight * offset) / 100;
        }
    }
    // pass through wheel events on scrollbars
    _updateWheel({ deltaX, deltaY }) {
        this._wrapperRef.scrollLeft += deltaX;
        this._wrapperRef.scrollTop += deltaY;
    }
    // derive scrollbar handle sizes from available scroll space
    // eslint-disable-next-line max-statements
    _updateScrollbarSizes() {
        // gather dimensions
        const { clientHeight, clientWidth, scrollHeight, scrollWidth } = this._wrapperRef;
        // update scrollbar visibility
        this._showHorizontal = scrollWidth > clientWidth;
        this._showVertical = scrollHeight > clientHeight;
        // update handle sizes
        if (this._showHorizontal) {
            const horizontalSize = (clientWidth / scrollWidth) * 100;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this._scrollbarHorizontalRef.style.setProperty('---zui-scrollbar-handle-size', `${horizontalSize}%`);
        }
        if (this._showVertical) {
            const verticalSize = (clientHeight / scrollHeight) * 100;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this._scrollbarVerticalRef.style.setProperty('---zui-scrollbar-handle-size', `${verticalSize}%`);
        }
        // align positions
        this._updateScrollbarPositions();
    }
    // set scrollbar handle positions from current scroll offset
    _updateScrollbarPositions() {
        const { clientHeight, clientWidth, scrollHeight, scrollWidth, scrollLeft, scrollTop } = this._wrapperRef;
        if (this._showHorizontal) {
            const horizontalOffset = (scrollLeft / (scrollWidth - clientWidth)) * 100;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this._scrollbarHorizontalRef.style.setProperty('---zui-scrollbar-handle-offset', `${horizontalOffset}%`);
        }
        if (this._showVertical) {
            const verticalOffset = (scrollTop / (scrollHeight - clientHeight)) * 100;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this._scrollbarVerticalRef.style.setProperty('---zui-scrollbar-handle-offset', `${verticalOffset}%`);
        }
    }
    // toggle scrollbar tracks depening if currently scrolled
    _updateScrollDirection() {
        // we need a reference to the scroll container
        // get current scroll offset
        const toggleScrollDirection = (ref, timeout) => {
            ref.scrolling = true;
            // no need to check for existance
            // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/clearTimeout#notes
            window.clearTimeout(timeout);
            return window.setTimeout(() => {
                // remove flag from scrollbar
                ref.scrolling = false;
            }, SCROLLING_TIMEOUT);
        };
        // horizontal scrollbar
        if (this._wrapperScrollOffsets.x !== this._wrapperRef.scrollLeft) {
            this._scrollHorizontalTimeout = toggleScrollDirection(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this._scrollbarHorizontalRef, this._scrollHorizontalTimeout);
        }
        // vertical scrollbar
        if (this._wrapperScrollOffsets.y !== this._wrapperRef.scrollTop) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this._scrollVerticalTimeout = toggleScrollDirection(this._scrollbarVerticalRef, this._scrollVerticalTimeout);
        }
        // store the current offset
        this._wrapperScrollOffsets.x = this._wrapperRef.scrollLeft;
        this._wrapperScrollOffsets.y = this._wrapperRef.scrollTop;
    }
    _handleSlotChange() {
        // TODO: think about "unobserving" strategy... / find out whether this is really problem / memory leak
        // observe slotted elements
        this._assignedNodes.forEach((element) => this._resizeObserver.observe(element));
        // check for changes
        requestAnimationFrame(() => this._updateScrollbarSizes());
    }
    _handleScroll() {
        // -> https://stackoverflow.com/questions/4326845/how-can-i-determine-the-direction-of-a-jquery-scroll-event
        const { scrollTop } = this._wrapperRef;
        if (scrollTop > this._previousScrollTop) {
            this.emitScrollableScrollDownEvent();
        }
        else {
            this.emitScrollableScrollUpEvent();
        }
        this._previousScrollTop = scrollTop;
        this._updateScrollbarPositions();
        this._updateScrollDirection();
        this.emitScrollEvent();
    }
    _handleCornerEnter() {
        this._cornerColliding = true;
    }
    _handleCornerLeave() {
        this._cornerColliding = false;
    }
    // initialize listeners as DOM is now initialized
    firstRendered() {
        super.firstRendered();
        // track sizes
        this._resizeObserver.observe(this._wrapperRef);
        // observe slotted elements (important when component disconnects and re-connects, because then there is no new slotchange)
        this._assignedNodes.forEach((element) => this._resizeObserver.observe(element));
    }
    /**
     * Detects if one of the deprecated property was used
     *
     * @param {Map<string,unknown>} changedProperties properties which have been changed
     */
    updated(changedProperties) {
        super.update(changedProperties);
        if (changedProperties.has('background') && this.background === 'visible') {
            console.warn(`Deprecated background: ${this.background} was used on zui-scrollable-directive.`);
        }
        if (changedProperties.has('hitarea') && this.hitarea === 'minimal') {
            console.warn(`Deprecated hitarea: ${this.hitarea} was used on zui-scrollable-directive.`);
        }
    }
    render() {
        return html `
      <div class="wrapper" part="wrapper" @scroll="${this._handleScroll}">
        <slot @slotchange="${this._handleSlotChange}"></slot>
      </div>

      <zui-scrollbar
        background="${this.background}"
        hitarea="${this.hitarea}"
        orientation="horizontal"
        part="scrollbar-horizontal"
        ?colliding="${this._cornerColliding}"
        ?disabled="${!this._showHorizontal}"
        ?multiple="${this._showVertical}"
        ?show-track="${this.showTrack}"
        @zui-scrollbar-scrolled="${this._updateScroll}"
        @wheel="${this._updateWheel}"
      ></zui-scrollbar>
      <zui-scrollbar
        background="${this.background}"
        hitarea="${this.hitarea}"
        orientation="vertical"
        part="scrollbar-vertical"
        ?colliding="${this._cornerColliding}"
        ?disabled="${!this._showVertical}"
        ?multiple="${this._showHorizontal}"
        ?show-track="${this.showTrack}"
        @zui-scrollbar-scrolled="${this._updateScroll}"
        @wheel="${this._updateWheel}"
      ></zui-scrollbar>
      <span
        class="corner"
        @mouseenter="${this._handleCornerEnter}"
        @mouseleave="${this._handleCornerLeave}"
        @wheel="${this._updateWheel}"
      ></span>
    `;
    }
};
ScrollableDirective.styles = [
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    SCROLLABLE_DIRECTIVE_STYLES,
];
__decorate([
    property({ reflect: true, type: String })
], ScrollableDirective.prototype, "background", void 0);
__decorate([
    property({ reflect: true, type: String })
], ScrollableDirective.prototype, "hitarea", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'show-track' })
], ScrollableDirective.prototype, "showTrack", void 0);
__decorate([
    query('.wrapper')
], ScrollableDirective.prototype, "_wrapperRef", void 0);
__decorate([
    query('zui-scrollbar[orientation=horizontal]')
], ScrollableDirective.prototype, "_scrollbarHorizontalRef", void 0);
__decorate([
    query('zui-scrollbar[orientation=vertical]')
], ScrollableDirective.prototype, "_scrollbarVerticalRef", void 0);
__decorate([
    queryAssignedElements({ flatten: true })
], ScrollableDirective.prototype, "_assignedNodes", void 0);
__decorate([
    state()
], ScrollableDirective.prototype, "_cornerColliding", void 0);
__decorate([
    state()
], ScrollableDirective.prototype, "_showHorizontal", void 0);
__decorate([
    state()
], ScrollableDirective.prototype, "_showVertical", void 0);
ScrollableDirective = __decorate([
    customElement('zui-scrollable-directive')
], ScrollableDirective);
export { ScrollableDirective };
