import type { NotificationsHostProps } from 'piral-notifications';
import {
  Children,
  type FC,
  isValidElement,
  type ReactElement,
  type ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useClose } from '../../hooks/use-close';
import { ModalContainer } from './ModalContainer';

interface NotificationsListProps {
  children: ReactNode;
  close(): void;
}

const NotificationsList: FC<NotificationsListProps> = ({ children, close }) => {
  const ref = useClose('notifications', close);

  return <div ref={ref}>{children}</div>;
};

export const NotificationsHost: FC<NotificationsHostProps> = ({ children }) => {
  const [open, setOpen] = useState(false);

  const closeAll = useCallback(() => {
    Children.forEach(children, (child) => {
      if (isValidElement(child) && typeof (child as ReactElement).props.onClose === 'function') {
        child.props.onClose();
      }
    });

    setOpen(false);
  }, [children]);

  useEffect(() => {
    setOpen(Children.count(children) > 0);
  }, [children]);

  return (
    <ModalContainer open={open}>
      {open && <NotificationsList close={closeAll}>{children}</NotificationsList>}
    </ModalContainer>
  );
};
