import { getPublicPath } from '../environment';
import type { RuntimeConfig } from '../types';
import { getAuthorizationEndpoint } from './zeiss-id-discovery';

export function zeissIdLogin(config: RuntimeConfig): Promise<any> {
  return getAuthorizationEndpoint(config)
    .then((url) => createAuthorizationRequest(url, config))
    .then((url) => redirectToLogin(url));
}

function createAuthorizationRequest(authorizationUrl: string, config: RuntimeConfig): string {
  const url = new URL(authorizationUrl);
  const redirectAfterLogin = getRedirectUrlAfterLogin();

  url.searchParams.append('client_id', config.auth.clientId);
  url.searchParams.append('response_type', 'code');
  url.searchParams.append('response_mode', 'form_post');
  url.searchParams.append(
    'redirect_uri',
    `${location.origin}${config.endpoints.auth.redirectUriPath}`
  );

  url.searchParams.append('state', redirectAfterLogin);

  url.searchParams.append('scope', `${config.auth.clientId} offline_access openid`);
  return url.toString();
}

function redirectToLogin(url: string): Promise<void> {
  location.href = url;

  // We are deliberately not resolving the promise in order to let the redirect run to completion as it is not instant
  return Promise.resolve();
}

function getRedirectUrlAfterLogin(): string {
  const redirectTo = new URLSearchParams(location.search).get('redirect_to');
  const redirectUrl = `${location.origin}${getPublicPath()}${redirectTo ?? ''}`;
  return encodeURIComponent(redirectUrl);
}
