var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, query, queryAssignedElements, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { BaseElement } from '../../base/base-element.class.js';
import { Breakpoint } from '../../../types.js';
import { compareMediaBreakpoint, getBreakpointForWidth } from '../../../utils/component.utils.js';
import '../../user-menu-button/user-menu-button/user-menu-button.component.js';
import '../../searchbar/searchbar/searchbar.component.js';
import '../headerbar-icon-button/headerbar-icon-button.component.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{display:flex;align-items:center;justify-content:space-between;width:100%;height:calc(var(--zui-gu) * 8);background-color:var(--zui-color-headerbar-background)}.left-aligned,.left-aligned-content,.right-aligned,.right-aligned-content{display:flex;align-items:center;min-width:0}:host([media=XS]) .left-aligned,:host([media=S]) .left-aligned,:host([media=XS]) .left-aligned-content,:host([media=S]) .left-aligned-content,:host([media=XS]) .right-aligned,:host([media=S]) .right-aligned,:host([media=XS]) .right-aligned-content,:host([media=S]) .right-aligned-content{flex:1}.right-aligned,.right-aligned-content{justify-content:flex-end}:host([media=XS]) .right-aligned,:host([media=S]) .right-aligned{flex:0 0 auto}:host(:not([show-searchbar])) .right-aligned{flex-shrink:0}.right-aligned-content{flex-shrink:0}.centered-searchbar{display:flex;flex:1;align-items:center;justify-content:center;width:100%;min-width:0;height:100%}.centered-searchbar.hidden{display:none}:host([media=XS]) .centered-searchbar,:host([media=S]) .centered-searchbar{flex:0}slot[name=icon]::slotted(*){flex-shrink:0;margin-left:calc(var(--zui-gu) * 3)}slot[name=productName]{display:flex;flex:1;min-width:0}slot[name=productName].hidden{display:none}slot[name=productName]::slotted(zui-headerbar-product-name){width:100%;padding:0 calc(var(--zui-gu) * 3)}slot[name=searchbar]::slotted(zui-searchbar){width:100%}:host([show-searchbar]) slot[name=searchbar]::slotted(zui-searchbar){padding:0 calc(var(--zui-gu) * 3)}.slotted-buttons{display:flex}slot[name=userMenuButton]::slotted(zui-user-menu-button){max-width:calc(var(--zui-gu) * 32);border-left:1px solid var(--zui-color-headerbar-button-divider-border-color)}"
import { GetNotificationEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-headerbar-background: var(--zui-color-gs-10);--zui-color-headerbar-button-divider-border-color: var(--zui-color-gs-40)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-headerbar-background: var(--zui-color-gs-100);--zui-color-headerbar-button-divider-border-color: var(--zui-color-gs-120)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
// icons
import '@zeiss/zui-icons/dist/components/generated/common-search-search.component.js';
const headerbarStyles = css `
  ${unsafeCSS(styles)}
`;
// min width of 320px and a 24px padding on each side
const SEARCHBAR_TOTAL_WIDTH = 378;
// Product name is hidden, when the width is smaller than 480px
const PRODUCT_NAME_BREAKPOINT = 480;
class HeaderbarSearchbarIconSelectedEvent extends GetNotificationEventClass('headerbar-searchbar-icon-selected') {
}
/**
 * The Headerbar component is displayed at the top of the page.
 *
 * ## Figma
 * - [Web - Component Library](https://www.figma.com/file/z4fyXFOJCpuaNImx3K234n/❖-04-Web---Component-Library---1.4?node-id=1%3A12)
 * - [Styleguide – Web](https://www.figma.com/file/6dkjypErYWQPfuRBD58Aey/%F0%9F%93%96--Styleguide---Web?node-id=3279%3A57420&viewport=279%2C103%2C0.15313252806663513)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-headerbar>
 *   <zui-zeiss-logo size="s32" slot="icon"></zui-zeiss-logo>
 *   <zui-headerbar-product-name product-name="ZUi" product-sub-name="web component library" slot="productName"></zui-headerbar-product-name>
 * </zui-headerbar>
 * ```
 *
 * ```html
 * <zui-headerbar>
 *   <zui-zeiss-logo size="s32" slot="icon"></zui-zeiss-logo>
 *   <zui-headerbar-product-name product-name="ZUi" product-sub-name="web component library" slot="productName"></zui-headerbar-product-name>
 * </zui-headerbar>
 * ```
 *
 * ```html
 * <zui-headerbar show-searchbar>
 *   <zui-zeiss-logo size="s32" slot="icon"></zui-zeiss-logo>
 *   <zui-headerbar-product-name product-name="ZUi" product-sub-name="web component library" slot="productName"></zui-headerbar-product-name>
 *   <zui-searchbar placeholder="Enter your search here..." slot="searchbar"></zui-searchbar>
 *   <zui-headerbar-icon-button emphasis="default" size="m" slot="iconButtons">
 *     <zui-icon-common-holy-placeholder slot="icon"></zui-icon-common-holy-placeholder>
 *   </zui-headerbar-icon-button>
 *   <zui-headerbar-notification-button has-notifications emphasis="default" size="m" slot="iconButtons">
 *     <zui-icon-alerts-alarm-on slot="icon"></zui-icon-alerts-alarm-on>
 *     <zui-state-dot slot="stateDot" state="error"></zui-state-dot>
 *   </zui-headerbar-notification-button>
 *   <zui-user-menu-button size="l" slot="userMenuButton" user-name="User" user-role="Role">
 *     <zui-avatar initials="ZW" slot="avatar"></zui-avatar>
 *   </zui-user-menu-button>
 * </zui-headerbar>
 * ```
 *
 * @slot icon - slot for an icon
 * @slot productName - slot for a product name
 * @slot searchbar - slot for a searchbar
 * @slot iconButtons - slot for icon buttons
 * @slot userMenuButton - slot for a user menu button
 * @fires {GetZuiEvent<Headerbar, 'SearchbarIconSelectedEvent'>} zui-headerbar-searchbar-icon-selected - The headerbar-searchbar-icon-selected event is fired when the search button that is only visible for resolutions lower than 1024px is clicked
 */
let Headerbar = class Headerbar extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.SearchbarIconSelectedEvent = HeaderbarSearchbarIconSelectedEvent;
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * Headerbar media
         */
        this.media = Breakpoint.L;
        /**
         * whether to show or hide the searchbar
         */
        this.showSearchbar = false;
        /**
         * currently there is no dark theme for the headerbar! it should be always displayed with theme light!
         *
         * @private
         */
        this.theme = 'light';
        this._hasSearchbar = false;
        this._headerbarResizeObserver = new ResizeObserver(([{ contentRect }]) => {
            requestAnimationFrame(() => {
                this._width = contentRect.width;
                this.media = getBreakpointForWidth(contentRect.width);
            });
        });
        this._rightAlignedContentResizeObserver = new ResizeObserver(([{ contentRect }]) => {
            requestAnimationFrame(() => {
                this._widthRight = contentRect.width;
            });
        });
    }
    get _maxOfRightWidth() {
        var _a;
        return (_a = this._widthRight) !== null && _a !== void 0 ? _a : 0;
    }
    // the searchbar is only shown for breakpoints larger than s
    get _isMediaLargerSmall() {
        return compareMediaBreakpoint(this.media, '>', Breakpoint.S);
    }
    get _maxRightWidth() {
        return this._hasSearchbar && this._isMediaLargerSmall ? `${this._maxOfRightWidth}px` : 'auto';
    }
    get _leftAlignedWidth() {
        return this._hasSearchbar && this._isMediaLargerSmall ? 'max-width' : 'width';
    }
    // maximum space for left aligned content results of the main width, the maximum of the right aligned content
    // and the minimum space for the searchbar
    get _maxLeftWidth() {
        return this._hasSearchbar && this._isMediaLargerSmall
            ? `${this._width - this._maxOfRightWidth - SEARCHBAR_TOTAL_WIDTH}px`
            : 'auto';
    }
    get _showSearchbar() {
        return this._isMediaLargerSmall && this._width - 2 * this._maxOfRightWidth > SEARCHBAR_TOTAL_WIDTH;
    }
    get _showProductName() {
        return this._width >= PRODUCT_NAME_BREAKPOINT;
    }
    disconnectedCallback() {
        this._headerbarResizeObserver.disconnect();
        this._rightAlignedContentResizeObserver.disconnect();
        super.disconnectedCallback();
    }
    _handleHeaderbarSearchbarIconSelected() {
        this.emitHeaderbarSearchbarIconSelectedEvent();
    }
    _handleHeaderbarSearchbarSlotChange() {
        this._hasSearchbar = this._assignedSearchbar.length > 0;
    }
    _updateUserMenuButtonAvatarOnly() {
        if (this._assignedUserMenuButtons.every((userMenuButton) => userMenuButton.avatarOnly)) {
            return;
        }
        const avatarOnly = compareMediaBreakpoint(this.media, '<', Breakpoint.M);
        this._assignedUserMenuButtons.forEach((userMenuButton) => (userMenuButton.avatarOnlyExternally = avatarOnly));
    }
    firstRendered() {
        super.firstRendered();
        this._headerbarResizeObserver.observe(this);
        this._rightAlignedContentResizeObserver.observe(this._rightAlignedContentElement);
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('media')) {
            this._updateUserMenuButtonAvatarOnly();
        }
    }
    render() {
        return html `
      <div class="left-aligned" style="${styleMap({
            [this._leftAlignedWidth]: this._maxLeftWidth,
        })}">
      <div class="left-aligned-content">
            <slot name="icon"></slot>
            <slot name="productName" class='${classMap({ hidden: !this._showProductName })}'></slot>
          </div>
        </div>
        <div class="centered-searchbar ${classMap({ hidden: !this._hasSearchbar || !this._showSearchbar })}">
          <slot name="searchbar" @slotchange="${this._handleHeaderbarSearchbarSlotChange}"></slot>
        </div>
        <div class="right-aligned" style="min-width: ${this._maxRightWidth}">
          ${this._hasSearchbar && !this._showSearchbar
            ? html `
                  <zui-headerbar-icon-button
                    @click="${this._handleHeaderbarSearchbarIconSelected}"
                    emphasis="default"
                    id="icon-button-search"
                    size="m"
                  >
                    <zui-icon-common-search-search slot="icon"></zui-icon-common-search-search>
                  </zui-headerbar-icon-button>
                `
            : nothing}
          <div class="right-aligned-content">
            <div class="slotted-buttons">
              <slot name="iconButtons"></slot>
            </div>
            <slot @slotchange="${this._updateUserMenuButtonAvatarOnly}" name="userMenuButton"></slot>
          </div>
        </div>
      </div>
    `;
    }
};
Headerbar.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    headerbarStyles,
];
__decorate([
    property({ reflect: true, type: String })
], Headerbar.prototype, "media", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'show-searchbar' })
], Headerbar.prototype, "showSearchbar", void 0);
__decorate([
    property({ reflect: true, type: String })
], Headerbar.prototype, "theme", void 0);
__decorate([
    state()
], Headerbar.prototype, "_hasSearchbar", void 0);
__decorate([
    state()
], Headerbar.prototype, "_width", void 0);
__decorate([
    state()
], Headerbar.prototype, "_widthRight", void 0);
__decorate([
    query('.right-aligned-content')
], Headerbar.prototype, "_rightAlignedContentElement", void 0);
__decorate([
    queryAssignedElements({ slot: 'searchbar', selector: 'zui-searchbar', flatten: true })
], Headerbar.prototype, "_assignedSearchbar", void 0);
__decorate([
    queryAssignedElements({ slot: 'userMenuButton', selector: 'zui-user-menu-button', flatten: true })
], Headerbar.prototype, "_assignedUserMenuButtons", void 0);
Headerbar = __decorate([
    customElement('zui-headerbar')
], Headerbar);
export { Headerbar };
