import {
  ApplicationInsights,
  DistributedTracingModes,
  type ITelemetryItem,
  Telemetry,
} from '@microsoft/applicationinsights-web';

import { getUserData } from '../auth';
import details from '../details.codegen';
import type { RuntimeConfig } from '../types';

let appInsights: ApplicationInsights | null = null;

export function initTechLogger(config: RuntimeConfig): void {
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: config.logging.techInstrumentationKeyPlaceholder,
      endpointUrl: config.endpoints.techEndpoint,
      disableAjaxTracking: false,
      disableFetchTracking: false,
      disableExceptionTracking: false,
      enableAutoRouteTracking: false,
      autoTrackPageVisitTime: false,
      loggingLevelTelemetry: 0,
      enableRequestHeaderTracking: false,
      enableResponseHeaderTracking: true,
      enableCorsCorrelation: true,
      distributedTracingMode: DistributedTracingModes.W3C,
      isCookieUseDisabled: true,
      isStorageUseDisabled: true,
      correlationHeaderDomains: [window.location.host],
      enableSessionStorageBuffer: false,
      disableInstrumentationKeyValidation: true,
      samplingPercentage: config.logging.techSamplingPercentage,
    },
  });
  initApplicationContextTelemetryInitializer(appInsights);
  initUserContextTelemetryInitializer(appInsights, config.logging.agreementIdsPlaceholder);
  initBrowserContextTelemetryInitializer(appInsights);
  initExcludeRemoteDependencyTelemetryInitializer(appInsights, window.location.origin);
  appInsights.loadAppInsights();
}

function getTechnicalLogger(): ApplicationInsights {
  if (!appInsights) {
    throw new Error(
      'Technical logger was not properly initialized before usage! Ensure to call "initTechLogger()" during setup of app-shell before any http-requests are made.'
    );
  }

  return appInsights;
}

export function trackException(exception: Error): void {
  if (window.navigator.webdriver) {
    console.warn('[WDIO] Received error to log: ' + exception);
  } else {
    getTechnicalLogger().trackException({ exception });
  }
}

/**
 * Register a telemetry initializer, that filters all successful network events and all external requests.
 * We do not want these events, because they are not useful for our error handling.
 *
 * @param applicationInsightsService: ApplicationInsights
 * @param backendUrl: needed to check if an external request is made to exclude those from logging
 * @param investigationMode: if true, also log successful requests
 */
function initExcludeRemoteDependencyTelemetryInitializer(
  applicationInsightsService: ApplicationInsights,
  backendUrl: string,
  investigationMode = false
) {
  applicationInsightsService.addTelemetryInitializer((envelope: ITelemetryItem) => {
    // Filter to remove successful requests from logs
    if (
      investigationMode !== true &&
      envelope.baseType === Telemetry.RemoteDependencyData.dataType &&
      envelope.baseData?.['success']
    ) {
      return false;
    } // Filter to remove external requests from logs

    if (
      envelope.baseType === Telemetry.RemoteDependencyData.dataType &&
      checkForExternalRequest(envelope.baseData?.['target'], backendUrl)
    ) {
      return false;
    }

    return undefined;
  });
}

// Example data-URL: "GET http://localhost:6060/abc"
function checkForExternalRequest(data: string, backendUrl: string): boolean {
  return !data.includes(backendUrl);
}

function initUserContextTelemetryInitializer(
  appInsights: ApplicationInsights,
  agreementIdsPlaceholder: string
) {
  appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
    const userData = getUserData();
    if (userData) {
      envelope.baseData = {
        ...envelope.baseData,
        properties: {
          ...envelope.baseData?.['properties'],
          userId: userData.accountId,
          agreementIds: agreementIdsPlaceholder,
        },
      };
      if (envelope.tags) {
        envelope.tags['ai.user.id'] = userData.accountId;
      }
    }
  });
}

function initBrowserContextTelemetryInitializer(appInsights: ApplicationInsights) {
  appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
    envelope.baseData = {
      ...envelope.baseData,
      properties: {
        ...envelope.baseData?.['properties'],
        url: window.location.href,
        timeZoneOffset: new Date().getTimezoneOffset(),
        windowHeight: window.innerHeight.toString(),
        windowWidth: window.innerWidth.toString(),
      },
    };
  });
}

function initApplicationContextTelemetryInitializer(appInsights: ApplicationInsights) {
  appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
    envelope.baseData = {
      ...envelope.baseData,
      properties: {
        ...envelope.baseData?.['properties'],
        applicationVersion: details.appVersion,
        piralVersion: details.piralVersion,
      },
    };
  });
}
