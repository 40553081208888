var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, nothing, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { getDefaultLocale, isoDateConverter } from '../../date-picker/utils/date-picker.utils.js';
import { hasDayTime } from '../../time-picker/utils/time-picker.utils.js';
import { hostStyles } from '../../../host.styles.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const style = ":host{display:flex;flex-direction:column;align-items:flex-start}:host([emphasis=outbound]){align-items:flex-end}#chat-item-wrapper{width:fit-content;padding:calc(var(--zui-gu) * 1.25) calc(var(--zui-gu) * 2) calc(var(--zui-gu) * 1.5) calc(var(--zui-gu) * 2);border-radius:8px}:host([emphasis=inbound]) #chat-item-wrapper{color:var(--zui-color-chat-item-inbound-text);background-color:var(--zui-color-chat-item-inbound-bg)}:host([emphasis=outbound]) #chat-item-wrapper{color:var(--zui-color-chat-item-outbound-text);background-color:var(--zui-color-chat-item-outbound-bg)}::slotted(*){margin:0;padding:0}:host([emphasis=inbound]) ::slotted(*){color:var(--zui-color-chat-item-inbound-text)}:host([emphasis=outbound]) ::slotted(*){color:var(--zui-color-chat-item-outbound-text)}#chat-item-date-wrapper{display:flex;gap:calc(var(--zui-gu) * 0.625);align-items:center;height:calc(var(--zui-gu) * 1.5);margin-top:calc(var(--zui-gu) * 1);color:var(--zui-color-chat-item-delivery-text);font:var(--zui-typography-caption)}#chat-item-date-wrapper::selection{background-color:var(--zui-color-chat-item-delivery-bg-selection)}zui-divider{--zui-horizontal-rule-margin: 0;--zui-color-thin-line-default: var(--zui-color-chat-item-divider)}"
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-chat-item-inbound-bg: var(--zui-color-gs-40);--zui-color-chat-item-inbound-text: var(--zui-color-gs-100);--zui-color-chat-item-inbound-bg-selection: rgba(var(--zui-color-ac-db-110-rgb), 0.4);--zui-color-chat-item-outbound-bg: var(--zui-color-ac-db-110);--zui-color-chat-item-outbound-text: var(--zui-color-gs-10);--zui-color-chat-item-outbound-bg-selection: rgba(var(--zui-color-gs-100-rgb), 0.3);--zui-color-chat-item-delivery-text: var(--zui-color-gs-85);--zui-color-chat-item-delivery-bg-selection: rgba(var(--zui-color-ac-db-110-rgb), 0.3);--zui-color-chat-item-divider: var(--zui-color-gs-60)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-chat-item-inbound-bg: var(--zui-color-gs-100);--zui-color-chat-item-inbound-text: var(--zui-color-gs-50);--zui-color-chat-item-inbound-bg-selection: rgba(var(--zui-color-ac-db-110-rgb), 0.4);--zui-color-chat-item-outbound-bg: var(--zui-color-ac-db-110);--zui-color-chat-item-outbound-text: var(--zui-color-gs-10);--zui-color-chat-item-outbound-bg-selection: rgba(var(--zui-color-gs-100-rgb), 0.3);--zui-color-chat-item-delivery-text: var(--zui-color-gs-70);--zui-color-chat-item-delivery-bg-selection: rgba(var(--zui-color-ac-db-110-rgb), 0.3);--zui-color-chat-item-divider: var(--zui-color-gs-120)}"
const themeLight = ""
const themeDark = ""
// deps
import '../../divider/divider.component.js';
// icons
import '@zeiss/zui-icons/dist/components/generated/common-show.component.js';
import '@zeiss/zui-icons/dist/components/generated/symbols-checkmark.component.js';
const chatItemStyles = css `
  ${unsafeCSS(style)}
`;
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
/**
 * The zui-chat-item is an atomic component. It applies styling to any slotted content in the default slot. The component also provides a date and delivery status if set.
 * The emphasis of the chat item influences both style and behavior. Therfore a delivery status is only shown for outbound chat items and the date is then right aligned.
 *
 * ## Figma
 * - [Styleguide - Desktop](https://www.figma.com/file/6dkjypErYWQPfuRBD58Aey/%F0%9F%93%96--Styleguide---Web)
 * - [Component Library - Desktop](https://www.figma.com/file/z4fyXFOJCpuaNImx3K234n/%E2%9D%96-04-Web---Component-Library---4.4?node-id=3453%3A49749)
 *
 * ## Skeleton
 * The Skeleton components can be added to the named skeleton slot and will be displayed when **nothing** is in the default slot.
 * When the skeleton is not displayed make sure there are no spaces or line breaks passed in the default slot.
 *
 *
 * @example
 * HTML:
 *
 * Inbound chat item:
 * ```html
 * <chat-item date="2019-04-13T15:50+01:00" locale="de-DE">
 * Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
 * dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
 * </chat-item>
 * ```
 *
 * Outbound chat item:
 * ```html
 * <chat-item date="2019-04-13T15:50+01:00" emphasis="outbound" delivery-status="sent">
 *  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
 *  dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
 * </chat-item>
 * ```
 *
 * Chat Item with Skeleton:
 * We support skeletons on emphasis outbound only because its valid to show a skeleton when the message is not yet been delivered.
 * For emphasis inbound the date should always be available therefore it is not possible to add a skeleton.
 * ```html
 * <zui-chat-item emphasis="outbound" style="width: 400px; margin-right: 48px" date="2019-04-13T15:50+01:00" locale="de-DE"
 *  ><zui-skeleton-rectangle slot="skeleton" style="--zui-skeleton-rectangle-width: 400px; --zui-skeleton-rectangle-height: 200px;"
 *  ></zui-skeleton-rectangle
 *  ><zui-skeleton-rectangle slot="skeleton-delivery-date" style="--zui-skeleton-rectangle-width: 100px; --zui-skeleton-rectangle-height: 12px"
 * ></zui-chat-item>
 * ```
 *
 * @slot default - any html element or text content
 * @slot skeleton - This is the slot for adding a skeleton instead of text content. The Skeleton is only displayed when the default slot is empty.
 * @slot skeleton-delivery-date - This is the slot for adding a skeleton instead of an delivery date on a outbound chat item. The Skeleton is only displayed when the delivery date is not set.
 */
let ChatItem = class ChatItem extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * emphasis style of the component.
         */
        this.emphasis = 'inbound';
        /**
         * delivery status shows an icon next to the date. Only available on the outbound emphasis and when set.
         */
        this.deliveryStatus = 'none';
        /**
         * shown date for the delivery/recieved time.
         */
        this.date = null;
        /**
         * Full locale code as defined in RFC 5646/BCP 47,
         * i.e. "en-US".
         *
         * By default, the first full locale in [navigator.languages](https://developer.mozilla.org/en-US/docs/Web/API/Navigator/languages) is used.
         */
        this.locale = getDefaultLocale();
    }
    /**
     * hour cycle
     *
     * @returns {TimePickerHourCycle} locale dependent or overridden hour cycle
     */
    get hourCycle() {
        return this._internalHourCycle ? this._internalHourCycle : hasDayTime(this.locale) ? 'h12' : 'h23';
    }
    set hourCycle(value) {
        const oldValue = this._internalHourCycle;
        this._internalHourCycle = value;
        this.requestUpdate('hourCycle', oldValue);
    }
    get _getDeliveryIcon() {
        if (this.emphasis !== 'outbound') {
            return nothing;
        }
        switch (this.deliveryStatus) {
            case 'none':
                return nothing;
            case 'sent':
                return html `<zui-icon-symbols-checkmark size="s12"></zui-icon-symbols-checkmark>`;
            case 'read':
                return html `<zui-icon-common-show size="s12"></zui-icon-common-show>`;
            default:
                return nothing;
        }
    }
    get _isDateDeliveryShown() {
        return this.date !== null || (this.emphasis === 'outbound' && this.deliveryStatus !== 'none');
    }
    render() {
        return html `<div id="chat-item-wrapper">
        <slot>
          <slot name="skeleton"></slot>
        </slot>
      </div>

      ${this._isDateDeliveryShown
            ? html `<div id="chat-item-date-wrapper"
            >${this.date !== null
                ? html `${this.date.toLocaleString(this.locale, { day: '2-digit', month: '2-digit', year: 'numeric' })}
                  <zui-divider orientation="vertical"></zui-divider>
                  ${this.date.toLocaleString(this.locale, {
                    minute: '2-digit',
                    hour: '2-digit',
                    hourCycle: this.hourCycle,
                })}`
                : html `<slot name="skeleton-delivery-date"></slot>`}
            ${this._getDeliveryIcon}</div
          >`
            : nothing}`;
    }
};
ChatItem.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    chatItemStyles,
];
__decorate([
    property({ reflect: true, type: String })
], ChatItem.prototype, "emphasis", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'delivery-status' })
], ChatItem.prototype, "deliveryStatus", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], ChatItem.prototype, "date", void 0);
__decorate([
    property({ reflect: true, type: String })
], ChatItem.prototype, "locale", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'hour-cycle' })
], ChatItem.prototype, "hourCycle", null);
ChatItem = __decorate([
    customElement('zui-chat-item')
], ChatItem);
export { ChatItem };
