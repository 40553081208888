var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { themeLight } from '../../styles/themes/light/index.js';
import { themeDark } from '../../styles/themes/dark/index.js';
import { themeZbdsLight } from '../../styles/themes/light-zbds/index.js';
import { themeZbdsDark } from '../../styles/themes/dark-zbds/index.js';
import { BaseElement } from '../base/base-element.class.js';
/**
 * The ThemeProvider is used to set a ZUi theme globablly.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-theme-provider theme="light"><zui-button></zui-button></zui-theme-provider>
 * ```
 */
let ThemeProvider = class ThemeProvider extends LitElement {
    constructor() {
        super(...arguments);
        /**
         * Defines a theme that is applied to child components
         * N.B. You cannot use the translucent theme here, this has to be set
         * individually on a per-component basis.
         */
        this.theme = BaseElement.DEFAULT_THEME;
    }
    createRenderRoot() {
        // this has no shadowDOM and is just for styling purposes
        return this;
    }
    render() {
        // only actually render a style, if buildThemes are enabled,
        // because otherwise style-tag will leak into global context
        return html `
      <style>
        zui-theme-provider {
          display: contents;
        }
        ${BaseElement.FEATURE_ENABLE_BUILD_THEMES ? this._applyTheme(this.theme) : nothing}
      </style>
    `;
    }
    _applyTheme(theme) {
        switch (theme) {
            case 'light':
                return html `${unsafeHTML(themeLight.cssText)}`;
            case 'zbds-light':
                return html `${unsafeHTML(themeZbdsLight.cssText)}`;
            case 'dark':
                return html `${unsafeHTML(themeDark.cssText)}`;
            case 'zbds-dark':
                return html `${unsafeHTML(themeZbdsDark.cssText)}`;
        }
    }
};
__decorate([
    property({ reflect: true, type: String })
], ThemeProvider.prototype, "theme", void 0);
ThemeProvider = __decorate([
    customElement('zui-theme-provider')
], ThemeProvider);
export { ThemeProvider };
