var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var MultiItemSlider_1;
import debounce from 'lodash-es/debounce.js';
import { css, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, query, queryAssignedElements, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { animateWithCSS } from '../../../utils/animation.utils.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{--zui-multi-item-slider-animation-duration: 800ms;--zui-multi-item-slider-animation-duration-pagination: 400ms;--zui-multi-item-slider-animation-easing: cubic-bezier(0.4, 0, 0.2, 1);--zui-multi-item-slider-pagination-spacing: 8px;--zui-multi-item-slider-item-gap-size: var(--zui-space-medium);---zui-multi-item-slider-group-size: 4}.items{---zui-multi-item-slider-gaps-per-group: calc(var(---zui-multi-item-slider-group-size) - 1);---zui-multi-item-slider-gap-width-per-group: calc(var(---zui-multi-item-slider-gaps-per-group) * var(--zui-multi-item-slider-item-gap-size));---zui-multi-item-slider-gap-width-per-item: calc(var(---zui-multi-item-slider-gap-width-per-group) / var(---zui-multi-item-slider-group-size));---zui-multi-item-slider-item-width-without-gap: calc(100% / var(---zui-multi-item-slider-group-size));---zui-multi-item-slider-item-width: calc(var(---zui-multi-item-slider-item-width-without-gap) - var(---zui-multi-item-slider-gap-width-per-item));display:grid;grid-auto-columns:var(---zui-multi-item-slider-item-width);grid-auto-flow:column;grid-gap:var(--zui-multi-item-slider-item-gap-size);overflow:scroll;-ms-overflow-style:none;scrollbar-width:none}.items::-webkit-scrollbar{display:none}.items.snapping{scroll-snap-type:x mandatory}.items.snapping ::slotted(zui-multi-item-slider-item){scroll-snap-align:start}nav{display:flex;justify-content:center;margin-top:var(--zui-multi-item-slider-pagination-spacing)}nav zui-multi-item-slider-pagination{--zui-multi-item-slider-pagination-animation-duration: var(--zui-multi-item-slider-animation-duration-pagination)}"
import '../multi-item-slider-item/multi-item-slider-item.component.js';
import '../multi-item-slider-pagination/multi-item-slider-pagination.component.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const multiItemSliderStyles = css `
  ${unsafeCSS(styles)}
`;
/**
 * Allows showing multiple items with arbitrary contents in a horizontal slider.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-multi-item-slider gap-size="l" group-size="3">
 *   <zui-multi-item-slider-item>
 *     <img src="/some/image/source-1" alt="Image 1" />
 *   </zui-multi-item-slider-item>
 *   <zui-multi-item-slider-item>
 *     <img src="/some/image/source-2" alt="Image 2" />
 *   </zui-multi-item-slider-item>
 *   <zui-multi-item-slider-item>
 *     <div>
 *       <h2>Mixed content</h2>
 *       <p>is possible as well</p>
 *     </div>
 *   </zui-multi-item-slider-item>
 * </zui-multi-item-slider>
 * ```
 *
 * @slot default - Default slot accepts zui-multi-item-slider-item elements to be shown in columns
 *
 * @cssprop --zui-multi-item-slider-animation-duration - Duration of the item animation
 * @cssprop --zui-multi-item-slider-animation-duration-pagination - Duration of the used pagination dot animation
 * @cssprop --zui-multi-item-slider-animation-easing - Easing timing function of the item animation
 * @cssprop --zui-multi-item-slider-pagination-spacing - Distance of the slider items to the pagination
 * @cssprop --zui-multi-item-slider-item-gap-size - Gap size of the items, defaults to `var(--zui-space-medium)` and can be `var(--zui-space-small)`, `var(--zui-space-large)` or a `px` value (e.g. `16px`)
 */
let MultiItemSlider = MultiItemSlider_1 = class MultiItemSlider extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * Amount of visible elements
         */
        this.groupSize = 4;
        /**
         * Allows hiding the pagination
         */
        this.hidePagination = false;
        this._isSnapping = true;
        // the currently first visible item index
        this._selectedItemIndex = 0;
        // after resizing we might have to align the scroll offset
        this._resizeObserver = new ResizeObserver(debounce(this._handleResize.bind(this), 200));
        // while scrolling we want to update the selected page
        this._scrollListener = debounce(this._handleScroll.bind(this), 20);
    }
    get _isAnimating() {
        var _a;
        return ((_a = this._currentAnimation) === null || _a === void 0 ? void 0 : _a.playState) === 'running';
    }
    get _pageCount() {
        var _a;
        return Math.ceil(((_a = this._itemRefs) === null || _a === void 0 ? void 0 : _a.length) / this.groupSize);
    }
    get _selectedPageIndex() {
        var _a;
        const targetPage = Math.floor(this._selectedItemIndex / this.groupSize);
        const maxPage = Math.floor(((_a = this._itemRefs) === null || _a === void 0 ? void 0 : _a.length) / this.groupSize);
        return Math.min(targetPage, maxPage);
    }
    // clean up once removed from DOM
    disconnectedCallback() {
        this._resizeObserver.disconnect();
        super.disconnectedCallback();
    }
    _handleSlotChange() {
        // the amount of slotted items may have been changed,
        // thus we have to check for altered page count
        this.requestUpdate();
    }
    _handlePageSelected({ detail: { value: pageIndex } }) {
        // update current indices
        const targetItemIndex = pageIndex * this.groupSize;
        const overhangItems = this._itemRefs.length % this.groupSize;
        const overhangAdjust = overhangItems > 0 ? this.groupSize - overhangItems : 0;
        const maxPageIndex = this._pageCount - 1;
        const maxItemIndex = maxPageIndex * this.groupSize - overhangAdjust;
        const nextItemIndex = Math.min(targetItemIndex, maxItemIndex);
        // update state
        this._selectedItemIndex = nextItemIndex;
        // animated scrolling
        this._animateToItem(this._selectedItemIndex);
    }
    // is called on every scroll event nevertheless its caused by the user or by an animation
    _handleScroll() {
        // do not align selected item if animation is in progress
        if (!this._isAnimating) {
            // determine selected item from closest offset
            this._selectedItemIndex = this._getClosestItemIndexToOffset(this._itemsRef.scrollLeft);
        }
    }
    _handleResize() {
        var _a;
        // abort eventually running scroll animations
        (_a = this._currentAnimation) === null || _a === void 0 ? void 0 : _a.cancel();
        // scroll back to the selected item
        const selectedItem = this._itemRefs[this._selectedItemIndex];
        this._itemsRef.scrollLeft = this._getItemOffset(selectedItem);
    }
    // is called only if the user interacts to scroll the items
    _handleInteractiveScroll() {
        var _a;
        // abort eventually running scroll animations
        (_a = this._currentAnimation) === null || _a === void 0 ? void 0 : _a.cancel();
        // allow snapping
        this._isSnapping = true;
    }
    // animates to a specific page
    _animateToItem(index) {
        var _a;
        const to = this._getItemOffset(this._itemRefs[index]);
        const from = this._itemsRef.scrollLeft;
        const delta = to - from;
        // stop eventually running animations
        (_a = this._currentAnimation) === null || _a === void 0 ? void 0 : _a.cancel();
        // prevent interruptions from css snapping
        this._isSnapping = false;
        // scroll to item at selected page
        this._currentAnimation = animateWithCSS(`var(${MultiItemSlider_1.MULTI_ITEM_SLIDER_ANIMATION_EASING})`, `var(${MultiItemSlider_1.MULTI_ITEM_SLIDER_ANIMATION_DURATION})`, (step) => (this._itemsRef.scrollLeft = from + delta * step), { host: this, autostart: true });
        return this._currentAnimation.finished;
    }
    // delivers the index of the item closest to the given offset
    // s. https://stackoverflow.com/a/19277804
    _getClosestItemIndexToOffset(offset) {
        const itemOffsets = this._itemRefs.map((item) => this._getItemOffset(item));
        const closestOffset = itemOffsets.reduce((a, b) => (Math.abs(b - offset) < Math.abs(a - offset) ? b : a));
        return itemOffsets.indexOf(closestOffset);
    }
    // as we can not just use `offsetLeft` (the offset parent isn't available through
    // shadow DOM), we have to come up with some magic calculating it by ourselves
    _getItemOffset(item) {
        const parentOffsetLeft = this._itemsRef.getBoundingClientRect().left;
        const parentScrollLeft = this._itemsRef.scrollLeft;
        const offsetLeft = item.getBoundingClientRect().left - parentOffsetLeft + parentScrollLeft;
        return Math.floor(offsetLeft);
    }
    // start watching for resizes once rendered
    firstRendered() {
        super.firstRendered();
        this._resizeObserver.observe(this._itemsRef);
    }
    render() {
        return html `
      <div
        class="${classMap({ items: true, snapping: this._isSnapping })}"
        style="${styleMap({ [MultiItemSlider_1.MULTI_ITEM_SLIDER_GROUP_SIZE_CSS_PROPERTY]: `${this.groupSize}` })}"
        @touchstart="${this._handleInteractiveScroll}"
        @wheel="${this._handleInteractiveScroll}"
        @scroll="${this._scrollListener}"
      >
        <slot @slotchange="${this._handleSlotChange}"></slot>
      </div>

      ${!this.hidePagination && this._pageCount > 1
            ? html `
            <nav>
              <zui-multi-item-slider-pagination
                selected-item-index="${this._selectedPageIndex}"
                item-count="${this._pageCount}"
                @zui-multi-item-slider-pagination-page-selected="${this._handlePageSelected}"
              ></zui-multi-item-slider-pagination>
            </nav>
          `
            : nothing}
    `;
    }
};
/* eslint-disable @typescript-eslint/naming-convention */
MultiItemSlider.MULTI_ITEM_SLIDER_ANIMATION_DURATION = '--zui-multi-item-slider-animation-duration';
MultiItemSlider.MULTI_ITEM_SLIDER_ANIMATION_EASING = '--zui-multi-item-slider-animation-easing';
MultiItemSlider.MULTI_ITEM_SLIDER_GROUP_SIZE_CSS_PROPERTY = '---zui-multi-item-slider-group-size';
/* eslint-enable @typescript-eslint/naming-convention */
MultiItemSlider.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    multiItemSliderStyles,
];
__decorate([
    property({ reflect: true, attribute: 'group-size', type: Number })
], MultiItemSlider.prototype, "groupSize", void 0);
__decorate([
    property({ reflect: true, attribute: 'hide-pagination', type: Boolean })
], MultiItemSlider.prototype, "hidePagination", void 0);
__decorate([
    query('.items')
], MultiItemSlider.prototype, "_itemsRef", void 0);
__decorate([
    queryAssignedElements({ selector: 'zui-multi-item-slider-item', flatten: true })
], MultiItemSlider.prototype, "_itemRefs", void 0);
__decorate([
    state()
], MultiItemSlider.prototype, "_isSnapping", void 0);
__decorate([
    state()
], MultiItemSlider.prototype, "_selectedItemIndex", void 0);
MultiItemSlider = MultiItemSlider_1 = __decorate([
    customElement('zui-multi-item-slider')
], MultiItemSlider);
export { MultiItemSlider };
