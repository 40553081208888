var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import '../../../contracts/event.contracts.js';
import { MimickedChangeEvent, MimickedInputEvent, MimickedBlurEvent } from '../../../contracts/event.classes.js';
import '../../../types.js';
import { isValueInList } from '../../../utils/types.utils.js';
import '../../../mixins/form-participation/form-participation.interfaces.js';
import { FormValidationMixin } from '../../../mixins/form-participation/form-validation.mixin.js';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin.js';
import { strictNumberParse, isValidNumber } from '../../../utils/number.utils.js';
import { clamp, numberUndefinedConverter } from '../../../utils/component.utils.js';
import Decimal from 'decimal.js';
const allowedNumericKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const allowedNavigationKeys = [
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'Backspace',
    'Delete',
    'End',
    'Home',
    'NumpadEnter',
    'Enter',
    'Tab',
];
const customAllowedKeys = ['+', '-', '.', 'v', 'c', 'x', 'a'];
const allAllowedKeys = [...allowedNumericKeys, ...allowedNavigationKeys, ...customAllowedKeys];
/**
 * The InternalInputNumberNative is our internal helper component, replacing the native input[type=number], allowing only numbers
 * to be entered and allowing full text selection.
 *
 * @example
 *  ```html
 * <zui-internal-input-number-native></zui-internal-input-number-native>
 * ```
 * @fires change - The event that fires when the user has changed <code>value</code>
 * @fires input - The event that fires when the user has made any input <code>value</code>
 */
let InternalInputNumberNative = class InternalInputNumberNative extends FormValidationMixin(FormDataHandlingMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.InputEvent = MimickedInputEvent;
        this.ChangeEvent = MimickedChangeEvent;
        this.BlurEvent = MimickedBlurEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}))) {
    constructor() {
        super();
        /**
         * The min value of the InternalInputNumberNative
         */
        this.min = undefined;
        /**
         * The max value of the InternalInputNumberNative
         */
        this.max = undefined;
        /**
         * The steps of the InternalInputNumberNative.
         */
        this.step = undefined;
        /**
         * Placeholder text for the input
         */
        this.placeholder = '';
        /**
         * Value of component, is always a number
         */
        this.value = undefined;
        /**
         * When set, the value will be not validated for bad input
         */
        this.disableBadInputValidation = false;
        /**
         * When set, the value will be not validated for step mismatch
         */
        this.disableStepMismatchValidation = false;
        this._handleInput = (event) => {
            event.stopPropagation();
            const rawInputValue = event.target.value;
            // map "empty" value to undefined, otherwise use our notion of "strictParsing"
            this.value = rawInputValue === '' ? undefined : strictNumberParse(rawInputValue);
            this.emitInputEvent();
        };
        this._handleBlur = (event) => {
            event.stopPropagation();
            this.emitBlurEvent();
        };
        this._handleChange = (event) => {
            event.stopPropagation();
            this.emitChangeEvent();
        };
        this.addValidator({
            type: 'valueMissing',
            // valid, if not required, or if the value is not undefined
            validator: () => this.required === false || !(this.value === undefined),
            validatesOnProperties: ['required'],
        });
        this.addValidator({
            type: 'rangeOverflow',
            validator: () => 
            // prettier-ignore
            this.value !== undefined && this.max !== undefined
                ? this.value <= this.max
                : true,
            validatesOnProperties: ['max'],
        });
        this.addValidator({
            type: 'rangeUnderflow',
            validator: () => 
            // prettier-ignore
            this.value !== undefined && this.min !== undefined
                ? this.value >= this.min
                : true,
            validatesOnProperties: ['min'],
        });
        // stepMismatch will be not validated if no step is set
        this.addValidator({
            type: 'stepMismatch',
            validator: () => 
            // prettier-ignore
            !this.disableStepMismatchValidation &&
                this.value !== undefined &&
                this.step !== undefined
                ? new Decimal(this.value).modulo(this.step).toNumber() === 0
                : true,
            validatesOnProperties: ['step'],
        });
        // badInput will be not validated if the attribute/property disable-bad-input-validation is set
        this.addValidator({
            type: 'badInput',
            validator: () => 
            // prettier-ignore
            this.value !== undefined && !this.disableBadInputValidation
                ? !isNaN(this.value)
                : true,
        });
    }
    // expose native selection methods and pass it to inner input element
    select() {
        this._inputElement.select();
    }
    setRangeText(replacement, start, end, selectionMode) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore because of avoiding crazy overloads
        this._inputElement.setRangeText(replacement, start, end, selectionMode);
    }
    setSelectionRange(start, end, direction) {
        this._inputElement.setSelectionRange(start, end, direction);
    }
    stepUp() {
        var _a, _b, _c;
        // we only add sth. if it is valid number, otherwise we simply take the step
        // if step is not set, we default to 1
        const step = (_a = this.step) !== null && _a !== void 0 ? _a : 1;
        const sum = isValidNumber(this.value) ? new Decimal(this.value).add(step).toNumber() : step;
        this.value = clamp((_b = this.min) !== null && _b !== void 0 ? _b : -Infinity, sum, (_c = this.max) !== null && _c !== void 0 ? _c : Infinity);
        this.emitInputEvent();
        this.emitChangeEvent();
    }
    stepDown() {
        var _a, _b, _c;
        const step = (_a = this.step) !== null && _a !== void 0 ? _a : 1;
        const sub = isValidNumber(this.value) ? new Decimal(this.value).sub(step).toNumber() : -step;
        this.value = clamp((_b = this.min) !== null && _b !== void 0 ? _b : -Infinity, sub, (_c = this.max) !== null && _c !== void 0 ? _c : Infinity);
        this.emitInputEvent();
        this.emitChangeEvent();
    }
    // we have to delegate focus manually here as the DelegateFocusMixin only works with components with shadow-dom
    focus() {
        var _a;
        super.focus();
        if (!this.disabled) {
            (_a = this._inputElement) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }
    get valueAsNumber() {
        var _a;
        return (_a = this.value) !== null && _a !== void 0 ? _a : NaN;
    }
    // here is the guard for allowed keys. Everything not allowed will be stopped on keyDown, stopping the event chain.
    // due to imitation of input type number ArrowUp and ArrowDown must be managed to handle stepUp and stepDown
    _handleKeyDown(event) {
        // Allow all control key events like copy & paste
        if (event.ctrlKey || event.metaKey) {
            return;
        }
        if (!isValueInList(event.key, allAllowedKeys)) {
            event.preventDefault();
            return;
        }
        switch (event.key) {
            // allow select all shortcut
            // allow copy+paste shortcuts
            case 'a':
            case 'c':
            case 'x':
            case 'v': {
                if (!event.metaKey || !event.ctrlKey) {
                    event.preventDefault();
                }
                break;
            }
            case 'ArrowUp':
                event.preventDefault();
                this.stepUp();
                break;
            case 'ArrowDown':
                event.preventDefault();
                this.stepDown();
                break;
            case '.':
            case '0':
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
            case 'ArrowLeft':
            case 'ArrowRight':
            case 'Delete':
            case 'End':
            case 'Home':
            case 'Tab':
            case 'Backspace':
            case 'Enter':
            case 'NumpadEnter':
            case '+':
            case '-':
                break;
            default:
                // exhaustiveness checking
                // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
                const t = event.key;
                break;
        }
    }
    // we have no shadowDOM at all
    createRenderRoot() {
        return this;
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        // now we will update the input directly, but only if the current value is actually not NaN
        if (this.value === undefined) {
            this._inputElement.value = '';
        }
        else if (!isNaN(this.value)) {
            this._inputElement.value = `${this.value}`;
        }
    }
    render() {
        return html `
      <input
        placeholder="${this.placeholder}"
        type="text"
        title=""
        ?required="${this.required}"
        ?disabled="${this.disabled}"
        ?readonly="${this.readonly}"
        inputmode="decimal"
        @input="${this._handleInput}"
        @change="${this._handleChange}"
        @blur="${this._handleBlur}"
        @keydown="${this._handleKeyDown}"
      />
    `;
    }
};
__decorate([
    property({ reflect: true, converter: numberUndefinedConverter })
], InternalInputNumberNative.prototype, "min", void 0);
__decorate([
    property({ reflect: true, converter: numberUndefinedConverter })
], InternalInputNumberNative.prototype, "max", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], InternalInputNumberNative.prototype, "required", void 0);
__decorate([
    property({ reflect: true, converter: numberUndefinedConverter })
], InternalInputNumberNative.prototype, "step", void 0);
__decorate([
    property({ type: String, reflect: true })
], InternalInputNumberNative.prototype, "placeholder", void 0);
__decorate([
    property({ reflect: true, converter: numberUndefinedConverter })
], InternalInputNumberNative.prototype, "value", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'disable-bad-input-validation' })
], InternalInputNumberNative.prototype, "disableBadInputValidation", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'disable-step-mismatch-validation' })
], InternalInputNumberNative.prototype, "disableStepMismatchValidation", void 0);
__decorate([
    query('input')
], InternalInputNumberNative.prototype, "_inputElement", void 0);
InternalInputNumberNative = __decorate([
    customElement('zui-internal-input-number-native')
], InternalInputNumberNative);
export { InternalInputNumberNative };
