var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const styles = ""
const themeLight = ""
const themeDark = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const selectPlaceholderStyles = css `
  ${unsafeCSS(styles)}
`;
// eslint-disable-next-line no-shadow
var PlaceholderSpecifier;
(function (PlaceholderSpecifier) {
    PlaceholderSpecifier["ItemCount"] = "itemCount";
    PlaceholderSpecifier["Item"] = "item";
    PlaceholderSpecifier["Items"] = "items";
    PlaceholderSpecifier["SelectedCount"] = "selectedCount";
    PlaceholderSpecifier["Selected"] = "selected";
    PlaceholderSpecifier["Selection"] = "selection";
})(PlaceholderSpecifier || (PlaceholderSpecifier = {}));
/**
 * A feature component providing functionality for select placeholders and selected labels.
 * It's used by the `zui-select` feature internally but can be slotted explicitly into it as well to customize placeholders
 * and labels likewise.
 *
 * ### Functionality
 * It can be parametrized with patterns for multiple selection states.
 * Every pattern template can use the following specifiers to be replaced:
 *
 * | specifier      | description                            |
 * | :------------- | :------------------------------------- |
 * | %itemCount     | amount of available items              |
 * | %item          | first available item                   |
 * | %items         | all available items, joined by a comma |
 * | %selectedCount | amount of selected items               |
 * | %selected      | first selected item                    |
 * | %selection     | all selected items, joined by a comma  |
 *
 * @example
 * ```HTML
 * <zui-select-placeholder>Custom placeholder</zui-select-placeholder>
 * ```
 *
 * ```HTML
 * <zui-select-placeholder pattern-all="%selectedCount selected"
 *                         pattern-many="%selectedCount selected"
 *                         pattern-single="%selected"
 *                         slot="placeholder"
 * >
 *   Custom placeholder
 * </zui-select-placeholder>
 * ```
 *
 * @slot - default slot for defining the placeholder label (aka watermark)
 */
let SelectPlaceholder = class SelectPlaceholder extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * is used if all are selected
         */
        this.patternAll = '%selectedCount selected';
        /**
         * is used if not all are selected, but more than one
         */
        this.patternMany = '%selectedCount selected';
        /**
         * is used if exactly one item is selected (but more than one are selectable)
         */
        this.patternSingle = '%selected';
    }
    /**
     * formats a label for the current selection using the appropriate pattern
     *
     * @param items
     * @param selection
     * @param multiple
     *
     * @returns string
     */
    format(items, selection, multiple) {
        // use replacement patters for multiple selection
        if (multiple) {
            if (items.length === selection.length) {
                return this.usePattern(this.patternAll, items, selection);
            }
            else if (selection.length > 1) {
                return this.usePattern(this.patternMany, items, selection);
            }
            else {
                return this.usePattern(this.patternSingle, items, selection);
            }
        }
        else {
            // single selection means that we only get passed one option
            const ref = selection[0];
            return ref.innerHTML;
        }
    }
    /**
     * utility to get text only from a given option or menu item
     *
     * @param option any option from the select component.
     *
     * @returns string
     */
    extractText(option) {
        return Array.from(option.childNodes)
            .filter(({ nodeType }) => nodeType !== Node.COMMENT_NODE)
            .map(({ textContent }) => textContent !== null && textContent !== void 0 ? textContent : '')
            .join('')
            .trim();
    }
    /**
     * delivers the label based on the current selection and all available items
     *
     * @param pattern
     * @param items
     * @param selection
     *
     * @returns string
     */
    usePattern(pattern, items, selection) {
        const specifiers = Object.values(PlaceholderSpecifier);
        const regex = new RegExp(`%(${specifiers.join('|')})`, 'gi');
        return pattern.replaceAll(regex, (match, specifier) => {
            switch (specifier) {
                // replace all item variables
                case PlaceholderSpecifier.ItemCount:
                    return items.length.toString();
                case PlaceholderSpecifier.Item:
                    return this.extractText(items[0]);
                case PlaceholderSpecifier.Items:
                    return items.map((item) => this.extractText(item)).join(', ');
                // replace all selection variables
                case PlaceholderSpecifier.SelectedCount:
                    return selection.length.toString();
                case PlaceholderSpecifier.Selected:
                    return this.extractText(selection[0]);
                case PlaceholderSpecifier.Selection:
                    return selection.map((selected) => this.extractText(selected)).join(', ');
                default:
                    // just make sure that changes to the enum require a replacement implementation
                    // https://stackoverflow.com/a/39419171/1146207
                    // eslint-disable-next-line no-case-declarations,@typescript-eslint/no-unused-vars
                    const exhaustiveCheck = specifier;
                    return match;
            }
        });
    }
    render() {
        return html `<slot></slot>`;
    }
};
SelectPlaceholder.styles = [
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    selectPlaceholderStyles,
];
__decorate([
    property({ reflect: true, type: String, attribute: 'pattern-all' })
], SelectPlaceholder.prototype, "patternAll", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'pattern-many' })
], SelectPlaceholder.prototype, "patternMany", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'pattern-single' })
], SelectPlaceholder.prototype, "patternSingle", void 0);
SelectPlaceholder = __decorate([
    customElement('zui-select-placeholder')
], SelectPlaceholder);
export { SelectPlaceholder };
