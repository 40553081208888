var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { html, unsafeCSS, css } from 'lit';
import { customElement, property, queryAll } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
import { DateTime } from 'luxon';
import { daysOfWeekConverter, getCalendarDaysForSelectedMonth, getDateTimesFromJsDates, getDefaultLocale, getNextMonthStart, getPreviousMonthStart, getStartOfMonth, getStartOfMonthName, getWeekdays, hasWeekday, isoDateConverter, isSameDay, isSameMonth, isSameYear, someIsSameDay, someIsSameMonth, someIsSameYear, } from '../utils/date-picker.utils.js';
// import needed other componets
import '../../picker/picker-grid-cell/picker-grid-cell.component.js';
import '../../picker/picker-header-cell/picker-header-cell.component.js';
import '../../picker/picker-header/picker-header.component.js';
import '../../picker/picker-grid/picker-grid.component.js';
import { GetSharedEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import '../../interactive-icon/interactive-icon.component.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const style = ""
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const datePickerDayPickerStyles = css `
  ${unsafeCSS(style)}
`;
// icons
import '@zeiss/zui-icons/dist/components/generated/arrows-arrow-outline-actually-centred-right.component.js';
import '@zeiss/zui-icons/dist/components/generated/arrows-arrow-outline-actually-centred-left.component.js';
// TODO: these have the wrong naming convention; rename them?
class DayPickerDaySelectedEvent extends GetSharedEventClass('day-picker-day-selected') {
}
class DayPickerMonthSelectedEvent extends GetSharedEventClass('day-picker-month-selected') {
}
class DayPickerNextMonthSelectedEvent extends GetSharedEventClass('day-picker-next-month-selected') {
}
class DayPickerPreviousMonthSelectedEvent extends GetSharedEventClass('day-picker-previous-month-selected') {
}
class DayPickerWeekdaySelectedEvent extends GetSharedEventClass('day-picker-weekday-selected') {
}
/**
 * The date picker day picker is a feature component that should be used inside the date picker component for selecting days.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-date-picker-day-picker
 *   disabled-days-of-week="Saturday,Sunday"
 *   locale="en-US"
 *   max="2021-12-31T23:59:59.999+01:00"
 *   min="2021-01-01T00:00:00.000+01:00"
 *   selected-date="2021-06-15T08:00:00.000+02:00"
 *   week-start="Monday">
 * </zui-date-picker-day-picker>
 * ```
 */
let DatePickerDayPicker = class DatePickerDayPicker extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.DaySelectedEvent = DayPickerDaySelectedEvent;
        this.MonthSelectedEvent = DayPickerMonthSelectedEvent;
        this.NextMonthSelectedEvent = DayPickerNextMonthSelectedEvent;
        this.PreviousMonthSelectedEvent = DayPickerPreviousMonthSelectedEvent;
        this.WeekdaySelectedEvent = DayPickerWeekdaySelectedEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * whether the month select is disabled or not
         */
        this.monthSelectDisabled = false;
        /**
         * max date
         */
        this.max = null;
        /**
         * min date
         */
        this.min = null;
        /**
         * disabled dates
         */
        this.disabledDates = [];
        /**
         * disabled months
         */
        this.disabledMonths = [];
        /**
         * disabled years
         */
        this.disabledYears = [];
        /**
         * disabled days of week
         */
        this.disabledDaysOfWeek = [];
        /**
         * locale
         */
        this.locale = getDefaultLocale();
        /**
         * selected date
         */
        this.selectedDate = null;
        /**
         * current date
         *
         * @returns currentDate
         */
        this.currentDate = new Date();
        this._headerCellTemplate = ({ text, value }) => {
            return html `
      <zui-picker-header-cell
        slot="pickerGridHeaderCells"
        style="--zui-picker-header-cell-width: 32px; --zui-picker-header-cell-height: 56px"
        text="${text}"
        value="${value}"
      ></zui-picker-header-cell>
    `;
        };
        this._gridCellTemplate = ({ content, disabled, emphasis, value }) => {
            return html `
      <zui-picker-grid-cell
        ?disabled="${disabled}"
        emphasis="${emphasis}"
        slot="pickerGridCells"
        style="--zui-picker-grid-cell-width: 32px"
        value="${value}"
      >
        ${content}
      </zui-picker-grid-cell>
    `;
        };
    }
    get _maxDateDT() {
        return this.max ? DateTime.fromJSDate(this.max) : undefined;
    }
    get _minDateDT() {
        return this.min ? DateTime.fromJSDate(this.min) : undefined;
    }
    get _selectedDateDT() {
        return this.selectedDate ? DateTime.fromJSDate(this.selectedDate) : undefined;
    }
    get _currentDateDT() {
        return DateTime.fromJSDate(this.currentDate);
    }
    get _pickerHeaderValue() {
        return `${getStartOfMonthName(this._currentDateDT.year, this._currentDateDT.month, this.locale)} ${this._currentDateDT.year}`;
    }
    get _gridCellDates() {
        const startOfMonth = getStartOfMonth(this._currentDateDT.year, this._currentDateDT.month);
        return getCalendarDaysForSelectedMonth(startOfMonth, this.locale, this.weekStart);
    }
    get _headerCellData() {
        return getWeekdays(this.locale, this.weekStart);
    }
    get _disabledGridCellConditions() {
        const disabledDates = getDateTimesFromJsDates(this.disabledDates);
        const disabledMonths = getDateTimesFromJsDates(this.disabledMonths);
        const disabledYears = getDateTimesFromJsDates(this.disabledYears);
        return [
            // minDate, if set
            (date) => (this._minDateDT ? date.startOf('day') < this._minDateDT.startOf('day') : false),
            // maxDate, if set
            (date) => (this._maxDateDT ? date.startOf('day') > this._maxDateDT.startOf('day') : false),
            // disabledDays
            (date) => someIsSameDay(date, disabledDates),
            // disabledMonths
            (date) => someIsSameMonth(date, disabledMonths),
            // disabledYears
            (date) => someIsSameYear(date, disabledYears),
            // disabledWeekDays
            (date) => hasWeekday(date, this.disabledDaysOfWeek),
        ];
    }
    get _gridCellFocusConditions() {
        return [
            () => (this._selectedDateDT ? isSameMonth(this._selectedDateDT, this._currentDateDT) : false),
            () => (this._selectedDateDT ? isSameYear(this._selectedDateDT, this._currentDateDT) : false),
        ];
    }
    get _gridCellSelectedConditions() {
        return [(date) => (this._selectedDateDT ? isSameDay(date, this._selectedDateDT) : false)];
    }
    get _gridCellSubtleConditions() {
        const startOfMonth = getStartOfMonth(this._currentDateDT.year, this._currentDateDT.month);
        return [(date) => !isSameMonth(date, startOfMonth)];
    }
    _isGridCellDisabled(date) {
        return this._disabledGridCellConditions.some((predicate) => predicate(date));
    }
    _canFocusGridCell() {
        return this._gridCellFocusConditions.every((predicate) => predicate());
    }
    _getGridCellEmphasis(date) {
        const isSelected = this._gridCellSelectedConditions.every((predicate) => predicate(date));
        const isSubtle = this._gridCellSubtleConditions.every((predicate) => predicate(date));
        return isSelected ? 'selected' : isSubtle ? 'subtle' : 'default';
    }
    _focusSelectedDate() {
        if (!this._canFocusGridCell()) {
            return;
        }
        // TODO: rework to make it more readable
        const pickerGridCellMatchingSelectedDate = Array.from(this._pickerGridCells).find((pickerGridCell) => this._selectedDateDT ? isSameDay(DateTime.fromISO(pickerGridCell.value), this._selectedDateDT) : false);
        pickerGridCellMatchingSelectedDate === null || pickerGridCellMatchingSelectedDate === void 0 ? void 0 : pickerGridCellMatchingSelectedDate.focus();
    }
    _handleDaySelected({ detail, }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { selected, value } = detail;
            if (selected.disabled) {
                return;
            }
            this.selectedDate = new Date(value);
            this._emitDayPickerDaySelectedEvent({
                value: this.selectedDate,
            });
        });
    }
    _handleMonthSelected() {
        this._emitDayPickerMonthSelectedEvent({ value: this.currentDate });
    }
    _handleNextMonthSelected() {
        this.currentDate = getNextMonthStart(this._currentDateDT.year, this._currentDateDT.month).toJSDate();
        this._emitDayPickerNextMonthSelectedEvent({
            startOfMonth: this.currentDate,
            endOfMonth: this._currentDateDT.endOf('month').toJSDate(),
        });
    }
    _handlePreviousMonthSelected() {
        this.currentDate = getPreviousMonthStart(this._currentDateDT.year, this._currentDateDT.month).toJSDate();
        this._emitDayPickerPreviousMonthSelectedEvent({
            startOfMonth: this.currentDate,
            endOfMonth: this._currentDateDT.endOf('month').toJSDate(),
        });
    }
    _handleWeekdaySelected({ detail }) {
        this._emitDayPickerWeekdaySelectedEvent({ value: detail.value });
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('selectedDate') || changedProperties.has('currentDate')) {
            this._focusSelectedDate();
        }
    }
    render() {
        return html `
      <zui-picker-header
        ?disabled="${this.monthSelectDisabled}"
        value="${this._pickerHeaderValue}"
        @zui-picker-header-current-selected="${this._handleMonthSelected}"
        @zui-picker-header-next-selected="${this._handleNextMonthSelected}"
        @zui-picker-header-previous-selected="${this._handlePreviousMonthSelected}"
      >
        <zui-interactive-icon slot="icon-left">
          <zui-icon-arrows-arrow-outline-actually-centred-left></zui-icon-arrows-arrow-outline-actually-centred-left>
        </zui-interactive-icon>
        <zui-interactive-icon slot="icon-right">
          <zui-icon-arrows-arrow-outline-actually-centred-right></zui-icon-arrows-arrow-outline-actually-centred-right>
        </zui-interactive-icon>
      </zui-picker-header>

      <zui-picker-grid
        columns="7"
        @zui-picker-grid-cell-selected="${this._handleDaySelected}"
        @zui-picker-grid-header-cell-selected="${this._handleWeekdaySelected}"
      >
        ${this._headerCellData.map(this._headerCellTemplate)}
        ${this._gridCellDates.map((dateTime) => {
            const disabled = this._isGridCellDisabled(dateTime);
            const emphasis = this._getGridCellEmphasis(dateTime);
            return this._gridCellTemplate({
                content: dateTime.day.toString(),
                disabled,
                emphasis,
                value: dateTime.toISO(),
            });
        })}
      </zui-picker-grid>
    `;
    }
};
DatePickerDayPicker.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    datePickerDayPickerStyles,
];
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'month-select-disabled' })
], DatePickerDayPicker.prototype, "monthSelectDisabled", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], DatePickerDayPicker.prototype, "max", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], DatePickerDayPicker.prototype, "min", void 0);
__decorate([
    property({ attribute: false })
], DatePickerDayPicker.prototype, "disabledDates", void 0);
__decorate([
    property({ attribute: false })
], DatePickerDayPicker.prototype, "disabledMonths", void 0);
__decorate([
    property({ attribute: false })
], DatePickerDayPicker.prototype, "disabledYears", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'disabled-days-of-week', converter: daysOfWeekConverter })
], DatePickerDayPicker.prototype, "disabledDaysOfWeek", void 0);
__decorate([
    property({ reflect: true, type: String })
], DatePickerDayPicker.prototype, "locale", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'selected-date', converter: isoDateConverter })
], DatePickerDayPicker.prototype, "selectedDate", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'current-date', converter: isoDateConverter })
], DatePickerDayPicker.prototype, "currentDate", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'week-start' })
], DatePickerDayPicker.prototype, "weekStart", void 0);
__decorate([
    queryAll('zui-picker-grid-cell')
], DatePickerDayPicker.prototype, "_pickerGridCells", void 0);
DatePickerDayPicker = __decorate([
    customElement('zui-date-picker-day-picker')
], DatePickerDayPicker);
export { DatePickerDayPicker };
