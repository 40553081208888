var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createPopper } from '@popperjs/core/dist/esm/popper-lite.js';
import flip from '@popperjs/core/dist/esm/modifiers/flip.js';
import offset from '@popperjs/core/dist/esm/modifiers/offset.js';
import { css, html, unsafeCSS } from 'lit';
import { BaseElement } from '../../components/base/base-element.class.js';
import { customElement, property } from 'lit/decorators.js';
import { commaListConverter } from '../../utils/component.utils.js';
import { generateUid, getContentsFromPortal, unregisterPortal } from '../../utils/portal.utils.js';
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const theme = ""
const themeLight = ""
const themeDark = ""
import { hostStyles } from '../../host.styles.js';
const style = ":host{position:relative;pointer-events:none}"
import { GetNotificationEventClass } from '../../contracts/event.classes.js';
import { WithEventsMixin } from '../../mixins/events/events.mixin.js';
import '../../contracts/event.contracts.js';
// deps
import '../portal/portal.directive.js';
export const OVERLAY_DIRECTIVE_STYLES = css `
  ${unsafeCSS(style)}
`;
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const OVERLAY_PORTAL = 'overlay';
const OVERLAY_DEFAULT_PLACEMENTS = ['bottom-start'];
class OverlayReadyEvent extends GetNotificationEventClass('overlay-ready') {
}
/**
 * This directive allows projecting arbitrary html content into an overlay.
 * The projected contents will be positioned relative to the host.
 *
 * @example
 * HTML:
 * ```html
 * <zui-overlay-directive>
 *   <div>
 *     Projected content
 *   </div>
 * </zui-overlay-directive>
 * ```
 * @fires {GetZuiEvent<OverlayDirective, 'ReadyEvent'>} zui-overlay-ready - fired when portal content updated with the latest slot contents
 * @slot - The default slot content will be projected into the overlay.
 */
let OverlayDirective = class OverlayDirective extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.ReadyEvent = OverlayReadyEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * The destination overlay name is passed through.
         */
        this.portal = `${OVERLAY_PORTAL}-${generateUid()}`;
        /**
         * Allowed placements of the overlay content relative to the host.
         * Multiple values can be provided as comma separated list. The
         * first setting will be applied initially.
         * Defaults to `bottom-start`.
         *
         * @example `placements="bottom-start,top-end"`
         * @see https://popper.js.org/docs/v2/constructors/#options
         */
        this.placements = OVERLAY_DEFAULT_PLACEMENTS;
        /**
         * Clones projected contents instead of moving them.
         * You can not clone nested slots. If this is what you want use the shouldProjectSlots property.
         */
        this.clone = false;
        /**
         * Whether to flip content if space is up or not.
         * Possible flipping directions are set via the `placements` attribute.
         */
        this.flip = false;
        /**
         * Allows setting a padding to the flip detection.
         */
        this.flipPadding = 0;
        /**
         * If true any slots in the to be projected content will be handled. Their slotted content will be transfered into the overlay and back when unmounting.
         * This is necessary if nested slots should be displayed properly in the portal.
         * A slot should not be the first child of the portal as it will be flattened.
         * Always wrap your projected content in a non-slot element if you want it to be projected back to its origin after the portal is unmounted.
         */
        this.shouldProjectSlots = false;
        /**
         * An optional callback function which allows to reveal the positioning reference element.
         *
         * @returns VirtualElement minimal element implementation to derive position
         */
        this.positionReferenceCallback = () => this;
        /**
         * An optional function which allows to customize the tooltip offset.
         *
         * @returns function offset handler
         */
        this.offsetHandler = () => [0, 0];
    }
    // Forces popper to render again.
    forcePositioning() {
        var _a;
        (_a = this._popperInstance) === null || _a === void 0 ? void 0 : _a.forceUpdate();
    }
    handleSlotChange() {
        // wait for the slotted content to be rendered in next render cycle
        requestAnimationFrame(() => this._positionSlotContents());
    }
    disconnectedCallback() {
        unregisterPortal(this.portal);
        this._endPositioning();
        super.disconnectedCallback();
    }
    // recursively finds slotted contents for positioning
    _positionSlotContents() {
        // check if contents are present (first element is the injected style)
        const [, element] = getContentsFromPortal(this.portal);
        if (element !== undefined) {
            this._endPositioning();
            this._startPositioning(element);
        }
    }
    // creates a new popper instance
    _startPositioning(target) {
        var _a;
        // instantiate popper placement library in lite mode
        // TODO: this looks odd, usually I'd expect a callback to be called by Popper instead calling it right here...
        // we default to this, because we might get passed undefined... :(
        this._popperInstance = createPopper((_a = this.positionReferenceCallback()) !== null && _a !== void 0 ? _a : this, target);
        // set options to instance
        this._setPositioningOptions();
    }
    // derives and updates options for the popper instance
    _setPositioningOptions() {
        return __awaiter(this, void 0, void 0, function* () {
            // we need an instance to be prepared
            if (!this._popperInstance) {
                return;
            }
            // instantiate popper placement library in lite mode
            const [placement, ...fallbackPlacements] = this.placements.length > 0 ? this.placements : OVERLAY_DEFAULT_PLACEMENTS;
            // customize flip modifier to place menu above or below
            const customFlip = Object.assign(Object.assign({}, flip), { enabled: this.flip, options: { fallbackPlacements, padding: this.flipPadding } });
            // prepare custom offsets
            const customOffset = Object.assign(Object.assign({}, offset), { options: { offset: this.offsetHandler } });
            // prepare modifiers
            const modifiers = [customFlip, customOffset];
            // set the options to the instance
            yield this._popperInstance.setOptions({ modifiers, placement });
        });
    }
    // finishes an existing popper instance
    _endPositioning() {
        var _a;
        // destroy library instance
        (_a = this._popperInstance) === null || _a === void 0 ? void 0 : _a.destroy();
        this._popperInstance = undefined;
    }
    _handlePortalContentProjected() {
        this.emitOverlayReadyEvent();
    }
    updated(_changedProperties) {
        super.updated(_changedProperties);
        // update popper options if properties have been modified
        if (_changedProperties.has('flip') ||
            _changedProperties.has('offsetHandler') ||
            _changedProperties.has('placements')) {
            this._setPositioningOptions();
        }
    }
    render() {
        return html `
      <zui-portal-directive
        @zui-portal-content-projected="${this._handlePortalContentProjected}"
        ?clone=${this.clone}
        portal=${this.portal}
        ?shouldProjectSlots=${this.shouldProjectSlots}
      >
        <slot @slotchange="${this.handleSlotChange}"></slot>
      </zui-portal-directive>
    `;
    }
};
OverlayDirective.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    OVERLAY_DIRECTIVE_STYLES,
];
__decorate([
    property({ reflect: true, type: String })
], OverlayDirective.prototype, "portal", void 0);
__decorate([
    property({ reflect: true, converter: commaListConverter() })
], OverlayDirective.prototype, "placements", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], OverlayDirective.prototype, "clone", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], OverlayDirective.prototype, "flip", void 0);
__decorate([
    property({ reflect: true, type: Number, attribute: 'flip-padding' })
], OverlayDirective.prototype, "flipPadding", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'should-project-slots' })
], OverlayDirective.prototype, "shouldProjectSlots", void 0);
__decorate([
    property({ attribute: false, type: Object })
], OverlayDirective.prototype, "positionReferenceCallback", void 0);
__decorate([
    property({ attribute: false, type: Object })
], OverlayDirective.prototype, "offsetHandler", void 0);
OverlayDirective = __decorate([
    customElement('zui-overlay-directive')
], OverlayDirective);
export { OverlayDirective };
