var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{--zui-title-stack-meta-item-separator-height: calc(var(--zui-gu) * 1.75);--zui-title-stack-meta-item-separator-width: calc(var(--zui-gu) * 0.125);---zui-title-stack-meta-item-height: initial;---zui-title-stack-meta-item-separator-offset: calc( (var(---zui-title-stack-meta-item-height) - var(--zui-title-stack-meta-item-separator-height)) / 2 );position:relative;display:inline;padding:0 calc(var(--zui-gu) * 1) 0 calc(var(--zui-gu) * 1.125);white-space:nowrap}.separator{position:absolute;top:var(---zui-title-stack-meta-item-separator-offset);right:auto;bottom:auto;left:0;display:block;width:var(--zui-title-stack-meta-item-separator-width);height:var(--zui-title-stack-meta-item-separator-height);background:var(--zui-color-title-stack-separator)}:host([hide-separator]){padding-left:0}:host([hide-separator]) .separator{display:none}"
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-title-stack-label: var(--zui-color-gs-85);--zui-color-title-stack-headline: var(--zui-color-gs-100);--zui-color-title-stack-separator: var(--zui-color-gs-60);--zui-color-title-stack-meta: var(--zui-color-gs-85)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-title-stack-label: var(--zui-color-gs-70);--zui-color-title-stack-headline: var(--zui-color-gs-50);--zui-color-title-stack-separator: var(--zui-color-gs-90);--zui-color-title-stack-meta: var(--zui-color-gs-70)}"
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const titleStackMetaItemStyles = css `
  ${unsafeCSS(styles)}
`;
/**
 * The title stack meta item is used to compose additional captions of a title stack.
 * Please refer to the readme of the title stack component for further details.
 *
 * ## Skeleton
 * The Skeleton components can be added to the named skeleton slot and will be displayed when **nothing** is in the default slot.
 * When the skeleton is not displayed make sure there are no spaces or line breaks passed in the default slot.
 *
 *
 * @example
 * HTML:
 *
 * A title stack with two meta items
 * ```html
 * <zui-title-stack>
 *   <zui-title-stack-meta-item slot="meta">Caption 1</zui-title-stack-meta-item>
 * </zui-title-stack>
 * ```
 *
 * A title stack meta item with skeleton
 * ```html
 * <zui-title-stack>
 *  <zui-title-stack-meta-item slot="meta"
 *     ><zui-skeleton-rectangle slot="skeleton" style="--zui-skeleton-rectangle-width: 120px; --zui-skeleton-rectangle-height: 16px;"
 *     ></zui-skeleton-rectangle
 *  ></zui-title-stack-meta-item>
 * </zui-title-stack>
 * ```
 *
 * @slot default - This is the default slot which should reflect the text node.
 * @slot skeleton - This is the slot for adding a skeleton instead text content. The Skeleton is only displayed when the default slot is empty.
 * @cssprop --zui-title-stack-meta-item-separator-height - sets the vertical size of the item separator
 * @cssprop --zui-title-stack-meta-item-separator-width - sets the horizontal size of the item separator
 */
let TitleStackMetaItem = class TitleStackMetaItem extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * Sets the size of the title stack
         */
        this.size = 'm';
        /**
         * Allows hiding the leading separator
         */
        this.hideSeparator = false;
    }
    render() {
        // DO NOT ADD LINE BREAKS as it will lead to unwanted trailing white space
        // prettier-ignore
        return html `<span class="separator"></span>
    <slot>
      <slot name="skeleton"></slot>
    </slot>`;
    }
};
TitleStackMetaItem.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    titleStackMetaItemStyles,
];
__decorate([
    property({ reflect: true })
], TitleStackMetaItem.prototype, "size", void 0);
__decorate([
    property({ reflect: true, attribute: 'hide-separator', type: Boolean })
], TitleStackMetaItem.prototype, "hideSeparator", void 0);
TitleStackMetaItem = __decorate([
    customElement('zui-title-stack-meta-item')
], TitleStackMetaItem);
export { TitleStackMetaItem };
