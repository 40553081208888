var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isDefined } from './component.utils.js';
import { getParentElementBySelector } from './mixin.utils.js';
import { BaseElement } from '../components/base/base-element.class.js';
const PORTAL_DEFAULT_NAME = 'default';
/**
 * Allows setting a new portal instance to the global scope.
 *
 * @param name - the name of the portal
 * @param portal - a reference to a portal instance
 */
export const registerPortal = (name = PORTAL_DEFAULT_NAME, portal) => {
    var _a, _b;
    // add (replace) the portal in registry
    (_b = (_a = window.zui) === null || _a === void 0 ? void 0 : _a.portals) === null || _b === void 0 ? void 0 : _b.set(name, portal);
};
/**
 * Removes registered portals from the global scope.
 *
 * @param name - the name of the portal
 */
export const unregisterPortal = (name = PORTAL_DEFAULT_NAME) => {
    var _a, _b, _c;
    (_a = getPortal(name)) === null || _a === void 0 ? void 0 : _a.remove();
    (_c = (_b = window.zui) === null || _b === void 0 ? void 0 : _b.portals) === null || _c === void 0 ? void 0 : _c.delete(name);
};
/**
 * Checks whether a portal of the given name exists.
 *
 * @param name - the name of the portal
 * @returns boolean - portal name is known or not
 */
export const portalExists = (name = PORTAL_DEFAULT_NAME) => {
    var _a;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return isDefined((_a = window.zui) === null || _a === void 0 ? void 0 : _a.portals) ? window.zui.portals.has(name) : false;
};
/**
 * Delivers a registered portal.
 *
 * @param name - the name of the portal
 * @returns portal - a portal element instance if found
 */
export const getPortal = (name = PORTAL_DEFAULT_NAME) => {
    // nothing found
    if (!portalExists(name)) {
        return undefined;
    }
    // TODO: this looks like a race condition
    // deliver already registered portal
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return window.zui.portals.get(name);
};
/**
 * Creates, registers and returns a new portal instance.
 * This would not create existing portals, but return those references instead.
 *
 * @param name - the name of the portal
 * @param origin from where the portal should be inserted, must be set for buildTheming
 * @returns portal - a promise resolving the portal element instance
 */
export const preparePortal = (name = PORTAL_DEFAULT_NAME, origin) => __awaiter(void 0, void 0, void 0, function* () {
    // add the namespace and the map if missing
    if (!window.zui) {
        window.zui = {};
    }
    if (!window.zui.portals) {
        window.zui.portals = new Map();
    }
    // return references of existing one
    if (portalExists(name)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return getPortal(name);
    }
    // deliver the created portal once rendered
    return new Promise((resolve) => {
        var _a;
        // create a new portal and wait for emit
        const portal = window.document.createElement('zui-portal');
        portal.name = name;
        portal.addEventListener('zui-portal-ready', () => resolve(portal), { once: true, passive: true });
        // Portals are added to the body or, if build-theming is enabled
        // they will be added to their _nearest_ theme-provider
        let portalEntry = null;
        if (BaseElement.FEATURE_ENABLE_BUILD_THEMES) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            portalEntry = (_a = getParentElementBySelector(origin, 'zui-theme-provider')) !== null && _a !== void 0 ? _a : window.document.body;
        }
        else {
            portalEntry = window.document.body;
        }
        portalEntry.appendChild(portal);
    });
});
/**
 * Delivers the currently projected contents from a portal.
 *
 * @param portal - name of the portal
 * @param selector - optional selector to narrow result
 * @returns content - the projected content
 */
export const getContentsFromPortal = (portal, selector) => {
    var _a, _b, _c, _d;
    let results;
    // use the given selector to narrow down result
    if (selector) {
        results = (_b = (_a = getPortal(portal)) === null || _a === void 0 ? void 0 : _a.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelectorAll(selector);
    }
    else {
        results = (_d = (_c = getPortal(portal)) === null || _c === void 0 ? void 0 : _c.shadowRoot) === null || _d === void 0 ? void 0 : _d.children;
    }
    // deliver the first element if found
    return Array.from(results !== null && results !== void 0 ? results : []);
};
/**
 * Delivers the assigned elements of any slots in the currently projected content from a portal. These are not located in the shadow root but are placed next to it.
 *
 * @param portal - name of the portal
 * @param selector - optional selector to narrow result
 * @returns content - the projected assigned elements to any slots in the portal
 */
export const getSlottedContentsFromPortal = (portal, selector) => {
    var _a, _b;
    let results;
    if (selector) {
        results = (_a = getPortal(portal)) === null || _a === void 0 ? void 0 : _a.querySelectorAll(selector);
    }
    else {
        results = (_b = getPortal(portal)) === null || _b === void 0 ? void 0 : _b.children;
    }
    return Array.from(results !== null && results !== void 0 ? results : []);
};
/**
 * Generates a simple unique id to be used e.g. as portal name.
 *
 * @returns uuid - the generated unique identifier
 */
export const generateUid = () => Math.random().toString(36).slice(-6);
