export class RequestError extends Error {
  constructor(public code: number, message: string, public source?: string) {
    super(message);
  }

  public override toString(): string {
    return `${this.code} : ${this.message}`;
  }
}

export interface InternalHandleErrorOptions {
  caption: string;
  error?: unknown;
}

export interface HandleErrorOptions {
  caption: string;
  code?: number;
}

export type InternalErrorHandler = (options: InternalHandleErrorOptions) => void;

export class ErrorCodeResponseError extends Error {
  constructor(public errorCode: string) {
    super();
  }
}

export type ErrorCodeMessageSupplier = (errorCode: string) => string;
