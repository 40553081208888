var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var Select_1;
import '@popperjs/core/lib/enums.js';
import { css, html, nothing, unsafeCSS } from 'lit';
import { customElement, eventOptions, property, query, queryAssignedElements, state } from 'lit/decorators.js';
import { cache } from 'lit/directives/cache.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { styleMap } from 'lit/directives/style-map.js';
import isEqual from 'lodash-es/isEqual.js';
import { FormValidationMixin } from '../../../mixins/form-participation/form-validation.mixin.js';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin.js';
import '../../../mixins/form-participation/form-participation.types.js';
import { BaseElement } from '../../base/base-element.class.js';
import { DelegateFocusMixin } from '../../../mixins/visual-focus/delegate-focus.mixin.js';
import { commaListConverter } from '../../../utils/component.utils.js';
import { generateUid, getContentsFromPortal } from '../../../utils/portal.utils.js';
import { GetNotificationEventClass, MimickedChangeEvent, MimickedInputEvent, } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { ValidationStyleMixin } from '../../../mixins/validation-style/validation-style.mixin.js';
import '../../../contracts/validation-style.contract.js';
// deps
import { SelectDivider } from '../select-divider/select-divider.component.js';
import { SelectOption } from '../select-option/select-option.component.js';
import '../select-button/select-button.component.js';
import '../select-all/select-all.component.js';
import '../select-menu/select-menu.component.js';
import '../select-placeholder/select-placeholder.component.js';
import '../../../directives/overlay/overlay.directive.js';
import '../../../directives/validation-message/validation-message.directive.js';
import { hostStyles } from '../../../host.styles.js';
const style = ":host{--zui-select-animation-duration: 0.1s;--zui-select-width: calc(var(--zui-gu) * 15);--zui-menu-item-count: 9.5;position:relative;display:inline-block;width:var(--zui-select-width);text-align:left}.trap{display:none}"
import { ifDefined } from 'lit/directives/if-defined.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const SELECT_PORTAL = 'select';
const SELECT_MENU_DEFAULT_PLACEMENTS = ['bottom-start', 'bottom-end', 'top-start', 'top-end'];
const SELECT_STYLES = css `
  ${unsafeCSS(style)}
`;
class SelectOpenedEvent extends GetNotificationEventClass('select-opened') {
}
class SelectClosedEvent extends GetNotificationEventClass('select-closed') {
}
/* eslint-disable jsdoc/require-property-description */
/**
 * The select feature component is a form element which allows picking one or multiple options.
 * All options and the structure are described using structural elements such as `zui-select-option` or
 * `zui-select-divider` which are declared in the default slot. Optionally a `zui-placeholder` component can be
 * reflected into the placeholder slot.
 *
 * *Please notice:* Internally, the `value` of the `zui-select` is an array of strings.
 * However, for HTML attributes only plain string is allowed.
 * If you are using the select with `multiple=false` you can pass the value of the (pre-)selected option directly.
 * When `multiple=true` you have to pass a comma-separated string as attribute, i.e. `<zui-select value="opt1,opt2">`.
 *
 * On the other hand, if you set the JS property of the component, you always have to pass an array of strings (even
 * with `multiple=false`):
 * ```js
 * const zuiSelect = document.querySelector("zui-select");
 * zuiSelect.value = ["opt1", "opt2"];
 *
 * // multiple = false
 * zuiSelect.value = ["opt2"];
 * ```
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/❖-01-Desktop---Component-Library---4.1?node-id=13009%3A2719)
 * - [Styleguide - Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/📖--Styleguide---Desktop?node-id=1%3A102386 )
 *
 * @example
 * ### Basic usage
 * ```HTML
 * <zui-select placeholder="Select an option">
 *   <zui-select-option value="foo">Foo</zui-select-option>
 *   <zui-select-option value="bar">Bar</zui-select-option>
 *   <zui-select-divider>Not available</zui-select-divider>
 *   <zui-select-option value="baz" disabled>Baz</zui-select-option>
 * </zui-select>
 * ```
 *
 * ### Using icons
 * ```HTML
 * <zui-select placeholder="Select an option">
 *   <zui-select-option value="foo">
 *     <zui-icon-common-holy-placeholder slot="icon"></zui-icon-common-holy-placeholder>
 *     Foo
 *   </zui-select-option>
 *   <zui-select-option value="bar">Bar</zui-select-option>
 *   <zui-select-divider>Not available</zui-select-divider>
 *   <zui-select-option value="baz" disabled>
 *     <zui-icon-common-holy-placeholder slot="icon"></zui-icon-common-holy-placeholder>
 *     Baz
 *   </zui-select-option>
 * </zui-select>
 * ```
 *
 * ### Multiple select
 * ```HTML
 * <zui-select multiple>
 *   <zui-select-option value="foo">Foo</zui-select-option>
 *   <zui-select-option value="bar">Bar</zui-select-option>
 *   <zui-select-divider>Not available</zui-select-divider>
 *   <zui-select-option value="baz" disabled>Baz</zui-select-option>
 * </zui-select>
 * ```
 *
 * ### Using a custom placeholder
 * ```HTML
 * <zui-select>
 *   <zui-select-placeholder slot="placeholder">Select an option</zui-select-placeholder>
 *   <zui-select-option value="foo" disabled>Foo</zui-select-option>
 *   <zui-select-option value="bar">Bar</zui-select-option>
 *   <zui-select-option value="baz">Baz</zui-select-option>
 * </zui-select>
 * ```
 *
 * ### Pre-select single value
 * ```HTML
 * <zui-select value="bar">
 *   <zui-select-option value="foo">Foo</zui-select-option>
 *   <zui-select-option value="bar">Bar</zui-select-option>
 *   <zui-select-option value="baz">Baz</zui-select-option>
 * </zui-select>
 * ```
 *
 * ### Pre-select multiple values
 * ```HTML
 * <zui-select value="foo,bar">
 *   <zui-select-option value="foo">Foo</zui-select-option>
 *   <zui-select-option value="bar">Bar</zui-select-option>
 *   <zui-select-option value="baz">Baz</zui-select-option>
 * </zui-select>
 * ```
 *
 *
 * @fires change - simulates the default `change` event to imitate default behavior
 * @fires input - simulates the default `input` event to imitate default behavior
 * @fires {GetZuiEvent<Select, 'OpenedEvent'>} zui-select-opened - custom `open` event to notify about the dropdown menu being visible
 * @fires {GetZuiEvent<Select, 'ClosedEvent'>} zui-select-closed - custom `close` event to notify about the dropdown menu being hidden
 *
 * @slot - default slot for declaring the select structure
 * @slot placeholder - allows passing-in a customized placeholder element
 *
 * @cssprop --zui-select-animation-duration - duration of the menu toggle, is passed to the portal
 * @cssprop --zui-select-width - horizontal size of the select menu, is passed to the portal
 * @cssprop --zui-menu-item-count - override the default item count above which the menu will be scrollable.
 * @cssprop --zui-select-menu-width - to customize the menu to have a fixed width (**must be an absolute value!**)
 *
 * @property  { "line-only" | "static-text" | "static-tooltip" | "cursor-anchored-tooltip" | "component-anchored-tooltip" } [validationMessageStyle=static-text]
 */
let Select = Select_1 = 
/* eslint-enable jsdoc/require-property-description */
class Select extends ValidationStyleMixin(FormValidationMixin(FormDataHandlingMixin(DelegateFocusMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.InputEvent = MimickedInputEvent;
        this.ChangeEvent = MimickedChangeEvent;
        this.ClosedEvent = SelectClosedEvent;
        this.OpenedEvent = SelectOpenedEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
})))), {
    supportedValidationMessageStyles: [
        'static-text',
        'line-only',
        'static-tooltip',
        'cursor-anchored-tooltip',
        'component-anchored-tooltip',
    ],
}) {
    constructor() {
        super();
        /* eslint-enable @typescript-eslint/naming-convention */
        /**
         * ARIA haspopup for this element; defaults to 'listbox' if not explicitly set by author
         */
        this.ariaHaspopup = 'listbox';
        /**
         * ARIA expanded for this element; defaults to 'false'
         */
        this.ariaExpanded = 'false';
        /**
         * ARIA role for this element; defaults to 'listbox' if not explicitly set by author
         */
        this.role = 'listbox';
        /**
         * provides an emphasis of the select
         */
        this.emphasis = 'selected';
        /**
         * whether to hide the border or not
         */
        this.hideBorder = false;
        /**
         * a text based placeholder; may be overwritten by a passed-in `zui-placeholder`
         */
        this.placeholder = '';
        /**
         * allows to set an explicit target portal for the select menu
         */
        this.portal = `${SELECT_PORTAL}-${generateUid()}`;
        /**
         * the size is derived from the touch environment initially if not provided
         */
        this.size = this.hasTouch ? 'l' : 's';
        /**
         * toggles the alternative layout
         */
        this.alternative = false;
        /**
         * opens the dropdown menu; will also reflect its current visibility state
         */
        this.expanded = false;
        /**
         * allows selecting multiple values using checkboxes
         */
        this.multiple = false;
        /**
         * adds a helper item which allows toggling all menu items at once
         */
        this.showAllItem = false;
        /**
         * custom label for the helper item if `showAllItem` is set
         */
        this.allItemLabel = 'All';
        /**
         * disables truncation and scales the select menu to its items
         */
        this.adaptMenuWidth = false;
        /**
         * defines the overflow strategy of the select menu items
         */
        this.menuOverflow = 'truncate';
        /**
         * Allowed placements of the select menu.
         * Multiple values can be provided as comma separated list.
         * The first setting will be applied initially, which defaults to `bottom-start`.
         *
         * @example `menu-placements="bottom-start,top-end"`
         * @see https://popper.js.org/docs/v2/constructors/#options
         */
        this.menuPlacements = SELECT_MENU_DEFAULT_PLACEMENTS;
        /**
         * required
         */
        this.required = false;
        /**
         * value
         */
        this.value = [];
        // reflects the state of the menu being completely open or currently in
        // an opening transition and not in a closed or closing condition
        this._opened = this.expanded;
        this._buttonDisplayValue = '';
        this._previouslyDisabledSelectedValues = [];
        this._messageAnchorReferenceCallback = () => this;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.getPositionReference = () => this._buttonRef;
        this.addValidator({
            validator: () => { var _a; return this.required === false || ((_a = this.value) === null || _a === void 0 ? void 0 : _a.length) > 0; },
            type: 'valueMissing',
            validatesOnProperties: ['required'],
        });
        this.setDefaultValidityMessages({ valueMissing: Select_1.REQUIRED_ERROR_MESSAGE });
    }
    get _unselectedSelectableOptions() {
        return this._enabledOptionRefs.filter(({ value }) => !this.value.includes(value));
    }
    _handleBlur(event) {
        // check blur outside
        if (this._targetIsOutsideComponentOrPortal(event.relatedTarget)) {
            // close the menu
            this._toggleMenu(false);
        }
    }
    _handleButtonClick() {
        // check if we're allowed to do this... 🤭
        if (this.disabled || this.readonly) {
            return;
        }
        // toggle the overlay menu
        this._toggleMenu();
    }
    _handleItemClick({ currentTarget }) {
        // check if we're allowed to do this... 🤭
        if (this.disabled || currentTarget.disabled) {
            return;
        }
        // handle selection
        if (!this.multiple) {
            this._selectSingle(currentTarget.value);
        }
        else {
            this._selectMultiple(currentTarget.value);
        }
        this._updateAllItemState();
    }
    _handleAllClick() {
        // do not open menu if completely disabled
        if (this.disabled === true) {
            return;
        }
        // toggle all, if there is sth. more to select
        this._selectAll(this._unselectedSelectableOptions.length > 0);
    }
    get _selectCount() {
        return this.value === undefined ? 0 : this.value.length;
    }
    get _hasSelection() {
        const selectedOptions = this._selectOptionRefs
            .map((option) => option.value)
            .filter((optionValue) => this.value.includes(optionValue));
        //this returns false if no selection is available or the current selection is invalid
        return this._selectCount > 0 && selectedOptions.length === this.value.length;
    }
    connectedCallback() {
        super.connectedCallback();
        // register local event listeners
        this.addEventListener('blur', this._preventBlurOnPortal, { capture: true });
        this.addEventListener('blur', this._handleBlur);
    }
    disconnectedCallback() {
        // remove local event listeners
        this.removeEventListener('blur', this._preventBlurOnPortal, { capture: true });
        this.removeEventListener('blur', this._handleBlur);
        // I tell you we must die...
        super.disconnectedCallback();
    }
    _preventBlurOnPortal(event) {
        // Stop blur event when focus is delegated to the portal.
        // We just want "real" blur events (e.g. to show the error message)
        if (!this._targetIsOutsideComponentOrPortal(event.relatedTarget)) {
            event.stopPropagation();
        }
    }
    _reflectValueToOptions() {
        this._selectOptionRefs.forEach((option) => {
            option.selected = this._hasSelection && this.value.includes(option.value);
        });
        this.requestUpdate();
    }
    /**
     *
     * @param relatedTarget the next target that will get focus on blur.
     * @returns true if the focused element on blur is neither the select nor in its portal.
     */
    _targetIsOutsideComponentOrPortal(relatedTarget) {
        var _a, _b;
        const target = relatedTarget;
        const isSelect = this.isSameNode(target);
        const isInSelect = this.contains(target);
        const isPortal = (target === null || target === void 0 ? void 0 : target.tagName.toLowerCase()) === 'zui-portal';
        const isMenu = (_a = this._selectMenuRef) === null || _a === void 0 ? void 0 : _a.isSameNode(target);
        const isInMenu = (_b = this._selectMenuRef) === null || _b === void 0 ? void 0 : _b.contains(target);
        return !isSelect && !isInSelect && !isPortal && !isMenu && !isInMenu;
    }
    _selectAll(selected) {
        if (selected) {
            // add missing ones, that can be selected but store previously disabled selected
            this._previouslyDisabledSelectedValues = this._disabledOptionRefs
                .filter(({ value }) => this.value.includes(value))
                .map(({ value }) => value);
            this.value = [...this.value, ...this._unselectedSelectableOptions.map(({ value }) => value)];
        }
        else {
            // reset values
            this.value = this._previouslyDisabledSelectedValues;
        }
        // notify about changes
        this.emitInputEvent();
        this.emitChangeEvent();
        // retrieve all item state
        this._updateAllItemState();
    }
    _toggleMenu(shouldOpen = !this.expanded) {
        // nothing changed so far
        if (this._opened === shouldOpen) {
            return;
        }
        // start the opening procedure
        if (shouldOpen) {
            // set all flags to open up
            this.expanded = true;
            this._opened = true;
        }
        else {
            // set opened to false and wait for close event to remove it
            this._opened = false;
        }
    }
    _updateRefs() {
        requestAnimationFrame(() => {
            var _a;
            // retrieve references to projected contents
            const [selectMenuRef] = getContentsFromPortal(this.portal, 'zui-select-menu');
            const [menuRef] = getContentsFromPortal(this.portal, 'zui-menu');
            this._selectMenuRef = selectMenuRef;
            // update the "all" items state
            this._updateAllItemState();
            // as the menu is rendered (and will be visible soon), position again
            (_a = this._overlayRef) === null || _a === void 0 ? void 0 : _a.forcePositioning();
            // focus the menu
            menuRef === null || menuRef === void 0 ? void 0 : menuRef.focus();
        });
    }
    _handleSelectMenuOpened() {
        // (re-)dispatch opened event
        this.emitSelectOpenedEvent();
    }
    _handleSelectMenuClosed() {
        var _a;
        // (re-)dispatch closed event
        this.emitSelectClosedEvent();
        // will remove the select menu
        this.expanded = false;
        // focus the menubutton again
        (_a = this._buttonRef) === null || _a === void 0 ? void 0 : _a.focus();
    }
    // in order to allow styling, we're passing the custom properties to the projection wrapper
    _injectCustomProperties() {
        const styles = getComputedStyle(this);
        const properties = ['--zui-select-animation-duration', '--zui-select-width'];
        return properties.reduce((props, property) => (Object.assign(Object.assign({}, props), { [property]: styles.getPropertyValue(property) })), {});
    }
    // TODO: this should be refactored into a derived getter
    _updateAllItemState() {
        // nothing selected
        if (this.value.length === 0) {
            this._allItemState = false;
            // some are selected, but there is more
        }
        else if (this._unselectedSelectableOptions.length > 0) {
            this._allItemState = 'mixed';
        }
        else {
            this._allItemState = true;
        }
    }
    _selectSingle(value) {
        const oldValue = this.value;
        // store the current option only
        this.value = value !== '' ? [value] : [];
        this.emitInputEvent();
        // notify about changes
        if (!isEqual(this.value, oldValue)) {
            this.emitChangeEvent();
        }
        // close the menu
        this._toggleMenu(false);
    }
    _selectMultiple(value) {
        // toggle the selection on the stack
        if (this._hasSelection && this.value.includes(value)) {
            const index = this.value.indexOf(value);
            this.value = [...this.value.slice(0, index), ...this.value.slice(index + 1)];
        }
        else if (Array.isArray(this.value)) {
            this.value = [...this.value, value];
        }
        else {
            this.value = [value];
        }
        this.emitInputEvent();
        // notify about changes
        this.emitChangeEvent();
    }
    _handleKeysForSelectButton({ code }) {
        if (this.disabled || this.readonly) {
            return;
        }
        switch (code) {
            case 'Enter':
            case 'NumpadEnter':
            case 'Space':
            case 'ArrowUp':
            case 'ArrowDown':
                this._toggleMenu(true);
                break;
        }
    }
    _handleKeysForMenuItems({ code }) {
        switch (code) {
            case 'Escape':
                this._toggleMenu(false);
                break;
        }
    }
    get _menuItemCount() {
        const hostStyle = getComputedStyle(this);
        return hostStyle.getPropertyValue('--zui-menu-item-count');
    }
    _handlePlaceholderChange() {
        this._updateDisplayValue();
    }
    _handleSlotChange() {
        this._reflectValueToOptions();
        this._updateDisplayValue();
    }
    _getFormattedDisplayValueOrPlaceholder() {
        // if nothing is selected and the component is in read-only state, we display an en dash
        if (this.readonly && !this._hasSelection) {
            return '–';
        }
        const placeholderRef = this._placeholderRefs[0];
        // either format if we have a selection or simply return the text from the placeholder
        if (this._hasSelection) {
            return placeholderRef.format(this._selectOptionRefs, this._selectOptionRefs.filter(({ value }) => this.value.includes(value)), this.multiple);
        }
        return placeholderRef.innerHTML;
    }
    _updateDisplayValue() {
        this._buttonDisplayValue = this._getFormattedDisplayValueOrPlaceholder();
    }
    getMenuWidth() {
        const definedWidth = getComputedStyle(this).getPropertyValue('--zui-select-menu-width');
        return definedWidth !== '' ? definedWidth : `${this.offsetWidth}px`;
    }
    willUpdate(changedProperties) {
        super.willUpdate(changedProperties);
        // sync ARIA attributes
        this.ariaExpanded = String(this.expanded);
    }
    /**
     * @param {PropertyValues} changedProperties properties which have been changed
     */
    updated(changedProperties) {
        super.updated(changedProperties);
        // we only want to add logic if the value has changed
        if (changedProperties.has('value')) {
            // reflect select state to options
            this._reflectValueToOptions();
            this._updateDisplayValue();
        }
        // check if initially opened
        if (changedProperties.has('expanded') && this.expanded) {
            requestAnimationFrame(() => this._toggleMenu(true));
        }
        // we need the template in the DOM to update the placeholder...
        if (changedProperties.has('placeholder') || changedProperties.has('readonly')) {
            this._updateDisplayValue();
        }
    }
    render() {
        var _a;
        return html `
      <!-- trap projected contents -->
      <slot class="trap" name="placeholder" @slotchange="${this._handlePlaceholderChange}">
        <!-- FIXME: the placeholder must be put into the zui-select-buttons innerHTML and its formatting
         options must be attributes on the select itself -->
        <zui-select-placeholder>${this.placeholder}</zui-select-placeholder>
      </slot>
      <!-- FIXME: Traped slots will not be needed but be passed through to menu-wrapper -->
      <slot @slotchange="${this._handleSlotChange}" class="trap"></slot>

      <!-- build a synthetic structure from ui components -->
      <zui-select-button
        aria-describedby="menu"
        aria-expanded="${this.expanded ? 'true' : 'false'}"
        aria-haspopup="${((_a = this._selectOptionRefs) === null || _a === void 0 ? void 0 : _a.length) ? 'menu' : 'false'}"
        ?alternative="${this.alternative}"
        ?disabled="${this.disabled}"
        ?hide-border="${this.hideBorder}"
        ?show-validation="${this.showValidation}"
        ?opened="${this.expanded}"
        ?readonly="${this.readonly}"
        ?selected="${this._hasSelection}"
        size="${this.size}"
        tabindex="${ifDefined(this.disabled ? undefined : 0)}"
        @click="${this._handleButtonClick}"
        @keydown="${this._handleKeysForSelectButton}"
      >
        ${unsafeHTML(this._buttonDisplayValue)}
      </zui-select-button>

      ${this.expanded
            ? cache(html `
            <!-- FIXME: flip, level and portal can probably be part of the zui-menu wrapper -->
            <zui-overlay-directive
              flip
              portal="${this.portal}"
              .placements="${this.menuPlacements}"
              .positionReferenceCallback="${this.getPositionReference}"
            >
              <!-- FIXME: _injectCustomProperties  can be replaced by custom variable -->
              <!-- FIXME: _updateRefs  migrate focus handling and potential positioning to menu-wrapper -->
              <zui-select-menu
                ?open="${this._opened}"
                style="${styleMap(this._injectCustomProperties())}"
                @zui-select-menu-open="${this._updateRefs}"
                @zui-select-menu-opened="${this._handleSelectMenuOpened}"
                @zui-select-menu-closed="${this._handleSelectMenuClosed}"
              >
                <!-- FIXME: tabindex  will only be rendered/visible if expanded, probably tabindex 0 should be hardcoded -->
                <!-- FIXME: adapt-width  should be default behaviour if no explicit width is set -->
                <!-- FIXME: styles should be common styles of the menu or be set by custom properties -->
                <!-- FIXME: _handleBlur can be done completly in the wrapper because it only concerns logic about dismissing the menu -->
                <!-- FIXME: _handleKeysForMenuItems could maybe be part of the wrapper as it describes default behaviour for now -->
                <zui-menu
                  id="menu"
                  size="${this.size}"
                  overflow="${this.menuOverflow}"
                  tabindex="${this.expanded ? 0 : -1}"
                  ?adapt-width="${this.adaptMenuWidth}"
                  ?disabled="${this.disabled}"
                  style="${styleMap({
                '--zui-menu-margin-top': this.hideBorder ? '4px' : '0',
                '--zui-menu-width': this.getMenuWidth(),
                '--zui-menu-item-count': this._menuItemCount,
            })}"
                  @blur="${this._handleBlur}"
                  @keydown="${this._handleKeysForMenuItems}"
                >
                  <!-- FIXME: _handleBlur is unnecessary on all children beneath zui-menu. They do nothing. -->
                  <!-- FIXME: Maybe instead of binding blur everywhere commit event from wrapper and handle target?? -->
                  ${this.multiple && this.showAllItem
                ? html `
                        <zui-select-all
                          .value="${this._allItemState}"
                          size="${this.size}"
                          @blur="${this._handleBlur}"
                          @click="${this._handleAllClick}"
                        >
                          ${this.allItemLabel}
                        </zui-select-all>
                      `
                : nothing}
                  <!-- FIXME: This will be the default slot and will be passed through as to the menu wrapper -->
                  <!-- FIXME: This will only stick to a common interface and not be any explicit markup -->
                  ${this._optionRefs.map((item) => {
                if (item instanceof SelectDivider) {
                    return html `<zui-menu-divider>${unsafeHTML(item.innerHTML)}</zui-menu-divider>`;
                }
                // FIXME: zui-select-option now shares an interface with the zui-menu-item and can be used as such
                if (item instanceof SelectOption) {
                    return html `
                        <zui-menu-item
                          emphasis="${!this.multiple && item.selected ? this.emphasis : 'default'}"
                          aria-selected="${item.selected}"
                          ?selectable="${this.multiple}"
                          ?selected="${item.selected}"
                          ?disabled="${this.disabled || item.disabled}"
                          value="${item.value}"
                          @blur="${this._handleBlur}"
                          @click="${this._handleItemClick}"
                        >
                          ${unsafeHTML(item.innerHTML)}
                        </zui-menu-item>
                      `;
                }
                return nothing;
            })}
                </zui-menu>
              </zui-select-menu>
            </zui-overlay-directive>
          `)
            : nothing}
      <zui-validation-message-directive
        validation-message=${this.validationMessage}
        .messageAnchorReferenceCallback=${this._messageAnchorReferenceCallback}
        .triggerHostReference=${this}
      ></zui-validation-message-directive>
    `;
    }
};
Select.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    SELECT_STYLES,
];
/* eslint-disable @typescript-eslint/naming-convention */
Select.REQUIRED_ERROR_MESSAGE = 'Selection is required.';
__decorate([
    property({ reflect: true, attribute: 'aria-haspopup' })
], Select.prototype, "ariaHaspopup", void 0);
__decorate([
    property({ reflect: true, attribute: 'aria-expanded', type: String })
], Select.prototype, "ariaExpanded", void 0);
__decorate([
    property({ reflect: true })
], Select.prototype, "role", void 0);
__decorate([
    property({ reflect: true, type: String })
], Select.prototype, "emphasis", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'hide-border' })
], Select.prototype, "hideBorder", void 0);
__decorate([
    property({ reflect: true, type: String })
], Select.prototype, "placeholder", void 0);
__decorate([
    property({ reflect: true, type: String })
], Select.prototype, "portal", void 0);
__decorate([
    property({ reflect: true, type: String })
], Select.prototype, "size", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], Select.prototype, "alternative", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], Select.prototype, "expanded", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], Select.prototype, "multiple", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'show-all-item' })
], Select.prototype, "showAllItem", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'all-item-label' })
], Select.prototype, "allItemLabel", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'adapt-menu-width' })
], Select.prototype, "adaptMenuWidth", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'menu-overflow' })
], Select.prototype, "menuOverflow", void 0);
__decorate([
    property({ reflect: true, converter: commaListConverter(), attribute: 'menu-placements' })
], Select.prototype, "menuPlacements", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], Select.prototype, "required", void 0);
__decorate([
    property({
        reflect: true,
        type: String,
        converter: commaListConverter(),
        hasChanged(value, oldValue) {
            return !isEqual(value, oldValue);
        },
    })
], Select.prototype, "value", void 0);
__decorate([
    query('zui-select-button')
], Select.prototype, "_buttonRef", void 0);
__decorate([
    queryAssignedElements({ selector: 'zui-select-divider,zui-select-option', flatten: true })
], Select.prototype, "_optionRefs", void 0);
__decorate([
    queryAssignedElements({ selector: 'zui-select-option', flatten: true })
], Select.prototype, "_selectOptionRefs", void 0);
__decorate([
    queryAssignedElements({ selector: 'zui-select-option:not([disabled])', flatten: true })
], Select.prototype, "_enabledOptionRefs", void 0);
__decorate([
    queryAssignedElements({ selector: 'zui-select-option[disabled]', flatten: true })
], Select.prototype, "_disabledOptionRefs", void 0);
__decorate([
    queryAssignedElements({ slot: 'placeholder', selector: 'zui-select-placeholder', flatten: true })
], Select.prototype, "_placeholderRefs", void 0);
__decorate([
    query('zui-overlay-directive')
], Select.prototype, "_overlayRef", void 0);
__decorate([
    state()
], Select.prototype, "_opened", void 0);
__decorate([
    state()
], Select.prototype, "_allItemState", void 0);
__decorate([
    state()
], Select.prototype, "_buttonDisplayValue", void 0);
__decorate([
    eventOptions({ passive: true })
], Select.prototype, "_handleBlur", null);
__decorate([
    eventOptions({ passive: true })
], Select.prototype, "_handleButtonClick", null);
__decorate([
    eventOptions({ passive: true })
], Select.prototype, "_handleItemClick", null);
__decorate([
    eventOptions({ passive: true })
], Select.prototype, "_handleAllClick", null);
Select = Select_1 = __decorate([
    customElement('zui-select')
    /* eslint-enable jsdoc/require-property-description */
], Select);
export { Select };
