export function getCsrfToken(csrfCookieName: string): string {
  return (
    document.cookie
      .split(';')
      .filter((cookie) => cookie.trim().startsWith(csrfCookieName))
      .map((cookie) => cookie.split('=')[1])[0] || ''
  );
}

export function targetsSameOrigin(target: RequestInfo | URL): boolean {
  if (target instanceof URL) {
    return target.origin === window.location.origin;
  }

  if (typeof target === 'string') {
    return target.startsWith('/') || target.startsWith(window.location.origin);
  }

  if (target instanceof Object && 'url' in target) {
    return targetsSameOrigin(target.url);
  }

  return false;
}
