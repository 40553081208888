var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import { BaseElement, RealBaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{position:relative;width:calc(var(--zui-gu) * 8);height:calc(var(--zui-gu) * 8)}slot[name=stateDot]::slotted(zui-state-dot){position:absolute;pointer-events:none}:host([size=m]) slot[name=stateDot]::slotted(zui-state-dot){top:calc(var(--zui-gu) * 2.5);right:calc(var(--zui-gu) * 2.25)}:host([size=l]) slot[name=stateDot]::slotted(zui-state-dot){top:calc(var(--zui-gu) * 2);right:calc(var(--zui-gu) * 1.75)}:host(:not([has-notifications])) slot[name=stateDot]::slotted(zui-state-dot){display:none}"
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import '../headerbar-icon-button/headerbar-icon-button.component.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const headerbarNotificationButtonStyles = css `
  ${unsafeCSS(styles)}
`;
/**
 * HeaderbarNotificationButton component displays a slotted icon and a slotted state dot. The state dot is shown when the `has-notifications` attribute is set.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-headerbar-notification-button emphasis="default" has-notifications size="m">
 *   <zui-icon-common-holy-placeholder slot="icon"></zui-icon-common-holy-placeholder>
 *   <zui-state-dot slot="stateDot"></zui-state-dot>
 * </zui-headerbar-notification-button>
 * ```
 *
 * @slot icon - Slot for an icon
 * @slot stateDot - Slot for a state dot
 */
let HeaderbarNotificationButton = class HeaderbarNotificationButton extends DisabledMixin(RealBaseElement) {
    constructor() {
        super(...arguments);
        /**
         * Emphasis of the HeaderbarNotificationButton
         */
        this.emphasis = 'default';
        /**
         * Whether to show the state dot or not
         */
        this.hasNotifications = false;
        /**
         * Size of the HeaderbarNotificationButton
         */
        this.size = 'm';
    }
    _handleStateDotSlotchange() {
        this._assignedStateDots.forEach((stateDot) => (stateDot.size = 's8'));
    }
    render() {
        return html `
      <zui-headerbar-icon-button emphasis="${this.emphasis}" size="${this.size}">
        <slot name="icon" slot="icon"></slot>
      </zui-headerbar-icon-button>
      <slot name="stateDot" @slotchange="${this._handleStateDotSlotchange}"></slot>
    `;
    }
};
HeaderbarNotificationButton.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    headerbarNotificationButtonStyles,
];
__decorate([
    property({ reflect: true, type: String })
], HeaderbarNotificationButton.prototype, "emphasis", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'has-notifications' })
], HeaderbarNotificationButton.prototype, "hasNotifications", void 0);
__decorate([
    property({ reflect: true, type: String })
], HeaderbarNotificationButton.prototype, "size", void 0);
__decorate([
    queryAssignedElements({ slot: 'stateDot', selector: 'zui-state-dot', flatten: true })
], HeaderbarNotificationButton.prototype, "_assignedStateDots", void 0);
HeaderbarNotificationButton = __decorate([
    customElement('zui-headerbar-notification-button')
], HeaderbarNotificationButton);
export { HeaderbarNotificationButton };
