export var Breakpoint;
(function (Breakpoint) {
    // <= 767px
    Breakpoint["XS"] = "XS";
    // 768px - 1023px
    Breakpoint["S"] = "S";
    // 1024px - 1279px
    Breakpoint["M"] = "M";
    // >= 1280
    Breakpoint["L"] = "L";
})(Breakpoint || (Breakpoint = {}));
