import { ZuiListItem, ZuiStateDot, ZuiTextLink, ZuiTruncateWithTooltip } from '@zeiss/zui-react';
import styled from 'styled-components';

export const NotificationItem = styled(ZuiListItem)<{ canChange?: boolean }>`
  padding: var(--arn-space-3) var(--arn-space-8);
  border-bottom: 1px solid var(--zui-color-item-separator-line-first-hierarchy-default-enabled);
  --zui-line-strength-thin: 0;
  ${(props) => !props.canChange && 'background: #f8f8f8; opacity: 0.5;'}

  position: relative;
`;

export const NotificationItemContent = styled.div`
  display: flex;
  align-items: flex-start;
  padding-left: 0;
  gap: 16px;
`;

export const NotificationItemText = styled.div`
  width: 100%;
`;

export const NotificationItemOrigin = styled(ZuiTruncateWithTooltip)`
  margin: 0 0 2px;
  color: var(--zui-color-gs-85);
  font: var(--zui-typography-subtitle2);
  font-size: 10px;
  line-height: 12px;
  text-transform: var(--zui-typography-label2-text-transform);
`;

export const NotificationItemTitle = styled(ZuiTruncateWithTooltip)`
  margin: 0;
  color: var(--zui-color-gs-100);
  font: var(--zui-typography-subtitle1);
  font-size: 14px;
  line-height: 20px;
  max-width: var(--arn-space-100);
  text-transform: var(--zui-typography-subtitle1-text-transform);
`;

export const NotificationItemDescription = styled(ZuiTruncateWithTooltip)`
  margin: 0;
  color: var(--zui-color-gs-85);
  font: var(--zui-typography-body);
  font-size: 14px;
  line-height: 20px;
  max-width: var(--arn-space-84);
  text-transform: var(--zui-typography-body-text-transform);
`;

export const NotificationItemFooter = styled.div`
  display: flex;
`;

export const NotificationItemAction = styled(ZuiTextLink)`
  color: var(--zui-color-gs-100);
  font-size: 12px;
  line-height: 16px;
`;

export const NotificationItemUnreadDot = styled(ZuiStateDot)`
  position: absolute;
  left: 16px;
  top: 20px;
`;
