var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
import '../content-tab-base.class.js';
import { isDefined } from '../../../utils/component.utils.js';
import '../content-tab-default-item/content-tab-default-item.component.js';
import '../content-tab-highlight-item/content-tab-highlight-item.component.js';
import '../content-tab-image-item/content-tab-image-item.component.js';
import { GetNotificationEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const style = ":host{display:inline-flex;justify-content:center;max-width:100%}"
const theme = ""
const themeDark = ""
const themeLight = ""
const contentTabBarStyles = css `
  ${unsafeCSS(style)}
`;
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const wasClickOnCloseButton = (event) => event
    .composedPath()
    .some((element) => element.tagName && element.tagName.toLowerCase() === 'zui-icon-symbols-close');
class ContentTabBarItemClosedEvent extends GetNotificationEventClass('content-tab-bar-item-closed') {
}
class ContentTabBarItemSelectedEvent extends GetNotificationEventClass('content-tab-bar-item-selected') {
}
/**
 * The content tab bar is a feature component
 * The `zui-content-tab-bar` is intended to be used with `zui-content-tab-default-item`'s, `zui-content-tab-highlight-item`'s and `zui-content-tab-image-item`'s.
 * Each item should get a value for identification. When an item is selected it's value is reflected on the `zui-content-tab-bar` and an event is emitted.
 *
 * The content tab bar may only support using items of the same type!
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=52930%3A120)
 * - [Styleguide – Desktop](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---3.1?node-id=76949%3A367659)
 *
 * @example
 * HTML:
 *
 * Example with default items
 * ```html
 * <zui-content-tab-bar>
 *   <zui-content-tab-default-item text="Text 1" size="m" value="itemOne"></zui-content-tab-default-item>
 *   <zui-content-tab-default-item text="Text 2" size="m" value="itemTwo" disabled></zui-content-tab-default-item>
 *   <zui-content-tab-default-item text="Text 3" size="m" value="itemThree"><zui-icon-hardware-server-server-list slot="icon"></zui-icon-hardware-server-server-list></zui-content-tab-default-item>
 *   <zui-content-tab-default-item text="Text 4" size="m" value="itemFour" closable></zui-content-tab-default-item>
 * </zui-content-tab-bar>
 * ```
 *
 * Example with highlight items
 * ```html
 * <zui-content-tab-bar>
 *   <zui-content-tab-highlight-item text="Text 1" value="itemOne"></zui-content-tab-default-item>
 *   <zui-content-tab-highlight-item text="Text 2" value="itemTwo" disabled></zui-content-tab-default-item>
 *   <zui-content-tab-highlight-item text="Text 3" value="itemThree"><zui-icon-hardware-server-server-list slot="icon"></zui-icon-hardware-server-server-list></zui-content-tab-default-item>
 *   <zui-content-tab-highlight-item text="Text 4" value="itemFour" closable></zui-content-tab-default-item>
 * </zui-content-tab-bar>
 * ```
 *
 * Example with image items
 * ```html
 * <zui-content-tab-bar>
 *   <zui-content-tab-image-item text="Text 1" value="itemOne"></zui-content-tab-default-item>
 *   <zui-content-tab-image-item text="Text 2" value="itemTwo" disabled></zui-content-tab-default-item>
 *   <zui-content-tab-image-item text="Text 3" value="itemThree"><zui-icon-hardware-server-server-list slot="icon"></zui-icon-hardware-server-server-list></zui-content-tab-default-item>
 *   <zui-content-tab-image-item text="Text 4" value="itemFour" closable></zui-content-tab-default-item>
 * </zui-content-tab-bar>
 * ```
 * @fires {GetZuiEvent<ContentTabBar, 'ItemSelectedEvent'>} zui-content-tab-bar-item-selected - fired when the tab selection was changed. Contains the value of the tab as payload.
 * @fires {GetZuiEvent<ContentTabBar, 'ItemClosedEvent'>} zui-content-tab-bar-item-closed - fired the close icon of a tab was clicked. Contains the value of the tab as payload.
 */
let ContentTabBar = class ContentTabBar extends DisabledMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.ItemClosedEvent = ContentTabBarItemClosedEvent;
        this.ItemSelectedEvent = ContentTabBarItemSelectedEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
})) {
    constructor() {
        super(...arguments);
        /**
         * the size of all items inside
         */
        this.size = 's';
        /**
         * hierarchy of all items inside
         */
        this.hierarchy = 'first';
        this._propagateDisabled = false;
    }
    _handleSlotchange() {
        this._propagateState();
    }
    _handleClick(clickEvent) {
        clickEvent.stopPropagation();
        clickEvent.stopImmediatePropagation();
        // When the close icon was clicked, we don't want to select the item.
        if (!wasClickOnCloseButton(clickEvent) && this.value !== clickEvent.target.value) {
            this.value = clickEvent.target.value;
            this.emitContentTabBarItemSelectedEvent({ value: this.value });
        }
    }
    _handleClose(closeEvent) {
        closeEvent.stopPropagation();
        closeEvent.stopImmediatePropagation();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.emitContentTabBarItemClosedEvent({ value: closeEvent.target['value'] });
    }
    _propagateState() {
        this._assignedTabs.forEach((tab) => {
            if (this._propagateDisabled) {
                tab.disabled = this.disabled;
            }
            if (isDefined(this.value)) {
                tab.emphasis = this.value === tab.value ? 'selected' : 'default';
            }
            tab.hierarchy = this.hierarchy;
            tab.size = this.size;
        });
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (this.disabled || changedProperties.get('disabled')) {
            this._propagateDisabled = true;
        }
        this._propagateState();
    }
    render() {
        return html `<slot
      @zui-content-tab-item-closed=${this._handleClose}
      @click="${this._handleClick}"
      @slotchange="${this._handleSlotchange}"
    ></slot>`;
    }
};
ContentTabBar.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    contentTabBarStyles,
];
__decorate([
    property({ reflect: true, type: String })
], ContentTabBar.prototype, "size", void 0);
__decorate([
    property({ reflect: true, type: String })
], ContentTabBar.prototype, "hierarchy", void 0);
__decorate([
    property({ reflect: true, type: String })
], ContentTabBar.prototype, "value", void 0);
__decorate([
    queryAssignedElements({ flatten: true })
], ContentTabBar.prototype, "_assignedTabs", void 0);
ContentTabBar = __decorate([
    customElement('zui-content-tab-bar')
], ContentTabBar);
export { ContentTabBar };
