var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, unsafeCSS, html, nothing } from 'lit';
import { customElement, query, property, queryAssignedElements, state } from 'lit/decorators.js';
import { BaseElement, RealBaseElement } from '../../base/base-element.class.js';
import { styleMap } from 'lit/directives/style-map.js';
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { getSlottedContentsFromPortal } from '../../../utils/portal.utils.js';
import { hostStyles } from '../../../host.styles.js';
import '../../menu/menu-wrapper/menu-wrapper.component.js';
import '../action-split-option/action-split-option.component.js';
import '../../button/button.component.js';
import '../../../types.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const style = ":host{display:inline-block;width:calc(var(--zui-action-button-width-default) + var(---zui-action-split-button-toggle-button-width));isolation:isolate;--zui-action-button-width-default: calc(var(--zui-gu) * 12);---zui-action-icon-button-width-default: calc(var(--zui-gu) * 4);---zui-action-split-button-overlap: 2px;---zui-action-split-button-toggle-button-width: calc(var(--zui-gu) * 2);--zui-menu-width: calc(var(--zui-action-button-width-default) + var(---zui-action-split-button-toggle-button-width));--zui-menu-item-count: 9.5}:host([variant=default-width-adapted]){--zui-menu-width: 100%}:host([variant=icon-only]){width:calc(var(---zui-action-icon-button-width-default) + var(---zui-action-split-button-toggle-button-width));--zui-menu-width: 100%}:host([variant=icon-only-width-restricted]){width:calc(var(---zui-action-icon-button-width-default) + var(---zui-action-split-button-toggle-button-width));--zui-menu-width: calc( var(---zui-action-icon-button-width-default) + var(---zui-action-split-button-toggle-button-width) )}:host(:not([open])) slot:not([name])::slotted(zui-action-split-option){display:none}slot[name=action]::slotted(zui-action-split-option){z-index:1}#container{display:flex}zui-button{--zui-button-width: calc( var(---zui-action-split-button-toggle-button-width) + var(---zui-action-split-button-overlap) );margin-left:calc(var(---zui-action-split-button-overlap)*-1)}zui-button::part(button){border-left:none;border-radius:0 var(--zui-border-radius-default) var(--zui-border-radius-default) 0}zui-icon-arrows-arrow-filled-up,zui-icon-arrows-arrow-filled-down{margin-left:var(---zui-action-split-button-overlap)}"
const theme = ""
const themeDark = ""
const themeLight = ""
// icons
import '@zeiss/zui-icons/dist/components/generated/arrows-arrow-filled-down.component.js';
import '@zeiss/zui-icons/dist/components/generated/arrows-arrow-filled-up.component.js';
const actionSplitButtonStyles = css `
  ${unsafeCSS(style)}
`;
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
/**
 * The zui-action-split-button consists of an action button, an menu button, an animated menu and a custom number of menu-item like elements.
 * The action button is the main action and provided by the zui-action-split-option with the attribute "primary".
 * The menu button toggles the opening/closing of the menu. A outside click will also close the menu.
 * The menu-item like elements are provided by the zui-action-split-option by default.
 *
 * All event handlers can be directly assigned to the slotted elements (action button / menu-item like elements).
 * The menu will be closed on selection of a menu-item like element or by clicking outside of the menu.
 * The menu can be opened by clicking on the menu-button.
 *
 * When using the zui-action-split-button keep in mind that the menu-item like elements are not rendered inside the component but in the menu inside a portal when the menu is open.
 * If you want to make changes to the menu-item like elements you might have to do it by holding a DOM reference or you might close the menu first.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=47241%3A370833)
 * - [Styleguide – Desktop](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---5.1?node-id=13558%3A191770)
 *
 * @example
 * HTML:
 *
 * ### Basic usage
 * ```html
 * <zui-action-split-button>
 *   <zui-action-split-option primary>
 *     <zui-icon-common-holy-placeholder slot="icon"></zui-icon-common-holy-placeholder>
 *     Save
 *   <zui-action-split-option>
 *
 *   <zui-action-split-option>
 *     <zui-icon-common-seal slot="icon"></zui-icon-common-seal>
 *     Save as...
 *   </zui-action-split-option>
 *
 *   <zui-action-split-option disabled>
 *     <zui-icon-common-seal slot="icon"></zui-icon-common-seal>
 *     Delete
 *   </zui-action-split-option>
 * </zui-action-split-button>
 *  ```
 *
 * ### Icon-only variant
 * This variant features makes the menu adapt its width to the content by default and provides the proper sizing for a button with only an icon.
 *
 * ```html
 * <zui-action-split-button variant="icon-only">
 *   <zui-action-split-option primary><zui-icon-save slot="icon"></zui-icon-save<zui-action-split-option>
 *
 *   <zui-action-split-option>
 *     <zui-icon-common-seal slot="icon"></zui-icon-common-seal>
 *   </zui-action-split-option>
 *
 *   <zui-action-split-option disabled>
 *     <zui-icon-common-seal slot="icon"></zui-icon-common-seal>
 *   </zui-action-split-option>
 * </zui-action-split-button>
 *  ```
 *
 * ### Alternative behavior
 * You can manually set the menu-overflow behavior and overwrite the variants default concerning the menu width adaption.
 *
 * ```html
 * <zui-action-split-button menu-overflow="scroll" variant="default-width-adapted">
 *   <zui-action-split-option primary><zui-icon-save slot="icon"></zui-icon-save<zui-action-split-option>
 *
 *   <zui-action-split-option>
 *     <zui-icon-common-seal slot="icon"></zui-icon-common-seal>
 *     Save as...
 *   </zui-action-split-option>
 *
 *   <zui-action-split-option disabled>
 *     <zui-icon-common-seal slot="icon"></zui-icon-common-seal>
 *     Delete
 *   </zui-action-split-option>
 * </zui-action-split-button>
 *  ```
 *
 * @slot default - Supply all zui-menu-item like elements that should be rendered in the menu.
 * @slot action - This is the slot for the action button. The action button can be freely adjusted but will be supplied with a standard style as default.
 *
 * @cssprop --zui-action-button-width-default - override the recommended default width of the action button. The menu will also be adapted accordingly.
 * @cssprop --zui-menu-item-count - override the default item count above which the menu will be scrollable.
 * @cssprop --zui-menu-width - override the menu width. Usually you can rely on the default behavior of the variant and the menu-overflow properties.
 */
let ActionSplitButton = class ActionSplitButton extends DisabledMixin(RealBaseElement) {
    constructor() {
        super(...arguments);
        /**
         * Manages if the menu is open or closed.
         * Changing this property will trigger an animation.
         */
        this.open = false;
        /**
         * Defines the strategy for the menu overflow behavior.
         * Default is truncation of overflowing menu-items.
         */
        this.menuOverflow = 'truncate';
        /**
         * Defines the strategy for the menu adapt-width behavior.
         * Default is adapt-width for icon-only variant and fixed width for the default variant. This behavior can be altered by the other variants.
         */
        this.variant = 'default';
        /**
         * Enabling this, will automatically replace the current action button with the latest selected one
         */
        this.replaceOnSelection = false;
        this._menuAnimationIsRunning = false;
        this._noOptionsAtAll = false;
        this._lastSelectedAction = undefined;
        this._getButtonReference = () => {
            return this._containerRef;
        };
        this._handleFocusOut = (event) => {
            const [menuRef] = getSlottedContentsFromPortal(this._menuWrapperRef.portal, 'zui-menu');
            // closes the menu when anything else than the menu or anything it contains has been clicked.
            if (this.open &&
                // when clicked outside, we do not have any related target, therefore we check for a target
                event.target !== null &&
                event.relatedTarget !== menuRef &&
                event.relatedTarget !== this &&
                !(menuRef === null || menuRef === void 0 ? void 0 : menuRef.contains(event.relatedTarget))) {
                this.open = false;
            }
        };
    }
    _handleMenuClick({ target: selectedOption }) {
        // TODO check if menu should close, if itself -and not an option- was clicked
        this._lastSelectedAction = selectedOption;
        // Prevent double toggle from outside click and menu-button click
        if (!this._menuAnimationIsRunning) {
            this.open = false;
        }
    }
    _handleToggleMenu() {
        // Closes automatically when clicking outside of the menu
        if (!this._menuAnimationIsRunning) {
            this.open = !this.open;
        }
    }
    _handleDefaultSlotChange() {
        // mark first option as primary, if there is no primary option and if there is not already an action button
        if (this._primaryOption.length !== 1 && this._actionButton.length === 0) {
            this._optionRefs[0].primary = true;
        }
    }
    _handleActionButtonSlotChangeEvent() {
        this._actionButton.forEach((actionButton) => {
            // Only disable, don't enable the action button if it has been specifically disabled.
            if (this.disabled) {
                actionButton.disabled = true;
            }
        });
        // eventually toggle this state to prevent menu from being opened...
        this._noOptionsAtAll = this._optionRefs.length === 0;
    }
    _handleMenuKeydown({ code }) {
        if (code === 'Escape' && !this._menuAnimationIsRunning) {
            this.open = false;
        }
    }
    get _menuCustomProperties() {
        const hostStyle = getComputedStyle(this);
        return {
            '--zui-menu-item-count': hostStyle.getPropertyValue('--zui-menu-item-count'),
            '--zui-menu-width': hostStyle.getPropertyValue('--zui-menu-width'),
        };
    }
    _handleMenuWrapperOpening() {
        this._menuAnimationIsRunning = true;
    }
    _handleMenuWrapperClosing() {
        this._menuAnimationIsRunning = true;
    }
    _handleMenuWrapperClosed() {
        this._menuAnimationIsRunning = false;
    }
    _handleMenuWrapperOpened() {
        this._menuAnimationIsRunning = false;
        const [menuRef] = getSlottedContentsFromPortal(this._menuWrapperRef.portal, 'zui-menu');
        menuRef === null || menuRef === void 0 ? void 0 : menuRef.focus();
    }
    _propagateState() {
        this._actionButton.forEach((actionButton) => {
            actionButton.disabled = this.disabled;
        });
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('disabled')) {
            this._propagateState();
        }
        // after a render from open true -> false we need to eventually swap buttons
        if (changedProperties.get('open') && this.replaceOnSelection) {
            if (this._lastSelectedAction) {
                const oldAction = this._actionButton[0];
                // sadly must explicitly assign the slot, because of some weird races... :(
                oldAction.slot = '';
                oldAction.primary = false;
                this._lastSelectedAction.primary = true;
                this._lastSelectedAction = undefined;
            }
        }
    }
    /**
     * We need assigned slots when the menu is closed for property propagation but they may not be displayed.
     *
     * @returns The html template. The slot inside the portal needs to be re-created after the menu is closed because of the current way the portal handles nested child slots.
     */
    render() {
        return html `
      <div id="container">
        <slot name="action" @slotchange=${this._handleActionButtonSlotChangeEvent}
          ><zui-action-split-option
            primary
            ?disabled=${this.disabled}
            id="action-button-fallback"
          ></zui-action-split-option
        ></slot>
        <zui-button
          zui-internal-retain-icon-size
          ?disabled=${this.disabled || this._noOptionsAtAll}
          @click=${this._handleToggleMenu}
          id="toggle-button"
          >${this.open
            ? html `<zui-icon-arrows-arrow-filled-up slot="icon" size="s8"></zui-icon-arrows-arrow-filled-up>`
            : html `<zui-icon-arrows-arrow-filled-down
                slot="icon"
                size="s8"
              ></zui-icon-arrows-arrow-filled-down>`}</zui-button
        >
      </div>

      <zui-menu-wrapper
        ?open=${this.open}
        .getPositionReference=${this._getButtonReference}
        @zui-menu-wrapper-open=${this._handleMenuWrapperOpening}
        @zui-menu-wrapper-close=${this._handleMenuWrapperClosing}
        @zui-menu-wrapper-opened=${this._handleMenuWrapperOpened}
        @zui-menu-wrapper-closed=${this._handleMenuWrapperClosed}
      >
        <zui-menu
          style=${styleMap({
            '--zui-menu-item-count': this._menuCustomProperties['--zui-menu-item-count'],
            '--zui-menu-width': this._menuCustomProperties['--zui-menu-width'],
        })}
          ?adaptWidth=${this.variant === 'icon-only' || this.variant === 'default-width-adapted'}
          ?disabled=${this.disabled}
          .overflow=${this.menuOverflow}
          @click=${this._handleMenuClick}
          @keydown=${this._handleMenuKeydown}
          @focusout=${this._handleFocusOut}
        >
          ${this.open ? html `<slot @slotchange=${this._handleDefaultSlotChange}></slot>` : nothing}
        </zui-menu>
      </zui-menu-wrapper>

      <slot aria-hidden="true" @slotchange=${this._handleDefaultSlotChange}></slot>
    `;
    }
};
ActionSplitButton.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    actionSplitButtonStyles,
];
__decorate([
    property({ reflect: true, type: Boolean })
], ActionSplitButton.prototype, "open", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'menu-overflow' })
], ActionSplitButton.prototype, "menuOverflow", void 0);
__decorate([
    property({ reflect: true, type: String })
], ActionSplitButton.prototype, "variant", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'replace-on-selection' })
], ActionSplitButton.prototype, "replaceOnSelection", void 0);
__decorate([
    query('#container')
], ActionSplitButton.prototype, "_containerRef", void 0);
__decorate([
    query('zui-menu-wrapper')
], ActionSplitButton.prototype, "_menuWrapperRef", void 0);
__decorate([
    queryAssignedElements({ flatten: true, selector: 'zui-action-split-option' })
], ActionSplitButton.prototype, "_optionRefs", void 0);
__decorate([
    queryAssignedElements({ flatten: true, selector: 'zui-action-split-option[primary]' })
], ActionSplitButton.prototype, "_primaryOption", void 0);
__decorate([
    queryAssignedElements({ selector: 'zui-action-split-option', slot: 'action' })
], ActionSplitButton.prototype, "_actionButton", void 0);
__decorate([
    state()
], ActionSplitButton.prototype, "_menuAnimationIsRunning", void 0);
__decorate([
    state()
], ActionSplitButton.prototype, "_noOptionsAtAll", void 0);
ActionSplitButton = __decorate([
    customElement('zui-action-split-button')
], ActionSplitButton);
export { ActionSplitButton };
