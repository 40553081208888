import { getSessionData } from '../auth';
import type { Countries, LangLinks } from '../types';

export function getLocalizedLink(links: LangLinks, getUserLocale: () => string): string {
  const country = (getSessionData()?.country as Countries) || 'default';

  if (!links || typeof links !== 'object') {
    return '';
  }

  const languages = links[country];

  if (!languages) {
    return links.default || '';
  }

  if (typeof languages === 'string') {
    return languages;
  }

  const locale = getUserLocale() as keyof typeof languages;
  return languages[locale] ?? languages.default;
}
