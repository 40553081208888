import { AuthenticationContext } from '@arnold/react-utils';
import { ZuiThemeProvider } from '@zeiss/zui-react';
import { type LayoutProps, usePiletApi } from 'piral-core';
import { Modals } from 'piral-modals';
import { Notifications } from 'piral-notifications';
import { type FC, Suspense } from 'react';

import { InactivityOverlay } from '../inactivity/InactivityOverlay';

export const Layout: FC<LayoutProps> = ({ children }) => {
  const piral = usePiletApi();
  const redirectToLogin = () => piral.emit('redirectToLogin', {});
  const logout = () => piral.emit('logout', {});

  return (
    <ZuiThemeProvider theme="zbds-dark">
      <Suspense fallback="loading">
        <AuthenticationContext.Provider value={{ redirectToLogin, logout }}>
          {children}
        </AuthenticationContext.Provider>
        <Modals />
        <Notifications />
        <InactivityOverlay />
      </Suspense>
    </ZuiThemeProvider>
  );
};
