import { ZuiList } from '@zeiss/zui-react';
import type { FC, ReactNode } from 'react';

import type { BellNotification } from '../../plugins/bell-notification/types';
import { BellNotificationElement } from './BellNotificationElement';
import { EmptyBellNotificationList } from './EmptyBellNotificationList';

interface BellNotificationPopoverProps {
  notifications: Array<BellNotification>;
  children?: ReactNode;
}

export const BellNotificationPopover: FC<BellNotificationPopoverProps> = ({ notifications }) =>
  notifications.length > 0 ? (
    <div data-test="bell-notification-content">
      <ZuiList size="xl">
        {notifications.map((notification) => (
          <BellNotificationElement notification={notification} key={notification.id} />
        ))}
      </ZuiList>
    </div>
  ) : (
    <EmptyBellNotificationList />
  );
