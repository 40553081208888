import { ZuiScrollableDirective } from '@zeiss/zui-react';
import type { BaseComponentProps } from 'piral-core';
import { Menu } from 'piral-menu';
import type { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

import HeaderBar from '../components/header/HeaderBar';

export const AppContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: var(--zui-color-gs-40);
`;

export const Nav = styled.nav`
  position: absolute;
  top: var(--arn-space-18);
  bottom: var(--arn-space-2);
  height: var(--arn-content-height, 100%);

  zui-menubar {
    z-index: 1;
    height: var(--arn-content-height, 100%);
    transition: box-shadow var(--arn-animation-quick) ease-in-out;

    &[opened] {
      box-shadow: var(--zui-box-shadow-large);
    }
  }
`;

export const App = styled.div`
  @media screen {
    display: flex;
    flex: 1;
    padding: var(--arn-space-2);
    margin-top: var(--arn-space-16);
  }

  @media print {
    padding: var(--arn-space-2);
  }
`;

export const Main = styled.div`
  @media screen {
    position: relative;
    display: flex;
    min-height: var(--arn-content-height, 100%);
    flex: 1;
    margin-left: var(--arn-space-16);
    background-color: var(--zui-color-gs-20);
  }

  @media print {
    position: relative;
    display: flex;
    min-height: var(--arn-content-height, 100%);
    flex: 1;
    margin-left: var(--arn-space-16);
  }
`;

const Scrollable = styled(ZuiScrollableDirective)`
  width: 100%;
  --zui-scrollable-max-height: var(--arn-content-height, 100%);
`;

export const DefaultLayout: FC<PropsWithChildren<BaseComponentProps>> = ({ children }) => (
  <AppContainer>
    <HeaderBar />
    <App>
      <Nav data-test="app-main-menu">
        <Menu type="general" />
      </Nav>
      <Scrollable>
        <Main data-test="app-main">{children}</Main>
      </Scrollable>
    </App>
  </AppContainer>
);
