var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { hostStyles } from '../../host.styles.js';
import { BaseElement } from '../base/base-element.class.js';
import '../progress-ring/progress-ring.component.js';
import { clamp } from '../../utils/component.utils.js';
const style = ":host([emphasis=highlight]){--zui-progress-bar-background-color: var(--zui-color-progress-bar-highlight-background);--zui-progress-bar-foreground-color: var(--zui-color-progress-bar-highlight-foreground)}:host([emphasis=primary-highlight]){--zui-progress-bar-background-color: var(--zui-color-progress-bar-primary-highlight-background);--zui-progress-bar-foreground-color: var(--zui-color-progress-bar-primary-highlight-foreground)}:host([mode=activity]) div{left:-33%;width:33%;animation:activity 3s linear infinite}:host([mode=progress]) div{width:var(---zui-progress-bar-progress-width)}:host(:not([mode=activity])[striped]) div{left:0;width:var(---zui-progress-bar-progress-width, 100%);overflow:hidden;animation:unset}:host(:not([mode=activity])[striped]) div::after{position:absolute;top:0;right:0;bottom:0;left:0;overflow:hidden;background-image:linear-gradient(-45deg, var(--zui-color-progress-bar-striped-foreground-left-tile) 25%, var(--zui-color-progress-bar-striped-background-right-tile) 25%, var(--zui-color-progress-bar-striped-background-right-tile) 50%, var(--zui-color-progress-bar-striped-foreground-left-tile) 50%, var(--zui-color-progress-bar-striped-foreground-left-tile) 75%, var(--zui-color-progress-bar-striped-background-right-tile) 75%, var(--zui-color-progress-bar-striped-background-right-tile));background-size:var(---zui-progress-bar-striped-background-size) var(---zui-progress-bar-striped-background-size);animation:candybar .4s linear infinite;content:\"\"}:host{position:relative;width:100%;height:calc(var(--zui-gu) * 0.5);overflow:hidden;background:var(--zui-progress-bar-background-color);---zui-progress-bar-striped-background-size: calc(var(--zui-gu) * 3.2)}div{position:absolute;height:100%;background:var(--zui-progress-bar-foreground-color)}@keyframes activity{0%{left:-33%}100%{left:100%}}@keyframes candybar{from{background-position:0 0}to{background-position:var(---zui-progress-bar-striped-background-size) 0}}"
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-progress-bar-highlight-background: var(--zui-color-gs-50);--zui-color-progress-bar-highlight-foreground: var(--zui-color-gs-85);--zui-color-progress-bar-primary-highlight-background: var(--zui-color-gs-50);--zui-color-progress-bar-primary-highlight-foreground: var(--zui-color-pb-100);--zui-color-progress-bar-striped-background: var(--zui-color-pb-50);--zui-color-progress-bar-striped-foreground-left-tile: var(--zui-color-pb-100);--zui-color-progress-bar-striped-background-right-tile: var(--zui-color-pb-90)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-progress-bar-highlight-background: var(--zui-color-gs-90);--zui-color-progress-bar-highlight-foreground: var(--zui-color-gs-40);--zui-color-progress-bar-primary-highlight-background: var(--zui-color-gs-90);--zui-color-progress-bar-primary-highlight-foreground: var(--zui-color-pb-90);--zui-color-progress-bar-striped-background: var(--zui-color-pb-90);--zui-color-progress-bar-striped-foreground-left-tile: var(--zui-color-pb-100);--zui-color-progress-bar-striped-background-right-tile: var(--zui-color-pb-90)}"
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const themeLight = ""
const themeDark = ""
const progressBarStyles = css `
  ${unsafeCSS(style)}
`;
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
/**
 * The progress bar is a simple progress bar component, featuring emphasis (highlight, primary-highlight) and two
 * different operation modes (activity and progress). The activity mode shows an inifinte looped animation while the
 * progress mode shows a progress bar with a fixed foreground bar width, according to the given value.
 * There is also a "striped" mode, which shows an animated striped progress bar in mode "progress". The striped bar
 * will not be available, nor work in the "activity" mode.
 *
 *  ## Figma
 * - [Styleguide - Desktop](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---1.4?node-id=13009:2769)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-progress-bar mode="progress" emphasis="highlight" value="30"></zui-progress-bar>
 * ```
 */
let ProgressBar = class ProgressBar extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * Defines the mode of the progress bar (activity/progress)
         */
        this.mode = 'activity';
        /**
         * Defines emphasis states (highlight/primary-highlight)
         */
        this.emphasis = 'highlight';
        /**
         * Striped, or not striped
         */
        this.striped = false;
        /**
         * Defines the value of progress bar (between 0 and 100 - only usable in mode "progress")
         */
        this.value = 0;
    }
    get _getDerivedProgressBarWidth() {
        const clamendValue = clamp(0, this.value, 100);
        return `${clamendValue}%`;
    }
    render() {
        return html `<div
      style="${styleMap({
            '---zui-progress-bar-progress-width': this._getDerivedProgressBarWidth,
        })}"
    ></div>`;
    }
};
ProgressBar.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    progressBarStyles,
];
__decorate([
    property({ reflect: true, type: String })
], ProgressBar.prototype, "mode", void 0);
__decorate([
    property({ reflect: true, type: String })
], ProgressBar.prototype, "emphasis", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], ProgressBar.prototype, "striped", void 0);
__decorate([
    property({ reflect: true, type: Number })
], ProgressBar.prototype, "value", void 0);
ProgressBar = __decorate([
    customElement('zui-progress-bar')
], ProgressBar);
export { ProgressBar };
