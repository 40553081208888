import { css } from 'lit';
import { registerStyle } from './theme.utils.js';
/**
 * This fragment sets specific styles dependent on the used platform, e.g. for specific button arrangements
 */
export const platformCustomization = () => {
    if (navigator.platform.includes('Win')) {
        registerStyle(css `
        :root {
          --zui-internal-action-button-order: row;
          --zui-internal-action-button-alignment: flex-end;
        }
      `, 'platform');
    }
    else {
        // reverse confirm-position on any other OS such as Mac OS
        registerStyle(css `
        :root {
          --zui-internal-action-button-order: row-reverse;
          --zui-internal-action-button-alignment: flex-start;
        }
      `, 'platform');
    }
};
