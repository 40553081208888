import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function isSelected(location: { pathname: string }, href: string) {
  if (href === '/') {
    return href === location.pathname;
  }

  if (href) {
    return location.pathname.startsWith(href);
  }

  return false;
}

export type SelectedCssClass = 'selected-primary' | 'default';

/**
 * React hook to determine the emphasis of a ZUi menubar item based on its href and the current location
 * @param href href the menubar item links to
 */
export function useEmphasis(href: string | undefined = ''): SelectedCssClass {
  const location = useLocation();
  const selected = useMemo(() => isSelected(location, href), [location, href]);
  return selected ? 'selected-primary' : 'default';
}
