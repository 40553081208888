import { setLocalStorageItem, StorageKey } from '../utils/storage';

export function dispatchInactivityDialogClosed(): void {
  const evt = new Event('interactionTabClosed', {
    bubbles: true,
    composed: true,
  });
  window.document.dispatchEvent(evt);

  setLocalStorageItem(StorageKey.INACTIVE_TAB_OPEN, JSON.stringify(false));
}
