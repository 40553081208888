import type { GlobalState, GlobalStateContext } from 'piral-core';

import type { BellNotification } from '../../plugins/bell-notification/types';

export function markSingleNotificationRead(
  context: GlobalStateContext,
  notificationId: string
): void {
  context.dispatch((state: GlobalState) => {
    const updatedNotifications = state.bellNotifications.map((notification: BellNotification) => ({
      ...notification,
      state: notification.id === notificationId ? 'read' : notification.state,
    }));

    return {
      ...state,
      bellNotifications: updatedNotifications,
    };
  });
}

export function markAllNotificationsRead(
  notifications: Array<BellNotification>,
  context: GlobalStateContext
): void {
  const filteredNotifications = notifications
    .filter((notification) => notification.fromCurrentTab && notification.state === 'unread')
    .map((notification) => notification.id);
  filteredNotifications.forEach((id) => markSingleNotificationRead(context, id));
}
