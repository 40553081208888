import { DateTime } from 'luxon';
import { clamp, isDefined } from '../../../utils/component.utils.js';
const dateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
};
/**
 * returns if the given locale has a dayPeriod (am/pm for en-US) by default or not
 *
 * @param {string} locale locale
 *
 * @returns {boolean} whether the locale dependent time has a day period or not
 */
export function hasDayTime(locale) {
    return DateTime.now()
        .toLocaleParts(Object.assign({ locale }, dateTimeFormatOptions))
        .some(({ type }) => type === 'dayPeriod');
}
/**
 * returns a 23 hour format number (0 - 23) from a given 12 hour format number (1 - 12) and a dayTime value
 *
 * @param {number} value value
 * @param {'AM' | 'PM'} dayTime day time
 *
 * @returns {number} hour
 */
export function getHourByDayTime(value, dayTime) {
    switch (dayTime) {
        case 'AM':
            return value % 12;
        case 'PM':
            return (value % 12) + 12;
    }
}
/**
 * returns a new date time based on the given values
 *
 * @param {{ hour: number; minute: number }} value value
 * @param {number} value.hour hour
 * @param {number} value.minute minute
 * @param {DateTime} date date
 * @param {'h12' | 'h23'} hourCycle hour cycle
 * @param {'AM' | 'PM'} dayTime day time
 *
 * @returns {DateTime} date time
 */
export function getUpdatedDate(value, date, hourCycle, dayTime) {
    return date.set({
        hour: hourCycle === 'h12' ? getHourByDayTime(value.hour, dayTime) : value.hour,
        minute: value.minute,
        second: 0,
        millisecond: 0,
    });
}
/**
 * returns a h12 hour converted from h23
 *
 * @param {DateTime | undefined} date date time
 * @param {string} locale locale
 * @param {'h12' | 'h23'} hourCycle hour cycle
 *
 * @returns {string | undefined} localized hour
 */
export function getLocalizedHour(date, locale, hourCycle) {
    const { value } = date.toLocaleParts({ locale, hour: '2-digit', hourCycle }).find(({ type }) => type === 'hour');
    return value;
}
/**
 * returns the day time in 'AM' and 'PM' for a given date when the hourCycle is set to 'h12'
 *
 * @param {DateTime} date date
 * @param {'h12' | 'h23'} hourCycle hour cycle
 *
 * @returns {'AM' | 'PM' | undefined} day time
 */
export function getDayTime(date, hourCycle) {
    if (hourCycle === 'h12') {
        return date.hour < 12 ? 'AM' : 'PM';
    }
    return undefined;
}
/**
 * returns on max string length dependent (5 => 1, 10 => 2) sliced and between min and max clamped value
 *
 * @param {string} value value
 * @param {number} min value
 * @param {number} max value
 *
 * @returns {string} sliced and clamped value
 */
export function sliceAndClamp(value, min, max) {
    const maxLength = String(max).length;
    if (value.length > maxLength) {
        value = value.slice(0, maxLength);
    }
    if (value.length < maxLength) {
        const hasMinLength = value.length === String(min).length;
        const valueGreaterZero = Number(value) > 0;
        return hasMinLength && valueGreaterZero ? String(clamp(min, Number(value), max)) : value;
    }
    const ignoreClamp = Number(value) >= min && Number(value) <= max;
    return !ignoreClamp ? String(clamp(min, Number(value), max)) : value;
}
/**
 * return diff of minutes from two dates
 *
 * @param {DateTime} value value
 * @param {DateTime} minOrMax min or max
 *
 * @returns {number} diff in minutes
 */
export function diffTime(value, minOrMax) {
    const now = DateTime.now().toUTC();
    const updatedValue = now.set({ hour: value.hour, minute: value.minute });
    const updatedMinOrMax = now.set({ hour: minOrMax.hour, minute: minOrMax.minute });
    const { minutes } = updatedMinOrMax.diff(updatedValue, ['minutes'], { conversionAccuracy: 'longterm' }).toObject();
    // TODO: make TS somehow more happy...
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return minutes;
}
export const timePickerInputValueConverter = {
    fromAttribute: (value) => {
        if (value === null) {
            return { dayTime: undefined, hour: undefined, minute: undefined };
        }
        const [hour, minute] = value
            .split(' ')[0]
            .split(':')
            .map((value) => (value !== '' ? parseInt(value) : undefined));
        const [, dayTime] = value.split(' ');
        return { dayTime, hour, minute };
    },
    toAttribute: (value) => {
        var _a, _b;
        if (value === undefined) {
            return undefined;
        }
        if (value.dayTime === undefined && value.hour === undefined && value.minute === undefined) {
            return undefined;
        }
        if (value.dayTime && value.hour === undefined && value.minute === undefined) {
            return `: ${value.dayTime}`;
        }
        return `${(_a = value.hour) !== null && _a !== void 0 ? _a : ''}:${(_b = value.minute) !== null && _b !== void 0 ? _b : ''}${value.dayTime ? ' ' + value.dayTime : ''}`;
    },
};
/**
 * Convert an hour from h23 to h12 format
 *
 * @param hour the hour that has to be converted
 *
 * @returns value the converted value
 */
export function h23ToH12(hour) {
    return hour === 0 || hour === 12 ? 12 : hour % 12;
}
/**
 * Converts a TimePickerInputValue based on the given target HourCycle
 *
 * @param value TimePickerInputValue
 * @param hourCycle HourCycle
 *
 * @returns value converted TimePickerInputValue
 */
export function applyHourCycle(value, hourCycle) {
    const { dayTime, hour } = value;
    if (!isDefined(hour)) {
        return Object.assign(Object.assign({}, value), { 
            // set dayTime to undefined for h12 => h23
            dayTime: hourCycle === 'h12' ? dayTime : undefined });
    }
    return Object.assign(Object.assign({}, value), { dayTime: hourCycle === 'h12' ? dayTime !== null && dayTime !== void 0 ? dayTime : (hour < 12 ? 'AM' : 'PM') : undefined, 
        // take h12 dayTime into account when changing from h12 => 23
        hour: hourCycle === 'h12' ? h23ToH12(hour) : isDefined(dayTime) ? getHourByDayTime(hour, dayTime) : hour });
}
