import { match } from '@formatjs/intl-localematcher';
import { getDefaultLoader } from 'piral-base';
import { type BaseComponentProps, createInstance, Piral, type PiralInstance } from 'piral-core';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

import { getSessionData, logout, zeissIdLogin } from './auth';
import { msalInstance } from './basic-auth/authConfig';
import { createShellErrorComponents, createShellLayoutComponents } from './components/shell';
import details from './details.codegen';
import { getPublicPath } from './environment';
import * as errorActions from './error-handling/error-actions';
import { createExtensions } from './extensions';
import { dispatchInactivityDialogClosed } from './inactivity/user-interaction';
import { setupI18next } from './intl/i18n';
import { getLegalLanguage } from './intl/legal-language';
import { getUserDefinedLanguage, setUserDefinedLanguage } from './intl/user-defined-language';
import { loadLocales } from './locale';
import { updateDependencies } from './ng';
import { createPages } from './pages';
import { createPlugins } from './plugins';
import GlobalStyle from './style';
import type { HdpApp, RuntimeConfig } from './types';
import { createFeedUrls, fetchPilets, getFeeds } from './utils/fetch-pilets';
function createApp(config: RuntimeConfig): HdpApp {
  const locale = 'en';
  const basePath = getPublicPath();
  const country = getSessionData()?.country || 'default';
  const defaultLoader = loadLocales();
  const getBrowserLocale = () => match([navigator.language], config.availableLocales, locale);
  const getUserLocale = () => getUserDefinedLanguage() ?? getBrowserLocale();
  const ifuLanguage = getLegalLanguage(country, config.legalLanguages, getUserLocale());

  return {
    basePath,
    config,
    title: 'HDP App Shell',
    locale,
    ifuLanguage,
    country,
    languageProvider: setupI18next(defaultLoader, locale),
    getUserLocale,
    getBrowserLocale,
  };
}

export function renderApp(config: RuntimeConfig): PiralInstance {
  const app = createApp(config);
  const load = getDefaultLoader();

  const TranslationWrapper = (props: BaseComponentProps) => {
    const i18n = props.piral.getLanguageProvider();
    return <I18nextProvider {...props} i18n={i18n} />;
  };

  const instance = createInstance({
    actions: {
      ...errorActions,
    },
    state: {
      components: createShellLayoutComponents(),
      bellNotifications: [],
      errorMessages: [],
      errorComponents: createShellErrorComponents(),
      registry: {
        applicationInfos: {},
        pages: createPages(),
        extensions: createExtensions(app),
        wrappers: {
          modal: TranslationWrapper,
          menu: TranslationWrapper,
          extension: TranslationWrapper,
          page: TranslationWrapper,
        },
      },
    },
    plugins: createPlugins(app),
    loadPilet(entry) {
      if (entry.spec === 'v2') {
        updateDependencies(entry.dependencies);
      }

      return load(entry);
    },
    requestPilets() {
      const feeds = getFeeds(app.config.feeds, 'full');
      return fetchPilets(createFeedUrls(config.environment, config.endpoints.feed, feeds));
    },
  });

  instance.on('redirectToLogin', () => zeissIdLogin(config));
  instance.once('logout', () => logout(msalInstance));
  instance.on('inactivityDialogClosed', () => dispatchInactivityDialogClosed());
  instance.on('languageChange', (language) => setUserDefinedLanguage(language));

  instance.root.addVersionInfo('Piral', details.piralVersion);
  instance.root.addVersionInfo(app.title, details.appVersion);

  const container = document.querySelector('#app');

  if (container) {
    const root = createRoot(container);

    root.render(
      <I18nextProvider i18n={app.languageProvider}>
        <GlobalStyle />
        <Piral instance={instance} />
      </I18nextProvider>
    );
  }

  return instance;
}
