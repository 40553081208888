import { AuthenticationContext } from '@arnold/react-utils';
import { ZuiIconCommonLogout } from '@zeiss/zui-react-icons';
import { Menu } from 'piral-menu';
import { type FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useClose } from '../../hooks/use-close';
import {
  LogoutButton,
  Popover,
  PopoverContainer,
  Scrollable,
} from './UserMenuPopoverContent.styles';

interface PopoverProps {
  onClose(): void;
}

export const UserMenuPopoverContent: FC<PopoverProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthenticationContext);
  const ref = useClose('user-menu', onClose);

  const checkClose = useCallback(
    (ev: { target: EventTarget | null }) => {
      const element = ev.target;

      if (element instanceof HTMLElement && element.closest('zui-list-item')) {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <PopoverContainer ref={ref} className="popover" slot="icon" onClick={checkClose}>
      <Popover>
        <Scrollable>
          <LogoutButton data-test="logout-button" size="s" emphasis="default" onClick={logout}>
            <ZuiIconCommonLogout slot="icon" />
            {t('accountMenu.logout')}
          </LogoutButton>
          <Menu type="user" />
        </Scrollable>
      </Popover>
    </PopoverContainer>
  );
};
