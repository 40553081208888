import { ZuiHeaderbarNotificationButton, ZuiStateDot } from '@zeiss/zui-react';
import { ZuiIconAlertsAlarmOn } from '@zeiss/zui-react-icons';
import { useGlobalState } from 'piral-core';
import { type FC, useState } from 'react';
import styled from 'styled-components';

import BellNotificationPopoverContent from './BellNotificationPopoverContent';

const StateDot = styled(ZuiStateDot)`
  --zui-color-state-dot: var(--zui-color-sc-r-100);
`;

// This counter is an internal state to this file.
// Its initialized only when this file is loaded.
// Its not reset if the react component is destroyed and remounted.
// Thus it adds a "local" persistency.
let persistedCounter = 0;

export const BellNotificationButton: FC = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const notificationCount = useGlobalState((state) => state.bellNotifications?.length || 0);
  const openBellNotificationMenu = () => {
    if (!isPopoverOpen) {
      persistedCounter = notificationCount;
      setIsPopoverOpen(true);
    }
  };

  const closeBellNotificationMenu = () => {
    setTimeout(() => setIsPopoverOpen(false));
  };

  return (
    <>
      <ZuiHeaderbarNotificationButton
        emphasis={isPopoverOpen ? 'selected' : 'default'}
        hasNotifications
        onClick={openBellNotificationMenu}
        slot="iconButtons"
      >
        <ZuiIconAlertsAlarmOn slot="icon" />
        {persistedCounter < notificationCount && <StateDot slot="stateDot" />}
      </ZuiHeaderbarNotificationButton>

      {isPopoverOpen && <BellNotificationPopoverContent onClose={closeBellNotificationMenu} />}
    </>
  );
};
