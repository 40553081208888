var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var TimePickerCellDivider_1;
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement, RealBaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{display:inline-flex;align-items:center;justify-content:center;width:calc(var(--zui-gu) * 3)}circle{fill:var(--zui-time-picker-cell-divider-color)}:host([disabled]) circle{opacity:var(--zui-disabled-opacity)}"
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-time-picker-cell-divider-color: var(--zui-color-gs-100)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-time-picker-cell-divider-color: var(--zui-color-gs-50)}"
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const timePickerCellDividerComponentStyles = css `
  ${unsafeCSS(styles)}
`;
/**
 * Time picker cell divider.
 *
 * @example
 * html```
 * <zui-time-picker-cell-divider></zui-time-picker-cell-divider>
 * ```
 */
let TimePickerCellDivider = TimePickerCellDivider_1 = class TimePickerCellDivider extends DisabledMixin(RealBaseElement) {
    constructor() {
        super(...arguments);
        /**
         * literal
         */
        this.literal = ':';
    }
    get _colonHeight() {
        return TimePickerCellDivider_1.COLON_SPACING + 2 * TimePickerCellDivider_1.COLON_CIRCLE_WIDTH;
    }
    _getColonLiteral() {
        return html `
      <svg width="${TimePickerCellDivider_1.COLON_CIRCLE_WIDTH}" height="${this._colonHeight}">
        <circle
          cx="${TimePickerCellDivider_1.COLON_CIRCLE_WIDTH / 2}"
          cy="${TimePickerCellDivider_1.COLON_CIRCLE_WIDTH}"
          r="${TimePickerCellDivider_1.COLON_CIRCLE_WIDTH / 2}"
        />
        <circle
          cx="${TimePickerCellDivider_1.COLON_CIRCLE_WIDTH / 2}"
          cy="${this._colonHeight - TimePickerCellDivider_1.COLON_CIRCLE_WIDTH}"
          r="${TimePickerCellDivider_1.COLON_CIRCLE_WIDTH / 2}"
        />
      </svg>
    `;
    }
    render() {
        switch (this.literal) {
            case ':':
                return this._getColonLiteral();
            default:
                return this._getColonLiteral();
        }
    }
};
TimePickerCellDivider.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    timePickerCellDividerComponentStyles,
];
// eslint-disable-next-line @typescript-eslint/naming-convention
TimePickerCellDivider.COLON_SPACING = 10;
// eslint-disable-next-line @typescript-eslint/naming-convention
TimePickerCellDivider.COLON_CIRCLE_WIDTH = 3;
__decorate([
    property({ reflect: true, type: String })
], TimePickerCellDivider.prototype, "literal", void 0);
TimePickerCellDivider = TimePickerCellDivider_1 = __decorate([
    customElement('zui-time-picker-cell-divider')
], TimePickerCellDivider);
export { TimePickerCellDivider };
