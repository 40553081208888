import { loginRequest, msalInstance } from './authConfig';

const mayerAccessTokenKey = 'mayer-access-token';
const mayerTokenExpiration = 'mayer-token-expiration';

export async function initAuth() {
  if (msalInstance) {
    await msalInstance.initialize();
    msalInstance
      .handleRedirectPromise()
      .then(async (response) => {
        let accountId;
        if (response) {
          accountId = response.account?.homeAccountId;
          sessionStorage.setItem(mayerAccessTokenKey, response.accessToken);
          // You may want to store the expiration time as well
          sessionStorage.setItem(mayerTokenExpiration, new Date(response.expiresOn).valueOf());
        } else {
          const currentAccounts = msalInstance.getAllAccounts();
          if (currentAccounts.length === 0) {
            msalInstance.loginRedirect(loginRequest);
          } else if (currentAccounts.length === 1) {
            accountId = currentAccounts[0]?.homeAccountId;
            // Fetch token silently if needed
            await refreshTokenIfNeeded(currentAccounts[0]);
          }
        }

        console.log(accountId);
      })
      .catch((error) => {
        console.error('Error handling redirect:', error);
      });
  }
}

export async function refreshTokenIfNeeded(account) {
  let token = sessionStorage.getItem(mayerAccessTokenKey);
  const tokenExpiration = sessionStorage.getItem(mayerTokenExpiration);
  if (token === 'undefined' || !isTokenValid(parseInt(tokenExpiration, 10))) {
    try {
      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account,
      });

      if (response?.accessToken) {
        token = response.accessToken;
        sessionStorage.setItem(mayerAccessTokenKey, response.accessToken);
        sessionStorage.setItem(mayerTokenExpiration, new Date(response.expiresOn).valueOf());
      } else {
        throw new Error('Failed to acquire new access token.');
      }
    } catch (error) {
      // Handle token refresh failure, e.g., redirect to login
      msalInstance.loginRedirect(loginRequest);
      return null;
    }
  }

  return token;
}

export function isTokenValid(expirationTime) {
  const currentTime = new Date().getTime();
  return currentTime < expirationTime;
}

export function logoutAction() {
  sessionStorage.removeItem(mayerAccessTokenKey);
  sessionStorage.removeItem(mayerTokenExpiration);
  msalInstance.logoutRedirect();
}
