import {
  type BaseRegistration,
  type Dict,
  type PiralPlugin,
  withKey,
  withoutKey,
} from 'piral-core';

import type { ApplicationInfo } from './types';

interface ApplicationRegistration extends ApplicationInfo, BaseRegistration {}

interface ApplicationInfoPiralState {
  applicationInfos: Dict<ApplicationRegistration>;
}

/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'piral-core/lib/types/custom' {
  interface PiletCustomApi extends PiletApplicationInfoApi {}
  interface PiralCustomRegistryState extends ApplicationInfoPiralState {}
}

export interface PiletApplicationInfoApi {
  /**
   * Enables registering a human-readable name for the application which will be
   * displayed in the Support Form as a dropdown menu item.
   *
   * @param {ApplicationInfo} info
   */
  registerApplicationInfo(info: ApplicationInfo): void;
  /**
   * Removes the application infos placed for the current pilet.
   */
  unregisterApplicationInfo(): void;
}

export function createApplicationInfoApi(): PiralPlugin<PiletApplicationInfoApi> {
  return (context) => (api) => ({
    registerApplicationInfo(info: ApplicationInfo): void {
      context.dispatch((state) => ({
        ...state,
        registry: {
          ...state.registry,
          applicationInfos: withKey(state.registry.applicationInfos, api.meta.name, {
            ...info,
            pilet: api.meta.name,
          }),
        },
      }));
    },
    unregisterApplicationInfo(): void {
      context.dispatch((state) => ({
        ...state,
        registry: {
          ...state.registry,
          applicationInfos: withoutKey(state.registry.applicationInfos, api.meta.name),
        },
      }));
    },
  });
}
