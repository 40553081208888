var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseElement, RealBaseElement } from '../../base/base-element.class.js';
import { FocusDifferentiationMixin } from '../../../mixins/visual-focus/focus-differentiation.mixin.js';
import { hostStyles } from '../../../host.styles.js';
const style = ":host{display:flex;width:100%}:host([zui-keyboard-focused]) div:focus{border:1px dashed;border-radius:var(--zui-border-radius-on-focus);outline:none}div{width:100%;padding:calc(var(--zui-gu) * 2);color:var(--zui-color-ewiq-dialog-content-text-color);background:var(--zui-color-ewiq-dialog-content-background);border:1px solid rgba(0,0,0,0)}"
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-ewiq-dialog-content-text-color: var(--zui-color-gs-100);--zui-color-ewiq-dialog-content-background: var(--zui-color-gs-20)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-ewiq-dialog-content-text-color: var(--zui-color-gs-50);--zui-color-ewiq-dialog-content-background: var(--zui-color-gs-90)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const ewiqDialogContentStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * This represents a very simple content box component, which is used in other components, e.g. EwiqDialog.
 * It provides a pre-styled span container with a default slot.
 *
 * @example
 * HTML:
 *
 * ```html
 *  <zui-ewiq-dialog-content>Content</zui-ewiq-dialog-content>
 *
 * ```
 *
 * @slot - This is the default slot, to keep the content
 */
let EwiqDialogContent = class EwiqDialogContent extends FocusDifferentiationMixin(RealBaseElement) {
    render() {
        return html `<div tabindex="0"><slot></slot></div>`;
    }
};
EwiqDialogContent.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    ewiqDialogContentStyles,
];
EwiqDialogContent = __decorate([
    customElement('zui-ewiq-dialog-content')
], EwiqDialogContent);
export { EwiqDialogContent };
