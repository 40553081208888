var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { hostStyles } from '../../host.styles.js';
import { BaseElement } from '../base/base-element.class.js';
const style = ":host{---zui-tooltip-color-background: var(--zui-color-text-box-bg);---zui-tooltip-color-border: var(--zui-color-tooltip-border);---zui-tooltip-color-text: var(--zui-color-text-default);--zui-tooltip-max-width: calc(var(--zui-gu) * 46.5);--zui-tooltip-padding: calc(var(--zui-gu) * 0.25) calc(var(--zui-gu) * 1) calc(var(--zui-gu) * 0.5);display:block;width:max-content;max-width:var(--zui-tooltip-max-width);padding:var(--zui-tooltip-padding);color:var(---zui-tooltip-color-text);font:var(--zui-typography-caption) !important;background-color:var(---zui-tooltip-color-background);border:var(--zui-line-strength-thin) solid var(---zui-tooltip-color-border);box-shadow:var(--zui-box-shadow-default);cursor:default;user-select:none;pointer-events:none}:host([zui-internal-no-text]){--zui-tooltip-padding: calc(var(--zui-gu) * 1)}:host([emphasis=warning]){---zui-tooltip-color-background: var(--zui-color-sc-r-110);---zui-tooltip-color-border: var(--zui-color-sc-r-110);---zui-tooltip-color-text: var(--zui-color-gs-10)}"
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-text-default: var(--zui-color-gs-100);--zui-color-text-on-dark-bg: var(--zui-color-gs-10);--zui-color-text-primary: var(--zui-color-pb-100);--zui-color-text-header: var(--zui-color-gs-120);--zui-color-text-secondary-line: var(--zui-color-gs-85);--zui-color-text-disabled: var(--zui-color-gs-100);--zui-color-text-box-bg: var(--zui-color-gs-30);--zui-color-box-shadow-default: rgba(0, 0, 0, 20%);--zui-box-shadow-default: 0px 3px 8px var(--zui-color-box-shadow-default);--zui-box-shadow-large: 0px 3px 12px var(--zui-color-box-shadow-default);--zui-box-shadow-large-inverted: 0px -3px 12px var(--zui-color-box-shadow-default);--zui-color-tooltip-border: var(--zui-color-gs-60)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-text-default: var(--zui-color-gs-50);--zui-color-text-on-dark-bg: var(--zui-color-gs-10);--zui-color-text-primary: var(--zui-color-pb-70);--zui-color-text-header: var(--zui-color-gs-10);--zui-color-text-secondary-line: var(--zui-color-gs-70);--zui-color-text-disabled: var(--zui-color-gs-50);--zui-color-text-divider: var(--zui-color-gs-70);--zui-color-text-box-bg: var(--zui-color-gs-110);--zui-color-box-shadow-default: rgba(0, 0, 0, 20%);--zui-box-shadow-default: 0px 3px 8px var(--zui-color-box-shadow-default);--zui-box-shadow-large: 0px 3px 12px var(--zui-color-box-shadow-default);--zui-box-shadow-large-inverted: 0px -3px 12px var(--zui-color-box-shadow-default);--zui-color-tooltip-border: var(--zui-color-gs-120)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
export const TOOLTIP_STYLES = css `
  ${unsafeCSS(style)}
`;
/**
 * The tooltip is a common graphical user interface element.
 * It is used in conjunction with a cursor, usually a pointer.
 * The user hovers the pointer over an item, without clicking it,
 * and a tooltip may appear — a small "hover box" with information about the item being hovered over.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2751)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=23852%3A34)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-tooltip></zui-tooltip>
 * <zui-tooltip emphasis="warning"></zui-tooltip>
 * ```
 *
 * @slot - This is the default slot. It's an innerHtml of the tooltip-element. <br/>It can contain either text:<br/>\<p\>, \<span\>, \<a\>, \<i\>, \<b\>, \<strong\>, \<code\>, \<pre\> <br/>or non-text content.
 *
 * @cssprop --zui-tooltip-max-width - as the tooltip grows with its contents, this defines the horizontal limit
 * @cssprop --zui-tooltip-padding - inner spacing of the tooltip container
 */
let Tooltip = class Tooltip extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * defines one of two possible emphasis states (default/warning);
         */
        this.emphasis = 'default';
        /**
         * allows defining a text message body (which would be overwritten by any slotted content)
         */
        this.message = '';
        /**
         * flags non-text content for styling
         *
         * @private
         */
        this.isNotText = false;
    }
    // detect non-text content
    _checkSlotContent() {
        this.isNotText = this._slotRef.assignedElements().length > 0;
    }
    // run initial tasks
    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this._checkSlotContent();
    }
    render() {
        return html `<slot @slotchange="${this._checkSlotContent}">${this.message}</slot> `;
    }
};
Tooltip.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    TOOLTIP_STYLES,
];
__decorate([
    property({ reflect: true, type: String })
], Tooltip.prototype, "emphasis", void 0);
__decorate([
    property({ reflect: true, type: String })
], Tooltip.prototype, "message", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'zui-internal-no-text' })
], Tooltip.prototype, "isNotText", void 0);
__decorate([
    query('slot')
], Tooltip.prototype, "_slotRef", void 0);
Tooltip = __decorate([
    customElement('zui-tooltip')
], Tooltip);
export { Tooltip };
