import '../../utils/util.types.js';
import '../../contracts/event.contracts.js';
import { CommandEvent, MimickedDOMEvent, NotificationEvent, SharedEvent } from '../../contracts/event.classes.js';
import '../../components/base/base-element.class.js';
import camelCase from 'lodash-es/camelCase.js';
import 'lit';
const pascalCase = (input) => {
    const [firstLetter, ...restingLetters] = camelCase(input);
    return firstLetter.toUpperCase() + restingLetters.join('');
};
// TODO: use GetEventEmitterType
const getEventEmitterName = (event) => {
    const eventInst = new event();
    // TODO: use switch
    if (eventInst instanceof MimickedDOMEvent || eventInst instanceof NotificationEvent) {
        return `emit${pascalCase(eventInst.baseType)}Event`;
    }
    if (eventInst instanceof SharedEvent || eventInst instanceof CommandEvent) {
        return `_emit${pascalCase(eventInst.baseType)}Event`;
    }
    // TODO: prevent undefined
};
// TODO: this will be later removed and is currently not used (besides tests :) )
export const addEvent = (event) => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/explicit-module-boundary-types
    return function (superClass) {
        class EventMixedClass extends superClass {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            constructor(...args) {
                super(...args);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore because too much meta programming
                this[getEventEmitterName(event)] = (detail = undefined) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore because too much meta programming
                    return this.dispatchEvent(new event(detail));
                };
            }
        }
        return EventMixedClass;
    };
};
// TODO: this will be later removed and is currently not used
// "inspired" by https://stackoverflow.com/questions/66707738/comprehensible-chaining-of-mixins-for-a-typescript-class-without-nesting
// TODO: the EventBaseClassConstraint should be generic
// eslint-disable-next-line jsdoc/require-jsdoc,@typescript-eslint/naming-convention,@typescript-eslint/no-explicit-any
export function EventsMixin(baseClass, ...mixins) {
    return mixins.reduce((mix, applyMixin) => applyMixin(mix), baseClass);
}
// TODO: this desperately needs tests
// eslint-disable-next-line jsdoc/require-jsdoc,@typescript-eslint/naming-convention,@typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function WithEventsMixin(baseClass) {
    class EventMixedClass extends baseClass {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        constructor(...args) {
            super(...args);
            Object.keys(this)
                .filter((key) => key.endsWith('Event') && this[key]['constructor'] !== undefined)
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                .map((keyOfEvent) => ({ eventEmitterName: getEventEmitterName(this[keyOfEvent]), keyOfEvent }))
                .forEach(({ eventEmitterName, keyOfEvent }) => (this[eventEmitterName] = (detail = undefined) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore because too much meta programming
                return this.dispatchEvent(new this[keyOfEvent](detail));
            }));
        }
    }
    return EventMixedClass;
}
