var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
/**
 * A structural element to be used within the `zui-select` feature component to separate options.
 * It has neither styling nor functional implementations whatsoever. The optional slotted content
 * will be passed further into the UI divider.
 *
 * @example
 * ```HTML
 * <zui-select-divider>An optional label</zui-select-divider>
 * ```
 *
 * @slot - default slot for an optional divider label
 */
let SelectDivider = class SelectDivider extends BaseElement {
    render() {
        return html `<slot></slot> `;
    }
};
SelectDivider = __decorate([
    customElement('zui-select-divider')
], SelectDivider);
export { SelectDivider };
