/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type { RuntimeConfig } from '../types';
import { cancelSessionRefreshStream } from '../utils/session-refresh';
import { resetStorageData } from './session.provider';
import { getLogoutEndpoint } from './zeiss-id-discovery';
const mayerAccessTokenKey = 'mayer-access-token';
const mayerTokenExpiration = 'mayer-token-expiration';
export const logout = async (instance: any): Promise<void> => {
  if (instance) {
    sessionStorage.removeItem(mayerAccessTokenKey);
    sessionStorage.removeItem(mayerTokenExpiration);
    await instance.logout();
  }
};

export const endSession = async (config: RuntimeConfig): Promise<void> => {
  cancelSessionRefreshStream();
  const endSessionUrl = new URL(await getLogoutEndpoint(config));
  endSessionUrl.searchParams.set('post_logout_redirect_uri', location.href);
  resetStorageData();
  location.replace(endSessionUrl.toString());
};
