import { isValueInList } from '../../../utils/types.utils.js';
export const addLeadingZeros = (value, length) => {
    return `${value}`.padStart(length, '0');
};
export const constrainInputValue = (value, min, max) => {
    if (value < min) {
        return min;
    }
    if (value > max) {
        return max;
    }
    return value;
};
const allowedNumericKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const allowedNavigationKeys = [
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'Backspace',
    'Delete',
    'End',
    'Enter',
    'NumpadEnter',
    'Home',
    'Tab',
];
const allowedNumericNavigationKeys = [...allowedNumericKeys, ...allowedNavigationKeys];
/**
 * Filter for valid numeric keys and navigation keys.
 *
 * @param key - keyboard input
 * @returns true if the key is part of a collection of allowed keys.
 */
export const isAllowedNumericKeyboardInput = (key) => isValueInList(key, allowedNumericNavigationKeys);
/**
 * This behaviour is inspired by the Chrome <input type=month" />
 * It bundles business logic for the selection of the next relevant month based on a users keyboard search input.
 * Accepts only alphabetic strings.
 *
 * @param months all available localized months - case sensitive
 * @param currentIndex the index of currently selected month in the months array
 * @param searchString a string of alphabetic lowercased characters determining the search result. A single character returns the next result in the cycle from the current selection.
 * A multicharacter string returns the next matching result in the cycle including the current selection.
 *
 * @returns the next selected month index based on the users input
 */
export const searchNextRelevantMonth = (months, currentIndex, searchString) => {
    const monthsLowerCased = months.map((month) => month.toLowerCase());
    const matchingMonths = monthsLowerCased.filter((option) => option.startsWith(searchString));
    if (matchingMonths.length === 0) {
        return currentIndex;
    }
    if (currentIndex === null) {
        return monthsLowerCased.indexOf(matchingMonths[0]);
    }
    // Toggles to the next match in the cycle
    if (searchString.length === 1) {
        const nextMatch = matchingMonths.find((match) => monthsLowerCased.indexOf(match) > currentIndex);
        return monthsLowerCased.indexOf(nextMatch !== null && nextMatch !== void 0 ? nextMatch : matchingMonths[0]);
    }
    // Selects the next match in the cycle prioritizing the current selection
    else {
        const nextMatchIncludingCurrent = matchingMonths.find((match) => monthsLowerCased.indexOf(match) >= currentIndex);
        return monthsLowerCased.indexOf(nextMatchIncludingCurrent !== null && nextMatchIncludingCurrent !== void 0 ? nextMatchIncludingCurrent : matchingMonths[0]);
    }
};
