import { ZuiSectionDivider } from '@zeiss/zui-react';
import { usePiletApi } from 'piral-core';
import { Children, type FC, isValidElement, type ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { getSessionData } from '../auth';
import { showConsent } from '../consent';
import { getLocalizedLink } from '../hooks/use-localized-link';
import {
  SETTINGS_DIALOG_NAME,
  SUPPORT_DIALOG_NAME,
  VERSION_DIALOG_NAME,
} from '../modals/constants';
import * as routes from '../routes';
import DataStorageDisplay from './data-storage-display/DataStorageDisplay';
import { UserMenuItem } from './UserMenuItem';

const SectionDivider = styled(ZuiSectionDivider)`
  div {
    font: var(--zui-typography-label2);
  }
`;

function byWeight(a: { weight: number }, b: { weight: number }) {
  return b.weight - a.weight;
}

export interface UserMenuPopoverProps {
  children?: ReactNode;
}

type WeightedChild = { weight: number; child: ReactNode };

export const UserMenuPopover: FC<UserMenuPopoverProps> = ({ children }) => {
  const session = getSessionData();
  const api = usePiletApi();
  const { t } = useTranslation();
  const [configuration, help, info] = useMemo(() => {
    const helpItems: Array<WeightedChild> = [
      {
        weight: 0,
        child: (
          <UserMenuItem
            key="customer-support"
            name="customer-support"
            title={t('accountMenu.customerSupport')}
            icon="zui-icon-user-user-operator"
            callback={() => api.showModal(SUPPORT_DIALOG_NAME)}
          />
        ),
      },
      {
        weight: 0,
        child: (
          <UserMenuItem
            key="user-manual"
            name="user-manual"
            title={t('accountMenu.userManual')}
            icon="zui-icon-common-user-manual"
            target="_blank"
            href={routes.instructionsForUse}
          />
        ),
      },
    ];
    const infoItems: Array<WeightedChild> = [
      {
        weight: 1000,
        child: (
          <UserMenuItem
            key="imprint"
            name="imprint"
            title={t('accountMenu.imprintLink')}
            target="_blank"
            href={getLocalizedLink(api.getConfig('imprintLinks'), api.getUserLocale)}
          />
        ),
      },
      {
        weight: 200,
        child: (
          <UserMenuItem
            key="data-privacy"
            name="data-privacy"
            title={t('accountMenu.dataProtection')}
            target="_blank"
            href={routes.dataProtection}
          />
        ),
      },
      {
        weight: 0,
        child: (
          <UserMenuItem
            name="end-user-terms"
            title={t('accountMenu.endUserTerms')}
            target="_blank"
            key="end-user-terms"
            href={routes.termsOfUse}
          />
        ),
      },
      {
        weight: 0,
        child: (
          <UserMenuItem
            key="legal-notice"
            name="legal-notice"
            title={t('accountMenu.legalNotice')}
            target="_blank"
            href={getLocalizedLink(api.getConfig('legalNoticeLinks'), api.getUserLocale)}
          />
        ),
      },
      {
        weight: 0,
        child: (
          <UserMenuItem
            key="third-party-software-licenses"
            name="third-party-software-licenses"
            title={t('accountMenu.thirdPartyTerms')}
            target="_blank"
            href={routes.foss}
          />
        ),
      },
      {
        weight: 0,
        child: (
          <UserMenuItem
            key="software-version"
            name="software-version"
            title={t('accountMenu.softwareVersion')}
            callback={() => api.showModal(VERSION_DIALOG_NAME)}
          />
        ),
      },
      {
        weight: 0,
        child: (
          <UserMenuItem
            key="product-labels"
            name="product-labels"
            title={t('accountMenu.productLabels')}
            target="_blank"
            href={routes.productLabels}
          />
        ),
      },
      {
        weight: 100,
        child: (
          <UserMenuItem
            key="edit-cookie-consent"
            name="edit-cookie-consent"
            title={t('cookieConsent.editPreferencesLink')}
            callback={showConsent}
          />
        ),
      },
    ];
    const configItems: Array<WeightedChild> = [
      {
        weight: 0,
        child: (
          <UserMenuItem
            key="configuration"
            name="configuration"
            title={t('accountMenu.settings')}
            icon="zui-icon-common-settings-settings"
            callback={() => api.showModal(SETTINGS_DIALOG_NAME)}
          />
        ),
      },
    ];

    Children.forEach(children, (child) => {
      if (isValidElement(child)) {
        const { section, weight = 0 } = child.props.meta;

        if (section === 'info') {
          infoItems.push({ weight, child });
        } else if (section === 'help') {
          helpItems.push({ weight, child });
        } else if (section === 'configuration') {
          configItems.push({ weight, child });
        }
      }
    });

    return [
      configItems.sort(byWeight).map((m) => m.child),
      helpItems.sort(byWeight).map((m) => m.child),
      infoItems.sort(byWeight).map((m) => m.child),
    ];
  }, [children, t]);

  return (
    <>
      {session?.isUsingLicenseModel && <DataStorageDisplay />}

      <SectionDivider>
        <div>{t('accountMenu.configuration')}</div>
      </SectionDivider>

      {configuration}

      <SectionDivider>
        <div>{t('accountMenu.help')}</div>
      </SectionDivider>

      {help}

      <SectionDivider>
        <div>{t('accountMenu.aboutDivider')}</div>
      </SectionDivider>

      {info}
    </>
  );
};
