var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Breakpoint } from '../types.js';
import { customElement } from 'lit/decorators.js';
const breakpoints = {
    [Breakpoint.XS]: {
        min: 0,
        max: 767,
    },
    [Breakpoint.S]: {
        min: 768,
        max: 1023,
    },
    [Breakpoint.M]: {
        min: 1024,
        max: 1279,
    },
    [Breakpoint.L]: {
        min: 1280,
        max: Infinity,
    },
};
/**
 * Checks if a string is not empty
 * a string is also empty when it contains only spaces
 *
 * @param {string | undefined} testString - The string which should be checked
 * @returns {boolean} whether the string is not empty
 * @private
 */
export function isStringNotEmpty(testString) {
    // we are not empty, if the trimmed string has a length > 0
    if (testString) {
        return testString.trim().length > 0;
    }
    else {
        return false;
    }
}
/**
 * Checks if a string is empty
 * a string is also empty when it contains only spaces
 *
 * @param {string | undefined} testString - The string which should be checked
 * @returns {boolean} whether the string is empty
 * @private
 */
export function isStringEmpty(testString) {
    return !isStringNotEmpty(testString);
}
/**
 * Checks if slot is not empty
 *
 * @param {HTMLSlotElement} slot the slot which gets checked
 * @returns {boolean} whether the slot is not empty
 * @private
 */
export function isSlotNotEmpty(slot) {
    return slot.assignedNodes().length > 0;
}
/**
 * Checks if a slot is empty, i.e. has no assigned nodes
 *
 * @param {HTMLSlotElement} slot the slot which gets checked
 * @returns {boolean} whether the slot is empty
 * @private
 */
export function isSlotEmpty(slot) {
    return !isSlotNotEmpty(slot);
}
/**
 * Returns the zui font, because font gets rendered differently by the browser than it gets set in the variable
 *
 * @param {string} fontClass class of the zui font
 * @returns {string} information of the zui font
 * @private
 */
export function getZuiFont(fontClass) {
    // TODO: maybe should be refactored into own generate function
    const div = document.createElement('div');
    div.setAttribute('style', 'font: var(--zui-typography-' + fontClass + ');');
    document.documentElement.appendChild(div);
    const zuiFont = window.getComputedStyle(div).getPropertyValue('font');
    document.documentElement.removeChild(div);
    return zuiFont;
}
/**
 * Gets the value of a css prpoerty from the given element
 *
 * @param {HTMLElement} element the element which
 * @param {string} prop the property
 * @returns {string} the value of the css property
 * @private
 */
export function getCssPropertyFromElement(element, prop) {
    const propertyValue = window.getComputedStyle(element).getPropertyValue(prop);
    return propertyValue;
}
/**
 * Returns the computed style for an element returned by a query from a shadow dom
 *
 * @param {Element} webelement the webelement in which shadow dom the query should search
 * @param {string} cssQuery the query for the search
 * @returns {CSSStyleDeclaration} the returned computed Style, it will be empty when the query didn't found an element
 * @private
 */
export function getComputedStyleForQueryInShadowDom(webelement, cssQuery) {
    var _a;
    const element = (_a = webelement.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector(cssQuery);
    if (element) {
        return window.getComputedStyle(element);
    }
    else {
        // this will return an empty CSSStyleDeclaration
        return window.getComputedStyle(document.createElement('p'));
    }
}
/**
 * Compares the value of a specific border-property of an element to an expected value
 *
 * @param {HTMLElement} element the element to check
 * @param {string} borderProperty the specific border-property to check
 * @param {string} value expected value
 * @returns {boolean} whether the value is like it was expected
 * @private
 */
export function hasElementBorderPropertyValue(element, borderProperty, value) {
    return ['top', 'right', 'bottom', 'left'].every((borderPart) => value === getCssPropertyFromElement(element, `border-${borderPart}-${borderProperty}`));
}
/**
 * @param {HTMLElement} element the element where to find the slots
 * @param {string} slotName the name of the slot to obtain nodes
 * @returns {HTMLElement[]} returns the slotted element
 * @private
 */
export function getSlottedElementsFromNamedSlot(element, slotName) {
    return Array.from(
    // we simply assume being called only on elements with shadow DOM
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    element.shadowRoot.querySelector(`slot[name=${slotName}]`).assignedNodes()).filter((node) => node instanceof HTMLElement);
}
/**
 * propagates a value to a selection of childs found by query from an element
 *
 * @param slot that the predicate is used to propagate values for
 * @param predicate function that filters childs
 * @param property that should be set for child matching predicagte
 * @param value for property for matched child by predicate
 */
// TODO: come up with some SICK uber TS magic, that generates a matching type for property and value
export function propagateValueToSlotContentByPredicate(slot, predicate, property, value) {
    Array.from(slot.assignedElements())
        .filter(predicate)
        // eslint-disable-next-line no-return-assign
        .forEach((contentElement) => (contentElement[property] = value));
}
/**
 * returns the serialized DOMContent as a string from a slot
 *
 * @param {HTMLSlotElement} slot whose content should be serialized
 * @returns {string|undefined} returns either a string if the slot contains something or undefined if it is empty
 */
export function serializeSlotContent(slot) {
    if (slot.assignedNodes().length > 0) {
        // get a temporary node, for our serializing business
        const tempNode = document.createElement('span');
        slot.assignedNodes().forEach((node) => tempNode.append(node.cloneNode(true)));
        return tempNode.innerHTML;
    }
    else {
        return undefined;
    }
}
/**
 * @param {HTMLElement} element the element from what the shadowRoot should be queried
 * @param {string|undefined} slotName an optional slotName for the querySelector
 * @returns {HTMLSlotElement | null} returns either a HTMLSlotElement or null
 */
export function getSlotByName(element, slotName) {
    // lets hope this gets only called on element with an actual shadowRoot
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return element.shadowRoot.querySelector(`slot${slotName ? `[name="${slotName}"]` : ':not([name])'}`);
}
/**
 * @param {number} width the width that lies between a min and max of a Breakpoint
 * @returns {Breakpoint} returns a Breakpoint
 */
export function getBreakpointForWidth(width) {
    return Object.keys(breakpoints).find((bp) => width >= breakpoints[bp].min && width <= breakpoints[bp].max);
}
const mediaOperations = {
    '<': (a, b) => a.max < b.min,
    '>': (a, b) => a.min > b.max,
};
/**
 * @param {Breakpoint} media media
 * @param {'<' | '>'} operator smaller / larger
 * @param {Breakpoint} breakpoint breakpoint
 * @returns {boolean} returns whether the given media is larger or smaller than the breakpoint or not
 */
export function compareMediaBreakpoint(media, operator, breakpoint) {
    const operation = mediaOperations[operator];
    return operation(breakpoints[media], breakpoints[breakpoint]);
}
// custom converters
/**
 * component attribute converter from string to boolean and vice versa
 */
export const booleanStringConverter = {
    fromAttribute: (value) => value === 'true',
    toAttribute: (value) => (value ? 'true' : 'false'),
};
/**
 * a factory for creating a custom lit element converter for strings which are declared as list
 * (separated by a given string) in attributes, but handled as array properties internally
 *
 * @param separator to be used to split and join items
 * @param mapperConverter is an inner converter, that is called after the string list has been unwrapped
 * @returns custom attribute converter
 */
export const getStringArrayConverter = (separator = ' ', mapperConverter = {
    fromAttribute(value) {
        return value;
    },
    toAttribute(value) {
        return value;
    },
}) => ({
    fromAttribute: (value) => value === null || value.length === 0
        ? []
        : value.split(separator).map(mapperConverter.fromAttribute),
    toAttribute: (value) => value === undefined || value.length === 0 ? '' : value.map(mapperConverter.toAttribute).join(separator),
});
/**
 * component attribute converter from stringy number to number | undefined and vice versa
 */
export const numberUndefinedConverter = {
    fromAttribute: (value) => (value === null ? undefined : Number(value)),
    toAttribute: (value) => (typeof value === 'number' ? String(value) : null),
};
/**
 * component attribute converter from string to string | undefined and vice versa
 */
export const stringUndefinedConverter = {
    fromAttribute: (value) => (value === null ? undefined : value),
    toAttribute: (value) => (typeof value === 'string' ? value : null),
};
/**
 * wraps any passed converter and maps empty string in attribute to empty attribute
 * @param converterToBeWrapped
 * @returns ComplexAttributeConverter
 */
export const emptyStringToNullWrapperConverter = (converterToBeWrapped) => ({
    fromAttribute: (value) => 
    // if empty string is passed map it internally to an empty = null attribute
    (value === null || value === void 0 ? void 0 : value.length) === 0
        ? converterToBeWrapped.fromAttribute(null)
        : converterToBeWrapped.fromAttribute(value),
    toAttribute: converterToBeWrapped.toAttribute,
});
/**
 * returns the background color of a given element
 *
 * @param {HTMLElement} element to be inspected
 * @returns {string} the background color as rgb string
 */
export const getComputedBackgroundColor = (element) => window.getComputedStyle(element).getPropertyValue('background-color');
/**
 * returns the opacity of a given element
 *
 * @param {HTMLElement} element to be inspected
 * @returns {string} the opacity as string
 */
export const getComputedOpacity = (element) => window.getComputedStyle(element).getPropertyValue('opacity');
/**
 * returns the clamped value between a given minimum and a maximum
 *
 * @param {number} min the minimum
 * @param {number} n the number to be clamped
 * @param {number} max the maximum
 * @returns {number} the clamped value
 */
export const clamp = (min, n, max) => {
    return Math.max(min, Math.min(n, max));
};
/**
 * returns whether the passed value is defined, i.e. neither `null` nor `undefined`
 * @param value
 * @returns true, if defined otherwise false
 */
export function isDefined(value) {
    return value !== null && value !== undefined;
}
/**
 * returns the next number dependent on the given direction and step
 * when the max is reached the min is returned; when the min is reached the max is returned
 *
 * @param {number} value current value
 * @param {number} min min value
 * @param {number} max max value
 * @param {number} step the value that is decreased or increased from current value
 * @param {number} direction decrease or increase value
 *
 * @returns {number} the decreased or increased value
 */
export const cycle = (value, min, max, step, direction) => {
    switch (direction) {
        case 'decrease': {
            const nextStep = value - step;
            return nextStep >= min ? nextStep : max + 1 - Math.abs(min - nextStep);
        }
        case 'increase': {
            const nextStep = value + step;
            return nextStep <= max ? nextStep : min - 1 + Math.abs(nextStep - max);
        }
    }
};
// TODO: this must be moved into test.utils.ts after BuildFix
/**
 * this is a helper function, that allows to wait for a certain amount of invocations of a
 * method of an object by returning promises in a fluent API interface
 *
 * @param obj
 * @param methodName
 * @param initialCount
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function waitForInvocationsOfMethod(obj, methodName, initialCount = 1) {
    let times = initialCount;
    let resolveOnce, resolveTwice, resolveThrice;
    const oncePromise = new Promise((resolve) => (resolveOnce = resolve));
    const twicePromise = new Promise((resolve) => (resolveTwice = resolve));
    const thricePromise = new Promise((resolve) => (resolveThrice = resolve));
    const resolveOnTimes = () => {
        if (times >= 1) {
            resolveOnce();
        }
        if (times >= 2) {
            resolveTwice();
        }
        if (times >= 3) {
            resolveThrice();
        }
    };
    resolveOnTimes();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore too much meta
    const oldMethod = obj[methodName];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore too much meta
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    obj[methodName] = function (...args) {
        times++;
        const result = oldMethod.apply(obj, args);
        resolveOnTimes();
        return result;
    };
    return {
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        once: () => __awaiter(this, void 0, void 0, function* () { return oncePromise; }),
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        twice: () => __awaiter(this, void 0, void 0, function* () { return twicePromise; }),
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        thrice: () => __awaiter(this, void 0, void 0, function* () { return thricePromise; }),
    };
}
/**
 * helper function to wait for a LitElement to have its render() function been called a certain amount of times
 *
 * @param elm LitElement to wait for
 * @param initialCount is the initial count the elm has already been rendered
 *
 * @returns a fluent API ( twice(), ...) that can be waited for
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function elementHasRendered(elm, initialCount = 1) {
    return waitForInvocationsOfMethod(elm, 'render', initialCount);
}
/**
 * The commaListConverter takes a comma separated string like `one,two,three` and maps that by default to a string array `['one', 'two', 'three']`.
 * It is also possible to pass a converter for example the numberUndefinedConverter so that it maps to a number array.
 *
 * @param {ComplexAttributeConverter} converter converter
 *
 * @returns {ComplexAttributeConverter} comma list converter
 */
export const commaListConverter = (converter = {
    fromAttribute(value) {
        return value;
    },
    toAttribute(value) {
        return String(value);
    },
}) => {
    return {
        fromAttribute(attributeValue) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return (attributeValue === null || attributeValue === void 0 ? void 0 : attributeValue.length) > 0
                ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    attributeValue
                        .split(',')
                        .map((value) => value.trim())
                        .map((value) => converter.fromAttribute(value))
                : [];
        },
        toAttribute(propertyValue) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return (propertyValue === null || propertyValue === void 0 ? void 0 : propertyValue.length) > 0
                ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    String(propertyValue.map((value) => converter.toAttribute(value)))
                : null;
        },
    };
};
/**
 * simple helper, that stops event propagation to be used directly in the template
 * @param event
 */
export function stopEventPropagation(event) {
    event.stopPropagation();
}
// TODO: this should be using some shared featureFlagFramework
/**
 * Helper function, determining, whether BuildThemes should be disabled
 *
 * @returns whether BuildThemes should be disabled
 */
export function shouldBuildThemesBeDisabled() {
    var _a;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const FEATURE_NAME = 'feature-disable-builtin-themes';
    const disableByQueryParam = new URLSearchParams(location.search).has(`zui-${FEATURE_NAME}`);
    const disableByFeatureFlag = JSON.parse((_a = sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.getItem('zui.features')) !== null && _a !== void 0 ? _a : '{}')[FEATURE_NAME] === true;
    const disableBuildThemes = disableByQueryParam || disableByFeatureFlag;
    if (disableBuildThemes) {
        console.warn('Feature: DisableBuildThemes enabled');
    }
    return disableBuildThemes;
}
/**
 * Helper function, determining, whether BuildThemes should be enabled
 *
 * @returns whether BuildThemes should be enabled
 */
export function shouldBuildThemesBeEnabled() {
    var _a;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const FEATURE_NAME = 'feature-enable-builtin-themes';
    const enableByQueryParam = new URLSearchParams(location.search).has(`zui-${FEATURE_NAME}`);
    const enableByFeatureFlag = JSON.parse((_a = sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.getItem('zui.features')) !== null && _a !== void 0 ? _a : '{}')[FEATURE_NAME] === true;
    const enableBuildThemes = enableByQueryParam || enableByFeatureFlag;
    if (enableBuildThemes) {
        if (window['__zuiBuildTimeFeatureHasBeenLogged'] === undefined) {
            console.info('Feature: EnableBuildThemes enabled');
        }
        window['__zuiBuildTimeFeatureHasBeenLogged'] = true;
    }
    else {
        // they are disabled, but have they been enabled before?
        if (window['__zuiBuildTimeFeatureHasBeenLogged'] === true) {
            console.warn('Feature: EnableBuildThemes disabled, although it has been enabled before. This is unsupported!');
        }
    }
    return enableBuildThemes;
}
/**
 * Helper that checks component's dimensions.
 * [Why should we use this helper?]{@link https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_wiki/wikis/DI_ZUi-Web.wiki/35892/Dimension-calculations}
 *
 * @param {HTMLElement} element - an element that will be checked for dimensions.
 * @returns {boolean} - true if offsetHeight and offsetWidth are greater than 0.
 */
export const hasElementAnyDimensions = (element) => element.offsetHeight > 0 && element.offsetWidth > 0;
/**
 * returns the host for a slotElement
 *
 * @param slot that the shadowHost should be returned for
 * @returns the shadowHost
 */
export const getHostBySlot = (slot) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore because a slot always has ShadowHost
    return slot.getRootNode().host;
};
/**
 * Helper decorator, that will only run Lit customElement, if the passed tagName
 * is not already defined
 *
 * @param tagName of the component, that it should be defined for
 * @returns customDecoratorFunction
 */
export const gracefulCustomElement = (tagName) => {
    if (customElements.get(tagName) === undefined) {
        return customElement(tagName);
    }
    else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return function (first) {
            return;
        };
    }
};
/**
 * helper function that waits until a LitElement is stable, i.e. its updates are not tiggering a re-render
 * and it itself is actually defined
 *
 * @param litElement litelement which should be stable
 *
 * @returns a promise if element is stable or not
 */
export const waitForLitElementUpdated = (litElement) => __awaiter(void 0, void 0, void 0, function* () {
    yield customElements.whenDefined(litElement.tagName.toLowerCase());
    // we have to wait, until the element is stable, i.e. updateComplete resolves to true
    let elementIsStable = false;
    do {
        elementIsStable = yield litElement.updateComplete;
    } while (elementIsStable === false);
});
