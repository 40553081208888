/* eslint-disable lit/attribute-value-entities */
import { css, html } from 'lit';
import { segoeSettings, segoeUIFontFaces, notoLatinSettings, notoCJKSettings, fontStyles, fontFaces, SelectableFonts, } from './typography.js';
import { registerStyle } from '../utils/theme.utils.js';
// Noto Sans Japanese
// eslint-disable-next-line @typescript-eslint/naming-convention
const NotoSansJP = html `<link
  href="https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,700&display=swap&subset=japanese"
  rel="stylesheet"
/>`;
// Noto Sans Korean
// eslint-disable-next-line @typescript-eslint/naming-convention
const NotoSansKR = html `<link
  href="https://fonts.googleapis.com/css?family=Noto+Sans+KR:300,400,700&display=swap&subset=korean"
  rel="stylesheet"
/>`;
// Noto Sans Simplified Chinese
// eslint-disable-next-line @typescript-eslint/naming-convention
const NotoSansSC = html `<link
  href="https://fonts.googleapis.com/css?family=Noto+Sans+SC:300,400,700&display=swap&subset=chinese-simplified"
  rel="stylesheet"
/>`;
// Noto Sans Traditional Chinese
// eslint-disable-next-line @typescript-eslint/naming-convention
const NotoSansTC = html `<link
  href="https://fonts.googleapis.com/css?family=Noto+Sans+TC:300,400,700&display=swap&subset=chinese-traditional"
  rel="stylesheet"
/>`;
const asianLanguages = new Map([
    [
        'ja',
        {
            link: NotoSansJP,
            fontFamily: css `Noto Sans JP`,
        },
    ],
    [
        'ko',
        {
            link: NotoSansKR,
            fontFamily: css `Noto Sans KR`,
        },
    ],
    [
        'zh-Hans',
        {
            link: NotoSansSC,
            fontFamily: css `Noto Sans SC`,
        },
    ],
    [
        'zh-Hant',
        {
            link: NotoSansTC,
            fontFamily: css `Noto Sans TC`,
        },
    ],
]);
/**
 * Add google typefaces to application
 *
 * @param {TemplateResult} item Google font link
 * @returns {void}
 */
const registerGoogleFonts = (item) => {
    document.head.append(item.getTemplateElement().content);
};
/**
 * @param {string} font - the font that is to be registered
 */
const registerAsianFonts = (font) => {
    registerGoogleFonts(font.link);
    registerStyle(css `
      :root {
        --zui-font-family: ${font.fontFamily};
      }
    `, 'font');
    registerStyle(notoCJKSettings, 'font');
};
const registerSegoe = () => {
    getFontStyleGroups().forEach((style) => style.remove());
    registerStyle(segoeUIFontFaces, 'font');
    registerStyle(segoeSettings, 'font');
};
const registerNoto = (fontDirectoryURL) => {
    getFontStyleGroups().forEach((style) => style.remove());
    registerStyle(fontFaces(fontDirectoryURL), 'font');
    registerStyle(notoLatinSettings, 'font');
};
export const areFontsAvailable = () => {
    return Array.from(document.head.querySelectorAll('[data-style-group="initial-fonts"]')).length > 0;
};
export const hasPlatformStyleBeenApplied = () => {
    return Array.from(document.head.querySelectorAll('[data-style-group="platform"]')).length > 0;
};
export const getFontStyleGroups = () => {
    return Array.from(document.head.querySelectorAll('[data-style-group=font]'));
};
/**
 * Add typefaces to application based on language and installed fonts
 *
 * @param {string} fontDirectoryURL - URL to downloaded fonts
 * @param {string} requestedFont - request a particular font
 * @returns {Promise} resolves with Promise<void> after font registration
 */
export const registerTypefaces = (fontDirectoryURL = '/public/fonts', requestedFont) => {
    // we abuse registerStyle with its very own tagging attribute to get a global state, that we persist in the DOM
    // for this to happen, this has to be sync... whole font stuff is a mess at best
    registerStyle(fontStyles, 'initial-fonts');
    return (Promise.resolve()
        // Get browser language
        .then(() => asianLanguages.get(document.documentElement.lang))
        // Try to find browser language in map of asian Languages
        .then((asianFont) => (asianFont ? asianFont : Promise.reject(new Error())))
        .then(registerAsianFonts)
        .catch(() => {
        switch (requestedFont) {
            case SelectableFonts.Segoe:
                registerSegoe();
                break;
            case SelectableFonts.Noto:
                registerNoto(fontDirectoryURL);
                break;
            case SelectableFonts.Auto:
            default:
                return loadAvailableFont(fontDirectoryURL);
        }
    }));
};
export const loadAvailableFont = (fontDirectoryURL) => {
    // Creates a Segoe UI font-face definition with local font
    const segoeUICheckFontFace = new FontFace('Segoe UI', "local('Segoe UI')");
    return (segoeUICheckFontFace
        // Checks if Segoe UI can be loaded from local
        .load()
        .then(() => {
        registerSegoe();
    })
        .catch(() => {
        registerNoto(fontDirectoryURL);
    }));
};
