import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DataStorageContainer, DataStorageLabel } from './DataStorageDisplay.styles';
import { DataStorageDisplayContent } from './DataStorageDisplayContent';

const DataStorageDisplay: FC = () => {
  const { t } = useTranslation();
  const dataStorageLabel = t('accountMenu.dataStorage.label');

  return (
    <DataStorageContainer data-test="storage-display">
      <DataStorageLabel>{dataStorageLabel}</DataStorageLabel>
      <DataStorageDisplayContent />
    </DataStorageContainer>
  );
};

export default DataStorageDisplay;
