var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAll, state } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import '../../../types.js';
import { atEnd, atStart, getPaginationDots } from '../utils/pagination.utils.js';
import { PaginationDot } from '../pagination-dot/pagination-dot.component.js';
import { hostStyles } from '../../../host.styles.js';
const style = ":host{--zui-pagination-dot-bar-transition-timing: 200ms;display:inline-flex;align-items:center;vertical-align:top}:host>zui-pagination-dot{--zui-pagination-dot-transition-timing: var(---zui-pagination-dot-bar-transition-timing)}:host>zui-pagination-dot+zui-pagination-dot{margin-left:calc(var(--zui-gu) * 1)}"
const theme = ""
import { GetNotificationEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const paginationDotBarStyles = css `
  ${unsafeCSS(style)}
`;
class PaginationDotBarPaginationDotSelectedEvent extends GetNotificationEventClass('pagination-dot-bar-pagination-dot-selected') {
}
/**
 * The zui-pagination-dot-bar shows a list of zui-pagination-dot's. The number of shown dots is defined by item-count and the selection by selected-item-index.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=62193%3A344226)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=32074%3A520216)
 *
 *
 * @example
 * ```HTML
 * <zui-pagination-dot-bar item-count="7" selected-item-index="2"></zui-pagination-dot-bar>
 * ```
 * @cssprop --zui-pagination-dot-bar-transition-timing - animation duration of the used dots
 * @fires {GetZuiEvent<PaginationDotBar, 'PaginationDotSelectedEvent'>} zui-pagination-dot-bar-pagination-dot-selected - emits when a pagination dot is selected
 */
let PaginationDotBar = class PaginationDotBar extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.PaginationDotSelectedEvent = PaginationDotBarPaginationDotSelectedEvent;
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * the total number of items
         */
        this.itemCount = 0;
        // todo: works for odd numbers but should be checked again when requirements change and max items are configurable
        this._maxItems = 5;
    }
    _handlePaginationDotSlelected({ target }) {
        this.selectedItemIndex = parseInt(target.id, 10);
        this.emitPaginationDotBarPaginationDotSelectedEvent({ value: this.selectedItemIndex });
    }
    // we are directly manipulation the DOM, because LitHTML does cache its DOM node
    // direct DOM manipulation won't trigger a re-render
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('selectedItemIndex')) {
            const selectedDot = Array.from(this._dotsRef).find((dot) => parseInt(dot.id, 10) === this.selectedItemIndex);
            selectedDot === null || selectedDot === void 0 ? void 0 : selectedDot.setAttribute(PaginationDot.internalAnimateSelectedAttribute, '');
        }
    }
    render() {
        return html `${getPaginationDots(this.itemCount, this.selectedItemIndex, this._maxItems).map((paginationDotIndex, index) => {
            const isSelected = paginationDotIndex === this.selectedItemIndex;
            const isFirst = index === 0;
            const isLast = index === this._maxItems - 1;
            const showSmall = (isFirst && !atStart(this.itemCount, this.selectedItemIndex, this._maxItems)) ||
                (isLast && !atEnd(this.itemCount, this.selectedItemIndex, this._maxItems));
            return html `
          <zui-pagination-dot
            id="${paginationDotIndex}"
            emphasis="${isSelected ? 'selected' : 'default'}"
            size="${showSmall ? 's' : 'm'}"
            @click="${this._handlePaginationDotSlelected}"
          >
          </zui-pagination-dot>
        `;
        })}`;
    }
};
PaginationDotBar.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    paginationDotBarStyles,
];
__decorate([
    property({ reflect: true, type: Number, attribute: 'item-count' })
], PaginationDotBar.prototype, "itemCount", void 0);
__decorate([
    property({ reflect: true, type: Number, attribute: 'selected-item-index' })
], PaginationDotBar.prototype, "selectedItemIndex", void 0);
__decorate([
    state()
], PaginationDotBar.prototype, "_maxItems", void 0);
__decorate([
    queryAll('zui-pagination-dot')
], PaginationDotBar.prototype, "_dotsRef", void 0);
PaginationDotBar = __decorate([
    customElement('zui-pagination-dot-bar')
], PaginationDotBar);
export { PaginationDotBar };
