import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ContentString } from '../types';

export function useContent(contentString: ContentString): string {
  // we make sure to never change this - otherwise it violates the rules of hooks
  const content = useMemo(() => contentString, []);

  if (typeof content === 'function') {
    useTranslation();
    return content();
  }

  if (typeof content !== 'string') {
    const [current, setCurrent] = useState('');

    useEffect(() => {
      const sub = content.subscribe(setCurrent);
      return () => sub.unsubscribe();
    }, [content]);

    return current;
  }

  return content;
}
