import { usePiletApi } from 'piral-core';
import { useEffect, useState } from 'react';

import type { RuntimeConfig } from '../types';
import { fetchJSON } from '../utils/fetch-json';
import { useMinimumLoadingTime } from './use-minimum-loading-time';

export type StorageData = {
  maxUsage: number;
  usage: number;
};

export function useStorageData(): [StorageData, boolean] {
  const defaultStorage = { maxUsage: 0, usage: 0 };
  const loadingTimeHasPassed = useMinimumLoadingTime();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [storage, setStorage] = useState<StorageData>(defaultStorage);
  const piral = usePiletApi();

  const storageInfoApiEndpoint =
    piral?.getConfig<RuntimeConfig['endpoints']>('endpoints').tenant.storageInfo;

  useEffect(() => {
    const abortController = new AbortController();
    async function getStorageData() {
      try {
        const storage = await fetchJSON<StorageData>(storageInfoApiEndpoint, {
          signal: abortController.signal,
        });
        setStorage(storage);
        setIsLoading(false);
      } catch (error: unknown) {
        console.log(error);
        setStorage(defaultStorage);
        setIsLoading(false);
      }
    }

    getStorageData();

    return () => {
      abortController.abort();
    };
  }, []);

  return [storage, isLoading || loadingTimeHasPassed];
}
