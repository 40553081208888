var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import { BaseElement, RealBaseElement } from '../../base/base-element.class.js';
import '../../menu/menu-item/menu-item.component.js';
import { hostStyles } from '../../../host.styles.js';
const style = ":host{position:relative;display:flex;width:calc(100% - var(--zui-searchbar-results-menu-margin-left) - var(--zui-searchbar-results-menu-margin-right));min-width:calc(var(--zui-gu) * 45);max-width:calc(var(--zui-gu) * 65)}"
const theme = ""
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const themeDark = ""
const themeLight = ""
// dependencies
import '../../menu/menu/menu.component.js';
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const searchbarResultsMenuStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * The SearchbarResultMenu shows a list of menu items and dividers.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-searchbar-results-menu>
 *   <zui-menu-item value="1">First result</zui-menu-item>
 *   <zui-menu-item value="2">
 *     <zui-icon-common-holy-placeholder slot="icon"></zui-icon-common-holy-placeholder>
 *     Second result
 *   </zui-menu-item>
 *   <zui-menu-divider>Label</zui-menu-divider>
 *   <zui-menu-item value="3">Third result</zui-menu-item>
 * </zui-searchbar-results-menu>
 * ```
 *
 * @slot - default slot for menu items and dividers
 */
let SearchbarResultsMenu = class SearchbarResultsMenu extends DisabledMixin(RealBaseElement) {
    constructor() {
        super(...arguments);
        /**
         * Size of the menu
         */
        this.size = 's';
    }
    _handleSlotchange() {
        this._assignedMenuItems.forEach((menuItem) => (menuItem.disabled = this.disabled));
    }
    render() {
        return html `
      <zui-menu ?disabled="${this.disabled}" size="${this.size}" style="--zui-menu-margin-top: 4px">
        <slot @slotchange="${this._handleSlotchange}"></slot>
      </zui-menu>
    `;
    }
};
SearchbarResultsMenu.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    searchbarResultsMenuStyles,
];
__decorate([
    property({ reflect: true, type: String })
], SearchbarResultsMenu.prototype, "size", void 0);
__decorate([
    queryAssignedElements({ selector: 'zui-menu-item', flatten: true })
], SearchbarResultsMenu.prototype, "_assignedMenuItems", void 0);
SearchbarResultsMenu = __decorate([
    customElement('zui-searchbar-results-menu')
], SearchbarResultsMenu);
export { SearchbarResultsMenu };
