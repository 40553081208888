var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
import 'lit';
import '../../utils/util.types.js';
import { property } from 'lit/decorators.js';
// eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const DisabledMixin = (superClass) => {
    var _ComponentDisabled_instances, _ComponentDisabled_previousTabIndex, _ComponentDisabled_handleClick, _ComponentDisabled_disableInteraction, _ComponentDisabled_enableInteraction;
    class ComponentDisabled extends superClass {
        constructor() {
            super(...arguments);
            _ComponentDisabled_instances.add(this);
            _ComponentDisabled_previousTabIndex.set(this, null);
            /**
             * Defines if component is disabled. When a component is disabled, no click events are emitted.
             */
            this.disabled = false;
        }
        updated(changedProperties) {
            super.updated(changedProperties);
            if (changedProperties.has('disabled')) {
                if (this.disabled) {
                    __classPrivateFieldGet(this, _ComponentDisabled_instances, "m", _ComponentDisabled_disableInteraction).call(this);
                }
                else {
                    __classPrivateFieldGet(this, _ComponentDisabled_instances, "m", _ComponentDisabled_enableInteraction).call(this);
                }
            }
        }
    }
    _ComponentDisabled_previousTabIndex = new WeakMap(), _ComponentDisabled_instances = new WeakSet(), _ComponentDisabled_handleClick = function _ComponentDisabled_handleClick(event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
    }, _ComponentDisabled_disableInteraction = function _ComponentDisabled_disableInteraction() {
        __classPrivateFieldSet(this, _ComponentDisabled_previousTabIndex, this.getAttribute('tabindex'), "f");
        this.removeAttribute('tabindex');
        this.addEventListener('click', __classPrivateFieldGet(this, _ComponentDisabled_instances, "m", _ComponentDisabled_handleClick), { capture: true });
    }, _ComponentDisabled_enableInteraction = function _ComponentDisabled_enableInteraction() {
        if (__classPrivateFieldGet(this, _ComponentDisabled_previousTabIndex, "f") !== null && !this.hasAttribute('tabindex')) {
            this.setAttribute('tabindex', __classPrivateFieldGet(this, _ComponentDisabled_previousTabIndex, "f"));
        }
        this.removeEventListener('click', __classPrivateFieldGet(this, _ComponentDisabled_instances, "m", _ComponentDisabled_handleClick), { capture: true });
    };
    __decorate([
        property({ reflect: true, type: Boolean })
    ], ComponentDisabled.prototype, "disabled", void 0);
    return ComponentDisabled;
};
