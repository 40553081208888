var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var TimePicker_1;
import { css, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { getDefaultLocale, isoDateConverter } from '../../date-picker/utils/date-picker.utils.js';
import { getDayTime, getHourByDayTime, getLocalizedHour, getUpdatedDate, hasDayTime, } from '../utils/time-picker.utils.js';
import { DateTime } from 'luxon';
import { ifDefined } from 'lit/directives/if-defined.js';
import '../time-picker-day-time-toggle/time-picker-day-time-toggle.component.js';
import '../time-picker-cell/time-picker-cell.component.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{display:inline-flex;align-items:center;outline:none}:host([integrated]){flex-direction:column}.time-picker{display:inline-flex;align-items:center;justify-content:center}.day-time-toggle{margin:0 0 0 calc(var(--zui-gu) * 2)}:host([integrated]) .day-time-toggle{margin:0 0 30px 0}"
const theme = ""
import { GetSharedEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const themeDark = ""
const themeLight = ""
const timePickerComponentStyles = css `
  ${unsafeCSS(styles)}
`;
// dependencies
import '../time-picker-cell/time-picker-cell.component.js';
import '../time-picker-cell-divider/time-picker-cell-divider.component.js';
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
class TimePickerChangedEvent extends GetSharedEventClass('time-picker-changed') {
}
class TimePickerInputEvent extends GetSharedEventClass('time-picker-input') {
}
/**
 * The `zui-time-picker` is part of the `zui-textfield-time-picker` form component.
 * It consists of two `zui-time-picker-cell`'s that are responsible for manipulating hours and minutes.
 * The am/pm toggle is available when the `hour-cycle` is set to `h12`. Switching between am and pm adjusts the date value accordingly.
 *
 * @example
 * html```
 * <zui-time-picker default-value="2021-12-01T08:30:00.000Z" hour-cycle="h23"></zui-time-picker>
 * ```
 */
let TimePicker = TimePicker_1 = class TimePicker extends DisabledMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.ChangedEvent = TimePickerChangedEvent;
        this.InputEvent = TimePickerInputEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
})) {
    constructor() {
        super(...arguments);
        /* eslint-enable @typescript-eslint/naming-convention */
        /**
         * the default value is used when there is no value present and an interaction with one of the cell's has happened
         */
        this.defaultValue = new Date();
        /**
         * standalone / integrated
         */
        this.integrated = false;
        /**
         * Full locale code as defined in RFC 5646/BCP 47,
         * i.e. "en-US".
         *
         * By default, the first full locale in [navigator.languages](https://developer.mozilla.org/en-US/docs/Web/API/Navigator/languages) is used.
         */
        this.locale = getDefaultLocale();
        /**
         * step size
         *
         * @private
         */
        this.step = 1;
        /**
         * selected value
         */
        this.value = null;
    }
    get _defaultValueDT() {
        return DateTime.fromJSDate(this.defaultValue);
    }
    /**
     * hour cycle
     *
     * @returns {TimePickerHourCycle} locale dependent or overridden hour cycle
     */
    get hourCycle() {
        if (!this._internalHourCycle) {
            return hasDayTime(this.locale) ? 'h12' : 'h23';
        }
        return this._internalHourCycle;
    }
    set hourCycle(value) {
        const oldValue = this._internalHourCycle;
        this._internalHourCycle = value;
        this.requestUpdate('_internalHourCycle', oldValue);
    }
    get _valueDT() {
        return this.value ? DateTime.fromJSDate(this.value) : undefined;
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.hasAttribute('tabindex')) {
            this.tabIndex = 0;
        }
    }
    get _currentDate() {
        var _a;
        return (_a = this._valueDT) !== null && _a !== void 0 ? _a : this._defaultValueDT;
    }
    get _dayTime() {
        return this._is12HourFormat ? getDayTime(this._currentDate, this.hourCycle) : undefined;
    }
    get _defaultHour() {
        return getLocalizedHour(this._defaultValueDT, this.locale, this.hourCycle);
    }
    get _hour() {
        return this._valueDT ? getLocalizedHour(this._valueDT, this.locale, this.hourCycle) : undefined;
    }
    get _is12HourFormat() {
        return this.hourCycle === 'h12';
    }
    get _literal() {
        return this._currentDate
            .toLocaleParts({ hour: 'numeric', minute: 'numeric' })
            .find(({ type }) => type === 'literal').value;
    }
    _handleTimePickerDayTimeChangedEvent({ detail, }) {
        var _a;
        const hour = getHourByDayTime(this._currentDate.hour, detail.value);
        this.value = this._currentDate.set({ hour }).toJSDate();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this._emitTimePickerInputEvent({ value: this._valueDT.toISO() });
        this._emitTimePickerChangedEvent({ value: (_a = this.value) === null || _a === void 0 ? void 0 : _a.toISOString() });
    }
    _handleTimePickerCellChangedEvent() {
        var _a, _b, _c;
        const hour = (_a = this._timePickerCellHour.value) !== null && _a !== void 0 ? _a : this._timePickerCellHour.defaultValue;
        const minute = (_b = this._timePickerCellMinute.value) !== null && _b !== void 0 ? _b : this._timePickerCellMinute.defaultValue;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.value = getUpdatedDate({ hour, minute }, this._currentDate, this.hourCycle, this._dayTime).toJSDate();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this._emitTimePickerInputEvent({ value: this._valueDT.toISO() });
        this._emitTimePickerChangedEvent({ value: (_c = this.value) === null || _c === void 0 ? void 0 : _c.toISOString() });
    }
    _handleTimePickerCellInputEvent() {
        var _a, _b;
        const hour = (_a = this._timePickerCellHour.value) !== null && _a !== void 0 ? _a : this._timePickerCellHour.defaultValue;
        const minute = (_b = this._timePickerCellMinute.value) !== null && _b !== void 0 ? _b : this._timePickerCellMinute.defaultValue;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const newDate = getUpdatedDate({ hour, minute }, this._currentDate, this.hourCycle, this._dayTime);
        this._emitTimePickerInputEvent({ value: newDate.toISO() });
    }
    _getDayTimeToggleTemplate() {
        return html `
      <div class="day-time-toggle">
        <zui-time-picker-day-time-toggle
          ?integrated="${this.integrated}"
          value="${ifDefined(this._dayTime)}"
          @zui-time-picker-day-time-changed="${this._handleTimePickerDayTimeChangedEvent}"
        ></zui-time-picker-day-time-toggle>
      </div>
    `;
    }
    render() {
        var _a;
        return html `
      ${this._is12HourFormat && this.integrated ? this._getDayTimeToggleTemplate() : nothing}
      <div
        class="time-picker"
        @zui-time-picker-cell-changed="${this._handleTimePickerCellChangedEvent}"
        @zui-time-picker-cell-input="${this._handleTimePickerCellInputEvent}"
      >
        <zui-time-picker-cell
          ?disabled="${this.disabled}"
          default-value="${this._defaultHour}"
          id="hour"
          max="${this._is12HourFormat ? TimePicker_1.MAX_12_HOURS : TimePicker_1.MAX_24_HOURS}"
          min="${this._is12HourFormat ? TimePicker_1.MIN_12_HOURS : TimePicker_1.MIN_24_HOURS}"
          step="${this.step}"
          value="${ifDefined(this._hour)}"
        >
        </zui-time-picker-cell>
        <zui-time-picker-cell-divider
          ?disabled="${this.disabled}"
          literal="${this._literal}"
        ></zui-time-picker-cell-divider>
        <zui-time-picker-cell
          ?disabled="${this.disabled}"
          default-value="${this._defaultValueDT.minute}"
          id="minute"
          max="59"
          min="0"
          step="${this.step}"
          value="${ifDefined((_a = this._valueDT) === null || _a === void 0 ? void 0 : _a.minute)}"
        >
        </zui-time-picker-cell>
        ${this._is12HourFormat && !this.integrated ? this._getDayTimeToggleTemplate() : nothing}
      </div>
    `;
    }
};
TimePicker.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    timePickerComponentStyles,
];
/* eslint-disable @typescript-eslint/naming-convention */
TimePicker.MAX_12_HOURS = 12;
TimePicker.MAX_24_HOURS = 23;
TimePicker.MIN_12_HOURS = 1;
TimePicker.MIN_24_HOURS = 0;
__decorate([
    property({ reflect: true, type: String, attribute: 'default-value', converter: isoDateConverter })
], TimePicker.prototype, "defaultValue", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'hour-cycle' })
], TimePicker.prototype, "hourCycle", null);
__decorate([
    property({ reflect: true, type: Boolean })
], TimePicker.prototype, "integrated", void 0);
__decorate([
    property({ reflect: true, type: String })
], TimePicker.prototype, "locale", void 0);
__decorate([
    property({ reflect: true, type: Number })
], TimePicker.prototype, "step", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], TimePicker.prototype, "value", void 0);
__decorate([
    query('zui-time-picker-cell#hour')
], TimePicker.prototype, "_timePickerCellHour", void 0);
__decorate([
    query('zui-time-picker-cell#minute')
], TimePicker.prototype, "_timePickerCellMinute", void 0);
__decorate([
    state()
], TimePicker.prototype, "_internalHourCycle", void 0);
TimePicker = TimePicker_1 = __decorate([
    customElement('zui-time-picker')
], TimePicker);
export { TimePicker };
