var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{display:inline-block}:host([state=default]){--zui-color-state-dot: var(--zui-color-state-dot-default)}:host([state=error]){--zui-color-state-dot: var(--zui-color-sc-r-110)}:host([state=warning]){--zui-color-state-dot: var(--zui-color-sc-y-100)}:host([state=progress]){--zui-color-state-dot: var(--zui-color-pb-100)}:host([state=ready]){--zui-color-state-dot: var(--zui-color-sc-g-100)}:host([size=s8]){--zui-size-state-dot-circle: 6px}:host([size=s12]){--zui-size-state-dot-circle: 8px}:host([size=s16]){--zui-size-state-dot-circle: 16px}:host([size=s24]){--zui-size-state-dot-circle: 24px}:host([size=s32]){--zui-size-state-dot-circle: 32px}:host([size=s40]){--zui-size-state-dot-circle: 40px}:host([size=s48]){--zui-size-state-dot-circle: 48px}:host([size=s64]){--zui-size-state-dot-circle: 64px}:host([size=s72]){--zui-size-state-dot-circle: 72px}:host([size=s80]){--zui-size-state-dot-circle: 80px}:host([size=s8]){padding:1px}:host([size=s12]){padding:2px}#state-dot{width:var(--zui-size-state-dot-circle);height:var(--zui-size-state-dot-circle);background-color:var(--zui-color-state-dot);border-radius:50%}"
import { IconMixin } from '../../../mixins/icons/icon.mixin.js';
import { RealBaseElement } from '../../base/base-element.class.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-state-dot-default: var(--zui-color-gs-50)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-state-dot-default: var(--zui-color-gs-110)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const stateDotStyles = css `
  ${unsafeCSS(styles)}
`;
/**
 * The state dot is a colored dot with a state-mapped color.
 *
 * It behaves like an icon (sizes, zui-icon tagging attribute, ..).
 *
 * State: default | error | warning | progress | ready
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=23531%3A787239)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=12425%3A0)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-state-dot size="s12" state="default"></zui-state-dot>
 * ```
 */
let StateDot = class StateDot extends IconMixin(RealBaseElement, { defaultSize: 's12' }) {
    constructor() {
        super(...arguments);
        /**
         * Sets the state of the StateDot
         */
        this.state = 'default';
    }
    render() {
        return html `<div id="state-dot"></div>`;
    }
};
StateDot.styles = [
    hostStyles,
    ...(RealBaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    stateDotStyles,
];
__decorate([
    property({ reflect: true, type: String })
], StateDot.prototype, "state", void 0);
StateDot = __decorate([
    customElement('zui-state-dot')
], StateDot);
export { StateDot };
