var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, unsafeCSS, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { RealMenuItemBase } from '../../menu/menu-item-base.class.js';
import { BaseElement } from '../../base/base-element.class.js';
import { DelegateFocusMixin } from '../../../mixins/visual-focus/delegate-focus.mixin.js';
import { hostStyles } from '../../../host.styles.js';
// deps
import '../../button/button.component.js';
import '../../menu/menu-item/menu-item.component.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const styles = ":host{---zui-action-split-button-toggle-button-width: calc(var(--zui-gu) * 2);--zui-button-alignment: flex-start;--zui-button-width: 100%}:host([primary]){width:calc(100% - var(---zui-action-split-button-toggle-button-width))}"
const themeLight = ""
const themeDark = ""
const actionSplitOptionStyles = css `
  ${unsafeCSS(styles)}
`;
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
/**
 * The zui-action-split-option is a slotted element provided to the action-split-button. Depending on the context it is either a zui-button or a zui-menu-item.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=47241%3A370833)
 * - [Styleguide – Desktop](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---5.1?node-id=13558%3A191770)
 *
 * @example
 * HTML:
 *
 * ```html
 *    <zui-action-split-option primary
 *      ><zui-icon-common-holy-placeholder slot="icon"></zui-icon-common-holy-placeholder
 *      >Action</zui-action-split-option
 *    >
 *
 *    <zui-action-split-option
 *      ><zui-icon-common-holy-placeholder slot="icon"></zui-icon-common-holy-placeholder
 *      >Option</zui-action-split-option
 *    >
 *  ```
 *
 * @slot default - Any label to be shown in either the zui-button or zui-menu-item.
 * @slot icon -  Any icon to be shown in either the zui-button or zui-menu-item.
 *
 */
let ActionSplitOption = class ActionSplitOption extends DelegateFocusMixin(RealMenuItemBase) {
    constructor() {
        super(...arguments);
        /**
         * Display mode. If true a zui-button is rendered as the primary action.
         * If false, the default, a zui-menu-item is rendered inside the menu.
         */
        this.primary = false;
        /**
         * the emphasis of either the rendered action button or the menu item
         */
        this.primaryActionEmphasis = 'default';
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('primary')) {
            this.slot = this.primary ? 'action' : '';
        }
    }
    render() {
        return this.primary
            ? html `
          <zui-button icon-position="left" size="s" emphasis=${this.primaryActionEmphasis} ?disabled=${this.disabled}
            ><slot name="icon" slot="icon"></slot><slot></slot
          ></zui-button>
        `
            : html `
          <zui-menu-item emphasis=${this.emphasis} ?disabled=${this.disabled}>
            <slot name="icon" slot="icon"></slot>
            <slot></slot>
          </zui-menu-item>
        `;
    }
};
ActionSplitOption.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    actionSplitOptionStyles,
];
__decorate([
    property({ reflect: true, type: Boolean })
], ActionSplitOption.prototype, "primary", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'primary-action-emphasis' })
], ActionSplitOption.prototype, "primaryActionEmphasis", void 0);
ActionSplitOption = __decorate([
    customElement('zui-action-split-option')
], ActionSplitOption);
export { ActionSplitOption };
