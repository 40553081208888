import { StatusCodes } from 'http-status-codes';
import type { GlobalStateContext } from 'piral-core';

import { trackException } from '../utils/logging';
import { GlobalErrorMessages } from './global-error-messages';
import type { HandleErrorOptions } from './types';

const HTTP_STATUS_CODES = [
  StatusCodes.BAD_REQUEST,
  StatusCodes.UNAUTHORIZED,
  StatusCodes.FORBIDDEN,
  StatusCodes.NOT_FOUND,
  StatusCodes.INTERNAL_SERVER_ERROR,
  StatusCodes.SERVICE_UNAVAILABLE,
];

function showErrorMessage(context: GlobalStateContext, message: string): void {
  const messages = context.readState((state) => state.errorMessages);

  context.dispatch((state) => ({
    ...state,
    errorMessages: [...state.errorMessages, message],
  }));

  if (messages.length === 0) {
    const piral = Object.values(context.apis)[0];

    piral?.showNotification(<GlobalErrorMessages />, {
      type: 'error',
      title: 'Error',
    });
  }
}

export function handleError(
  context: GlobalStateContext,
  { code, caption }: HandleErrorOptions
): void {
  const piral = Object.values(context.apis)[0];

  if (!piral) {
    trackException(
      new Error("Couldn't handle error as the Piral context or Language provider is not ready.")
    );
    return;
  }

  const languageProvider = piral.getLanguageProvider();

  if (code && HTTP_STATUS_CODES.includes(code)) {
    const message = languageProvider.t(`error.http.${code}`, {
      caption,
    });
    showErrorMessage(context, message);
    return;
  }

  if (code && /^4|5/.test(`${code}`)) {
    const fallbackKey = `${String(code)[0]}xx`;
    const message = languageProvider.t(`error.http.${fallbackKey}`, { caption });
    showErrorMessage(context, message);
    return;
  }

  showErrorMessage(context, caption || languageProvider.t(`error.generic-message`));
}

export function clearErrorMessages(context: GlobalStateContext): void {
  context.dispatch((state) => ({
    ...state,
    errorMessages: [],
  }));
}
