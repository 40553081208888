var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, query, queryAssignedElements, state } from 'lit/decorators.js';
import { FormValidationMixin } from '../../../mixins/form-participation/form-validation.mixin.js';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin.js';
import { BaseElement } from '../../base/base-element.class.js';
import '../../../mixins/form-participation/form-participation.types.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { numberUndefinedConverter, stringUndefinedConverter } from '../../../utils/component.utils.js';
import { DelegateFocusMixin } from '../../../mixins/visual-focus/delegate-focus.mixin.js';
import { classMap } from 'lit/directives/class-map.js';
import '../../select/select/select.component.js';
import '../../interactive-icon/interactive-icon.component.js';
// icons
import '@zeiss/zui-icons/dist/components/generated/common-search-search.component.js';
import '@zeiss/zui-icons/dist/components/generated/symbols-close.component.js';
import { hostStyles } from '../../../host.styles.js';
const style = ":host{--zui-color-searchbar-placeholder-text: var(--zui-color-searchbar-placeholder);display:flex;box-sizing:border-box;min-width:calc(var(--zui-gu) * 40);max-width:calc(var(--zui-gu) * 67);height:calc(var(--zui-gu) * 4.5);padding:0 calc(var(--zui-gu) * 2);background-color:var(--zui-color-searchbar-input-background);border-radius:18px}:host([disabled]){pointer-events:none}:host([readonly]),:host([disabled]){opacity:var(--zui-disabled-opacity)}slot[name=filter]{display:inline-flex;height:calc(var(--zui-gu) * 4.5)}.searchbar-input-wrapper{display:flex;flex:1;align-items:center;height:100%}.input-wrapper{display:flex;flex:1}.searchbar-input-wrapper.has-filter .input-wrapper::before{box-sizing:border-box;height:calc(var(--zui-gu) * 3);margin:0 calc(var(--zui-gu) * 1);border-right:1px solid var(--zui-color-searchbar-filter-divider);content:\"\"}#searchbar-input{width:100%;padding:0;color:var(--zui-color-searchbar-query-text);font:var(--zui-typography-body);text-overflow:ellipsis;background:rgba(0,0,0,0);border:0;outline:0;box-shadow:none}#searchbar-input::placeholder{color:var(--zui-color-searchbar-placeholder-text)}:host(:not([readonly])) #searchbar-input:focus-within::placeholder{color:rgba(0,0,0,0)}#close-icon,#search-icon{margin:2px 0 2px 4px}#search-icon{flex-shrink:0;color:var(--zui-color-searchbar-search-icon)}"
import { GetNotificationEventClass, MimickedChangeEvent, MimickedInputEvent, } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-searchbar-search-icon: var(--zui-color-gs-85);--zui-color-searchbar-placeholder: var(--zui-color-gs-80);--zui-color-searchbar-input-background: var(--zui-color-gs-30);--zui-color-searchbar-filter-divider: var(--zui-color-gs-60);--zui-color-searchbar-query-text: var(--zui-color-gs-100)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-searchbar-search-icon: var(--zui-color-gs-70);--zui-color-searchbar-placeholder: var(--zui-color-gs-80);--zui-color-searchbar-input-background: var(--zui-color-gs-115);--zui-color-searchbar-filter-divider: var(--zui-color-gs-120);--zui-color-searchbar-query-text: var(--zui-color-gs-50)}"
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const searchbarInputStyles = css `
  ${unsafeCSS(style)}
`;
class SearchbarInputChangedEvent extends GetNotificationEventClass('searchbar-input-changed') {
}
/**
 * The SearchbarInput is a input field for search queries and allows users to quickly explore a website or application.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-searchbar-input placeholder="Enter your search here..."></zui-searchbar-input>
 * ```
 *
 *  * HTML (SearchbarInput with filter):
 *
 * ```html
 * <zui-searchbar-input placeholder="Enter your search here...">
 *   <zui-select
 *     all-item-label="Everything"
 *     hide-border
 *     multiple
 *     show-all-item
 *     slot="filter"
 *   >
 *     <zui-select-placeholder slot="placeholder" pattern-all="%selection" pattern-many="%selection">
 *       Filter...
 *     </zui-select-placeholder>
 *     <zui-select-option value="books">Books</zui-select-option>
 *     <zui-select-option value="movies">Movies</zui-select-option>
 *   </zui-select>
 * </zui-searchbar-input>
 * ```
 * @slot filter - Slot for a select with select options
 * @fires change - The change event is fired when the value of the searchbar-input has changed
 * @fires input - The input event is fired when the value of the searchbar-input has received input
 * @fires {GetZuiEvent<SearchbarInput, 'ChangedEvent'>} zui-searchbar-input-changed - The searchbar-input-changed event is fired when the value of the searchbar-input has changed
 */
let SearchbarInput = class SearchbarInput extends FormValidationMixin(FormDataHandlingMixin(DelegateFocusMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.ChangedEvent = SearchbarInputChangedEvent;
        this.InputEvent = MimickedInputEvent;
        this.ChangeEvent = MimickedChangeEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
})))) {
    constructor() {
        super();
        /**
         * Sets the placeholder text for the input
         */
        this.placeholder = '';
        /**
         * required
         */
        this.required = false;
        this._hasValue = false;
        this.addValidator({
            type: 'patternMismatch',
            validatesOnProperties: ['pattern'],
            validator: () => !this._searchbarInput.validity.patternMismatch,
        });
        this.addValidator({
            type: 'tooLong',
            validatesOnProperties: ['maxlength'],
            validator: () => this.value !== undefined && this.value.length > 0 && this.maxlength
                ? this.value.length <= this.maxlength
                : true,
        });
        this.addValidator({
            type: 'tooShort',
            validatesOnProperties: ['minlength'],
            validator: () => this.value !== undefined && this.value.length > 0 && this.minlength
                ? this.value.length >= this.minlength
                : true,
        });
        this.addValidator({
            type: 'valueMissing',
            validatesOnProperties: ['required'],
            validator: () => !this._searchbarInput.validity.valueMissing,
        });
    }
    /**
     * value
     *
     * @returns {string | undefined} value
     */
    get value() {
        return this._internalValue;
    }
    set value(value) {
        const oldValue = this.value;
        this._internalValue = value;
        this._hasValue = value !== undefined && value.length !== 0;
        this.requestUpdate('value', oldValue);
    }
    get _hasFilter() {
        return this._assignedFilterMenu.length > 0;
    }
    get _showClearInputIcon() {
        return this._hasValue && !this.disabled && !this.readonly;
    }
    get _showSearchIcon() {
        return !this._hasValue;
    }
    _handleClearInputValue() {
        this.value = '';
        this._searchbarInput.focus();
        this.emitInputEvent();
        this.emitSearchbarInputChangedEvent({ value: this.value });
    }
    // we need this to dispatch a custom event to react on input value changes (searchbar results) and not only already committed changed ones
    _handleSearchbarInputEvent() {
        this._hasValue = this._searchbarInput.value.length > 0;
        this.emitSearchbarInputChangedEvent({ value: this._searchbarInput.value });
    }
    _handleSearchbarInputChangeEvent() {
        this.value = this._searchbarInput.value;
        this.emitChangeEvent();
    }
    _handleSlotChange() {
        this.requestUpdate();
    }
    render() {
        var _a;
        return html `
      <div class="${classMap({ 'searchbar-input-wrapper': true, 'has-filter': this._hasFilter })}">
        <slot name="filter" @slotchange="${this._handleSlotChange}"></slot>
        <div class="input-wrapper">
          <input
            zuiFormControl
            .value=${(_a = this.value) !== null && _a !== void 0 ? _a : ''}
            ?disabled=${this.disabled}
            ?readonly=${this.readonly}
            ?required=${this.required}
            id="searchbar-input"
            maxlength="${ifDefined(this.maxlength)}"
            minlength="${ifDefined(this.minlength)}"
            pattern="${ifDefined(this.pattern)}"
            placeholder="${this.placeholder}"
            type="text"
            @change="${this._handleSearchbarInputChangeEvent}"
            @input=${this._handleSearchbarInputEvent}
          />
        </div>
        ${this._showClearInputIcon
            ? html `
              <zui-interactive-icon id="close-icon" emphasis="subtle">
                <zui-icon-symbols-close size="s12" @click="${this._handleClearInputValue}"></zui-icon-symbols-close>
              </zui-interactive-icon>
            `
            : nothing}
        ${this._showSearchIcon
            ? html `<zui-icon-common-search-search id="search-icon" size="s16"></zui-icon-common-search-search> `
            : nothing}
      </div>
    `;
    }
};
SearchbarInput.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    searchbarInputStyles,
];
__decorate([
    property({ reflect: true, converter: numberUndefinedConverter })
], SearchbarInput.prototype, "maxlength", void 0);
__decorate([
    property({ reflect: true, converter: numberUndefinedConverter })
], SearchbarInput.prototype, "minlength", void 0);
__decorate([
    property({ reflect: true, converter: stringUndefinedConverter })
], SearchbarInput.prototype, "pattern", void 0);
__decorate([
    property({ reflect: true, type: String })
], SearchbarInput.prototype, "placeholder", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], SearchbarInput.prototype, "required", void 0);
__decorate([
    property({ reflect: true, converter: stringUndefinedConverter })
], SearchbarInput.prototype, "value", null);
__decorate([
    query('#searchbar-input')
], SearchbarInput.prototype, "_searchbarInput", void 0);
__decorate([
    queryAssignedElements({ slot: 'filter', selector: 'zui-select', flatten: true })
], SearchbarInput.prototype, "_assignedFilterMenu", void 0);
__decorate([
    state()
], SearchbarInput.prototype, "_hasValue", void 0);
SearchbarInput = __decorate([
    customElement('zui-searchbar-input')
], SearchbarInput);
export { SearchbarInput };
