var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import { hostStyles } from '../../../host.styles.js';
import '../../../types.js';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin.js';
import { BaseElement } from '../../base/base-element.class.js';
import '../../../mixins/form-participation/form-participation.types.js';
import { FormValidationMixin } from '../../../mixins/form-participation/form-validation.mixin.js';
import { DelegateFocusMixin } from '../../../mixins/visual-focus/delegate-focus.mixin.js';
import { emptyStringToNullWrapperConverter, stopEventPropagation, stringUndefinedConverter, } from '../../../utils/component.utils.js';
import '../radio-button/radio-button.component.js';
const style = ":host([readonly]),:host([disabled]){opacity:var(--zui-disabled-opacity);pointer-events:none}"
const theme = ""
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const themeDark = ""
const themeLight = ""
import { MimickedBlurEvent, MimickedChangeEvent, MimickedInputEvent } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const radioButtonGroupStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * The radioButtonGroup is a component to encapsulate multiple radio buttons.
 * Only one of this radio buttons can be chaecked at a given time.
 * If a new radio button gets checked the other checked radio button gets unchecked.
 * It is possible to reset the radio button group to it's default value, by calling the function reset(); on it.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-radio-button-group name="size" value="small">
 * 		<zui-radio-button label="Small" value="small"></zui-radio-button>
 * 		<zui-radio-button label="Medium" value="medium"></zui-radio-button>
 * 		<zui-radio-button label="Large" value="large"></zui-radio-button>
 * </zui-radio-button-group>
 * ```
 *
 * @fires input - Simulates the default `input` event to imitate default behavior
 * @fires change - Simulates the default `change` event to imitate default behavior
 * @fires blur - Simulates the default `blur` event to imitate default behavior
 * @slot default - In this slot should the radio buttons of the radio button group be placed.
 * No other type of HTML elements should be placed inside.
 */
let RadioButtonGroup = class RadioButtonGroup extends FormValidationMixin(FormDataHandlingMixin(DelegateFocusMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.BlurEvent = MimickedBlurEvent;
        this.ChangeEvent = MimickedChangeEvent;
        this.InputEvent = MimickedInputEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
})), {
    formControlSelector: function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore because TS does not know, that we dynamically bind this in a mixin; I would forget it myself!
        return this._selectedRadioButton;
    },
})) {
    constructor() {
        super();
        /**
         * whether the radio button group is required
         */
        this.required = false;
        this._propagateDisabled = false;
        this._isUserInteraction = false;
        this.addValidator({
            type: 'valueMissing',
            validator: () => {
                return this.required === true
                    ? this.value !== undefined &&
                        // if we are required, only a value that can be found within buttons is valid
                        this._assignedRadioButtons.find((radioButton) => radioButton.value === this.value) !== undefined
                    : true;
            },
            validatesOnProperties: ['required'],
        });
    }
    get _selectedRadioButton() {
        var _a;
        return (_a = this._assignedRadioButtons.find((radioButton) => radioButton.value === this.value)) !== null && _a !== void 0 ? _a : null;
    }
    _handleFocusOut({ relatedTarget: focusDestination }) {
        const isInsideClick = this._assignedRadioButtons.some((radioButton) => radioButton === focusDestination);
        if (!isInsideClick) {
            this.emitBlurEvent();
        }
    }
    _inputEventHandler(event) {
        event.stopPropagation();
        if (this.disabled || this.readonly) {
            return;
        }
        if (event.target.tagName.toLowerCase() === 'zui-radio-button') {
            this.value = event.target.value;
            this._isUserInteraction = true;
            this.emitInputEvent();
        }
    }
    _propagateState() {
        this._assignedRadioButtons.forEach((button) => {
            if (this._propagateDisabled) {
                button.disabled = this.disabled;
            }
            if (this.value) {
                button.checked = button.value === this.value;
            }
            else {
                button.checked = false;
            }
        });
    }
    _handleSlotChange() {
        this._propagateState();
        // missing options might turn it invalid
        this.checkValidity();
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        // if disabled is true it should always be propagated
        if (this.disabled || changedProperties.get('disabled')) {
            this._propagateDisabled = true;
        }
        if (changedProperties.has('value') && this._isUserInteraction) {
            this.emitChangeEvent();
            this._isUserInteraction = false;
        }
        this._propagateState();
    }
    render() {
        return html `<div @focusout="${this._handleFocusOut}">
      <slot
        id="radio-button-slot"
        @slotchange="${this._handleSlotChange}"
        @input="${this._inputEventHandler}"
        @change="${stopEventPropagation}"
      ></slot>
    </div>`;
    }
};
RadioButtonGroup.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    radioButtonGroupStyles,
];
__decorate([
    property({ reflect: true, converter: emptyStringToNullWrapperConverter(stringUndefinedConverter) })
], RadioButtonGroup.prototype, "value", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], RadioButtonGroup.prototype, "required", void 0);
__decorate([
    queryAssignedElements({ selector: 'zui-radio-button', flatten: true })
], RadioButtonGroup.prototype, "_assignedRadioButtons", void 0);
RadioButtonGroup = __decorate([
    customElement('zui-radio-button-group')
], RadioButtonGroup);
export { RadioButtonGroup };
