var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import { hostStyles } from '../../host.styles.js';
import { preparePortal, unregisterPortal } from '../../utils/portal.utils.js';
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const theme = ""
const style = ":host{display:none}"
const themeLight = ""
const themeDark = ""
// deps
import '../../components/portal/portal.component.js';
import { BaseElement } from '../../components/base/base-element.class.js';
import { GetSharedEventClass } from '../../contracts/event.classes.js';
import { WithEventsMixin } from '../../mixins/events/events.mixin.js';
import '../../contracts/event.contracts.js';
export const PORTAL_DIRECTIVE_STYLES = css `
  ${unsafeCSS(style)}
`;
class PortalContentProjectedEvent extends GetSharedEventClass('portal-content-projected') {
}
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
/**
 * This directive allows projecting arbitrary html elements into a portal.
 * The targeted portal (or at least a default portal) must exist in the DOM somewhere.
 *
 * @example
 * HTML:
 * ```html
 * ```
 *
 * @fires {GetZuiEvent<PortalDirective, 'ContentProjectedEvent'>} zui-portal-content-projected - fired when content nodes are added to the shadow DOM
 * @slot - The default slot content will be projected into the portal.
 */
let PortalDirective = class PortalDirective extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.ContentProjectedEvent = PortalContentProjectedEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * Clones projected contents instead of moving them.
         * You can not clone nested slots. If this is what you want use the shouldProjectSlots property.
         */
        this.clone = false;
        /**
         * If true the projected Elements contained slots assigned Elements will handled as well
         * This is necessary if nested slots should be displayed properly in the portal.
         * A slot should not be the first child of the portal as it will be flattened.
         * Always wrap your projected content in a non-slot element if you want it to be projected back to its origin after the portal is unmounted.
         */
        this.shouldProjectSlots = false;
    }
    /**
     * Projects the given slot elements contents to a portal.
     * **Only one element (not a text node!) can be projected!**
     */
    projectContents() {
        return __awaiter(this, void 0, void 0, function* () {
            // as the contents are actually moved, the slot change event is triggered
            // twice, but the second time the slot is already missing its contents...
            const [element] = this._projectedElements;
            // weirdly, the slot change listener is triggered even after disconnect,
            // thus we have to skip the content projection once disconnected
            if (element !== undefined && this.isConnected) {
                // check for related portal (and create if not existing yet)
                this._portal = yield preparePortal(this.portal, this);
                // pass through clone option
                this._portal.clone = this.clone;
                // update the portal content with the latest slot contents
                this._portal.showContent(element, this.shouldProjectSlots);
            }
            this._emitPortalContentProjectedEvent();
        });
    }
    // remove portal along with directive
    disconnectedCallback() {
        var _a;
        unregisterPortal((_a = this._portal) === null || _a === void 0 ? void 0 : _a.name);
        super.disconnectedCallback();
    }
    render() {
        return html `<slot @slotchange="${this.projectContents}"></slot>`;
    }
};
PortalDirective.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    PORTAL_DIRECTIVE_STYLES,
];
__decorate([
    property({ reflect: true })
], PortalDirective.prototype, "portal", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], PortalDirective.prototype, "clone", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], PortalDirective.prototype, "shouldProjectSlots", void 0);
__decorate([
    queryAssignedElements({ flatten: true })
], PortalDirective.prototype, "_projectedElements", void 0);
PortalDirective = __decorate([
    customElement('zui-portal-directive')
], PortalDirective);
export { PortalDirective };
