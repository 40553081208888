var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { MenuItemBase } from '../../menu/menu-item-base.class.js';
import { BaseElement } from '../../base/base-element.class.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const styles = ""
const themeDark = ""
const themeLight = ""
// deps
import '../../menu/menu-item/menu-item.component.js';
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const selectOptionStyles = css `
  ${unsafeCSS(styles)}
`;
/**
 * A structural element to be used within the `zui-select` feature component to describe options.
 * It has neither styling nor functional implementations whatsoever and just carryies some attributes  to be
 * used by the feature component to prepare and track the UI and state.
 * This components attributes will always be updated at runtime and can be used to query for current values.
 *
 * @example
 * ```HTML
 * <zui-select-option value="foo">Foo</zui-select-option>
 * <zui-select-option value="bar" disabled>Bar</zui-select-option>
 * <zui-select-option value="baz">Baz</zui-select-option>
 * ```
 *
 * @slot - default slot for an optional label
 * @slot icon - optional slot to pass-in an icon
 */
let SelectOption = class SelectOption extends MenuItemBase {
    constructor() {
        super(...arguments);
        /**
         * ARIA role for this element; defaults to 'option' if not explicitly set by author
         */
        this.role = 'option';
    }
    willUpdate(changedProperties) {
        super.willUpdate(changedProperties);
        if (!this.id) {
            // transform value to id
            this.id = this.value.replace(/\s/g, '').toLocaleLowerCase();
        }
    }
    render() {
        return html `
      <zui-menu-item
        ?disabled=${this.disabled}
        ?disableTruncation=${this.disableTruncation}
        ?selectable=${this.selectable}
        ?selected=${this.selected}
        .emphasis=${this.emphasis}
        .size=${this.size}
        .value=${this.value}
      >
        <slot name="icon" slot="icon"></slot>
        <slot></slot>
      </zui-menu-item>
    `;
    }
};
SelectOption.styles = [
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    selectOptionStyles,
];
__decorate([
    property({ reflect: true })
], SelectOption.prototype, "role", void 0);
SelectOption = __decorate([
    customElement('zui-select-option')
], SelectOption);
export { SelectOption };
