var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import 'lit';
import { property } from 'lit/decorators.js';
import '../../utils/util.types.js';
import './icon.types.js';
import './icon.interfaces.js';
// eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const IconMixin = (superClass, { defaultSize } = { defaultSize: 's16' }) => {
    class IconComponentClass extends superClass {
        constructor() {
            super(...arguments);
            /**
             * tagging property used to use `*[zui-icon]` as a querySelector for zuiIcons
             */
            this.isIcon = true;
            /**
             * The size of the Icon
             */
            this.size = defaultSize;
        }
    }
    __decorate([
        property({ reflect: true, attribute: 'zui-icon', type: Boolean })
    ], IconComponentClass.prototype, "isIcon", void 0);
    __decorate([
        property({ reflect: true, type: String })
    ], IconComponentClass.prototype, "size", void 0);
    return IconComponentClass;
};
