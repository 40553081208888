var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { hostStyles } from '../../../host.styles.js';
import '../../../types.js';
import '../../button/button.component.js';
const style = ":host{display:flex;flex:1 1 0;min-width:0}zui-button{flex:1 1 0;min-width:0}zui-button::part(button){width:100%;min-width:0;max-width:100%}:host([emphasis=default]:not(:hover)) zui-button::part(button){background-color:rgba(0,0,0,0);border:rgba(0,0,0,0)}:host([emphasis=default][disabled]) zui-button::part(button){background-color:rgba(0,0,0,0);border:rgba(0,0,0,0)}"
const theme = ""
import { GetNotificationEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { BaseElement } from '../../base/base-element.class.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const toggleBarButtonStyles = css `
  ${unsafeCSS(style)}
`;
class ToggleBarButtonSelectedEvent extends GetNotificationEventClass('toggle-bar-button-selected') {
}
/**
 * The zui-toggle-bar-button is a wrapper for the zui-button to add toggle bar specific styling.
 * It is not intended for single use but just as a part of the zui-toggle-bar.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.2?node-id=13009%3A2767)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=23651%3A480653)
 *
 * @example
 * HTML:
 *
 * ```html
 *   <zui-toggle-bar-button value="one">One</zui-toggle-bar-button>
 * ```
 *
 * @slot default - This is the default slot. It's an innerHtml of the button-element
 * @slot icon - Here you can insert your own icon
 *
 * @fires {GetZuiEvent<ToggleBarButton, 'SelectedEvent'>} zui-toggle-bar-button-selected - emits when the toggle bar button is selected
 */
let ToggleBarButton = class ToggleBarButton extends DisabledMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.SelectedEvent = ToggleBarButtonSelectedEvent;
    }
})) {
    constructor() {
        super(...arguments);
        /**
         * constitutes whether the button is non-interactive
         */
        this.nonInteractive = false;
        /**
         * Defines one of three possible emphasis states (default/highlight/primary-highlight).
         */
        this.emphasis = 'default';
        /**
         * Defines one of two possible sizes (s/l);
         */
        this.size = 's';
        /**
         * readonly state
         */
        this.readonly = false;
    }
    _handleClick(event) {
        this.emitToggleBarButtonSelectedEvent({ value: this.value });
    }
    render() {
        return html `
      <zui-button
        ?disabled="${this.disabled}"
        ?readonly="${this.readonly}"
        ?non-interactive="${this.nonInteractive}"
        emphasis="${this.emphasis}"
        size="${this.size}"
        type="button"
        @click="${this._handleClick}"
        ><slot></slot><slot name="icon" slot="icon"></slot
      ></zui-button>
    `;
    }
};
ToggleBarButton.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    toggleBarButtonStyles,
];
__decorate([
    property({ reflect: true, attribute: 'non-interactive', type: Boolean })
], ToggleBarButton.prototype, "nonInteractive", void 0);
__decorate([
    property({ reflect: true, type: String })
], ToggleBarButton.prototype, "emphasis", void 0);
__decorate([
    property({ reflect: true, type: String })
], ToggleBarButton.prototype, "size", void 0);
__decorate([
    property({ reflect: true, type: String })
], ToggleBarButton.prototype, "value", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], ToggleBarButton.prototype, "readonly", void 0);
ToggleBarButton = __decorate([
    customElement('zui-toggle-bar-button')
], ToggleBarButton);
export { ToggleBarButton };
