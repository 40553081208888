import { useGlobalStateContext } from 'piral-core';
import { type FC, useCallback } from 'react';

import { useContent } from '../../hooks/use-content';
import { useNavAction } from '../../hooks/use-nav-action';
import type { BellNotification } from '../../plugins/bell-notification/types';
import {
  NotificationItem,
  NotificationItemAction as Action,
  NotificationItemContent as Content,
  NotificationItemDescription as Description,
  NotificationItemFooter as Footer,
  NotificationItemOrigin as Origin,
  NotificationItemText as Text,
  NotificationItemTitle as Title,
  NotificationItemUnreadDot as UnreadDot,
} from './BellNotificationElement.styles';
import { BellNotificationElementTime as Time } from './BellNotificationElementTime';
import { markSingleNotificationRead } from './mark-notification-read.utils';
import { NotificationSeverityIcon as SeverityIcon } from './NotificationSeverityIcon';

interface BellNotificationElementProps {
  notification: BellNotification;
}

export const BellNotificationElement: FC<BellNotificationElementProps> = ({ notification }) => {
  const action = useNavAction(notification.action?.href, notification.action?.callback);
  const actionTitle = useContent(notification.action?.title || 'Click');
  const context = useGlobalStateContext();

  const onNotificationClick = useCallback(() => {
    if (notification.fromCurrentTab) {
      markSingleNotificationRead(context, notification.id);
    }
  }, [notification]);

  return (
    <NotificationItem
      readonly
      canChange={notification.fromCurrentTab}
      onClick={onNotificationClick}
    >
      <Content data-test="bell-notification-item">
        {notification.state === 'unread' && <UnreadDot size="s8" state="progress" />}

        <SeverityIcon severity={notification.severity} />
        <Text data-test="bell-notification-text">
          <Origin tooltipTrigger={['hover']}>{useContent(notification.origin)}</Origin>

          <Title tooltipTrigger={['hover']}>{useContent(notification.title)}</Title>

          <Description tooltipTrigger={['hover']}>
            {useContent(notification.description)}
          </Description>

          <Footer>
            <Time timestamp={notification.timestamp} />

            {notification.action && (
              <Action
                text={actionTitle}
                onClick={() => {
                  action();
                  closeBellNotificationPopup();
                }}
              />
            )}
          </Footer>
        </Text>
      </Content>
    </NotificationItem>
  );
};

function closeBellNotificationPopup() {
  const evt = new Event('bellNotificationActionClicked', {
    bubbles: true,
    composed: true,
  });
  window.document.dispatchEvent(evt);
}
