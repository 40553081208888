import { useObservable } from '@arnold/store/react';
import { usePiletApi } from 'piral-core';
import type { FC } from 'react';

import { ModalContainer } from '../shell/ModalContainer';
import { InactivityDialog } from './InactivityDialog';

export const InactivityOverlay: FC = () => {
  const piral = usePiletApi();
  const [open] = useObservable(piral.inactivityEvents$);

  if (open) {
    return (
      <ModalContainer open>
        <InactivityDialog timeInMsBeforeForcedLogout={piral.inactivityModalDuration} />
      </ModalContainer>
    );
  }

  return null;
};
