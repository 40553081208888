var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
/* eslint-disable @typescript-eslint/member-ordering */
import 'lit';
import { property } from 'lit/decorators.js';
import { isDefined } from '../../utils/component.utils.js';
import '../../utils/util.types.js';
import { getParentElementBySelector } from '../../utils/mixin.utils.js';
import './form-participation.interfaces.js';
import { DisabledMixin } from '../disabled/disabled.mixin.js';
const isFormDataEventSupported = Boolean('FormDataEvent' in window);
/**
 * This mixin is used to add form-data-handling capabilities to a component.
 * The component has to implement `FormEnabledElement`.
 *
 * Example:
 * ```
 * class MyFormComponent extends FormDataHandlingMixin(RealBaseElement) implements FormEnabledElement {
 *   @property({reflect: true})
 *   value: string;
 *
 *   render() {
 *     //...
 *   }
 * }
 *
 * ```
 *
 *  @param superClass the superClass for the component. Most likely this is `RealBaseElement`.
 *  @param options to customize behavior
 *  @param options.disableSubmitOnEnter to disable submission of parent form if `Enter` is pressed
 *  @returns a constructor for the component with form-participation enabled.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const FormDataHandlingMixin = (superClass, { disableSubmitOnEnter = false, formControlSelector = '[zuiFormControl]' } = {}) => {
    var _FormEnabledComponentClass_instances, _FormEnabledComponentClass_formControl_get, _FormEnabledComponentClass_resetPropertyValue, _FormEnabledComponentClass_lastResetValueType, _FormEnabledComponentClass_hasResetBeenInitialized, _FormEnabledComponentClass_initialValue, _FormEnabledComponentClass_shouldSyncHiddenInput_get, _FormEnabledComponentClass_hiddenInput_get, _FormEnabledComponentClass_addHiddenInput, _FormEnabledComponentClass_syncHiddenInput, _FormEnabledComponentClass_deleteInput, _FormEnabledComponentClass_handleKey, _FormEnabledComponentClass_handleFormReset, _FormEnabledComponentClass_handleFormSubmit, _FormEnabledComponentClass_handleFormData;
    class FormEnabledComponentClass extends DisabledMixin(superClass) {
        constructor() {
            super(...arguments);
            _FormEnabledComponentClass_instances.add(this);
            // eslint-disable-next-line jsdoc/require-jsdoc
            this.readonly = false;
            _FormEnabledComponentClass_resetPropertyValue.set(this, void 0);
            _FormEnabledComponentClass_lastResetValueType.set(this, 'property');
            _FormEnabledComponentClass_hasResetBeenInitialized.set(this, false);
            _FormEnabledComponentClass_initialValue.set(this, void 0);
            _FormEnabledComponentClass_handleKey.set(this, (ev) => {
                var _a;
                // Enter will submit the _hostForm
                if (ev.code === 'Enter' || ev.code === 'NumpadEnter') {
                    ev.preventDefault();
                    (_a = this.hostForm) === null || _a === void 0 ? void 0 : _a.requestSubmit();
                }
            });
            _FormEnabledComponentClass_handleFormReset.set(this, () => {
                this.formResetCallback();
            });
            _FormEnabledComponentClass_handleFormSubmit.set(this, (subEvent) => {
                if (!this.disabled) {
                    this.formSubmitCallback(subEvent);
                }
            });
            _FormEnabledComponentClass_handleFormData.set(this, (formData) => {
                this.formDataCallback(formData);
            });
        }
        get formControl() {
            if (typeof formControlSelector === 'string') {
                return __classPrivateFieldGet(this, _FormEnabledComponentClass_instances, "a", _FormEnabledComponentClass_formControl_get);
            }
            else {
                // formControlSelector is a selector function, that gets this bound to allow the selectorFn itself
                return formControlSelector.bind(this)();
            }
        }
        get disableSubmitOnEnter() {
            if (disableSubmitOnEnter instanceof Function) {
                return disableSubmitOnEnter.bind(this)();
            }
            else {
                return disableSubmitOnEnter;
            }
        }
        // we do not this.requestUpdate() because we cannot reliably
        // determine what kind of serialization is needed for the value
        // this could only be fixed, by making the converter for the value
        // a param of the mixin...
        // we only use the property decorator to be later able to "re-use"
        // attributeChangedCallback ...
        get resetValue() {
            return __classPrivateFieldGet(this, _FormEnabledComponentClass_resetPropertyValue, "f");
        }
        set resetValue(val) {
            __classPrivateFieldSet(this, _FormEnabledComponentClass_hasResetBeenInitialized, true, "f");
            __classPrivateFieldSet(this, _FormEnabledComponentClass_lastResetValueType, 'property', "f");
            __classPrivateFieldSet(this, _FormEnabledComponentClass_resetPropertyValue, val, "f");
        }
        reset() {
            this.formResetCallback();
        }
        formResetCallback() {
            if (!__classPrivateFieldGet(this, _FormEnabledComponentClass_hasResetBeenInitialized, "f")) {
                this['value'] = __classPrivateFieldGet(this, _FormEnabledComponentClass_initialValue, "f");
            }
            else {
                if (__classPrivateFieldGet(this, _FormEnabledComponentClass_lastResetValueType, "f") === 'property') {
                    this['value'] = this.resetValue;
                }
                else {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    this.setAttribute('value', this.getAttribute('reset-value'));
                }
            }
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        formDisabledCallback() { }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        formSubmitCallback(subEvent) { }
        formDataCallback({ formData }) {
            if (this.name && !this.disabled) {
                // FIXME: this looks like a potential bug
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                formData.append(this.name, this.getAttribute('value'));
            }
        }
        attributeChangedCallback(name, oldValue, newValue) {
            if (name === 'reset-value') {
                __classPrivateFieldSet(this, _FormEnabledComponentClass_hasResetBeenInitialized, true, "f");
                __classPrivateFieldSet(this, _FormEnabledComponentClass_lastResetValueType, 'attribute', "f");
            }
            else {
                super.attributeChangedCallback(name, oldValue, newValue);
            }
        }
        updated(changedProperties) {
            var _a, _b;
            super.updated(changedProperties);
            // toggle pristine if value change from undef -> sth
            if (__classPrivateFieldGet(this, _FormEnabledComponentClass_instances, "a", _FormEnabledComponentClass_shouldSyncHiddenInput_get)) {
                // changes of disabled + value need to be reflected to hidden input
                if (changedProperties.has('value') || changedProperties.has('disabled')) {
                    __classPrivateFieldGet(this, _FormEnabledComponentClass_instances, "m", _FormEnabledComponentClass_syncHiddenInput).call(this);
                }
                // name change, needs some special treatment, because the old hidden input
                // must be potentially be deleted
                if (changedProperties.has('name')) {
                    const oldName = changedProperties.get('name');
                    __classPrivateFieldGet(this, _FormEnabledComponentClass_instances, "m", _FormEnabledComponentClass_deleteInput).call(this, oldName);
                    __classPrivateFieldGet(this, _FormEnabledComponentClass_instances, "m", _FormEnabledComponentClass_syncHiddenInput).call(this);
                }
            }
            if (!this.disableSubmitOnEnter) {
                // for each update, remove eventlisteners and re-attach, because the template might
                // have re-rendered
                (_a = this.formControl) === null || _a === void 0 ? void 0 : _a.removeEventListener('keydown', __classPrivateFieldGet(this, _FormEnabledComponentClass_handleKey, "f"));
                (_b = this.formControl) === null || _b === void 0 ? void 0 : _b.addEventListener('keydown', __classPrivateFieldGet(this, _FormEnabledComponentClass_handleKey, "f"));
            }
            if (changedProperties.has('disabled') && this.disabled) {
                this.formDisabledCallback();
            }
        }
        // on connection to the DOM, find parent form
        connectedCallback() {
            super.connectedCallback();
            this.hostForm = getParentElementBySelector(this, 'form');
            // retain initial value
            __classPrivateFieldSet(this, _FormEnabledComponentClass_initialValue, this['value'], "f");
            if (this.hostForm) {
                // TODO: this should be most likely in the capture phase as well
                this.hostForm.addEventListener('reset', __classPrivateFieldGet(this, _FormEnabledComponentClass_handleFormReset, "f"));
                this.hostForm.addEventListener('submit', __classPrivateFieldGet(this, _FormEnabledComponentClass_handleFormSubmit, "f"), { capture: true });
                if (isFormDataEventSupported) {
                    this.hostForm.addEventListener('formdata', __classPrivateFieldGet(this, _FormEnabledComponentClass_handleFormData, "f"));
                }
            }
        }
        disconnectedCallback() {
            // remove event listeners from parent form
            if (this.hostForm) {
                this.hostForm.removeEventListener('reset', __classPrivateFieldGet(this, _FormEnabledComponentClass_handleFormReset, "f"));
                this.hostForm.removeEventListener('submit', __classPrivateFieldGet(this, _FormEnabledComponentClass_handleFormSubmit, "f"), { capture: true });
                if (isFormDataEventSupported) {
                    this.hostForm.removeEventListener('formdata', __classPrivateFieldGet(this, _FormEnabledComponentClass_handleFormData, "f"));
                }
                if (__classPrivateFieldGet(this, _FormEnabledComponentClass_instances, "a", _FormEnabledComponentClass_shouldSyncHiddenInput_get)) {
                    __classPrivateFieldGet(this, _FormEnabledComponentClass_instances, "m", _FormEnabledComponentClass_deleteInput).call(this, this['name']);
                }
            }
            super.disconnectedCallback();
        }
    }
    _FormEnabledComponentClass_resetPropertyValue = new WeakMap(), _FormEnabledComponentClass_lastResetValueType = new WeakMap(), _FormEnabledComponentClass_hasResetBeenInitialized = new WeakMap(), _FormEnabledComponentClass_initialValue = new WeakMap(), _FormEnabledComponentClass_handleKey = new WeakMap(), _FormEnabledComponentClass_handleFormReset = new WeakMap(), _FormEnabledComponentClass_handleFormSubmit = new WeakMap(), _FormEnabledComponentClass_handleFormData = new WeakMap(), _FormEnabledComponentClass_instances = new WeakSet(), _FormEnabledComponentClass_formControl_get = function _FormEnabledComponentClass_formControl_get() {
        const selector = typeof formControlSelector === 'string' ? formControlSelector : '_';
        return this.renderRoot.querySelector(selector);
    }, _FormEnabledComponentClass_shouldSyncHiddenInput_get = function _FormEnabledComponentClass_shouldSyncHiddenInput_get() {
        return isDefined(this.hostForm) && isDefined(this['name']) && !isFormDataEventSupported;
    }, _FormEnabledComponentClass_hiddenInput_get = function _FormEnabledComponentClass_hiddenInput_get() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const hasHiddenInput = this.hostForm.querySelector(`input[name=${this['name']}]`);
        return hasHiddenInput ? hasHiddenInput : __classPrivateFieldGet(this, _FormEnabledComponentClass_instances, "m", _FormEnabledComponentClass_addHiddenInput).call(this);
    }, _FormEnabledComponentClass_addHiddenInput = function _FormEnabledComponentClass_addHiddenInput() {
        const inputElement = document.createElement('input');
        inputElement.type = 'hidden';
        inputElement.name = this['name'];
        this.hostForm.append(inputElement);
        return inputElement;
    }, _FormEnabledComponentClass_syncHiddenInput = function _FormEnabledComponentClass_syncHiddenInput() {
        const inputRef = __classPrivateFieldGet(this, _FormEnabledComponentClass_instances, "a", _FormEnabledComponentClass_hiddenInput_get);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore because TS does not know DOM
        inputRef.value = this.getAttribute('value');
        inputRef.disabled = this.disabled;
    }, _FormEnabledComponentClass_deleteInput = function _FormEnabledComponentClass_deleteInput(name) {
        var _a;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (_a = this.hostForm.querySelector(`input[name=${name}]`)) === null || _a === void 0 ? void 0 : _a.remove();
    };
    __decorate([
        property({ reflect: true, type: Boolean })
    ], FormEnabledComponentClass.prototype, "readonly", void 0);
    __decorate([
        property({ reflect: true, type: String })
    ], FormEnabledComponentClass.prototype, "name", void 0);
    __decorate([
        property({ reflect: false, type: String, attribute: 'reset-value' })
    ], FormEnabledComponentClass.prototype, "resetValue", null);
    return FormEnabledComponentClass;
};
