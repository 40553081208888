/**
 * generates a pseudo client rect to be used as tooltip relative target
 *
 * @param x {int}
 * @param y {int}
 * @returns {ClientRectObject} pseudo client rect
 */
export const generatePseudoClientRect = (x, y) => ({
    x,
    y,
    width: 0,
    height: 0,
    top: y,
    right: x,
    bottom: y,
    left: x,
});
/**
 * maps artificial trigger events used for a convenient API to "real" DOM event names to be used
 *
 * @param triggerEvents {TooltipTrigger[]} artificial event names
 * @param defaultEvents {string[]} events to be used by default
 * @returns {string[]} native event names
 */
export const mapTriggerEvents = (triggerEvents = [], defaultEvents = []) => triggerEvents.reduce((events, trigger) => {
    switch (trigger) {
        case 'click':
            return events.concat(['click', 'mouseleave']);
        case 'focus':
            return events.concat(['blur', 'focus']);
        case 'hover':
            return events.concat(['mouseenter', 'mousemove', 'mouseleave']);
        default:
            // this exhaustiveness check helps us to guard each possible case of the `trigger` discriminant
            // union type and will cause a typescript error (TS2322) if something remains uncovered
            // https://stackoverflow.com/a/39419171/1146207
            // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-case-declarations, no-underscore-dangle, id-length
            const _ = trigger;
            return events;
    }
}, defaultEvents);
