import {
  type ComponentType,
  type FunctionComponent,
  type LazyExoticComponent,
  Suspense,
} from 'react';

import { LoadingIndicator } from '../components/shell/LoadingIndicator';

type GetProps<T> = T extends ComponentType<infer R> ? R : any;

export function withSuspense<T extends ComponentType<any>>(
  Component: LazyExoticComponent<T>,
  fallback: JSX.Element | null = <LoadingIndicator />
): FunctionComponent<GetProps<T>> {
  const SuspendedComponent: FunctionComponent<GetProps<T>> = (props) => (
    <Suspense fallback={fallback}>
      <Component {...props} />
    </Suspense>
  );
  SuspendedComponent.displayName = 'SuspendedComponent';
  return SuspendedComponent;
}
