import { css, unsafeCSS } from 'lit';
import { colorsRawStyle } from './colors.js';
import { bindCssBodyToSelector } from '../utils/theme.utils.js';
const baseLegacyRootThemeCSS = "zui-dialog-box,zui-interaction-dialog,zui-scrollable-directive,zui-scrollbar{box-sizing:initial}[zui-element]{font:var(--zui-typography-body)}[zui-element]::selection,[zui-element] ::selection{background-color:rgba(var(--zui-color-ac-db-110-rgb), var(--zui-selection-opacity))}zui-chat-item[emphasis=inbound]::selection,zui-chat-item[emphasis=inbound] ::selection{color:var(--zui-color-chat-item-inbound-text);background-color:var(--zui-color-chat-item-inbound-bg-selection)}zui-chat-item[emphasis=outbound]::selection,zui-chat-item[emphasis=outbound] ::selection{color:var(--zui-color-chat-item-outbound-text);background-color:var(--zui-color-chat-item-outbound-bg-selection)}:root{--zui-icon-size-8: 8px;--zui-icon-size-12: 12px;--zui-icon-size-16: 16px;--zui-icon-size-24: 24px;--zui-icon-size-32: 32px;--zui-icon-size-40: 40px;--zui-icon-size-48: 48px;--zui-icon-size-64: 64px;--zui-icon-size-72: 72px;--zui-icon-size-80: 80px;--zui-gu: 8px;--zui-space-extra-small: calc(var(--zui-gu) * 0.75);--zui-space-small: var(--zui-gu);--zui-space-medium: calc(var(--zui-gu) * 2);--zui-space-large: calc(var(--zui-gu) * 4);--zui-disabled-opacity: 0.5;--zui-selection-opacity: 0.3;--zui-line-strength-thin: 1px;--zui-line-strength-medium: calc(var(--zui-gu) / 4);--zui-line-strength-thick: calc(var(--zui-gu) / 2);--zui-border-radius-default: 3px}*{box-sizing:border-box}html[theme]{background:var(--zui-color-app-bg)}"
const baseLegacyComponentThemeCSS = ":host([theme=light]),:host([theme=dark]),:host([theme=translucent]){--zui-icon-size-8: 8px;--zui-icon-size-12: 12px;--zui-icon-size-16: 16px;--zui-icon-size-24: 24px;--zui-icon-size-32: 32px;--zui-icon-size-40: 40px;--zui-icon-size-48: 48px;--zui-icon-size-64: 64px;--zui-icon-size-72: 72px;--zui-icon-size-80: 80px;--zui-gu: 8px;--zui-space-extra-small: calc(var(--zui-gu) * 0.75);--zui-space-small: var(--zui-gu);--zui-space-medium: calc(var(--zui-gu) * 2);--zui-space-large: calc(var(--zui-gu) * 4);--zui-disabled-opacity: 0.5;--zui-selection-opacity: 0.3;--zui-line-strength-thin: 1px;--zui-line-strength-medium: calc(var(--zui-gu) / 4);--zui-line-strength-thick: calc(var(--zui-gu) / 2);--zui-border-radius-default: 3px}"
export const themeBaseLegacy = css `
  ${unsafeCSS(bindCssBodyToSelector(colorsRawStyle, ':root'))}
  ${unsafeCSS(baseLegacyRootThemeCSS)}
`;
export const themeBaseLegacyStyle = themeBaseLegacy;
export const themeBaseLegacyComponentStyle = css `
  ${unsafeCSS(bindCssBodyToSelector(colorsRawStyle, ':host([theme="light"]), :host([theme="dark"]), :host([theme="translucent"])'))}
  ${unsafeCSS(baseLegacyComponentThemeCSS)}
`;
