// we disable func-style, because we'd otherwise have to export const funky = function funky() ... :(
/* eslint-disable func-style */
import './util.types.js';
/**
 * gets the HostElement from a child
 *
 * @param {HTMLElement} child element that the function is looking from
 * @param {boolean} checkShadowDomRecursively by default, to detect shadow roots up the tree
 * @returns {HTMLElement} the HostElement
 */
export function getHostElementFromChild(child, checkShadowDomRecursively = true) {
    // TODO: add safeguard
    // either we are in a shadowDom and need to "escape" it :) or we are not, then,
    // our host is simply the parent...
    if (checkShadowDomRecursively ? isInShadow(child) : isInDirectShadow(child)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return child.parentNode.getRootNode().host;
    }
    else {
        return child.parentNode;
    }
}
/**
 * Injects a style directly on the HostElement from the child
 *
 * @param {HTMLElement} child element that the function is looking from
 * @param {string} style to be injected
 */
export function injectStyleOnHostFromChild(child, style) {
    const hostElement = getHostElementFromChild(child);
    const styleElement = document.createElement('style');
    // while this seems odd, otherwise the style tag itself will be rendered...
    styleElement.setAttribute('style', 'display: none');
    styleElement.innerHTML = style.replace(/\n/g, ' ');
    hostElement.append(styleElement);
}
/**
 * Injects a style on the HostElement's ShadowDOM and thus allows for modifying ShadowDOM
 *
 * @param {HTMLElement} child element that the function is looking from
 * @param {string} style to be injected
 * @param {string} guid optional guid, that is used to "find" the style and re-apply it, after host's template changes
 */
export function injectStyleOnHostShadowFromChild(child, style, guid = 'style-1') {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = style.replace(/\n/g, ' ');
    addElementToHostFromChild(child, styleElement, guid);
}
/**
 * Adds a class to the HostElement via classList
 *
 * @param {HTMLElement} child element that the function is looking from
 * @param {string} className of the class to be added
 */
export function addClassToHostFromChild(child, className) {
    const hostElement = getHostElementFromChild(child);
    hostElement.classList.add(className);
}
/**
 * Adds a named slot to the HostElement's ShadowDOM
 *
 * @param {HTMLElement} child element that the function is looking from
 * @param {string} slotName of the named host
 */
export function addSlotToHostFromChild(child, slotName) {
    const slotElement = document.createElement('slot');
    slotElement.setAttribute('name', slotName);
    addElementToHostFromChild(child, slotElement, slotName);
}
/**
 * this adds non-invasiveley any element to a given elemnts host regardless if it has ShadowDOM or not
 *
 * @param {HTMLElement} child element that the function is looking from
 * @param {HTMLElement} element to be added
 * @param {string} guid that is used to "find" the element and re-apply it, after host's template changes
 */
function addElementToHostFromChild(child, element, guid) {
    // we are non-invasively adding an element to our host's shadowDom; for this to happen reliably,
    // we need to watch its shadowDom and after every change, append our element...
    const hostElement = getHostElementFromChild(child);
    const hostRoot = hasShadow(hostElement) ? hostElement.shadowRoot : hostElement;
    const addElement = () => {
        const clone = element.cloneNode(true);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        clone.id = guid;
        hostRoot.append(clone);
    };
    // then register mutationObserver on host for every childChange and add accordingly
    const shadowDomObserver = new MutationObserver((mutationList) => {
        // whenever something changed; add our element... HOWEVER ignore ourselves being added...
        const areWeAddedOurselves = mutationList.some((mutation) => Array.from(mutation.addedNodes)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .some((addedNode) => addedNode.tagName === element.tagName && addedNode.getAttribute('id') === guid));
        // and don't forget, we might be already added...
        const alreadyAdded = hostRoot.querySelector(`#${guid}`) !== null;
        if (!areWeAddedOurselves && !alreadyAdded) {
            addElement();
        }
    });
    shadowDomObserver.observe(hostRoot, { childList: true });
    // initially we will be added for sure :)
    addElement();
}
/**
 * registers a callback on one of the HostComponent's attributes
 *
 * @param {HTMLElement} child element that the function is looking from
 * @param {string} attrName of the attribute
 * @param {Function} changeCallback that is called with newValue as a string, after attribute has changed
 *
 * @returns {MutationObserver} the observer, so it can be disconnected later
 */
export function watchAttributeOnHostFromChild(child, attrName, changeCallback) {
    // determine attr value from host and trigger initially
    const hostElement = getHostElementFromChild(child);
    const currentValue = hostElement.getAttribute(attrName);
    changeCallback(currentValue);
    // then register mutationObserver on host for this attribute only
    const attrObserver = new MutationObserver(() => {
        // because we only observe a passed attribute, we can always trigger the callback
        changeCallback(hostElement.getAttribute(attrName));
    });
    attrObserver.observe(hostElement, { childList: false, subtree: false, attributeFilter: [attrName] });
    return attrObserver;
}
/**
 * Sets a custom CSS property on the HostElement
 *
 * @param {HTMLElement} child element that the function is looking from
 * @param {string} propName of the custom property
 * @param {string | null} value of the custom property
 */
export function setCustomPropertyOnHostFromChild(child, propName, value) {
    const hostElement = getHostElementFromChild(child);
    hostElement.style.setProperty(propName, value);
}
/**
 * returns, whether it has a hostElement with a tagName having a certain prefix
 *
 * @param {HTMLElement} child element that the function is looking from
 * @param {string} prefix that the parentElement is tested for
 * @returns {boolean} whether is has or not
 */
export function hasHostWithPrefixFromChild(child, prefix) {
    // simply look at the hostElements tagName
    const hostElement = getHostElementFromChild(child);
    // if we do not have a hostElement at all, we obviously will never have a proper match
    if (!hostElement) {
        return false;
    }
    else {
        return hostElement.tagName.toLowerCase().startsWith(prefix.toLowerCase());
    }
}
/**
 * Returns the addEventListener from the HostElement
 *
 * @param {HTMLElement} child element that the function is looking from
 * @returns {Function} addEventListener callback registration function
 */
export function getAddEventListenerOnHostFromChild(child) {
    const hostElement = getHostElementFromChild(child);
    return hostElement.addEventListener;
}
/**
 * Returns whether a passed element is within a direct ShadowDOM or not
 *
 * @param {HTMLElement} node to be tested
 * @returns {boolean} whether it is in ShadowDOM or not
 */
export function isInShadow(node) {
    while (node) {
        if (isInDirectShadow(node)) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
}
/**
 * Simply returns the host of a shadowed element

 * @param node that the shadowHost should be returned for
 * @returns shadowHost
 */
export function getShadowHost(node) {
    return node.getRootNode()['host'];
}
/**
 * Checks a given node if it is directly in a shadow root
 *
 * @param {HTMLElement} node to be tested
 * @returns {boolean} whether it's in direct ShadowDOM or not
 */
export function isInDirectShadow(node) {
    var _a;
    return ((_a = node.parentNode) === null || _a === void 0 ? void 0 : _a.toString()) === '[object ShadowRoot]';
}
/**
 * Detects if a given element has ShadowDOM or not
 *
 * @param {HTMLElement} node to be tested
 * @returns {boolean} whether it has ShadowDOM or not
 */
export function hasShadow(node) {
    return node.shadowRoot !== null;
}
/**
 * returns the parent element selected by a selector, passes shadowDOM boundaries
 *
 * @param current element to search from
 * @param selector selector to apply
 * @returns element matching the selector or null
 */
export function getParentElementBySelector(current, selector) {
    var _a;
    let currentNode = current;
    // while it is not found, pierce ShadowDOM until there is no ShadowDOM anymore
    let foundClosest = currentNode.closest(selector);
    while (!foundClosest && currentNode && isInShadow(currentNode)) {
        currentNode = getShadowHost(currentNode);
        foundClosest = (_a = currentNode === null || currentNode === void 0 ? void 0 : currentNode.closest(selector)) !== null && _a !== void 0 ? _a : null;
    }
    return foundClosest;
}
// -> https://stackoverflow.com/questions/66707738/comprehensible-chaining-of-mixins-for-a-typescript-class-without-nesting
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line jsdoc/require-jsdoc,@typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
export function ApplyMixins(baseClass, ...mixins) {
    return mixins.reduce((mix, applyMixin) => applyMixin(mix), baseClass);
}
