import type { GlobalStateContext } from 'piral-core';

import { getUserData } from '../../auth/authenticated-user';
import type { ContentString } from '../../types';
import { normalizeNotificationsQueue } from './state';
import type { BellNotification, BellNotificationSeverity, BellNotificationState } from './types';

interface SyncedBellNotification {
  id: string;
  description: string;
  origin: string;
  severity: BellNotificationSeverity;
  title: string;
  timestamp: Date;
  tabId: string;
  state: BellNotificationState;
}

interface NotificationBroadcastMessageBase {
  sender: string;
  receiver?: string;
}

/**
 * The message used to indicate that a new tab has been created.
 * This will invoke other tabs to send their current snapshot.
 */
interface NotificationBroadcastMessageEnter extends NotificationBroadcastMessageBase {
  type: 'enter';
}

/**
 * The message used to indicate that the tab is about to be destroyed.
 * This will invoke other tabs to clean up their messages (removing of the notifications from this tab).
 */
interface NotificationBroadcastMessageLeave extends NotificationBroadcastMessageBase {
  type: 'leave';
}

/**
 * The message used to send a snapshot of the currently owned notifications to all other tabs.
 * Usually this is directed at a single tab only, which should be specified with the receiver prop.
 */
interface NotificationBroadcastMessageSnapshot extends NotificationBroadcastMessageBase {
  type: 'snapshot';
  items: Array<SyncedBellNotification>;
}

/**
 * The message used to report a change of notifications from one tab to all other tabs.
 */
interface NotificationBroadcastMessageReport extends NotificationBroadcastMessageBase {
  type: 'report';
  added: Array<SyncedBellNotification>;
  removed: Array<SyncedBellNotification>;
  updated: Array<SyncedBellNotification>;
}

type NotificationBroadcastMessage =
  | NotificationBroadcastMessageEnter
  | NotificationBroadcastMessageLeave
  | NotificationBroadcastMessageSnapshot
  | NotificationBroadcastMessageReport;

function shouldSyncNotification(notification: BellNotification) {
  return notification.fromCurrentTab && !notification.noSync;
}

function getString(content: ContentString) {
  if (typeof content === 'function') {
    return content();
  }

  if (typeof content !== 'string') {
    let result = '';
    const sub = content.subscribe((value) => {
      result = value;
      sub.unsubscribe();
    });
    return result;
  }

  return content;
}

function convertNotification(notification: BellNotification): SyncedBellNotification {
  return {
    id: notification.id,
    description: getString(notification.description),
    origin: getString(notification.origin),
    severity: notification.severity,
    title: getString(notification.title),
    timestamp: notification.timestamp,
    state: notification.state,
    tabId: notification.tabId,
  };
}

function expandNotification(notification: SyncedBellNotification): BellNotification {
  return {
    ...notification,
    fromCurrentTab: false,
  };
}

function reassignNotification(
  updates: Array<SyncedBellNotification>,
  original: BellNotification
): BellNotification {
  const { id } = original;
  const update = updates.find((n) => n.id === id);

  if (update) {
    return expandNotification(update);
  }

  return original;
}

function diffNotifications(
  currentBellNotifications: Array<BellNotification>,
  previousBellNotifications: Array<BellNotification>
) {
  const previousNotifications = previousBellNotifications.filter(shouldSyncNotification);
  const currentNotifications = currentBellNotifications.filter(shouldSyncNotification);
  const previousNotificationIds = previousNotifications.map((m) => m.id);
  const currentNotificationIds = currentNotifications.map((m) => m.id);
  const removedNotifications = previousNotifications.filter(
    (m) => !currentNotificationIds.includes(m.id)
  );
  const addedNotifications = currentNotifications.filter(
    (m) => !previousNotificationIds.includes(m.id)
  );
  const updatedNotifications = currentNotifications.filter(
    (m) => !previousNotifications.includes(m) && !addedNotifications.includes(m)
  );
  const changes =
    addedNotifications.length + removedNotifications.length + updatedNotifications.length;

  return {
    changes,
    added: addedNotifications.map(convertNotification),
    updated: updatedNotifications.map(convertNotification),
    removed: removedNotifications.map(convertNotification),
  };
}

export function createNotificationsSync(context: GlobalStateContext, tabId: string): void {
  const accountId = getUserData()?.accountId ?? 0;
  const notificationsChannel = new BroadcastChannel(`notifications:${accountId}`);

  notificationsChannel.addEventListener(
    'message',
    (e: MessageEvent<NotificationBroadcastMessage>) => {
      const msg = e.data;

      if (typeof msg !== 'object' || !msg) {
        return;
      }

      const messageSender = msg.sender;

      if (typeof messageSender !== 'string') {
        // only if sender is known
        return;
      }

      if (typeof msg.receiver === 'string' && msg.receiver !== tabId) {
        // only for "me"
        return;
      }

      switch (msg.type) {
        case 'enter':
          notificationsChannel.postMessage({
            type: 'snapshot',
            items: context
              .readState((state) => state.bellNotifications)
              .filter(shouldSyncNotification)
              .map(convertNotification),
            sender: tabId,
            receiver: messageSender,
          });
          break;
        case 'report':
          context.dispatch((state) => ({
            ...state,
            bellNotifications: normalizeNotificationsQueue([
              ...state.bellNotifications
                .filter((notification) =>
                  msg.removed.every(
                    (removedNotification) => removedNotification.id !== notification.id
                  )
                )
                .map((notification) => reassignNotification(msg.updated, notification)),
              ...msg.added.map(expandNotification),
            ]),
          }));
          break;
        case 'leave':
          context.dispatch((state) => ({
            ...state,
            bellNotifications: normalizeNotificationsQueue(
              state.bellNotifications.filter((notification) => notification.tabId !== messageSender)
            ),
          }));
          break;
        case 'snapshot':
          context.dispatch((state) => ({
            ...state,
            bellNotifications: normalizeNotificationsQueue([
              ...state.bellNotifications,
              ...msg.items.map(expandNotification),
            ]),
          }));
          break;
        default:
          break;
      }
    }
  );

  context.state.subscribe((state, previousState) => {
    const current = state.bellNotifications;
    const previous = previousState.bellNotifications;

    if (current !== previous) {
      const { added, changes, removed, updated } = diffNotifications(current, previous);

      if (changes > 0) {
        notificationsChannel.postMessage({
          type: 'report',
          sender: tabId,
          added,
          removed,
          updated,
        });
      }
    }
  });

  notificationsChannel.postMessage({ type: 'enter', sender: tabId });

  window.addEventListener('unload', () => {
    notificationsChannel.postMessage({ type: 'leave', sender: tabId });
  });
}
