import type { ApiConfig } from './types';

export const API_ENDPOINTS: ApiConfig = {
  auth: {
    redirectUriPath: '/api/auth/login',
    logout: '/api/auth/logout',
    sessionInfo: '/api/auth/session-info',
  },
  feed: '/mayerfeeds',
  proxy: '/api/service/v1',
  support: {
    customer_support: '/api/support/customer-support',
  },
  techEndpoint: '/ai-proxy/techeventlog',
  tenant: {
    licenses: '/api/user-management/tenant/licenses',
    storageInfo: '/api/user-management/tenant/storage-info',
    users: '/api/user-management/tenant/users',
    user: '/api/user-management/tenant/users/__USER_ID__',
    userLicenses: '/api/user-management/tenant/users/__USER_ID__/licenses',
    userPresets: '/api/user-management/tenant/users/__USER_ID__/presets',
  },
  termsAndConditions: '/api/legal/terms-and-conditions',
};
