var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ZeissLogo_1;
import { css, html, unsafeCSS } from 'lit';
import { customElement } from 'lit/decorators.js';
import { hostStyles } from '../../host.styles.js';
const style = ":host([size=s8]){width:var(--zui-icon-size-8);height:var(--zui-icon-size-8)}:host([size=s12]){width:var(--zui-icon-size-12);height:var(--zui-icon-size-12)}:host([size=s16]){width:var(--zui-icon-size-16);height:var(--zui-icon-size-16)}:host([size=s24]){width:var(--zui-icon-size-24);height:var(--zui-icon-size-24)}:host([size=s32]){width:var(--zui-icon-size-32);height:var(--zui-icon-size-32)}:host([size=s40]){width:var(--zui-icon-size-40);height:var(--zui-icon-size-40)}:host([size=s48]){width:var(--zui-icon-size-48);height:var(--zui-icon-size-48)}:host([size=s64]){width:var(--zui-icon-size-64);height:var(--zui-icon-size-64)}:host([size=s72]){width:var(--zui-icon-size-72);height:var(--zui-icon-size-72)}:host([size=s80]){width:var(--zui-icon-size-80);height:var(--zui-icon-size-80)}svg{display:block;width:100%;height:100%}"
import { IconMixin } from '../../mixins/icons/icon.mixin.js';
import '../../mixins/icons/icon.types.js';
import { RealBaseElement } from '../base/base-element.class.js';
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
const zeissIconStyles = css `
  ${unsafeCSS(style)}
`;
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const _s32IconSvg = html `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M32 32C27.1221 29.8095 21.7022 28.5674 16.0113 28.5674C10.2978 28.5674 4.87791 29.7869 0 32V0H32V32Z"
    fill="#0F2DB3"
  />
  <path
    d="M3.83909 9.68825C2.32603 9.68825 2.21312 11.2013 2.21312 11.2013H1.49046V8.94301H7.09103C7.09103 8.94301 7.13619 10.6819 5.48764 12.5337C5.48764 12.5337 3.90683 14.3629 3.86167 15.1759H4.76499C6.07479 15.1759 6.21029 13.6628 6.21029 13.6628H6.95553V15.9211H1.21947C1.21947 15.9211 1.01622 14.2048 3.00352 12.1272C3.00352 12.1272 4.223 10.7722 4.51657 9.68825H3.83909Z"
    fill="white"
  />
  <path
    d="M18.0886 15.921V15.1758H17.9079C17.5918 15.1758 17.3434 14.9274 17.3434 14.6112V10.2527C17.3434 9.93657 17.5918 9.68815 17.9079 9.68815H18.0886V8.94292H14.385V9.68815H14.5657C14.8818 9.68815 15.1302 9.93657 15.1302 10.2527V14.6112C15.1302 14.9274 14.8818 15.1758 14.5657 15.1758H14.385V15.921H18.0886Z"
    fill="white"
  />
  <path
    d="M13.6175 15.9211V13.6628H12.8723C12.8723 13.6628 12.7368 15.1984 11.427 15.1984H10.6818V12.8272H10.9527C11.2689 12.8272 11.5173 13.0756 11.5173 13.3918V13.6628H12.2626V11.2238H11.5173V11.5174C11.5173 11.8336 11.2689 12.082 10.9527 12.082H10.6818V9.71077H11.427C12.7368 9.71077 12.8723 11.2238 12.8723 11.2238H13.6175V8.96553H7.70081V9.71077H7.88147C8.19763 9.71077 8.44604 9.95918 8.44604 10.2753V14.6338C8.44604 14.95 8.19763 15.1984 7.88147 15.1984H7.70081V15.9436L13.6175 15.9211Z"
    fill="white"
  />
  <path
    d="M21.9959 9.68815C23.5089 9.68815 23.6219 11.2012 23.6219 11.2012H24.3445V8.94292H18.9924C18.9924 8.94292 18.1568 10.0495 20.3473 12.5336C20.3473 12.5336 21.9281 14.3628 21.9507 15.1758H21.0474C19.7376 15.1758 19.6021 13.6627 19.6021 13.6627H18.8569V15.921H24.2542C24.2542 15.921 25.2252 14.8596 22.8089 12.1271C22.8089 12.1271 21.5442 10.8173 21.2958 9.68815H21.9959Z"
    fill="white"
  />
  <path
    d="M28.2511 9.68815C29.7415 9.68815 29.877 11.2012 29.877 11.2012H30.5997V8.94292H25.2475C25.2475 8.94292 24.3894 10.0495 26.6025 12.5336C26.6025 12.5336 28.1833 14.3628 28.2059 15.1758H27.3026C25.9928 15.1758 25.8573 13.6627 25.8573 13.6627H25.112V15.921H30.5094C30.5094 15.921 31.4804 14.8596 29.064 12.1271C29.064 12.1271 27.7994 10.8173 27.551 9.68815H28.2511Z"
    fill="white"
  />
</svg> `;
const _s64IconSvg = html `<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M64 64C54.2442 59.6189 43.4044 57.1348 32.0226 57.1348C20.5956 57.1348 9.75583 59.5737 0 64V0H64V64Z"
    fill="#0F2DB3"
  />
  <path
    d="M7.67818 19.3764C4.65207 19.3764 4.42624 22.4025 4.42624 22.4025H2.98093V17.8859H14.1821C14.1821 17.8859 14.2724 21.3637 10.9753 25.0673C10.9753 25.0673 7.81368 28.7257 7.72335 30.3517H9.52998C12.1496 30.3517 12.4206 27.3256 12.4206 27.3256H13.9111V31.8422H2.43894C2.43894 31.8422 2.03245 28.4096 6.00704 24.2543C6.00704 24.2543 8.446 21.5444 9.03315 19.3764H7.67818Z"
    fill="white"
  />
  <path
    d="M36.1772 31.8421V30.3516H35.8159C35.1836 30.3516 34.6868 29.8548 34.6868 29.2225V20.5055C34.6868 19.8731 35.1836 19.3763 35.8159 19.3763H36.1772V17.8858H28.77V19.3763H29.1314C29.7637 19.3763 30.2605 19.8731 30.2605 20.5055V29.2225C30.2605 29.8548 29.7637 30.3516 29.1314 30.3516H28.77V31.8421H36.1772Z"
    fill="white"
  />
  <path
    d="M27.2351 31.8421V27.3255H25.7446C25.7446 27.3255 25.4736 30.3968 22.854 30.3968H21.3635V25.6544H21.9055C22.5378 25.6544 23.0346 26.1512 23.0346 26.7836V27.3255H24.5251V22.4476H23.0346V23.0348C23.0346 23.6671 22.5378 24.1639 21.9055 24.1639H21.3635V19.4215H22.854C25.4736 19.4215 25.7446 22.4476 25.7446 22.4476H27.2351V17.9311H15.4016V19.4215H15.7629C16.3953 19.4215 16.8921 19.9184 16.8921 20.5507V29.2677C16.8921 29.9 16.3953 30.3968 15.7629 30.3968H15.4016V31.8873L27.2351 31.8421Z"
    fill="white"
  />
  <path
    d="M43.9918 19.3763C47.0179 19.3763 47.2437 22.4024 47.2437 22.4024H48.689V17.8858H37.9847C37.9847 17.8858 36.3136 20.099 40.6947 25.0672C40.6947 25.0672 43.8563 28.7256 43.9014 30.3516H42.0948C39.4752 30.3516 39.2042 27.3255 39.2042 27.3255H37.7137V31.8421H48.5084C48.5084 31.8421 50.4505 29.7193 45.6177 24.2542C45.6177 24.2542 43.0885 21.6346 42.5916 19.3763H43.9918Z"
    fill="white"
  />
  <path
    d="M56.5021 19.3763C59.4831 19.3763 59.7541 22.4024 59.7541 22.4024H61.1994V17.8858H50.4951C50.4951 17.8858 48.7788 20.099 53.205 25.0672C53.205 25.0672 56.3666 28.7256 56.4118 30.3516H54.6052C51.9856 30.3516 51.7146 27.3255 51.7146 27.3255H50.2241V31.8421H61.0187C61.0187 31.8421 62.9609 29.7193 58.1281 24.2542C58.1281 24.2542 55.5988 21.6346 55.102 19.3763H56.5021Z"
    fill="white"
  />
</svg> `;
const _s80IconSvg = html `<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M80.0001 80C67.8053 74.5236 54.2555 71.4185 40.0283 71.4185C25.7445 71.4185 12.1948 74.4672 0 80V0H80.0001V80Z"
    fill="#0F2DB3"
  />
  <path
    d="M9.59772 24.2205C5.81508 24.2205 5.53279 28.0031 5.53279 28.0031H3.72616V22.3574H17.7276C17.7276 22.3574 17.8405 26.7046 13.7191 31.3341C13.7191 31.3341 9.76709 35.9072 9.65418 37.9396H11.9125C15.187 37.9396 15.5257 34.157 15.5257 34.157H17.3888V39.8027H3.04868C3.04868 39.8027 2.54056 35.5119 7.5088 30.3179C7.5088 30.3179 10.5575 26.9304 11.2914 24.2205H9.59772Z"
    fill="white"
  />
  <path
    d="M45.2215 39.8026V37.9395H44.7699C43.9795 37.9395 43.3584 37.3185 43.3584 36.5281V25.6318C43.3584 24.8414 43.9795 24.2204 44.7699 24.2204H45.2215V22.3573H35.9625V24.2204H36.4142C37.2046 24.2204 37.8256 24.8414 37.8256 25.6318V36.5281C37.8256 37.3185 37.2046 37.9395 36.4142 37.9395H35.9625V39.8026H45.2215Z"
    fill="white"
  />
  <path
    d="M34.0438 39.8027V34.1569H32.1807C32.1807 34.1569 31.842 37.996 28.5675 37.996H26.7044V32.068H27.3819C28.1723 32.068 28.7933 32.6891 28.7933 33.4795V34.1569H30.6564V28.0596H28.7933V28.7935C28.7933 29.5839 28.1723 30.2049 27.3819 30.2049H26.7044V24.2769H28.5675C31.842 24.2769 32.1807 28.0596 32.1807 28.0596H34.0438V22.4138H19.252V24.2769H19.7037C20.4941 24.2769 21.1151 24.898 21.1151 25.6884V36.5846C21.1151 37.375 20.4941 37.996 19.7037 37.996H19.252V39.8591L34.0438 39.8027Z"
    fill="white"
  />
  <path
    d="M54.9897 24.2204C58.7724 24.2204 59.0546 28.003 59.0546 28.003H60.8613V22.3573H47.4809C47.4809 22.3573 45.392 25.1237 50.8683 31.334C50.8683 31.334 54.8203 35.9071 54.8768 37.9395H52.6185C49.344 37.9395 49.0052 34.1569 49.0052 34.1569H47.1421V39.8026H60.6354C60.6354 39.8026 63.0631 37.1491 57.0222 30.3178C57.0222 30.3178 53.8606 27.0433 53.2395 24.2204H54.9897Z"
    fill="white"
  />
  <path
    d="M70.6277 24.2204C74.3539 24.2204 74.6926 28.003 74.6926 28.003H76.4992V22.3573H63.1188C63.1188 22.3573 60.9735 25.1237 66.5063 31.334C66.5063 31.334 70.4583 35.9071 70.5148 37.9395H68.2565C64.9819 37.9395 64.6432 34.1569 64.6432 34.1569H62.7801V39.8026H76.2734C76.2734 39.8026 78.7011 37.1491 72.6601 30.3178C72.6601 30.3178 69.4985 27.0433 68.8775 24.2204H70.6277Z"
    fill="white"
  />
</svg> `;
/**
 * Zeiss Logo is available as a full-fledged component
 *
 * @example
 * HTML:
 * ```html
 * <zui-zeiss-logo size="s32"></zui-zeiss-logo>
 * ```
 */
let ZeissLogo = ZeissLogo_1 = class ZeissLogo extends IconMixin(RealBaseElement, { defaultSize: 's32' }) {
    render() {
        return html ` ${ZeissLogo_1._icons.get(this.size)}`;
    }
};
ZeissLogo.styles = [
    hostStyles,
    ...(RealBaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    zeissIconStyles,
];
/**
 * Defines which icon resource to use at which icon size
 */
ZeissLogo._icons = new Map([
    ['s8', _s32IconSvg],
    ['s12', _s32IconSvg],
    ['s16', _s32IconSvg],
    ['s24', _s32IconSvg],
    ['s32', _s32IconSvg],
    ['s40', _s32IconSvg],
    ['s48', _s32IconSvg],
    ['s64', _s64IconSvg],
    ['s72', _s64IconSvg],
    ['s80', _s80IconSvg],
]);
ZeissLogo = ZeissLogo_1 = __decorate([
    customElement('zui-zeiss-logo')
], ZeissLogo);
export { ZeissLogo };
