var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{width:auto;height:fit-content;overflow:hidden}"
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const multiItemSliderItemStyles = css `
  ${unsafeCSS(styles)}
`;
/**
 * The multi item slider items are used to declare single item contents of a slider.
 * It's slotting contents right through, but cuts-off horizontally overflowing content.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-multi-item-slider-item>Foo</zui-multi-item-slider-item>
 * ```
 *
 * @slot default - Default slot for passing in arbitrary content
 */
let MultiItemSliderItem = class MultiItemSliderItem extends BaseElement {
    render() {
        return html `<slot></slot>`;
    }
};
MultiItemSliderItem.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    multiItemSliderItemStyles,
];
MultiItemSliderItem = __decorate([
    customElement('zui-multi-item-slider-item')
], MultiItemSliderItem);
export { MultiItemSliderItem };
