var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { getNextPickerGridCell, getPreviousPickerGridCell } from '../utils/picker.utils.js';
import { PickerGridCell } from '../picker-grid-cell/picker-grid-cell.component.js';
import { PickerHeaderCell } from '../picker-header-cell/picker-header-cell.component.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{---zui-picker-grid-columns: 7;--zui-picker-grid-cell-height: utils.grid-units(4);--zui-picker-grid-cell-width: utils.grid-units(4);display:flex;flex-direction:column;width:calc(var(---zui-picker-grid-columns)*var(--zui-picker-grid-cell-width))}slot[name=pickerGridHeaderCells]{display:grid;grid-template-columns:repeat(var(---zui-picker-grid-columns), min-content)}slot[name=pickerGridCells]{display:grid;grid-template-columns:repeat(var(---zui-picker-grid-columns), min-content)}"
import { GetNotificationEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const pickerGridStyles = css `
  ${unsafeCSS(styles)}
`;
var ArrowKeys;
(function (ArrowKeys) {
    ArrowKeys["ArrowUp"] = "ArrowUp";
    ArrowKeys["ArrowRight"] = "ArrowRight";
    ArrowKeys["ArrowDown"] = "ArrowDown";
    ArrowKeys["ArrowLeft"] = "ArrowLeft";
})(ArrowKeys || (ArrowKeys = {}));
class PickerGridHeaderCellSelectedEvent extends GetNotificationEventClass('picker-grid-header-cell-selected') {
}
class PickerGridCellSelectedEvent extends GetNotificationEventClass('picker-grid-cell-selected') {
}
/**
 * Picker grid with a header of picker grid header cells and a grid of picker grid cells
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-picker-grid columns="4">
 *   <zui-picker-header-cell slot="pickerGridHeaderCells" text="1"></zui-picker-header-cell>
 *   <zui-picker-header-cell slot="pickerGridHeaderCells" text="2"></zui-picker-header-cell>
 *   <zui-picker-header-cell slot="pickerGridHeaderCells" text="3"></zui-picker-header-cell>
 *   <zui-picker-header-cell slot="pickerGridHeaderCells" text="4"></zui-picker-header-cell>
 *   <zui-picker-grid-cell slot="pickerGridCells" emphasis="subtle" value="1">1</zui-picker-grid-cell>
 *   <zui-picker-grid-cell slot="pickerGridCells" emphasis="selected" value="2">2</zui-picker-grid-cell>
 *   <zui-picker-grid-cell slot="pickerGridCells" disabled emphasis="default" value="3">3</zui-picker-grid-cell>
 *   <zui-picker-grid-cell slot="pickerGridCells" emphasis="default" value="4">4</zui-picker-grid-cell>
 * </zui-picker-grid>
 * ```
 *
 * @slot pickerGridHeaderCells - slot for picker grid header cells
 * @slot pickerGridCells - slot for picker grid cells
 *
 * @fires {GetZuiEvent<PickerGrid, 'CellSelectedEvent'>} zui-picker-grid-cell-selected - will be dispatched, if a `<zui-picker-grid-cell>` is selected
 * @fires {GetZuiEvent<PickerGrid, 'HeaderCellSelectedEvent'>} zui-picker-grid-header-cell-selected - will be dispatched, if a `<zui-picker-header-cell>` is selected
 * @cssprop --zui-picker-grid-cell-height - sets the cell height explicitly
 * @cssprop --zui-picker-grid-cell-width - sets the cell width explicitly
 */
let PickerGrid = class PickerGrid extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.HeaderCellSelectedEvent = PickerGridHeaderCellSelectedEvent;
        this.CellSelectedEvent = PickerGridCellSelectedEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * column count of the picker grid
         */
        this.columns = 7;
    }
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('keydown', this._handleKeyEvent);
        this.addEventListener('click', this._handleClickEvent);
    }
    disconnectedCallback() {
        this.removeEventListener('keydown', this._handleKeyEvent);
        this.removeEventListener('click', this._handleClickEvent);
        super.disconnectedCallback();
    }
    _handleKeyEvent(keyboardEvent) {
        let { code } = keyboardEvent;
        const { target, shiftKey } = keyboardEvent;
        // map Tab to Arrow.Right and Shift-Tab to Arrow.Left
        if (code === 'Tab') {
            keyboardEvent.preventDefault();
            code = shiftKey ? ArrowKeys.ArrowLeft : ArrowKeys.ArrowRight;
        }
        let nextInDirection = null;
        switch (code) {
            case ArrowKeys.ArrowUp: {
                nextInDirection = getPreviousPickerGridCell(target, this.columns);
                break;
            }
            case ArrowKeys.ArrowLeft: {
                nextInDirection = getPreviousPickerGridCell(target);
                break;
            }
            case ArrowKeys.ArrowRight: {
                nextInDirection = getNextPickerGridCell(target);
                break;
            }
            case ArrowKeys.ArrowDown: {
                nextInDirection = getNextPickerGridCell(target, this.columns);
                break;
            }
        }
        if (nextInDirection) {
            nextInDirection.focus();
        }
    }
    _handleClickEvent({ target }) {
        if (target instanceof PickerGridCell) {
            this.emitPickerGridCellSelectedEvent({ value: target.value, selected: target });
        }
        else if (target instanceof PickerHeaderCell) {
            this.emitPickerGridHeaderCellSelectedEvent({ text: target.text, value: target.value, selected: target });
        }
    }
    render() {
        return html `
      <slot name="pickerGridHeaderCells" style="---zui-picker-grid-columns: ${this.columns}"></slot>
      <slot name="pickerGridCells" style="---zui-picker-grid-columns: ${this.columns}"></slot>
    `;
    }
};
PickerGrid.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    pickerGridStyles,
];
__decorate([
    property({ reflect: true, type: Number })
], PickerGrid.prototype, "columns", void 0);
PickerGrid = __decorate([
    customElement('zui-picker-grid')
], PickerGrid);
export { PickerGrid };
