import { ZuiButton, ZuiPopover, ZuiScrollableDirective, ZuiTooltip } from '@zeiss/zui-react';
import styled, { css } from 'styled-components';

export const PopoverContainer = styled.div`
  position: fixed;
  z-index: 1;
  right: 0;
`;

export const Popover = styled(ZuiPopover)`
  position: relative;
  top: -4px;
  right: 0;
  width: 332px;
  padding: 0;
  color: var(--zui-color-gs-100);

  zui-list-item > a,
  zui-list-item > div {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 0 var(--arn-space-8);
  }

  zui-list-item zui-icon-arrows-arrow-outline-visually-centered-right {
    display: none;
  }
`;

const Content = css`
  overflow: hidden;
  max-width: 80%;
  margin: 0 var(--zui-space-large);
  font: var(--zui-typography-caption);
  text-overflow: ellipsis;
  white-space: nowrap;

  /* is needed to hide the default tooltip in safari browser */
  &::before {
    display: block;
    content: '';
  }
`;

export const Name = styled.div`
  ${Content}

  margin-top: var(--arn-space-6);
  margin-bottom: var(--arn-space-1);
  color: var(--zui-color-gs-100);
`;

export const Email = styled.div`
  ${Content}

  color: var(--zui-color-gs-80);
`;

export const LogoutButton = styled(ZuiButton)`
  --zui-button-width: 100%;

  width: 100%;
  padding: var(--arn-space-6) var(--arn-space-8);
`;

export const Tooltip = styled(ZuiTooltip)`
  position: absolute;
  z-index: 1;
  right: 0;
  left: 0;
  max-width: 90%;
  margin: var(--arn-space-3) auto;
  color: var(--zui-color-gs-120);
  font: var(--zui-typography-caption);
  word-break: break-all;
`;

export const Scrollable = styled(ZuiScrollableDirective)`
  --zui-scrollable-max-height: calc(100vh - var(--arn-space-28));

  width: 100%;
`;
