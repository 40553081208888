var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var TextfieldDatePicker_1;
import { css, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { hostStyles } from '../../../host.styles.js';
import { DateTime } from 'luxon';
import { BaseElement } from '../../base/base-element.class.js';
import '../../../types.js';
import { daysOfWeekConverter, getDateTimesFromJsDates, getDefaultLocale, hasWeekday, isoDateConverter, isValidDate, someIsSameDay, someIsSameMonth, someIsSameYear, } from '../utils/date-picker.utils.js';
import { FormValidationMixin } from '../../../mixins/form-participation/form-validation.mixin.js';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin.js';
import '../../../mixins/form-participation/form-participation.types.js';
import { isDefined } from '../../../utils/component.utils.js';
import '@popperjs/core/lib/enums.js';
import { generateUid, getContentsFromPortal } from '../../../utils/portal.utils.js';
import { GetNotificationEventClass, MimickedChangeEvent, MimickedInputEvent, } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { ValidationStyleMixin } from '../../../mixins/validation-style/validation-style.mixin.js';
import '../../../contracts/validation-style.contract.js';
// deps
import '../../popover/popover.component.js';
import '../../div/div.component.js';
import '../date-picker/date-picker.component.js';
import '../date-picker-input/date-picker-input.component.js';
import '../../../directives/overlay/overlay.directive.js';
import { InvalidDate } from '../invalid-date.class.js';
import '../../../directives/validation-message/validation-message.directive.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const styles = ":host{--zui-textfield-date-picker-input-width: calc(var(--zui-gu) * 15);--zui-textfield-date-picker-day-placeholder-width: calc(var(--zui-gu) * 2.5);--zui-textfield-date-picker-month-placeholder-width: calc(var(--zui-gu) * 3);--zui-textfield-date-picker-year-placeholder-width: calc(var(--zui-gu) * 4.75);position:relative;width:var(--zui-textfield-date-picker-input-width);overflow:hidden}zui-date-picker-input{--zui-date-picker-input-day-placeholder-width: var(--zui-textfield-date-picker-day-placeholder-width);--zui-date-picker-input-month-placeholder-width: var(--zui-textfield-date-picker-month-placeholder-width);--zui-date-picker-input-width: var(--zui-textfield-date-picker-input-width);--zui-date-picker-input-year-placeholder-width: var(--zui-textfield-date-picker-year-placeholder-width)}"
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const textfieldDatePickerStyles = css `
  ${unsafeCSS(styles)}
`;
class TextfieldDatePickerDateSelectedEvent extends GetNotificationEventClass('textfield-date-picker-date-selected') {
}
/* eslint-disable jsdoc/require-property-description */
/**
 * The textfield date picker component shows an input and opens the date picker when the interactive icon is selected.
 *
 * ## Figma
 * - [Desktop - Component Library - Text Field](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---2.7?node-id=384:64193)
 * - [Desktop - Component Library - Date Picker](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=21190%3A191753)
 * - [Styleguide – Desktop - Text Field](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=48826:397355)
 * - [Styleguide – Desktop - Date Picker](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=6557%3A241082)
 *
 * @example
 * HTML:
 *
 * Textfield date picker
 * ```html
 *   <zui-textfield-date-picker
 *     close-on-date-selected
 *     disabled-days-of-week="Mo,Tuesday"
 *     locale="en-US"
 *     max="2010-01-01T00:00:00.000+01:00"
 *     min="2020-12-31T23:59:59.999+01:00"
 *     name="textfieldDatePicker"
 *     placeholder-day="DD"
 *     placeholder-month="MM"
 *     placeholder-year="YYYY"
 *     value="2021-07-21T11:00:00.000+02:00"
 *   >
 *   </zui-textfield-date-picker>
 * ```
 *
 * Form example
 * ```html
 * <form>
 *   <button type="reset">reset</button>
 *   <button type="submit">submit</button>
 *   <zui-textfield-date-picker
 *    ...
 *   >
 *   </zui-textfield-date-picker>
 * </form>
 * ```
 *
 * @fires {GetZuiEvent<TextfieldDatePicker, 'DateSelectedEvent'>} zui-textfield-date-picker-date-selected - emits the selected day
 * @fires change - The event that fires when user has changed <code>value</code>
 * @fires input - The event that fires when there has been any user input
 *
 * @cssprop --zui-textfield-date-picker-input-width - size of the input and the whole component - default width is 120px but also you can use percentages like 100%
 * @cssprop --zui-textfield-date-picker-day-placeholder-width - override default day input placeholder width that is optimized for DD
 * @cssprop --zui-textfield-date-picker-month-placeholder-width - override default month input placeholder width that is optimized for MM
 * @cssprop --zui-textfield-date-picker-year-placeholder-width - override default year input placeholder width that is optimized for YYYY
 * @property { "line-only" | "static-text" | "static-tooltip" | "cursor-anchored-tooltip" | "component-anchored-tooltip" } [validationMessageStyle=component-anchored-tooltip]
 */
let TextfieldDatePicker = TextfieldDatePicker_1 = 
/* eslint-enable jsdoc/require-property-description */
class TextfieldDatePicker extends ValidationStyleMixin(FormValidationMixin(FormDataHandlingMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.InputEvent = MimickedInputEvent;
        this.ChangeEvent = MimickedChangeEvent;
        this.DateSelectedEvent = TextfieldDatePickerDateSelectedEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}))), {
    supportedValidationMessageStyles: [
        'line-only',
        'static-text',
        'static-tooltip',
        'cursor-anchored-tooltip',
        'component-anchored-tooltip',
    ],
    defaultValidationMessageStyle: 'component-anchored-tooltip',
}) {
    constructor() {
        super();
        /* eslint-enable @typescript-eslint/naming-convention */
        /**
         * whether the picker should be closed after date selection or not
         */
        this.closeOnDateSelected = false;
        /**
         * disabled dates
         */
        this.disabledDates = [];
        /**
         * disabled months
         */
        this.disabledMonths = [];
        /**
         * disabled years
         */
        this.disabledYears = [];
        /**
         * disabled days of week
         */
        this.disabledDaysOfWeek = [];
        /**
         * Full locale code as defined in RFC 5646/BCP 47,
         * i.e. "en-US".
         *
         * By default, the first full locale in [navigator.languages](https://developer.mozilla.org/en-US/docs/Web/API/Navigator/languages) is used.
         */
        this.locale = getDefaultLocale();
        /**
         * max date
         */
        this.max = null;
        /**
         * min date
         */
        this.min = null;
        /**
         * placeholder day
         */
        this.placeholderDay = 'DD';
        /**
         * placeholder month
         */
        this.placeholderMonth = 'MM';
        /**
         * placeholder year
         */
        this.placeholderYear = 'YYYY';
        /**
         * required
         */
        this.required = false;
        /**
         * show the calendar UI or not, defaults to false
         */
        this.showCalendar = false;
        /**
         * selected date value
         *
         * @returns value Date | null
         */
        this.value = null;
        this._internalDateTimeValue = {
            day: null,
            month: null,
            year: null,
        };
        this._currentDate = null;
        this._datePickerPortal = `date-picker-overlay-${generateUid()}`;
        this._textfieldDatePickerResizeObserver = new ResizeObserver(() => {
            requestAnimationFrame(() => {
                var _a, _b;
                (_a = this._textfieldDatePickerPopoverOverlayRef) === null || _a === void 0 ? void 0 : _a.forcePositioning();
                (_b = this._textfieldDatePickerWarningMessageOverlayRef) === null || _b === void 0 ? void 0 : _b.forcePositioning();
            });
        });
        // Validators
        this._disabledDateValidator = () => this._valueDT ? !this._disabledDateConditions.some((predicate) => this._valueDT && predicate(this._valueDT)) : true;
        this._invalidDateValidator = () => (this.value ? DateTime.fromJSDate(this.value).isValid : true);
        this._rangeOverflowValidator = () => this._valueDT && this._maxDateDT ? this._valueDT.toMillis() < this._maxDateDT.toMillis() : true;
        this._rangeUnderflowValidator = () => this._valueDT && this._minDateDT ? this._valueDT.toMillis() > this._minDateDT.toMillis() : true;
        this._requiredDateValidator = () => !this.required || isDefined(this.value);
        this._offset = () => [0, 8];
        this._datePickerPositionReference = () => this._datePickerInput;
        // todo: this should be removed when a reusable solution has been implemented
        // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
        this._handleOutsideClick = (event) => {
            if (!this.showCalendar) {
                return;
            }
            const isInsideClick = this.isSameNode(event.target) &&
                event
                    .composedPath()
                    .filter((target) => (target === null || target === void 0 ? void 0 : target.tagName) !== undefined)
                    .some((element) => element.tagName.toLowerCase() === 'zui-date-picker-input');
            const isDatePickerPortal = event.target.getAttribute('name') === this._datePickerPortal;
            if (!isInsideClick && !isDatePickerPortal) {
                this.showCalendar = false;
            }
        };
        this._messageAnchorReferenceCallback = () => this._datePickerInput;
        this.addValidator({
            type: 'badInput',
            validator: this._invalidDateValidator,
        });
        this.addValidator({
            type: 'customError',
            validator: this._disabledDateValidator,
            validatesOnProperties: ['disabledDates', 'disabledMonths', 'disabledYears', 'disabledDaysOfWeek'],
        });
        this.addValidator({
            type: 'rangeOverflow',
            validator: this._rangeOverflowValidator,
            validatesOnProperties: ['max'],
        });
        this.addValidator({
            type: 'rangeUnderflow',
            validator: this._rangeUnderflowValidator,
            validatesOnProperties: ['min'],
        });
        this.addValidator({
            type: 'valueMissing',
            validator: this._requiredDateValidator,
            validatesOnProperties: ['required'],
        });
        this.setDefaultValidityMessages({
            badInput: TextfieldDatePicker_1.INVALID_ERROR_MESSAGE,
            customError: TextfieldDatePicker_1.DISABLED_ERROR_MESSAGE,
            rangeOverflow: TextfieldDatePicker_1.DISABLED_ERROR_MESSAGE,
            rangeUnderflow: TextfieldDatePicker_1.DISABLED_ERROR_MESSAGE,
            valueMissing: TextfieldDatePicker_1.REQUIRED_ERROR_MESSAGE,
        });
    }
    get _disabledDatesDT() {
        return getDateTimesFromJsDates(this.disabledDates);
    }
    get _disabledMonthsDT() {
        return getDateTimesFromJsDates(this.disabledMonths);
    }
    get _disabledYearsDT() {
        return getDateTimesFromJsDates(this.disabledYears);
    }
    get _maxDateDT() {
        return this.max ? DateTime.fromJSDate(this.max) : undefined;
    }
    get _minDateDT() {
        return this.min ? DateTime.fromJSDate(this.min) : undefined;
    }
    get _valueDT() {
        return this.value && DateTime.fromJSDate(this.value).isValid ? DateTime.fromJSDate(this.value) : undefined;
    }
    /**
     * current date, i.e. pre-selected value range for DatePicker
     *
     * @returns Date
     */
    get currentDate() {
        var _a, _b;
        const validDateOrNull = 
        // prettier-ignore
        this.value instanceof Date
            ? (isValidDate(this.value) ? this.value : null)
            : null;
        // the current date defaults to either its internal value, if it is not set, the selected date and last but not least today
        return (_b = (_a = this._currentDate) !== null && _a !== void 0 ? _a : validDateOrNull) !== null && _b !== void 0 ? _b : new Date();
    }
    /**
     * Sets the default pre-selected date for the zui-date-picker. Can also be null, if so other fallback values are used.
     * @param value - sets the current date for the zui-date-picker
     */
    set currentDate(date) {
        const oldValue = this._currentDate;
        this._currentDate = date;
        this.requestUpdate('currentDate', oldValue);
    }
    get _disabledDateConditions() {
        return [
            (date) => someIsSameDay(date, this._disabledDatesDT),
            (date) => someIsSameMonth(date, this._disabledMonthsDT),
            (date) => someIsSameYear(date, this._disabledYearsDT),
            (date) => hasWeekday(date, this.disabledDaysOfWeek),
        ];
    }
    connectedCallback() {
        super.connectedCallback();
        // todo: this should be removed when a reusable solution has been implemented
        // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
        window.addEventListener('click', this._handleOutsideClick);
    }
    disconnectedCallback() {
        this._textfieldDatePickerResizeObserver.disconnect();
        // todo: this should be removed when a reusable solution has been implemented
        // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
        window.removeEventListener('click', this._handleOutsideClick);
        super.disconnectedCallback();
    }
    /**
     * @private
     */
    formResetCallback() {
        super.formResetCallback();
        this._internalDateTimeValue = {
            day: null,
            month: null,
            year: null,
        };
    }
    // if we have an invalid date, return its parsed fragments
    // or resort to the valid date fragment value
    // or an explicitly set internal value
    get _inputPartDayValue() {
        var _a, _b;
        // prettier-ignore
        return this.value instanceof InvalidDate
            ? this.value.day
            : (_b = (_a = this._valueDT) === null || _a === void 0 ? void 0 : _a.day) !== null && _b !== void 0 ? _b : this._internalDateTimeValue.day;
    }
    get _inputPartMonthValue() {
        var _a, _b;
        return this.value instanceof InvalidDate
            ? this.value.month
            : (_b = (_a = this._valueDT) === null || _a === void 0 ? void 0 : _a.month) !== null && _b !== void 0 ? _b : this._internalDateTimeValue.month;
    }
    get _inputPartYearValue() {
        var _a, _b;
        return this.value instanceof InvalidDate
            ? this.value.year
            : (_b = (_a = this._valueDT) === null || _a === void 0 ? void 0 : _a.year) !== null && _b !== void 0 ? _b : this._internalDateTimeValue.year;
    }
    _handleDatePickerInputCalendarSelected() {
        this.showCalendar = !this.showCalendar;
    }
    _handleDatePickerInputChanged({ detail: { value, error }, }) {
        const oldValue = this.value;
        this.value = value;
        if (error) {
            this._internalDateTimeValue = Object.assign({}, error);
        }
        // do not emit an event when only parts changes and the previous value is not defined
        if (this.value === null && oldValue === null) {
            return;
        }
        this.emitTextfieldDatePickerDateSelectedEvent({ value: this.value });
        this.emitInputEvent();
        this.emitChangeEvent();
    }
    _handleDatePickerInputFocused() {
        this.showCalendar = false;
    }
    _handleDatePickerDateSelected({ detail: { value } }) {
        this.value = value;
        this.currentDate = value;
        this.showCalendar = !this.closeOnDateSelected;
        this.emitTextfieldDatePickerDateSelectedEvent({ value });
        this.emitInputEvent();
        this.emitChangeEvent();
    }
    _handleDatePickerKeyDown({ code }) {
        if (code === 'Escape') {
            this.showCalendar = false;
        }
    }
    _delegateFocusToDatePicker() {
        const [datePickerRef] = getContentsFromPortal(this._datePickerPortal, 'zui-date-picker');
        datePickerRef.focus();
    }
    firstRendered() {
        super.firstRendered();
        this._textfieldDatePickerResizeObserver.observe(this);
        this._textfieldDatePickerResizeObserver.observe(this._datePickerInput);
    }
    render() {
        return html `
      <zui-div>
        <zui-date-picker-input
          ?calendar-opened="${this.showCalendar}"
          ?disabled="${this.disabled}"
          ?invalid="${this.showValidation}"
          ?readonly="${this.readonly}"
          .inputPartDayValue="${this._inputPartDayValue}"
          .inputPartMonthValue="${this._inputPartMonthValue}"
          .inputPartYearValue="${this._inputPartYearValue}"
          locale="${this.locale}"
          placeholder-day="${this.placeholderDay}"
          placeholder-month="${this.placeholderMonth}"
          placeholder-year="${this.placeholderYear}"
          selected-date="${ifDefined(this._valueDT)}"
          @zui-date-picker-input-calendar-selected="${this._handleDatePickerInputCalendarSelected}"
          @zui-date-picker-input-changed="${this._handleDatePickerInputChanged}"
          @zui-date-picker-input-focused="${this._handleDatePickerInputFocused}"
        >
        </zui-date-picker-input>
        ${!this.showCalendar
            ? html `<zui-validation-message-directive
              .messageAnchorReferenceCallback=${this._messageAnchorReferenceCallback}
              .triggerHostReference=${this}
              validation-message=${this.validationMessage}
            ></zui-validation-message-directive>`
            : nothing}
      </zui-div>
      ${this.showCalendar
            ? html `
            <zui-overlay-directive
              .offsetHandler="${this._offset}"
              .placements="${TextfieldDatePicker_1.POPOVER_PLACEMENTS}"
              .positionReferenceCallback="${this._datePickerPositionReference}"
              flip
              id="textfield-date-picker-popover"
              portal="${this._datePickerPortal}"
              @zui-overlay-ready="${this._delegateFocusToDatePicker}"
            >
              <zui-popover style="width: auto; padding: 32px">
                <zui-date-picker
                  .currentDate="${this.currentDate}"
                  .disabledDates="${this.disabledDates}"
                  .disabledMonths="${this.disabledMonths}"
                  .disabledYears="${this.disabledYears}"
                  .max=${this.max}
                  .min=${this.min}
                  disabled-days-of-week="${this.disabledDaysOfWeek.join(',')}"
                  locale="${this.locale}"
                  selected-date="${ifDefined(this._valueDT)}"
                  week-start="${ifDefined(this.weekStart)}"
                  @zui-date-picker-date-selected="${this._handleDatePickerDateSelected}"
                  @keydown="${this._handleDatePickerKeyDown}"
                >
                </zui-date-picker>
              </zui-popover>
            </zui-overlay-directive>
          `
            : nothing}
    `;
    }
};
TextfieldDatePicker.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    textfieldDatePickerStyles,
];
/* eslint-disable @typescript-eslint/naming-convention */
TextfieldDatePicker.DISABLED_ERROR_MESSAGE = 'This date is not allowed.';
TextfieldDatePicker.INVALID_ERROR_MESSAGE = 'Please enter a valid date.';
TextfieldDatePicker.POPOVER_PLACEMENTS = ['bottom-start', 'bottom-end', 'top-start', 'top-end'];
TextfieldDatePicker.REQUIRED_ERROR_MESSAGE = 'A date is required.';
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'close-on-date-selected' })
], TextfieldDatePicker.prototype, "closeOnDateSelected", void 0);
__decorate([
    property({ attribute: false })
], TextfieldDatePicker.prototype, "disabledDates", void 0);
__decorate([
    property({ attribute: false })
], TextfieldDatePicker.prototype, "disabledMonths", void 0);
__decorate([
    property({ attribute: false })
], TextfieldDatePicker.prototype, "disabledYears", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'disabled-days-of-week', converter: daysOfWeekConverter })
], TextfieldDatePicker.prototype, "disabledDaysOfWeek", void 0);
__decorate([
    property({ reflect: true, type: String })
], TextfieldDatePicker.prototype, "locale", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], TextfieldDatePicker.prototype, "max", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], TextfieldDatePicker.prototype, "min", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'placeholder-day' })
], TextfieldDatePicker.prototype, "placeholderDay", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'placeholder-month' })
], TextfieldDatePicker.prototype, "placeholderMonth", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'placeholder-year' })
], TextfieldDatePicker.prototype, "placeholderYear", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], TextfieldDatePicker.prototype, "required", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'show-calendar' })
], TextfieldDatePicker.prototype, "showCalendar", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], TextfieldDatePicker.prototype, "value", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'current-date', converter: isoDateConverter })
], TextfieldDatePicker.prototype, "currentDate", null);
__decorate([
    property({ reflect: true, type: String, attribute: 'week-start' })
], TextfieldDatePicker.prototype, "weekStart", void 0);
__decorate([
    query('#textfield-date-picker-popover')
], TextfieldDatePicker.prototype, "_textfieldDatePickerPopoverOverlayRef", void 0);
__decorate([
    query('#textfield-date-picker-warning-message')
], TextfieldDatePicker.prototype, "_textfieldDatePickerWarningMessageOverlayRef", void 0);
__decorate([
    state()
], TextfieldDatePicker.prototype, "_internalDateTimeValue", void 0);
__decorate([
    query('zui-date-picker-input')
], TextfieldDatePicker.prototype, "_datePickerInput", void 0);
TextfieldDatePicker = TextfieldDatePicker_1 = __decorate([
    customElement('zui-textfield-date-picker')
    /* eslint-enable jsdoc/require-property-description */
], TextfieldDatePicker);
export { TextfieldDatePicker };
