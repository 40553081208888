import { reactify, ZuiListItem } from '@zeiss/zui-react';
import type { FC } from 'react';
import styled from 'styled-components';

import { useNavAction } from '../hooks/use-nav-action';
import type { UserMenuItemProps } from '../types';
import { getContent } from '../utils/content';

const ListItem = styled(ZuiListItem)`
  padding: 0;

  a,
  div,
  span {
    display: flex;
    color: var(--zui-color-gs-100);
    font: var(--zui-typography-caption);
    gap: var(--arn-space-2);
    text-decoration: none;
  }

  border-width: 0;

  & + & {
    border-top-width: 1px;
    border-top-style: solid;
  }
`;

const ListItemContent = styled.div`
  width: 100%;
  justify-content: space-between;
`;

export const UserMenuItem: FC<{ name: string } & UserMenuItemProps> = ({
  name,
  href,
  icon,
  callback,
  title,
  target,
}) => {
  const Icon = icon && reactify(icon);
  const action = useNavAction(href, callback, target);

  return (
    <ListItem onClick={action}>
      <ListItemContent>
        <div data-test={name}>
          {Icon && <Icon size="s16" />}
          <span>{getContent(title)}</span>
        </div>
      </ListItemContent>
    </ListItem>
  );
};
