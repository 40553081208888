var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement, RealBaseElement } from '../../base/base-element.class.js';
import { FocusDifferentiationMixin } from '../../../mixins/visual-focus/focus-differentiation.mixin.js';
import { hostStyles } from '../../../host.styles.js';
const style = ":host([zui-keyboard-focused]){border:1px dashed;border-radius:var(--zui-border-radius-on-focus);outline:none}:host{margin:0;color:var(--zui-color-ewiq-dialog-description-text-color);border:1px solid rgba(0,0,0,0)}:host([use-safety]){color:var(--zui-color-ewiq-dialog-description-safety-text-color)}"
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-ewiq-dialog-description-text-color: var(--zui-color-gs-85);--zui-color-ewiq-dialog-description-safety-text-color: var(--zui-color-gs-100)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-ewiq-dialog-description-text-color: var(--zui-color-gs-70);--zui-color-ewiq-dialog-description-safety-text-color: var(--zui-color-gs-50)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const ewiqDialogDescriptionStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * This represents a very simple description component, which is used in other components, e.g. EwiqDialog. It provides a default slot,
 * which is wrapped in a zui-styled div container
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-ewiq-dialog-description>Test</zui-ewiq-dialog-description>
 * ```
 *
 * @slot - This is the default slot to be used for the description text
 */
let EwiqDialogDescription = class EwiqDialogDescription extends FocusDifferentiationMixin(RealBaseElement) {
    constructor() {
        super(...arguments);
        /**
         * whether or not to use the safety styling
         */
        this.useSafety = false;
    }
    /**
     * **deprecated** instead of 'tabindex' please use 'tabIndex', notice upper case 'I'.
     * We added this property with incorrect spelling (i in lower case), all components inherit the native tabIndex
     * property and tabindex attribute.
     * The tabindex property with wrong spelling will be removed, this only concerns the property and not the attribute.
     *
     * @deprecated
     */
    set tabindex(index) {
        const oldValue = this.tabIndex;
        this.tabIndex = index;
        // if we write our own setter we must call this. Lit documentation: https://lit.dev/docs/v1/components/properties/#accessors-custom
        this.requestUpdate('tabindex', oldValue);
    }
    get tabindex() {
        return this.tabIndex;
    }
    connectedCallback() {
        super.connectedCallback();
        // set initial tabindex to 0
        if (!this.hasAttribute('tabindex')) {
            this.tabIndex = 0;
        }
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('tabindex')) {
            console.warn(`Deprecated tabindex property with the value: ${this.tabindex} was used on zui-ewiq-dialog-description. Please use the native tabIndex property (notice upper case 'I').`);
        }
    }
    render() {
        return html `<slot></slot>`;
    }
};
EwiqDialogDescription.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    ewiqDialogDescriptionStyles,
];
__decorate([
    property({ noAccessor: true, attribute: false })
], EwiqDialogDescription.prototype, "tabindex", null);
__decorate([
    property({ reflect: true, attribute: 'use-safety', type: Boolean })
], EwiqDialogDescription.prototype, "useSafety", void 0);
EwiqDialogDescription = __decorate([
    customElement('zui-ewiq-dialog-description')
], EwiqDialogDescription);
export { EwiqDialogDescription };
