var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{--zui-multi-item-slider-pagination-animation-duration: 400ms;display:inline-flex;flex-direction:row}.pages{--zui-pagination-dot-transition-timing: var(--zui-multi-item-slider-pagination-animation-duration);margin:0 16px}"
import '../../interactive-icon/interactive-icon.component.js';
import '../../pagination/pagination-dot-bar/pagination-dot-bar.component.js';
import { GetNotificationEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
// icons
import '@zeiss/zui-icons/dist/components/generated/arrows-arrow-outline-visually-centered-right.component.js';
import '@zeiss/zui-icons/dist/components/generated/arrows-arrow-outline-visually-centered-left.component.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const multiItemSliderPaginationStyles = css `
  ${unsafeCSS(styles)}
`;
class MultiItemSliderPaginationPageSelectedEvent extends GetNotificationEventClass('multi-item-slider-pagination-page-selected') {
}
/**
 * A pagination used by the multi item slider internally.
 * It's composed of the pagination dot bar and navigation
 * buttons to each side for paging back- and forward.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-multi-item-slider-pagination count="5" selected-item-index="2"></zui-multi-item-slider-pagination>
 * ```
 * @cssprop --zui-multi-item-slider-pagination-animation-duration - animation duration of the used pagination dots
 * @fires {GetZuiEvent<MultiItemSliderPagination, 'PageSelectedEvent'>} zui-multi-item-slider-pagination-page-selected - emits when a page is selected
 */
let MultiItemSliderPagination = class MultiItemSliderPagination extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.PageSelectedEvent = MultiItemSliderPaginationPageSelectedEvent;
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * Amount of items to be pageable
         */
        this.itemCount = 1;
        /**
         * Currently selected zero based item index
         */
        this.selectedItemIndex = 0;
    }
    _handlePreviousClicked() {
        this.selectedItemIndex--;
        this.emitMultiItemSliderPaginationPageSelectedEvent({ value: this.selectedItemIndex });
    }
    _handleNextClicked() {
        this.selectedItemIndex++;
        this.emitMultiItemSliderPaginationPageSelectedEvent({ value: this.selectedItemIndex });
    }
    _handleDotSelected({ detail: { value } }) {
        this.selectedItemIndex = value;
        this.emitMultiItemSliderPaginationPageSelectedEvent({ value: this.selectedItemIndex });
    }
    render() {
        return html `
      <zui-interactive-icon ?disabled="${this.selectedItemIndex === 0}" @click="${this._handlePreviousClicked}">
        <zui-icon-arrows-arrow-outline-visually-centered-left></zui-icon-arrows-arrow-outline-visually-centered-left>
      </zui-interactive-icon>

      <zui-pagination-dot-bar
        class="pages"
        item-count="${this.itemCount}"
        selected-item-index="${this.selectedItemIndex}"
        @zui-pagination-dot-bar-pagination-dot-selected="${this._handleDotSelected}"
      >
      </zui-pagination-dot-bar>

      <zui-interactive-icon
        ?disabled="${this.selectedItemIndex === this.itemCount - 1}"
        @click="${this._handleNextClicked}"
      >
        <zui-icon-arrows-arrow-outline-visually-centered-right></zui-icon-arrows-arrow-outline-visually-centered-right>
      </zui-interactive-icon>
    `;
    }
};
MultiItemSliderPagination.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    multiItemSliderPaginationStyles,
];
__decorate([
    property({ reflect: true, attribute: 'item-count', type: Number })
], MultiItemSliderPagination.prototype, "itemCount", void 0);
__decorate([
    property({ reflect: true, attribute: 'selected-item-index', type: Number })
], MultiItemSliderPagination.prototype, "selectedItemIndex", void 0);
MultiItemSliderPagination = __decorate([
    customElement('zui-multi-item-slider-pagination')
], MultiItemSliderPagination);
export { MultiItemSliderPagination };
