import { type ExtensionRegistration, toExtension } from 'piral-core';
import type { ComponentType } from 'react';

export function makeExtension<T>(
  component: ComponentType<T>,
  defaults = {}
): Array<ExtensionRegistration> {
  const Component = toExtension(component);
  return [
    {
      component: (props) => <Component params={props.params} />,
      defaults,
      pilet: '',
      reference: component,
    },
  ];
}
