var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
import { isElementAfterOther, isElementBeforeOther, traverseDOMSiblingsByStepAndDirection, } from '../../../utils/dom.utils.js';
import { stringUndefinedConverter } from '../../../utils/component.utils.js';
import '../step-indicator-item/step-indicator-item.component.js';
const style = ":host{display:flex}"
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const stepIndicatorBarStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * The `zui-step-indicator-bar` shows a list of `zui-step-indicator-item`'s and can be used as a progress indicator.
 * Each step has an emphasis of visited, selected or next.
 * To advance one step or step back one step you can call `next()` and `previous()`.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.2?node-id=76965%3A368673)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=45219%3A117)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-step-indicator-bar size="l" value="two">
 *   <zui-step-indicator-item text="One" value="one"></zui-step-indicator-item>
 *   <zui-step-indicator-item text="Two" value="two"></zui-step-indicator-item>
 *   <zui-step-indicator-item text="Three" value="three"></zui-step-indicator-item>
 *   <zui-step-indicator-item text="Four" value="four"></zui-step-indicator-item>
 * </zui-step-indicator-bar>
 * ```
 */
let StepIndicatorBar = class StepIndicatorBar extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * size that is propagated to each `zui-step-indicator-item`
         */
        this.size = 'm';
    }
    get _selectedStepIndicatorItem() {
        return this.value !== undefined
            ? this._assignedStepIndicatorItems.find((item) => item.value === this.value)
            : undefined;
    }
    get _hasSelectedStepIndicatorItem() {
        return this._selectedStepIndicatorItem !== undefined;
    }
    /**
     * advance one step when possible and update `zui-step-indicator-item`'s emphasis accordingly
     */
    next() {
        var _a, _b, _c;
        if (this._hasSelectedStepIndicatorItem) {
            this.value = (_b = (_a = this._getSibling('next')) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : this.value;
        }
        else {
            const [firstItem] = this._assignedStepIndicatorItems;
            this.value = (_c = firstItem === null || firstItem === void 0 ? void 0 : firstItem.value) !== null && _c !== void 0 ? _c : this.value;
        }
    }
    /**
     * take a step back when possible and update `zui-step-indicator-item`'s emphasis accordingly
     */
    previous() {
        var _a, _b;
        if (this._hasSelectedStepIndicatorItem) {
            this.value = (_b = (_a = this._getSibling('previous')) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : this.value;
        }
    }
    _getSibling(direction) {
        // FIXME: this should be called conditionally
        return traverseDOMSiblingsByStepAndDirection(this._selectedStepIndicatorItem, direction);
    }
    _propagateSizeChange() {
        this._assignedStepIndicatorItems.forEach((item) => (item.size = this.size));
    }
    _propagateValueChange() {
        if (this._hasSelectedStepIndicatorItem) {
            this._assignedStepIndicatorItems.forEach((stepIndicatorItem) => {
                // FIXME: this should be called conditionally
                if (isElementBeforeOther(stepIndicatorItem, this._selectedStepIndicatorItem)) {
                    stepIndicatorItem.emphasis = 'visited';
                    // FIXME: this should be called conditionally
                }
                else if (isElementAfterOther(stepIndicatorItem, this._selectedStepIndicatorItem)) {
                    stepIndicatorItem.emphasis = 'next';
                }
                else if (stepIndicatorItem === this._selectedStepIndicatorItem) {
                    stepIndicatorItem.emphasis = 'selected';
                }
            });
        }
        else {
            this._assignedStepIndicatorItems.forEach((stepIndicatorItem) => (stepIndicatorItem.emphasis = 'next'));
        }
    }
    _handleSlotChange() {
        this._propagateSizeChange();
        this._propagateValueChange();
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('size')) {
            this._propagateSizeChange();
        }
        if (changedProperties.has('value')) {
            this._propagateValueChange();
        }
    }
    render() {
        return html `<slot @slotchange="${this._handleSlotChange}"></slot>`;
    }
};
StepIndicatorBar.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    stepIndicatorBarStyles,
];
__decorate([
    property({ reflect: true, type: String })
], StepIndicatorBar.prototype, "size", void 0);
__decorate([
    property({ reflect: true, converter: stringUndefinedConverter })
], StepIndicatorBar.prototype, "value", void 0);
__decorate([
    queryAssignedElements({ selector: 'zui-step-indicator-item', flatten: true })
], StepIndicatorBar.prototype, "_assignedStepIndicatorItems", void 0);
StepIndicatorBar = __decorate([
    customElement('zui-step-indicator-bar')
], StepIndicatorBar);
export { StepIndicatorBar };
