var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseElement } from '../base/base-element.class.js';
import { hostStyles } from '../../host.styles.js';
const style = ":host{display:block;margin:0;overflow:hidden;color:var(--zui-color-error);font:var(--zui-typography-caption) !important;white-space:nowrap;text-overflow:ellipsis}"
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-help: var(--zui-color-gs-80);--zui-color-info: var(--zui-color-pb-100);--zui-color-success: var(--zui-color-sc-g-110);--zui-color-warning: var(--zui-color-sc-y-100);--zui-color-error: var(--zui-color-sc-r-100)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-help: var(--zui-color-gs-80);--zui-color-info: var(--zui-color-pb-90);--zui-color-success: var(--zui-color-sc-g-110);--zui-color-warning: var(--zui-color-sc-y-100);--zui-color-error: var(--zui-color-sc-r-90)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const errorMessageStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * A UI component for showing error messages espacially in relation with form elements like `zui-textfield`.
 * It is used internally to add error messages to form elements.
 *
 * @example
 * ```HTML
 * <zui-error-message>Something went wrong.</zui-error-message>
 * ```
 *
 * @slot - default slot to provide an error message
 */
let ErrorMessage = class ErrorMessage extends BaseElement {
    render() {
        return html `<slot></slot>`;
    }
};
ErrorMessage.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    errorMessageStyles,
];
ErrorMessage = __decorate([
    customElement('zui-error-message')
], ErrorMessage);
export { ErrorMessage };
