import type { PiralPlugin } from 'piral-core';

import type { RuntimeConfig } from '../../types';

/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'piral-core/lib/types/custom' {
  interface PiletCustomApi extends PiletConfigApi {}
}

export interface PiletConfigApi {
  /**
   * Access the config provided for the pilet in the Prial Feed Service
   * @param key Key to access the config entry.
   * @param defaultValue Return value if config could not be found.
   */
  getConfig<T>(key: string, defaultValue?: T): T;
}

export function createConfigApi(config: RuntimeConfig): PiralPlugin<PiletConfigApi> {
  return () => (api) => ({
    getConfig<T>(key: string, defaultValue?: T): T {
      if (api.meta.spec === 'v2' && api.meta.config && key in api.meta.config) {
        return api.meta.config[key];
      }

      if (key in config) {
        return (config as any)[key];
      }

      return defaultValue as T;
    },
  });
}
