var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { hostStyles } from '../../../host.styles.js';
import { RealBaseElement } from '../../base/base-element.class.js';
import '../../../mixins/icons/icon.types.js';
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { DelegateFocusMixin } from '../../../mixins/visual-focus/delegate-focus.mixin.js';
const styles = ":host{display:inline-block;---zui-tag-min-width: calc(var(--zui-gu) * 4);---zui-tag-max-width: calc(var(--zui-gu) * 20);---zui-tag-height: calc(var(--zui-gu) * 3)}button{display:inline-flex;align-items:center;justify-content:center;box-sizing:border-box;min-width:var(---zui-tag-min-width);max-width:var(---zui-tag-max-width);height:var(---zui-tag-height);margin:0;padding:0 calc(var(--zui-gu) * 1);color:var(--zui-color-tag-text);background-color:var(--zui-color-tag-background-enabled);border:1px solid var(--zui-color-tag-border);border-radius:12px;cursor:pointer}button .text-container zui-truncate-with-tooltip{font:var(--zui-typography-caption)}:host(:not([disabled],[non-interactive])) button:hover{background-color:var(--zui-color-tag-background-hovered)}:host(:not([disabled],[non-interactive])) button:active{background-color:var(--zui-color-tag-background-active)}button:focus{outline:none}:host([non-interactive]) button{cursor:default}:host([disabled]) button{background-color:var(--zui-color-tag-background-enabled);opacity:.5;pointer-events:none}:host slot[name=icon]::slotted(*){margin-left:calc(var(--zui-gu) * 1)}.tooltip-container{display:inline-block}.text-container{min-height:calc(var(--zui-gu) * 2);overflow:hidden;white-space:nowrap;text-overflow:ellipsis}:host([zui-internal-has-icon]) .text-container{max-width:calc(var(---zui-tag-max-width) - calc(var(--zui-gu) * 4.75))}"
// import deps
import '../../tooltip/tooltip.component.js';
import '../../truncate-with-tooltip/truncate-with-tooltip.component.js';
import '../../menu/menu-item/menu-item.component.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-tag-background-enabled: var(--zui-color-gs-30);--zui-color-tag-background-hovered: var(--zui-color-gs-40);--zui-color-tag-background-active: var(--zui-color-gs-50);--zui-color-tag-border: var(--zui-color-gs-60);--zui-color-tag-text: var(--zui-color-gs-100)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-tag-background-enabled: var(--zui-color-gs-110);--zui-color-tag-background-hovered: var(--zui-color-gs-115);--zui-color-tag-background-active: var(--zui-color-gs-120);--zui-color-tag-border: var(--zui-color-gs-120);--zui-color-tag-text: var(--zui-color-gs-50)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const tagStyles = css `
  ${unsafeCSS(styles)}
`;
/**
 * A Tag is used to label and categorize items and support recognition and navigation.
 * Tags can be used to navigate to items linked with the same Tag.
 *
 * ## Figma
 * - [Styleguide – Web](https://www.figma.com/file/6dkjypErYWQPfuRBD58Aey/%F0%9F%93%96--Styleguide---Web?node-id=8492%3A90165)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-tag text="ZUi web component library">
 *  <zui-icon-common-holy-placeholder slot="icon"></zui-icon-common-holy-placeholder>
 * </zui-tag>
 * ```
 *
 * @slot icon - slot for an icon
 */
let Tag = class Tag extends DelegateFocusMixin(DisabledMixin(RealBaseElement)) {
    constructor() {
        super(...arguments);
        /**
         * This makes the Tag non-interactive
         */
        this.nonInteractive = false;
        /**
         * Menu item display mode, used to display tags in the tag menu
         *
         * @private
         */
        this.menuItem = false;
        /**
         * Toggles different max widths for the tag
         *
         * @private
         */
        this.hasIcon = false;
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.hasAttribute('tabindex')) {
            this.tabIndex = 0;
        }
    }
    _updateIconSize() {
        this.hasIcon = true;
        // update icon size
        this._assignedIcons.forEach((icon) => (icon.size = 's12'));
    }
    render() {
        return html `${this.menuItem
            ? html `
          <zui-menu-item value="${ifDefined(this.value)}" ?disabled=${this.disabled} emphasis="default" size="s">
            ${this.text}
          </zui-menu-item>
        `
            : html `
          <div class="tooltip-container">
            <button ?disabled=${this.disabled}>
              <span class="text-container">
                <zui-truncate-with-tooltip delayed tooltip-trigger="hover,focus" interactive-element-selector="zui-tag">
                  ${this.text}
                </zui-truncate-with-tooltip></span
              >
              <slot name="icon" @slotchange="${this._updateIconSize}" slot="icon"></slot>
            </button>
          </div>
        `}`;
    }
};
Tag.styles = [
    hostStyles,
    ...(RealBaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    tagStyles,
];
__decorate([
    property({ reflect: true, type: String })
], Tag.prototype, "value", void 0);
__decorate([
    property({ reflect: true, type: String })
], Tag.prototype, "text", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'non-interactive' })
], Tag.prototype, "nonInteractive", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'zui-internal-as-menu-item' })
], Tag.prototype, "menuItem", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'zui-internal-has-icon' })
], Tag.prototype, "hasIcon", void 0);
__decorate([
    queryAssignedElements({ slot: 'icon', flatten: true })
], Tag.prototype, "_assignedIcons", void 0);
Tag = __decorate([
    customElement('zui-tag')
], Tag);
export { Tag };
