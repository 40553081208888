/**
 * This mixin makes it possible to include elements from the shadow DOM into the focusorder of the browser.
 * When the component gets the focus, it is delegated to the first focusable element in the ShadowRoot.
 * To achieve this, the ShadowRootOption delegatesFocus is set to true.
 * [MDN-ShadowRoot: delegatesFocus](https://developer.mozilla.org/en-US/docs/Web/API/ShadowRoot/delegatesFocus)
 *
 * Additional a tabindex of '0' is set on the component to make it focusable.
 * With the parameter setTabindex = false the tabindex is **not** set to 0.
 * **important** When you want to set a specific default tabindex which is not 0 on a component you must set this praremeter to false.
 *
 * known limitation: only components with shadow-DOM can use this mixin
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const DelegateFocusMixin = (superClass, { 
/**
 * By default, the tabindex is set to '0' to every component with this mixin
 * This option allows to set a different default tabindex in the component
 */
setTabIndex = true, } = {}) => {
    class FocusableComponentClass extends superClass {
        connectedCallback() {
            super.connectedCallback();
            // we have to check whether a tab index has be set explicitly; this can be
            // detected with the following assumptions:
            // - tabIndex property is not -1 (the default value for HTMLElement)
            // - tabIndex attribute is explicitly set (unknown value, can even be -1)
            const tabIndexPropertyAltered = this.tabIndex !== -1;
            const tabIndexAttributeGiven = this.hasAttribute('tabindex');
            const isTabIndexExplicitlySet = tabIndexPropertyAltered || tabIndexAttributeGiven;
            if (setTabIndex && !isTabIndexExplicitlySet) {
                this.tabIndex = 0;
            }
        }
    }
    // See https://github.com/microsoft/TypeScript/issues/22788 for context.
    // We decided to ignore this because the mixin is an internal util and we know all usages and are confident that only subclasses of LitElement are used
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    FocusableComponentClass.shadowRootOptions = Object.assign(Object.assign({}, superClass.shadowRootOptions), { delegatesFocus: true });
    return FocusableComponentClass;
};
