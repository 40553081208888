const extractDateStr = (dateString) => {
    const [yearStr, monthStr, dayRest] = dateString.split('-');
    const [dayStr] = [...dayRest]
        .map((digitOrChar) => (!isNaN(Number(digitOrChar)) ? digitOrChar : '|'))
        .join('')
        .split('|');
    return [yearStr, monthStr, dayStr];
};
export class InvalidDate extends Date {
    constructor(dateStr) {
        super('1931-05-35');
        this.isInvalid = true;
        const [year, month, day] = extractDateStr(dateStr).map((dateStrPart) => Number(dateStrPart));
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.year = year;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.month = month;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.day = day;
    }
    static isParseable(dateString) {
        try {
            const [year, month, day] = extractDateStr(dateString).map((dateStr) => Number(dateStr));
            return [year, month, day].every((part) => !isNaN(part)) && month >= 1 && month <= 12 && day >= 1 && day <= 31;
        }
        catch (e) {
            return false;
        }
    }
    // This enables logging of the date objects value similar to the native Date object
    toJSON() {
        return this.toISOString();
    }
    toISOString() {
        if (this.day >= 1 && this.day <= 31 && this.month >= 1 && this.month <= 12) {
            return `${this.year}-${this.month}-${this.day}T00:00:00.001+00:00`;
        }
        else {
            return 'Invalid Date';
        }
    }
}
