import type { ClientSession } from '@arnold/common/types';

import {
  clearSessionStorage,
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
  StorageKey,
} from '../utils/storage';

const userChannel = new BroadcastChannel('mayer:user');

export const removeSessionData = (): void => {
  removeSessionStorageItem(StorageKey.SESSION_DATA);
  userChannel.postMessage(null);
};

export const resetStorageData = (): void => {
  clearSessionStorage();
  userChannel.postMessage(null);
};

export const addSessionData = (data: ClientSession): void => {
  setSessionStorageItem(StorageKey.SESSION_DATA, JSON.stringify(data));
  userChannel.postMessage(data);
};

export const registerSessionSync = (): void => {
  userChannel.onmessage = (ev: MessageEvent<null | ClientSession>) => {
    const userData = ev.data;
    if (userData === null && getSessionStorageItem(StorageKey.SESSION_DATA)) {
      removeSessionStorageItem(StorageKey.SESSION_DATA);
      location.reload();
    }

    if (userData && getSessionStorageItem(StorageKey.SESSION_DATA) === null) {
      setSessionStorageItem(StorageKey.SESSION_DATA, JSON.stringify(userData));
      location.reload();
    }
  };
};
