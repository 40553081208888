import 'lit';
import { BaseElement } from '../../components/base/base-element.class.js';
export const registerStyle = (style, attributeValue) => {
    const styleElement = document.createElement('style');
    styleElement.textContent = String(style);
    if (attributeValue) {
        styleElement.setAttribute('data-style-group', attributeValue);
    }
    document.head.append(styleElement);
};
/**
 * Register theming you want to use in the app
 *
 * @param {CSSResult} theme - Theme you want to register
 * @returns {void}
 */
export const registerTheme = (theme) => {
    if (BaseElement.FEATURE_ENABLE_BUILD_THEMES) {
        console.warn('Usage of registerTheme() is not working for BuildThemes, use <zui-theme-provider theme="themeName"> instead!');
    }
    else {
        registerStyle(theme);
    }
};
/**
 * Set Theme for the application
 *
 * @param {string} themeName - Name of the theming you want to set (i.e. dark, light, translucent)
 * @returns {void}
 */
export const setAppTheme = (themeName) => {
    if (BaseElement.FEATURE_ENABLE_BUILD_THEMES) {
        console.warn('Usage of setAppTheme() is not working for BuildThemes, use <zui-theme-provider theme="themeName"> instead!');
    }
    else {
        document.getElementsByTagName('html')[0].setAttribute('theme', themeName);
    }
};
/**
 * an identity tag function meant to be `import { cssId as css }` to get nice syntax highlighting in IDE
 * @param strings
 * @param subs
 */
export const cssId = (strings, ...subs) => {
    return strings.reduce((acc, currentPart, index) => {
        // "strings[0] subs[0] strings[1] subs[1] strings[2]" -> prevent to add non-existing substitute at the end
        acc = acc + currentPart + (index < strings.length - 1 ? String(subs[index]) : '');
        return acc;
    }, '');
};
/**
 * helper function to wrap rawStyle in a passed css selector
 * @param cssBody to be wrapped
 * @param selector that will wrap the cssBody, defaults to `':host'`
 */
export const bindCssBodyToSelector = (cssBody, selector = ':host') => `${selector} {
${cssBody}
}
`;
/**
 * Internal function to define colors in various formats
 * @param name of the custom property
 * @param hex value of the color
 * @param suffix to be used for list representation, defaults to `rgb`
 * @returns CSSResult color definition
 */
export const defineColor = (name, hex, suffix = 'rgb') => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore WTF?
    const [_, r, g, b] = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const rgb = [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)];
    return `
    --${name}-${suffix}: ${rgb.join(', ')};
    --${name}: ${hex};
  `;
};
// helper to map from ColorObj with multiple colors to a string
export const mapColors = (colors) => Object.entries(colors)
    .map(([name, color]) => defineColor(name, color))
    .join('');
