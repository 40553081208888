var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { BaseElement } from '../base/base-element.class.js';
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements, queryAssignedNodes } from 'lit/decorators.js';
import { hostStyles } from '../../host.styles.js';
import { classMap } from 'lit/directives/class-map.js';
const style = ":host{display:block}#message-container>h1{margin:0;overflow:hidden;color:var(--zui-color-inline-message-m-l-header-text);white-space:nowrap;text-overflow:ellipsis}#header-text-slot{font:var(--zui-typography-subtitle1)}#message-container>p{margin:0;overflow:hidden}:host([size=s]) #message-container>p{color:var(--zui-color-inline-message-s-message-text-without-header);font:var(--zui-typography-label1)}:host([size=l]),:host([size=m]) #message-container>p{color:var(--zui-color-inline-message-m-l-message-text-without-header);font:var(--zui-typography-body)}:host([size=l]),:host([size=m]) .header-shown #message-container>p{color:var(--zui-color-inline-message-m-l-message-text-with-header)}#root:not(.header-shown) #header-text-slot{display:none}#root{display:flex;width:100%}#message-container{flex-grow:1;align-self:center;width:0;margin-bottom:calc(var(--zui-gu) * 0.25);margin-left:calc(var(--zui-gu) * 2)}:host([size=s]) #message-container{margin-left:calc(var(--zui-gu) * 1)}#icon-container{align-self:flex-start;margin-top:calc(calc(var(--zui-gu) * 11) / 16);margin-bottom:calc(calc(var(--zui-gu) * 11) / 16)}:host([size=s]) #icon-container{margin-top:calc(var(--zui-gu) * 0.25);margin-bottom:calc(var(--zui-gu) * 0.25)}"
import '../../mixins/icons/icon.types.js';
// dependencies
import '../truncate-with-tooltip/truncate-with-tooltip.component.js';
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-inline-message-m-l-message-text-without-header: var(--zui-color-gs-100);--zui-color-inline-message-m-l-header-text: var(--zui-color-gs-100);--zui-color-inline-message-m-l-message-text-with-header: var(--zui-color-gs-85);--zui-color-inline-message-s-message-text-without-header: var(--zui-color-gs-85)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-inline-message-m-l-message-text-without-header: var(--zui-color-gs-50);--zui-color-inline-message-m-l-header-text: var(--zui-color-gs-50);--zui-color-inline-message-m-l-message-text-with-header: var(--zui-color-gs-70);--zui-color-inline-message-s-message-text-without-header: var(--zui-color-gs-70)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const inlineMessageStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * The inline message is an information component which can hold an icon, a header and multiple lines of text.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=14341%3A182190)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=1%3A102402)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-inline-message header-text="Some Header Text">
 *   Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
 *   <zui-icon-common-holy-placeholder slot="icon"></zui-icon-common-holy-placeholder>
 * </zui-inline-message>
 *  ```
 *
 * @slot - This is the default slot for the text of the inline message.
 * @slot icon - Slot for the `<zui-icon-*>` of the inline message.
 * @slot headerText - Here you can insert the header text, it will overwrite the headerText property.
 */
let InlineMessage = class InlineMessage extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * Defines the size of the inline message
         */
        this.size = 'm';
    }
    get _hasHeaderText() {
        var _a;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return ((_a = this.headerText) === null || _a === void 0 ? void 0 : _a.length) > 0 || this._headerTextNodes.length > 0;
    }
    /**
     * Prints a warning if size='s' and header is set
     */
    _checkSizeAndHeader() {
        if (this.size === 's' && this._hasHeaderText) {
            // eslint-disable-next-line no-console
            console.warn("header will not be visible when size='s'");
        }
    }
    _onHeaderTextSlotChanged() {
        this.requestUpdate();
    }
    /**
     * When the icon is changing set size of icon in the icon slot.
     */
    _onIconSlotChanged() {
        this._setIconSize();
    }
    /**
     * Set size of icon in the icon slot to one size larger than the size of the inline message.
     */
    _setIconSize() {
        this._assignedIcons.forEach((icon) => {
            // The requirements of the inline message demand that the size of the icon
            // is one size bigger than the size set to the inline message
            switch (this.size) {
                case 's':
                    icon.size = 's16';
                    break;
                case 'm':
                    icon.size = 's24';
                    break;
                case 'l':
                    icon.size = 's32';
                    break;
                default:
                    // exhaustiveness checking
                    // eslint-disable-next-line no-case-declarations
                    const neverHappens = this.size;
                    break;
            }
        });
    }
    updated(_changedProperties) {
        super.updated(_changedProperties);
        this._checkSizeAndHeader();
    }
    render() {
        return html `
      <div id="root" class=${classMap({ 'header-shown': this.size !== 's' && this._hasHeaderText })}>
        <div id="icon-container"><slot name="icon" @slotchange="${this._onIconSlotChanged}"></slot></div>
        <div id="message-container">
          <h1>
            <zui-truncate-with-tooltip
              tooltip-trigger="hover,focus"
              delayed
              interactive-element-selector="zui-inline-message"
            >
              <slot id="header-text-slot" name="headerText" @slotchange="${this._onHeaderTextSlotChanged}"
                >${this.headerText}</slot
              >
            </zui-truncate-with-tooltip>
          </h1>
          <p><slot id="text-slot"></slot></p>
        </div>
      </div>
    `;
    }
};
InlineMessage.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    inlineMessageStyles,
];
__decorate([
    property({ reflect: true, attribute: 'header-text', type: String })
], InlineMessage.prototype, "headerText", void 0);
__decorate([
    property({ reflect: true, type: String })
], InlineMessage.prototype, "size", void 0);
__decorate([
    queryAssignedNodes({ slot: 'headerText', flatten: true })
], InlineMessage.prototype, "_headerTextNodes", void 0);
__decorate([
    queryAssignedElements({ slot: 'icon', flatten: true })
], InlineMessage.prototype, "_assignedIcons", void 0);
InlineMessage = __decorate([
    customElement('zui-inline-message')
], InlineMessage);
export { InlineMessage };
