var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, nothing, unsafeCSS } from 'lit';
import { customElement, queryAssignedElements, state } from 'lit/decorators.js';
import { hostStyles } from '../../host.styles.js';
import { BaseElement, RealBaseElement } from '../base/base-element.class.js';
const styles = ":host{--zui-page-header-height: calc(var(--zui-gu) * 10);display:flex;align-items:center;height:var(--zui-page-header-height);background-color:var(--zui-color-headerbar-background)}zui-divider{flex:0 0 auto;height:var(--zui-page-header-height)}slot[name=iconButton]::slotted(zui-headerbar-icon-button){flex:0 0 auto;padding:calc(var(--zui-gu) * 1)}slot:not([name])::slotted(zui-title-stack){flex:1 1 auto;padding:0 calc(var(--zui-gu) * 4);overflow:auto}zui-divider~slot:not([name])::slotted(zui-title-stack){padding-left:calc(var(--zui-gu) * 3)}"
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-headerbar-background: var(--zui-color-gs-10)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-headerbar-background: var(--zui-color-gs-100)}"
import '../headerbar/headerbar-icon-button/headerbar-icon-button.component.js';
import '../title-stack/title-stack/title-stack.component.js';
import { DisabledMixin } from '../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const themeDark = ""
const themeLight = ""
// dependencies
import '../divider/divider.component.js';
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const pageHeaderStyles = css `
  ${unsafeCSS(styles)}
`;
/**
 * Page Headers are used at the top of a page and contain the top-level information of the current page.
 * The information can be displayed with a headline or a Title Stack.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-page-header>
 *  <zui-title-stack size="m">
 *    <h1>Headline</h1>
 *    <zui-icon-user-sex-other slot="icon"></zui-icon-user-sex-other>
 *    <zui-title-stack-meta-item slot="meta">Meta Data</zui-title-stack-meta-item>
 *    <zui-title-stack-meta-item slot="meta">Date of Birth</zui-title-stack-meta-item>
 *    <zui-title-stack-meta-item slot="meta">ID Number</zui-title-stack-meta-item>
 *  </zui-title-stack>
 *  <zui-headerbar-icon-button emphasis="subtle" size="m" slot="iconButton">
 *    <zui-icon-common-holy-placeholder slot="icon"></zui-icon-common-holy-placeholder>
 *  </zui-headerbar-icon-button>'
 * </zui-page-header>
 * ```
 *
 * @slot default - This is the default slot. It's an innerHtml of the page-header-element
 * @slot iconButton - This is the slot for the zui-headerbar-icon-button
 * @cssprop --zui-page-header-height - sets the overall height of the page header
 */
let PageHeader = class PageHeader extends DisabledMixin(RealBaseElement) {
    constructor() {
        super(...arguments);
        this._hasIcon = false;
    }
    _handleIconSlotChange() {
        // force specific icon size
        this._assignedHeaderbarIconButtons.forEach((headerbarIconButton) => (headerbarIconButton.size = 'm'));
        // check for existence of slotted icons
        this._hasIcon = this._assignedHeaderbarIconButtons.length > 0;
    }
    _handleDefaultSlotchange() {
        this._assignedTitleStacks.forEach((titleStack) => (titleStack.disableWrapping = true));
    }
    render() {
        return html `
      <slot name="iconButton" @slotchange="${this._handleIconSlotChange}"></slot>
      ${this._hasIcon ? html `<zui-divider emphasis="default" size="s" orientation="vertical"></zui-divider>` : nothing}
      <slot @slotchange="${this._handleDefaultSlotchange}"></slot>
    `;
    }
};
PageHeader.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    pageHeaderStyles,
];
__decorate([
    queryAssignedElements({ slot: 'iconButton', selector: 'zui-headerbar-icon-button', flatten: true })
], PageHeader.prototype, "_assignedHeaderbarIconButtons", void 0);
__decorate([
    queryAssignedElements({ selector: 'zui-title-stack', flatten: true })
], PageHeader.prototype, "_assignedTitleStacks", void 0);
__decorate([
    state()
], PageHeader.prototype, "_hasIcon", void 0);
PageHeader = __decorate([
    customElement('zui-page-header')
], PageHeader);
export { PageHeader };
