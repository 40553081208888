import type { Dict, PageRegistration, PiralPageMeta } from 'piral-core';
import { type ComponentType, lazy } from 'react';

import * as routes from '../routes';
import { withSuspense } from '../utils/with-suspense';

const LandingPage = lazy(() => import('./Landing'));
const HomePage = lazy(() => import('./Home'));
const InstructionsForUsePage = lazy(() => import('./InstructionsForUse'));
const ProductLabelsPage = lazy(() => import('./ProductLabels'));
const LegalDocumentPage = lazy(() => import('./LegalDocument'));
const UnauthorizedPage = lazy(() => import('./Unauthorized'));
const PrivacyNoticePage = lazy(() => import('./PrivacyNotice'));

function makePage(component: ComponentType<any>, meta: PiralPageMeta): PageRegistration {
  return {
    component,
    meta,
    pilet: '',
  };
}

export function createPages(): Dict<PageRegistration> {
  return {
    [routes.home]: makePage(withSuspense(HomePage), { layout: 'default' }),
    [routes.authentication]: makePage(withSuspense(LandingPage), { layout: 'fullscreen' }),
    [routes.instructionsForUse]: makePage(withSuspense(InstructionsForUsePage), {
      layout: 'legal',
    }),
    [routes.productLabels]: makePage(withSuspense(ProductLabelsPage), { layout: 'legal' }),
    [routes.dataProtection]: makePage(withSuspense(PrivacyNoticePage), { layout: 'fullscreen' }),
    [routes.termsOfUse]: makePage(withSuspense(LegalDocumentPage), { layout: 'legal' }),
    [routes.foss]: makePage(withSuspense(LegalDocumentPage), { layout: 'legal' }),
    [routes.unauthorized]: makePage(withSuspense(UnauthorizedPage), { layout: 'fullscreen' }),
  };
}
