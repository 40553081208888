var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import { hostStyles } from '../../../host.styles.js';
import { BaseElement } from '../../base/base-element.class.js';
const styles = ":host{display:block;height:calc(var(--zui-gu) * 4)}.icon-placeholder{min-width:calc(var(--zui-gu) * 2)}#flex-wrapper{display:flex;flex-direction:row;align-items:center;justify-content:space-between;width:100%;height:100%}#text-button{color:var(--zui-color-picker-header-text-color-default);font:var(--zui-typography-h4);background-color:rgba(0,0,0,0);border:none;outline:none;cursor:pointer}:host([disabled]) #text-button{color:var(--zui-color-picker-header-text-color-disabled);cursor:auto}#text-button:focus{color:var(--zui-color-picker-header-text-color-active)}#text-button:hover{color:var(--zui-color-picker-header-text-color-hovered)}#text-button:active{color:var(--zui-color-picker-header-text-color-pressed)}"
import '../../interactive-icon/interactive-icon.component.js';
import { GetNotificationEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-picker-header-text-color-default: var(--zui-color-text-default);--zui-color-picker-header-text-color-disabled: var(--zui-color-gs-60);--zui-color-picker-header-text-color-hovered: var(--zui-color-gs-80);--zui-color-picker-header-text-color-pressed: var(--zui-color-gs-80);--zui-color-picker-header-text-color-active: var(--zui-color-gs-80);--zui-color-text-default: var(--zui-color-gs-100);--zui-color-text-on-dark-bg: var(--zui-color-gs-10);--zui-color-text-primary: var(--zui-color-pb-100);--zui-color-text-header: var(--zui-color-gs-120);--zui-color-text-secondary-line: var(--zui-color-gs-85);--zui-color-text-disabled: var(--zui-color-gs-100)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-picker-header-text-color-default: var(--zui-color-text-default);--zui-color-picker-header-text-color-disabled: var(--zui-color-gs-80);--zui-color-picker-header-text-color-hovered: var(--zui-color-gs-10);--zui-color-picker-header-text-color-pressed: var(--zui-color-gs-10);--zui-color-picker-header-text-color-active: var(--zui-color-gs-10);--zui-color-text-default: var(--zui-color-gs-50);--zui-color-text-on-dark-bg: var(--zui-color-gs-10);--zui-color-text-primary: var(--zui-color-pb-70);--zui-color-text-header: var(--zui-color-gs-10);--zui-color-text-secondary-line: var(--zui-color-gs-70);--zui-color-text-disabled: var(--zui-color-gs-50);--zui-color-text-divider: var(--zui-color-gs-70)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const headerStyles = css `
  ${unsafeCSS(styles)}
`;
class PickerHeaderPreviousSelectedEvent extends GetNotificationEventClass('picker-header-previous-selected') {
}
class PickerHeaderNextSelectedEvent extends GetNotificationEventClass('picker-header-next-selected') {
}
class PickerHeaderCurrentSelectedEvent extends GetNotificationEventClass('picker-header-current-selected') {
}
/**
 * The picker header component is a UI sub component of the date picker component. It shows a string and two buttons.
 * When the string or the buttons get pressed events get emited.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-picker-header value="June 2021">
 *  <zui-interactive-icon slot="icon-left">
 *   <zui-icon-arrows-arrow-outline-actually-centred-left></zui-icon-arrows-arrow-outline-actually-centred-left>
 *  </zui-interactive-icon>
 *  <zui-interactive-icon slot="icon-right">
 *   <zui-icon-arrows-arrow-outline-actually-centred-right></zui-icon-arrows-arrow-outline-actually-centred-right>
 *  </zui-interactive-icon>
 * </zui-picker-header>
 * ```
 * @fires {GetZuiEvent<PickerHeader, 'NextSelectedEvent'>} zui-picker-header-previous-selected - event which gets emitted when the left icon gets clicked.
 * @fires {GetZuiEvent<PickerHeader, 'PreviousSelectedEvent'>} zui-picker-header-current-selected - event which gets emitted when the text gets clicked.
 * @fires {GetZuiEvent<PickerHeader, 'CurrentSelectedEvent'>} zui-picker-header-next-selected - event which gets emitted when the right icon gets clicked.
 *
 * @slot icon-left - This is the slot for the left icon.
 * @slot icon-right - This is the slot for the right icon.
 */
let PickerHeader = class PickerHeader extends DisabledMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.NextSelectedEvent = PickerHeaderNextSelectedEvent;
        this.PreviousSelectedEvent = PickerHeaderPreviousSelectedEvent;
        this.CurrentSelectedEvent = PickerHeaderCurrentSelectedEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
})) {
    constructor() {
        super(...arguments);
        /**
         * Text of the picker header
         */
        this.value = '';
    }
    /**
     * Adds subtle emphasis to the slotted left interactive icon
     *
     */
    _handleIconLeftSlotchange() {
        this._assignedInteractiveIconsLeft.forEach((icon) => (icon.emphasis = 'subtle'));
    }
    /**
     * Adds subtle emphasis to the slotted right interactive icon
     *
     */
    _handleIconRightSlotchange() {
        this._assignedInteractiveIconsRight.forEach((icon) => (icon.emphasis = 'subtle'));
    }
    render() {
        return html `<div id="flex-wrapper"
      ><div class="icon-placeholder"
        ><slot
          id="left-icon-slot"
          name="icon-left"
          @click=${this.emitPickerHeaderPreviousSelectedEvent}
          @slotchange=${this._handleIconLeftSlotchange}
        ></slot
      ></div>
      <button id="text-button" ?disabled=${this.disabled} @click=${this.emitPickerHeaderCurrentSelectedEvent}
        >${this.value}</button
      ><div class="icon-placeholder"
        ><slot
          id="right-icon-slot"
          name="icon-right"
          @click=${this.emitPickerHeaderNextSelectedEvent}
          @slotchange=${this._handleIconRightSlotchange}
        ></slot></div
    ></div>`;
    }
};
PickerHeader.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    headerStyles,
];
__decorate([
    property({ reflect: true, type: String })
], PickerHeader.prototype, "value", void 0);
__decorate([
    queryAssignedElements({ slot: 'icon-left', selector: 'zui-interactive-icon', flatten: true })
], PickerHeader.prototype, "_assignedInteractiveIconsLeft", void 0);
__decorate([
    queryAssignedElements({ slot: 'icon-right', selector: 'zui-interactive-icon', flatten: true })
], PickerHeader.prototype, "_assignedInteractiveIconsRight", void 0);
PickerHeader = __decorate([
    customElement('zui-picker-header')
], PickerHeader);
export { PickerHeader };
