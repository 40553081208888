import { reactify, ZuiHomeScreenTile } from '@zeiss/zui-react';
import type { FC } from 'react';

import { useContent } from '../hooks/use-content';
import { useNavAction } from '../hooks/use-nav-action';
import type { HomepageTileProps } from '../types';

export const Tile: FC<HomepageTileProps> = ({ icon, title, subtitle, href, callback }) => {
  const Icon = reactify(icon);
  const tileTitle = useContent(title);
  const tileSubtitle = subtitle ? useContent(subtitle) : '';
  const action = useNavAction(href, callback);

  return (
    <ZuiHomeScreenTile size="l" tileTitle={tileTitle} tileSubtitle={tileSubtitle} onClick={action}>
      <Icon slot="icon" />
    </ZuiHomeScreenTile>
  );
};
