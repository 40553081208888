import type { InitialModalDialog } from 'piral-modals';
import { lazy } from 'react';

import { makeModal } from '../utils/make-modal';
import { withSuspense } from '../utils/with-suspense';
import {
  DELETE_USER_DIALOG_NAME,
  EDIT_USER_DIALOG_NAME,
  INVITE_USER_DIALOG_NAME,
  LOADER_DIALOG_NAME,
  SETTINGS_DIALOG_NAME,
  SUPPORT_DIALOG_NAME,
  VERSION_DIALOG_NAME,
} from './constants';

const EditUserDialog = lazy(() => import('./EditUserDialog'));
const LoaderDialog = lazy(() => import('./LoaderDialog'));
const InviteUserDialog = lazy(() => import('./InviteUserDialog'));
const SupportDialog = lazy(() => import('./SupportDialog'));
const VersionDialog = lazy(() => import('./VersionDialog'));
const SettingsDialog = lazy(() => import('./SettingsDialog'));
const DeleteUserDialog = lazy(() => import('./DeleteUserDialog'));

export function createDialogs(): Array<InitialModalDialog> {
  return [
    makeModal(VERSION_DIALOG_NAME, withSuspense(VersionDialog), {
      closable: true,
      withLayout: false,
    }),
    makeModal(SUPPORT_DIALOG_NAME, withSuspense(SupportDialog), {
      withLayout: false,
      closable: true,
    }),
    makeModal(EDIT_USER_DIALOG_NAME, withSuspense(EditUserDialog), {
      withLayout: false,
      closable: true,
    }),
    makeModal(SETTINGS_DIALOG_NAME, withSuspense(SettingsDialog), {
      withLayout: false,
      closable: true,
    }),
    makeModal(INVITE_USER_DIALOG_NAME, withSuspense(InviteUserDialog), {
      withLayout: false,
      closable: true,
    }),
    makeModal(DELETE_USER_DIALOG_NAME, withSuspense(DeleteUserDialog), {
      withLayout: false,
      closable: true,
    }),
    makeModal(LOADER_DIALOG_NAME, withSuspense(LoaderDialog, null), {
      withLayout: false,
      closable: false,
    }),
  ];
}
