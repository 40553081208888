/**
 * determine content mode based on the slotted icons and the defined text.
 *
 * @param hasAssignedIcon - the icon elements from the slot. This can be null which is the case for the initial rendering.
 * @param text the text property
 */
export const getContentClass = (hasAssignedIcon, text) => {
    // When default slot is empty and icon slot is not empty then property content ist set to 'icon-only'
    if (hasAssignedIcon && text === undefined) {
        return 'icon-only';
    }
    // When icon slot is empty and default slot is not empty then property content is set to 'text-only'
    if (!hasAssignedIcon && text !== undefined) {
        return 'text-only';
    }
    // When icon slot is not empty and default slot is not empty then property content is set to 'text-icon'
    if (hasAssignedIcon && text !== undefined) {
        return 'text-icon';
    }
    return '_';
};
