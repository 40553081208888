var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin.js';
import '../../../mixins/form-participation/form-participation.types.js';
import { FormValidationMixin } from '../../../mixins/form-participation/form-validation.mixin.js';
import { hostStyles } from '../../../host.styles.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import '../../../types.js';
import { isValidStep, isValidValue } from '../slider.utils.js';
const style = ":host(:focus){outline:none}:host{---zui-slider-thumb-diameter: calc(var(--zui-gu) * 2);---zui-slider-thumb-border: calc(var(--zui-gu) * 0.25);---zui-slider-thumb-diameter-readonly: calc(var(--zui-gu) * 1);---zui-slider-line-height: calc(var(--zui-gu) * 0.25);---zui-slider-tick-width: calc(var(--zui-gu) * 0.125);---zui-slider-tick-height: calc(var(--zui-gu) * 0.5);---zui-slider-tick-top-margin: calc(var(--zui-gu) * 1);--zui-slider-padding: 8;width:var(--zui-slider-width, 100%);height:fit-content;min-height:calc(var(--zui-gu) * 3.625);overflow:hidden}:host .main-container{display:grid}:host .main-container>*{grid-row-start:1;grid-column-start:1}:host([orientation=vertical]){padding:calc(var(--zui-gu) * 1) 0 calc(var(--zui-gu) * 1) 0}:host([orientation=vertical]) .main-container{display:inline-flex}"
// import all the components we need
import '../slider-scale/slider-scale.component.js';
import '../slider-custom/slider-custom.component.js';
import { RealSliderBaseClass } from '../slider-base.class.js';
import { DelegateFocusMixin } from '../../../mixins/visual-focus/delegate-focus.mixin.js';
import '../../../contracts/event.contracts.js';
import '../../../contracts/event.classes.js';
import { numberUndefinedConverter } from '../../../utils/component.utils.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
import { BaseElement } from '../../base/base-element.class.js';
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const sliderStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * This is the wrapper for the custom slider and the scale. The component acts similar to a standard HTML `<input
 * type="range">` with additional features and styling.
 *
 * The slider is a form element that is used to select a number value from a range of values, range is defined by a
 * min and max value.
 * It's possible to have ticks and labels to visualize the selectable values.
 *
 *  As the component uses `<zui-slider-custom>`, this component is similar in most of the behavior and styling to
 *  `<zui-slider-range>`,  with the difference that `<zui-slider>` parametrizes `<zui-slider-custom>` to have only
 *  thumb, while `<zui-slider-range>` delivers a two thumb `<zui-slider-custom>`.
 *
 * ### Tick labels
 * You can define a format pattern for ticks with the `label-format` attribute.
 * The rules for this format string can be found here: https://github.com/alexei/sprintf.js/
 *  some examples:
 *
 * - "%s" for strings
 * - "%.2f" for floats with a certain amount of decimal places
 * - "%f" for pure floats
 * - "+%.2f%%" for having a "+" as prefix and "%" as suffix
 *
 * If the label-format is not sufficient, you can define your own custom ticks by using the `<zui-slider-tick-label>` component, i.e.:
 *
 * ```html
 *  <zui-slider
 *    min="0"
 *    max="10"
 *    value="0"
 *    step="1"
 *    label-interval="1"
 *    ghost-handle="2"
 *    orientation="horizontal">
 *    <zui-slider-tick-label>0.1 x</zui-slider-tick-label>
 *    <zui-slider-tick-label>0.25 x</zui-slider-tick-label>
 *    <zui-slider-tick-label>0.5 x</zui-slider-tick-label>
 *    <zui-slider-tick-label>1 x</zui-slider-tick-label>
 *    <zui-slider-tick-label>2 x</zui-slider-tick-label>
 * </zui-slider>
 * ```
 *
 * **Important note 1**: The slider only works properly if the step value is integer divisible with regard to the
 *  minimum / maximum value
 *
 * **Important note 2**: When you change the `value` via code you have to make sure that the value
 * is compatible with `min`, `max` and `step` on your own.
 * The component will not prevent you from setting invalid values via code but the visual behaviour will likely break.
 *
 * **Important note 3**: If you change the custom offset during runtime, the labels may not be aligned correctly.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2729)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=1%3A102409)
 *
 * @example
 *
 * ```html
 *  <zui-slider
 *    min="0"
 *    max="10"
 *    value="0"
 *    step="1"
 *    active-line-start="3"
 *    tick-interval="0.5"
 *    label-interval="1"
 *    label-format="%.2f"
 *    readonly
 *    >
 *      <zui-slider-tick-label>one</<zui-slider-tick-label>
 *      <zui-slider-tick-label>two</<zui-slider-tick-label>
 *    </zui-slider>
 * ```
 * @cssprop --zui-slider-width - The width of the slider (and scale)
 * @cssprop --zui-slider-vertical-height - The height of the slider (and scale), when it's used in vertical orientation
 * @cssprop --zui-slider-padding - Defines a custom offset for the slider and the scale (set it without an unit!)
 * @cssprop --zui-slider-min-padding - Defines a custom offset for the left-handed mininum area for the scale (set it without an unit!)
 * @cssprop --zui-slider-max-padding - Defines a custom offset for the right-handed maximum area for the scale (set it without an unit!)
 *
 * @fires change - The change event is fired when the value has changed
 * @fires input - The input event is fired when the value of the has received input
 * @slot - The default slot, used for the custom tick labels
 */
let Slider = class Slider extends FormValidationMixin(FormDataHandlingMixin(DelegateFocusMixin(RealSliderBaseClass))) {
    constructor() {
        super();
        /**
         * the tick interval for the slider
         */
        this.tickInterval = 0;
        /**
         * the label interval for the slider
         */
        this.labelInterval = 0;
        /**
         * the enabled/ disabled state of the active line
         */
        this.activeLineDisabled = false;
        /**
         * the orientation can be either horizontal or vertical; horizontal by default
         */
        this.orientation = 'horizontal';
        /**
         * If set, the ghost handle is enabled and set to the given value
         *
         */
        this.ghostHandle = undefined;
        /**
         * the value of the slider
         */
        this.value = this.min;
        this._overflowValidator = () => {
            return this.value <= this.max;
        };
        this._underflowValidator = () => {
            return this.value >= this.min;
        };
        this._stepMismatchValidator = () => {
            if (this.step === 'any' || this.step === 0) {
                return true;
            }
            return isValidStep(this.min, this.max, this.step) && isValidValue(this.min, this.value, this.step);
        };
        this.addValidator({
            type: 'rangeUnderflow',
            validator: this._underflowValidator,
            validatesOnProperties: ['min'],
        });
        this.addValidator({
            type: 'rangeOverflow',
            validator: this._overflowValidator,
            validatesOnProperties: ['max'],
        });
        this.addValidator({
            type: 'stepMismatch',
            validator: this._stepMismatchValidator,
            validatesOnProperties: ['step'],
        });
        this.setDefaultValidityMessages({ rangeOverflow: 'The given value is greater than max.' });
        this.setDefaultValidityMessages({ rangeUnderflow: 'The given value is less than min.' });
        this.setDefaultValidityMessages({ stepMismatch: 'The given value does not match the step size.' });
    }
    /**
     * **deprecated** instead of 'tabindex' please use 'tabIndex', notice upper case 'I'.
     * We added this property with incorrect spelling (i in lower case), all components inherit the native tabIndex
     * property and tabindex attribute.
     * The tabindex property with wrong spelling will be removed, this only concerns the property and not the attribute.
     *
     * @deprecated
     */
    set tabindex(index) {
        const oldValue = this.tabIndex;
        this.tabIndex = index;
        // if we write our own setter we must call this. Lit documentation: https://lit.dev/docs/v1/components/properties/#accessors-custom
        this.requestUpdate('tabindex', oldValue);
    }
    get tabindex() {
        return this.tabIndex;
    }
    _syncValue({ target: { value } }) {
        this.value = Number(value);
    }
    _handleChange() {
        this.emitChangeEvent();
    }
    get _getSliderScale() {
        return html `<zui-slider-scale
      min="${this.min}"
      max="${this.max}"
      label-format="${ifDefined(this.labelFormat)}"
      label-interval="${this.labelInterval}"
      tick-interval="${this.tickInterval}"
      ?readonly="${this.readonly}"
      ?disabled="${this.disabled}"
      orientation="${this.orientation}"
    >
      <slot></slot>
    </zui-slider-scale>`;
    }
    get _getSliderCustom() {
        return html `<zui-slider-custom
      zuiFormControl
      min="${this.min}"
      max="${this.max}"
      step="${this.step}"
      .value="${this.value}"
      ghost-handle="${ifDefined(this.ghostHandle)}"
      active-line-start="${this.activeLineStart}"
      ?active-line-disabled="${this.activeLineDisabled}"
      ?readonly="${this.readonly}"
      ?disabled="${this.disabled}"
      orientation="${this.orientation}"
      @input="${this._syncValue}"
      @change="${this._handleChange}"
    ></zui-slider-custom>`;
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('tabindex')) {
            console.warn(`Deprecated tabindex property with the value: ${this.tabindex} was used on zui-slider. Please use the native tabIndex property (notice upper case 'I').`);
        }
    }
    render() {
        return this.orientation === 'horizontal'
            ? html `<div class="main-container">${this._getSliderScale}${this._getSliderCustom}</div>`
            : html `<div class="main-container">${this._getSliderCustom}${this._getSliderScale}</div>`;
    }
};
Slider.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    sliderStyles,
];
__decorate([
    property({ noAccessor: true, attribute: false })
], Slider.prototype, "tabindex", null);
__decorate([
    property({ reflect: true, type: Number, attribute: 'tick-interval' })
], Slider.prototype, "tickInterval", void 0);
__decorate([
    property({ reflect: true, type: Number, attribute: 'label-interval' })
], Slider.prototype, "labelInterval", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'label-format' })
], Slider.prototype, "labelFormat", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'active-line-disabled' })
], Slider.prototype, "activeLineDisabled", void 0);
__decorate([
    property({ reflect: true, type: String })
], Slider.prototype, "orientation", void 0);
__decorate([
    property({ reflect: true, attribute: 'ghost-handle', converter: numberUndefinedConverter })
], Slider.prototype, "ghostHandle", void 0);
__decorate([
    property({
        reflect: true,
        type: Number,
    })
], Slider.prototype, "value", void 0);
Slider = __decorate([
    customElement('zui-slider')
], Slider);
export { Slider };
