import { ZuiHeaderbar, ZuiHeaderbarProductName, ZuiZeissLogo } from '@zeiss/zui-react';
import type { PageComponentProps } from 'piral-core';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Footer, InfoPanel, Main } from './LegalDocumentLayout.styles';

export const LegalDocumentLayout: FC<PageComponentProps> = ({ children }) => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <>
      <ZuiHeaderbar>
        <ZuiZeissLogo size="s32" slot="icon" />
        <ZuiHeaderbarProductName slot="productName" productName="Health Data Platform" />
      </ZuiHeaderbar>

      <Main>
        {children}
        <InfoPanel></InfoPanel>
      </Main>
      <Footer>
        <span>{t('copyright', { year })}</span>
      </Footer>
    </>
  );
};
