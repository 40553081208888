import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntervalWhen } from 'rooks';

interface RemainingTimeProps {
  targetTimestamp: number;
  action(): void;
}

function getDiffInSeconds(targetTimestamp: number) {
  return Math.round((targetTimestamp - Date.now()) / 1000);
}

export const RemainingTime: FC<RemainingTimeProps> = ({ targetTimestamp, action }) => {
  const { t } = useTranslation();
  const [secondsUntilAction, setSecondsUntilAction] = useState<number>(
    getDiffInSeconds(targetTimestamp)
  );
  const minutes = Math.floor(secondsUntilAction / 60);
  const seconds = secondsUntilAction % 60;

  useIntervalWhen(
    () => {
      const diff = getDiffInSeconds(targetTimestamp);
      setSecondsUntilAction(diff);

      if (diff < 1) {
        action();
      }
    },
    1_000,
    true,
    true
  );

  return minutes === 0 ? (
    <div>{t('dialogs.inactivity.logoutInTimeSeconds', { seconds })}</div>
  ) : (
    <div>{t('dialogs.inactivity.logoutInTimeMinutesSeconds', { minutes, seconds })}</div>
  );
};
