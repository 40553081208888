import { type ErrorInfoProps, SwitchErrorInfo } from 'piral-core';
import type { FC } from 'react';

import { redirectUnauthenticatedUserToLandingPage } from '../auth';
import { trackException } from '../utils/logging';

export const ErrorSwitch: FC<ErrorInfoProps> = (props) => {
  if (props.type === 'not_found') {
    redirectUnauthenticatedUserToLandingPage(true);
  } else {
    let { error } = props;
    if (error instanceof Error) {
      error.message = `Piral-Error: ${props.type} | ${error.message}`;
    } else if (typeof props.error === 'string') {
      error = new Error(`Piral-Error: ${props.type} | ${error}`);
    }

    trackException(error);
  }

  return <SwitchErrorInfo {...props} />;
};
