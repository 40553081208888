var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var TooltipDirective_1;
/* eslint-disable lit/no-template-bind */
import '@popperjs/core/lib/popper-lite.js';
import '@popperjs/core/lib/enums.js';
import { css, html, LitElement, nothing, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { commaListConverter } from '../../utils/component.utils.js';
import { generateUid } from '../../utils/portal.utils.js';
import { getHostElementFromChild, getParentElementBySelector } from '../../utils/mixin.utils.js';
import '../overlay/overlay.directive.js';
import { generatePseudoClientRect, mapTriggerEvents } from './tooltip.directive.utils.js';
import { hostStyles } from '../../host.styles.js';
const style = ":host{position:absolute;width:100%;pointer-events:none}.tooltip-content{position:absolute;top:auto;right:auto;bottom:auto;left:auto;transition:opacity ease-in-out .1s}:host([zui-internal-visible]) .tooltip-content{opacity:1}:host(:not([zui-internal-visible])) .tooltip-content{opacity:0}"
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-tooltip-border: var(--zui-color-gs-60)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-tooltip-border: var(--zui-color-gs-120)}"
const themeDark = ""
const themeLight = ""
import { BaseElement } from '../../components/base/base-element.class.js';
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
export const TOOLTIP_DIRECTIVE_STYLES = css `
  ${unsafeCSS(style)}
`;
export const TOOLTIP_PLACEMENTS = [
    'bottom-start',
    'bottom',
    'bottom-end',
    'top-start',
    'top',
    'top-end',
    'right-start',
    'right',
    'right-end',
    'left-start',
    'left',
    'left-end',
];
export const TOOLTIP_PORTAL = 'tooltip';
export const TOOLTIP_SHOW_DELAY = 300;
export const TOOLTIP_HIDE_DELAY = 200;
export const TOOLTIP_DEFAULT_EVENTS = ['keydown'];
export const TOOLTIP_SHOW_EVENTS = [
    'focus',
    'mouseenter',
    'mousemove',
    'click',
];
export const TOOLTIP_HIDE_EVENTS = ['blur', 'mouseleave', 'keydown'];
/**
 * This directive allows adding tooltip behavior to any element it is placed in.
 * It basically allows to use any element as it's carrying the logic to place the
 * slotted element, without being limited to a specific one. Nevertheless we
 * highly recommended to use the `zui-tooltip` ui component as this is covered
 * by our tests only.
 * For more informations on the tooltip itself, just visit its documentation.
 *
 * > Use arbitrary elements on your own risk. Stick to the provided
 * > `zui-tooltip` component instead.
 *
 * > Don't forget to provide an `aria-describedby` attribute pointing to the
 * > tooltip to improve accessibility.
 *
 * @example
 * HTML:
 * ```html
 * <zui-button aria-describedby="tooltip">
 *   <span>Me, the label!</span>
 *   <zui-tooltip-directive delayed id="tooltip" trigger="focus,hover">
 *     <zui-tooltip emphasis="warning">I warned you!</zui-tooltip>
 *   </zui-tooltip-directive>
 * </zui-button>
 * ```
 *
 * @slot - The default slot content will be used as tooltip.
 */
let TooltipDirective = TooltipDirective_1 = class TooltipDirective extends LitElement {
    constructor() {
        super(...arguments);
        /* eslint-enable @typescript-eslint/naming-convention */
        /**
         * enforces the "role" attribute for a11y reasons
         */
        this.role = 'tooltip';
        /**
         * allows delaying the transitions
         * (prevents tooltip from appearing until the cursor stops moving)
         */
        this.delayed = false;
        /**
         * whether to align the tooltip to the cursor or to the assigned component
         */
        this.anchoring = 'cursor';
        /**
         * defines the triggers responsible for toggling the tooltip;
         * as the property is an array, the attribute is a **comma separated** list
         */
        this.trigger = ['click', 'focus'];
        /**
         * Allows setting a custom trigger host selector.
         * Defines the closest element upwards the tree matching the selector.
         */
        this.triggerHostSelector = '*';
        /**
         * Allows setting a custom trigger host selector through a shadow DOM.
         *
         * @returns reference to the element that should register the trigger events and show the slotted content.
         */
        this.triggerHostReference = undefined;
        /**
         * internal flag for tooltip visibility to be targeted by css
         *
         * @private
         */
        this.visible = false;
        /**
         * The destination overlay name is passed through.
         */
        this.portal = `${TOOLTIP_PORTAL}-${generateUid()}`;
        this._clientRect = generatePseudoClientRect(0, 0);
        // stores all derived event names which are used as tooltip triggers
        this._triggerEvents = new Set(TOOLTIP_DEFAULT_EVENTS);
        // stores all currently registered trigger event listeners
        this._triggerListeners = new Map();
        // using the mouse cursor as positioning target requires us to build a so called `VirtualElement`
        // which implements at least the method to retrieve the `ClientRect` which itself contains in
        // particular the current cursor position
        this._pseudoCursor = { getBoundingClientRect: () => this._clientRect };
        // the click event in conjunction with focus triggers both events at the same time, which forces
        // the always second handled click event to close the tooltip again right after it has been shown
        // through the first arriving focus event - thus we have to remember this until the click
        this._hasBeenFocusedPreviously = false;
    }
    connectedCallback() {
        var _a;
        super.connectedCallback();
        this._parentElementRef =
            (_a = this.triggerHostReference) !== null && _a !== void 0 ? _a : 
            // it is safe to assume, that parentElement is always set, because it would only be null for detached
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            getParentElementBySelector(this.parentElement, this.triggerHostSelector);
        this._addTriggerListeners();
    }
    disconnectedCallback() {
        this._removeTriggerListeners();
        this._removeMoveListener();
        super.disconnectedCallback();
    }
    // as the triggers are conveniently named they have to
    // be mapped to the "real" DOM event names
    _deriveTriggerEvents() {
        // first clear existing events
        this._triggerEvents.clear();
        // add mapped events then
        mapTriggerEvents(this.trigger, [...TOOLTIP_DEFAULT_EVENTS]).forEach((event) => this._triggerEvents.add(event));
    }
    // adds necessary listeners to parent element
    _addTriggerListeners() {
        // resolve required event names
        this._triggerEvents.forEach((trigger) => {
            const listener = () => this._handleParentEvent(trigger);
            this._parentElementRef.addEventListener(trigger, listener, false);
            this._triggerListeners.set(trigger, listener);
        });
    }
    // removes all listeners from parent element
    _removeTriggerListeners() {
        this._triggerListeners.forEach((listener, trigger) => {
            this._parentElementRef.removeEventListener(trigger, listener, false);
        });
        this._triggerListeners.clear();
    }
    // handle the triggered event properly
    // we pass the name of the trigger as well, because we can't rely on the `event.type` as it's
    // simply typed as `string` in lib.dom
    // eslint-disable-next-line max-statements
    _handleParentEvent(name) {
        // remember focus events
        if (name === 'focus') {
            this._hasBeenFocusedPreviously = true;
        }
        // if we receive a click right after focus, we won't handle it, because it would cause the
        // toggle mechanism to hide the tooltip right after it has been shown by the focus
        if (name === 'click' && this._hasBeenFocusedPreviously) {
            this._hasBeenFocusedPreviously = false;
            return;
        }
        // TODO: properly initialize visible to make TS happy
        // determine the next visibility state
        let visible;
        if (TOOLTIP_SHOW_EVENTS.includes(name)) {
            visible = true;
        }
        if (TOOLTIP_HIDE_EVENTS.includes(name)) {
            visible = false;
        }
        // either delayed or not, this is always to do
        const finalize = (visible) => {
            if (this.visible !== visible) {
                this.visible = visible;
                // once visible, the overlay may need a little advise to re-position after rendering
                requestAnimationFrame(() => { var _a; return (_a = this._overlayRef) === null || _a === void 0 ? void 0 : _a.forcePositioning(); });
            }
        };
        // apply directly or use a timeout if delayed
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore because we can compare undef with false
        if (this.delayed || visible === false) {
            window.clearTimeout(this._visibilityTimeout);
            this._visibilityTimeout = window.setTimeout(() => finalize(visible), 
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            visible ? TOOLTIP_SHOW_DELAY : TOOLTIP_HIDE_DELAY);
        }
        else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            finalize(visible);
        }
    }
    // add listener for cursor update
    _addMoveListener() {
        // update the pseudo cursor on mouse move
        this._parentElementRef.addEventListener('mousemove', this._updatePseudoCursor.bind(this), false);
        // update the initial position but take cursor offset into account
        const { bottom, left } = this._parentElementRef.getBoundingClientRect();
        this._clientRect = generatePseudoClientRect(left, bottom - 12);
    }
    // remove the cursor updater
    _removeMoveListener() {
        this._parentElementRef.removeEventListener('mousemove', this._updatePseudoCursor.bind(this), false);
    }
    _updatePseudoCursor({ clientX, clientY }) {
        var _a;
        this._clientRect = generatePseudoClientRect(clientX, clientY);
        (_a = this._overlayRef) === null || _a === void 0 ? void 0 : _a.forcePositioning();
    }
    // delivers either the simulated element containing the mouse position,
    // or the parents host reference
    getPositionReference() {
        // are we directly in shadow DOM?
        return this.anchoring === 'cursor' ? this._pseudoCursor : getHostElementFromChild(this, false);
    }
    offsetHandler({ placement }) {
        if (this.anchoring === 'cursor') {
            // we need an offset of 4px to the cursor, but since we can't know about
            // the cursor size we have to assume that the default cursor with
            // approximately 16px height is used
            const addCursor = placement.includes('bottom') || placement.includes('right');
            return [0, addCursor ? 22 : 6];
        }
        else {
            // fixed positioning should always have an offset of 4px
            return TooltipDirective_1.TOOLTIP_FIXED_POSITIONING_OFFSET;
        }
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        // re-apply all listeners if the trigger changes
        if (changedProperties.has('trigger')) {
            this._deriveTriggerEvents();
            this._removeTriggerListeners();
            this._addTriggerListeners();
        }
        // add listeners for mouse cursor
        if (changedProperties.has('anchoring')) {
            this._removeMoveListener();
            if (this.anchoring) {
                this._addMoveListener();
            }
        }
    }
    render() {
        return html `${this.visible
            ? html `
          <zui-overlay-directive
            clone
            flip
            flip-padding="16"
            portal="${ifDefined(this.portal)}"
            .offsetHandler="${this.offsetHandler.bind(this)}"
            .placements="${TOOLTIP_PLACEMENTS}"
            .positionReferenceCallback="${this.getPositionReference.bind(this)}"
          >
            <slot></slot>
          </zui-overlay-directive>
        `
            : nothing}`;
    }
};
TooltipDirective.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    TOOLTIP_DIRECTIVE_STYLES,
];
/* eslint-disable @typescript-eslint/naming-convention */
TooltipDirective.TOOLTIP_FIXED_POSITIONING_OFFSET = [0, 4];
__decorate([
    property({ reflect: true, type: String })
], TooltipDirective.prototype, "role", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], TooltipDirective.prototype, "delayed", void 0);
__decorate([
    property({ reflect: true, type: String })
], TooltipDirective.prototype, "anchoring", void 0);
__decorate([
    property({ reflect: true, converter: commaListConverter() })
], TooltipDirective.prototype, "trigger", void 0);
__decorate([
    property({ reflect: true, attribute: 'trigger-host-selector', type: String })
], TooltipDirective.prototype, "triggerHostSelector", void 0);
__decorate([
    property({ attribute: false, type: Object })
], TooltipDirective.prototype, "triggerHostReference", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'zui-internal-visible' })
], TooltipDirective.prototype, "visible", void 0);
__decorate([
    property({ reflect: true, type: String })
], TooltipDirective.prototype, "portal", void 0);
__decorate([
    query('zui-overlay-directive')
], TooltipDirective.prototype, "_overlayRef", void 0);
__decorate([
    state()
], TooltipDirective.prototype, "_clientRect", void 0);
TooltipDirective = TooltipDirective_1 = __decorate([
    customElement('zui-tooltip-directive')
], TooltipDirective);
export { TooltipDirective };
