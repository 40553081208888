var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, query, queryAssignedElements } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { BaseElement } from '../../base/base-element.class.js';
import '../../menu/menu-item/menu-item.component.js';
import '../searchbar-input/searchbar-input.component.js';
import '../searchbar-results-menu/searchbar-results-menu.component.js';
import '../../select/select/select.component.js';
import { hostStyles } from '../../../host.styles.js';
const style = ":host{--zui-searchbar-results-menu-expanded-height: calc( 9.5 * ( var(--zui-body-font-size) * var(--zui-body-line-height) + calc(var(--zui-gu) * 0.625) + calc(var(--zui-gu) * 0.875) ) );--zui-searchbar-results-menu-animation-duration: 0.1s;--zui-searchbar-results-menu-margin-left: calc(var(--zui-gu) * 1);--zui-searchbar-results-menu-with-filter-margin-left: calc(var(--zui-gu) * 18);--zui-searchbar-results-menu-margin-right: calc(var(--zui-gu) * 1);--zui-searchbar-results-menu-z-index: 1;position:relative;width:100%;min-width:calc(var(--zui-gu) * 40);max-width:calc(var(--zui-gu) * 67);height:calc(var(--zui-gu) * 4.5)}.searchbar-results-menu-wrapper{position:absolute;top:calc(var(--zui-gu) * 4.5);z-index:var(--zui-searchbar-results-menu-z-index);width:100%;max-width:calc(var(--zui-gu) * 67);height:var(--zui-searchbar-results-menu-expanded-height);margin-right:var(--zui-searchbar-results-menu-margin-right);margin-left:var(--zui-searchbar-results-menu-margin-left);clip-path:polygon(-50% 0, 150% 0, 150% 150%, -50% 150%);pointer-events:none}.searchbar-results-menu-wrapper.has-filter-menu{margin-left:var(--zui-searchbar-results-menu-with-filter-margin-left)}:host([showSearchResults]) .searchbar-results-menu{transform:translateY(0);opacity:1;transition:opacity var(--zui-searchbar-results-menu-animation-duration) ease-out,transform var(--zui-searchbar-results-menu-animation-duration) ease-out;pointer-events:all}:host(:not([showSearchResults])) .searchbar-results-menu{top:0;transform:translateY(calc(var(--zui-gu) * -5));opacity:0;transition:opacity var(--zui-searchbar-results-menu-animation-duration) ease-in,transform var(--zui-searchbar-results-menu-animation-duration) ease-out;pointer-events:none}"
const theme = ""
import { GetNotificationEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const searchbarStyles = css `
  ${unsafeCSS(style)}
`;
class SearchbarFilterChangedEvent extends GetNotificationEventClass('searchbar-filter-changed') {
}
class SearchbarFilterOpenedEvent extends GetNotificationEventClass('searchbar-filter-opened') {
}
class SearchbarInputEvent extends GetNotificationEventClass('searchbar-input') {
}
class SearchbarInputBlurredEvent extends GetNotificationEventClass('searchbar-input-blurred') {
}
class SearchbarInputFocusedEvent extends GetNotificationEventClass('searchbar-input-focused') {
}
class SearchbarInputSelectedEvent extends GetNotificationEventClass('searchbar-input-selected') {
}
class SearchbarResultSelectedEvent extends GetNotificationEventClass('searchbar-result-selected') {
}
class SearchbarResultsClosedEvent extends GetNotificationEventClass('searchbar-results-closed') {
}
class SearchbarResultsOpenedEvent extends GetNotificationEventClass('searchbar-results-opened') {
}
/**
 * The Searchbar shows an input and a menu with menu items and dividers. When the menu is shown and a menu item is clicked
 * a custom event with the value of the clicked menu item is emitted and the menu is closed.
 *
 * ## Figma
 * - [Styleguide – Web](https://www.figma.com/file/6dkjypErYWQPfuRBD58Aey/%F0%9F%93%96--Styleguide---Web?node-id=3279%3A73599&viewport=279%2C103%2C0.15313252806663513)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-searchbar placeholder="Enter your search here..." value="result">
 *   <zui-menu-item value="1">First result</zui-menu-item>
 *   <zui-menu-item value="2">
 *     <zui-icon-common-holy-placeholder slot="icon"></zui-icon-common-holy-placeholder>
 *     Second result
 *   </zui-menu-item>
 *   <zui-menu-divider>Label</zui-menu-divider>
 *   <zui-menu-item value="3">Third result</zui-menu-item>
 * </zui-searchbar>
 * ```
 *
 * HTML (Searchbar with filter):
 *
 * ```html
 * <zui-searchbar placeholder="Enter your search here..." value="result">
 *   <zui-select
 *     hide-border
 *     slot="filter"
 *   >
 *     <zui-select-option value="all" selected>Everything</zui-select-option>
 *     <zui-select-option value="books">Books</zui-select-option>
 *     <zui-select-option value="movies">Movies</zui-select-option>
 *   </zui-select>
 *   <zui-menu-item value="1">First result</zui-menu-item>
 *   <zui-menu-item value="2">
 *     <zui-icon-common-holy-placeholder slot="icon"></zui-icon-common-holy-placeholder>
 *     Second result
 *   </zui-menu-item>
 *   <zui-menu-divider>Label</zui-menu-divider>
 *   <zui-menu-item value="3">Third result</zui-menu-item>
 * </zui-searchbar>
 * ```
 *
 * HTML (Searchbar with multi select filter):
 *
 * ```html
 * <zui-searchbar placeholder="Enter your search here..." value="result">
 *   <zui-select
 *     all-item-label="Everything"
 *     hide-border
 *     multiple
 *     show-all-item
 *     slot="filter"
 *   >
 *     <zui-select-placeholder slot="placeholder" pattern-all="%selection" pattern-many="%selection">
 *       Filter...
 *     </zui-select-placeholder>
 *     <zui-select-option value="books">Books</zui-select-option>
 *     <zui-select-option value="movies">Movies</zui-select-option>
 *   </zui-select>
 *   <zui-menu-item value="1">First result</zui-menu-item>
 *   <zui-menu-item value="2">
 *     <zui-icon-common-holy-placeholder slot="icon"></zui-icon-common-holy-placeholder>
 *     Second result
 *   </zui-menu-item>
 *   <zui-menu-divider>Label</zui-menu-divider>
 *   <zui-menu-item value="3">Third result</zui-menu-item>
 * </zui-searchbar>
 * ```
 *
 * @slot - Default slot for menu items and dividers
 * @slot filter - Slot for a select with select options
 * @fires {GetZuiEvent<Searchbar, 'FilterChangedEvent'>} zui-searchbar-filter-changed - The searchbar-filter-changed event is fired when the filter has changed - the detail value is an array of the selected option values
 * @fires {GetZuiEvent<Searchbar, 'FilterOpenedEvent'>} zui-searchbar-filter-opened - The searchbar-filter-opened event is fired when the filter has opened
 * @fires {GetZuiEvent<Searchbar, 'InputEvent'>} zui-searchbar-input - The searchbar-input event is fired when the value of the input changes
 * @fires {GetZuiEvent<Searchbar, 'InputBlurredEvent'>} zui-searchbar-input-blurred - The searchbar-input-blurred event is fired when the searchbar input has lost it's focus
 * @fires {GetZuiEvent<Searchbar, 'InputFocusedEvent'>} zui-searchbar-input-focused - The searchbar-input-focused event is fired when the searchbar input is focused
 * @fires {GetZuiEvent<Searchbar, 'InputSelectedEvent'>} zui-searchbar-input-selected - The searchbar-input-selected event is fired when the searchbar input is selected, i.e. the Enter key is invoked
 * @fires {GetZuiEvent<Searchbar, 'ResultSelectedEvent'>} zui-searchbar-result-selected - The searchbar-result-selected event is fired when a menu item is clicked - the detail value is the value of the selected menu item
 * @fires {GetZuiEvent<Searchbar, 'ResultsClosedEvent'>} zui-searchbar-results-closed - The searchbar-results-closed event is fired when the menu is closed
 * @fires {GetZuiEvent<Searchbar, 'ResultsOpenedEvent'>} zui-searchbar-results-opened - The searchbar-results-opened event is fired when the menu is opened
 * @cssprop --zui-searchbar-results-menu-animation-duration - duration of the menu toggle animation
 * @cssprop --zui-searchbar-results-menu-expanded-height - limits the height of the menu, calculated automatically by default
 * @cssprop --zui-searchbar-results-menu-margin-left - left offset of the menu
 * @cssprop --zui-searchbar-results-menu-margin-right - right offset of the menu
 * @cssprop --zui-searchbar-results-menu-z-index (deprecated) - level of the menu
 */
let Searchbar = class Searchbar extends DisabledMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.FilterChangedEvent = SearchbarFilterChangedEvent;
        this.FilterOpenedEvent = SearchbarFilterOpenedEvent;
        this.InputEvent = SearchbarInputEvent;
        this.InputBlurredEvent = SearchbarInputBlurredEvent;
        this.FocusedEvent = SearchbarInputFocusedEvent;
        this.InputSelectedEvent = SearchbarInputSelectedEvent;
        this.ResultSelectedEvent = SearchbarResultSelectedEvent;
        this.ResultsClosedEvent = SearchbarResultsClosedEvent;
        this.ResultsOpenedEvent = SearchbarResultsOpenedEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
})) {
    constructor() {
        super(...arguments);
        /**
         * Placeholder text for the searchbar input
         */
        this.placeholder = '';
        /**
         * Whether to show or hide the search results menu
         */
        this.showSearchResults = false;
        /**
         * Value of the searchbar input
         */
        this.value = '';
    }
    get _hasFilterMenu() {
        return this._assignedFilterMenu.length > 0;
    }
    /**
     * When the menu is opened the showSearchResults property is set to false and a `searchbar-results-closed` event is emitted
     */
    closeSearchbarResultsMenu() {
        if (!this.showSearchResults) {
            return;
        }
        this.showSearchResults = false;
        this.emitSearchbarResultsClosedEvent();
    }
    /**
     * When the menu is closed the showSearchResults property is set to true and a `searchbar-results-opened` event is emitted
     */
    openSearchbarResultsMenu() {
        if (this.showSearchResults) {
            return;
        }
        this.showSearchResults = true;
        this.emitSearchbarResultsOpenedEvent();
    }
    _handleSearchbarFilterChangedEvent({ target }) {
        this.emitSearchbarFilterChangedEvent({ value: target.value });
    }
    _handleSearchbarFilterOpenedEvent() {
        this.emitSearchbarFilterOpenedEvent();
    }
    _handleSearchbarFilterSlotChange() {
        this.requestUpdate();
    }
    _handleSearchbarResultSelected({ target }) {
        if (this.disabled) {
            return;
        }
        if (target.tagName.toLowerCase() === 'zui-menu-item') {
            this.emitSearchbarResultSelectedEvent({ value: target.value });
        }
    }
    _handleSearchbarInputKeyDownEvent({ code }) {
        if (this.disabled) {
            return;
        }
        if (code === 'NumpadEnter' || code === 'Enter') {
            this.emitSearchbarInputSelectedEvent({ value: this.value });
        }
    }
    _handleSearchbarInputChangedEvent(event) {
        if (event.target.tagName.toLowerCase() === 'zui-searchbar-input') {
            this.value = event.detail.value;
            this.emitSearchbarInputEvent({ value: this.value });
        }
    }
    _handleSearchbarInputBlurredEvent() {
        this.emitSearchbarInputBlurredEvent();
    }
    _handleSearchbarInputFocusedEvent({ target }) {
        this.emitSearchbarInputFocusedEvent({ value: target.value !== undefined ? target.value : '' });
    }
    render() {
        return html `
      <zui-searchbar-input
        .value="${this.value}"
        ?disabled="${this.disabled}"
        placeholder="${this.placeholder}"
        @blur="${this._handleSearchbarInputBlurredEvent}"
        @focus="${this._handleSearchbarInputFocusedEvent}"
        @zui-searchbar-input-changed="${this._handleSearchbarInputChangedEvent}"
        @keydown="${this._handleSearchbarInputKeyDownEvent}"
      >
        <slot
          name="filter"
          slot="filter"
          @change="${this._handleSearchbarFilterChangedEvent}"
          @zui-select-opened="${this._handleSearchbarFilterOpenedEvent}"
          @slotchange="${this._handleSearchbarFilterSlotChange}"
        ></slot>
      </zui-searchbar-input>
      <div class="${classMap({ 'searchbar-results-menu-wrapper': true, 'has-filter-menu': this._hasFilterMenu })}">
        <zui-searchbar-results-menu ?disabled="${this.disabled}" class="searchbar-results-menu">
          <slot @click="${this._handleSearchbarResultSelected}"></slot>
        </zui-searchbar-results-menu>
      </div>
    `;
    }
};
Searchbar.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    searchbarStyles,
];
__decorate([
    property({ reflect: true, type: String })
], Searchbar.prototype, "placeholder", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], Searchbar.prototype, "showSearchResults", void 0);
__decorate([
    property({ reflect: true, type: String })
], Searchbar.prototype, "value", void 0);
__decorate([
    query('zui-searchbar-results-menu')
], Searchbar.prototype, "resultsMenu", void 0);
__decorate([
    queryAssignedElements({ slot: 'filter', selector: 'zui-select', flatten: true })
], Searchbar.prototype, "_assignedFilterMenu", void 0);
Searchbar = __decorate([
    customElement('zui-searchbar')
], Searchbar);
export { Searchbar };
