var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { cycle, isDefined, numberUndefinedConverter } from '../../../utils/component.utils.js';
import '../../../types.js';
import { addLeadingZeros } from '../../date-picker/utils/date-picker-input.utils.js';
import { sliceAndClamp } from '../utils/time-picker.utils.js';
import { DelegateFocusMixin } from '../../../mixins/visual-focus/delegate-focus.mixin.js';
import { classMap } from 'lit/directives/class-map.js';
import { hostStyles } from '../../../host.styles.js';
const sharedStyles = ":host([disabled]){pointer-events:none}input{height:calc(var(--zui-gu) * 3.75);padding:0;color:var(--zui-color-text-default);font:var(--zui-typography-body);line-height:calc(var(--zui-gu) * 3.75);background-color:rgba(0,0,0,0);border:none;box-shadow:none}input:focus{outline:none}input:active{outline:none}input::placeholder{color:var(--zui-color-placeholder-input)}input::selection{background:var(--zui-color-selection-background)}input[type=number]{-moz-appearance:textfield}input[type=number]::-webkit-outer-spin-button,input[type=number]::-webkit-inner-spin-button{margin:0;-webkit-appearance:none}"
const styles = "input{width:calc(var(--zui-gu) * 2)}input.show-placeholder{width:var(--zui-time-picker-input-part-number-placeholder-width, calc(var(--zui-gu) * 2.5))}:host([readonly]) input::placeholder{color:rgba(0,0,0,0);opacity:unset}"
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-placeholder-input: var(--zui-color-gs-80);--zui-color-input-bottom-line-default-enabled: var(--zui-color-gs-60);--zui-color-input-bottom-line-default-hover: var(--zui-color-gs-120);--zui-color-input-bottom-line-default-focused: var(--zui-color-gs-100);--zui-color-input-bottom-line-default-pressed: var(--zui-color-gs-120)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-placeholder-input: var(--zui-color-gs-80);--zui-color-input-bottom-line-default-enabled: var(--zui-color-gs-120);--zui-color-input-bottom-line-default-hover: var(--zui-color-gs-120);--zui-color-input-bottom-line-default-focused: var(--zui-color-gs-120);--zui-color-input-bottom-line-default-pressed: var(--zui-color-gs-120)}"
import { GetSharedEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const timePickerInputPartStyles = css `
  ${unsafeCSS(sharedStyles)}
`;
const timePickerInputPartNumberComponentStyles = css `
  ${unsafeCSS(styles)}
`;
class TimePickerInputPartFocusNextEvent extends GetSharedEventClass('time-picker-input-part-focus-next') {
}
class TimePickerInputPartFocusPreviousEvent extends GetSharedEventClass('time-picker-input-part-focus-previous') {
}
class TimePickerInputPartNumberChangedEvent extends GetSharedEventClass('time-picker-input-part-number-changed') {
}
class TimePickerInputPartNumberInputEvent extends GetSharedEventClass('time-picker-input-part-number-input') {
}
/**
 * The `zui-time-picker-input-part-number` is part of the `zui-time-picker-input` and is not designed for single usage.
 *
 * @example
 * html```
 * <zui-time-picker-input-part-number max="23" min="0" placeholder="HH"></zui-time-picker-input-part-number>
 * ```
 * @cssprop --zui-time-picker-input-part-number-placeholder-width - width of the input when no value is present
 */
let TimePickerInputPartNumber = class TimePickerInputPartNumber extends DisabledMixin(DelegateFocusMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.FocusNextEvent = TimePickerInputPartFocusNextEvent;
        this.FocusPreviousEvent = TimePickerInputPartFocusPreviousEvent;
        this.ChangedEvent = TimePickerInputPartNumberChangedEvent;
        this.InputEvent = TimePickerInputPartNumberInputEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}))) {
    constructor() {
        super(...arguments);
        /**
         * readonly
         */
        this.readonly = false;
        /**
         * step
         *
         * @private
         */
        this.step = 1;
        this.showPlaceholder = true;
    }
    /**
     * value
     *
     * @returns value number | undefined
     */
    get value() {
        return this._internalValue;
    }
    set value(value) {
        const oldValue = this._internalValue;
        this._internalValue = value;
        this.showPlaceholder = !isDefined(value);
        this.requestUpdate('value', oldValue);
    }
    get _paddedValue() {
        if (isDefined(this.value)) {
            return addLeadingZeros(this.value, String(this.max).length);
        }
        return null;
    }
    get _initialValue() {
        var _a;
        return (_a = this.value) !== null && _a !== void 0 ? _a : this.defaultValue;
    }
    _handleTimePickerInputPartNumberFocusEvent() {
        this._inputRef.select();
    }
    _handleTimePickerInputPartNumberInputEvent(event) {
        event.stopPropagation();
        this._inputRef.value = sliceAndClamp(this._inputRef.value, this.min, this.max);
        this.showPlaceholder = this._inputRef.value.length === 0;
        // TODO: this is too much magic and should be extracted
        this._emitTimePickerInputPartNumberInputEvent({
            value: isNaN(this._inputRef.valueAsNumber) ? null : this._inputRef.valueAsNumber,
        });
        if (this._inputRef.value.length === String(this.max).length) {
            this._emitTimePickerInputPartFocusNextEvent();
        }
    }
    _handleTimePickerInputPartNumberBlurredEvent() {
        this._commitInputValue();
    }
    _commitInputValue() {
        var _a;
        const oldValue = this.value;
        if (oldValue === undefined && isNaN(this._inputRef.valueAsNumber)) {
            return;
        }
        const isValidValue = !isNaN(this._inputRef.valueAsNumber) &&
            this._inputRef.valueAsNumber >= this.min &&
            this._inputRef.valueAsNumber <= this.max;
        this.value = isValidValue ? this._inputRef.valueAsNumber : undefined;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore because TS does not know about the DOM
        this._inputRef.value = this._paddedValue;
        if (oldValue !== this.value) {
            this._emitTimePickerInputPartNumberChangedEvent({ value: (_a = this.value) !== null && _a !== void 0 ? _a : null });
        }
    }
    _handleTimePickerInputPartNumberKeydownEvent(event) {
        var _a, _b;
        if (this.readonly) {
            return;
        }
        switch (event.key) {
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
            case '0':
            case 'ArrowLeft':
            case 'ArrowRight':
            case 'Delete':
            case 'Tab':
                break;
            case 'ArrowUp':
                {
                    event.preventDefault();
                    this._inputRef.value = isNaN(this._inputRef.valueAsNumber)
                        ? String((_a = this._initialValue) !== null && _a !== void 0 ? _a : this.min)
                        : String(cycle(this._inputRef.valueAsNumber, this.min, this.max, this.step, 'increase'));
                    this.showPlaceholder = this._inputRef.value.length === 0;
                    // TODO: this is too much magic and should be extracted
                    this._emitTimePickerInputPartNumberInputEvent({
                        value: isNaN(this._inputRef.valueAsNumber) ? null : this._inputRef.valueAsNumber,
                    });
                }
                break;
            case 'ArrowDown':
                {
                    event.preventDefault();
                    this._inputRef.value = isNaN(this._inputRef.valueAsNumber)
                        ? String((_b = this._initialValue) !== null && _b !== void 0 ? _b : this.max)
                        : String(cycle(this._inputRef.valueAsNumber, this.min, this.max, this.step, 'decrease'));
                    this.showPlaceholder = this._inputRef.value.length === 0;
                    // TODO: this is too much magic and should be extracted
                    this._emitTimePickerInputPartNumberInputEvent({
                        value: isNaN(this._inputRef.valueAsNumber) ? null : this._inputRef.valueAsNumber,
                    });
                }
                break;
            case 'Backspace':
                if (this._inputRef.value === '') {
                    event.preventDefault();
                    this._emitTimePickerInputPartFocusPreviousEvent();
                }
                break;
            case 'Enter':
                event.preventDefault();
                event.stopPropagation();
                this._commitInputValue();
                break;
            default:
                event.preventDefault();
                break;
        }
    }
    render() {
        return html `
      <input
        .value="${this._paddedValue}"
        ?disabled="${this.disabled}"
        ?readonly="${this.readonly}"
        class="${classMap({ 'show-placeholder': this.showPlaceholder })}"
        max="${this.max}"
        min="${this.min}"
        placeholder="${ifDefined(this.placeholder)}"
        step="${this.step}"
        type="number"
        title=""
        @blur=${this._handleTimePickerInputPartNumberBlurredEvent}
        @focus=${this._handleTimePickerInputPartNumberFocusEvent}
        @input=${this._handleTimePickerInputPartNumberInputEvent}
        @keydown=${this._handleTimePickerInputPartNumberKeydownEvent}
      />
    `;
    }
};
TimePickerInputPartNumber.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    timePickerInputPartStyles,
    timePickerInputPartNumberComponentStyles,
];
__decorate([
    property({ reflect: true, type: Number })
], TimePickerInputPartNumber.prototype, "max", void 0);
__decorate([
    property({ reflect: true, type: Number })
], TimePickerInputPartNumber.prototype, "min", void 0);
__decorate([
    property({ reflect: true, type: String })
], TimePickerInputPartNumber.prototype, "placeholder", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], TimePickerInputPartNumber.prototype, "readonly", void 0);
__decorate([
    property({ reflect: true, type: Number })
], TimePickerInputPartNumber.prototype, "step", void 0);
__decorate([
    property({ reflect: true, converter: numberUndefinedConverter })
], TimePickerInputPartNumber.prototype, "value", null);
__decorate([
    property({ reflect: true, attribute: 'default-value', converter: numberUndefinedConverter })
], TimePickerInputPartNumber.prototype, "defaultValue", void 0);
__decorate([
    state()
], TimePickerInputPartNumber.prototype, "showPlaceholder", void 0);
__decorate([
    query('input')
], TimePickerInputPartNumber.prototype, "_inputRef", void 0);
TimePickerInputPartNumber = __decorate([
    customElement('zui-time-picker-input-part-number')
], TimePickerInputPartNumber);
export { TimePickerInputPartNumber };
