import styled from 'styled-components';

export const DataStorageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 var(--arn-space-8) var(--arn-space-8);
  min-height: var(--arn-space-20);
`;

export const DataStorageLabel = styled.div`
  font: var(--zui-typography-label2);
  text-transform: uppercase;
`;

export const DataStorageLoadingIndicatorContainer = styled.div`
  padding: var(--arn-space-2) 0 13px 0;
`;

export const DataStorageProgressBarContainer = styled.div`
  padding: calc(var(--zui-gu) * 0.75) 0;
`;

export const DataStorageNumbersContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const DataStorageQuota = styled.div`
  font: var(--zui-typography-label1);
`;
