import type { ClientSession, User } from '@arnold/common/types';
import type { PiralPlugin } from 'piral-core';

import { getSessionData, getUserData } from '../../auth/authenticated-user';

/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'piral-core/lib/types/custom' {
  interface PiletCustomApi extends PiletAuthApi {}
}

export interface AuthConfig {}

export interface PiletAuthApi {
  /**
   * Returns the data of the User parsed from the ZEISS ID JWT-Token.
   */
  getUserData(): User | undefined;
  /**
   * Returns the data of the User parsed from the ZEISS ID JWT-Token and context data about the session of the User.
   */
  getSessionData(): ClientSession | undefined;
  /**
   * Returns all action groups the user is assigned to via assignment of presets.
   */
  getActionGroups(): Array<string>;
}

export function createAuthApi(): PiralPlugin<PiletAuthApi> {
  return () => () => ({
    getUserData() {
      return getUserData();
    },
    getSessionData() {
      return getSessionData();
    },
    getActionGroups() {
      return getSessionData()?.actionGroups || [];
    },
  });
}
