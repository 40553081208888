var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { commaListConverter, numberUndefinedConverter } from '../../../utils/component.utils.js';
import '../../../mixins/form-participation/form-participation.types.js';
import { css, html, unsafeCSS } from 'lit';
import { isValidRangeValue, isValidStep } from '../slider.utils.js';
import { customElement, property } from 'lit/decorators.js';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin.js';
import { FormValidationMixin } from '../../../mixins/form-participation/form-validation.mixin.js';
import { DelegateFocusMixin } from '../../../mixins/visual-focus/delegate-focus.mixin.js';
import '../../../types.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { hostStyles } from '../../../host.styles.js';
const style = ":host(:focus){outline:none}:host{---zui-slider-thumb-diameter: calc(var(--zui-gu) * 2);---zui-slider-thumb-border: calc(var(--zui-gu) * 0.25);---zui-slider-thumb-diameter-readonly: calc(var(--zui-gu) * 1);---zui-slider-line-height: calc(var(--zui-gu) * 0.25);---zui-active-line-visibility: initial;---zui-slider-tick-width: calc(var(--zui-gu) * 0.125);---zui-slider-tick-height: calc(var(--zui-gu) * 0.5);---zui-slider-tick-top-margin: calc(var(--zui-gu) * 1);---zui-slider-range-scale-vertical-default-offset: calc(var(--zui-gu) * 0.5);--zui-slider-padding: 12;width:var(--zui-slider-width, 100%)}:host .main-container{display:grid}:host .main-container>*{grid-row-start:1;grid-column-start:1}:host([orientation=vertical]){padding:calc(var(--zui-gu) * 1) 0 calc(var(--zui-gu) * 1) 0}:host([orientation=vertical]) .main-container{display:inline-flex}"
// import all the components we need
import '../slider-scale/slider-scale.component.js';
import '../slider-custom/slider-custom.component.js';
import { RealSliderBaseClass } from '../slider-base.class.js';
import '../../../contracts/event.contracts.js';
import '../../../contracts/event.classes.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
import { BaseElement } from '../../base/base-element.class.js';
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const sliderStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * The slider-range is a form element that is used to select a number range from a range of values.
 * The full range is defined by a min and max attribute.
 * The selected range (`value`) is an array of two values for the start and end value of the range.
 *
 *  As the component uses `<zui-slider-custom>`, this component is similar in most of the behavior and styling to
 *  `<zui-slider-range>`,  with the difference that `<zui-slider>` parametrizes `<zui-slider-custom>` to have only
 *  thumb, while `<zui-slider-range>` delivers a two thumb `<zui-slider-custom>`.
 *
 * It's possible to have ticks and labels to visualize the selectable values.
 *
 * This is a wrapper for the custom slider and the scale.
 * The custom slider represents a completely custom made slider, which enables features like multi-thumb,
 * ghost-handle, vertical and horizontal orientation, and so on.
 *
 * ### Tick labels
 * You can define a format pattern for ticks with the `label-format` attribute.
 * The rules for this format string can be found here: https://github.com/alexei/sprintf.js/
 *  some examples:
 *
 * - "%s" for strings
 * - "%.2f" for floats with a certain amount of decimal places
 * - "%f" for pure floats
 * - "+%.2f%%" for having a "+" as prefix and "%" as suffix
 *
 * @example
 *
 * ```html
 *  <zui-slider-range
 *    min="0"
 *    max="10"
 *    value="2,4"
 *    step="1"
 *    tick-interval="0.5"
 *    label-interval="1"
 *    label-format="%.2f"
 *    orientation="horizontal"
 *    readonly
 *    >
 *      <zui-slider-tick-label>one</<zui-slider-tick-label>
 *      <zui-slider-tick-label>two</<zui-slider-tick-label>
 *    </zui-slider-range>
 * ```
 *
 * @cssprop --zui-slider-width - The width of the slider (and scale)
 * @cssprop --zui-slider-vertical-height - The height of the slider (and scale), when it's used in vertical orientation
 * @cssprop --zui-slider-padding- Defines a custom offset for the slider and scale (set it without an unit!)
 * @cssprop --zui-slider-min-padding - Defines a custom offset for the left-handed mininum area for the scale (set it without an unit!)
 * @cssprop --zui-slider-max-padding - Defines a custom offset for the right-handed maximum area for the scale (set it without an unit!)
 * @fires change - The change event is fired when the value has changed
 * @fires input - The input event is fired when the value of the has received input
 * @slot - The default slot, used for the custom tick labels
 */
let SliderRange = class SliderRange extends FormValidationMixin(FormDataHandlingMixin(DelegateFocusMixin(RealSliderBaseClass))) {
    constructor() {
        super();
        /**
         * the tick interval for the slider-range
         */
        this.tickInterval = 0;
        /**
         * the label interval for the slider-range
         */
        this.labelInterval = 0;
        /**
         * the enabled/ disabled state of the active line
         */
        this.activeLineDisabled = false;
        // TODO to be implemented non-privately in it's own story
        /**
         * the orientation can be either horizontal or vertical; horizontal by default
         *
         * @private
         */
        this.orientation = 'horizontal';
        /**
         * the value of the slider; begin and end are separated by a comma
         */
        this.value = [this.min, this.max];
        this._overflowValidator = () => {
            return this.value.every((value) => value <= this.max);
        };
        this._underflowValidator = () => {
            return this.value.every((value) => value >= this.min);
        };
        this._stepMismatchValidator = () => {
            if (this.step === 'any') {
                return true;
            }
            return isValidStep(this.min, this.max, this.step) && isValidRangeValue(this.min, this.value, this.step);
        };
        this.addValidator({
            type: 'rangeUnderflow',
            validator: this._underflowValidator,
            validatesOnProperties: ['min'],
        });
        this.addValidator({
            type: 'rangeOverflow',
            validator: this._overflowValidator,
            validatesOnProperties: ['max'],
        });
        this.addValidator({
            type: 'stepMismatch',
            validator: this._stepMismatchValidator,
            validatesOnProperties: ['step'],
        });
        this.setDefaultValidityMessages({ rangeOverflow: 'The given value is greater than max.' });
        this.setDefaultValidityMessages({ rangeUnderflow: 'The given value is less than min.' });
        this.setDefaultValidityMessages({ stepMismatch: 'The given value does not match the step size.' });
    }
    /**
     * **deprecated** instead of 'tabindex' please use 'tabIndex', notice upper case 'I'.
     * We added this property with incorrect spelling (i in lower case), all components inherit the native tabIndex
     * property and tabindex attribute.
     * The tabindex property with wrong spelling will be removed, this only concerns the property and not the attribute.
     *
     * @deprecated
     */
    set tabindex(index) {
        const oldValue = this.tabIndex;
        this.tabIndex = index;
        // if we write our own setter we must call this. Lit documentation: https://lit.dev/docs/v1/components/properties/#accessors-custom
        this.requestUpdate('tabindex', oldValue);
    }
    get tabindex() {
        return this.tabIndex;
    }
    _syncValue({ target: { value } }) {
        this.value = value;
    }
    _handleChange() {
        this.emitChangeEvent();
    }
    get _getSliderScale() {
        return html `<zui-slider-scale
      min="${this.min}"
      max="${this.max}"
      label-format="${ifDefined(this.labelFormat)}"
      label-interval="${this.labelInterval}"
      tick-interval="${this.tickInterval}"
      orientation="${this.orientation}"
      ?readonly="${this.readonly}"
      ?disabled="${this.disabled}"
    >
      <slot></slot>
    </zui-slider-scale>`;
    }
    get _getSliderCustom() {
        return html `<zui-slider-custom
      zuiFormControl
      min="${this.min}"
      max="${this.max}"
      step="${this.step}"
      .value="${this.value}"
      active-line-start="${this.activeLineStart}"
      orientation="${this.orientation}"
      ?active-line-disabled="${this.activeLineDisabled}"
      ?readonly="${this.readonly}"
      ?disabled="${this.disabled}"
      @input="${this._syncValue}"
      @change="${this._handleChange}"
      dual-knobs
    ></zui-slider-custom>`;
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('tabindex')) {
            console.warn(`Deprecated tabindex property with the value: ${this.tabindex} was used on zui-slider-range. Please use the native tabIndex property (notice upper case 'I').`);
        }
    }
    render() {
        return this.orientation === 'horizontal'
            ? html `<div class="main-container">${this._getSliderScale}${this._getSliderCustom}</div>`
            : html `<div class="main-container">${this._getSliderCustom}${this._getSliderScale}</div>`;
    }
};
SliderRange.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    sliderStyles,
];
__decorate([
    property({ noAccessor: true, attribute: false })
], SliderRange.prototype, "tabindex", null);
__decorate([
    property({ reflect: true, type: Number, attribute: 'tick-interval' })
], SliderRange.prototype, "tickInterval", void 0);
__decorate([
    property({ reflect: true, type: Number, attribute: 'label-interval' })
], SliderRange.prototype, "labelInterval", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'label-format' })
], SliderRange.prototype, "labelFormat", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'active-line-disabled' })
], SliderRange.prototype, "activeLineDisabled", void 0);
__decorate([
    property({ reflect: true, type: String })
], SliderRange.prototype, "orientation", void 0);
__decorate([
    property({ reflect: true, converter: commaListConverter(numberUndefinedConverter) })
], SliderRange.prototype, "value", void 0);
SliderRange = __decorate([
    customElement('zui-slider-range')
], SliderRange);
export { SliderRange };
