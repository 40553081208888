var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement } from '../base/base-element.class.js';
import { hostStyles } from '../../host.styles.js';
const style = "hr{margin:var(--zui-horizontal-rule-margin, revert);border:none}:host([size=s]) hr,:host([size=s][orientation=horizontal]) hr{height:var(--zui-line-strength-thin)}:host([size=l]) hr,:host([size=l][orientation=horizontal]) hr{height:var(--zui-line-strength-thick)}:host([size=s][emphasis=default]) hr{background-color:var(--zui-color-thin-line-default)}:host([size=s][emphasis=highlight]) hr{background-color:var(--zui-color-thin-line-highlight)}:host([size=l][emphasis=default]) hr{background-color:var(--zui-color-thick-line-default)}:host([size=l][emphasis=highlight]) hr{background-color:var(--zui-color-thick-line-highlight)}:host([orientation=vertical]){height:100%}:host([size=s][orientation=vertical]){width:var(--zui-line-strength-thin)}:host([size=l][orientation=vertical]){width:var(--zui-line-strength-thick)}:host([size=s][orientation=vertical]) hr{width:100%;height:100%}:host([size=l][orientation=vertical]) hr{width:100%;height:100%}"
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-thin-line-default: var(--zui-color-gs-40);--zui-color-thin-line-highlight: var(--zui-color-gs-60);--zui-color-thick-line-default: var(--zui-color-gs-40);--zui-color-thick-line-highlight: var(--zui-color-gs-60);--zui-color-thick-line-primary: var(--zui-color-pb-100)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-thin-line-default: var(--zui-color-gs-110);--zui-color-thin-line-highlight: var(--zui-color-gs-120);--zui-color-thick-line-default: var(--zui-color-gs-110);--zui-color-thick-line-highlight: var(--zui-color-gs-120);--zui-color-thick-line-primary: var(--zui-color-pb-90)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const dividerStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * A divider is a horizontal line that can be used to define a thematic break.
 * The component can also be used as a vertical line too.
 * Line height has a 100% and is inherited from parent.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2739)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=32766%3A522888)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-divider emphasis="highlight" size="l"></zui-divider>
 * ```
 */
let Divider = class Divider extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * Defines one of two possible sizes (small/large);
         */
        this.size = 's';
        /**
         * Defines one of two possible emphasis states (default/highlight)
         */
        this.emphasis = 'default';
        /**
         * Defines one of two possible orientation (horizontal/vertical);
         */
        this.orientation = 'horizontal';
    }
    render() {
        return html `<hr />`;
    }
};
Divider.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    dividerStyles,
];
__decorate([
    property({ reflect: true, type: String })
], Divider.prototype, "size", void 0);
__decorate([
    property({ reflect: true, type: String })
], Divider.prototype, "emphasis", void 0);
__decorate([
    property({ reflect: true, type: String })
], Divider.prototype, "orientation", void 0);
Divider = __decorate([
    customElement('zui-divider')
], Divider);
export { Divider };
