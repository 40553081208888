import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

import { getPublicPath } from '../environment';

function isAbsolute(href: string): boolean {
  return href.startsWith('http:') || href.startsWith('https:');
}

export function useNavAction(href?: string, callback?: () => void, target?: string): () => void {
  const history = useHistory();
  const location = useLocation();

  return useCallback((): void => {
    if (href) {
      if (isAbsolute(href)) {
        window.open(href, target || '_self')?.focus();
      } else if (target) {
        const baseUrl = getPublicPath();
        const path = href.startsWith('/') ? href.substring(1) : href;
        const url = `${baseUrl}${path}`;
        window.open(url, target)?.focus();
      } else {
        history.push(href + location.search);
      }
    }

    if (callback) {
      callback();
    }
  }, [history, location, href, callback]);
}
