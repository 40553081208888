var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{display:inline-block}:host(:focus){outline:none}"
const theme = ""
import { ifDefined } from 'lit/directives/if-defined.js';
import '../../../mixins/icons/icon.types.js';
import { GetNotificationEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const themeDark = ""
const themeLight = ""
// dependencies
import '../pill-content/pill-content.component.js';
import '../../interactive-icon/interactive-icon.component.js';
// icons
import '@zeiss/zui-icons/dist/components/generated/symbols-close.component.js';
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const pillStyles = css `
  ${unsafeCSS(styles)}
`;
class PillClosedEvent extends GetNotificationEventClass('pill-closed') {
}
/**
 * The pill component displays a rounded rectangle with a simple text content or either a state dot or icon.
 * When closable an additional close icon is shown. The pill emits a close event when this icon is clicked or when
 * focused the 'Enter' key is pressed.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2728)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=40415%3A344667)
 *
 * @example
 * HTML:
 *
 * Closable pill with text
 * ```html
 * <zui-pill closable>
 *   Content
 * </zui-pill>
 * ```
 *
 * Closable pill with a state dot
 * ```html
 * <zui-pill closable>
 *   <zui-state-dot slot="icon" state="ready"></zui-state-dot>
 * </zui-pill>
 * ```
 *
 * Closable pill with an icon
 * ```html
 * <zui-pill closable>
 *   <zui-icon-common-holy-placeholder slot="icon"></zui-icon-common-holy-placeholder>
 * </zui-pill>
 * ```
 *
 * @slot - default slot for content
 * @slot icon - slot for a state dot or an icon
 * @fires {GetZuiEvent<Pill, 'ClosedEvent'>} zui-pill-closed - emitted when the pill is closable not disabled and the close icon is clicked or when focused the 'Enter' key is pressed
 *
 */
let Pill = class Pill extends DisabledMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.ClosedEvent = PillClosedEvent;
    }
})) {
    constructor() {
        super(...arguments);
        /**
         * Sets closable of the PillComponent
         */
        this.closable = false;
    }
    _handleClick() {
        this.emitPillClosedEvent();
    }
    _handleKeyDown({ key }) {
        if (this.disabled) {
            return;
        }
        if (key === 'Enter' || key === 'NumpadEnter') {
            this.emitPillClosedEvent();
        }
    }
    _updateIconSize() {
        this._assignedIcons.forEach((icon) => (icon.size = 's12'));
    }
    render() {
        return html `
      <zui-pill-content
        ?disabled="${this.disabled}"
        tabindex="${ifDefined(this.closable && !this.disabled ? 0 : undefined)}"
      >
        <slot></slot>
        <slot name="icon" @slotchange="${this._updateIconSize}" slot="icon"></slot>
        ${this.closable
            ? html `
              <zui-interactive-icon
                ?disabled="${this.disabled}"
                emphasis="subtle"
                slot="iconClose"
                tabindex="${ifDefined(this.closable && !this.disabled ? 0 : undefined)}"
                @click="${this._handleClick}"
                @keydown="${this._handleKeyDown}"
              >
                <zui-icon-symbols-close size="s8"></zui-icon-symbols-close>
              </zui-interactive-icon>
            `
            : nothing}
      </zui-pill-content>
    `;
    }
};
Pill.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    pillStyles,
];
__decorate([
    property({ reflect: true, type: Boolean })
], Pill.prototype, "closable", void 0);
__decorate([
    queryAssignedElements({ slot: 'icon', flatten: true })
], Pill.prototype, "_assignedIcons", void 0);
Pill = __decorate([
    customElement('zui-pill')
], Pill);
export { Pill };
