import dayjs, { type UnitType } from 'dayjs';
import type { TFunction } from 'i18next';
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntervalWhen } from 'rooks';

import { Time } from './BellNotificationElementTime.styles';

type Duration = {
  count: number;
  unitOfMeasurement: UnitType;
};

interface BellNotificationElementTimeProps {
  timestamp: Date;
}

export const BellNotificationElementTime: FC<BellNotificationElementTimeProps> = ({
  timestamp,
}) => {
  const { t } = useTranslation();
  const [currentDuration, setCurrentDuration] = useState(() => getDuration(timestamp));

  useIntervalWhen(() => {
    const newDuration = getDuration(timestamp);
    if (
      currentDuration.count !== newDuration.count ||
      currentDuration.unitOfMeasurement !== newDuration.unitOfMeasurement
    ) {
      setCurrentDuration(newDuration);
    }
  }, 1_000);

  return <Time>{humanizeDuration(currentDuration, t)}</Time>;
};

function getDuration(date: Date): Duration {
  const units: Array<UnitType> = ['years', 'months', 'days', 'hours', 'minutes'];
  const defaultValue: Duration = {
    count: 0,
    unitOfMeasurement: 'minutes',
  };

  return units.reduce<Duration>((acc: Duration, curr: UnitType) => {
    const diff = dayjs().diff(date, curr);

    if (diff > 0 && acc.count === 0) {
      return {
        count: diff,
        unitOfMeasurement: curr,
      };
    }

    return acc;
  }, defaultValue);
}

function humanizeDuration(duration: Duration, t: TFunction<'translation', undefined>): string {
  const { count, unitOfMeasurement } = duration;

  return count === 0
    ? t('generic.humanTime.now')
    : t(`generic.humanTime.${unitOfMeasurement}Ago`, { count });
}
