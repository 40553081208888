import { ZuiMenubar } from '@zeiss/zui-react';
import { type FC, type ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutsideClick } from 'rooks';
import styled from 'styled-components';

import { orderChildren } from '../utils/weights';
import { MenuBarItem } from './MenuBarItem';

const CustomZuiMenuBar = styled(ZuiMenubar)`
  z-index: 2 !important;
`;

export interface NavigationBarProps {
  children?: ReactNode;
}

export const NavigationBar: FC<NavigationBarProps> = ({ children }) => {
  const { t } = useTranslation();
  const ref = useRef<any>(null);
  const sorted = orderChildren(children);
  useOutsideClick(ref, () => ref.current.removeAttribute('opened'));

  return (
    <CustomZuiMenuBar ref={ref} expandable autoClose>
      <MenuBarItem icon="zui-icon-common-home" href="/" title={t('homePage.homeMenuItem')} />
      {sorted}
    </CustomZuiMenuBar>
  );
};
