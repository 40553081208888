var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
const style = ":host{--zui-skeleton-circle-diameter: 100%;width:var(--zui-skeleton-circle-diameter);height:var(--zui-skeleton-circle-diameter);overflow:hidden;background-color:var(--zui-skeleton-background-color);border-radius:50%}:host([emphasis=default]) .movement-layer,:host([emphasis=default]) .opacity-layer{background-color:var(--zui-skeleton-color-default)}:host([emphasis=highlight]) .movement-layer,:host([emphasis=highlight]) .opacity-layer{background-color:var(--zui-skeleton-color-highlight)}:host([emphasis=subtle]) .movement-layer,:host([emphasis=subtle]) .opacity-layer{background-color:var(--zui-skeleton-color-subtle)}.opacity-layer,.movement-layer{position:relative;width:100%;height:100%}.movement-layer{top:calc(var(--zui-skeleton-circle-diameter)*-1);animation:movement-animation 2s ease-out infinite}.opacity-layer{opacity:.3;animation:opacity-animation 2s ease-out infinite}@keyframes movement-animation{0%{left:0px;width:100%}75%{left:100%;width:0px}100%{left:100%;width:0px}}@keyframes opacity-animation{0%{opacity:.3}75%{opacity:1}100%{opacity:1}}"
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-skeleton-background-color: var(--zui-color-gs-10);--zui-skeleton-color-default: var(--zui-color-gs-50);--zui-skeleton-color-highlight: var(--zui-color-gs-50);--zui-skeleton-color-subtle: var(--zui-color-gs-40)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-skeleton-background-color: var(--zui-color-gs-120);--zui-skeleton-color-default: var(--zui-color-gs-80);--zui-skeleton-color-highlight: var(--zui-color-gs-80);--zui-skeleton-color-subtle: var(--zui-color-gs-90)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const skeletonCircleStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * When a Screen is loading, skeleton elements can be used as placeholders
 * to preview the user what kind of content is there to expect.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---7.4?node-id=91634-407258&t=RegTZsKlwmDcb2OT-0)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=26232-3203&t=sEdNfT0RO1qiNBIS-0)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-skeleton-circle emphasis="subtle"
 *      style="--zui-skeleton-circle-diameter: 12px;">
 * </zui-skeleton-circle>
 * ```
 *
 * @cssprop --zui-skeleton-circle-diameter - set the explicit diameter of the skeleton-circle in all possible css units
 */
let SkeletonCircle = class SkeletonCircle extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * Defines one of three possible emphasis states (default/highlight/subtle)
         */
        this.emphasis = 'default';
    }
    render() {
        return html `
      <div class="opacity-layer"></div>
      <div class="movement-layer"></div>
    `;
    }
};
SkeletonCircle.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    skeletonCircleStyles,
];
__decorate([
    property({ reflect: true, type: String })
], SkeletonCircle.prototype, "emphasis", void 0);
SkeletonCircle = __decorate([
    customElement('zui-skeleton-circle')
], SkeletonCircle);
export { SkeletonCircle };
