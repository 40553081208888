var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var MenuWrapper_1;
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
import { commaListConverter } from '../../../utils/component.utils.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styleMap } from 'lit/directives/style-map.js';
import { generateUid } from '../../../utils/portal.utils.js';
const style = ":not(:host([open][is-menu-closing]))::slotted(zui-menu){display:none}"
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { GetSharedEventClass, GetNotificationEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { SLOT_TAG } from '../../portal/portal.utils.js';
// deps
import '../menu-animation/menu-animation.component.js';
import '../../../directives/overlay/overlay.directive.js';
import '../menu/menu.component.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const menuWrapperStyles = css `
  ${unsafeCSS(style)}
`;
// TODO: fix event architecture and remove obsolete events;
// TODO: fix event types...
class MenuWrapperOpenEvent extends GetSharedEventClass('menu-wrapper-open') {
}
class MenuWrapperCloseEvent extends GetSharedEventClass('menu-wrapper-close') {
}
class MenuWrapperOpenedEvent extends GetNotificationEventClass('menu-wrapper-opened') {
}
class MenuWrapperClosedEvent extends GetNotificationEventClass('menu-wrapper-closed') {
}
/**
 * This element wraps the zui-menu and enriches and presents it as an overlay with animation.
 * Relevant custom properties for the animation are applied to the projected content.
 * Keep in mind that custom properties are not inherited by the projected content.
 * They need to be applied manually via the style attribute.
 *
 * @example
 * ```HTML
 * <zui-menu-wrapper open getPositionReference={this.getAnchorElement}>
 *     <zui-menu>
 *       <zui-menu-item>Must adhere to interface</zui-menu-item>
 *       <zui-menu-item>Must adhere to interface</zui-menu-item>
 *     </zui-menu>
 * </zui-menu-wrapper>
 * ```
 *
 * @fires menu-wrapper-open - broadcasts if menu starts opening
 * @fires menu-wrapper-opened - broadcasts if menu is fully opened
 * @fires menu-wrapper-close - notifies about menu closing
 * @fires menu-wrapper-closed - notifies about menu being completely closed
 * @fires menu-wrapper-outside-click - if another element than the projected zui-menu has been clicked
 *
 * @slot - default slot for the zui-menu
 */
let MenuWrapper = MenuWrapper_1 = class MenuWrapper extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.OpenEvent = MenuWrapperOpenEvent;
        this.CloseEvent = MenuWrapperCloseEvent;
        this.OpenedEvent = MenuWrapperOpenedEvent;
        this.ClosedEvent = MenuWrapperClosedEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * The animation duration for the ease in/out animation of the menu in ms.
         */
        this.animationDuration = 100;
        /**
         * Either show or hide the menu, triggers animations.
         */
        this.open = false;
        /**
         * Allowed placements of the select menu.
         * Multiple values can be provided as comma separated list.
         * The first setting will be applied initially, which defaults to `bottom-start`.
         *
         * @example `menu-placements="bottom-start,top-end"`
         * @see https://popper.js.org/docs/v2/constructors/#options
         */
        this.overlayPlacements = MenuWrapper_1.MENU_DEFAULT_PLACEMENTS;
        /**
         * The destination overlay name is passed through.
         */
        this.portal = `${MenuWrapper_1.MENU_PORTAL}-${generateUid()}`;
        /**
         * Is true if the menu is currently in an closing animation.
         * It is currently set as an property to apply CSS.
         */
        this._isMenuClosing = false;
    }
    _handleSlotChange() {
        var _a;
        const menuContentSlot = (_a = this._menuRef[0]) === null || _a === void 0 ? void 0 : _a.querySelector('slot');
        if (menuContentSlot) {
            menuContentSlot.setAttribute(...SLOT_TAG);
        }
    }
    /**
     * Side-effects once the closing animation has terminated
     */
    _handleTransitionEnd() {
        if (this.open) {
            this._isMenuClosing = true;
            this.emitMenuWrapperOpenedEvent();
        }
        else {
            this._isMenuClosing = false;
            this.emitMenuWrapperClosedEvent();
        }
    }
    get _menuAnimationDurationStyle() {
        return {
            '---zui-menu-animation-duration': `${this.animationDuration}ms`,
        };
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('open')) {
            if (this.open) {
                this._emitMenuWrapperOpenEvent();
                // Only emit on the transition from opened to closing state, not initial render
            }
            else if (changedProperties.get('open')) {
                this._emitMenuWrapperCloseEvent();
            }
        }
    }
    render() {
        return this.open || this._isMenuClosing
            ? html `
          <zui-overlay-directive
            flip
            level=${ifDefined(this.level)}
            portal=${ifDefined(this.portal)}
            .positionReferenceCallback=${this.getPositionReference}
            .placements=${this.overlayPlacements}
            should-project-slots
          >
            <zui-menu-animation
              @zui-menu-animation-transition-end=${this._handleTransitionEnd}
              ?open=${this.open}
              style=${styleMap(this._menuAnimationDurationStyle)}
            >
              <slot @slotchange=${this._handleSlotChange}></slot>
            </zui-menu-animation>
          </zui-overlay-directive>
        `
            : html `<slot @slotchange=${this._handleSlotChange} aria-hidden="true"></slot>`;
    }
};
MenuWrapper.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    menuWrapperStyles,
];
/* eslint-disable @typescript-eslint/naming-convention */
MenuWrapper.MENU_DEFAULT_PLACEMENTS = ['bottom-start', 'bottom-end', 'top-start', 'top-end'];
MenuWrapper.MENU_PORTAL = 'menu';
__decorate([
    property({ reflect: true, type: Number, attribute: 'animation-duration' })
], MenuWrapper.prototype, "animationDuration", void 0);
__decorate([
    property({ reflect: true, type: Object })
], MenuWrapper.prototype, "getPositionReference", void 0);
__decorate([
    property({ reflect: true, type: Number })
], MenuWrapper.prototype, "level", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], MenuWrapper.prototype, "open", void 0);
__decorate([
    property({ reflect: true, converter: commaListConverter(), attribute: 'menu-placements', type: Array })
], MenuWrapper.prototype, "overlayPlacements", void 0);
__decorate([
    property({ reflect: true, type: String })
], MenuWrapper.prototype, "portal", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'is-menu-closing' })
], MenuWrapper.prototype, "_isMenuClosing", void 0);
__decorate([
    queryAssignedElements({ flatten: true })
], MenuWrapper.prototype, "_menuRef", void 0);
MenuWrapper = MenuWrapper_1 = __decorate([
    customElement('zui-menu-wrapper')
], MenuWrapper);
export { MenuWrapper };
