import type { PiralPlugin } from 'piral-core';
import {
  debounceTime,
  filter,
  fromEvent,
  map,
  merge,
  type Observable,
  startWith,
  Subject,
  tap,
  throttleTime,
} from 'rxjs';

import { getSessionData } from '../../auth/authenticated-user';
import { isVideoPlaying } from '../../inactivity/video-watcher';
import { getLocalStorageItem, setLocalStorageItem, StorageKey } from '../../utils/storage';

/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'piral-core/lib/types/custom' {
  interface PiletCustomApi extends PiletInactivityApi {}
}

const MILLISECONDS_IN_SECONDS = 1000;

export interface PiletInactivityApi {
  inactivityEvents$: Observable<boolean>;
  inactivityModalDuration: number;
  isInactivityDialogOpen(): boolean;
}

export function createInactivityApi(): PiralPlugin<PiletInactivityApi> {
  const { idleTimeoutInSeconds, sessionExpirationInSeconds, user } = getSessionData() ?? {
    user: null,
    sessionExpirationInSeconds: 0,
    idleTimeoutInSeconds: 0,
  };

  const inactivityModalDuration =
    (sessionExpirationInSeconds - idleTimeoutInSeconds) * MILLISECONDS_IN_SECONDS;

  const activityChannel = new BroadcastChannel('activity');

  const activityEventsCurrentTab$ = merge(
    fromEvent(window, 'touchstart'),
    fromEvent(window, 'touchmove'),
    fromEvent(window, 'wheel'),
    fromEvent(window, 'mousemove'),
    fromEvent(window, 'mousedown'),
    fromEvent(window, 'resize'),
    fromEvent(window, 'keydown')
  ).pipe(
    filter(() => user !== null),
    throttleTime((idleTimeoutInSeconds * MILLISECONDS_IN_SECONDS) / 4),
    tap(() => {
      activityChannel.postMessage(Date.now());
    })
  );

  const activityEventsFromBroadcastChannel$ = fromEvent<MessageEvent>(
    activityChannel,
    'message'
  ).pipe(
    startWith({ data: Date.now() } as MessageEvent),
    filter(() => user !== null)
  );

  const newVideoHasBeenStartedLocally$ = fromEvent<Event>(window.document, 'play', {
    capture: true,
  }).pipe(debounceTime((idleTimeoutInSeconds / 4) * MILLISECONDS_IN_SECONDS));

  // Local storage change does not affect the current opened tab,
  // thus we need to trigger the Subject of the current tab by presetSubject$.next('') if isVideoPlaying() is true.
  const presetSubject$ = new Subject();
  const resetStream$ = presetSubject$.pipe(
    debounceTime((idleTimeoutInSeconds / 4) * MILLISECONDS_IN_SECONDS)
  );

  const throttledEvents$ = merge(
    activityEventsFromBroadcastChannel$,
    activityEventsCurrentTab$,
    resetStream$,
    newVideoHasBeenStartedLocally$
  ).pipe(
    tap(() => {
      if (isVideoPlaying()) {
        activityChannel.postMessage(Date.now());
        presetSubject$.next('');
      }
    }),
    debounceTime(idleTimeoutInSeconds * MILLISECONDS_IN_SECONDS),
    tap(() => {
      setLocalStorageItem(StorageKey.INACTIVE_TAB_OPEN, JSON.stringify(true));
    }),
    map(() => true)
  );

  const tabClosedEvent$: Observable<boolean> = fromEvent<Event>(
    window.document,
    'interactionTabClosed'
  ).pipe(map(() => false));

  const dialogClosedEvent$: Observable<boolean> = fromEvent<StorageEvent>(window, 'storage').pipe(
    filter((event) => event.key === StorageKey.INACTIVE_TAB_OPEN && event.newValue === 'false'),
    map(() => false)
  );

  const inactivityEvents$ = merge(throttledEvents$, tabClosedEvent$, dialogClosedEvent$);

  const isInactivityDialogOpen = (): boolean => {
    const rawData = getLocalStorageItem(StorageKey.INACTIVE_TAB_OPEN) || JSON.stringify(false);

    try {
      return JSON.parse(rawData);
    } catch (error) {
      return false;
    }
  };

  return () => () => ({
    inactivityEvents$,
    inactivityModalDuration,
    isInactivityDialogOpen,
  });
}
