var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { commaListConverter } from '../../utils/component.utils.js';
import { getProjectedText, hasOverflow } from '../../utils/dom.utils.js';
import { BaseElement } from '../base/base-element.class.js';
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const style = ":host{display:block;overflow:hidden}div#trunky-wrapper{overflow:hidden;font:inherit !important;white-space:nowrap;text-overflow:ellipsis}slot[name=tooltip]{display:none}"
const theme = ""
const themeDark = ""
const themeLight = ""
// deps
import '../../directives/tooltip/tooltip.directive.js';
import '../tooltip/tooltip.component.js';
const TRUNCATE_WITH_TOOLTIP_STYLES = css `
  ${unsafeCSS(style)}
`;
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
/**
 * Convenient component to truncate the given contents and to show a tooltip.
 *
 * Reactivity:
 * - The content of the default tooltip is updated only on `slotchange` events (triggered by adding/removing/rerendering root-level slot nodes).
 * - The enabled state of the tooltip is updated not only on `slotchange` but also when the component itself resizes.
 *
 * @example truncate text only content and show tooltip on hover
 * ```html
 * <zui-button>
 *   <zui-truncate-with-tooltip tooltip-trigger="hover,focus">
 *     <strong>This text</strong> will be <em>cut off</em> if the surrounding button becomes <code>too narrow</code>.
 *   </zui-truncate-with-tooltip>
 * </zui-button>
 * ```
 *
 * @example truncate content and preserve markup in tooltip
 * ```html
 * <zui-button>
 *   <zui-truncate-with-tooltip preserve-markup>
 *     <strong>This text</strong> will be <em>cut off</em> if the surrounding button becomes <code>too narrow</code>.
 *   </zui-truncate-with-tooltip>
 * </zui-button>
 * ```
 *
 * @example pass a custom tooltip to be used instead
 * ```html
 * <zui-button>
 *   <zui-truncate-with-tooltip tooltip-trigger="hover,focus">
 *     <strong>This text</strong> will be <em>cut off</em> if the surrounding button becomes <code>too narrow</code>.
 *     <zui-tooltip slot="tooltip"><strong>This</strong> is shown as a custom tooltip.</zui-tooltip>
 *   </zui-truncate-with-tooltip>
 * </zui-button>
 * ```
 *
 * @slot default - The default slot contents will be truncated using text ellipsis.
 * @slot tooltip - Allows passing a custom tooltip to be shown.
 */
let TruncateWithTooltip = class TruncateWithTooltip extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * Wether to preserve html contents in expanded
         * tooltip or to show a text representation only.
         */
        this.preserveMarkup = false;
        /**
         * Anchoring of the tooltip, passed through.
         * See tooltip docs for more information.
         */
        this.tooltipAnchoring = 'cursor';
        /**
         * Trigger of the tooltip, passed through.
         * See tooltip docs for more information.
         */
        this.tooltipTrigger = ['click', 'focus'];
        /**
         * Defines, whether the tooltip is to be shown with a delay
         */
        this.delayed = false;
        this._isTruncated = false;
        // watch component size changes
        this._truncationObserver = new ResizeObserver(() => this._checkTruncation());
    }
    disconnectedCallback() {
        this._truncationObserver.disconnect();
        super.disconnectedCallback();
    }
    _checkTruncation() {
        this._isTruncated = hasOverflow(this._truncateRef);
    }
    _handleSlotChange() {
        this._checkTruncation();
        this._tooltipContent = getProjectedText(this._truncateSlotRef, this.preserveMarkup);
    }
    firstRendered() {
        super.firstRendered();
        this._checkTruncation();
        this._truncationObserver.observe(this._truncateRef);
    }
    render() {
        return html `
      <div id="trunky-wrapper">
        <slot @slotchange="${this._handleSlotChange}"></slot>

        ${this._isTruncated
            ? html `
              <zui-tooltip-directive
                .trigger="${this.tooltipTrigger}"
                anchoring="${this.tooltipAnchoring}"
                trigger-host-selector="${ifDefined(this.interactiveElementSelector)}"
                ?delayed="${this.delayed}"
              >
                <slot name="tooltip">
                  <zui-tooltip>
                    ${this.preserveMarkup ? unsafeHTML(this._tooltipContent) : this._tooltipContent}
                  </zui-tooltip>
                </slot>
              </zui-tooltip-directive>
            `
            : nothing}
      </div>
    `;
    }
};
// deliberately do not import the hostStyles, because this would reset the slotted contents style
// and because this is a directive it will be used within other components, i.e. it should not
// affect any slotted content at all
TruncateWithTooltip.styles = [
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    TRUNCATE_WITH_TOOLTIP_STYLES,
];
__decorate([
    property({ reflect: true, attribute: 'preserve-markup', type: Boolean })
], TruncateWithTooltip.prototype, "preserveMarkup", void 0);
__decorate([
    property({ reflect: true, attribute: 'tooltip-anchoring', type: String })
], TruncateWithTooltip.prototype, "tooltipAnchoring", void 0);
__decorate([
    property({ reflect: true, converter: commaListConverter(), attribute: 'tooltip-trigger' })
], TruncateWithTooltip.prototype, "tooltipTrigger", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], TruncateWithTooltip.prototype, "delayed", void 0);
__decorate([
    property({ reflect: true, attribute: 'interactive-element-selector', type: String })
], TruncateWithTooltip.prototype, "interactiveElementSelector", void 0);
__decorate([
    query('slot:not([name])')
], TruncateWithTooltip.prototype, "_truncateSlotRef", void 0);
__decorate([
    query('div#trunky-wrapper')
], TruncateWithTooltip.prototype, "_truncateRef", void 0);
__decorate([
    state()
], TruncateWithTooltip.prototype, "_tooltipContent", void 0);
__decorate([
    state()
], TruncateWithTooltip.prototype, "_isTruncated", void 0);
TruncateWithTooltip = __decorate([
    customElement('zui-truncate-with-tooltip')
], TruncateWithTooltip);
export { TruncateWithTooltip };
