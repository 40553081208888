var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { createMachine, interpret } from '@xstate/fsm';
import { BaseElement } from '../../base/base-element.class.js';
import { daysOfWeekConverter, getDefaultLocale, isoDateConverter, } from '../utils/date-picker.utils.js';
import { GetSharedEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { hostStyles } from '../../../host.styles.js';
// deps
import '../date-picker-day-picker/date-picker-day-picker.component.js';
import '../date-picker-month-picker/date-picker-month-picker.component.js';
import '../date-picker-year-picker/date-picker-year-picker.component.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const style = ":host{outline:none}"
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const datePickerStyles = css `
  ${unsafeCSS(style)}
`;
var CurrentPickerEnum;
(function (CurrentPickerEnum) {
    CurrentPickerEnum["Day"] = "day";
    CurrentPickerEnum["Month"] = "month";
    CurrentPickerEnum["Year"] = "year";
})(CurrentPickerEnum || (CurrentPickerEnum = {}));
class DatePickerDateSelectedEvent extends GetSharedEventClass('date-picker-date-selected') {
}
class DatePickerCurrentDateChangedEvent extends GetSharedEventClass('date-picker-current-date-changed') {
}
/**
 * The date picker component has three different types of integrated pickers (day, month, year).
 * The day picker is for selecting a date. The corresponding headers are for quick navigation and the selection of months and years.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-date-picker
 *  disabled-days-of-week="Mo,Tuesday"
 *  locale="en-US"
 *  max="2010-01-01T00:00:00.000+01:00"
 *  min="2020-12-31T23:59:59.999+01:00">
 *  weekStart="Monday"
 * </zui-date-picker>
 * ```
 * @fires  {GetZuiEvent<DatePicker, 'DateSelectedEvent'>} date-picker-date-selected - indicates that date is selected
 * @fires  {GetZuiEvent<DatePicker, 'CurrentDateChangedEvent'>} date-picker-current-date-changed - indicates that date was changed
 */
let DatePicker = class DatePicker extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.DateSelectedEvent = DatePickerDateSelectedEvent;
        this.CurrentDateChangedEvent = DatePickerCurrentDateChangedEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * current date for the current selected picker's date range
         */
        this.currentDate = new Date();
        /**
         * disabled dates
         */
        this.disabledDates = [];
        /**
         * disabled months
         */
        this.disabledMonths = [];
        /**
         * disabled years
         */
        this.disabledYears = [];
        /**
         * disabled days of week
         */
        this.disabledDaysOfWeek = [];
        /**
         * Full locale code as defined in RFC 5646/BCP 47,
         * i.e. "en-US".
         *
         * By default, the first full locale in [navigator.languages](https://developer.mozilla.org/en-US/docs/Web/API/Navigator/languages) is used.
         */
        this.locale = getDefaultLocale();
        /**
         * max date
         */
        this.max = null;
        /**
         * min date
         */
        this.min = null;
        /**
         * selected date that is emphasized in the picker.
         */
        this.selectedDate = null;
        /**
         * disabling the day picker, i.e. just allow setting month + year
         */
        this.disableDayPicker = false;
        // state stuff
        /**
         * this is the default, complete state machine for the normal picker, i.e. using day, month and year pickers
         */
        this._defaultPickerStateMachine = createMachine({
            id: 'default-picker',
            initial: 'day',
            states: {
                day: {
                    on: {
                        // transition to MonthView and update its currentYear
                        'zui-day-picker-month-selected': {
                            target: 'month',
                            actions: ['setCurrentDate'],
                        },
                        // actually select date
                        'zui-day-picker-day-selected': {
                            target: 'day',
                            actions: ['setCurrentDate', 'setSelectedDate'],
                        },
                    },
                },
                month: {
                    on: {
                        // transition to YearView and update is currentDecade
                        'zui-month-picker-year-selected': {
                            target: 'year',
                            actions: ['setCurrentDate'],
                        },
                        // transition back to DayView
                        'zui-month-picker-month-selected': {
                            target: 'day',
                            actions: ['setCurrentDate'],
                        },
                    },
                },
                year: {
                    on: {
                        // transition back to MonthView
                        'zui-year-picker-year-selected': {
                            target: 'month',
                            actions: ['setCurrentDate'],
                        },
                        // this makes no sense; but it is actually a requirement
                        'zui-year-picker-decade-selected': {
                            target: 'day',
                            actions: [],
                        },
                    },
                },
            },
        }, {
            actions: {
                setSelectedDate: (context, event) => {
                    this.selectedDate = event.detail.value;
                    this._emitDatePickerDateSelectedEvent({ value: this.selectedDate });
                },
                setCurrentDate: (context, event) => {
                    this.currentDate = event.detail.value;
                },
            },
        });
        /**
         * state machine for just month and year pickers, i.e. without allowing to set the day
         */
        this._monthPickerStateMachine = createMachine({
            id: 'month-picker',
            initial: 'month',
            states: {
                month: {
                    on: {
                        // transition to YearView and update is currentDecade
                        'zui-month-picker-year-selected': {
                            target: 'year',
                            actions: ['setCurrentDate'],
                        },
                        // actually select the date
                        'zui-month-picker-month-selected': {
                            target: 'month',
                            actions: ['setSelectedDate'],
                        },
                    },
                },
                year: {
                    on: {
                        // transition back to MonthView
                        'zui-year-picker-year-selected': {
                            target: 'month',
                            actions: ['setCurrentDate'],
                        },
                        // this makes no sense, too
                        'zui-year-picker-decade-selected': {
                            target: 'month',
                            actions: [],
                        },
                    },
                },
            },
        }, {
            actions: {
                setSelectedDate: (context, event) => {
                    this.selectedDate = event.detail.value;
                    this._emitDatePickerDateSelectedEvent({ value: this.selectedDate });
                },
                setCurrentDate: (context, event) => {
                    this.currentDate = event.detail.value;
                },
            },
        });
        this._currentPickerMachine = this._defaultPickerStateMachine;
        this._pickerMachineService = interpret(this._currentPickerMachine);
        // intentionally disabled eslint ordering rule, because we need to assign a private variable to
        // a decorated private field
        // eslint-disable-next-line @typescript-eslint/member-ordering
        this._pickerMachineState = this._currentPickerMachine.initialState;
        this._previousStateValue = undefined;
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.hasAttribute('tabindex')) {
            this.tabIndex = 0;
        }
    }
    _handleStateEvent(event) {
        this._pickerMachineService.send(event);
    }
    get _pickerTemplates() {
        switch (this._pickerMachineState.value) {
            case CurrentPickerEnum.Day:
                return html `
          <zui-date-picker-day-picker
            .disabledDates="${this.disabledDates}"
            .disabledMonths="${this.disabledMonths}"
            .disabledYears="${this.disabledYears}"
            disabled-days-of-week="${this.disabledDaysOfWeek.join(',')}"
            locale="${this.locale}"
            .max="${this.max}"
            .min="${this.min}"
            .selectedDate="${this.selectedDate}"
            .currentDate="${this.currentDate}"
            week-start="${ifDefined(this.weekStart)}"
            @zui-day-picker-day-selected="${this._handleStateEvent}"
            @zui-day-picker-month-selected="${this._handleStateEvent}"
          >
          </zui-date-picker-day-picker>
        `;
            case CurrentPickerEnum.Month:
                return html `
          <zui-date-picker-month-picker
            .disabledMonths="${this.disabledMonths}"
            .disabledYears="${this.disabledYears}"
            locale="${this.locale}"
            .max="${this.max}"
            .min="${this.min}"
            .selectedMonth="${this.selectedDate}"
            .currentYear="${this.currentDate}"
            @zui-month-picker-month-selected="${this._handleStateEvent}"
            @zui-month-picker-year-selected="${this._handleStateEvent}"
          >
          </zui-date-picker-month-picker>
        `;
            case CurrentPickerEnum.Year:
                return html `
          <zui-date-picker-year-picker
            .disabledYears="${this.disabledYears}"
            locale="${this.locale}"
            .max="${this.max}"
            .min="${this.min}"
            .selectedYear="${this.selectedDate}"
            .currentDecade="${this.currentDate}"
            @zui-year-picker-year-selected="${this._handleStateEvent}"
            @zui-year-picker-decade-selected="${this._handleStateEvent}"
          >
          </zui-date-picker-year-picker>
        `;
            default:
                return nothing;
        }
    }
    willUpdate(changedProperties) {
        // if the dayPicker is to be disabled, we have to configure the right state machine
        if (changedProperties.has('disableDayPicker')) {
            this._pickerMachineService.stop();
            // select current picker state machine
            this._currentPickerMachine = this.disableDayPicker
                ? this._monthPickerStateMachine
                : this._defaultPickerStateMachine;
            // subscribe to state changes and emit events accordingly
            this._pickerMachineService = interpret(this._currentPickerMachine);
            this._previousStateValue = undefined;
            this._pickerMachineService.subscribe((state) => {
                // emit state change event if not initial state transition and if the state actually changed
                // we cannot use state.changed because it is true, if some actions are associated with a state, i.e. also on self-transitions
                if (this._previousStateValue !== undefined && this._previousStateValue !== state.value) {
                    this._emitDatePickerCurrentDateChangedEvent({
                        currentDate: this.currentDate,
                        currentPicker: state.value,
                    });
                }
                this._previousStateValue = state.value;
                this._pickerMachineState = state;
            });
            this._pickerMachineService.start();
        }
    }
    render() {
        return html `${this._pickerTemplates}`;
    }
};
DatePicker.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    datePickerStyles,
];
__decorate([
    property({ reflect: true, type: String, attribute: 'current-date', converter: isoDateConverter })
], DatePicker.prototype, "currentDate", void 0);
__decorate([
    property({ attribute: false })
], DatePicker.prototype, "disabledDates", void 0);
__decorate([
    property({ attribute: false })
], DatePicker.prototype, "disabledMonths", void 0);
__decorate([
    property({ attribute: false })
], DatePicker.prototype, "disabledYears", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'disabled-days-of-week', converter: daysOfWeekConverter })
], DatePicker.prototype, "disabledDaysOfWeek", void 0);
__decorate([
    property({ reflect: true, type: String })
], DatePicker.prototype, "locale", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], DatePicker.prototype, "max", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], DatePicker.prototype, "min", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'selected-date', converter: isoDateConverter })
], DatePicker.prototype, "selectedDate", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'week-start' })
], DatePicker.prototype, "weekStart", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'disable-day-picker' })
], DatePicker.prototype, "disableDayPicker", void 0);
__decorate([
    state()
], DatePicker.prototype, "_pickerMachineState", void 0);
DatePicker = __decorate([
    customElement('zui-date-picker')
], DatePicker);
export { DatePicker };
