import type { FC } from 'react';
import { Link } from 'react-router-dom';

export const NotFoundError: FC = () => (
  <div>
    <p className="error">Could not find the requested page. Are you sure it exists?</p>
    <p>
      Go back <Link to="/">to the dashboard</Link>.
    </p>
  </div>
);
