/* eslint-disable @typescript-eslint/naming-convention */
export const greyScaleColors = {
    'zui-color-gs-120': '#0E0F11',
    'zui-color-gs-115': '#17191C',
    'zui-color-gs-110': '#202327',
    'zui-color-gs-105': '#292D32',
    'zui-color-gs-100': '#32373E',
    'zui-color-gs-95': '#3E444C',
    'zui-color-gs-90': '#4E565F',
    'zui-color-gs-85': '#606A76',
    'zui-color-gs-80': '#778592',
    // FIXME: enable this for testing and remove if all works
    // 'zui-color-gs-80': '#00FF00',
    'zui-color-gs-70': '#A4B0BC',
    'zui-color-gs-60': '#C2CDD6',
    'zui-color-gs-50': '#D1D9E1',
    'zui-color-gs-40': '#DCE3E9',
    'zui-color-gs-35': '#E6EBF0',
    'zui-color-gs-30': '#ECF0F4',
    'zui-color-gs-20': '#F5F8FA',
    'zui-color-gs-10': '#FFFFFF',
};
export const primaryBlueColors = {
    'zui-color-pb-120': '#00346C',
    'zui-color-pb-110': '#0052AA',
    'zui-color-pb-100': '#0072EF',
    'zui-color-pb-90': '#1A88FF',
    'zui-color-pb-80': '#3396FF',
    'zui-color-pb-70': '#5DB3FF',
    'zui-color-pb-60': '#7DC7FF',
};
export const secondaryColors = {
    /* Red */
    'zui-color-sc-r-110': '#EA3A3A',
    'zui-color-sc-r-100': '#ED4F4F',
    'zui-color-sc-r-90': '#F06C6C',
    /* Pink */
    'zui-color-sc-p-110': '#F118BF',
    'zui-color-sc-p-100': '#F448CF',
    'zui-color-sc-p-90': '#F666D7',
    /* Blue */
    'zui-color-sc-b-110': '#00A1C2',
    'zui-color-sc-b-100': '#00AED1',
    'zui-color-sc-b-90': '#00C3EB',
    /* Green */
    'zui-color-sc-g-110': '#2BBF91',
    'zui-color-sc-g-100': '#3AD2A3',
    'zui-color-sc-g-90': '#61DBB4',
    /* Yellow */
    'zui-color-sc-y-110': '#FDBB08',
    'zui-color-sc-y-100': '#FDCA3F',
    'zui-color-sc-y-90': '#FEDC7E',
    /* Orange */
    'zui-color-sc-o-110': '#EC6E38',
    'zui-color-sc-o-100': '#EF7E4E',
    'zui-color-sc-o-90': '#F1936B',
};
export const alternativeColors = {
    /* purple */
    'zui-color-ac-pu-110': '#CC12D9',
    'zui-color-ac-pu-100': '#DE19EC',
    'zui-color-ac-pu-90': '#E54CF0',
    /* Violet */
    'zui-color-ac-v-110': '#6D35EF',
    'zui-color-ac-v-100': '#7A4CF0',
    'zui-color-ac-v-90': '#8B69F3',
    /* Turquoise */
    'zui-color-ac-tu-110': '#0AC7B7',
    'zui-color-ac-tu-100': '#0CDFCD',
    'zui-color-ac-tu-90': '#0CEDDB',
    /* Dirty Blue */
    'zui-color-ac-db-110': '#6293C6',
    'zui-color-ac-db-100': '#7DA7D5',
    'zui-color-ac-db-90': '#92B8DF',
    /* Clear Green */
    'zui-color-ac-cg-110': '#09AA4C',
    'zui-color-ac-cg-100': '#0AC759',
    'zui-color-ac-cg-90': '#0CE868',
    /* Lime */
    'zui-color-ac-l-110': '#82E520',
    'zui-color-ac-l-100': '#9EEB51',
    'zui-color-ac-l-90': '#AEEE6E',
    /* Brown */
    'zui-color-ac-br-110': '#A27B54',
    'zui-color-ac-br-100': '#B68C64',
    'zui-color-ac-br-90': '#C19D7B',
};
export const zuiColors = Object.assign(Object.assign(Object.assign(Object.assign({}, greyScaleColors), primaryBlueColors), secondaryColors), alternativeColors);
