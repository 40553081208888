var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
/* eslint-disable @typescript-eslint/member-ordering */
import 'lit';
import { property } from 'lit/decorators.js';
import '../../types.js';
import { isBooleanDOMAttributeSet } from '../../utils/dom.utils.js';
import '../../utils/util.types.js';
import './form-participation.interfaces.js';
import './form-participation.types.js';
/**
 * This mixin is used to add form-handling capabilities to a component.
 * The component has to implement `FormEnabledElement`.
 *
 * Example:
 * ```
 * class MyExampleComponent extends FormValidationMixin(FormDataHandlingMixin(RealBaseElement))
 *  implements FormValidationElement<FormEnabledElement> {

 *   @property({reflect: true})
 *   value: string;
 *
 *   render() {
 *     //...
 *   }
 * }
 *
 * ```
 *
 *  @param superClass the superClass for the component. Most likely this is `RealBaseElement`.
 *  @param options to customize behavior
 *  @returns a constructor for the component with form-participation enabled.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const FormValidationMixin = (superClass, 
// eslint-disable-next-line no-empty-pattern
{} = {}) => {
    var _FormEnabledComponentClass_instances, _FormEnabledComponentClass_validators, _FormEnabledComponentClass_validationState, _FormEnabledComponentClass_validationMessages, _FormEnabledComponentClass_defaultValidationMessages, _FormEnabledComponentClass_forceCustomError, _FormEnabledComponentClass_propertiesForValidationUpdate_get, _FormEnabledComponentClass_toggleValidationState, _FormEnabledComponentClass_getFailedValidityState;
    class FormEnabledComponentClass extends superClass {
        constructor() {
            super(...arguments);
            _FormEnabledComponentClass_instances.add(this);
            // eslint-disable-next-line jsdoc/require-jsdoc
            this.valid = false;
            // eslint-disable-next-line jsdoc/require-jsdoc
            this.invalid = false;
            _FormEnabledComponentClass_validators.set(this, [FormEnabledComponentClass.initialValidator]);
            _FormEnabledComponentClass_validationState.set(this, {
                valid: true,
                customError: false,
                badInput: false,
                rangeOverflow: false,
                rangeUnderflow: false,
                stepMismatch: false,
                tooLong: false,
                tooShort: false,
                typeMismatch: false,
                valueMissing: false,
                patternMismatch: false,
            });
            _FormEnabledComponentClass_validationMessages.set(this, {});
            _FormEnabledComponentClass_defaultValidationMessages.set(this, FormEnabledComponentClass.initialValidityMessages);
            _FormEnabledComponentClass_forceCustomError.set(this, false);
        }
        get willValidate() {
            // if we are disabled, we are not a candidate, nor if we are readonly
            if (this.disabled || this.readonly) {
                return false;
            }
            // we are a candidate for validation, if any validators have been added
            // or if validateCallback has been "implemented"
            // eslint-disable-next-line no-prototype-builtins
            return __classPrivateFieldGet(this, _FormEnabledComponentClass_validators, "f").length > 1 || this.hasOwnProperty('validationCallback');
        }
        get validationMessage() {
            if (!this.willValidate || this.valid) {
                return '';
            }
            const [failedValidationType] = Object.entries(this.validity).find(([, failed]) => failed === true) || [];
            const validationMessages = this.getValidityMessages();
            // fallback to customError if no specific error message has been specified
            // and be graceful to return otherwise an empty string if nothing has set whatsoever
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore because we expect [undefined] to be undefined :)
            return validationMessages[failedValidationType] || validationMessages.customError || '';
        }
        get validity() {
            // FIXME: this is both a race condition, i.e. too early read of not yet updated _validationState
            // and it is not the official API, i.e. the official API also reports validationState if not
            // a candidate for validation, this has to be fixxxxxed!
            // return actual validationState if a candidate for validation
            return this.willValidate
                ? Object.assign(Object.assign({}, __classPrivateFieldGet(this, _FormEnabledComponentClass_validationState, "f")), { 
                    // we are always reporting a customError, because
                    // built-in messages won't work with custom elements anyhow
                    valid: this.valid }) : // otherwise there is no failed validation
                Object.keys(__classPrivateFieldGet(this, _FormEnabledComponentClass_validationState, "f")).reduce((result, validityKey) => {
                    result[validityKey] = false;
                    return result;
                }, {});
        }
        formSubmitCallback(subEvent) {
            const { target: hostForm } = subEvent;
            // we are to prevent form submission, if we are invalid
            // we can only be invalid, if we are to be checked for validity
            // we will ignore validation altogether, if our parent form has novalidate set
            // if parent has novalidate do nothing
            if (isBooleanDOMAttributeSet(hostForm, 'novalidate')) {
                return;
            }
            // if we are to validate and this turns out to be false
            if (this.willValidate && this.checkValidity() === false) {
                subEvent.preventDefault();
                subEvent.stopPropagation();
                subEvent.stopImmediatePropagation();
            }
        }
        checkValidity() {
            // if we are not to be validated, we are always valid
            if (!this.willValidate) {
                return true;
            }
            // if we are forced to have a failed custom error, set this and do not calculate any other validations...
            let failedValidationType = undefined;
            if (__classPrivateFieldGet(this, _FormEnabledComponentClass_forceCustomError, "f")) {
                failedValidationType = 'customError';
            }
            else {
                failedValidationType = __classPrivateFieldGet(this, _FormEnabledComponentClass_instances, "m", _FormEnabledComponentClass_getFailedValidityState).call(this);
            }
            if (failedValidationType !== undefined) {
                // toggle the invalid state
                __classPrivateFieldGet(this, _FormEnabledComponentClass_instances, "m", _FormEnabledComponentClass_toggleValidationState).call(this, failedValidationType);
                // dispatch invalid, toggle attribute states, toggle parts
                this.valid = false;
                this.invalid = true;
                this.dispatchEvent(new Event('invalid'));
                // any failed validation can potentially change the validationMessage
                this.requestUpdate('validationMessage');
                return false;
            }
            else {
                // make sure there is nothing invalid anymore
                __classPrivateFieldSet(this, _FormEnabledComponentClass_validationState, {
                    valid: true,
                    customError: false,
                    badInput: false,
                    rangeOverflow: false,
                    rangeUnderflow: false,
                    stepMismatch: false,
                    tooLong: false,
                    tooShort: false,
                    typeMismatch: false,
                    valueMissing: false,
                    patternMismatch: false,
                }, "f");
                this.valid = true;
                this.invalid = false;
                return true;
            }
        }
        setCustomValidity(message) {
            if (message !== '') {
                // make the form control invalid by forcing the custom validity to be true
                this.setValidityMessages({ customError: message });
                __classPrivateFieldSet(this, _FormEnabledComponentClass_forceCustomError, true, "f");
            }
            else {
                // reset customError
                __classPrivateFieldSet(this, _FormEnabledComponentClass_forceCustomError, false, "f");
            }
            // we need to re-evaluate validity
            this.checkValidity();
        }
        setValidityMessages(validityMessages) {
            __classPrivateFieldSet(this, _FormEnabledComponentClass_validationMessages, Object.assign(Object.assign({}, __classPrivateFieldGet(this, _FormEnabledComponentClass_validationMessages, "f")), validityMessages), "f");
            // updating the messages, might potentially update the validation as well
            this.requestUpdate('validationMessage');
        }
        getValidityMessages() {
            return Object.assign(Object.assign({}, __classPrivateFieldGet(this, _FormEnabledComponentClass_defaultValidationMessages, "f")), __classPrivateFieldGet(this, _FormEnabledComponentClass_validationMessages, "f"));
        }
        setDefaultValidityMessages(validityMessages) {
            __classPrivateFieldSet(this, _FormEnabledComponentClass_defaultValidationMessages, Object.assign(Object.assign({}, __classPrivateFieldGet(this, _FormEnabledComponentClass_defaultValidationMessages, "f")), validityMessages), "f");
        }
        validationCallback(value) {
            return { isValid: true };
        }
        addValidator(validator) {
            __classPrivateFieldGet(this, _FormEnabledComponentClass_validators, "f").push(validator);
        }
        removeValidator(validatorType) {
            __classPrivateFieldSet(this, _FormEnabledComponentClass_validators, __classPrivateFieldGet(this, _FormEnabledComponentClass_validators, "f").filter((validator) => validator.type !== validatorType), "f");
        }
        resetValidators() {
            __classPrivateFieldSet(this, _FormEnabledComponentClass_validators, [FormEnabledComponentClass.initialValidator], "f");
        }
        resetValidationState() {
            this.valid = false;
            this.invalid = false;
        }
        updated(changedProperties) {
            super.updated(changedProperties);
            // disabled and readonly both are removing either status attributes
            if ((changedProperties.has('disabled') && this.disabled === true) ||
                (changedProperties.has('readonly') && this.readonly === true)) {
                this.valid = false;
                this.invalid = false;
            }
            if (
            // only validate on changes to value and for explicitly passed properties
            (changedProperties.has('value') ||
                // if either readonly or disabled are transitioning to false
                // we have to re-validate...
                (changedProperties.has('disabled') && this.disabled === false) ||
                (changedProperties.has('readonly') && this.readonly === false) ||
                __classPrivateFieldGet(this, _FormEnabledComponentClass_instances, "a", _FormEnabledComponentClass_propertiesForValidationUpdate_get).some((propertyToConsiderInUpdate) => changedProperties.has(propertyToConsiderInUpdate))) &&
                this.willValidate) {
                this.checkValidity();
            }
        }
    }
    _FormEnabledComponentClass_validators = new WeakMap(), _FormEnabledComponentClass_validationState = new WeakMap(), _FormEnabledComponentClass_validationMessages = new WeakMap(), _FormEnabledComponentClass_defaultValidationMessages = new WeakMap(), _FormEnabledComponentClass_forceCustomError = new WeakMap(), _FormEnabledComponentClass_instances = new WeakSet(), _FormEnabledComponentClass_propertiesForValidationUpdate_get = function _FormEnabledComponentClass_propertiesForValidationUpdate_get() {
        // flat and remove doubles
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore too much JS magic...
        return Array.from(new Set(__classPrivateFieldGet(this, _FormEnabledComponentClass_validators, "f").map((validator) => validator.validatesOnProperties).flat()));
    }, _FormEnabledComponentClass_toggleValidationState = function _FormEnabledComponentClass_toggleValidationState(type) {
        for (const key in __classPrivateFieldGet(this, _FormEnabledComponentClass_validationState, "f")) {
            __classPrivateFieldGet(this, _FormEnabledComponentClass_validationState, "f")[key] = type === key;
        }
    }, _FormEnabledComponentClass_getFailedValidityState = function _FormEnabledComponentClass_getFailedValidityState() {
        // run through all validators and also incorporate validationCallback
        // run through validationCallback if present
        const { isValid = true, message: customError = __classPrivateFieldGet(this, _FormEnabledComponentClass_validationMessages, "f").customError } = this.validationCallback(this['value']);
        if (!isValid) {
            this.setValidityMessages({ customError });
            return 'customError';
        }
        // and finally run through the remaining validators if there are any
        const firstFailedValidator = __classPrivateFieldGet(this, _FormEnabledComponentClass_validators, "f").find((validator) => validator.validator(this['value']) === false);
        if (firstFailedValidator !== undefined) {
            return firstFailedValidator.type;
        }
        // nothing seem to have failed...
        return undefined;
    };
    FormEnabledComponentClass.initialValidator = { type: 'customError', validator: () => true };
    FormEnabledComponentClass.initialValidityMessages = {
        customError: undefined,
        badInput: undefined,
        patternMismatch: undefined,
        rangeOverflow: undefined,
        rangeUnderflow: undefined,
        stepMismatch: undefined,
        tooLong: undefined,
        tooShort: undefined,
        typeMismatch: undefined,
        valueMissing: undefined,
    };
    __decorate([
        property({ reflect: true, type: Boolean })
    ], FormEnabledComponentClass.prototype, "valid", void 0);
    __decorate([
        property({ reflect: true, type: Boolean })
    ], FormEnabledComponentClass.prototype, "invalid", void 0);
    __decorate([
        property({ attribute: false })
    ], FormEnabledComponentClass.prototype, "validationMessage", null);
    return FormEnabledComponentClass;
};
