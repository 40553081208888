var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { isSlotNotEmpty } from '../../../utils/component.utils.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
const style = ":host{--zui-menu-divider-color: var(--zui-color-text-divider);--zui-menu-divider-height: calc(var(--zui-gu) * 0.125);--zui-menu-divider-line-color: var(--zui-color-thin-line-highlight);--zui-menu-divider-line-height: calc(var(--zui-gu) * 0.125);--zui-menu-divider-line-padding: 0;---zui-menu-divider-line-position: 0;---zui-menu-divider-line-spacing: -1px;display:block;width:100%;height:var(--zui-menu-divider-height);overflow:hidden;font-size:0 !important;line-height:0 !important;text-align:center}.line{position:relative;display:inline-block;max-width:calc(100% - var(--zui-menu-divider-line-padding)*2);min-height:var(--zui-menu-divider-line-height);padding:0 var(--zui-menu-divider-line-padding)}.line::before,.line::after{position:absolute;top:auto;right:auto;bottom:var(---zui-menu-divider-line-position);left:auto;width:2000vw;height:var(--zui-menu-divider-line-height);background-color:var(--zui-menu-divider-line-color);content:\"\"}.line::before{right:calc(100% + var(---zui-menu-divider-line-spacing))}.line::after{left:calc(100% + var(---zui-menu-divider-line-spacing))}.label{font:var(--zui-typography-label2);text-transform:var(--zui-typography-label2-text-transform);display:block;width:100%;height:var(--zui-menu-divider-height);overflow:hidden;color:var(--zui-menu-divider-color);line-height:var(--zui-menu-divider-height);white-space:nowrap;text-overflow:ellipsis}:host([has-label]){--zui-menu-divider-height: calc(var(--zui-gu) * 4);--zui-menu-divider-line-padding: calc(var(--zui-gu) * 1.5);---zui-menu-divider-line-position: 50%}:host([has-label]) .label{margin-bottom:calc(var(--zui-gu) * 0.25)}"
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-text-divider: var(--zui-color-gs-85);--zui-color-thin-line-default: var(--zui-color-gs-40);--zui-color-thin-line-highlight: var(--zui-color-gs-60);--zui-color-thick-line-default: var(--zui-color-gs-40);--zui-color-thick-line-highlight: var(--zui-color-gs-60);--zui-color-thick-line-primary: var(--zui-color-pb-100)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-text-divider: var(--zui-color-gs-70);--zui-color-thin-line-default: var(--zui-color-gs-110);--zui-color-thin-line-highlight: var(--zui-color-gs-120);--zui-color-thick-line-default: var(--zui-color-gs-110);--zui-color-thick-line-highlight: var(--zui-color-gs-120);--zui-color-thick-line-primary: var(--zui-color-pb-90)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const menuDividerStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * A UI component mainly being used by the `zui-select` feature component internally. It encapsulates all styling purposes of the menu
 * divider feature, which can be used declaratively passing `zui-select-divider`.
 *
 * ## Functionality
 * It internally checks for a projected label through the default slot and sets a flag accordingly. Also, it cuts off labels which are
 * too long using three d...
 *
 * @example
 * ```HTML
 * <zui-menu-divider>Optional label</zui-menu-divider>
 * ```
 *
 * @slot - default slot to provide an optional divider label
 * @cssprop --zui-menu-divider-color - color of the divider label
 * @cssprop --zui-menu-divider-height - overall height of the divider
 * @cssprop --zui-menu-divider-line-color - color of the divider line
 * @cssprop --zui-menu-divider-line-height - minimum height of the line to be enforced even without label
 * @cssprop --zui-menu-divider-line-padding - offset from to label if necessary
 */
let MenuDivider = class MenuDivider extends BaseElement {
    _checkHasLabel({ target }) {
        this.hasLabel = isSlotNotEmpty(target);
    }
    render() {
        return html `
      <span class="line">
        <span class="label">
          <slot @slotchange="${this._checkHasLabel}"></slot>
        </span>
      </span>
    `;
    }
};
MenuDivider.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    menuDividerStyles,
];
__decorate([
    property({ attribute: 'has-label', reflect: true, type: Boolean })
], MenuDivider.prototype, "hasLabel", void 0);
MenuDivider = __decorate([
    customElement('zui-menu-divider')
], MenuDivider);
export { MenuDivider };
