/**
 * This utils get rid of the differences between the behaviour of parseInt and Number(),
 * unsing both to generate a strict number parsing. It returns always a numbers, either NaN if the string is not parsable
 * into a valid number, or the parsed number.
 *
 * @param value the string that must be parsed
 * @returns the parsed number
 */
export function strictNumberParse(value) {
    return value.includes(' ') || value.endsWith('.') ? NaN : isNaN(parseFloat(value)) ? NaN : Number(value);
}
/**
 * Type guard that checks if the value is a valid number and if it's defined.
 *
 * @param {number | undefined} val - The value to be checked. Should be a number or undefined.
 * @returns Returns true if the input is a valid number, false otherwise (e.g. is undefined).
 */
export function isValidNumber(val) {
    return val !== undefined && !isNaN(val);
}
