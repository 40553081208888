var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
// deps
import '../../menu/menu/menu.component.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
const style = "@keyframes toggle-bottom{from{transform:translateY(calc(var(--zui-gu) * -4));opacity:0}to{transform:translateY(0);opacity:1}}@keyframes toggle-top{from{transform:translateY(calc(var(--zui-gu) * 4));opacity:0}to{transform:translateY(0);opacity:1}}:host{--zui-select-menu-animation-duration: var(--zui-select-animation-duration, 0.1s);--zui-select-menu-overflow: calc(var(--zui-gu) * 3)}#wrapper{animation-duration:var(--zui-select-menu-animation-duration);animation-fill-mode:forwards}#wrapper.open{animation-timing-function:ease-out;animation-direction:normal;pointer-events:all}#wrapper.close{animation-timing-function:ease-in;animation-direction:reverse;pointer-events:none}:host([data-popper-placement^=bottom]) #wrapper.running{animation-name:toggle-bottom}:host([data-popper-placement^=top]) #wrapper.running{animation-name:toggle-top}"
import { GetNotificationEventClass, GetSharedEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const POPPER_PLACEMENT_DATA_ATTRIBUTE_NAME = 'data-popper-placement';
// TODO: fix event architecture and remove obsolete events;
// TODO: fix event types...
class SelectMenuOpenEvent extends GetSharedEventClass('select-menu-open') {
}
class SelectMenuCloseEvent extends GetSharedEventClass('select-menu-close') {
}
class SelectMenuOpenedEvent extends GetNotificationEventClass('select-menu-opened') {
}
class SelectMenuClosedEvent extends GetNotificationEventClass('select-menu-closed') {
}
const SELECT_MENU_STYLES = css `
  ${unsafeCSS(style)}
`;
/**
 * This element brings animations to toggleable select menus. For convenience it is
 * separated, it is a simple wrapper around a slotted menu and will itself be
 * projected most likely into a portal. It manages the open and close animations
 * and notifies about those processes.
 *
 * @example
 * ```HTML
 * <zui-overlay-directive flip>
 *   <zui-select-menu open>
 *     <zui-menu>
 *       <zui-menu-item>I'm a select menu now!</zui-menu-item>
 *     </zui-menu>
 *   </zui-select-menu>
 * </zui-overlay-directive>
 * ```
 *
 * @fires {GetZuiEvent<SelectMenu, 'OpenedEvent'>} zui-select-menu-opened - broadcasts if menu is fully opened
 * @fires {GetZuiEvent<SelectMenu, 'ClosedEvent'>} zui-select-menu-closed - notifies about menu being completely closed
 * @fires {GetZuiEvent<SelectMenu, 'OpenEvent'>} select-menu-open - fires when menu is opened but before animaton ended
 * @fires {GetZuiEvent<SelectMenu, 'CloseEvent'>} select-menu-close - fires when menu is closed but before animaton ended
 *
 * @slot - default slot for an optional divider label
 * @cssprop --zui-select-menu-animation-duration - duration of the menu toggle animation
 * @cssprop --zui-select-menu-overflow - offset before invisible overflow, prevents cut-offs of the menu shadow
 */
let SelectMenu = class SelectMenu extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.OpenEvent = SelectMenuOpenEvent;
        this.CloseEvent = SelectMenuCloseEvent;
        this.OpenedEvent = SelectMenuOpenedEvent;
        this.ClosedEvent = SelectMenuClosedEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * Either show or hide the menu, triggers animations.
         */
        this.open = false;
        this._running = false;
    }
    _handleTransitionEnd() {
        // the animation is over
        this._running = false;
        // trigger corresponding event
        if (this.open) {
            this.emitSelectMenuOpenedEvent();
        }
        else {
            this.emitSelectMenuClosedEvent();
        }
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        // everytime the open property is changed, we need to to toggle
        // a class for the CSS animation to be run (as we're re-using
        // the same animation for both transitions, just in reverse)
        if (changedProperties.has('open')) {
            this._running = true;
            // trigger corresponding event
            if (this.open) {
                this._emitSelectMenuOpenEvent();
            }
            else {
                this._emitSelectMenuCloseEvent();
            }
        }
        // TODO: this looks like it is violationg component boundaries and has a potential null exception as well
        // we're observing a data attribute which is manipulated by
        // the positioning library in order to update the placement
        // of the nested menu element
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore because there seems to be some meta magic around it...
        if (changedProperties.has('_zuiPlacement')) {
            this._menuRef[0].placement = this._zuiPlacement;
        }
    }
    render() {
        return html `
      <div id="clipper">
        <div
          id="wrapper"
          class="${classMap({ open: this.open, close: !this.open, running: this._running })}"
          @animationcancel="${this._handleTransitionEnd}"
          @animationend="${this._handleTransitionEnd}"
        >
          <slot></slot>
        </div>
      </div>
    `;
    }
};
SelectMenu.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    SELECT_MENU_STYLES,
];
__decorate([
    property({ reflect: true, type: Boolean })
], SelectMenu.prototype, "open", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: POPPER_PLACEMENT_DATA_ATTRIBUTE_NAME })
], SelectMenu.prototype, "_zuiPlacement", void 0);
__decorate([
    state()
], SelectMenu.prototype, "_running", void 0);
__decorate([
    queryAssignedElements({ selector: 'zui-menu', flatten: true })
], SelectMenu.prototype, "_menuRef", void 0);
SelectMenu = __decorate([
    customElement('zui-select-menu')
], SelectMenu);
export { SelectMenu };
