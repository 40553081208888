var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement } from '../base/base-element.class.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { WithEventsMixin } from '../../mixins/events/events.mixin.js';
import '../../contracts/event.contracts.js';
import { GetNotificationEventClass } from '../../contracts/event.classes.js';
import { iconSets } from '../../icon-sets.js';
import { hostStyles } from '../../host.styles.js';
// deps
import '../box/box.component.js';
import '../inline-message/inline-message.component.js';
import '../icon-bar/icon-bar.component.js';
import '../button/button.component.js';
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const style = ":host{--zui-banner-notification-height: 100%;---zui-banner-notification-min-height: calc(var(--zui-gu) * 8);---zui-banner-notification-ewi-bar-height: calc(var(--zui-gu) * 0.5);display:flex;flex-direction:column;width:100%;height:var(--zui-banner-notification-height);min-height:var(---zui-banner-notification-min-height)}:host([state=warning]){---zui-ewi-bar-background-color: var(--zui-color-ewi-warning)}:host([state=error]){---zui-ewi-bar-background-color: var(--zui-color-ewi-error)}:host([state=check]){---zui-ewi-bar-background-color: var(--zui-color-ewi-check)}:host([state=question]){---zui-ewi-bar-background-color: var(--zui-color-ewi-question)}:host([state=info]){---zui-ewi-bar-background-color: var(--zui-color-ewi-info)}zui-box{width:100%;height:100%;padding-top:calc(calc(var(--zui-gu) * 2) - var(---zui-banner-notification-ewi-bar-height))}#content{display:flex;flex-direction:row;align-items:flex-start;justify-content:space-between;width:100%;overflow:hidden}zui-inline-message{width:100%}zui-button{--zui-button-width: calc(var(--zui-gu) * 15)}zui-icon-bar{margin-top:calc(calc(var(--zui-gu) * 11) / 16);margin-bottom:calc(calc(var(--zui-gu) * 11) / 16)}slot[name=button]::slotted(zui-button){margin-left:calc(var(--zui-gu) * 2)}#ewi-bar{width:100%;height:var(---zui-banner-notification-ewi-bar-height);min-height:var(---zui-banner-notification-ewi-bar-height);background-color:var(---zui-ewi-bar-background-color)}"
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-ewi-warning: var(--zui-color-sc-y-100);--zui-color-ewi-info: var(--zui-color-pb-100);--zui-color-ewi-error: var(--zui-color-sc-r-100);--zui-color-ewi-question: var(--zui-color-gs-80);--zui-color-ewi-check: var(--zui-color-sc-g-110)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-ewi-warning: var(--zui-color-sc-y-100);--zui-color-ewi-info: var(--zui-color-pb-90);--zui-color-ewi-error: var(--zui-color-sc-r-100);--zui-color-ewi-question: var(--zui-color-gs-80);--zui-color-ewi-check: var(--zui-color-sc-g-110)}"
const themeDark = ""
const themeLight = ""
const bannerNotificationStyles = css `
  ${unsafeCSS(style)}
`;
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
class BannerNotificationClosedEvent extends GetNotificationEventClass('banner-notification-closed') {
}
/**
 * The zui-banner-notification is a simple UI component. It indicates the state via an EWI bar and an icon in the embedded inline-message.
 * Beside the header-text and the (text-)content, displayed in the inline-message, it serves an icon-bar, to include several icons and a slot for a button.
 * Wrapping all this in a zui-box, it's possible to set the hierarchy via an attribute (default is "secondary"), which changes the background color of the box.
 * Also, it's possible to change the icon set for the icon in the inline-message.
 *
 * Please note, there is a fixed minimum height of 64px.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---7.2?t=b4CeaAZc1D6fQich-0)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=73247%3A603191&t=j3evxKvc3ERNSd4I-0)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-banner-notification
 *   header-text="My header"
 *   content="My content"
 *   state="question"
 *   hierarchy="first"
 *   icon-set="meditec"
 *   closable>
 *   <zui-icon-common-holy-placeholder slot="icon1"></zui-icon-common-holy-placeholder>
 *   <zui-icon-common-holy-placeholder slot="icon2"></zui-icon-common-holy-placeholder>
 *   <zui-icon-common-holy-placeholder slot="icon3"></zui-icon-common-holy-placeholder>
 * </zui-banner-notification>
 *  ```
 *
 * ```html
 * <zui-banner-notification
 *   header-text="My header"
 *   state="warning"
 *   hierarchy="second"
 *   icon-set="common">
 *   <zui-button slot="button">OK</zui-button>
 * </zui-banner-notification>
 *  ```
 *  *
 * @fires {GetZuiEvent<BannerNotification, 'ClosedEvent'>} zui-banner-notification-closed - Event, to be fired, clicking the "close" button in the icon-bar of the banner notification
 * @cssprop --zui-banner-notification-height - sets the height of the banner notification
 */
let BannerNotification = class BannerNotification extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.ClosedEvent = BannerNotificationClosedEvent;
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * State of the banner notification, displaying the state color in the ewi bar
         * default value = "info"
         */
        this.state = 'info';
        /**
         * Defines the hierarchy level of the zui box in the banner notification
         * default value = "second"
         */
        this.hierarchy = 'second';
        /**
         * IconSet of the banner notification, displayed in the inline message
         */
        this.iconSet = 'common';
        /**
         * Header text of the banner notification, displayed in the inline message
         */
        this.headerText = '';
        /**
         * Text content of the banner notification, displayed in the inline message
         */
        this.content = '';
        /**
         * Closable attribute for the banner notification, toggling the close icon in the icon bar
         */
        this.closable = false;
    }
    _getIconTemplate() {
        const iconName = iconSets[this.iconSet][this.state];
        return html `${unsafeHTML(`<${iconName} size="s32" slot="icon"></${iconName}>`)}`;
    }
    _handleIconBarClosed() {
        this.emitBannerNotificationClosedEvent();
    }
    render() {
        return html ` <div id="ewi-bar"></div>
      <zui-box no-bottom-line size="s" hierarchy-level="${this.hierarchy}">
        <div id="content">
          <zui-inline-message header-text="${this.headerText}" size="m"
            >${this.content} ${this._getIconTemplate()}
          </zui-inline-message>
          <slot name="button"></slot>
          <zui-icon-bar ?closable="${this.closable}" @zui-icon-bar-closed="${this._handleIconBarClosed}">
            <slot name="icon1" slot="icon1"></slot>
            <slot name="icon2" slot="icon2"></slot>
            <slot name="icon3" slot="icon3"></slot>
          </zui-icon-bar>
        </div>
      </zui-box>`;
    }
};
BannerNotification.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    bannerNotificationStyles,
];
__decorate([
    property({ reflect: true, type: String })
], BannerNotification.prototype, "state", void 0);
__decorate([
    property({ reflect: true, type: String })
], BannerNotification.prototype, "hierarchy", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'icon-set' })
], BannerNotification.prototype, "iconSet", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'header-text' })
], BannerNotification.prototype, "headerText", void 0);
__decorate([
    property({ reflect: true, type: String })
], BannerNotification.prototype, "content", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], BannerNotification.prototype, "closable", void 0);
BannerNotification = __decorate([
    customElement('zui-banner-notification')
], BannerNotification);
export { BannerNotification };
