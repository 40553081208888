var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// FIXME: rethink the whole thing
import { LitElement } from 'lit';
import { property, state } from 'lit/decorators.js';
import { UsageMixin } from '../../mixins/metrics/simple-usage-metrics.mixin.js';
import { areFontsAvailable, hasPlatformStyleBeenApplied, registerTypefaces, } from '../../styles/typography/typeface.utils.js';
import { platformCustomization } from '../../styles/utils/browser.utils.js';
import { getParentElementBySelector } from '../../utils/mixin.utils.js';
import { getHostBySlot, shouldBuildThemesBeDisabled, shouldBuildThemesBeEnabled } from '../../utils/component.utils.js';
// polyfill metadata API, so that it is available in all components
import 'reflect-metadata';
/**
 * Environment
 * - AUTO "auto" globally set environment or browser capability
 * - Desktop "desktop" normal environment without touch capability
 * - TOUCH "touch" optimized for touch devices
 *
 * @property {string} AUTO "auto" globally set environment or browser capability
 * @property {string} Desktop "desktop" normal environment without touch capability
 * @property {string} TOUCH "touch" optimized for touch devices
 */
export var Environment;
(function (Environment) {
    Environment["AUTO"] = "auto";
    Environment["DESKTOP"] = "desktop";
    Environment["TOUCH"] = "touch";
})(Environment || (Environment = {}));
const env = 'environment';
/**
 * Base element for all components to extend.
 */
export class BaseElement extends UsageMixin(LitElement) {
    //* Constructor and static functions
    constructor() {
        super();
        /**
         * tagging property used to use [zui-element] as a querySelector for all zui elements
         *
         * @private
         */
        this.isElement = true;
        this._globalEnv = document.documentElement.getAttribute(env) || Environment.AUTO;
        this._environment = Environment.AUTO;
        this._handleMatchMedia = this._handleMatchMedia.bind(this);
        this._handleEnvironmentChange = this._handleEnvironmentChange.bind(this);
        this._mediaObserver = window.matchMedia('(pointer:coarse)');
        this._mediaEnv = this._mediaObserver.matches ? Environment.TOUCH : Environment.DESKTOP;
        this._envObserver = new MutationObserver(this._handleEnvironmentChange);
    }
    /**
     * Determines which environment theme to apply ('desktop' | 'touch' | 'auto')
     *
     * @returns {Environment} the resolved environment
     */
    get environment() {
        if (this._environment !== Environment.AUTO) {
            return this._environment;
        }
        if (this._globalEnv !== Environment.AUTO) {
            return this._globalEnv;
        }
        return this._mediaEnv;
    }
    /**
     * Sets the environment for a component explicitly with ('desktop' | 'touch')
     * or to the global environment with ('auto')
     */
    set environment(val) {
        const oldVal = this._environment;
        this._environment = val;
        this.requestUpdate('environment', oldVal);
    }
    /**
     *  Determines if a touch environment is applicable
     *
     *  @returns {boolean} if in touch environmet
     */
    get hasTouch() {
        return this.environment === Environment.TOUCH;
    }
    //* Callbacks and EventListener
    connectedCallback() {
        super.connectedCallback();
        // Safari doesn't have 'addEventListener' but only 'addListener'
        if (this._mediaObserver.addEventListener) {
            this._mediaObserver.addEventListener('change', this._handleMatchMedia);
        }
        else {
            this._mediaObserver.addListener(this._handleMatchMedia);
        }
        this._envObserver.observe(document.documentElement, { attributeFilter: [env] });
        // reset _disconnectedPromise
        this._disconnectedPromise = new Promise((resolve) => {
            this._resolveDisconnectedPromise = resolve;
        });
        // start race to trigger firstRendered() hook
        (() => __awaiter(this, void 0, void 0, function* () {
            const renderedBeforeDisconnect = yield Promise.race([
                this.updateComplete.then(() => true),
                this._disconnectedPromise.then(() => false),
            ]);
            if (renderedBeforeDisconnect) {
                this.firstRendered();
            }
        }))();
        if (!areFontsAvailable()) {
            registerTypefaces();
        }
        if (!hasPlatformStyleBeenApplied()) {
            platformCustomization();
        }
    }
    disconnectedCallback() {
        // Safari doesn't have 'removeEventListener' but only 'removeListener'
        if (this._mediaObserver.removeEventListener) {
            this._mediaObserver.removeEventListener('change', this._handleMatchMedia);
        }
        else {
            this._mediaObserver.removeListener(this._handleMatchMedia);
        }
        this._envObserver.disconnect();
        this._resolveDisconnectedPromise();
        super.disconnectedCallback();
    }
    // TODO: get rid of crazy env change logic
    _handleEnvironmentChange(ev) {
        ev.filter((val) => val.attributeName === env && val.target === document.documentElement).forEach((val) => 
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (this._globalEnv = val.target.getAttribute(val.attributeName)));
    }
    _handleMatchMedia(ev) {
        this._mediaEnv = ev.matches ? Environment.TOUCH : Environment.DESKTOP;
    }
    /**
     * Custom lifecycle hook.
     * Similar to `firstUpdated()`. But it's not only called after the very first render in a component's lifetime.
     * `firstRendered()` is called after *each* new connectedCallback and
     * consecutive updateComplete -> https://lit.dev/docs/v1/components/lifecycle/#updatecomplete
     * (except when component has already disconnected again before updateComplete) and thus it allows
     * for logic to be reliably called, once a component is still connected and has its (initial) update completed
     */
    firstRendered() {
        var _a;
        // if there is no theme set, we default to one,
        // either if we are slotted, we take the theme from slotHost,
        // or we take the next zui-element ones' theme,
        // or if after all, if there is no theme-provider, the default one
        if (BaseElement.FEATURE_ENABLE_BUILD_THEMES && !this.theme) {
            const themeFromNearestZuiElementWithTheme = (_a = getParentElementBySelector(this, '[zui-element][theme]')) === null || _a === void 0 ? void 0 : _a.getAttribute('theme');
            const themeFromSlotHost = this.assignedSlot ? getHostBySlot(this.assignedSlot).getAttribute('theme') : undefined;
            if (this.assignedSlot && themeFromSlotHost) {
                this.theme = themeFromSlotHost;
            }
            // otherwise look for the next zui-element with a theme
            else if (themeFromNearestZuiElementWithTheme) {
                this.theme = themeFromNearestZuiElementWithTheme;
            }
            // otherwise, if there is no themeProvider, set default theme
            else if (getParentElementBySelector(this, 'zui-theme-provider') === null) {
                this.theme = BaseElement.DEFAULT_THEME;
            }
        }
        return;
    }
}
/* eslint-disable @typescript-eslint/naming-convention */
BaseElement.DEFAULT_THEME = 'zbds-light';
BaseElement.FEATURE_DISABLE_BUILD_THEMES = shouldBuildThemesBeDisabled();
BaseElement.FEATURE_ENABLE_BUILD_THEMES = shouldBuildThemesBeEnabled();
/* eslint-enable @typescript-eslint/naming-convention */
BaseElement.shadowRootOptions = Object.assign({}, LitElement.shadowRootOptions);
__decorate([
    property({ reflect: true })
], BaseElement.prototype, "theme", void 0);
__decorate([
    property({ reflect: true, attribute: 'zui-element', type: Boolean })
], BaseElement.prototype, "isElement", void 0);
__decorate([
    property({ reflect: true })
], BaseElement.prototype, "environment", null);
__decorate([
    state()
], BaseElement.prototype, "_globalEnv", void 0);
__decorate([
    state()
], BaseElement.prototype, "_mediaEnv", void 0);
// we need this for usage with Mixins, because abstract classes are not possible due to TS restrictions...
export class RealBaseElement extends BaseElement {
}
