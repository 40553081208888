var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { css, html, unsafeCSS } from 'lit';
import { state, customElement, property, query, queryAssignedElements } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import '../../../mixins/disabled/disabled.interfaces.js';
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { BaseElement, RealBaseElement } from '../../base/base-element.class.js';
import { hasElementAnyDimensions, waitForLitElementUpdated } from '../../../utils/component.utils.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{display:flex;flex-direction:row;width:100%;height:calc(var(--zui-gu) * 3)}::slotted(zui-tag:not(:last-child)){margin-right:calc(var(--zui-gu) * 1)}.has-tag-menu::slotted(zui-tag:last-child){margin-right:8px}zui-tag-menu-button{height:calc(var(--zui-gu) * 4)}"
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
// deps
import '../tag/tag.component.js';
import { TagMenuButton } from '../tag-menu-button/tag-menu-button.component.js';
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const tagBarStyles = css `
  ${unsafeCSS(styles)}
`;
/**
 * The Tag Bar is used when not all tags be shown due to missing screenspace or intended limitation.
 * Clicking on the Tag Bar will open a dropdown menu, that makes the other tags accessible.
 *
 * The tags in the menu are rendered as zui-menu-item(s) automatically.
 *
 * ## Figma
 * - [Styleguide – Web](https://www.figma.com/file/6dkjypErYWQPfuRBD58Aey/%F0%9F%93%96--Styleguide---Web?node-id=8492%3A90165&t=qA0nGRRolSR2QUey-0)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-tag-bar expand-label="More">
 *   <zui-tag value="Dense white cataracy" text="Dense white cataracy"></zui-tag>
 *   <zui-tag value="High densitiy" text="High density"></zui-tag>
 *   <zui-tag value="corrective surgery" text="corrective surgery"></zui-tag>
 *   <zui-tag value="failed rhexis" text="failed rhexis"></zui-tag>
 *   <zui-tag value="dense cataract" text="dense cataract"></zui-tag>
 *   <zui-tag value="challenging case" text="challenging case"></zui-tag>
 *   <zui-tag value="additional tag" text="additional tag"></zui-tag>
 *   <zui-tag value="test test" text="test test"></zui-tag>
 * </zui-tag-bar>
 *
 * ```
 *
 * @slot default - This is the default slot for tag components
 *
 * */
let TagBar = class TagBar extends DisabledMixin(RealBaseElement) {
    constructor() {
        super(...arguments);
        /**
         * Manages if the menu of the tag menu button is open or closed.
         */
        this.open = false;
        /**
         * This hides the count of tags inside the tag menu button
         */
        this.hideTagCount = false;
        /**
         * Text to be displayed as label for the tag menu button
         */
        this.expandLabel = TagMenuButton.TAG_MENU_BUTTON_EXPAND_LABEL;
        /**
         * An attribute to configure the max number of items that are visible in the menu of the tag menu button without scroll
         */
        this.itemCount = 9.5;
        /**
         * Defines the strategy for the menu adapt-width behavior of the tag menu button.
         * Default is false that means the width is fixed
         */
        this.adaptWidth = false;
        this._showTagMenuButton = false;
        this._isTagBarStable = false;
        /**
         * Resize Observer which triggers reloading when the width of the tag bar gets changed
         */
        this._widthObserver = new ResizeObserver(() => __awaiter(this, void 0, void 0, function* () {
            if (hasElementAnyDimensions(this)) {
                this._resetState();
                yield this._hasTagMenuButtonRendered;
                this._removeLastTag();
            }
        }));
    }
    get _hasOverflow() {
        // an offsetWidth of 0 is no problem
        // in this case this return false (0 < 0) and this will exit the algorithm
        return this.scrollWidth > this.offsetWidth;
    }
    get _hasTagMenuButtonRendered() {
        return waitForLitElementUpdated(this._tagMenuButtonRef);
    }
    disconnectedCallback() {
        this._widthObserver.disconnect();
        super.disconnectedCallback();
    }
    _resetState() {
        this._isTagBarStable = false;
        this._assignedTagMenuTags.forEach((tag) => {
            tag.removeAttribute('slot');
            tag.removeAttribute('zui-internal-as-menu-item');
        });
        this._showTagMenuButton = false;
    }
    _removeLastTag() {
        if (this._hasOverflow) {
            this._assignedTags[this._assignedTags.length - 1].setAttribute('slot', 'overflow');
            this._showTagMenuButton = true;
        }
    }
    _handleSlotChange() {
        this._hasTagMenuButtonRendered.then(() => {
            // this limits the logic, to only work with non-opened tagMenuButtons,
            // because the current portal won't allow adding stuff while other stuff is projected anyway
            if (!this._tagMenuButtonRef.open) {
                if (this._hasOverflow) {
                    this._removeLastTag();
                    return;
                }
                else if (!this._isTagBarStable) {
                    this._isTagBarStable = true;
                    return;
                }
                else if (this._isTagBarStable) {
                    this._resetState();
                    return;
                }
            }
        });
    }
    _handleSlotChangeOverflow() {
        if (!this._tagMenuButtonRef.open) {
            if (this._assignedTagMenuTags.length === 0) {
                this._showTagMenuButton = false;
            }
        }
    }
    firstRendered() {
        super.firstRendered();
        this._widthObserver.observe(this);
    }
    render() {
        return html `
      <slot
        @slotchange=${this._handleSlotChange}
        class="${classMap({ 'has-tag-menu': this._showTagMenuButton })}"
      ></slot>
      <zui-tag-menu-button
        ?hidden=${!this._showTagMenuButton}
        ?open=${this.open}
        ?adapt-width=${this.adaptWidth}
        ?hide-tag-count=${this.hideTagCount}
        expand-label=${this.expandLabel}
        item-count=${this.itemCount}
      >
        <slot name="overflow" @slotchange=${this._handleSlotChangeOverflow}></slot>
      </zui-tag-menu-button>
    `;
    }
};
TagBar.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    tagBarStyles,
];
__decorate([
    property({ reflect: true, type: Boolean })
], TagBar.prototype, "open", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'hide-tag-count' })
], TagBar.prototype, "hideTagCount", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'expand-label' })
], TagBar.prototype, "expandLabel", void 0);
__decorate([
    property({ reflect: true, type: Number, attribute: 'item-count' })
], TagBar.prototype, "itemCount", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'adapt-width' })
], TagBar.prototype, "adaptWidth", void 0);
__decorate([
    queryAssignedElements({ flatten: true })
], TagBar.prototype, "_assignedTags", void 0);
__decorate([
    queryAssignedElements({ slot: 'overflow', flatten: true })
], TagBar.prototype, "_assignedTagMenuTags", void 0);
__decorate([
    query('zui-tag-menu-button')
], TagBar.prototype, "_tagMenuButtonRef", void 0);
__decorate([
    state()
], TagBar.prototype, "_showTagMenuButton", void 0);
TagBar = __decorate([
    customElement('zui-tag-bar')
], TagBar);
export { TagBar };
