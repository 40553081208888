import { type PiralPlugin } from 'piral-core';
import { firstValueFrom, type Observable, ReplaySubject } from 'rxjs';

import type { RuntimeConfig } from '../../types';

/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'piral-core/lib/types/custom' {
  interface PiletCustomApi extends PiletCookiePreferencesApi {}
}

declare global {
  interface Window {
    OnetrustActiveGroups: string | undefined;
  }
}

export type CookiePreferences = Record<string, boolean>;

export interface PiletCookiePreferencesApi {
  /**
   * Provides an event stream which emits the latest Cookie Preferences immediately when subscribing and then every time the information is updated.
   */
  cookiePreferences$: Observable<CookiePreferences>;

  /**
   * Async function that returns the current state of the Cookie Preferences.
   *
   * @return Key-value pair where the key is the type of Cookie Preference. For example: functional, statistics, or marketing. The value is a Boolean depending on the User consent.
   */
  getCookiePreferences(): Promise<CookiePreferences>;
}

function createPreferencesMapping(config: RuntimeConfig, cookieCodes: string): CookiePreferences {
  const codes = cookieCodes.split(',').filter(Boolean);
  const entries = Object.entries(config.cookieCategoryMapping).map(([key, code]) => [
    key,
    codes.includes(code),
  ]);

  return Object.fromEntries(entries);
}

export function createCookiePreferencesApi(
  config: RuntimeConfig
): PiralPlugin<PiletCookiePreferencesApi> {
  const cookiePreferences$ = new ReplaySubject<CookiePreferences>(1);

  if (window.OnetrustActiveGroups) {
    updateCookiePreferences(window.OnetrustActiveGroups);
  }

  window.addEventListener('OneTrustGroupsUpdated', ((e: CustomEvent) => {
    updateCookiePreferences(e.detail.join(','));
  }) as EventListener);

  function updateCookiePreferences(newValue: string): void {
    const cookiePreferences = createPreferencesMapping(config, newValue);
    cookiePreferences$.next(cookiePreferences);
  }

  return () => () => ({
    cookiePreferences$: cookiePreferences$.asObservable(),
    getCookiePreferences: () => firstValueFrom(cookiePreferences$),
  });
}
