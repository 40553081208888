var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement } from '../base/base-element.class.js';
import { hostStyles } from '../../host.styles.js';
const style = ":host{display:inline-block;box-sizing:border-box;max-width:800px;text-align:center;overflow-wrap:anywhere}#headline{color:var(--zui-color-loader-box-headline-text);font:var(--zui-typography-subtitle1)}#description{color:var(--zui-color-loader-box-description-text);font:var(--zui-typography-body)}#container{display:flex;flex-direction:column;align-items:center;justify-content:center;padding:calc(var(--zui-gu) * 4);background-color:var(--zui-color-loader-box-bg);border-radius:calc(var(--zui-gu) * 1)}#container.description-only,#container.headline-only,#container.headline-with-description{padding-bottom:27px}#container.description-only zui-progress-ring,#container.headline-only zui-progress-ring,#container.headline-with-description zui-progress-ring{margin-bottom:19px}#container.headline-with-description #headline{margin-bottom:6px}#container.headline-with-description #description{color:var(--zui-color-loader-box-description-text-with-headline)}zui-progress-ring{--zui-progress-ring-rail-color: var(--zui-color-loader-box-progress-ring-rail);--zui-progress-ring-progress-color: var(--zui-color-loader-box-progress-ring-progress);--zui-color-progress-ring-default-text: var(--zui-color-loader-box-progress-ring-default-text)}"
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-loader-box-bg: rgba(var(--zui-color-gs-120-rgb), 0.85);--zui-color-loader-box-headline-text: var(--zui-color-gs-10);--zui-color-loader-box-description-text: var(--zui-color-gs-50);--zui-color-loader-box-description-text-with-headline: var(--zui-color-gs-70);--zui-color-loader-box-progress-ring-rail: var(--zui-color-gs-90);--zui-color-loader-box-progress-ring-progress: var(--zui-color-gs-40);--zui-color-loader-box-progress-ring-default-text: var(--zui-color-gs-50)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-loader-box-bg: rgba(var(--zui-color-gs-120-rgb), 0.85);--zui-color-loader-box-headline-text: var(--zui-color-gs-10);--zui-color-loader-box-description-text: var(--zui-color-gs-50);--zui-color-loader-box-description-text-with-headline: var(--zui-color-gs-70);--zui-color-loader-box-progress-ring-rail: var(--zui-color-gs-90);--zui-color-loader-box-progress-ring-progress: var(--zui-color-gs-40);--zui-color-loader-box-progress-ring-default-text: var(--zui-color-gs-50)}:host([theme=translucent]),:host([theme=zbds-translucent]){--zui-color-loader-box-bg: rgba(var(--zui-color-gs-120-rgb), 0.85);--zui-color-loader-box-headline-text: var(--zui-color-gs-10);--zui-color-loader-box-description-text: var(--zui-color-gs-50);--zui-color-loader-box-description-text-with-headline: var(--zui-color-gs-70);--zui-color-loader-box-progress-ring-rail: var(--zui-color-gs-90);--zui-color-loader-box-progress-ring-progress: var(--zui-color-gs-40);--zui-color-loader-box-progress-ring-default-text: var(--zui-color-gs-50)}"
import { classMap } from 'lit/directives/class-map.js';
// dependencies
import '../progress-ring/progress-ring.component.js';
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const themeTranslucent = ""
const themeLight = ""
const themeDark = ""
const loaderBoxStyles = css `
  ${unsafeCSS(style)}
`;
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
/**
 * This component combines a progress ring with several texts.
 *
 * It is only available in translucent theme.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-loader-box headline="Headline" description="Description"></zui-loader-box>
 * ```
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=45966%3A1)
 * - [Styleguide – Desktop](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---6.1?node-id=13009%3A2769)
 *
 */
let LoaderBox = class LoaderBox extends BaseElement {
    constructor() {
        super();
        /**
         * Defines headline
         */
        this.headline = '';
        /**
         * Defines description
         */
        this.description = '';
        /**
         * Defines mode
         */
        this.mode = 'activity';
        /**
         * It allows to show the value as a percentage text (only usable with mode progress)
         */
        this.showPercent = false;
        /**
         * Defines the value of progress between 0 and 100 (only usable with mode progress)
         */
        this.value = 0;
        // loader-box should always have translucent theme.
        this.theme = 'translucent';
    }
    // maps from component state _content to a css class
    get _contentClass() {
        if (this.headline && this.headline.trim() && this.description && this.description.trim()) {
            return 'headline-with-description';
        }
        if (this.headline && this.headline.trim()) {
            return 'headline-only';
        }
        if (this.description && this.description.trim()) {
            return 'description-only';
        }
        // return '_' as special css class, that is never used; but we have to return something...
        return '_';
    }
    render() {
        return html `<div id="container" class="${classMap({ [this._contentClass]: true })}">
      <zui-progress-ring
        size="s72"
        value="${this.value}"
        mode="${this.mode}"
        ?show-percent="${this.showPercent}"
      ></zui-progress-ring>
      <div id="headline">${this.headline}</div>
      <div id="description">${this.description}</div>
    </div>`;
    }
};
LoaderBox.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    loaderBoxStyles,
];
__decorate([
    property({ reflect: true, type: String })
], LoaderBox.prototype, "headline", void 0);
__decorate([
    property({ reflect: true, type: String })
], LoaderBox.prototype, "description", void 0);
__decorate([
    property({ reflect: true, type: String })
], LoaderBox.prototype, "mode", void 0);
__decorate([
    property({ reflect: true, attribute: 'show-percent', type: Boolean })
], LoaderBox.prototype, "showPercent", void 0);
__decorate([
    property({ reflect: true, type: Number })
], LoaderBox.prototype, "value", void 0);
LoaderBox = __decorate([
    customElement('zui-loader-box')
], LoaderBox);
export { LoaderBox };
