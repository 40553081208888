import { useGlobalState, useGlobalStateContext } from 'piral-core';
import { type FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useClose } from '../../hooks/use-close';
import type { BellNotification } from '../../plugins/bell-notification/types';
import { BellNotificationMenu } from './BellNotificationMenu';
import {
  Popover,
  PopoverContainer,
  PopoverHeader,
  Scrollable,
  StyledNotificationItemAction,
  StyledPopoverHeaderHeadline,
} from './BellNotificationPopoverContent.styles';
import { markAllNotificationsRead } from './mark-notification-read.utils';

interface PopoverProps {
  onClose(): void;
}

const BellNotificationPopoverContent: FC<PopoverProps> = ({ onClose }) => {
  const ref = useClose('bell-notifications', onClose);
  const { t } = useTranslation();
  const context = useGlobalStateContext();
  const notifications: Array<BellNotification> = useGlobalState((state) => state.bellNotifications);

  const checkClose = useCallback(
    (ev: { target: EventTarget | null }) => {
      const element = ev.target;

      if (element instanceof HTMLElement && !element.closest('zui-popover')) {
        onClose();
      }
    },
    [onClose]
  );

  function handleMarkAllAsRead() {
    markAllNotificationsRead(notifications, context);
  }

  useEffect(() => {
    window.document.addEventListener('bellNotificationActionClicked', onClose);
    return () => {
      window.document.removeEventListener('bellNotificationActionClicked', onClose);
    };
  }, [onClose]);

  return (
    <PopoverContainer ref={ref} className="popover" slot="icon" onClick={checkClose}>
      <Popover>
        <PopoverHeader>
          <StyledPopoverHeaderHeadline>
            {t('bellNotificationMenu.header')}
          </StyledPopoverHeaderHeadline>
          {Boolean(notifications.length) && (
            <StyledNotificationItemAction
              text={t('bellNotificationMenu.markAllAsRead')}
              onClick={handleMarkAllAsRead}
            />
          )}
        </PopoverHeader>
        <Scrollable>
          <BellNotificationMenu />
        </Scrollable>
      </Popover>
    </PopoverContainer>
  );
};

export default BellNotificationPopoverContent;
