import { type ErrorInfoProps, ExtensionSlot } from 'piral-core';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const ErrorInfo: FC<ErrorInfoProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <h1 className="title" data-test="error-title">
        {t('errorPage.title')}
      </h1>
      <ExtensionSlot name="app-shell-error-switch" params={props} />
    </div>
  );
};
