import './set-zui-session-storage-item'; // must be loaded first, keep this on top

import { registerSessionSync } from './auth';
import { setupApp } from './setup';
import type { AppConfig } from './types';

// We only want to run the app when we retrieved the config and are sure
// that the browser is compatible / supported.
// This event is picked up / handled in "browser-check.js"
window.dispatchEvent(
  new CustomEvent('queue-hdp-setup', {
    detail(config: AppConfig) {
      (document.getElementById('app') as HTMLElement).style.display = 'flex';
      registerSessionSync();
      setupApp(config);
    },
  })
);
