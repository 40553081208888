// type guard to differentiate between touch and mouse events
export const isTouchEvent = (event) => 'touches' in event;
// delivers the cursor (or pointer) position from a given touch _or_ mouse event
export const getCursorPosition = (event) => {
    const { clientX, clientY } = isTouchEvent(event) ? event.touches[0] : event;
    return { clientX, clientY };
};
/**
 * traverse a DOM tree for siblings in either previous or next direction
 * and utilizes a step length, i.e. it either advances 1 or multiple steps
 *
 * @param current Element to traverse from
 * @param direction to traverse to
 * @param step length that should be traverse, defaults to 1
 * @param isValidNextElement is a predicate, indicating whether the currently traverse element is valid; defaults to a
 *   comparison of tagNames of passed current element
 * @returns either a matching Element or null, if none is found
 */
export function traverseDOMSiblingsByStepAndDirection(current, direction, step = 1, isValidNextElement = (element) => element.tagName === current.tagName) {
    // determine next element by binding to direction
    const nextElement = (element) => direction === 'previous' ? element.previousElementSibling : element.nextElementSibling;
    const advanceElement = (element) => Array.from({ length: step }).reduce((acc) => {
        return acc ? nextElement(acc) : null;
    }, element);
    let nextElementInDirection = current;
    do {
        nextElementInDirection = advanceElement(nextElementInDirection);
    } while (nextElementInDirection && isValidNextElement(nextElementInDirection) === false);
    return nextElementInDirection;
}
/**
 * return whether a boolean DOM attribute is set
 *
 * @param elm DOM element to be checked for
 * @param attr name of the attribute to be checked for
 * @returns true if the attribute is set on the passed element
 */
export function isBooleanDOMAttributeSet(elm, attr) {
    const attrValue = elm.getAttribute(attr);
    // a boolean DOM attribute is true...
    // if it is present
    if (attrValue === '') {
        return true;
        // if it is set to its name
    }
    else if (attrValue === attr) {
        return true;
    }
    else {
        return false;
    }
}
/**
 *
 * @param otherElement the node we like to know whether it is before an element or not
 * @param element the node the otherElement position is tested against
 *
 * @returns boolean true when otherElement is before element
 */
export const isElementBeforeOther = (otherElement, element) => {
    return ((element.compareDocumentPosition(otherElement) & Node.DOCUMENT_POSITION_PRECEDING) ===
        Node.DOCUMENT_POSITION_PRECEDING);
};
/**
 *
 * @param otherElement the node we like to know whether it is after an element or not
 * @param element the node the otherElement position is tested against
 *
 * @returns boolean true when otherElement is after element
 */
export const isElementAfterOther = (otherElement, element) => {
    return ((element.compareDocumentPosition(otherElement) & Node.DOCUMENT_POSITION_FOLLOWING) ===
        Node.DOCUMENT_POSITION_FOLLOWING);
};
/**
 * Convenient function to retrieve the string representation of slotted content
 *
 * @param slot of which contents shall be revealed
 * @param preserveMarkup allows grabbing the html contents rather then pure text contents
 * @returns string representation of the slot contents
 */
export const getProjectedText = (slot, preserveMarkup = false) => {
    return slot.assignedNodes({ flatten: true }).reduce((content, node) => {
        const part = preserveMarkup && 'outerHTML' in node ? node['outerHTML'] : node.textContent;
        return content + part;
    }, '');
};
/**
 * Checks the given element for having some overflow going on
 *
 * @param element to check
 * @returns boolean whether content flows over or not
 */
export const hasOverflow = (element) => {
    return element.scrollWidth > element.offsetWidth;
};
/**
 * creates an EventListener function that will stop events if predicate is true
 *
 * @param predicate function to call
 * @returns an EventListener function
 */
export const stopEventListenerByPredicate = (predicate) => {
    return (event) => {
        if (predicate()) {
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
    };
};
