import {
  ZuiDialogButton,
  ZuiEwiqDialog,
  ZuiEwiqDialogButtons,
  ZuiEwiqDialogDescription,
  ZuiEwiqDialogHeadline,
} from '@zeiss/zui-react';
import type { TFunction } from 'i18next';
import type { NotificationOptions, NotificationsToastProps } from 'piral-notifications';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { getContent } from '../../utils/content';

type EwiqState = NonNullable<Parameters<typeof ZuiEwiqDialog>[0]['state']>;

const Container = styled.div`
  padding: var(--arn-space-1);
`;

const StyledZuiEwiqDialogDescription = styled(ZuiEwiqDialogDescription)`
  font: var(--zui-typography-body);
`;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

function mapTypeToEwiqState(options: NotificationOptions): EwiqState {
  if (options.question) {
    return 'question';
  }

  switch (options.type) {
    case 'error':
      return 'error';
    case 'info':
      return 'info';
    case 'warning':
      return 'warning';
    case 'success':
      return 'check';
    default:
      return 'info';
  }
}

function getButtons(t: TFunction, options: NotificationOptions, close: () => void) {
  if (options.question) {
    const {
      acceptLabel = 'OK',
      cancelLabel = 'Cancel',
      onAccept = noop,
      onCancel = noop,
    } = options.question;

    const accept = () => {
      onAccept();
      close();
    };

    const cancel = () => {
      onCancel();
      close();
    };

    return (
      <>
        <ZuiDialogButton slot="primary" onClick={accept} emphasis="primary-highlight">
          {getContent(acceptLabel)}
        </ZuiDialogButton>
        <ZuiDialogButton onClick={cancel} emphasis="default">
          {getContent(cancelLabel)}
        </ZuiDialogButton>
      </>
    );
  }

  const buttonText = options.type === 'info' ? t('generic.ok') : t('generic.close');

  return (
    <ZuiDialogButton slot="primary" onClick={close}>
      {buttonText}
    </ZuiDialogButton>
  );
}

export const NotificationsToast: FC<NotificationsToastProps> = ({ children, options, onClose }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <ZuiEwiqDialog state={mapTypeToEwiqState(options)}>
        <ZuiEwiqDialogHeadline slot="headerText">{options.title}</ZuiEwiqDialogHeadline>
        <StyledZuiEwiqDialogDescription>{children}</StyledZuiEwiqDialogDescription>
        <ZuiEwiqDialogButtons slot="footer">{getButtons(t, options, onClose)}</ZuiEwiqDialogButtons>
      </ZuiEwiqDialog>
    </Container>
  );
};
