import type { BareModalComponentProps, InitialModalDialog, ModalLayoutOptions } from 'piral-modals';
import type { ComponentType } from 'react';

export function makeModal<T>(
  name: string,
  component: ComponentType<BareModalComponentProps<T>>,
  layout: ModalLayoutOptions
): InitialModalDialog {
  return {
    name,
    component,
    defaults: {},
    layout,
  };
}
