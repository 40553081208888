import { reactify, ZuiMenubarNavItem } from '@zeiss/zui-react';
import { type FC, useMemo } from 'react';

import { useEmphasis } from '../hooks/use-emphasis';
import { useNavAction } from '../hooks/use-nav-action';
import type { MenuBarItemProps } from '../types';
import { getContent } from '../utils/content';

// 459722 bugfix - solution to provide unique value<string> for the ZuiMenubarNavItem 'value' attribute
let uniqueValue = 0;

export const MenuBarItem: FC<MenuBarItemProps> = ({ href, icon, callback, title }) => {
  const emphasis = useEmphasis(href);
  const Icon = reactify(icon);
  const action = useNavAction(href, callback);
  const value = useMemo(() => `${uniqueValue++}`, []);

  return (
    <ZuiMenubarNavItem emphasis={emphasis} onClick={action} value={value}>
      <Icon size="s16" slot="icon" />
      {getContent(title)}
    </ZuiMenubarNavItem>
  );
};
