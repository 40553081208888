var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ValidationMessageDirective_1;
import { html, nothing, LitElement, unsafeCSS, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { generateUid } from '../../utils/portal.utils.js';
import { getHostElementFromChild } from '../../utils/mixin.utils.js';
import { BaseElement } from '../../components/base/base-element.class.js';
import '../../mixins/form-participation/form-participation.interfaces.js';
import '../../mixins/validation-style/validation-style.interfaces.js';
import { hostStyles } from '../../host.styles.js';
// deps
import '../../components/tooltip/tooltip.component.js';
import '../../components/error-message/error-message.component.js';
import '../../directives/overlay/overlay.directive.js';
import { TooltipDirective } from '../../directives/tooltip/tooltip.directive.js';
const style = ""
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
export const tooltipDirectiveStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * Directive that handles the display of the validation message, only works with ValidationStyleMixin-enabled hosts.
 *
 * @example
 * ```html
 * <zui-validation-message-directive
 *    validation-message=${this.validationMessage}
 *    .messageAnchorReferenceCallback=${this._messageAnchorReferenceCallback}
 *    .triggerHostReferenceCallback=${this._triggerHostReferenceCallback}
 * ></zui-validation-message-directive>
 * ```
 *
 */
let ValidationMessageDirective = ValidationMessageDirective_1 = class ValidationMessageDirective extends LitElement {
    constructor() {
        super(...arguments);
        /**
         * Callback for an element that the validation message should be anchored to.
         * Only relevant for validation-message-style="static-tooltip"
         */
        this.messageAnchorReferenceCallback = undefined;
        /**
         * Reference for an element that triggers the visibility of the validation message.
         * Only relevant for validation-message-style="cursor-anchored-tooltip" and validation-message-style="component-anchored-tooltip"
         */
        this.triggerHostReference = undefined;
        /**
         * The validation message set by the form-validation mixin of the host.
         * Big TODO: Ideally there should not be an explicit dependency to the host. We have evaluated different solutions (Metadata, lifycycle, mutation observer) but none of them are satisfactory.
         * We aim to pass the message as a default slot once the portal has been refactored and accepts a text node (https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/829178)
         */
        this.validationMessage = '';
        // These are synced with the host's properties.
        this._showValidation = false;
        this._supportedValidationMessageStyles = [];
        this._staticTooltipPortalUid = `validation-style-static-tooltip-warning-${generateUid()}`;
        this._tooltipOffset = () => TooltipDirective.TOOLTIP_FIXED_POSITIONING_OFFSET;
    }
    connectedCallback() {
        super.connectedCallback();
        // assign host once as it will not change but is unaccessible via a getter on disconnectedCallback because the component is already removed from the DOM
        this._host = getHostElementFromChild(this);
        // We initially have to sync the private fields with the hosts properties because the directive could be rendered at any point.
        this._showValidation = this._host.showValidation;
        this._validationMessageStyle = this._host.validationMessageStyle;
        this._supportedValidationMessageStyles = Reflect.getMetadata('supportedValidationMessageStyles', this._host, 'validationMessageStyle');
        // we need a reference to ourselves in a propDescy that we attach to our host and
        // thus with another this context
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const directive = this;
        Object.defineProperty(this._host, 'showValidation', {
            configurable: true,
            get() {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return Reflect.get(Reflect.getPrototypeOf(this), 'showValidation', this);
            },
            set(val) {
                directive._showValidation = val;
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                Reflect.set(Reflect.getPrototypeOf(this), 'showValidation', val, this);
            },
        });
        Object.defineProperty(this._host, 'validationMessageStyle', {
            configurable: true,
            get() {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return Reflect.get(Reflect.getPrototypeOf(this), 'validationMessageStyle', this);
            },
            set(val) {
                directive._validationMessageStyle = val;
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                Reflect.set(Reflect.getPrototypeOf(this), 'validationMessageStyle', val, this);
            },
        });
    }
    disconnectedCallback() {
        delete this._host.validationMessageStyle;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore because this is the way to remove getters/setters
        delete this._host.showValidation;
        super.disconnectedCallback();
    }
    render() {
        if (!this._showValidation) {
            return nothing;
        }
        if (!this._supportedValidationMessageStyles.includes(this._validationMessageStyle)) {
            return nothing;
        }
        switch (this._validationMessageStyle) {
            case 'line-only':
                return nothing;
            case 'static-text':
                return html `<zui-error-message>${this.validationMessage}</zui-error-message>`;
            case 'static-tooltip':
                return html `<zui-overlay-directive
          .offsetHandler="${this._tooltipOffset}"
          .placements="${ValidationMessageDirective_1.WARNING_MESSAGE_PLACEMENTS}"
          .positionReferenceCallback="${this.messageAnchorReferenceCallback}"
          portal="${this._staticTooltipPortalUid}"
        >
          <zui-tooltip emphasis="warning">${this.validationMessage}</zui-tooltip>
        </zui-overlay-directive>`;
            case 'cursor-anchored-tooltip':
                return html `
          <zui-tooltip-directive trigger="hover" anchoring="cursor" .triggerHostReference=${this.triggerHostReference}>
            <zui-tooltip emphasis="warning">${this.validationMessage}</zui-tooltip>
          </zui-tooltip-directive>
        `;
            case 'component-anchored-tooltip':
                return html `<zui-tooltip-directive
          trigger="focus,hover"
          anchoring="component"
          .triggerHostReference=${this.triggerHostReference}
        >
          <zui-tooltip emphasis="warning">${this.validationMessage}</zui-tooltip>
        </zui-tooltip-directive>`;
            default:
                // exhaustiveness checking
                // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
                const t = this._validationMessageStyle;
                return nothing;
        }
    }
};
ValidationMessageDirective.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    tooltipDirectiveStyles,
];
// eslint-disable-next-line @typescript-eslint/naming-convention
ValidationMessageDirective.WARNING_MESSAGE_PLACEMENTS = ['bottom-start'];
__decorate([
    property({ attribute: false, type: Object })
], ValidationMessageDirective.prototype, "messageAnchorReferenceCallback", void 0);
__decorate([
    property({ attribute: false, type: Object })
], ValidationMessageDirective.prototype, "triggerHostReference", void 0);
__decorate([
    property({ attribute: 'validation-message', type: String })
], ValidationMessageDirective.prototype, "validationMessage", void 0);
__decorate([
    state()
], ValidationMessageDirective.prototype, "_showValidation", void 0);
__decorate([
    state()
], ValidationMessageDirective.prototype, "_validationMessageStyle", void 0);
ValidationMessageDirective = ValidationMessageDirective_1 = __decorate([
    customElement('zui-validation-message-directive')
], ValidationMessageDirective);
export { ValidationMessageDirective };
