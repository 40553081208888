export const iconSets = {
    common: {
        check: 'zui-icon-alerts-ewi-check',
        error: 'zui-icon-alerts-ewi-error-triangle',
        info: 'zui-icon-alerts-ewi-info',
        question: 'zui-icon-alerts-ewi-question',
        warning: 'zui-icon-alerts-ewi-warning-triangle',
    },
    meditec: {
        check: 'zui-icon-alerts-ewi-check',
        error: 'zui-icon-alerts-ewi-error-round',
        info: 'zui-icon-alerts-ewi-info',
        question: 'zui-icon-alerts-ewi-question',
        warning: 'zui-icon-alerts-ewi-warning-round',
    },
};
