import { ZuiDialogBox } from '@zeiss/zui-react';
import type { ModalsDialogProps } from 'piral-modals';
import { type FC, useCallback } from 'react';
import styled from 'styled-components';

import { useClose } from '../../hooks/use-close';
import { useContent } from '../../hooks/use-content';
import type { DialogLayout } from '../../types';

export type DialogProps = Parameters<typeof ZuiDialogBox>[0];

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const defaultLayout: Required<DialogLayout> = {
  title: '',
  closable: true,
  canClose: true,
  withLayout: true,
  acceptLabel: 'OK',
  cancelLabel: 'Cancel',
  dialogProps: {},
  onCancel: noop,
  onAccept: noop,
};

const EmptyDialog = styled.div`
  max-height: 100%;
  border-radius: var(--arn-space-2);
  background-color: var(--zui-color-gs-10);
`;

export const DefaultDialogLayout: FC<ModalsDialogProps> = ({
  id,
  children,
  close,
  layout = {},
}) => {
  const {
    closable = defaultLayout.closable,
    acceptLabel = defaultLayout.acceptLabel,
    canClose = defaultLayout.canClose,
    cancelLabel = defaultLayout.cancelLabel,
    onCancel = close,
    onAccept = close,
    dialogProps = defaultLayout.dialogProps,
    title = defaultLayout.title,
    withLayout = defaultLayout.withLayout,
  } = layout;

  const tryClose = useCallback(
    (ev: Event) => {
      if (!closable) {
        // If this dialog is not closable we ignore this close request
        noop();
      } else if (typeof canClose === 'function' && !canClose(ev)) {
        // We also ignore it if we have a canClose function telling us to ignore it
        noop();
      } else if (canClose === false) {
        // Or if canClose is always set to false
        noop();
      } else {
        // Otherwise, it's fine - let's close it
        close();
      }
    },
    [close, closable, canClose]
  );

  const titleStr = useContent(title);
  const acceptStr = useContent(acceptLabel);
  const cancelStr = useContent(cancelLabel);
  const ref = useClose(id, tryClose);

  return withLayout ? (
    <ZuiDialogBox
      ref={ref}
      closable={closable}
      headerText={titleStr}
      acceptLabel={acceptStr}
      cancelLabel={cancelStr}
      onDialogBoxClosed={tryClose}
      onDialogBoxCancelled={onCancel}
      onDialogBoxAccepted={onAccept}
      {...dialogProps}
    >
      {children}
    </ZuiDialogBox>
  ) : (
    <EmptyDialog ref={ref}>{children}</EmptyDialog>
  );
};
