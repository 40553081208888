var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { property } from 'lit/decorators.js';
import { BaseElement } from '../base/base-element.class.js';
import { stringUndefinedConverter } from '../../utils/component.utils.js';
import { GetSharedEventClass } from '../../contracts/event.classes.js';
import '../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../mixins/events/events.mixin.js';
import { DisabledMixin } from '../../mixins/disabled/disabled.mixin.js';
import 'lit';
class ContentTabItemClosedEvent extends GetSharedEventClass('content-tab-item-closed', { bubbles: true }) {
}
/**
 * Abstract base class for all content-tab-items that are used in the content-tab-bar.
 */
export class ContentTabItemBase extends DisabledMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.ClosedEvent = ContentTabItemClosedEvent;
    }
})) {
    constructor() {
        super(...arguments);
        /**
         * marker to know at runtime that this element is a content-tab-item.
         *
         * @private
         */
        this.isContentTabItem = true;
        /**
         * Defines one of three possible emphasis states (default/selected).
         */
        this.emphasis = 'default';
        /**
         * It is possible to use this attribute to show the close icon in the component.
         */
        this.closable = false;
    }
}
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'zui-internal-is-content-tab-item' })
], ContentTabItemBase.prototype, "isContentTabItem", void 0);
__decorate([
    property({ reflect: true, type: String })
], ContentTabItemBase.prototype, "emphasis", void 0);
__decorate([
    property({ reflect: true, converter: stringUndefinedConverter })
], ContentTabItemBase.prototype, "value", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], ContentTabItemBase.prototype, "closable", void 0);
__decorate([
    property({ reflect: true, converter: stringUndefinedConverter })
], ContentTabItemBase.prototype, "text", void 0);
