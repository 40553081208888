var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement } from '../base/base-element.class.js';
import { hostStyles } from '../../host.styles.js';
const style = ":host{box-sizing:border-box;padding:calc(var(--zui-space-extra-small) - var(--zui-line-strength-thin)) var(--zui-space-large);overflow:hidden;background-color:var(--zui-color-text-box-bg);border-color:var(--zui-color-thin-line-highlight);border-style:solid;border-width:var(--zui-line-strength-thin);border-right:none;border-left:none}:host([header-alignment=left]),:host([force-left-alignment]){text-align:left !important}:host([header-alignment=center]){text-align:center}::slotted(*){font:var(--zui-typography-label2);text-transform:var(--zui-typography-label2-text-transform);overflow:hidden;color:var(--zui-color-text-divider);white-space:nowrap;text-overflow:ellipsis}"
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-text-divider: var(--zui-color-gs-85);--zui-color-thin-line-default: var(--zui-color-gs-40);--zui-color-thin-line-highlight: var(--zui-color-gs-60);--zui-color-thick-line-default: var(--zui-color-gs-40);--zui-color-thick-line-highlight: var(--zui-color-gs-60);--zui-color-thick-line-primary: var(--zui-color-pb-100);--zui-color-text-box-bg: var(--zui-color-gs-30)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-text-divider: var(--zui-color-gs-70);--zui-color-thin-line-default: var(--zui-color-gs-110);--zui-color-thin-line-highlight: var(--zui-color-gs-120);--zui-color-thick-line-default: var(--zui-color-gs-110);--zui-color-thick-line-highlight: var(--zui-color-gs-120);--zui-color-thick-line-primary: var(--zui-color-pb-90);--zui-color-text-box-bg: var(--zui-color-gs-110)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const sectionDividerStyles = css `
  ${unsafeCSS(style)}
`;
const MINIMUM_WIDTH = 559;
/**
 * A divider is a horizontal line that can be used to define a thematic break.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=20971%3A190657)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=805%3A198897)
 *
 * ## Skeleton
 * The Skeleton components can be added to the named skeleton slot and will be displayed when **nothing** is in the default slot.
 * When the skeleton is not displayed make sure there are no spaces or line breaks passed in the default slot.
 *
 * **Attention**: Respecting the specs of the ZUi Section Divider, the header text will always be left aligned, when the component has a width of 559px or more.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-section-divider header-alignment="center">
 *   <span>Text Content</span>
 * </zui-section-divider>
 * ```
 *
 * Section Divider with Skeleton
 * ```html
 * <zui-section-divider header-alignment="center"
 *    ><zui-skeleton-rectangle slot="skeleton" style="--zui-skeleton-rectangle-width: 200px; --zui-skeleton-rectangle-height: 12px;"
 *    ></zui-skeleton-rectangle
 * ></zui-section-divider>
 * ```
 *
 * @slot default - any html element or text content
 * @slot skeleton - This is the slot for adding a skeleton instead text content. The Skeleton is only displayed when the default slot is empty.
 */
let SectionDivider = class SectionDivider extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * Defines one of two possible positions of the header text alignment (left/center);
         */
        this.headerAlignment = 'left';
        this._widthObserver = new ResizeObserver(() => {
            const sectionDividerWidth = this.offsetWidth;
            if (sectionDividerWidth > MINIMUM_WIDTH) {
                this.setAttribute('force-left-alignment', '');
            }
            else {
                this.removeAttribute('force-left-alignment');
            }
        });
    }
    disconnectedCallback() {
        this._widthObserver.disconnect();
        super.disconnectedCallback();
    }
    /**
     * Adds width Observer to the Section Divider component
     */
    firstRendered() {
        super.firstRendered();
        this._widthObserver.observe(this);
    }
    render() {
        return html `<slot>
      <slot name="skeleton"></slot>
    </slot>`;
    }
};
SectionDivider.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    sectionDividerStyles,
];
__decorate([
    property({ reflect: true, attribute: 'header-alignment' })
], SectionDivider.prototype, "headerAlignment", void 0);
SectionDivider = __decorate([
    customElement('zui-section-divider')
], SectionDivider);
export { SectionDivider };
