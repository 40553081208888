import type { SyntheticEvent } from 'react';

import { getUserDefinedLanguage } from './intl/user-defined-language';

declare global {
  interface Window {
    Optanon: {
      ToggleInfoDisplay(): void;
      changeLanguage(language: string): void;
    };
    dataLayer: Array<unknown>;
    OptanonWrapper(): void;
  }
}

export function installConsentScript(oneTrustId: string): void {
  window.OptanonWrapper = () => {
    // Empty on purpose
  };

  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('charset', 'UTF-8');
  script.setAttribute('data-document-language', 'true');
  script.setAttribute('data-domain-script', oneTrustId);
  script.src = `https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`;
  document.body.appendChild(script);
}

export function showConsent(e?: SyntheticEvent): void {
  e?.preventDefault();
  // Set fallback language for "pt-PT" and "pt-BR" to "en"
  // eslint-disable-next-line no-warning-comments
  // TODO: remove workaround as soon as we have translations and this is clarified with OneTrust
  const userLanguage = getUserDefinedLanguage();

  if (userLanguage === 'pt-BR' || userLanguage === 'pt-PT') {
    window.Optanon?.changeLanguage('en');
    window.Optanon?.ToggleInfoDisplay();
    window.Optanon?.changeLanguage(userLanguage);
    return;
  }

  window.Optanon?.ToggleInfoDisplay();
}

export function changeConsentLanguage(language: string): void {
  window.Optanon?.changeLanguage(language);
}
