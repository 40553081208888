var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var TextfieldMonthPicker_1;
import { css, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { hostStyles } from '../../../host.styles.js';
import { DateTime } from 'luxon';
import '../../../types.js';
import { getDateTimesFromJsDates, getDefaultLocale, isoDateConverter, someIsSameMonth, someIsSameYear, } from '../utils/date-picker.utils.js';
import { FormValidationMixin } from '../../../mixins/form-participation/form-validation.mixin.js';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin.js';
import '../../../mixins/form-participation/form-participation.types.js';
import { isDefined } from '../../../utils/component.utils.js';
import { generateUid, getContentsFromPortal } from '../../../utils/portal.utils.js';
import '../../../directives/overlay/overlay.directive.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { BaseElement } from '../../base/base-element.class.js';
import '../../../contracts/event.contracts.js';
import { GetNotificationEventClass, MimickedChangeEvent, MimickedInputEvent, } from '../../../contracts/event.classes.js';
import '../../../contracts/validation-style.contract.js';
// deps
import '../../popover/popover.component.js';
import '../../tooltip/tooltip.component.js';
import '../month-picker-input/month-picker-input.component.js';
import '../date-picker-input-part/date-picker-input-part.component.js';
import '../date-picker-input-part-month/date-picker-input-part-month.component.js';
import '../../interactive-icon/interactive-icon.component.js';
import '../date-picker/date-picker.component.js';
import { InvalidDate } from '../invalid-date.class.js';
import { ValidationStyleMixin } from '../../../mixins/validation-style/validation-style.mixin.js';
import '../../../directives/validation-message/validation-message.directive.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const styles = ":host{--zui-textfield-month-picker-input-width: calc(var(--zui-gu) * 15);--zui-textfield-month-picker-month-placeholder-width: calc(var(--zui-gu) * 4.65);--zui-textfield-month-picker-year-placeholder-width: calc(var(--zui-gu) * 4.75);--zui-date-picker-month-input-parts-gap-width: calc(var(--zui-gu) * 0.5);position:relative;width:var(--zui-textfield-month-picker-input-width)}zui-month-picker-input{--zui-month-picker-input-parts-gap-width: var(--zui-date-picker-month-input-parts-gap-width);--zui-month-picker-input-width: var(--zui-textfield-month-picker-input-width);--zui-month-picker-input-month-placeholder-width: var(--zui-textfield-month-picker-month-placeholder-width);--zui-month-picker-input-year-placeholder-width: var(--zui-textfield-month-picker-year-placeholder-width)}"
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const textfieldMonthPickerStyles = css `
  ${unsafeCSS(styles)}
`;
class TextfieldMonthPickerDateSelectedEvent extends GetNotificationEventClass('textfield-month-picker-date-selected') {
}
/* eslint-disable jsdoc/require-property-description */
/**
 * The textfield date picker month component shows an input and opens the date picker when the interactive icon is selected. In contrast to the textfield-date-picker it features a literal display
 * of the months and only allows for a months selection.
 *
 * ## Figma
 * - [Desktop - Component Library - Text Field](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---2.6?node-id=21190%3A191753)
 * - [Desktop - Component Library - Date Picker](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---2.7?node-id=384%3A64193)
 * - [Styleguide – Desktop - Text Field](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=6557%3A241082)
 * - [Styleguide – Desktop - Date Picker](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=48826%3A397355)
 *
 * @example
 * HTML:
 *
 * Textfield date picker
 * ```html
 *   <zui-textfield-month-picker
 *     close-on-date-selected
 *     locale="en-US"
 *     max="2010-01-01T00:00:00.000+01:00"
 *     min="2020-12-31T23:59:59.999+01:00"
 *     name="textfieldMonthPicker"
 *     placeholder-month="MMM"
 *     placeholder-year="YYYY"
 *     value="2021-07-21T11:00:00.000+02:00"
 *   >
 *   </zui-textfield-month-picker>
 * ```
 *
 * Form example
 * ```html
 * <form>
 *   <button type="reset">reset</button>
 *   <button type="submit">submit</button>
 *   <zui-textfield-month-picker
 *    ...
 *   >
 *   </zui-textfield-month-picker>
 * </form>
 * ```
 *
 * @fires {GetZuiEvent<TextfieldMonthPicker, 'DateSelectedEvent'>} zui-textfield-month-picker-date-selected - emits the date after a selection event.
 * @fires change - The change event is fired when the value of the zui-textfield-month-picker has changed
 * @fires input - The input event is fired when the value of the zui-textfield-month-picker has received input
 *
 * @cssprop --zui-textfield-month-picker-input-width - size of the input - default width is 120px
 * @cssprop --zui-textfield-month-picker-month-placeholder-width - override default month input placeholder width that is optimized for MMM
 * @cssprop --zui-textfield-month-picker-year-placeholder-width - override default year input placeholder width that is optimized for YYYY
 * @cssprop --zui-date-picker-month-input-parts-gap-width - sets some margin in between the input parts instead of literals
 *
 * @property { "line-only" | "static-text" | "static-tooltip" | "cursor-anchored-tooltip" | "component-anchored-tooltip" } [validationMessageStyle=component-anchored-tooltip]
 */
let TextfieldMonthPicker = TextfieldMonthPicker_1 = 
/* eslint-enable jsdoc/require-property-description */
class TextfieldMonthPicker extends ValidationStyleMixin(FormValidationMixin(FormDataHandlingMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.InputEvent = MimickedInputEvent;
        this.ChangeEvent = MimickedChangeEvent;
        this.DateSelectedEvent = TextfieldMonthPickerDateSelectedEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}))), {
    supportedValidationMessageStyles: [
        'line-only',
        'static-text',
        'static-tooltip',
        'cursor-anchored-tooltip',
        'component-anchored-tooltip',
    ],
    defaultValidationMessageStyle: 'component-anchored-tooltip',
}) {
    constructor() {
        super();
        /* eslint-enable @typescript-eslint/naming-convention */
        /**
         * whether the picker should be closed after date selection or not
         */
        this.closeOnDateSelected = false;
        /**
         * disabled months
         */
        this.disabledMonths = [];
        /**
         * disabled years
         */
        this.disabledYears = [];
        /**
         * BCP 47 language tag, e.g. 'en-US'
         */
        this.locale = getDefaultLocale();
        /**
         * max date as an ISO 8601 date string
         * the start of the selected month must be before the specified max date.
         */
        this.max = null;
        /**
         * min date as an ISO 8601 date string
         * the start of the selected month must be after the specified min date
         */
        this.min = null;
        /**
         * placeholder month for input
         */
        this.placeholderMonth = 'MMM';
        /**
         * placeholder year for input
         */
        this.placeholderYear = 'YYYY';
        /**
         * required in form validation
         */
        this.required = false;
        /**
         * specifies if the date-picker should be displayed.
         */
        this.showCalendar = false;
        /**
         * selected date value of the control as an ISO 8601 date string.
         */
        this.value = null;
        /**
         * This state serves as a default fallback value if the current _valueDT is not set.
         * e.g.: this internal value is set in an error case.
         */
        this._internalDateTimeValue = {
            month: null,
            year: null,
        };
        this._currentDate = null;
        this._userInteraction = false;
        this._datePickerMonthPortal = `date-picker-overlay-${generateUid()}`;
        this._textfieldMonthPickerResizeObserver = new ResizeObserver(() => {
            requestAnimationFrame(() => {
                var _a, _b;
                (_a = this._textfieldMonthPickerPopoverOverlayRef) === null || _a === void 0 ? void 0 : _a.forcePositioning();
                (_b = this._textfieldMonthPickerWarningMessageOverlayRef) === null || _b === void 0 ? void 0 : _b.forcePositioning();
            });
        });
        this._disabledDateValidator = () => {
            if (!this._valueDT) {
                return true;
            }
            const isDisabledMonthOrYear = someIsSameMonth(this._valueDT, this._disabledMonthsDT) || someIsSameYear(this._valueDT, this._disabledYearsDT);
            return !isDisabledMonthOrYear;
        };
        // Validators
        this._invalidDateValidator = () => (this.value ? DateTime.fromJSDate(this.value).isValid : true);
        this._rangeOverflowValidator = () => this._valueDT && this._maxDateDT ? this._valueDT.startOf('month') <= this._maxDateDT.startOf('month') : true;
        this._rangeUnderflowValidator = () => this._valueDT && this._minDateDT ? this._valueDT.startOf('month') >= this._minDateDT.startOf('month') : true;
        this._requiredDateValidator = () => !this.required || isDefined(this.value);
        this._offset = () => [0, 8];
        this._monthPickerPositionReference = () => this._monthPickerInput;
        // TODO: this should be removed when a reusable solution has been implemented
        // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
        this._handleOutsideClick = (event) => {
            if (!this.showCalendar) {
                return;
            }
            const isInsideClick = this.isSameNode(event.target) &&
                event
                    .composedPath()
                    .filter((target) => (target === null || target === void 0 ? void 0 : target.tagName) !== undefined)
                    .some((element) => element.tagName.toLowerCase() === 'zui-month-picker-input');
            const isDatePickerPortal = event.target.getAttribute('name') === this._datePickerMonthPortal;
            if (!isInsideClick && !isDatePickerPortal) {
                this.showCalendar = false;
            }
        };
        this._messageAnchorReferenceCallback = () => this._monthPickerInput;
        this.addValidator({
            type: 'badInput',
            validator: this._invalidDateValidator,
        });
        this.addValidator({
            type: 'customError',
            validator: this._disabledDateValidator,
            validatesOnProperties: ['disabledMonths', 'disabledYears'],
        });
        this.addValidator({
            type: 'rangeOverflow',
            validator: this._rangeOverflowValidator,
            validatesOnProperties: ['max'],
        });
        this.addValidator({
            type: 'rangeUnderflow',
            validator: this._rangeUnderflowValidator,
            validatesOnProperties: ['min'],
        });
        this.addValidator({
            type: 'valueMissing',
            validator: this._requiredDateValidator,
            validatesOnProperties: ['required'],
        });
        this.setDefaultValidityMessages({
            badInput: TextfieldMonthPicker_1.INVALID_ERROR_MESSAGE,
            customError: TextfieldMonthPicker_1.DISABLED_ERROR_MESSAGE,
            rangeOverflow: TextfieldMonthPicker_1.DISABLED_ERROR_MESSAGE,
            rangeUnderflow: TextfieldMonthPicker_1.DISABLED_ERROR_MESSAGE,
            valueMissing: TextfieldMonthPicker_1.REQUIRED_ERROR_MESSAGE,
        });
    }
    get _disabledMonthsDT() {
        return getDateTimesFromJsDates(this.disabledMonths);
    }
    get _disabledYearsDT() {
        return getDateTimesFromJsDates(this.disabledYears);
    }
    get _maxDateDT() {
        return this.max ? DateTime.fromJSDate(this.max) : undefined;
    }
    get _minDateDT() {
        return this.min ? DateTime.fromJSDate(this.min) : undefined;
    }
    get _valueDT() {
        return this.value && DateTime.fromJSDate(this.value).isValid ? DateTime.fromJSDate(this.value) : undefined;
    }
    /**
     * current date, i.e. pre-selected value range for zui-date-picker as an ISO 8601 date string.
     * if current date is not set or invalid, the components current value is used or new Date() as a fallback.
     *
     * @returns Date
     */
    get currentDate() {
        var _a, _b;
        // the current date defaults to either its internal value, if it is not set, the selected date and last but not least today
        return ((_b = (_a = this._currentDate) !== null && _a !== void 0 ? _a : 
        // only return value if it is set and not an InvalidDate; then fall through...
        (this.value && !(this.value instanceof InvalidDate) ? this.value : null)) !== null && _b !== void 0 ? _b : new Date());
    }
    /**
     * Sets the default pre-selected date for the zui-date-picker. Can also be null, if so other fallback values are used.
     * @param date - sets the current date for the zui-date-picker
     */
    set currentDate(date) {
        const oldValue = this._currentDate;
        this._currentDate = date;
        this.requestUpdate('currentDate', oldValue);
    }
    connectedCallback() {
        super.connectedCallback();
        // todo: this should be removed when a reusable solution has been implemented
        // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
        window.addEventListener('click', this._handleOutsideClick);
    }
    disconnectedCallback() {
        this._textfieldMonthPickerResizeObserver.disconnect();
        // todo: this should be removed when a reusable solution has been implemented
        // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
        window.removeEventListener('click', this._handleOutsideClick);
        super.disconnectedCallback();
    }
    /**
     * @private
     */
    formResetCallback() {
        super.formResetCallback();
        this._internalDateTimeValue = {
            month: null,
            year: null,
        };
    }
    get _inputPartMonthValue() {
        var _a, _b;
        return (_b = (_a = this._valueDT) === null || _a === void 0 ? void 0 : _a.month) !== null && _b !== void 0 ? _b : this._internalDateTimeValue.month;
    }
    get _inputPartYearValue() {
        var _a, _b;
        return (_b = (_a = this._valueDT) === null || _a === void 0 ? void 0 : _a.year) !== null && _b !== void 0 ? _b : this._internalDateTimeValue.year;
    }
    _handleDatePickerInputCalendarSelected() {
        this.showCalendar = !this.showCalendar;
    }
    _handleDatePickerInputChanged({ detail: { value, error }, }) {
        this.value = value;
        this._userInteraction = true;
        if (error) {
            this._internalDateTimeValue = Object.assign({}, error);
        }
    }
    _handleDatePickerInputFocused() {
        this.showCalendar = false;
    }
    _handleDatePickerDateSelected({ detail: { value } }) {
        this.value = value;
        this.currentDate = value;
        this.showCalendar = !this.closeOnDateSelected;
        this._userInteraction = true;
    }
    _handleDatePickerKeyDown({ code }) {
        if (code === 'Escape') {
            this.showCalendar = false;
        }
    }
    _delegateFocusToDatePicker() {
        const [datePickerRef] = getContentsFromPortal(this._datePickerMonthPortal, 'zui-date-picker');
        datePickerRef.focus();
    }
    firstRendered() {
        super.firstRendered();
        this._textfieldMonthPickerResizeObserver.observe(this);
        this._textfieldMonthPickerResizeObserver.observe(this._monthPickerInput);
    }
    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (_changedProperties.has('value') && this._userInteraction) {
            this.emitTextfieldMonthPickerDateSelectedEvent({ value: this.value });
            this.emitInputEvent();
            this.emitChangeEvent();
            this._userInteraction = false;
        }
    }
    render() {
        return html `
      <zui-month-picker-input
        ?calendar-opened=${this.showCalendar}
        ?disabled=${this.disabled}
        ?invalid=${this.showValidation}
        ?readonly=${this.readonly}
        .inputPartMonthValue=${this._inputPartMonthValue}
        .inputPartYearValue=${this._inputPartYearValue}
        locale=${this.locale}
        placeholder-month=${this.placeholderMonth}
        placeholder-year=${this.placeholderYear}
        selected-date=${ifDefined(this._valueDT)}
        @zui-month-picker-input-calendar-selected=${this._handleDatePickerInputCalendarSelected}
        @zui-month-picker-input-changed=${this._handleDatePickerInputChanged}
        @zui-month-picker-input-focused=${this._handleDatePickerInputFocused}
      >
      </zui-month-picker-input>
      ${!this.showCalendar
            ? html `<zui-validation-message-directive
            .messageAnchorReferenceCallback=${this._messageAnchorReferenceCallback}
            .triggerHostReference=${this}
            validation-message=${this.validationMessage}
          ></zui-validation-message-directive>`
            : nothing}
      ${this.showCalendar
            ? html `
            <zui-overlay-directive
              .placements=${TextfieldMonthPicker_1.POPOVER_PLACEMENTS}
              .positionReferenceCallback=${this._monthPickerPositionReference}
              .offsetHandler=${this._offset}
              flip
              id="textfield-month-picker-popover"
              portal=${this._datePickerMonthPortal}
              @zui-overlay-ready="${this._delegateFocusToDatePicker}"
            >
              <zui-popover style="width: auto; padding: 32px">
                <zui-date-picker
                  .currentDate=${this.currentDate}
                  .disabledMonths=${this.disabledMonths}
                  .disabledYears=${this.disabledYears}
                  .max=${this.max}
                  .min=${this.min}
                  disable-day-picker
                  locale=${this.locale}
                  selected-date=${ifDefined(this._valueDT)}
                  @zui-date-picker-date-selected=${this._handleDatePickerDateSelected}
                  @keydown=${this._handleDatePickerKeyDown}
                >
                </zui-date-picker>
              </zui-popover>
            </zui-overlay-directive>
          `
            : nothing}
    `;
    }
};
TextfieldMonthPicker.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    textfieldMonthPickerStyles,
];
/* eslint-disable @typescript-eslint/naming-convention */
TextfieldMonthPicker.DISABLED_ERROR_MESSAGE = 'This date is not allowed.';
TextfieldMonthPicker.INVALID_ERROR_MESSAGE = 'Please enter a valid date.';
TextfieldMonthPicker.POPOVER_PLACEMENTS = ['bottom-start', 'bottom-end', 'top-start', 'top-end'];
TextfieldMonthPicker.REQUIRED_ERROR_MESSAGE = 'A date is required.';
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'close-on-date-selected' })
], TextfieldMonthPicker.prototype, "closeOnDateSelected", void 0);
__decorate([
    property({ attribute: false })
], TextfieldMonthPicker.prototype, "disabledMonths", void 0);
__decorate([
    property({ attribute: false })
], TextfieldMonthPicker.prototype, "disabledYears", void 0);
__decorate([
    property({ reflect: true, type: String })
], TextfieldMonthPicker.prototype, "locale", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], TextfieldMonthPicker.prototype, "max", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], TextfieldMonthPicker.prototype, "min", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'placeholder-month' })
], TextfieldMonthPicker.prototype, "placeholderMonth", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'placeholder-year' })
], TextfieldMonthPicker.prototype, "placeholderYear", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], TextfieldMonthPicker.prototype, "required", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'show-calendar' })
], TextfieldMonthPicker.prototype, "showCalendar", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], TextfieldMonthPicker.prototype, "value", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'current-date', converter: isoDateConverter })
], TextfieldMonthPicker.prototype, "currentDate", null);
__decorate([
    query('#textfield-month-picker-popover')
], TextfieldMonthPicker.prototype, "_textfieldMonthPickerPopoverOverlayRef", void 0);
__decorate([
    query('#textfield-month-picker-warning-message')
], TextfieldMonthPicker.prototype, "_textfieldMonthPickerWarningMessageOverlayRef", void 0);
__decorate([
    state()
], TextfieldMonthPicker.prototype, "_internalDateTimeValue", void 0);
__decorate([
    query('zui-month-picker-input')
], TextfieldMonthPicker.prototype, "_monthPickerInput", void 0);
TextfieldMonthPicker = TextfieldMonthPicker_1 = __decorate([
    customElement('zui-textfield-month-picker')
    /* eslint-enable jsdoc/require-property-description */
], TextfieldMonthPicker);
export { TextfieldMonthPicker };
