const EVENT_PREFIX = 'zui';
export class TypedEvent extends CustomEvent {
}
export class NotificationEvent extends TypedEvent {
    constructor(typeParam, eventOptions) {
        super(typeParam, eventOptions);
    }
}
export class MimickedDOMEvent extends TypedEvent {
    constructor(typeParam, eventOptions) {
        super(typeParam, eventOptions);
    }
}
export class CommandEvent extends TypedEvent {
    constructor(typeParam, eventOptions) {
        super(typeParam, eventOptions);
    }
}
export class SharedEvent extends TypedEvent {
    constructor(typeParam, eventOptions) {
        super(typeParam, eventOptions);
    }
}
// the following are Class generating functions constraining certain events to eventInit defaults and allowing
// to use the returned classes directly in all methods expecting events -> this.dispatch(new
// GetNotificationEventClass('box-closed')) ...
// TODO: fix linting errors
const generateEventNames = (eventName) => `${EVENT_PREFIX}-${eventName}`;
// notification event will bubble and be not composed
// eslint-disable-next-line
export function GetNotificationEventClass(eventName, { composed = false, bubbles = true } = {}) {
    return class BuiltClass extends NotificationEvent {
        constructor(detail) {
            super(generateEventNames(eventName), { cancelable: false, composed, bubbles, detail });
            this.baseType = eventName;
        }
    };
}
/**
 * Creates a new MimickedDOMEvent class for the specified event name.
 * The event  will behave like a native dom event
 *
 * @template TPayload - A generic type extending Record<string, unknown>.
 * @template TType - A generic type extending string.
 * @param {TType} eventName - The name of the event.
 * @param {MimickedDOMEventOptions} options - An optional object with the event options.
 * @returns A new MimickedDOMEvent class with the specified event name and payload.
 */
// eslint-disable-next-line
export function GetMimickedDOMEventClass(eventName, { composed = false, bubbles = true, cancelable = false } = {}) {
    return class BuiltClass extends MimickedDOMEvent {
        constructor(detail) {
            super(eventName, { cancelable, composed, bubbles, detail });
            this.baseType = eventName;
        }
    };
}
/**
 * Creates a new CommandEvent class for the specified event name.
 * This event will not bubble nor be composed
 *
 * @template TPayload - A generic type extending Record<string, unknown>.
 * @template TType - A generic type extending EnforceKebabCase.
 * @param {TType} eventName - The name of the event in kebab-case format.
 * @returns - A new CommandEvent class with the specified event name and payload.
 */
// eslint-disable-next-line
export function GetCommandEventClass(eventName) {
    return class BuiltClass extends CommandEvent {
        constructor(detail) {
            super(generateEventNames(eventName), { cancelable: false, composed: false, bubbles: false, detail });
            this.baseType = eventName;
        }
    };
}
/**
 *
 * Creates a new class that extends SharedEvent<TType, TPayload> with a specified event name.
 * The shared event will bubble as configured (default is false) and not be composed.
 *
 * @template TPayload - The type of the payload associated with the event.
 * @template TType - The type of the event name.
 * @param {TType} eventName - The name of the event.
 * @param {Object} [options={}] - An object that contains options to customize the behavior of the event.
 * @param {boolean} [options.composed=false] - Determines whether the event can bubble across the boundary between the shadow DOM and the regular DOM.
 * @param {boolean} [options.bubbles=false] - Determines whether the event bubbles up through the DOM or not.
 *
 * @returns A new class that extends SharedEvent<TType, TPayload>.
 */
// eslint-disable-next-line
export function GetSharedEventClass(eventName, { composed = false, bubbles = false } = {}) {
    return class BuiltClass extends SharedEvent {
        constructor(detail) {
            super(generateEventNames(eventName), { cancelable: false, composed, bubbles, detail });
            this.baseType = eventName;
        }
    };
}
// specialized generic events
// default mimicked DOM events
// input-related events
export class MimickedChangeEvent extends GetMimickedDOMEventClass('change', {
    bubbles: true,
    cancelable: false,
    composed: false,
}) {
}
export class MimickedInputEvent extends GetMimickedDOMEventClass('input', {
    bubbles: true,
    cancelable: false,
    composed: true,
}) {
}
// focus-related stuff
export class MimickedBlurEvent extends GetMimickedDOMEventClass('blur', {
    bubbles: false,
    cancelable: false,
    composed: true,
}) {
}
export class MimickedFocusoutEvent extends GetMimickedDOMEventClass('focusout', {
    bubbles: true,
    cancelable: false,
    composed: true,
}) {
}
// other
export class MimickedScrollEvent extends GetMimickedDOMEventClass('scroll', {
    bubbles: true,
    cancelable: true,
    composed: false,
}) {
}
