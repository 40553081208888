var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAll } from 'lit/decorators.js';
import { hostStyles } from '../../../host.styles.js';
import { BaseElement } from '../../base/base-element.class.js';
import { DateTime } from 'luxon';
import { getCalendarYearsForSelectedDecade, getDateTimesFromJsDates, getDefaultLocale, isInDecade, isoDateConverter, isSameYear, someIsSameYear, } from '../utils/date-picker.utils.js';
// dependencies
import '../../picker/picker-grid-cell/picker-grid-cell.component.js';
import '../../picker/picker-header-cell/picker-header-cell.component.js';
import '../../picker/picker-grid/picker-grid.component.js';
import '../../picker/picker-header/picker-header.component.js';
import { GetSharedEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import '../../interactive-icon/interactive-icon.component.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const styles = ".picker-grid{margin-top:calc(var(--zui-gu) * 3)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
// icons
import '@zeiss/zui-icons/dist/components/generated/arrows-arrow-outline-actually-centred-right.component.js';
import '@zeiss/zui-icons/dist/components/generated/arrows-arrow-outline-actually-centred-left.component.js';
const datePickerYearPickerStyles = css `
  ${unsafeCSS(styles)}
`;
// TODO: they do not follow naming convention
class YearPickerDecadeSelectedEvent extends GetSharedEventClass('year-picker-decade-selected') {
}
class YearPickerNextDecadeSelectedEvent extends GetSharedEventClass('year-picker-next-decade-selected') {
}
class YearPickerPreviousDecadeSelectedEvent extends GetSharedEventClass('year-picker-previous-decade-selected') {
}
class YearPickerYearSelectedEvent extends GetSharedEventClass('year-picker-year-selected') {
}
/**
 * The date picker year picker is a feature component that should be used inside the date picker component for selecting years.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-date-picker-year-picker
 *   locale="en-US"
 *   max="2040-12-31T23:59:59.999+01:00"
 *   min="2000-01-01T00:00:00.000+01:00">
 * </zui-date-picker-year-picker>
 * ```
 */
let DatePickerYearPicker = class DatePickerYearPicker extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.DecadeSelectedEvent = YearPickerDecadeSelectedEvent;
        this.NextDecadeSelectedEvent = YearPickerNextDecadeSelectedEvent;
        this.PreviousDecadeSelectedEvent = YearPickerPreviousDecadeSelectedEvent;
        this.YearSelectedEvent = YearPickerYearSelectedEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * whether the decade select is disabled or not
         */
        this.decadeSelectDisabled = false;
        /**
         * disabled years
         */
        this.disabledYears = [];
        /**
         * locale
         */
        this.locale = getDefaultLocale();
        /**
         * max date
         */
        this.max = null;
        /**
         * min date
         */
        this.min = null;
        /**
         * selected year
         */
        this.selectedYear = null;
    }
    get _maxDateDT() {
        return this.max ? DateTime.fromJSDate(this.max) : undefined;
    }
    get _minDateDT() {
        return this.min ? DateTime.fromJSDate(this.min) : undefined;
    }
    get _selectedYearDT() {
        return this.selectedYear ? DateTime.fromJSDate(this.selectedYear) : undefined;
    }
    /**
     * current decade
     *
     * @returns currentDecade
     */
    get currentDecade() {
        var _a;
        // internal beats today
        const decadeBaseDT = DateTime.fromJSDate((_a = this._internalCurrentDecade) !== null && _a !== void 0 ? _a : new Date());
        return decadeBaseDT
            .minus({ years: decadeBaseDT.year % 10 })
            .startOf('year')
            .toJSDate();
    }
    set currentDecade(decade) {
        const oldValue = this._internalCurrentDecade;
        this._internalCurrentDecade = decade;
        this.requestUpdate('currentDecade', oldValue);
    }
    get _currentDecadeDT() {
        return DateTime.fromJSDate(this.currentDecade);
    }
    get _gridCellYears() {
        return getCalendarYearsForSelectedDecade(this._currentDecadeDT);
    }
    _headerValue() {
        const years = this._gridCellYears;
        return `${years[0].year} - ${years[9].year}`;
    }
    get _disabledGridCellConditions() {
        const disabledYears = getDateTimesFromJsDates(this.disabledYears);
        return [
            (date) => (this._minDateDT ? date.startOf('year') < this._minDateDT.startOf('year') : false),
            (date) => (this._maxDateDT ? date.startOf('year') > this._maxDateDT.startOf('year') : false),
            (date) => someIsSameYear(date, disabledYears),
        ];
    }
    get _gridCellFocusConditions() {
        return [() => (this._selectedYearDT ? isInDecade(this._selectedYearDT, this._currentDecadeDT) : false)];
    }
    get _gridCellSelectedConditions() {
        return [(date) => (this._selectedYearDT ? isSameYear(date, this._selectedYearDT) : false)];
    }
    get _gridCellSubtleConditions() {
        return [(date) => !isInDecade(date, this._currentDecadeDT)];
    }
    _isGridCellDisabled(date) {
        return this._disabledGridCellConditions.some((predicate) => predicate(date));
    }
    _canFocusGridCell() {
        return this._gridCellFocusConditions.every((predicate) => predicate());
    }
    _getGridCellEmphasis(date) {
        const isSelected = this._gridCellSelectedConditions.every((predicate) => predicate(date));
        const isSubtle = this._gridCellSubtleConditions.every((predicate) => predicate(date));
        return isSelected ? 'selected' : isSubtle ? 'subtle' : 'default';
    }
    _handleYearSelected({ detail }) {
        const { selected, value } = detail;
        if (selected.disabled) {
            return;
        }
        this.selectedYear = DateTime.fromISO(value).startOf('year').toJSDate();
        this._emitYearPickerYearSelectedEvent({ value: this.selectedYear });
    }
    _handleDecadeSelected() {
        this._emitYearPickerDecadeSelectedEvent({ value: this.currentDecade });
    }
    _handleNextDecadeSelected() {
        this.currentDecade = this._currentDecadeDT.plus({ years: 10 }).toJSDate();
        this._emitYearPickerNextDecadeSelectedEvent({
            startOfDecade: this.currentDecade,
            endOfDecade: this._currentDecadeDT.plus({ years: 9 }).toJSDate(),
        });
    }
    _handlePreviousDecadeSelected() {
        this.currentDecade = this._currentDecadeDT.minus({ years: 10 }).toJSDate();
        this._emitYearPickerPreviousDecadeSelectedEvent({
            startOfDecade: this.currentDecade,
            endOfDecade: this._currentDecadeDT.plus({ years: 9 }).toJSDate(),
        });
    }
    _updateFocus() {
        if (!this._canFocusGridCell()) {
            return;
        }
        // TODO: this needs to be reworked into sth. more readable...
        const pickerGridCell = Array.from(this._pickerGridCells).find((pickerGridCell) => this._selectedYearDT ? isSameYear(DateTime.fromISO(pickerGridCell.value), this._selectedYearDT) : false);
        pickerGridCell === null || pickerGridCell === void 0 ? void 0 : pickerGridCell.focus();
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('selectedYear')) {
            this._updateFocus();
        }
    }
    render() {
        return html `
      <zui-picker-header
        ?disabled="${this.decadeSelectDisabled}"
        value="${this._headerValue()}"
        @zui-picker-header-current-selected="${this._handleDecadeSelected}"
        @zui-picker-header-next-selected="${this._handleNextDecadeSelected}"
        @zui-picker-header-previous-selected="${this._handlePreviousDecadeSelected}"
      >
        <zui-interactive-icon slot="icon-left">
          <zui-icon-arrows-arrow-outline-actually-centred-left></zui-icon-arrows-arrow-outline-actually-centred-left>
        </zui-interactive-icon>
        <zui-interactive-icon slot="icon-right">
          <zui-icon-arrows-arrow-outline-actually-centred-right></zui-icon-arrows-arrow-outline-actually-centred-right>
        </zui-interactive-icon>
      </zui-picker-header>

      <zui-picker-grid class="picker-grid" columns="4" @zui-picker-grid-cell-selected="${this._handleYearSelected}">
        ${this._gridCellYears.map((dateTime) => {
            const disabled = this._isGridCellDisabled(dateTime);
            const emphasis = this._getGridCellEmphasis(dateTime);
            return html `
            <zui-picker-grid-cell
              ?disabled="${disabled}"
              emphasis="${emphasis}"
              slot="pickerGridCells"
              style="--zui-picker-grid-cell-width: 56px; --zui-picker-grid-cell-height: 56px"
              value="${dateTime}"
            >
              ${dateTime.year}
            </zui-picker-grid-cell>
          `;
        })}
      </zui-picker-grid>
    `;
    }
};
DatePickerYearPicker.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    datePickerYearPickerStyles,
];
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'decade-select-disabled' })
], DatePickerYearPicker.prototype, "decadeSelectDisabled", void 0);
__decorate([
    property({ attribute: false })
], DatePickerYearPicker.prototype, "disabledYears", void 0);
__decorate([
    property({ reflect: true, type: String })
], DatePickerYearPicker.prototype, "locale", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], DatePickerYearPicker.prototype, "max", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], DatePickerYearPicker.prototype, "min", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'selected-year', converter: isoDateConverter })
], DatePickerYearPicker.prototype, "selectedYear", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'current-decade', converter: isoDateConverter })
], DatePickerYearPicker.prototype, "currentDecade", null);
__decorate([
    queryAll('zui-picker-grid-cell')
], DatePickerYearPicker.prototype, "_pickerGridCells", void 0);
DatePickerYearPicker = __decorate([
    customElement('zui-date-picker-year-picker')
], DatePickerYearPicker);
export { DatePickerYearPicker };
