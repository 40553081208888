import type { ExtensionRegistration } from 'piral-core';
import type { ReactElement, ReactNode } from 'react';

function getWeightOf<T>(ext: T, gw: (val: T) => number | undefined) {
  const value = gw(ext);

  if (typeof value === 'number') {
    return value;
  }

  // Move all tiles without a weight to the back.
  return Number.NEGATIVE_INFINITY;
}

function weightOfExtensionRegistration(ext: ExtensionRegistration) {
  return ext.defaults?.weight;
}

function weightOfReactElement(element: ReactElement) {
  return element.props.meta?.weight;
}

function weightOfItem<T extends { weight?: number }>(element: T) {
  return element.weight;
}

function elementWeightOrderer(a: ReactElement, b: ReactElement) {
  return getWeightOf(b, weightOfReactElement) - getWeightOf(a, weightOfReactElement);
}

function extensionWeightOrderer(a: ExtensionRegistration, b: ExtensionRegistration) {
  return (
    getWeightOf(b, weightOfExtensionRegistration) - getWeightOf(a, weightOfExtensionRegistration)
  );
}

function itemWeightOrderer<T extends { weight?: number }>(a: T, b: T) {
  return getWeightOf(b, weightOfItem) - getWeightOf(a, weightOfItem);
}

export function orderComponents(
  extensions: Array<ExtensionRegistration>
): Array<ExtensionRegistration> {
  return [...extensions].sort(extensionWeightOrderer);
}

export function orderItems<T extends { weight?: number }>(items: Array<T>): Array<T> {
  return [...items].sort(itemWeightOrderer);
}

export function orderChildren(components: ReactNode): ReactNode {
  if (!Array.isArray(components)) {
    return components;
  }

  return [...components].filter((c): c is ReactElement => Boolean(c)).sort(elementWeightOrderer);
}
