var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import { hostStyles } from '../../../host.styles.js';
import { BaseElement } from '../../base/base-element.class.js';
import '../../../types.js';
import '../menu-tab-icon-item/menu-tab-icon-item.component.js';
import '../menu-tab-text-item/menu-tab-text-item.component.js';
const style = ":host{display:flex;flex:1 1 0;justify-content:center;max-width:100%}"
import { GetNotificationEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const menuTabBarStyles = css `
  ${unsafeCSS(style)}
`;
class MenuTabBarSelectionChangedEvent extends GetNotificationEventClass('menu-tab-bar-selection-changed') {
}
/**
 * Menu tab bar.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---3.2?node-id=36634%3A0)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=52930%3A120)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-menu-tab-bar size="m" value="#1">
 *   <zui-menu-tab-text-item text="Tab text item 1" value="#1"></zui-menu-tab-text-item>
 *   <zui-menu-tab-text-item text="Tab text item 2" value="#2"></zui-menu-tab-text-item>
 *   <zui-menu-tab-text-item text="Tab text item 3" value="#3"></zui-menu-tab-text-item>
 * </zui-menu-tab-bar>
 * ```
 *
 * ```html
 * <zui-menu-tab-bar value="#2">
 *   <zui-menu-tab-icon-item value="#1"><zui-icon-med-laser-ready></zui-icon-med-laser-ready></zui-menu-tab-icon-item>
 *   <zui-menu-tab-icon-item value="#2"><zui-icon-med-laser-ready></zui-icon-med-laser-ready></zui-menu-tab-icon-item>
 *   <zui-menu-tab-icon-item value="#3"><zui-icon-med-laser-ready></zui-icon-med-laser-ready></zui-menu-tab-icon-item>
 * </zui-menu-tab-bar>
 * ```
 *
 * @slot - default slot for `zui-menu-tab-text-item`'s or `zui-menu-tab-icon-item`'s
 *
 * @fires {GetZuiEvent<MenuTabBar, 'SelectionChangedEvent'>} zui-menu-tab-bar-selection-changed - emits when an item is selected
 */
let MenuTabBar = class MenuTabBar extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.SelectionChangedEvent = MenuTabBarSelectionChangedEvent;
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * set size of `zui-menu-tab-text-item`'s; `zui-menu-tab-icon-item`'s always default to size 'm'
         */
        this.size = 'm';
    }
    _propagateMenuTabBarSize() {
        this._assignedMenuTabTextItems.forEach((menuTabTextItem) => (menuTabTextItem.size = this.size));
    }
    _propagateMenuTabBarValue() {
        if (!this.value) {
            return;
        }
        this._assignedMenuTabIconItems.forEach((menuTabIconItem) => {
            menuTabIconItem.emphasis = menuTabIconItem.value === this.value ? 'selected' : 'default';
        });
        this._assignedMenuTabTextItems.forEach((menuTabTextItem) => {
            menuTabTextItem.emphasis = menuTabTextItem.value === this.value ? 'selected' : 'default';
        });
    }
    _handleMenuTabItemSelected({ target }) {
        this.value = target.value;
        this.emitMenuTabBarSelectionChangedEvent({ value: target.value });
    }
    _handleMenuTabItemSlotchange() {
        this._propagateMenuTabBarSize();
        this._propagateMenuTabBarValue();
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('size')) {
            this._propagateMenuTabBarSize();
        }
        if (changedProperties.has('value')) {
            this._propagateMenuTabBarValue();
        }
    }
    render() {
        return html `
      <slot @click="${this._handleMenuTabItemSelected}" @slotchange="${this._handleMenuTabItemSlotchange}"></slot>
    `;
    }
};
MenuTabBar.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    menuTabBarStyles,
];
__decorate([
    property({ reflect: true, type: String })
], MenuTabBar.prototype, "size", void 0);
__decorate([
    property({ reflect: true, type: String })
], MenuTabBar.prototype, "value", void 0);
__decorate([
    queryAssignedElements({ selector: 'zui-menu-tab-icon-item', flatten: true })
], MenuTabBar.prototype, "_assignedMenuTabIconItems", void 0);
__decorate([
    queryAssignedElements({ selector: 'zui-menu-tab-text-item', flatten: true })
], MenuTabBar.prototype, "_assignedMenuTabTextItems", void 0);
MenuTabBar = __decorate([
    customElement('zui-menu-tab-bar')
], MenuTabBar);
export { MenuTabBar };
