import { changeConsentLanguage } from '../consent';
import { getLocalStorageItem, setLocalStorageItem, StorageKey } from '../utils/storage';

export function getUserDefinedLanguage(): string | null {
  return getLocalStorageItem(StorageKey.BROWSER_LANGUAGE);
}

export function setUserDefinedLanguage(language: string): void {
  setLocalStorageItem(StorageKey.BROWSER_LANGUAGE, language);

  const languageChangeFromSettingsEvent = new CustomEvent('languageChangeFromSettings', {
    detail: language,
  });

  window.dispatchEvent(languageChangeFromSettingsEvent);
  changeConsentLanguage(language);
}
