import { traverseDOMSiblingsByStepAndDirection } from '../../../utils/dom.utils.js';
/**
 * gets a previous gridCell by a certain step length
 * @param currentGridCell
 * @param step
 * @returns the previous GridCell matching criteria
 */
export function getPreviousPickerGridCell(currentGridCell, step = 1) {
    return traverseDOMSiblingsByStepAndDirection(currentGridCell, 'previous', step, (element) => element.tagName === currentGridCell.tagName && !element.hasAttribute('disabled'));
}
/**
 * gets a previous gridCell by a certain step length
 * @param currentGridCell
 * @param step
 * @returns the next GridCell matching criteria
 */
export function getNextPickerGridCell(currentGridCell, step = 1) {
    return traverseDOMSiblingsByStepAndDirection(currentGridCell, 'next', step, (element) => element.tagName === currentGridCell.tagName && !element.hasAttribute('disabled'));
}
