import { css, unsafeCSS } from 'lit';
export const segoeUIFontFaces = css `
  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Light');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Semilight');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Semibold');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Bold');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Light Italic');
    font-weight: 200;
    font-style: italic;
  }

  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Semilight Italic');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Italic');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Semibold Italic');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Bold Italic');
    font-weight: 700;
    font-style: italic;
  }
`;
export const fontFaces = (fontDirectoryURL) => {
    const fontDirectory = unsafeCSS(fontDirectoryURL);
    return css `
    @font-face {
      font-family: 'Noto Sans';
      src: local('Noto Sans Light'), local('NotoSans-Light'),
        url('${fontDirectory}/NotoSans-Light.woff2') format('woff2'),
        url('${fontDirectory}/NotoSans-Light.woff') format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: local('Noto Sans Regular'), local('NotoSans-Regular'),
        url('${fontDirectory}/NotoSans-Regular.woff2') format('woff2'),
        url('${fontDirectory}/NotoSans-Regular.woff') format('woff');
      font-weight: 400;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: local('Noto Sans SemiBold'), local('NotoSans-SemiBold'),
        url('${fontDirectory}/NotoSans-SemiBold.woff2') format('woff2'),
        url('${fontDirectory}/NotoSans-SemiBold.woff') format('woff');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: local('Noto Sans Bold'), local('NotoSans-Bold'), url('${fontDirectory}/NotoSans-Bold.woff2') format('woff2'),
        url('${fontDirectory}/NotoSans-Bold.woff') format('woff');
      font-weight: 700;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: local('Noto Sans Light Italic'), local('NotoSans-LightItalic'),
        url('${fontDirectory}/NotoSans-LightItalic.woff2') format('woff2'),
        url('${fontDirectory}/NotoSans-LightItalic.woff') format('woff');
      font-weight: 300;
      font-style: italic;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: local('Noto Sans Italic'), local('NotoSans-Italic'),
        url('${fontDirectory}/NotoSans-Italic.woff2') format('woff2'),
        url('${fontDirectory}/NotoSans-Italic.woff') format('woff');
      font-weight: 400;
      font-style: italic;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: local('Noto Sans SemiBold Italic'), local('NotoSans-SemiBoldItalic'),
        url('${fontDirectory}/NotoSans-SemiBoldItalic.woff2') format('woff2'),
        url('${fontDirectory}/NotoSans-SemiBoldItalic.woff') format('woff');
      font-weight: 600;
      font-style: italic;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: local('Noto Sans Bold Italic'), local('NotoSans-BoldItalic'),
        url('${fontDirectory}/NotoSans-BoldItalic.woff2') format('woff2'),
        url('${fontDirectory}/NotoSans-BoldItalic.woff') format('woff');
      font-weight: 700;
      font-style: italic;
      font-display: swap;
    }
  `;
};
const fallbackFont = css `sans-serif`;
export const fontStyles = css `
  :root {
    --zui-typography-body: var(--zui-body-font-style) var(--zui-body-font-weight) var(--zui-body-font-size) /
        var(--zui-body-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-body-text-transform: initial;

    --zui-typography-h0: var(--zui-h0-font-style) var(--zui-h0-font-weight) var(--zui-h0-font-size) /
        var(--zui-h0-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-h0-text-transform: initial;

    --zui-typography-h1: var(--zui-h1-font-style) var(--zui-h1-font-weight) var(--zui-h1-font-size) /
        var(--zui-h1-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-h1-text-transform: initial;

    --zui-typography-h2: var(--zui-h2-font-style) var(--zui-h2-font-weight) var(--zui-h2-font-size) /
        var(--zui-h2-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-h2-text-transform: initial;

    --zui-typography-h3: var(--zui-h3-font-style) var(--zui-h3-font-weight) var(--zui-h3-font-size) /
        var(--zui-h3-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-h3-text-transform: initial;

    --zui-typography-h4: var(--zui-h4-font-style) var(--zui-h4-font-weight) var(--zui-h4-font-size) /
        var(--zui-h4-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-h4-text-transform: initial;

    --zui-typography-subtitle1: var(--zui-subtitle1-font-style) var(--zui-subtitle1-font-weight)
        var(--zui-subtitle1-font-size) / var(--zui-subtitle1-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-subtitle1-text-transform: initial;

    --zui-typography-subtitle2: var(--zui-subtitle2-font-style) var(--zui-subtitle2-font-weight)
        var(--zui-subtitle2-font-size) / var(--zui-subtitle2-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-subtitle2-text-transform: initial;

    --zui-typography-caption: var(--zui-caption-font-style) var(--zui-caption-font-weight) var(--zui-caption-font-size) /
        var(--zui-caption-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-caption-text-transform: initial;

    --zui-typography-label1: var(--zui-label1-font-style) var(--zui-label1-font-weight) var(--zui-label1-font-size) /
        var(--zui-label1-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-label1-text-transform: initial;

    --zui-typography-label2: var(--zui-label2-font-style) var(--zui-label2-font-weight) var(--zui-label2-font-size) /
        var(--zui-label2-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-label2-text-transform: uppercase;
  }
`;
export const segoeSettings = css `
  :root {
    --zui-font-family: 'Segoe UI';

    --zui-h0-font-style: normal;
    --zui-h0-font-weight: 200;
    --zui-h0-font-size: 48px;
    --zui-h0-line-height: 1.5;

    --zui-h0-cap-margin-top: -0.3359em;
    --zui-h0-cap-margin-bottom: -0.4639em;

    --zui-h1-font-style: normal;
    --zui-h1-font-weight: 200;
    --zui-h1-font-size: 32px;
    --zui-h1-line-height: 1.5;

    --zui-h1-cap-margin-top: -0.3359em;
    --zui-h1-cap-margin-bottom: 0.4639em;

    --zui-h2-font-style: normal;
    --zui-h2-font-weight: 300;
    --zui-h2-font-size: 24px;
    --zui-h2-line-height: 1.5;

    --zui-h2-cap-margin-top: -0.3359em;
    --zui-h2-cap-margin-bottom: -0.4639em;

    --zui-h3-font-style: normal;
    --zui-h3-font-weight: 300;
    --zui-h3-font-size: 20px;
    --zui-h3-line-height: 1.4;

    --zui-h3-cap-margin-top: -0.2859em;
    --zui-h3-cap-margin-bottom: -0.4139em;

    --zui-h4-font-style: normal;
    --zui-h4-font-weight: 400;
    --zui-h4-font-size: 16px;
    --zui-h4-line-height: 1.5;

    --zui-h4-cap-margin-top: -0.3359em;
    --zui-h4-cap-margin-bottom: -0.4639em;

    --zui-subtitle1-font-style: normal;
    --zui-subtitle1-font-weight: 600;
    --zui-subtitle1-font-size: 14px;
    --zui-subtitle1-line-height: 1.42857143;

    --zui-subtitle1-cap-margin-top: -0.3002em;
    --zui-subtitle1-cap-margin-bottom: -0.4282em;

    --zui-subtitle2-font-style: normal;
    --zui-subtitle2-font-weight: 700;
    --zui-subtitle2-font-size: 12px;
    --zui-subtitle2-line-height: 1.33333333;

    --zui-subtitle2-cap-margin-top: -0.2526em;
    --zui-subtitle2-cap-margin-bottom: -0.3805em;

    --zui-body-font-style: normal;
    --zui-body-font-weight: 400;
    --zui-body-font-size: 14px;
    --zui-body-line-height: 1.42857143;

    --zui-body-cap-margin-top: -0.3002em;
    --zui-body-cap-margin-bottom: -0.4282em;

    --zui-caption-font-style: normal;
    --zui-caption-font-weight: 400;
    --zui-caption-font-size: 12px;
    --zui-caption-line-height: 1.33333333;

    --zui-caption-cap-margin-top: -0.2526em;
    --zui-caption-cap-margin-bottom: -0.3805em;

    --zui-label1-font-style: normal;
    --zui-label1-font-weight: 600;
    --zui-label1-font-size: 12px;
    --zui-label1-line-height: 1.33333333;

    --zui-label1-cap-margin-top: -0.2526em;
    --zui-label1-cap-margin-bottom: -0.3805em;

    --zui-label2-font-style: normal;
    --zui-label2-font-weight: 700;
    --zui-label2-font-size: 10px;
    --zui-label2-line-height: 1.2;

    --zui-label2-cap-margin-top: -0.1859em;
    --zui-label2-cap-margin-bottom: -0.3139em;
  }
`;
export const notoLatinSettings = css `
  :root {
    --zui-font-family: 'Noto Sans';

    --zui-h0-font-style: normal;
    --zui-h0-font-weight: 300;
    --zui-h0-font-size: 46px;
    --zui-h0-line-height: 1.56521739;

    --zui-h0-cap-margin-top: -0.3946em;
    --zui-h0-cap-margin-bottom: -0.4566em;

    --zui-h1-font-style: normal;
    --zui-h1-font-weight: 300;
    --zui-h1-font-size: 31px;
    --zui-h1-line-height: 1.5483871;

    --zui-h1-cap-margin-top: -0.3862em;
    --zui-h1-cap-margin-bottom: -0.4482em;

    --zui-h2-font-style: normal;
    --zui-h2-font-weight: 300;
    --zui-h2-font-size: 22px;
    --zui-h2-line-height: 1.63636364;

    --zui-h2-cap-margin-top: -0.4302em;
    --zui-h2-cap-margin-bottom: -0.4922em;

    --zui-h3-font-style: normal;
    --zui-h3-font-weight: 300;
    --zui-h3-font-size: 19px;
    --zui-h3-line-height: 1.47368421;

    --zui-h3-cap-margin-top: -0.3488em;
    --zui-h3-cap-margin-bottom: -0.4108em;

    --zui-h4-font-style: normal;
    --zui-h4-font-weight: 400;
    --zui-h4-font-size: 15px;
    --zui-h4-line-height: 1.6;

    --zui-h4-cap-margin-top: -0.412em;
    --zui-h4-cap-margin-bottom: -0.474em;

    --zui-subtitle1-font-style: normal;
    --zui-subtitle1-font-weight: 600;
    --zui-subtitle1-font-size: 13px;
    --zui-subtitle1-line-height: 1.53846154;

    --zui-subtitle1-cap-margin-top: -0.3812em;
    --zui-subtitle1-cap-margin-bottom: -0.4432em;

    --zui-subtitle2-font-style: normal;
    --zui-subtitle2-font-weight: 700;
    --zui-subtitle2-font-size: 11px;
    --zui-subtitle2-line-height: 1.45454545;

    --zui-subtitle2-cap-margin-top: -0.3393em;
    --zui-subtitle2-cap-margin-bottom: -0.4013em;

    --zui-body-font-style: normal;
    --zui-body-font-weight: 400;
    --zui-body-font-size: 13px;
    --zui-body-line-height: 1.53846154;

    --zui-body-cap-margin-top: -0.3812em;
    --zui-body-cap-margin-bottom: -0.4432em;

    --zui-caption-font-style: normal;
    --zui-caption-font-weight: 400;
    --zui-caption-font-size: 11px;
    --zui-caption-line-height: 1.45454545;

    --zui-caption-cap-margin-top: -0.3393em;
    --zui-caption-cap-margin-bottom: -0.4013em;

    --zui-label1-font-style: normal;
    --zui-label1-font-weight: 600;
    --zui-label1-font-size: 11px;
    --zui-label1-line-height: 1.45454545;

    --zui-label1-cap-margin-top: -0.3393em;
    --zui-label1-cap-margin-bottom: -0.4013em;

    --zui-label2-font-style: normal;
    --zui-label2-font-weight: 700;
    --zui-label2-font-size: 10px;
    --zui-label2-line-height: 1.2;

    --zui-label2-cap-margin-top: -0.212em;
    --zui-label2-cap-margin-bottom: -0.274em;
  }
`;
export const notoCJKSettings = css `
  :root {
    /* --zui-font-family would be set at typefaceManager */

    --zui-h0-font-style: normal;
    --zui-h0-font-weight: 300;
    --zui-h0-font-size: 46px;
    --zui-h0-line-height: 1.17391304;

    --zui-h1-font-style: normal;
    --zui-h1-font-weight: 300;
    --zui-h1-font-size: 36px;
    --zui-h1-line-height: 1.27777778;

    --zui-h2-font-style: normal;
    --zui-h2-font-weight: 300;
    --zui-h2-font-size: 28px;
    --zui-h2-line-height: 1.28571429;

    --zui-h3-font-style: normal;
    --zui-h3-font-weight: 300;
    --zui-h3-font-size: 21px;
    --zui-h3-line-height: 1.14285714;

    --zui-h4-font-style: normal;
    --zui-h4-font-weight: 300;
    --zui-h4-font-size: 18px;
    --zui-h4-line-height: 1.11111111;

    --zui-subtitle1-font-style: normal;
    --zui-subtitle1-font-weight: 700;
    --zui-subtitle1-font-size: 16px;
    --zui-subtitle1-line-height: 1.125;

    --zui-subtitle2-font-style: normal;
    --zui-subtitle2-font-weight: 700;
    --zui-subtitle2-font-size: 16px;
    --zui-subtitle2-line-height: 1;

    --zui-body-font-style: normal;
    --zui-body-font-weight: 400;
    --zui-body-font-size: 16px;
    --zui-body-line-height: 1;

    --zui-caption-font-style: normal;
    --zui-caption-font-weight: 400;
    --zui-caption-font-size: 14px;
    --zui-caption-line-height: 1;

    --zui-label1-font-style: normal;
    --zui-label1-font-weight: 700;
    --zui-label1-font-size: 15px;
    --zui-label1-line-height: 0.93333333;

    --zui-label2-font-style: normal;
    --zui-label2-font-weight: 700;
    --zui-label2-font-size: 15px;
    --zui-label2-line-height: 1.06666667;
  }
`;
export var SelectableFonts;
(function (SelectableFonts) {
    SelectableFonts["Auto"] = "Auto";
    SelectableFonts["Segoe"] = "Segoe UI";
    SelectableFonts["Noto"] = "Noto Sans";
})(SelectableFonts || (SelectableFonts = {}));
