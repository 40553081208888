var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var Portal_1;
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement } from '../base/base-element.class.js';
import { hostStyles } from '../../host.styles.js';
import { registerPortal, unregisterPortal } from '../../utils/portal.utils.js';
const style = ":host{---zui-portal-level: var(--zui-portal-level, 1000);position:fixed;top:0;right:0;bottom:0;left:0;z-index:var(---zui-portal-level);display:flex;flex-direction:column;align-items:center;justify-content:center;pointer-events:none}:host *{pointer-events:all}"
const theme = ""
import { GetNotificationEventClass } from '../../contracts/event.classes.js';
import '../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../mixins/events/events.mixin.js';
import { _findRenderRootOfElement } from './portal.utils.js';
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const PORTAL_STYLES = css `
  ${unsafeCSS(style)}
`;
class PortalReadyEvent extends GetNotificationEventClass('portal-ready') {
}
const INTERNAL_SLOT_ATTRIBUTE_NAME = 'zui-internal-portal-slot';
/**
 * A portal to be placed anywhere in the DOM to receive contents by `use-portal` entrances.
 *
 * @example basic
 * ```html
 * <zui-portal>
 *   <zui-popover>Help, I'm alive!</zui-popover>
 * </zui-portal>
 * ```
 *
 * @example add to Angular CDK dialog stacking context
 * @see https://material.angular.io/components/dialog/overview
 * ```html
 * <body style="--zui-portal-level: 1000">
 *   <zui-portal>
 *     <zui-popover>Hey CDK, wanna play?</zui-popover>
 *   </zui-portal>
 * </body>
 * ```
 *
 * @example add to Material UI modal stacking context
 * @see https://mui.com/components/modal/#nested-modal
 * ```html
 * <body style="--zui-portal-level: 1300">
 *   <zui-portal>
 *     <zui-popover>Hey CDK, wanna play?</zui-popover>
 *   </zui-portal>
 * </body>
 * ```
 *
 * @fires {GetZuiEvent<Portal, 'ReadyEvent'>} zui-portal-ready - An event telling the management utilities about the portal being created and inserted into the
 *   DOM.
 *
 * @slot - The default content to be shown if nothing is projected.
 * @cssprop --zui-portal-level - sets the z-index level, can be inherited
 */
let Portal = Portal_1 = class Portal extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.ReadyEvent = PortalReadyEvent;
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * Clones projected contents instead of moving them.
         * You can not clone nested slots. If this is what you want use the shouldProjectSlots parameter.
         *
         * @see {@link showContent}
         */
        this.clone = false;
        // elements that are directly moved to the portal.
        this._slottedElements = new Map();
        // nested children that need to be moved with the main content as well.
        this._deeplySlottedChildElements = new Map();
        // slots inside the slotted main content. e.g.: <slot> inside <zui-menu> which is inside <zui-menu-wrapper>.
        // Is needed to bring back the slot after exchanging it. Consists of root slot and the parent of that slot as HTMLElement .
        this._deeplySlottedSlotElements = new Map();
    }
    connectedCallback() {
        super.connectedCallback();
        // register the portal itself
        registerPortal(this.name, this);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        // Write slotted contents back to their previous parents. E.g: rehydrate slotted contents to zui-menu-wrapper.
        this._slottedElements.forEach((providerRef, assignedElements) => {
            providerRef.append(...assignedElements);
        });
        this._deeplySlottedChildElements.forEach((providerRef, assignedElements) => {
            assignedElements.forEach((element) => {
                const originalSlotValue = element.getAttribute(INTERNAL_SLOT_ATTRIBUTE_NAME);
                if (originalSlotValue !== '') {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    element.setAttribute('slot', originalSlotValue);
                }
                element.removeAttribute(INTERNAL_SLOT_ATTRIBUTE_NAME);
            });
            providerRef.append(...assignedElements);
        });
        this._deeplySlottedSlotElements.forEach((parentRef, slotBoundary) => {
            Array.from(parentRef.children).forEach((child) => child.remove());
            parentRef.append(slotBoundary);
        });
        // clean up first
        this._removeExistingContents();
        // remove the portal from global manager
        unregisterPortal(this.name);
    }
    /**
     * This function gets all the slots that are left on content to be projected.
     * It then saves the slotted contents and a reference to their parent to be able to rehydrate them later.
     * This function supports multiple first level slots and child slots of these.
     *
     * @param element The whole content to project (from the flattned default slot)
     * @param initialized defaults to true on the first iteration
     */
    _cacheAllNestedSlotContentsWithOriginalPosition(element, initialized = true) {
        // All further slots inside the portal directives default slot
        const allNestedSlots = element.querySelectorAll('slot');
        // Track the origin of all slotted contents and project them.
        // The slotted contents will return to their origin when the component is unmounted.
        const allSlottedContents = Array.from(allNestedSlots)
            .map((slot) => slot.assignedElements({ flatten: true }))
            .filter((elements) => elements.length !== 0);
        allSlottedContents.forEach((assignedElements) => {
            const providerRef = assignedElements === null || assignedElements === void 0 ? void 0 : assignedElements[0].parentElement;
            const allSameParents = assignedElements.every((node) => node.parentElement === providerRef);
            // Be sure that all contents have the same parent. This should always be the case, but make sure to avoid potential edge cases.
            if (!providerRef || !allSameParents)
                return;
            // Assign first-level slotted contents to the portal itself.
            if (initialized) {
                this._slottedElements.set(assignedElements, providerRef);
                assignedElements.forEach((element) => this._cacheAllNestedSlotContentsWithOriginalPosition(element, false));
            }
            // Handling children of the main content. Swap slotted contents with their respective slot.
            // Keep in mind to render the slot again when the portal is unmounted, otherwise it will be gone.
            // This is done because of the way queryAsyncAssignedElements works.
            else {
                this._deeplySlottedChildElements.set(assignedElements, providerRef);
                if (assignedElements[0].assignedSlot) {
                    const nestedRootRenderSlot = _findRenderRootOfElement(assignedElements[0].assignedSlot);
                    const nestedSlotParentElement = nestedRootRenderSlot.parentElement;
                    this._deeplySlottedSlotElements.set(nestedRootRenderSlot, nestedSlotParentElement);
                    assignedElements.forEach((element) => {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        element.setAttribute(INTERNAL_SLOT_ATTRIBUTE_NAME, element.assignedSlot.name);
                        element.removeAttribute('slot');
                    });
                    nestedRootRenderSlot.replaceWith(...assignedElements);
                }
            }
        });
    }
    /**
     * Adds content nodes to the shadow DOM
     *
     * @param content - the DOM nodes to be added
     * @param shouldProjectSlots true if slot should be cloned from the projected content to the portal. These slots will be returned to their respective root component when the component unmounts.
     */
    showContent(content, shouldProjectSlots = false) {
        var _a;
        // remove eventually visible fallback contents
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (_a = this.shadowRoot.getElementById('placeholder')) === null || _a === void 0 ? void 0 : _a.remove();
        // remove eventually existing stuff
        this._removeExistingContents();
        // clone given markup
        if (this.clone) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.shadowRoot.appendChild(content.cloneNode(true));
            return;
        }
        if (shouldProjectSlots) {
            this._cacheAllNestedSlotContentsWithOriginalPosition(content);
            this._slottedElements.forEach((_, assignedElements) => {
                // copy all slotted contents to the portal
                this.append(...assignedElements);
            });
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.shadowRoot.append(content);
    }
    /**
     * Removes all contents from the shadow DOM
     */
    resetContent() {
        // clear the content
        this._removeExistingContents();
        // add back fallback placeholder
        const slot = document.createElement('slot');
        slot.id = 'placeholder';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.shadowRoot.appendChild(slot);
    }
    _removeExistingContents() {
        // clean content, but attach the style inline due to a bug in Firefox and Safari
        // FIXME: investigate bug and fix implementation
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Array.from(this.shadowRoot.children).forEach((e) => e.remove());
        const style = document.createElement('style');
        style.textContent = Portal_1.styles.map(({ cssText }) => cssText).join('');
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.shadowRoot.appendChild(style);
    }
    firstUpdated(changedProperties) {
        super.firstUpdated(changedProperties);
        // notify about ready state
        this.emitPortalReadyEvent();
    }
    render() {
        return html `<slot id="placeholder"></slot>`;
    }
};
Portal.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    PORTAL_STYLES,
];
__decorate([
    property({ reflect: true, type: String })
], Portal.prototype, "name", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], Portal.prototype, "clone", void 0);
Portal = Portal_1 = __decorate([
    customElement('zui-portal')
], Portal);
export { Portal };
