var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
import 'lit';
import '../form-participation/form-participation.interfaces.js';
import { property } from 'lit/decorators.js';
// eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const ValidationStyleMixin = (superClass, { showValidationOnSubmit = true, showValidationForPristine = false, showValidationOn = 'blur', supportedValidationMessageStyles, defaultValidationMessageStyle, }) => {
    var _ComponentValidationStyle_isPristine, _ComponentValidationStyle_handleBlurForValidation, _ComponentValidationStyle_handleInputForValidation;
    class ComponentValidationStyle extends superClass {
        constructor() {
            super(...arguments);
            /**
             * Determines the behavior when the validation message should be shown on interaction with the input element.
             * Input mode always shows the message while blur mode hides the message on typing.
             */
            this.showValidationOn = showValidationOn;
            /**
             * Flag that is used to determine if the component validation message should be shown.
             *
             * @returns true if the validation message should be shown under the provided configuration.
             */
            this.showValidation = false;
            /**
             * Component specific validation style for showing the validation message.
             * Defaults to first supported style, if not explicitly set.
             */
            this.validationMessageStyle = defaultValidationMessageStyle !== null && defaultValidationMessageStyle !== void 0 ? defaultValidationMessageStyle : supportedValidationMessageStyles[0];
            _ComponentValidationStyle_isPristine.set(this, true);
            _ComponentValidationStyle_handleBlurForValidation.set(this, () => {
                // user input taints the control
                __classPrivateFieldSet(this, _ComponentValidationStyle_isPristine, false, "f");
                // blurring always shows validation
                this.showValidation = this.invalid;
            });
            _ComponentValidationStyle_handleInputForValidation.set(this, () => __awaiter(this, void 0, void 0, function* () {
                // We must await the checkValidity call in updated after the value has changed.
                yield this.updateComplete;
                // user input taints the control
                __classPrivateFieldSet(this, _ComponentValidationStyle_isPristine, false, "f");
                if (this.showValidationOn === 'input') {
                    this.showValidation = this.invalid;
                }
                // hide validation if only to show for blur
                if (this.showValidationOn === 'blur') {
                    this.showValidation = false;
                }
            }));
        }
        formSubmitCallback(subEvent) {
            super.formSubmitCallback(subEvent);
            if (showValidationOnSubmit) {
                this.showValidation = this.invalid;
            }
        }
        formResetCallback() {
            super.formResetCallback();
            __classPrivateFieldSet(this, _ComponentValidationStyle_isPristine, true, "f");
            // reset showValidation to its initial state
            if (showValidationForPristine) {
                this.showValidation = this.invalid;
            }
            else {
                this.showValidation = false;
            }
        }
        connectedCallback() {
            super.connectedCallback();
            this.addEventListener('input', __classPrivateFieldGet(this, _ComponentValidationStyle_handleInputForValidation, "f"));
            this.addEventListener('blur', __classPrivateFieldGet(this, _ComponentValidationStyle_handleBlurForValidation, "f"));
        }
        disconnectedCallback() {
            this.removeEventListener('input', __classPrivateFieldGet(this, _ComponentValidationStyle_handleInputForValidation, "f"));
            this.removeEventListener('blur', __classPrivateFieldGet(this, _ComponentValidationStyle_handleBlurForValidation, "f"));
            super.disconnectedCallback();
        }
        updated(changedProperties) {
            super.updated(changedProperties);
            // show message for initial invalid control if configured
            if (changedProperties.has('invalid') && this.invalid) {
                if (__classPrivateFieldGet(this, _ComponentValidationStyle_isPristine, "f") && showValidationForPristine) {
                    this.showValidation = true;
                }
            }
            // validation is not shown for valid in any case
            if (changedProperties.has('valid') && this.valid) {
                this.showValidation = false;
            }
            // show no validation when disabled
            if (changedProperties.has('disabled') && this.disabled) {
                this.showValidation = false;
            }
            // show no validation when readonly
            if (changedProperties.has('readonly') && this.readonly) {
                this.showValidation = false;
            }
            // re-evaluate if disabled is false
            if (changedProperties.has('disabled') && this.disabled === false) {
                // but of course only if not pristine
                if (!__classPrivateFieldGet(this, _ComponentValidationStyle_isPristine, "f") && showValidationForPristine) {
                    this.showValidation = this.invalid;
                }
            }
            // re-evaluate if readonly is false
            if (changedProperties.has('readonly') && this.readonly === false) {
                if (!__classPrivateFieldGet(this, _ComponentValidationStyle_isPristine, "f") && showValidationForPristine) {
                    this.showValidation = this.invalid;
                }
            }
            // We provide a type signature but want to call the users attention if he is using an unsupported message style.
            // This will give him the invalid message style and the consumed zui component that is erroneous.
            if (!supportedValidationMessageStyles.includes(this.validationMessageStyle)) {
                console.error(`The currently selected validation-message-style: '${this.validationMessageStyle}' is not supported on ${this.tagName.toLowerCase()}. Please choose a supported style from the component type signature or documentation.`);
            }
        }
        /**
         * this forces the validation message to be shown, if the control is invalid
         */
        reportValidity() {
            this.showValidation = this.invalid;
        }
    }
    _ComponentValidationStyle_isPristine = new WeakMap(), _ComponentValidationStyle_handleBlurForValidation = new WeakMap(), _ComponentValidationStyle_handleInputForValidation = new WeakMap();
    __decorate([
        property({ type: String, reflect: true, attribute: 'show-validation-on' })
    ], ComponentValidationStyle.prototype, "showValidationOn", void 0);
    __decorate([
        property({ type: Boolean, reflect: true, attribute: 'show-validation' })
    ], ComponentValidationStyle.prototype, "showValidation", void 0);
    __decorate([
        property({ type: String, attribute: 'validation-message-style', reflect: true }),
        Reflect.metadata('supportedValidationMessageStyles', supportedValidationMessageStyles)
    ], ComponentValidationStyle.prototype, "validationMessageStyle", void 0);
    return ComponentValidationStyle;
};
