import { type PiletApi, type PiralPlugin, type RegistrationDisposer } from 'piral-core';
import { v4 as uuidv4 } from 'uuid';

import { MenuBarItem } from '../../components/MenuBarItem';
import { Tile } from '../../components/Tile';
import type { SettingsSectionType } from '../../types';
import type { PiletLayoutApi, SettingsMenuConfig, SubWorkflow } from './types';

export function createLayoutApi(): PiralPlugin<PiletLayoutApi> {
  return () => (api) => ({
    registerSidebar(name, { weight = 0, ...props }): RegistrationDisposer {
      return api.registerMenu(name, () => <MenuBarItem {...props} />, {
        weight,
        type: 'general',
      });
    },

    registerHomepageTile(props): RegistrationDisposer {
      return api.registerExtension('app-shell-homepage-tile', () => <Tile {...props} />, {
        weight: props.weight,
      });
    },

    registerWorkflowSubsectionOnSettingsPage(config: SettingsMenuConfig): RegistrationDisposer {
      return registerSubsection(config, 'workflow', api);
    },
  });
}

function registerSubsection(
  { component = () => null, title, weight = 0, children }: SettingsMenuConfig,
  section: SettingsSectionType,
  api: PiletApi
): RegistrationDisposer {
  const levelTwoPath = `workflow/${uuidv4()}`;
  const registeredChildren = registerChildren(levelTwoPath, section, api, children);

  const subsectionDisposer = api.registerExtension('app-shell-settings-subsection', component, {
    path: levelTwoPath,
    title,
    weight,
    section,
    children: registeredChildren.ids,
  });

  const registrationDisposers = [...registeredChildren.registrationDisposers];
  registrationDisposers.push(subsectionDisposer);

  return () => {
    registrationDisposers.forEach((disposer) => disposer());
  };
}

function registerChildren(
  levelTwoPath: string,
  section: SettingsSectionType,
  api: PiletApi,
  children?: ReadonlyArray<SubWorkflow>
): ChildrenRegistration {
  const childrenIds: Array<string> = [];
  const registrationDisposers: Array<RegistrationDisposer> = [];

  if (!children) {
    return {
      registrationDisposers: [],
    };
  }

  children?.forEach((child) => {
    const levelThreePath = `${levelTwoPath}/${uuidv4()}`;

    childrenIds.push(levelThreePath);

    const registrationDisposer = api.registerExtension(
      'app-shell-settings-subsection',
      child.component,
      {
        path: levelThreePath,
        title: child.title,
        weight: child.weight ?? 0,
        section,
      }
    );

    registrationDisposers.push(registrationDisposer);
  });

  return {
    ids: childrenIds,
    registrationDisposers,
  };
}

type ChildrenRegistration = {
  ids?: ReadonlyArray<string>;
  registrationDisposers: ReadonlyArray<RegistrationDisposer>;
};
