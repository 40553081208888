export enum StorageKey {
  SESSION_DATA = 'mayer:session-data',
  LOGGED_IN = 'mayer:logged_in',
  USER_INTERACTED = 'mayer:user-interacted',
  INACTIVE_TAB_OPEN = 'mayer:inactive-tab-open',
  BROWSER_LANGUAGE = 'mayer:browser-language',
}

export const setSessionStorageItem = (key: StorageKey, value: string): void =>
  sessionStorage.setItem(key, value);
export const getSessionStorageItem = (key: StorageKey): string | null =>
  sessionStorage.getItem(key);
export const removeSessionStorageItem = (key: StorageKey): void => sessionStorage.removeItem(key);
export const clearSessionStorage = (): void => sessionStorage.clear();

export const setLocalStorageItem = (key: StorageKey, value: string): void =>
  localStorage.setItem(key, value);
export const getLocalStorageItem = (key: StorageKey): string | null => localStorage.getItem(key);
export const removeLocalStorageItem = (key: StorageKey): void => localStorage.removeItem(key);
export const clearLocalStorage = (): void => localStorage.clear();
