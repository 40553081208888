var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
const style = ":host{display:contents}"
// dependencies
import '../../menu/menu-divider/menu-divider.component.js';
import '../../menu/menu-item/menu-item.component.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const SELECT_ALL_STYLES = css `
  ${unsafeCSS(style)}
`;
/**
 * A grouping UI component which uses a `zui-menu-item` wrapping an `zui-checkbox` along with a `zui-menu-divider`
 * to wrap the select-all UI requirements.
 *
 * ## Functionality
 * It proxies the `focus` method of HTMLElement to pass-through focus to the embedded list item.
 * The used menu item skips focus by having an explicit [negative tabindex]:
 * > A negative value (usually tabindex="-1") means that the element is not reachable via sequential keyboard navigation, but could be
 * > focused with JavaScript or visually by clicking with the mouse. It's mostly useful to create accessible widgets with JavaScript.
 * > A negative value is useful when you have off-screen content that appears on a specific event. The user won't be able to focus any
 * > element with a negative tabindex using the keyboard, but a script can do so by calling the focus() method.
 * The checkbox value can optionally be set and is passed-through directly, further informations can be found at the `zui-checkbox`
 * documentation.
 * An optional label can be provided through the _default slot_.
 * As native click and change events are bubbling up the tree, those can be listened on this element as usual.
 *
 * @example
 * ```HTML
 * <zui-select-all>Select all</zui-select-all>
 * ```
 *
 * @slot - default slot for an optional checkbox label
 *
 * [negative tabindex]: https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex
 */
let SelectAll = class SelectAll extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * the value is passed through to the embedded checkbox
         */
        this.value = false;
        /**
         * the size is derived from the touch environment initially if not provided
         */
        this.size = 's';
    }
    /**
     * convenient getter to retrieve a simple boolean (which helps to simply toggle between states)
     */
    get selected() {
        return this.value !== false;
    }
    /**
     * forwards the focus to the embedded list item
     */
    focus() {
        // TODO: use focus-delegation
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.shadowRoot.querySelector('zui-menu-item').focus();
    }
    render() {
        return html `
      <zui-menu-item emphasis="default" size="${this.size}" tabindex="-1">
        <zui-checkbox enable-mixed value="${this.value}">
          <slot></slot>
        </zui-checkbox>
      </zui-menu-item>
      <zui-menu-divider skip></zui-menu-divider>
    `;
    }
};
SelectAll.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    SELECT_ALL_STYLES,
];
__decorate([
    property({ reflect: true })
], SelectAll.prototype, "value", void 0);
__decorate([
    property({ reflect: true, type: String })
], SelectAll.prototype, "size", void 0);
SelectAll = __decorate([
    customElement('zui-select-all')
], SelectAll);
export { SelectAll };
