var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAll } from 'lit/decorators.js';
import { hostStyles } from '../../../host.styles.js';
import { BaseElement } from '../../base/base-element.class.js';
import { getCalendarMonthsForSelectedYear, getDateTimesFromJsDates, getDefaultLocale, getStartOfMonthName, isoDateConverter, isSameMonth, isSameYear, someIsSameMonth, someIsSameYear, } from '../utils/date-picker.utils.js';
import { DateTime } from 'luxon';
import '../../picker/picker-grid-cell/picker-grid-cell.component.js';
import '../../picker/picker-header-cell/picker-header-cell.component.js';
import '../../picker/picker-grid/picker-grid.component.js';
import '../../picker/picker-header/picker-header.component.js';
import { GetSharedEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import '../../interactive-icon/interactive-icon.component.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const styles = ".picker-grid{margin-top:calc(var(--zui-gu) * 3)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
// icons
import '@zeiss/zui-icons/dist/components/generated/arrows-arrow-outline-actually-centred-right.component.js';
import '@zeiss/zui-icons/dist/components/generated/arrows-arrow-outline-actually-centred-left.component.js';
const datePickerMonthPickerStyles = css `
  ${unsafeCSS(styles)}
`;
// TODO: those do not follow naming convention, fix them?
class MonthPickerMonthSelectedEvent extends GetSharedEventClass('month-picker-month-selected') {
}
class MonthPickerYearSelectedEvent extends GetSharedEventClass('month-picker-year-selected') {
}
class MonthPickerPreviousYearSelectedEvent extends GetSharedEventClass('month-picker-previous-year-selected') {
}
class MonthPickerNextYearSelectedEvent extends GetSharedEventClass('month-picker-next-year-selected') {
}
/**
 * The date picker month picker is a feature component that should be used inside the date picker component for selecting months.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-date-picker-month-picker
 *   locale="en-US"
 *   max="2022-12-31T23:59:59.999+01:00"
 *   min="2020-01-01T00:00:00.000+01:00">
 * </zui-date-picker-month-picker>
 * ```
 */
let DatePickerMonthPicker = class DatePickerMonthPicker extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.MonthSelectedEvent = MonthPickerMonthSelectedEvent;
        this.YearSelectedEvent = MonthPickerYearSelectedEvent;
        this.PreviousYearSelectedEvent = MonthPickerPreviousYearSelectedEvent;
        this.NextYearSelectedEvent = MonthPickerNextYearSelectedEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * whether the year select is disabled or not
         */
        this.yearSelectDisabled = false;
        /**
         * disabled months
         */
        this.disabledMonths = [];
        /**
         * disabled years
         */
        this.disabledYears = [];
        /**
         * locale
         */
        this.locale = getDefaultLocale();
        /**
         * max date
         */
        this.max = null;
        /**
         * min date
         */
        this.min = null;
        /**
         * selected month
         */
        this.selectedMonth = null;
        /**
         * current year
         *
         * @returns current year
         */
        this.currentYear = new Date();
    }
    get _maxDateDT() {
        return this.max ? DateTime.fromJSDate(this.max) : undefined;
    }
    get _minDateDT() {
        return this.min ? DateTime.fromJSDate(this.min) : undefined;
    }
    get _selectedMonthDT() {
        return this.selectedMonth ? DateTime.fromJSDate(this.selectedMonth) : undefined;
    }
    get _currentYearDT() {
        return DateTime.fromJSDate(this.currentYear);
    }
    get _gridCellMonths() {
        return getCalendarMonthsForSelectedYear(this._currentYearDT);
    }
    get _disabledGridCellConditions() {
        const disabledMonths = getDateTimesFromJsDates(this.disabledMonths);
        const disabledYears = getDateTimesFromJsDates(this.disabledYears);
        return [
            // minDate, if set
            (date) => (this._minDateDT ? date.startOf('month') < this._minDateDT.startOf('month') : false),
            // maxDate, if set
            (date) => (this._maxDateDT ? date.startOf('month') > this._maxDateDT.startOf('month') : false),
            // disabledMonths
            (date) => someIsSameMonth(date, disabledMonths),
            // disabledYears
            (date) => someIsSameYear(date, disabledYears),
        ];
    }
    get _gridCellFocusConditions() {
        return [
            () => (this._selectedMonthDT ? isSameMonth(this._selectedMonthDT, this._currentYearDT) : false),
            () => (this._selectedMonthDT ? isSameYear(this._selectedMonthDT, this._currentYearDT) : false),
        ];
    }
    get _gridCellSelectedConditions() {
        return [
            (date) => (this._selectedMonthDT ? isSameMonth(date, this._selectedMonthDT) : false),
            (date) => isSameYear(date, this._currentYearDT),
        ];
    }
    get _gridCellSubtleConditions() {
        return [(date) => !isSameYear(date, this._currentYearDT)];
    }
    _isGridCellDisabled(date) {
        return this._disabledGridCellConditions.some((predicate) => predicate(date));
    }
    _canFocusGridCell() {
        return this._gridCellFocusConditions.every((predicate) => predicate());
    }
    _getGridCellEmphasis(date) {
        const isSelected = this._gridCellSelectedConditions.every((predicate) => predicate(date));
        const isSubtle = this._gridCellSubtleConditions.every((predicate) => predicate(date));
        return isSelected ? 'selected' : isSubtle ? 'subtle' : 'default';
    }
    _handleMonthSelected({ detail }) {
        const { selected, value } = detail;
        if (selected.disabled) {
            return;
        }
        this.selectedMonth = new Date(value);
        this.currentYear = this.selectedMonth;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this._emitMonthPickerMonthSelectedEvent({ value: this._selectedMonthDT.startOf('month').toJSDate() });
    }
    _handleYearSelected() {
        this._emitMonthPickerYearSelectedEvent({ value: this.currentYear });
    }
    _handlePreviousYearSelected() {
        this.currentYear = this._currentYearDT.minus({ year: 1 }).toJSDate();
        this._emitMonthPickerPreviousYearSelectedEvent({
            startOfYear: this._currentYearDT.startOf('year').toJSDate(),
            endOfYear: this._currentYearDT.endOf('year').toJSDate(),
        });
    }
    _handleNextYearSelected() {
        this.currentYear = this._currentYearDT.plus({ year: 1 }).toJSDate();
        this._emitMonthPickerNextYearSelectedEvent({
            startOfYear: this._currentYearDT.startOf('year').toJSDate(),
            endOfYear: this._currentYearDT.endOf('year').toJSDate(),
        });
    }
    _updateFocus() {
        if (!this._canFocusGridCell()) {
            return;
        }
        // TODO: this needs to be reworked to make it more readable
        const pickerGridCell = Array.from(this._pickerGridCells).find((pickerGridCell) => this._selectedMonthDT ? isSameMonth(DateTime.fromISO(pickerGridCell.value), this._selectedMonthDT) : false);
        pickerGridCell === null || pickerGridCell === void 0 ? void 0 : pickerGridCell.focus();
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('selectedMonth')) {
            this._updateFocus();
        }
    }
    render() {
        return html `
      <zui-picker-header
        ?disabled="${this.yearSelectDisabled}"
        value="${this._currentYearDT.year}"
        @zui-picker-header-current-selected="${this._handleYearSelected}"
        @zui-picker-header-next-selected="${this._handleNextYearSelected}"
        @zui-picker-header-previous-selected="${this._handlePreviousYearSelected}"
      >
        <zui-interactive-icon slot="icon-left">
          <zui-icon-arrows-arrow-outline-actually-centred-left></zui-icon-arrows-arrow-outline-actually-centred-left>
        </zui-interactive-icon>
        <zui-interactive-icon slot="icon-right">
          <zui-icon-arrows-arrow-outline-actually-centred-right></zui-icon-arrows-arrow-outline-actually-centred-right>
        </zui-interactive-icon>
      </zui-picker-header>

      <zui-picker-grid class="picker-grid" columns="4" @zui-picker-grid-cell-selected="${this._handleMonthSelected}">
        ${this._gridCellMonths.map((dateTime) => {
            const disabled = this._isGridCellDisabled(dateTime);
            const emphasis = this._getGridCellEmphasis(dateTime);
            return html `
            <zui-picker-grid-cell
              ?disabled="${disabled}"
              emphasis="${emphasis}"
              slot="pickerGridCells"
              style="--zui-picker-grid-cell-width: 56px; --zui-picker-grid-cell-height: 56px"
              value="${dateTime.toISO()}"
            >
              ${getStartOfMonthName(dateTime.year, dateTime.month, this.locale, 'short')}
            </zui-picker-grid-cell>
          `;
        })}
      </zui-picker-grid>
    `;
    }
};
DatePickerMonthPicker.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    datePickerMonthPickerStyles,
];
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'year-select-disabled' })
], DatePickerMonthPicker.prototype, "yearSelectDisabled", void 0);
__decorate([
    property({ attribute: false })
], DatePickerMonthPicker.prototype, "disabledMonths", void 0);
__decorate([
    property({ attribute: false })
], DatePickerMonthPicker.prototype, "disabledYears", void 0);
__decorate([
    property({ reflect: true, type: String })
], DatePickerMonthPicker.prototype, "locale", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], DatePickerMonthPicker.prototype, "max", void 0);
__decorate([
    property({ reflect: true, type: String, converter: isoDateConverter })
], DatePickerMonthPicker.prototype, "min", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'selected-month', converter: isoDateConverter })
], DatePickerMonthPicker.prototype, "selectedMonth", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'current-year', converter: isoDateConverter })
], DatePickerMonthPicker.prototype, "currentYear", void 0);
__decorate([
    queryAll('zui-picker-grid-cell')
], DatePickerMonthPicker.prototype, "_pickerGridCells", void 0);
DatePickerMonthPicker = __decorate([
    customElement('zui-date-picker-month-picker')
], DatePickerMonthPicker);
export { DatePickerMonthPicker };
