import {
  ZuiDialogButton,
  ZuiEwiqDialog,
  ZuiEwiqDialogButtons,
  ZuiEwiqDialogDescription,
  ZuiEwiqDialogHeadline,
} from '@zeiss/zui-react';
import { usePiletApi } from 'piral-core';
import { type FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useClose } from '../../hooks/use-close';
import { RemainingTime } from './RemainingTime';

interface InactivityDialogProps {
  timeInMsBeforeForcedLogout: number;
}

const InactivityDialogContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledZuiEwiqDialogDescription = styled(ZuiEwiqDialogDescription)`
  font: var(--zui-typography-body);
`;

export const InactivityDialog: FC<InactivityDialogProps> = ({ timeInMsBeforeForcedLogout }) => {
  const [targetLogoutTimestamp] = useState<number>(Date.now() + timeInMsBeforeForcedLogout);
  const piral = usePiletApi();
  const tabOpen = piral.isInactivityDialogOpen();
  const { t } = useTranslation();

  const onClose = useCallback(() => {
    piral.emit('inactivityDialogClosed', {});
  }, []);

  const logout = () => {
    piral.emit('logout', {});
    onClose();
  };

  useEffect(() => {
    if (tabOpen === false) {
      onClose();
    }
  }, [tabOpen]);

  const ref = useClose('inactivity-dialog', onClose);

  return (
    <InactivityDialogContainer ref={ref}>
      <ZuiEwiqDialog state="info">
        <ZuiEwiqDialogHeadline slot="headerText">
          {t('dialogs.inactivity.inactive')}
        </ZuiEwiqDialogHeadline>
        <StyledZuiEwiqDialogDescription>
          <RemainingTime targetTimestamp={targetLogoutTimestamp} action={logout} />
        </StyledZuiEwiqDialogDescription>

        <footer slot="footer">
          <ZuiEwiqDialogButtons>
            <ZuiDialogButton emphasis="primary-highlight" onclick={onClose}>
              {t('dialogs.inactivity.keepWorkingButton')}
            </ZuiDialogButton>
            <ZuiDialogButton data-test="log-out" emphasis="default" onclick={logout}>
              <span data-test="log-out">{t('accountMenu.logout')}</span>
            </ZuiDialogButton>
          </ZuiEwiqDialogButtons>
        </footer>
      </ZuiEwiqDialog>
    </InactivityDialogContainer>
  );
};
