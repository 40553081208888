var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { BaseElement } from '../base/base-element.class.js';
import { property } from 'lit/decorators.js';
import { WithEventsMixin } from '../../mixins/events/events.mixin.js';
import { MimickedChangeEvent, MimickedInputEvent } from '../../contracts/event.classes.js';
import '../../contracts/event.contracts.js';
import 'lit';
export class SliderBaseClass extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.InputEvent = MimickedInputEvent;
        this.ChangeEvent = MimickedChangeEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * The min value of the slider.
         */
        this.min = 0;
        /**
         * The max value of the slider.
         */
        this.max = 1;
        /**
         * the step value for the slider. defines what values can be selected by the user.
         */
        this.step = 'any';
        /**
         * the start value of the active line
         * defaults to negative infinity to ensure it defaults to the very beginning of the slider
         */
        this.activeLineStart = 'min';
    }
}
__decorate([
    property({ reflect: true, type: Number })
], SliderBaseClass.prototype, "min", void 0);
__decorate([
    property({ reflect: true, type: Number })
], SliderBaseClass.prototype, "max", void 0);
__decorate([
    property({ reflect: true })
], SliderBaseClass.prototype, "step", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'active-line-start' })
], SliderBaseClass.prototype, "activeLineStart", void 0);
export class RealSliderBaseClass extends SliderBaseClass {
}
