var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseElement, RealBaseElement } from '../../base/base-element.class.js';
import { FocusDifferentiationMixin } from '../../../mixins/visual-focus/focus-differentiation.mixin.js';
import { hostStyles } from '../../../host.styles.js';
const style = ":host([zui-keyboard-focused]) span{border:1px dashed;border-radius:var(--zui-border-radius-on-focus);outline:none}span{margin:0;overflow:hidden;color:var(--zui-color-ewiq-dialog-question-text-color);font:var(--zui-typography-subtitle1);text-overflow:ellipsis;border:1px solid rgba(0,0,0,0)}"
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-ewiq-dialog-question-text-color: var(--zui-color-gs-100)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-ewiq-dialog-question-text-color: var(--zui-color-gs-50)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const ewiqDialogQuestionStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * This represents a very simple question text component, which is used in other components, e.g. EwiqDialog. It provides a default slot,
 * which is wrapped in a zui-styled span tags
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-ewiq-dialog-question>How much is the fish?</zui-ewiq-dialog-question>
 * ```
 * @slot - This is the default slot to be used for the question text
 */
let EwiqDialogQuestion = class EwiqDialogQuestion extends FocusDifferentiationMixin(RealBaseElement) {
    render() {
        return html `<span tabindex="0"><slot></slot></span>`;
    }
};
EwiqDialogQuestion.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    ewiqDialogQuestionStyles,
];
EwiqDialogQuestion = __decorate([
    customElement('zui-ewiq-dialog-question')
], EwiqDialogQuestion);
export { EwiqDialogQuestion };
