import { ZuiProgressRing } from '@zeiss/zui-react';
import type { FC } from 'react';
import styled from 'styled-components';

const Centered = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

type IconSize = 's8' | 's12' | 's16' | 's24' | 's32' | 's40' | 's48' | 's64' | 's72' | 's80';

type Emphasis = 'default' | 'highlight' | 'primary-highlight';

interface LoadingIndicatorProps {
  size?: IconSize;
  emphasis?: Emphasis;
}

export const LoadingIndicator: FC<LoadingIndicatorProps> = ({
  size = 's16',
  emphasis = 'highlight',
}) => (
  <Centered>
    <ZuiProgressRing emphasis={emphasis} size={size} />
  </Centered>
);
