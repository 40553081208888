var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import { BaseElement } from '../base/base-element.class.js';
import { hostStyles } from '../../host.styles.js';
const style = "#icon-container{display:flex;align-items:center;user-select:none}#closebutton{display:none}:host([closable]) #closebutton{display:block;margin-left:calc(var(--zui-gu) * 2)}zui-interactive-icon{margin-left:calc(var(--zui-gu) * 2)}"
import '../../mixins/icons/icon.types.js';
import { GetNotificationEventClass } from '../../contracts/event.classes.js';
import '../../contracts/event.contracts.js';
// deps
import '../interactive-icon/interactive-icon.component.js';
import { WithEventsMixin } from '../../mixins/events/events.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
// icons
import '@zeiss/zui-icons/dist/components/generated/symbols-close.component.js';
const iconBarStyles = css `
  ${unsafeCSS(style)}
`;
class IconBarClosedEvent extends GetNotificationEventClass('icon-bar-closed') {
}
/**
 * Internal component for three icons, used for example in `<zui-toast-notification>` `<zui-box>` `<zui-dialog-box>`.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-icon-bar emphasis="highlight">
 * 		<zui-icon-common-holy-placeholder slot="icon1"></zui-icon-common-holy-placeholder>
 * 		<zui-icon-common-holy-placeholder slot="icon2"></zui-icon-common-holy-placeholder>
 * 		<zui-icon-common-holy-placeholder slot="icon3"></zui-icon-common-holy-placeholder>
 * </zui-icon-bar>
 * ```
 *
 * @fires {GetZuiEvent<IconBar, 'ClosedEvent'>} zui-icon-bar-closed - event which gets fired when the icon bar is closed
 * @slot icon1 - first icon slot.
 * @slot icon2 - second icon slot.
 * @slot icon3 - third icon slot.
 */
let IconBar = class IconBar extends WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.ClosedEvent = IconBarClosedEvent;
    }
}) {
    constructor() {
        super(...arguments);
        /**
         * Defines one of three possible emphasis states (default/subtle/highlight)
         */
        this.emphasis = 'subtle';
        /**
         * If closable is enabled an additional button will be visible that allows the user to close the box.
         */
        this.closable = false;
    }
    get _allIcons() {
        return [...this._assignedIcons1, ...this._assignedIcons2, ...this._assignedIcons3];
    }
    get _showIcon1() {
        return this._assignedIcons1.length === 1;
    }
    get _showIcon2() {
        return this._assignedIcons2.length === 1;
    }
    get _showIcon3() {
        return this._assignedIcons3.length === 1;
    }
    _handleIconSlotChange() {
        this._allIcons.forEach((icon) => (icon.size = 's16'));
        this.requestUpdate();
    }
    /**
     * Handler for the click event on the close button, fires a box-closed event.
     */
    _handleClick() {
        this.emitIconBarClosedEvent();
    }
    render() {
        return html `<div id="icon-container">
      <zui-interactive-icon ?hidden=${!this._showIcon1} emphasis="${this.emphasis}">
        <slot name="icon1" @slotchange="${this._handleIconSlotChange}"></slot>
      </zui-interactive-icon>
      <zui-interactive-icon ?hidden=${!this._showIcon2} emphasis="${this.emphasis}">
        <slot name="icon2" @slotchange="${this._handleIconSlotChange}"></slot>
      </zui-interactive-icon>
      <zui-interactive-icon ?hidden=${!this._showIcon3} emphasis="${this.emphasis}">
        <slot name="icon3" @slotchange="${this._handleIconSlotChange}"></slot>
      </zui-interactive-icon>
      ${this.closable
            ? html `<zui-interactive-icon emphasis="${this.emphasis}" @click="${this._handleClick}" id="closebutton">
            <zui-icon-symbols-close size="s12"></zui-icon-symbols-close>
          </zui-interactive-icon>`
            : nothing}
    </div>`;
    }
};
IconBar.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    iconBarStyles,
];
__decorate([
    property({ reflect: true, type: String })
], IconBar.prototype, "emphasis", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], IconBar.prototype, "closable", void 0);
__decorate([
    queryAssignedElements({ slot: 'icon1', flatten: true })
], IconBar.prototype, "_assignedIcons1", void 0);
__decorate([
    queryAssignedElements({ slot: 'icon2', flatten: true })
], IconBar.prototype, "_assignedIcons2", void 0);
__decorate([
    queryAssignedElements({ slot: 'icon3', flatten: true })
], IconBar.prototype, "_assignedIcons3", void 0);
IconBar = __decorate([
    customElement('zui-icon-bar')
], IconBar);
export { IconBar };
