/* eslint-disable lit/no-template-bind */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var TagMenuButton_1;
import { css, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, query, queryAssignedElements, state } from 'lit/decorators.js';
import '../../../mixins/disabled/disabled.interfaces.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { GetNotificationEventClass } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { BaseElement } from '../../base/base-element.class.js';
import { styleMap } from 'lit/directives/style-map.js';
import { hostStyles } from '../../../host.styles.js';
const styles = ":host{display:flex;---zui-tag-menu-button-padding: calc(var(--zui-gu) * 1);--zui-menu-item-count: 9.5;--zui-menu-width: calc(var(--zui-gu) * 20)}:host([adapt-width]){--zui-menu-width: 100%}div{padding-bottom:var(---zui-tag-menu-button-padding)}:host(:not([open])) slot:not([name])::slotted(*){display:none}"
// deps
import '../tag/tag.component.js';
import { getSlottedContentsFromPortal } from '../../../utils/portal.utils.js';
import '../../menu/menu/menu.component.js';
import '../../menu/menu-wrapper/menu-wrapper.component.js';
// icons
import '@zeiss/zui-icons/dist/components/generated/arrows-arrow-outline-actually-centred-down.component.js';
import '@zeiss/zui-icons/dist/components/generated/arrows-arrow-outline-actually-centred-up.component.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const tagMenuButtonStyles = css `
  ${unsafeCSS(styles)}
`;
class TagMenuSelectedEvent extends GetNotificationEventClass('tag-menu-selected') {
}
/**
 * The Tag Menu Button is used when not all Tags be shown due to missing screenspace or intended limitation.
 * Clicking on the Tag Menu Button will open a dropdown menu, that makes the other tags accessible.
 *
 * The tags in the menu are rendered as zui-menu-item(s) automatically.
 *
 * ## Figma
 * - [Styleguide – Web](https://www.figma.com/file/6dkjypErYWQPfuRBD58Aey/%F0%9F%93%96--Styleguide---Web?node-id=8492%3A90165)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-tag-menu-button expand-label="More">
 *   <zui-tag text="Dense white cataracy" value="Dense white cataracy"></zui-tag>
 *   <zui-tag text="High density" value="High density"></zui-tag>
 *   <zui-tag text="corrective surgery" value="corrective surgery"></zui-tag>
 *   <zui-tag text="failed rhexis" value="failed rhexis"></zui-tag>
 *   <zui-tag text="dense cataract" value="dense cataract"></zui-tag>
 *   <zui-tag text="challenging case" value="challenging case"></zui-tag>
 * </zui-tag-menu-button>
 * ```
 * @fires {GetZuiEvent<TagMenuButton, 'MenuSelectEvent'>} zui-tag-menu-selected - will be dispatched, if a `<zui-tag>` from the menu is selected
 *
 * @slot default - This is the default slot for tag components
 *
 * @cssprop --zui-menu-item-count - the number of items in the menu to be displayed before scrolling
 * @cssprop --zui-menu-width - overrides the menu width. Defaults to preset width or full width on adapt-width variant.
 * */
let TagMenuButton = TagMenuButton_1 = class TagMenuButton extends DisabledMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.MenuSelectEvent = TagMenuSelectedEvent;
    }
})) {
    constructor() {
        super(...arguments);
        /**
         * Manages if the menu is open or closed.
         */
        this.open = false;
        /**
         * This hides the count of tags inside
         */
        this.hideTagCount = false;
        /**
         * Text to be displayed as label for the tag menu button
         */
        this.expandLabel = TagMenuButton_1.TAG_MENU_BUTTON_EXPAND_LABEL;
        /**
         * Defines the strategy for the menu adapt-width behavior.
         * Default is false that means the width is fixed
         */
        this.adaptWidth = false;
        this._tagCount = undefined;
        this._getTagButtonReference = () => {
            return this._containerRef;
        };
        this._handleFocusOut = (event) => {
            const [menuRef] = getSlottedContentsFromPortal(this._menuWrapperRef.portal, 'zui-menu');
            // closes the menu when anything else than the menu or anything it contains has been clicked.
            if (this.open &&
                // when clicked outside, we do not have any related target, therefore we check for a target
                event.target !== null &&
                event.relatedTarget !== menuRef &&
                event.relatedTarget !== this &&
                !menuRef.contains(event.relatedTarget)) {
                this.open = false;
            }
        };
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.hasAttribute('tabindex')) {
            this.tabIndex = 0;
        }
    }
    get _menuCustomProperties() {
        const hostStyle = getComputedStyle(this);
        return {
            '--zui-menu-item-count': hostStyle.getPropertyValue('--zui-menu-item-count'),
            '--zui-menu-width': hostStyle.getPropertyValue('--zui-menu-width'),
        };
    }
    _handleToggleMenu() {
        // avoid closing menu on outside click on disabled state
        if (this.disabled || (this._tagReferences.length === 0 && !this.open)) {
            return;
        }
        this.open = !this.open;
    }
    _handleSlotChange() {
        // every opening and closing moves the tags between inner <-> outer slot, we have to avoid to count from switching references
        if (this._tagReferences.length > 0) {
            this._tagCount = this._tagReferences.length;
        }
        // every slotted tag should be displayed as a menu item
        this._tagReferences.forEach((tag) => {
            tag.setAttribute('zui-internal-as-menu-item', '');
        });
    }
    // get text with or without tag count
    _getTagMenuButtonText() {
        return this.hideTagCount ? this.expandLabel : `${this.expandLabel} (${this._tagCount})`;
    }
    _handleSelected() {
        this.emitTagMenuSelectedEvent();
        this._handleToggleMenu();
    }
    _delegateFocusToMenu() {
        const [menuRef] = getSlottedContentsFromPortal(this._menuWrapperRef.portal, 'zui-menu');
        menuRef.focus();
    }
    _handleMenuKeydown({ code }) {
        if (code === 'Escape') {
            this.open = false;
        }
    }
    render() {
        return html `
      <div>
        <zui-tag @click="${this._handleToggleMenu}" text="${this._getTagMenuButtonText()}" ?disabled=${this.disabled}>
          ${this.open
            ? html `<zui-icon-arrows-arrow-outline-actually-centred-up
                slot="icon"
              ></zui-icon-arrows-arrow-outline-actually-centred-up>`
            : html `<zui-icon-arrows-arrow-outline-actually-centred-down
                slot="icon"
              ></zui-icon-arrows-arrow-outline-actually-centred-down>`}
        </zui-tag>
      </div>
      <zui-menu-wrapper
        ?open=${this.open}
        .getPositionReference=${this._getTagButtonReference}
        @zui-menu-wrapper-opened=${this._delegateFocusToMenu}
      >
        <zui-menu
          class="menu"
          style=${styleMap({
            '--zui-menu-item-count': this._menuCustomProperties['--zui-menu-item-count'],
            '--zui-menu-width': this._menuCustomProperties['--zui-menu-width'],
        })}
          ?adapt-width=${this.adaptWidth}
          ?disabled=${this.disabled}
          @click=${this._handleSelected}
          @keydown=${this._handleMenuKeydown}
          @focusout=${this._handleFocusOut}
        >
          ${this.open ? html `<slot @slotchange=${this._handleSlotChange}></slot>` : nothing}
        </zui-menu>
      </zui-menu-wrapper>
      <slot aria-hidden="true" @slotchange=${this._handleSlotChange}></slot>
    `;
    }
};
TagMenuButton.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    tagMenuButtonStyles,
];
// eslint-disable-next-line @typescript-eslint/naming-convention
TagMenuButton.TAG_MENU_BUTTON_EXPAND_LABEL = 'More';
__decorate([
    property({ reflect: true, type: Boolean })
], TagMenuButton.prototype, "open", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'hide-tag-count' })
], TagMenuButton.prototype, "hideTagCount", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'expand-label' })
], TagMenuButton.prototype, "expandLabel", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'adapt-width' })
], TagMenuButton.prototype, "adaptWidth", void 0);
__decorate([
    query('div')
], TagMenuButton.prototype, "_containerRef", void 0);
__decorate([
    query('zui-menu-wrapper')
], TagMenuButton.prototype, "_menuWrapperRef", void 0);
__decorate([
    state()
], TagMenuButton.prototype, "_tagCount", void 0);
__decorate([
    queryAssignedElements({ flatten: true, selector: 'zui-tag' })
], TagMenuButton.prototype, "_tagReferences", void 0);
TagMenuButton = TagMenuButton_1 = __decorate([
    customElement('zui-tag-menu-button')
], TagMenuButton);
export { TagMenuButton };
