var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, unsafeCSS } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseElement } from '../base/base-element.class.js';
import { themeBaseLegacyComponentStyle } from '../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../styles/base-zbds/index.js';
const theme = ""
const style = ""
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const divStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * A helper component to provide a maintained context if needed. For example the
 * tooltip requires to work properly to be in a non-disabled context. It can't
 * be injected into e.g. disabled buttons. Thus the disabled button could be
 * wrapped with this helper element to work as reference for the tooltip.
 *
 * _Note: Since divs are block elements you may have to align its styling._
 *
 * @example
 * ```html
 * <zui-div style="display: inline-block">
 *   <zui-button disabled>Click me!</zui-button>
 *   <zui-tooltip-directive>
 *     <zui-tooltip>Not allowed right now</zui-tooltip>
 *   </zui-tooltip-directive>
 * </zui-div>
 * ```
 *
 * @slot default simply projects everything inside
 */
let Div = class Div extends BaseElement {
    // set default style to mimic div
    connectedCallback() {
        super.connectedCallback();
        // prevent overriding custom styles
        if (this.style.getPropertyValue('display') === '') {
            this.style.setProperty('display', 'block');
        }
    }
    // if we have no shadow dom enabled, we have to inject the slotted contents as child nodes
    createRenderRoot() {
        // to render into the element's childNodes, return `this`
        // https://stackoverflow.com/a/55213037/1146207
        // https://lit-element.polymer-project.org/api/classes/_lit_element_.litelement.html#createrenderroot
        // https://github.com/lit/lit-element/blob/2b398727dacb7694fbf38816824675d20838704f/src/lit-element.ts#L244-L246
        return this;
    }
};
Div.styles = [
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    divStyles,
];
Div = __decorate([
    customElement('zui-div')
], Div);
export { Div };
