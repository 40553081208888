/**
 * Content inside the slotted element (e.g. <zui-menu>) can be slotted many times.
 * We add a tagging attribute to the root slot for identification. That way we can find it and copy slotted elements at its place.
 */
export const SLOT_TAG = ['zui-internal-slot-boundary', 'zui-internal-slot-boundary'];
/**
 * To resolve many layers of nested slots we need to find the first "root" slot element.
 * This function loops through the DOM tree until it reaches the slot tagged with a 'zui-internal-slot-boundary' attribute and returns it
 *
 * @param currentSlot the next slot in the DOM tree
 * @returns the tagged root slot.
 */
export function _findRenderRootOfElement(currentSlot) {
    while (!isSlotBoundary(currentSlot)) {
        if (currentSlot.assignedSlot) {
            currentSlot = currentSlot.assignedSlot;
        }
        else {
            return currentSlot;
        }
    }
    return currentSlot;
}
const isSlotBoundary = (slot) => {
    return slot.tagName === 'SLOT' && slot.hasAttribute(SLOT_TAG[0]);
};
