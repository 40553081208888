var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import { BaseElement, RealBaseElement } from '../../base/base-element.class.js';
const styles = "button{position:relative;width:calc(var(--zui-gu) * 8);height:calc(var(--zui-gu) * 8);padding:0;background-color:rgba(0,0,0,0);border:none}:host([emphasis=selected]) button{background-color:var(--zui-color-headerbar-icon-button-background-color-selected)}button:focus-visible{outline:none}#interactive-icon{display:flex;align-items:center;justify-content:center;width:100%;height:100%}"
import { hostStyles } from '../../../host.styles.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import '../../interactive-icon/interactive-icon.component.js';
import '../../../mixins/icons/icon.types.js';
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-headerbar-icon-button-background-color-selected: var(--zui-color-gs-30)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-headerbar-icon-button-background-color-selected: var(--zui-color-gs-120)}"
const themeDark = ""
const themeLight = ""
const interactiveIconEmphasis = {
    subtle: 'subtle',
    default: 'default',
    selected: 'highlight',
};
const headerbarIconButtonStyles = css `
  ${unsafeCSS(styles)}
`;
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
/**
 * HeaderbarIconButton component displays a slotted icon.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-headerbar-icon-button emphasis="default" size="m">
 *   <zui-icon-common-holy-placeholder slot="icon"></zui-icon-common-holy-placeholder>
 * </zui-headerbar-icon-button>
 * ```
 *
 * @slot icon - Slot for an icon
 */
let HeaderbarIconButton = class HeaderbarIconButton extends DisabledMixin(RealBaseElement) {
    constructor() {
        super(...arguments);
        /**
         * Emphasis of the IconButton
         */
        this.emphasis = 'default';
        /**
         * Aria label of the IconButton button
         */
        this.label = undefined;
        /**
         * Size of the IconButton
         */
        this.size = 'm';
    }
    _updateIconSize() {
        this._assignedIcons.forEach((icon) => {
            icon.size = this.size === 'l' ? 's24' : 's16';
        });
    }
    _handleIconSlotchange() {
        this._updateIconSize();
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('size')) {
            this._updateIconSize();
        }
    }
    render() {
        return html `
      <button ?disabled="${this.disabled}" aria-label="${ifDefined(this.label)}" type="button">
        <zui-interactive-icon
          ?disabled="${this.disabled}"
          id="interactive-icon"
          emphasis="${interactiveIconEmphasis[this.emphasis]}"
        >
          <slot name="icon" @slotchange="${this._handleIconSlotchange}"></slot>
        </zui-interactive-icon>
      </button>
    `;
    }
};
HeaderbarIconButton.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    headerbarIconButtonStyles,
];
__decorate([
    property({ reflect: true, type: String })
], HeaderbarIconButton.prototype, "emphasis", void 0);
__decorate([
    property({ type: String })
], HeaderbarIconButton.prototype, "label", void 0);
__decorate([
    property({ reflect: true, type: String })
], HeaderbarIconButton.prototype, "size", void 0);
__decorate([
    queryAssignedElements({ slot: 'icon', flatten: true })
], HeaderbarIconButton.prototype, "_assignedIcons", void 0);
HeaderbarIconButton = __decorate([
    customElement('zui-headerbar-icon-button')
], HeaderbarIconButton);
export { HeaderbarIconButton };
