import type { StorageData } from '@arnold/common/types';
import { ZuiProgressBar, ZuiTextLink } from '@zeiss/zui-react';
import { usePiletApi } from 'piral-core';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useStorageData } from '../../hooks/use-storage-data';
import { LoadingIndicator } from '../shell/LoadingIndicator';
import {
  DataStorageLoadingIndicatorContainer,
  DataStorageNumbersContainer,
  DataStorageProgressBarContainer,
  DataStorageQuota,
} from './DataStorageDisplay.styles';

export function DataStorageDisplayContent(): JSX.Element {
  const [storageData, loading] = useStorageData();

  if (loading) {
    return (
      <DataStorageLoadingIndicatorContainer>
        <LoadingIndicator />
      </DataStorageLoadingIndicatorContainer>
    );
  }

  return <DataStorage storageData={storageData} />;
}

const DataStorage: FC<{ storageData: StorageData }> = ({ storageData }) => {
  const piral = usePiletApi();
  const { t } = useTranslation();
  const progress = (storageData.usage / storageData.maxUsage) * 100 || 100;
  const upgradeStepInGB = 500;
  const dataStorageDescription = t('dialogs.dataStorage.description');
  const dataStorageQuota = t('accountMenu.dataStorage.quota', storageData);
  const upgradeSize = t('accountMenu.dataStorage.upgradeSize', { upgradeStepInGB });

  const openDataStorageDialog = () =>
    piral.showNotification(dataStorageDescription || '', { type: 'info' });

  return (
    <>
      <DataStorageProgressBarContainer>
        <ZuiProgressBar mode="progress" value={progress}></ZuiProgressBar>
      </DataStorageProgressBarContainer>
      <DataStorageNumbersContainer>
        <DataStorageQuota data-test="storage-display-quota">{dataStorageQuota}</DataStorageQuota>
        <ZuiTextLink text={upgradeSize} onclick={openDataStorageDialog}></ZuiTextLink>
      </DataStorageNumbersContainer>
    </>
  );
};
