import { cssId as css, defineColor, mapColors } from '../utils/theme.utils.js';
import { alternativeColors, greyScaleColors, primaryBlueColors, secondaryColors } from './color.definition.js';
export const colorsRawStyle = css `
  /* Black */
  ${defineColor('BK', '#000000')}

  /* Greyscale */
    ${mapColors(greyScaleColors)}

    /* Primary Blue */
    ${mapColors(primaryBlueColors)}

    /* Secondary... */
    ${mapColors(secondaryColors)}

    /* Alternative Colors */
    ${mapColors(alternativeColors)}
`;
