var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { property } from 'lit/decorators.js';
import { RealBaseElement } from '../base/base-element.class.js';
import 'lit';
import '../../mixins/disabled/disabled.interfaces.js';
import { DisabledMixin } from '../../mixins/disabled/disabled.mixin.js';
/**
 * Abstract base class for all interactive slots of zui-menu. Currently these are zui-menu-item and
 * zui-select-option.
 */
export class MenuItemBase extends DisabledMixin(RealBaseElement) {
    constructor() {
        super(...arguments);
        /**
         * ARIA selected for this element; defaults to 'false' if not explicitly set by author
         */
        this.ariaSelected = 'false';
        /**
         * aria-disabled should explicitly be set. It will be synced to the disabled property.
         */
        this.ariaDisabled = 'false';
        /**
         * disables truncation and grows the item to its contents.
         */
        this.disableTruncation = false;
        /**
         * the emphasis of the menu item
         */
        this.emphasis = 'default';
        /**
         * Specify if menu item is used to open a sub-menu.
         */
        this.hasPopup = 'false';
        /**
         * tagging attribute to query slots adhering to this class
         */
        this.isMenuItem = true;
        /**
         * Every menuitem must have an accessible name. This name comes from the element's contents by default.
         */
        this.label = null;
        /**
         * enforces the "role" attribute for a11y. A menu item is always contained by a menu or menubar and statically present.
         * Another valid role is "option" e.g. for a select or listbox.
         */
        this.role = 'menuitem';
        /**
         * marks this element as selected
         */
        this.selected = false;
        /**
         * makes this element selectable
         */
        this.selectable = false;
        /**
         * the size is derived from the touch environment initially if not provided
         */
        this.size = this.hasTouch ? 'l' : 's';
        /**
         * the value of the menu-like item
         * TODO: Evaluate if this is the best way to do this or rather allow undefined when refactoring the select.
         * Having an empty string may result in multiple items being selected at once.
         */
        this.value = '';
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.hasAttribute('tabindex')) {
            this.tabIndex = 0;
        }
    }
    willUpdate(changedProperties) {
        super.willUpdate(changedProperties);
        this.ariaDisabled = String(this.disabled);
        this.ariaSelected = String(this.selected);
    }
}
__decorate([
    property({ reflect: true, attribute: 'aria-selected', type: String })
], MenuItemBase.prototype, "ariaSelected", void 0);
__decorate([
    property({ reflect: true, attribute: 'aria-disabled', type: String })
], MenuItemBase.prototype, "ariaDisabled", void 0);
__decorate([
    property({ reflect: true, type: Boolean, attribute: 'disable-truncation' })
], MenuItemBase.prototype, "disableTruncation", void 0);
__decorate([
    property({ reflect: true, type: String })
], MenuItemBase.prototype, "emphasis", void 0);
__decorate([
    property({ reflect: true, attribute: 'aria-haspopup', type: String })
], MenuItemBase.prototype, "hasPopup", void 0);
__decorate([
    property({ reflect: true, attribute: 'zui-menu-item', type: Boolean })
], MenuItemBase.prototype, "isMenuItem", void 0);
__decorate([
    property({ reflect: true, attribute: 'aria-label', type: String })
], MenuItemBase.prototype, "label", void 0);
__decorate([
    property({ reflect: true, type: String })
], MenuItemBase.prototype, "role", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], MenuItemBase.prototype, "selected", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], MenuItemBase.prototype, "selectable", void 0);
__decorate([
    property({ reflect: true, type: String })
], MenuItemBase.prototype, "size", void 0);
__decorate([
    property({ reflect: true, type: String })
], MenuItemBase.prototype, "value", void 0);
// we need this for usage with Mixins, because abstract classes are not possible due to TS restrictions...
export class RealMenuItemBase extends MenuItemBase {
}
