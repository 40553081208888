/* eslint-disable @typescript-eslint/naming-convention */
export const greyScaleColors = {
    'zui-color-gs-120': '#07090d',
    'zui-color-gs-115': '#161d22',
    'zui-color-gs-110': '#1a2329',
    'zui-color-gs-105': '#262e36',
    'zui-color-gs-100': '#353d45',
    'zui-color-gs-95': '#404952',
    'zui-color-gs-90': '#555e6b',
    'zui-color-gs-85': '#626c7a',
    'zui-color-gs-80': '#828d9e',
    // FIXME: enable this for testing and remove if all works
    // 'zui-color-gs-80': '#FF00FF',
    'zui-color-gs-70': '#a5b1c2',
    'zui-color-gs-60': '#c1cbd9',
    'zui-color-gs-50': '#d1d9e5',
    'zui-color-gs-40': '#dde3ed',
    'zui-color-gs-35': '#e4ebf2',
    'zui-color-gs-30': '#edf2f7',
    'zui-color-gs-20': '#f5f7fa',
    'zui-color-gs-10': '#ffffff',
};
export const primaryBlueColors = {
    'zui-color-pb-120': '#0029af',
    'zui-color-pb-110': '#003ecd',
    'zui-color-pb-100': '#0050f2',
    'zui-color-pb-90': '#2e7af5',
    'zui-color-pb-80': '#5c9dff',
    'zui-color-pb-70': '#85b6ff',
    'zui-color-pb-60': '#a6c9ff',
};
export const secondaryColors = {
    /* Red */
    'zui-color-sc-r-110': '#e04747',
    'zui-color-sc-r-100': '#ed5353',
    'zui-color-sc-r-90': '#fc6060',
    /* Pink */
    'zui-color-sc-p-110': '#eb43ad',
    'zui-color-sc-p-100': '#f251b7',
    'zui-color-sc-p-90': '#ff68c7',
    /* Blue */
    'zui-color-sc-b-110': '#0098e6',
    'zui-color-sc-b-100': '#00a6fb',
    'zui-color-sc-b-90': '#31b9ff',
    /* Green */
    'zui-color-sc-g-110': '#29c192',
    'zui-color-sc-g-100': '#3ad2a3',
    'zui-color-sc-g-90': '#4ee9b9',
    /* Yellow */
    'zui-color-sc-y-110': '#efb92b',
    'zui-color-sc-y-100': '#fdca40',
    'zui-color-sc-y-90': '#ffd562',
    /* Orange */
    'zui-color-sc-o-110': '#e97847',
    'zui-color-sc-o-100': '#ef8354',
    'zui-color-sc-o-90': '#f7895a',
};
export const alternativeColors = {
    /* purple */
    'zui-color-ac-pu-110': '#c22df7',
    'zui-color-ac-pu-100': '#ca41fc',
    'zui-color-ac-pu-90': '#d164f8',
    /* Violet */
    'zui-color-ac-v-110': '#6b4beb',
    'zui-color-ac-v-100': '#7756fb',
    'zui-color-ac-v-90': '#8566ff',
    /* Turquoise */
    'zui-color-ac-tu-110': '#3ac7ce',
    'zui-color-ac-tu-100': '#45d7de',
    'zui-color-ac-tu-90': '#58dee4',
    /* Dirty Blue */
    'zui-color-ac-db-110': '#738fba',
    'zui-color-ac-db-100': '#85a1cc',
    'zui-color-ac-db-90': '#9ab3db',
    /* Clear Green */
    'zui-color-ac-cg-110': '#30bb4c',
    'zui-color-ac-cg-100': '#38cd56',
    'zui-color-ac-cg-90': '#48d965',
    /* Lime */
    'zui-color-ac-l-110': '#9ed929',
    'zui-color-ac-l-100': '#a8e431',
    'zui-color-ac-l-90': '#b4f23b',
    /* Brown */
    'zui-color-ac-br-110': '#a27b54',
    'zui-color-ac-br-100': '#b78d65',
    'zui-color-ac-br-90': '#c39c77',
};
export const zuiColors = Object.assign(Object.assign(Object.assign(Object.assign({}, greyScaleColors), primaryBlueColors), secondaryColors), alternativeColors);
