var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// TODO: this needs to be tested thoroughly
import 'lit';
import { waitForLitElementUpdated } from '../utils/component.utils.js';
/**
 * A property decorator that allows for selecting the assignedElements of a slot and that will
 * allow to pass an optional async waitFor function that gets each element passed. This defaults
 * to stable LitElement, i.e. it will wait until the selected assignedElements have actually rendered
 * Example usage:
 * ```ts
 * class MyElement extends LitElement {
 *
 *   @queryAssignedElements({ slot: 'icon', flatten: true, selector: '[zui-icon]' })
 *   private readonly _assignedIcons: IconBaseElement[];
 *
 *   render() {
 *     return html`
 *       <slot name="icon"></slot>
 *     `;
 *   }
 * }
 * ```
 *
 * @param [QueryAsyncAssignedElementsOptions] An optional options object
 * @param [QueryAsyncAssignedElementsOptions.waitFor] An optional function that is invoked and awaited for every queried `Node`. By default this function is waiting for stable LitElement.
 * @param [QueryAsyncAssignedElementsOptions.selector] An optional CSS selector to filter the queried nodes *after* all nodes have been awaited.
 * @param [QueryAsyncAssignedElementsOptions.slot] The actual slot whose assignedElements should be waited and selected for; defaults to the default slot
 * @param [QueryAsyncAssignedElementsOptions.flatten] Allows nested slots, by being set to true, defaults to undefined and thus the default slot
 */
export function queryAsyncAssignedElements({ waitFor = waitForLitElementUpdated, slot, selector, flatten, } = {}) {
    return function (target, key) {
        Object.defineProperty(target, key, {
            get() {
                var _a, _b;
                return __awaiter(this, void 0, void 0, function* () {
                    // get the slot, and then all elements it has...
                    const slotSelector = `slot${slot ? `[name=${slot}]` : ':not([name])'}`;
                    const slotEl = (_a = this.renderRoot) === null || _a === void 0 ? void 0 : _a.querySelector(slotSelector);
                    const elements = (_b = slotEl === null || slotEl === void 0 ? void 0 : slotEl.assignedElements({ flatten })) !== null && _b !== void 0 ? _b : [];
                    // wait for each element to be updated
                    yield Promise.all(elements.map((litElementToBeWaitedFor) => waitFor(litElementToBeWaitedFor)));
                    if (selector) {
                        return elements.filter((node) => node.matches(selector));
                    }
                    return elements;
                });
            },
            enumerable: true,
            configurable: true,
        });
    };
}
