var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
import 'lit';
import '../../types.js';
import '../../utils/util.types.js';
const KEYBOARD_FOCUSED_ATTRIBUTE = 'zui-keyboard-focused';
const MOUSE_FOCUSED_ATTRIBUTE = 'zui-mouse-focused';
/**
 * This mixin is used to differentiate between mouse focus and keyboard focus on a component.
 * It gives either the host the styling attribute 'zui-keyboard-focused' or 'zui-mouse-focused' depending, whether
 * the component itself should receive those or not.
 *
 * If you add the _tagging attribute_ `zui-differentiate-focus` to a DOM element they will receive one of
 * those attributes, too.
 *
 * However, in most cases, you do not need to supply any tagging attributes, because the focus
 * differentiation will fall back to the host element to determine focus type.
 *
 *
 * Example:
 * ```
 * class MyExampleComponent extends FocusDifferentiationMixin(RealBaseElement) {
 *   ...
 *   protected override render(): TemplateResult | void {
 *     return html`<span zui-differentiate-focus>"Example"</span>`;
 *   }
 *   ...
 * }
 *
 * ```
 * could be targeted with `span[zui-keyboard-focused]` if it receives keyboard focus
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const FocusDifferentiationMixin = (superClass, { 
/**
 * By default, the input source is not updated once the targets are focused.
 * This option allows to update the attributes while the focus remains on the targets.
 */
updateWhileFocused = false, 
/**
 * Selector for specific focus listener targets to be used.
 * Optionally, their ids will be used to provide explicit
 * attributes on the host to be used as styling targets.
 */
selector = '[zui-differentiate-focus]', } = {}) => {
    var _FocusDifferentiationClass_instances, _FocusDifferentiationClass_taggedDifferentiableElements_get, _FocusDifferentiationClass_keyboardActive, _FocusDifferentiationClass_differentiableElements_get, _FocusDifferentiationClass_addFocusListeners, _FocusDifferentiationClass_removeFocusListeners, _FocusDifferentiationClass_updateFocusAttributes, _FocusDifferentiationClass_handleFocusInEvent, _FocusDifferentiationClass_handleFocusOutEvent, _FocusDifferentiationClass_handleKeyDownEvent, _FocusDifferentiationClass_handleMouseDownEvent, _FocusDifferentiationClass_handleGlobalKeyDownEvent, _FocusDifferentiationClass_handleGlobalMouseDownEvent;
    class FocusDifferentiationClass extends superClass {
        constructor() {
            super(...arguments);
            _FocusDifferentiationClass_instances.add(this);
            // flags whether a keyboard or the mouse is responsible
            _FocusDifferentiationClass_keyboardActive.set(this, true);
            _FocusDifferentiationClass_handleFocusInEvent.set(this, ({ target }) => {
                __classPrivateFieldGet(this, _FocusDifferentiationClass_instances, "m", _FocusDifferentiationClass_updateFocusAttributes).call(this, target);
            });
            _FocusDifferentiationClass_handleFocusOutEvent.set(this, ({ target }) => {
                target.removeAttribute(KEYBOARD_FOCUSED_ATTRIBUTE);
                target.removeAttribute(MOUSE_FOCUSED_ATTRIBUTE);
            });
            _FocusDifferentiationClass_handleKeyDownEvent.set(this, ({ target }) => {
                __classPrivateFieldSet(this, _FocusDifferentiationClass_keyboardActive, true, "f");
                __classPrivateFieldGet(this, _FocusDifferentiationClass_instances, "m", _FocusDifferentiationClass_updateFocusAttributes).call(this, target);
            });
            _FocusDifferentiationClass_handleMouseDownEvent.set(this, ({ target }) => {
                __classPrivateFieldSet(this, _FocusDifferentiationClass_keyboardActive, false, "f");
                __classPrivateFieldGet(this, _FocusDifferentiationClass_instances, "m", _FocusDifferentiationClass_updateFocusAttributes).call(this, target);
            });
            _FocusDifferentiationClass_handleGlobalKeyDownEvent.set(this, () => {
                __classPrivateFieldSet(this, _FocusDifferentiationClass_keyboardActive, true, "f");
            });
            _FocusDifferentiationClass_handleGlobalMouseDownEvent.set(this, () => {
                __classPrivateFieldSet(this, _FocusDifferentiationClass_keyboardActive, false, "f");
            });
        }
        connectedCallback() {
            super.connectedCallback();
            // listen globally to determine input source
            window.addEventListener('keydown', __classPrivateFieldGet(this, _FocusDifferentiationClass_handleGlobalKeyDownEvent, "f"));
            window.addEventListener('mousedown', __classPrivateFieldGet(this, _FocusDifferentiationClass_handleGlobalMouseDownEvent, "f"));
        }
        disconnectedCallback() {
            window.removeEventListener('keydown', __classPrivateFieldGet(this, _FocusDifferentiationClass_handleGlobalKeyDownEvent, "f"));
            window.removeEventListener('mousedown', __classPrivateFieldGet(this, _FocusDifferentiationClass_handleGlobalMouseDownEvent, "f"));
            super.disconnectedCallback();
        }
        updated(changedProperties) {
            super.updated(changedProperties);
            // re-apply focus listeners
            __classPrivateFieldGet(this, _FocusDifferentiationClass_instances, "m", _FocusDifferentiationClass_removeFocusListeners).call(this);
            __classPrivateFieldGet(this, _FocusDifferentiationClass_instances, "m", _FocusDifferentiationClass_addFocusListeners).call(this);
        }
    }
    _FocusDifferentiationClass_keyboardActive = new WeakMap(), _FocusDifferentiationClass_handleFocusInEvent = new WeakMap(), _FocusDifferentiationClass_handleFocusOutEvent = new WeakMap(), _FocusDifferentiationClass_handleKeyDownEvent = new WeakMap(), _FocusDifferentiationClass_handleMouseDownEvent = new WeakMap(), _FocusDifferentiationClass_handleGlobalKeyDownEvent = new WeakMap(), _FocusDifferentiationClass_handleGlobalMouseDownEvent = new WeakMap(), _FocusDifferentiationClass_instances = new WeakSet(), _FocusDifferentiationClass_taggedDifferentiableElements_get = function _FocusDifferentiationClass_taggedDifferentiableElements_get() {
        var _a;
        return (_a = this.renderRoot.querySelectorAll(selector)) !== null && _a !== void 0 ? _a : [];
    }, _FocusDifferentiationClass_differentiableElements_get = function _FocusDifferentiationClass_differentiableElements_get() {
        // use tagged elements and always the host
        return [...__classPrivateFieldGet(this, _FocusDifferentiationClass_instances, "a", _FocusDifferentiationClass_taggedDifferentiableElements_get), this];
    }, _FocusDifferentiationClass_addFocusListeners = function _FocusDifferentiationClass_addFocusListeners() {
        __classPrivateFieldGet(this, _FocusDifferentiationClass_instances, "a", _FocusDifferentiationClass_differentiableElements_get).forEach((element) => {
            if (updateWhileFocused) {
                element.addEventListener('keydown', __classPrivateFieldGet(this, _FocusDifferentiationClass_handleKeyDownEvent, "f"));
                element.addEventListener('mousedown', __classPrivateFieldGet(this, _FocusDifferentiationClass_handleMouseDownEvent, "f"));
            }
            element.addEventListener('focusin', __classPrivateFieldGet(this, _FocusDifferentiationClass_handleFocusInEvent, "f"));
            element.addEventListener('focusout', __classPrivateFieldGet(this, _FocusDifferentiationClass_handleFocusOutEvent, "f"));
        });
    }, _FocusDifferentiationClass_removeFocusListeners = function _FocusDifferentiationClass_removeFocusListeners() {
        __classPrivateFieldGet(this, _FocusDifferentiationClass_instances, "a", _FocusDifferentiationClass_differentiableElements_get).forEach((element) => {
            if (updateWhileFocused) {
                element.removeEventListener('keydown', __classPrivateFieldGet(this, _FocusDifferentiationClass_handleKeyDownEvent, "f"));
                element.removeEventListener('mousedown', __classPrivateFieldGet(this, _FocusDifferentiationClass_handleMouseDownEvent, "f"));
            }
            element.removeEventListener('focusin', __classPrivateFieldGet(this, _FocusDifferentiationClass_handleFocusInEvent, "f"));
            element.removeEventListener('focusout', __classPrivateFieldGet(this, _FocusDifferentiationClass_handleFocusOutEvent, "f"));
        });
    }, _FocusDifferentiationClass_updateFocusAttributes = function _FocusDifferentiationClass_updateFocusAttributes(onElement) {
        if (__classPrivateFieldGet(this, _FocusDifferentiationClass_keyboardActive, "f")) {
            onElement.setAttribute(KEYBOARD_FOCUSED_ATTRIBUTE, '');
            onElement.removeAttribute(MOUSE_FOCUSED_ATTRIBUTE);
        }
        else {
            onElement.removeAttribute(KEYBOARD_FOCUSED_ATTRIBUTE);
            onElement.setAttribute(MOUSE_FOCUSED_ATTRIBUTE, '');
        }
    };
    return FocusDifferentiationClass;
};
