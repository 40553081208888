var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import { BaseElement, RealBaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
const style = ":host([disabled]){pointer-events:none}.menu-tab-icon-item{display:flex;align-items:center;justify-content:center;box-sizing:border-box;width:calc(var(--zui-gu) * 4);height:calc(var(--zui-gu) * 4);padding:0;background-color:var(--zui-color-menu-tab-item-default-background);border-top:0;border-right:0;border-bottom-color:rgba(0,0,0,0);border-bottom-width:calc(var(--zui-gu) * 0.5);border-bottom-style:solid;border-left:0;outline:none}:host([emphasis=default]) .menu-tab-icon-item{color:var(--zui-color-menu-tab-item-default);cursor:pointer}:host([emphasis=default]) .menu-tab-icon-item:hover{background-color:var(--zui-color-menu-tab-item-default-background-hover)}:host([emphasis=default]) .menu-tab-icon-item:focus{background-color:var(--zui-color-menu-tab-item-default-background-focus)}:host([emphasis=default]) .menu-tab-icon-item:active{background-color:var(--zui-color-menu-tab-item-default-background-active)}:host([emphasis=selected]) .menu-tab-icon-item{color:var(--zui-color-menu-tab-item-selected);border-bottom-color:var(--zui-color-menu-tab-item-selected-border-bottom);cursor:default}:host([disabled]) .menu-tab-icon-item{opacity:var(--zui-disabled-opacity)}::slotted(*){margin-top:calc(var(--zui-gu) * 0.5);pointer-events:none}"
import '../../../mixins/icons/icon.types.js';
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-menu-tab-item-default-background-active: var(--zui-color-gs-30);--zui-color-menu-tab-item-default-background-focus: var(--zui-color-gs-10);--zui-color-menu-tab-item-default-background-hover: var(--zui-color-gs-20);--zui-color-menu-tab-item-default-background: var(--zui-color-gs-10);--zui-color-menu-tab-item-default: var(--zui-color-gs-100);--zui-color-menu-tab-item-selected-background: var(--zui-color-gs-100);--zui-color-menu-tab-item-selected-border-bottom: var(--zui-color-pb-100);--zui-color-menu-tab-item-selected: var(--zui-color-gs-120)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-menu-tab-item-default-background-active: var(--zui-color-gs-110);--zui-color-menu-tab-item-default-background-focus: var(--zui-color-gs-100);--zui-color-menu-tab-item-default-background-hover: var(--zui-color-gs-105);--zui-color-menu-tab-item-default-background: var(--zui-color-gs-100);--zui-color-menu-tab-item-default: var(--zui-color-gs-50);--zui-color-menu-tab-item-selected-background: var(--zui-color-gs-100);--zui-color-menu-tab-item-selected-border-bottom: var(--zui-color-pb-90);--zui-color-menu-tab-item-selected: var(--zui-color-gs-10)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const menuTabIconItemStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * A menu tab icon item is meant to be used inside the menu tab bar.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-menu-tab-icon-item emphasis="selected" value="#1">
 *   <zui-icon-med-laser-ready></zui-icon-med-laser-ready>
 * </zui-menu-tab-icon-item>
 * ```
 */
let MenuTabIconItem = class MenuTabIconItem extends DisabledMixin(RealBaseElement) {
    constructor() {
        super(...arguments);
        /**
         * emphasis of the `zui-menu-tab-icon-item` (default/selected)
         */
        this.emphasis = 'default';
    }
    _handleMenuTabIconItemSlotchange() {
        this._assignedIcons.forEach((icon) => (icon.size = 's16'));
    }
    render() {
        return html `
      <button ?disabled="${this.disabled}" class="menu-tab-icon-item">
        <slot @slotchange="${this._handleMenuTabIconItemSlotchange}"></slot>
      </button>
    `;
    }
};
MenuTabIconItem.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    menuTabIconItemStyles,
];
__decorate([
    property({ reflect: true, type: String })
], MenuTabIconItem.prototype, "emphasis", void 0);
__decorate([
    property({ reflect: true, type: String })
], MenuTabIconItem.prototype, "value", void 0);
__decorate([
    queryAssignedElements({ flatten: true })
], MenuTabIconItem.prototype, "_assignedIcons", void 0);
MenuTabIconItem = __decorate([
    customElement('zui-menu-tab-icon-item')
], MenuTabIconItem);
export { MenuTabIconItem };
