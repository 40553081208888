import { ZuiIconAlertsAlarmOn } from '@zeiss/zui-react-icons';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const EmptyList = styled.div`
  display: flex;
  overflow: hidden;

  /* subtract height of header bar and popover header */
  height: calc(100vh - 119px);
  justify-content: center;
  background-color: var(--zui-color-gs-20);
  font: var(--zui-typography-body);
`;

export const EmptyListContent = styled.div`
  position: relative;
  top: 40%;

  > .icon {
    position: relative;
    bottom: var(--arn-space-2);
    left: 50%;
    color: var(--zui-color-gs-80);
    transform: translateX(-50%);
  }
`;

export const EmptyBellNotificationList: FC = () => {
  const { t } = useTranslation();
  return (
    <EmptyList data-test="bell-notification-content-empty-state">
      <EmptyListContent>
        <ZuiIconAlertsAlarmOn className="icon" slot="icon" size="s24" />
        {t('bellNotificationMenu.emptyState')}
      </EmptyListContent>
    </EmptyList>
  );
};
