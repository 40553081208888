var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, unsafeCSS } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import { BaseElement } from '../../base/base-element.class.js';
import { hostStyles } from '../../../host.styles.js';
import { classMap } from 'lit/directives/class-map.js';
// deps
import '../../dialog-button/dialog-button.component.js';
const style = ":host{--zui-ewiq-button-alignment: row;--zui-ewiq-button-border-top: 1px solid var(--zui-color-dialog-box-separator-line);--zui-ewiq-button-border-right: 1px solid var(--zui-color-dialog-box-separator-line)}.align-buttons-in-columns,:host([primary-position=auto]) .align-buttons-in-columns{--zui-ewiq-button-alignment: column;--zui-ewiq-button-border-top: 1px solid var(--zui-color-dialog-box-separator-line);--zui-ewiq-button-border-right: none}:host([primary-position=last]) .align-buttons-in-columns{--zui-ewiq-button-alignment: column-reverse}:host([primary-position=last]){--zui-ewiq-button-alignment: row-reverse}:host([primary-position=auto]){--zui-ewiq-button-alignment: var(--zui-internal-action-button-order)}#button-container:not(.primary-exists){--zui-ewiq-button-alignment: row}#button-container{display:flex;flex-direction:var(--zui-ewiq-button-alignment);justify-content:center;width:100%;height:auto}#button-container ::slotted(*){width:100%;height:calc(var(--zui-gu) * 8);border:none;border-top:var(--zui-ewiq-button-border-top);border-right:var(--zui-ewiq-button-border-right)}:host([primary-position=first]) #button-container ::slotted(*:last-child),:host([primary-position=auto]) #button-container:not(.row-reverse) ::slotted(*:last-child){border-right:none}.row-reverse ::slotted(*:first-child){border-right:none !important}:host([row-reverse]) slot{border:none}"
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ":host([theme=light]),:host([theme=zbds-light]){--zui-color-dialog-box-top-line: var(--zui-color-gs-90);--zui-color-dialog-box-separator-line: var(--zui-color-gs-40)}:host([theme=dark]),:host([theme=zbds-dark]){--zui-color-dialog-box-top-line: var(--zui-color-gs-90);--zui-color-dialog-box-separator-line: var(--zui-color-gs-110)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const ewiqDialogButtonsStyles = css `
  ${unsafeCSS(style)}
`;
/**
 * The button container holds the dialog buttons
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-ewiq-dialog-buttons primary-position="first">
 *   <zui-dialog-button slot="primary">Primary Action</zui-dialog-button>
 *   <zui-dialog-button slot="secondary">Secondary Action</zui-dialog-button>
 *   <zui-dialog-button>Tertiary Action</zui-dialog-button>
 * </zui-ewiq-dialog-buttons>
 * ```
 *
 * @slot primary - Slot for the primary buttons
 * @slot secondary - Slot for the secondary buttons
 * @slot - This is the default slot for default buttons
 */
let EwiqDialogButtons = class EwiqDialogButtons extends BaseElement {
    constructor() {
        super(...arguments);
        /**
         * ARIA role for this element; defaults to 'button' if not explicitly set by author
         */
        this.role = 'button';
        /**
         * this defines the position of the primary button
         */
        this.primaryPosition = 'auto';
    }
    get _alignButtonsInColumns() {
        return this._allButtons.some((button) => button.multiline) || this._allButtons.length > 2;
    }
    get _primaryExists() {
        return this._assignedPrimaryDialogButtons.length === 1;
    }
    get _secondaryExists() {
        return this._assignedSecondaryDialogButtons.length === 1;
    }
    get _rowReverse() {
        var _a;
        return ((_a = getComputedStyle(this).getPropertyValue('--zui-ewiq-button-alignment')) === null || _a === void 0 ? void 0 : _a.trim()) === 'row-reverse';
    }
    get _allButtons() {
        return [
            ...this._assignedPrimaryDialogButtons,
            ...this._assignedSecondaryDialogButtons,
            ...this._defaultSlotElements,
        ];
    }
    _primarySlotChangeHandler() {
        var _a;
        (_a = this._assignedPrimaryDialogButtons[0]) === null || _a === void 0 ? void 0 : _a.setAttribute('emphasis', 'primary-highlight');
        this.requestUpdate();
    }
    _secondarySlotChangeHandler() {
        var _a;
        (_a = this._assignedSecondaryDialogButtons[0]) === null || _a === void 0 ? void 0 : _a.setAttribute('emphasis', 'highlight');
        this.requestUpdate();
    }
    _defaultSlotChangeHandler() {
        this.requestUpdate();
    }
    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        // sadly we have to render twice, because we are depending on style, that is only available
        // after first render()
        this.requestUpdate();
    }
    render() {
        return html `
      <div
        id="button-container"
        class=${classMap({
            'align-buttons-in-columns': this._alignButtonsInColumns,
            'primary-exists': this._primaryExists,
            'secondary-exists': this._secondaryExists,
            'row-reverse': this._rowReverse,
        })}
      >
        <slot name="primary" @slotchange="${this._primarySlotChangeHandler}"></slot>
        <slot name="secondary" @slotchange="${this._secondarySlotChangeHandler}"></slot>
        <slot @slotchange="${this._defaultSlotChangeHandler}"></slot>
      </div>
    `;
    }
};
EwiqDialogButtons.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    ewiqDialogButtonsStyles,
];
__decorate([
    property({ reflect: true, type: String })
], EwiqDialogButtons.prototype, "role", void 0);
__decorate([
    property({ reflect: true, type: String, attribute: 'primary-position' })
], EwiqDialogButtons.prototype, "primaryPosition", void 0);
__decorate([
    queryAssignedElements({ flatten: true })
], EwiqDialogButtons.prototype, "_defaultSlotElements", void 0);
__decorate([
    queryAssignedElements({ slot: 'primary', selector: 'zui-dialog-button', flatten: true })
], EwiqDialogButtons.prototype, "_assignedPrimaryDialogButtons", void 0);
__decorate([
    queryAssignedElements({ slot: 'secondary', selector: 'zui-dialog-button', flatten: true })
], EwiqDialogButtons.prototype, "_assignedSecondaryDialogButtons", void 0);
EwiqDialogButtons = __decorate([
    customElement('zui-ewiq-dialog-buttons')
], EwiqDialogButtons);
export { EwiqDialogButtons };
