import type { MenuItemProps } from 'piral-menu';
import type { FC } from 'react';

import { ActionsMenuItem } from '../ActionsMenu';
import { ContainerMenuItem } from '../ContainerMenuItem';

export const MenuItem: FC<MenuItemProps> = ({ meta, type, children }) => {
  if (type === 'general' || type === 'user') {
    return <ContainerMenuItem meta={meta}>{children}</ContainerMenuItem>;
  }

  return <ActionsMenuItem meta={meta}>{children}</ActionsMenuItem>;
};
