import styled from 'styled-components';

const footerHeight = 'var(--arn-space-12)';

export const Main = styled.main`
  display: flex;
  height: calc(var(--arn-content-height) - ${footerHeight});
  flex-direction: row;
  padding: var(--arn-space-2);
  background-color: var(--zui-color-gs-40);
  gap: var(--arn-space-2);
`;

export const InfoPanel = styled.div`
  width: var(--arn-space-80);
  padding: var(--arn-space-8);
  background-color: var(--zui-color-gs-10);
`;

export const Footer = styled.footer`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--zui-color-gs-10);
  color: var(--zui-color-gs-80);
  font: var(--zui-typography-label1);
  padding-block: var(--arn-space-6);
  padding-inline-end: var(--arn-space-6);
`;
