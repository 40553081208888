var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var DatePickerInputPartMonth_1;
import { css, html, unsafeCSS } from 'lit';
import { BaseElement } from '../../base/base-element.class.js';
import '../../../types.js';
import { commaListConverter, cycle, hasElementAnyDimensions } from '../../../utils/component.utils.js';
import { searchNextRelevantMonth } from '../utils/date-picker-input.utils.js';
import { customElement } from 'lit/decorators/custom-element.js';
import { property } from 'lit/decorators/property.js';
import { query } from 'lit/decorators/query.js';
import { state } from 'lit/decorators.js';
import { DelegateFocusMixin } from '../../../mixins/visual-focus/delegate-focus.mixin.js';
import { GetSharedEventClass, MimickedInputEvent } from '../../../contracts/event.classes.js';
import '../../../contracts/event.contracts.js';
import { WithEventsMixin } from '../../../mixins/events/events.mixin.js';
import { hostStyles } from '../../../host.styles.js';
const sharedStyles = ":host{--zui-date-picker-input-part-placeholder-width: 0;---zui-date-picker-input-part-input-width: 0}input{width:var(---zui-date-picker-input-part-input-width);height:calc(var(--zui-gu) * 3.75);padding:0;color:var(--zui-color-text-default);font:var(--zui-typography-body);line-height:calc(var(--zui-gu) * 3.75);background-color:rgba(0,0,0,0);border:none;box-shadow:none}:host([disabled]) input{opacity:var(--zui-disabled-opacity)}input.show-placeholder{width:var(--zui-date-picker-input-part-placeholder-width)}input:focus{outline:none}input:active{outline:none}input::placeholder{color:var(--zui-color-placeholder-input)}:host([readonly]) input::placeholder{color:rgba(0,0,0,0);opacity:0}input::-moz-placeholder{opacity:1}input[type=number]{-moz-appearance:textfield}input[type=number]::-webkit-outer-spin-button,input[type=number]::-webkit-inner-spin-button{margin:0;-webkit-appearance:none}"
import { DisabledMixin } from '../../../mixins/disabled/disabled.mixin.js';
import { themeBaseLegacyComponentStyle } from '../../../styles/base-legacy/index.js';
import { themeZbdsBaseComponentStyle } from '../../../styles/base-zbds/index.js';
const theme = ""
const styles = ":host{--zui-date-picker-input-part-month-placeholder-width: calc(var(--zui-gu) * 4.5);---zui-date-picker-input-part-month-input-width: var(--zui-date-picker-input-part-month-placeholder-width)}input{width:var(---zui-date-picker-input-part-month-input-width)}"
const themeDark = ""
const themeLight = ""
const themeStyles = css `
  ${unsafeCSS(theme)}
`;
const datePickerInputPartStyles = css `
  ${unsafeCSS(sharedStyles)}
`;
const datePickerInputPartComponentStyles = css `
  ${unsafeCSS(styles)}
`;
// TODO: those are commandEvents!
class DatePickerInputPartMonthFocusPreviousEvent extends GetSharedEventClass('date-picker-input-part-month-focus-previous') {
}
class DatePickerInputPartMonthFocusNextEvent extends GetSharedEventClass('date-picker-input-part-month-focus-next') {
}
class DatePickerInputPartMonthChangedEvent extends GetSharedEventClass('date-picker-input-part-month-changed') {
}
/**
 * The `zui-date-picker-input-part-month` is part of the `zui-date-picker-input` and is not designed for single usage.
 *
 * @example
 * html```
 * <zui-date-picker-input-part-month months="January,February,March,April,May,June,July,August,September,October,November,December"></zui-date-picker-input-part-month>
 * ```
 *
 * @cssprop --zui-date-picker-input-part-month-placeholder-width - override input placeholder width
 */
let DatePickerInputPartMonth = DatePickerInputPartMonth_1 = class DatePickerInputPartMonth extends DisabledMixin(DelegateFocusMixin(WithEventsMixin(class extends BaseElement {
    constructor() {
        super(...arguments);
        /* eslint-disable @typescript-eslint/naming-convention */
        this.InputEvent = MimickedInputEvent;
        this.ChangedEvent = DatePickerInputPartMonthChangedEvent;
        this.FocusPreviousEvent = DatePickerInputPartMonthFocusPreviousEvent;
        this.FocusNextEvent = DatePickerInputPartMonthFocusNextEvent;
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}))) {
    constructor() {
        super(...arguments);
        /**
         * placeholder
         */
        this.placeholder = 'MMM';
        /**
         * readonly
         */
        this.readonly = false;
        /**
         * available months that can be chosen by the input.
         * These will usually be the localized months of the year.
         */
        this.months = [];
        /**
         * currently selected index from the provided months.
         */
        this.value = null;
        this._searchString = '';
        this._userInteraction = false;
        this._lastInputTimeStamp = Date.now();
    }
    // TODO: this is conceptually wrong; it should not toggle an inner state, but set this.value directly
    // and involve the logic of _handleSearchValueChange directly...
    _handleSearchValueInput(character) {
        const currentTimeStamp = Date.now();
        character = character.toLowerCase();
        // Elongate searchString with user input if within the timeout
        if (currentTimeStamp - this._lastInputTimeStamp <= DatePickerInputPartMonth_1.SEARCH_VALUE_RESET_TIMEOUT) {
            this._searchString = this._searchString.concat(character);
        }
        else {
            this._searchString = character;
            // We need this in case the searchString has not changed but it might cause a change for the value
            // e.g.: "a" => "a" toggle from April to August
            this.requestUpdate('_searchString');
        }
        this._lastInputTimeStamp = Date.now();
    }
    _handleSearchValueChange() {
        // Any valid number input will select the month at this index
        const searchStringAsNumber = Number(this._searchString);
        if (!isNaN(searchStringAsNumber) && searchStringAsNumber <= this.months.length && searchStringAsNumber > 0) {
            this.value = searchStringAsNumber - 1;
            return;
        }
        // if the months are toggled use single character otherwise whole string to match
        const isMonthIteration = Array.from(this._searchString).every((searchTerm) => searchTerm === this._searchString[0]);
        this.value = searchNextRelevantMonth(this.months, this.value, isMonthIteration ? this._searchString[0] : this._searchString);
    }
    _handleInputFocusEvent() {
        this._inputElement.select();
    }
    _handleDatePickerInputPartMonthKeydownEvent(event) {
        if (this.readonly) {
            return;
        }
        switch (event.key) {
            case 'Tab':
                break;
            case 'ArrowLeft':
                event.preventDefault();
                this._emitDatePickerInputPartMonthFocusPreviousEvent();
                break;
            case 'ArrowRight':
                event.preventDefault();
                this._emitDatePickerInputPartMonthFocusNextEvent();
                break;
            case 'Delete':
                event.preventDefault();
                this.value = null;
                this._userInteraction = true;
                break;
            case 'Backspace':
                event.preventDefault();
                if (this.value === null) {
                    this._emitDatePickerInputPartMonthFocusPreviousEvent();
                    break;
                }
                this.value = null;
                this._userInteraction = true;
                break;
            case 'ArrowDown':
                event.preventDefault();
                // Initially display the first month if no value is set. Otherwise cycle.
                this.value = this.value === null ? 0 : cycle(this.value, 0, this.months.length - 1, 1, 'decrease');
                this._userInteraction = true;
                break;
            case 'ArrowUp':
                event.preventDefault();
                this.value = this.value === null ? 0 : cycle(this.value, 0, this.months.length - 1, 1, 'increase');
                this._userInteraction = true;
                break;
            default:
                event.preventDefault();
                if ([...event.key].length <= 1) {
                    this._handleSearchValueInput(event.key);
                    this._userInteraction = true;
                }
                break;
        }
    }
    /**
     * This method is used to dynamically size the input element based on its current value.
     * We need this because when used in the month-picker-input component we want to avoid dynamic space between this input and the next one.
     * This SO thread discusses this further and the provided solution seamed the most bulletproof to me:
     *
     * @see https://stackoverflow.com/questions/3392493/adjust-width-of-input-field-to-its-input
     */
    _resizeInputWidthOnValueChange() {
        if (this.value !== null) {
            this.style.setProperty('---zui-date-picker-input-part-month-input-width', `0px`);
            this.style.setProperty('---zui-date-picker-input-part-month-input-width', `${this._inputElement.scrollWidth}px`);
        }
        else {
            // Set the input size to the default that allows for enough space for the placeholder
            this.style.setProperty('---zui-date-picker-input-part-month-input-width', `${this.style.getPropertyValue('--zui-date-picker-input-part-month-placeholder-width')}`);
        }
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('value')) {
            // emit events if there has been user interaction
            if (this._userInteraction) {
                this._emitDatePickerInputPartMonthChangedEvent({ value: this.value });
                this.emitInputEvent();
                this._userInteraction = false;
            }
            if (hasElementAnyDimensions(this)) {
                this._resizeInputWidthOnValueChange();
            }
        }
    }
    // TODO: we will get rid of this stuff, if setting value directly...
    willUpdate(changedProperties) {
        super.willUpdate(changedProperties);
        if (changedProperties.has('_searchString') && this._searchString.length > 0) {
            this._handleSearchValueChange();
        }
        // reset search string, if value has been cleared
        if (changedProperties.has('value') && this.value === null) {
            this._searchString = '';
        }
    }
    render() {
        var _a;
        return html `
      <input
        .value=${this.value === null ? '' : (_a = this.months[this.value]) !== null && _a !== void 0 ? _a : ''}
        ?disabled=${this.disabled}
        ?readonly=${this.readonly}
        placeholder=${this.placeholder}
        type="text"
        @focus=${this._handleInputFocusEvent}
        @keydown=${this._handleDatePickerInputPartMonthKeydownEvent}
      />
    `;
    }
};
DatePickerInputPartMonth.styles = [
    hostStyles,
    ...(BaseElement.FEATURE_ENABLE_BUILD_THEMES
        ? [themeBaseLegacyComponentStyle, themeZbdsBaseComponentStyle, themeStyles]
        : []),
    datePickerInputPartStyles,
    datePickerInputPartComponentStyles,
];
/* eslint-disable @typescript-eslint/naming-convention */
DatePickerInputPartMonth.SEARCH_VALUE_RESET_TIMEOUT = 1000;
__decorate([
    property({ reflect: true, type: String })
], DatePickerInputPartMonth.prototype, "placeholder", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], DatePickerInputPartMonth.prototype, "readonly", void 0);
__decorate([
    property({ reflect: true, type: String, converter: commaListConverter() })
], DatePickerInputPartMonth.prototype, "months", void 0);
__decorate([
    property({ reflect: true, type: Number })
], DatePickerInputPartMonth.prototype, "value", void 0);
__decorate([
    state()
], DatePickerInputPartMonth.prototype, "_searchString", void 0);
__decorate([
    query('input')
], DatePickerInputPartMonth.prototype, "_inputElement", void 0);
DatePickerInputPartMonth = DatePickerInputPartMonth_1 = __decorate([
    customElement('zui-date-picker-input-part-month')
], DatePickerInputPartMonth);
export { DatePickerInputPartMonth };
