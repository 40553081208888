import { createStoreApi } from '@arnold/store';
import type { PiralPlugin } from 'piral-core';
import { createMenuApi } from 'piral-menu';
import { createModalsApi } from 'piral-modals';
import { createNotificationsApi } from 'piral-notifications';
import { createPageLayoutsApi } from 'piral-page-layouts';

import { createLayouts } from '../layouts';
import { createDialogs } from '../modals';
import type { HdpApp } from '../types';
import { createApplicationInfoApi } from './application-info/plugin';
import { createAuthApi } from './auth/plugin';
import { createBellNotificationApi } from './bell-notification/plugin';
import { createConfigApi } from './config/plugin';
import { createCookiePreferencesApi } from './cookie-preferences/plugin';
import { createErrorHandlingApi } from './error-handling/plugin';
import { createInactivityApi } from './inactivity/plugin';
import { createIntlApi } from './intl/plugin';
import { createLayoutApi } from './layout/plugin';
import { createVersionsApi } from './versions/plugin';

export function createPlugins(app: HdpApp): Array<PiralPlugin<any>> {
  return [
    createErrorHandlingApi(),
    createCookiePreferencesApi(app.config),
    createBellNotificationApi(),
    createMenuApi(),
    createModalsApi({
      dialogs: createDialogs(),
    }),
    createStoreApi(),
    createLayoutApi(),
    createPageLayoutsApi({
      layouts: createLayouts(),
    }),
    createIntlApi(app),
    createConfigApi(app.config),
    createVersionsApi(),
    createAuthApi(),
    createNotificationsApi(),
    createInactivityApi(),
    createApplicationInfoApi(),
  ];
}
